import { useNavigation } from "@react-navigation/native";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../functions/user/actions";

import { Feather } from "@expo/vector-icons";
import { addEventListener } from "@react-native-community/netinfo";
import { useEffect, useState } from "react";
import { selectTabSelection } from "../../functions/navigation/actions";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import getColorScheme from "../../hooks/useColorScheme";

import { Sizes } from "../../constants/static-sizes";
import {
  selectPatientData,
  setPatientData,
} from "../../functions/calculator/actions";
import configureAnimations from "../../functions/configure-animations";
import useScrollTopMargin from "../../hooks/useScrollTopMargin";
import {
  getDisplayName,
  UserIconWithEdit,
} from "../../screens/more/profile/UserComponent";
import OfflineHintModal from "../modals/OfflineHintModal";

export default function PersonalContentHeader({
  height = 150,
  bottomOffset = 0,
}) {
  const insets = useSafeAreaInsets();
  const colorScheme = getColorScheme();

  const currentTabSelection = useSelector(selectTabSelection);
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();
  const [isOffline, setIsOffline] = useState(false);
  const [showHintBadge, setShowHintBadge] = useState(false);
  const [showOfflineHint, setShowOfflineHint] = useState(false);

  const topMargin = useScrollTopMargin();

  const patientData = useSelector(selectPatientData);

  const imageToTitle = (title) => {
    if (title === "Home")
      return require(`../../../assets/images/header/home.png`);
    if (title === "Inhalte")
      return require(`../../../assets/images/header/content.png`);
    if (title === "Persönlich")
      return require(`../../../assets/images/header/personal.png`);
  };

  useEffect(() => {
    const unsubscribe = addEventListener((state) => {
      configureAnimations();
      setIsOffline(
        !state.isConnected ||
          (state.type === "cellular" &&
            state.details.cellularGeneration === "2g")
      );
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function checkShowBadge() {
      if (isOffline) {
        if (await handleOneTimeAction("show_offline_hint")) {
          setShowHintBadge(true);
        }
      }
    }

    checkShowBadge();
  }, [isOffline]);

  return (
    <View>
      <OfflineHintModal
        open={showOfflineHint}
        onClose={() => setShowOfflineHint(false)}
      />
      <View
        style={{
          position: "absolute",
          top: -150,
          left: -Sizes.defaultContainerPadding,
          right: -Sizes.defaultContainerPadding,
          zIndex: 0,
          overflow: "hidden",
          bottom: -36 + bottomOffset,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <Image
          source={imageToTitle(currentTabSelection)}
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      <View
        style={{
          // paddingTop:
          //   (Platform.OS === "android"
          //     ? StatusBar.currentHeight ?? 12
          //     : insets.top) + (isLargerDevice() ? 20 : 0),

          height: height,
          marginBottom: bottomOffset,
        }}
      >
        <View
          style={{
            zIndex: 1,
            paddingTop: topMargin / 2,
            paddingBottom: 4,
            //   backgroundColor: Colors[colorScheme].background,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            <UserIconWithEdit size={100} currentUser={currentUser} />
            <Text
              style={{
                fontSize: 24,
                marginTop: 12,
                fontWeight: "700",
              }}
            >
              {getDisplayName(currentUser, null)}
            </Text>
          </View>
        </View>

        <PatientDataHeaderComponent />
      </View>
    </View>
  );
}

export const PatientDataHeaderComponent = () => {
  const patientData = useSelector(selectPatientData);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  if (!patientData) return null;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: 20,
      }}
    >
      <View
        style={{
          zIndex: 1,
          height: 70,

          flex: 1,
          justifyContent: "center",
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text>Name: </Text>
          <Text style={{ fontWeight: "800" }}>{patientData.name}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text>Gewicht: </Text>
          <Text style={{ fontWeight: "800" }}>{patientData.weight} kg</Text>
        </View>
      </View>

      <TouchableOpacity
        onPress={() => {
          navigation.navigate("AddPatientScreen");
        }}
      >
        <Feather name="edit" size={20} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => dispatch(setPatientData(null))}>
        <Feather name="x-circle" size={24} />
      </TouchableOpacity>
    </View>
  );
};
