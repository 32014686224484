import React, { useState } from "react";
import { ActivityIndicator, Dimensions, View } from "react-native";

import { useData } from "./PartnerDataProvider";
import SearchCard from "./SearchCard";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import { Sizes } from "../../constants/static-sizes";

const { width } = Dimensions.get("window");

const SearchSection: React.FC = () => {
  const { data, loading } = useData();
  const [currentIndex, setCurrentIndex] = useState(0);

  const today = new Date();
  const dayOfMonth = today.getDate();
  const primeTiles = data[dayOfMonth]?.banner || [];

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  return (
    <View
      style={{
        gap: 20,
        alignItems: "center",
        paddingBottom: 24,
        borderBottomColor: "#777",
        borderBottomWidth: 0.2,
        marginBottom: 24,
        marginHorizontal: -Sizes.defaultContainerPadding,
      }}
    >
      <CarouselComponent
        data={primeTiles}
        renderItem={({ item }) => <SearchCard item={item} />}
        sliderWidth={width}
        itemWidth={width * 0.8}
        autoplay
        autoplayDelay={2000}
        autoplayInterval={8000}
        loop
        vertical={false}
        containerCustomStyle={{ overflow: "visible" }}
        onSnapToItem={(index) => setCurrentIndex(index)}
      />
      {/* {primeTiles.length > 1 && (
        <Dots current={currentIndex} amount={primeTiles.length - 1} />
      )} */}
    </View>
  );
};

export default SearchSection;
