import AsyncStorage from "@react-native-async-storage/async-storage";
import apiCall from "../api/api-call";
import { getIsOffline } from "../components/search-tab/SearchResults";
import getUUID from "../hooks/getUUID";
import { store } from "./store";

import { updateOfflineUser } from "./user/functions";

import { reloadUserObject } from "./user/functions";

const QUEUE_KEY = "interactionQueue";

export async function synchronizeInteraction(interaction) {
  const { action, payload, dispatch } = interaction;
  const isOffline = await getIsOffline();
  if (!isOffline) {
    try {
      await actionHandlers[action](payload);
      const currentUser = store.getState().userReducer.user as any;
      updateOfflineUser(currentUser);
      return true;
    } catch {
      return false;
    }
  }

  if (dispatch) dispatch();
  const currentUser = store.getState().userReducer.user as any;
  updateOfflineUser(currentUser);
  const queue = JSON.parse((await AsyncStorage.getItem(QUEUE_KEY)) ?? "[]");
  queue.push(interaction);
  await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
  return false;
}

async function performApiCall({
  url,
  method = "GET",
  body = null,
}: {
  url: string;
  method?: string;
  body?: any;
}) {
  try {
    console.log("URL", url);
    const response = await apiCall(url, body, false, false, method);
    await reloadUserObject();
    return response;
  } catch (error) {
    await reloadUserObject();
    console.error(`API Call Error: ${url}`, error);
    throw error;
  }
}

export async function processQueue() {
  const queue = JSON.parse((await AsyncStorage.getItem(QUEUE_KEY)) ?? "[]");
  console.log(queue);
  if (queue.length === 0) return;

  while (queue.length > 0) {
    const interaction = queue[0]; // Hol das erste Element
    const { action, payload } = interaction;

    if (actionHandlers[action]) {
      try {
        await actionHandlers[action](payload);
        queue.shift();
        await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
      } catch (error) {
        console.error(`Fehler bei Aktion ${action}:`, error);
        break;
      }
    } else {
      console.error(`Unbekannte Aktion: ${action}`);
      queue.shift();
      await AsyncStorage.setItem(QUEUE_KEY, JSON.stringify(queue));
    }

    await reloadUserObject();
  }
}

const actionHandlers = {
  addBookmark: async (payload) =>
    await performApiCall({
      url: `/api/v2/users/${payload.user_id}/shortcuts/${payload.id}`,
      method: "PUT",
    }),

  removeBookmark: async (payload) =>
    await performApiCall({
      url: `/api/v2/users/${payload.user_id}/shortcuts/${payload.id}`,
      method: "DELETE",
    }),

  updatePatient: async (payload) =>
    await performApiCall({
      url: `/api/patients/${payload.id}`,
      method: "PUT",
      body: payload,
    }),

  removePatient: async (payload) =>
    await performApiCall({
      url: `/api/patients/${payload.id}`,
      method: "DELETE",
    }),

  addPageToPatientUpdate: async (payload) =>
    await performApiCall({
      url: `/api/patients/patient_data`,
      method: "PUT",
      body: {
        result_page_id: payload.pageId,
        data: payload.data,
        patient_id: payload.patient.id,
      },
    }),

  addPageToPatientCreate: async (payload) =>
    await performApiCall({
      url: `/api/patients/patient_data`,
      method: "POST",
      body: {
        patient_id: Number(payload.patient.id),
        result_page_id: payload.pageId,
        data: payload.data,
      },
    }),

  deletePageFromPatient: async (payload) =>
    await performApiCall({
      url: `/api/patients/patient_data`,
      method: "DELETE",
      body: {
        patient_id: Number(payload.patient.id),
        result_page_id: payload.pageId,
      },
    }),

  addPatient: async (payload) =>
    await performApiCall({
      url: `/api/patients/`,
      method: "POST",
      body: payload,
    }),

  addToUserHistory: async (payload) =>
    await performApiCall({
      url: `/api/v2/users/result_page/history`,
      method: "POST",
      body: {
        user_email: payload.user_email,
        result_page_id: payload.id,
        liked: null,
        event_id: getUUID(),
      },
    }),

  updateNote: async (payload) =>
    await performApiCall({
      url: `/api/v2/users/${payload.user_id}/notes/${payload.note.id}`,
      method: "PUT",
      body: {
        note: payload.note.note,
      },
    }),

  deleteNote: async (payload) =>
    await performApiCall({
      url: `/api/v2/users/${payload.user_id}/notes/${payload.noteId}`,
      method: "DELETE",
    }),
  addValueToInteractions: async (payload) => {
    await performApiCall({
      url: "/api/v3/users/interaction_records",
      method: "POST",
      body: payload,
    });
  },
  updateValuesInInteractions: async (payload) => {
    console.log("UPDATE Interaction", payload);
    await performApiCall({
      url: "/api/v3/users/interaction_records",
      method: "POST",
      body: payload,
    });
  },
};
