import { Text, View } from "react-native";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";

export default function SlideSeven() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingBottom: 100,
      }}
    >
      <Text
        style={{
          fontSize: 48,
          fontWeight: "500",
          marginBottom: 32,
        }}
      >
        {"Gleich\ngeschafft 😍"}
      </Text>
      <Text
        style={{
          fontSize: 24,
        }}
      >
        Ein paar letzte Fragen, damit wir das Nutzungserlebnis optimal auf
        deinen Fachbereich zuschneiden können.
      </Text>

      <DefaultButton
        title="Los geht's"
        action={() => {}}
        type={ButtonTypes.Primary}
        style={{
          marginTop: 24,
          width: "80%",
          alignSelf: "center",
          position: "absolute",
          bottom: 48,
        }}
      />
    </View>
  );
}
