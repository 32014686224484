import { Feather } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import I18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import CustomModal from "./CustomModal";

export default function WorkInProgressModal({ open, onClose, setClosed }) {
  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Feather
          name="alert-triangle"
          size={150}
          color={colors.semantic.error}
          style={{ alignSelf: "center", marginBottom: 20, opacity: 0.5 }}
        />
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("maintenance_work")}
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            fontFamily: "HostGrotesk-Regular",
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("maintenance_work_description")}
        </Text>
        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text={I18n.t("close")}
          icon={null}
        />
        <TouchableOpacity
          onPress={() => setClosed(false)}
          style={{ marginTop: 20, alignSelf: "center" }}
        >
          <Text
            style={{
              opacity: 0.75,
              color: colors.ui.textPrimary,
              fontFamily: "HostGrotesk-Regular",
            }}
          >
            {I18n.t("watch_anyway")}
          </Text>
        </TouchableOpacity>
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.ui.primary,
        ...style,
      }}
    >
      <Text
        style={{
          fontSize: 16,
          ...getFontStyle(500),
        }}
      >
        {text}
      </Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
