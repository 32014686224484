import { Image, Text, View } from "react-native";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideFive() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <View style={{ flex: 1, alignSelf: "stretch", justifyContent: "center" }}>
        <Text
          style={{
            fontSize: isLargerDevice() ? 54 : 36,
            ...getFontStyle(500),
            marginBottom: isLargerDevice() ? 64 : 32,
          }}
        >
          Effiziente Unterstützung bei Diagnose & Therapie 💊
        </Text>

        <View
          style={{
            flexDirection: "row",
            gap: 12,
            paddingLeft: 16,
            paddingRight: 16,
            alignItems: "flex-start",
          }}
        >
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              fontSize: isLargerDevice() ? 32 : 24,
            }}
          >
            •
          </Text>
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              fontSize: isLargerDevice() ? 32 : 24,
              flex: 1,
            }}
          >
            Krankheiten & Leitsymptome – präzise und kompakt für den Alltag
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            gap: 12,
            marginLeft: 16,
            paddingTop: 12,
            paddingRight: 16,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              fontSize: isLargerDevice() ? 32 : 24,
            }}
          >
            •
          </Text>
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              fontSize: isLargerDevice() ? 32 : 24,
              flex: 1,
            }}
          >
            Sicher durch den Notfall navigieren
          </Text>
        </View>
      </View>
      <Image
        source={require("../../../../assets/images/support_mock.png")}
        style={{
          width: "100%",
          height: isLargerDevice() ? "50%" : 450,
          marginBottom: -150,
          resizeMode: "contain",
          marginTop: 48,
          alignSelf: "center",
          marginRight: -16,
        }}
      />
    </View>
  );
}
