import { ResultBox } from "../../../../api/content/load-all/types";
import { CalculatorBox } from "./CalculatorBox";

export default function getCalculatorBox(box: ResultBox, collapsed = false) {
  return (
    <CalculatorBox
      key={box.id}
      collapsed={collapsed}
      title={box.fieldTitle}
      unit={box.unit}
    />
  );
}
