import { Dimensions } from "react-native";

export default function getWidth() {
  let width = Dimensions.get("window").width;

  if (width > 1300) {
    width = 1300;
  }

  return width;
}
