import { Text } from "react-native";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import getFontStyle from "../functions/getFontSize";

export default function SectionHeaderText({ children, style = {} }) {
  return (
    <Text
      style={{
        fontSize: Sizes.sectionHeader,
        color: colors.ui.textPrimary,
        ...style,
        ...getFontStyle(700),
      }}
    >
      {children}
    </Text>
  );
}
