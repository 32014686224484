import { createSlice } from "@reduxjs/toolkit";
import { CMSData } from "../../api/content/load-all/types";

const emptyData = {} as CMSData;

const dataSlice = createSlice({
  name: "data",
  initialState: {
    allData: emptyData,
    isLoading: true,
    versionRequirements: null,
    showUpdateBadge: false,
  },
  reducers: {
    setAllData(state, action) {
      state.allData = action.payload;
    },
    setVersionRequirements(state, action) {
      state.versionRequirements = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setShowBadge(state, action: any) {
      state.showUpdateBadge = action.payload;
    },
  },
});

export const {
  setVersionRequirements,
  setAllData,
  setIsLoading,
  setShowBadge,
} = dataSlice.actions;
export default dataSlice.reducer;

export const selectAllContent = (state) => state.dataReducer.allData;
export const selectIsLoading = (state) => state.dataReducer.isLoading;
export const selectVersionRequirements = (state) =>
  state.dataReducer.versionRequirements;
export const selectShowBadge = (state) => state?.dataReducer.showUpdateBadge;
