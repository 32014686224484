import { useMemo, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";

import { Feather } from "@expo/vector-icons";
import {
  ScoreElementMultiple,
  ScoreSelectionItem,
} from "../../../api/content/load-all/types";
import Colors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";
import { getSharedStylesContent } from "../../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";

export default function ScoreElementMultipleComponent({
  data,
  isSelecting,
  scrollViewRef,
  setCurrentSelectionIndex,
  setCurrentScore,
  setShouldChange,
  shouldChange,
  index,
  setAnswers,
  informationHeight,
  answers,
  stopTransmission = false,
  style = {},
}: {
  readonly setShouldChange: any;
  readonly shouldChange: any;
  readonly data: ScoreElementMultiple;
  readonly isSelecting: boolean;
  readonly setCurrentSelectionIndex: any;
  readonly scrollViewRef: any;
  readonly setAnswers: any;
  readonly index: any;
  readonly setCurrentScore: any;
  readonly informationHeight: number;
  readonly answers;
  readonly stopTransmission?: boolean;
  readonly style?: any;
}) {
  const colorScheme = getColorScheme();
  const [width, setWidth] = useState(0);

  const currentAnswer = answers.find((element) => element.title === data.title);

  return (
    <View
      key={data.title}
      style={{
        borderRadius: 10,
        backgroundColor: Colors[colorScheme].foreground,
        borderColor: isSelecting
          ? Colors[colorScheme].accent
          : Colors[colorScheme].text,
        borderWidth: isSelecting ? 2 : 1,
        marginBottom: 20,
        padding: 12,
        ...style,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              maxWidth: "100%",
              alignSelf: width === 0 ? "stretch" : "flex-start",
            }}
            onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
          >
            {width !== 0 && <TextItem data={data} isHeader />}
          </View>
        </View>
        {currentAnswer && currentAnswer.score !== null ? (
          <View
            style={{
              borderRadius: 6,
              borderColor: Colors[colorScheme].text,
              borderWidth: 1,
            }}
          >
            <Text
              style={{
                color: Colors[colorScheme].text,
                opacity: 0.75,
                fontSize: 20,
                marginRight: 12,
                marginLeft: 12,
                fontWeight: "bold",
              }}
            >
              {Math.round(currentAnswer?.score * 100) / 100}
            </Text>
          </View>
        ) : (
          !isSelecting && (
            <Feather
              name="chevron-down"
              color={Colors[colorScheme].text}
              size={20}
            />
          )
        )}
      </View>
      {isSelecting && (
        <SelectionView
          data={data}
          {...{
            setCurrentScore,
            index,
            setAnswers,
            currentAnswer,
            setCurrentSelectionIndex,
            shouldChange,
            setShouldChange,
            scrollViewRef,
            informationHeight,
            stopTransmission,
          }}
        />
      )}
    </View>
  );
}

const SelectionView = ({
  data,
  setCurrentScore,
  setAnswers,
  currentAnswer,
  setCurrentSelectionIndex,
  shouldChange,
  setShouldChange,
  stopTransmission,
  scrollViewRef,
  index,
  informationHeight,
}) => {
  return (
    <FlatList
      scrollEnabled={false}
      data={data.scoreSelectionItems}
      renderItem={({ item }) => (
        <ScoreElementItem
          key={Math.random()}
          {...{
            item,
            index,
            data,
            setCurrentScore,
            setCurrentSelectionIndex,
            shouldChange,
            setShouldChange,
            currentAnswer,
            scrollViewRef,
            setAnswers,
            informationHeight,
            stopTransmission,
          }}
        />
      )}
      style={{ width: "100%", marginTop: 12 }}
    />
  );
};

const ScoreElementItem = ({
  item,
  setCurrentScore,
  currentAnswer,
  setCurrentSelectionIndex,
  shouldChange,
  setShouldChange,
  scrollViewRef,
  setAnswers,
  data,
  index,
  informationHeight,
  stopTransmission,
}: {
  item: ScoreSelectionItem;
  setCurrentScore: any;

  currentAnswer: any;
  setCurrentSelectionIndex: any;
  shouldChange: boolean;
  setShouldChange: any;
  scrollViewRef: any;
  setAnswers: any;
  data: any;
  index: number;
  informationHeight: number;
  stopTransmission: boolean;
}) => {
  const colorScheme = getColorScheme();

  return (
    <TouchableOpacity
      onPress={() => {
        setCurrentScore((s) => {
          let t = 0;
          if (currentAnswer && typeof currentAnswer?.score === "string") {
            t = parseFloat(currentAnswer?.score);
          } else if (currentAnswer) {
            t = currentAnswer?.score;
          }
          if (s === null) s = 0;
          let value = item.value;

          if (value === null) return s;

          if (typeof value === "string") {
            value = parseFloat(value);
          }

          return s + value - t;
        });
        const additionalScoreInterpretation =
          data.additionalScoreInterpretations?.find(
            (_item) => _item.referenceItemTitle === item.title
          );

        setAnswers((answer) => {
          answer = answer.filter((element) => element.title !== data.title);
          return [
            ...answer,
            {
              title: data.title,
              value: item.title,
              score: item.value,
              data: data.scoreSelectionItems,
              additionalScoreInterpretation,
            },
          ];
        });
        if (stopTransmission) return;
        if (shouldChange || currentAnswer === null) {
          setCurrentSelectionIndex((s) => s + 1);
        } else {
          setCurrentSelectionIndex(-1);
          setShouldChange(true);
        }

        scrollViewRef.current?.scrollTo({
          y: informationHeight + index * 100,
          animated: true,
        });
      }}
      style={{
        paddingHorizontal: 12,
        borderRadius: 6,
        paddingVertical: 8,
        marginBottom: 8,
        flexDirection: "row",
        borderColor: Colors[colorScheme].text,
        borderWidth: currentAnswer?.value === item.title ? 2 : 1,
        alignSelf: "stretch",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors[colorScheme].foreground,
      }}
    >
      <View style={{ flex: 1, marginRight: 20 }}>
        <TextItem data={item} isHeader={false} />
      </View>
      {item.value !== null && (
        <Text
          style={{
            color: Colors[colorScheme].text,
            opacity: 0.75,
            fontSize: 20,
            marginRight: 12,
            marginLeft: 12,
            fontWeight: "bold",
          }}
        >
          {Math.round(item.value * 100) / 100}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const TextItem = ({ data, isHeader }) => {
  const colorScheme = getColorScheme();
  return useMemo(() => {
    return (
      <View
        style={{
          marginTop: -4,
          marginBottom: 8,
        }}
      >
        <BoxTextComponent
          style={getSharedStylesContent(data.title, colorScheme)}
          content={
            isHeader
              ? "<b>" + getText(data.title) + "</b>"
              : getText(data.title)
          }
        />
      </View>
    );
  }, [data.title, colorScheme, isHeader]);
};

function getText(text) {
  if (text.includes("<p>")) return text;
  else return "<p>" + text + "</p>";
}
