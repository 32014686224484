import { Feather } from "@expo/vector-icons";
import { Image, Text, TouchableOpacity, View } from "react-native";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";

import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import SectionHeaderText from "../../../../components/SectionHeaderText";
import { selectAllContent } from "../../../../functions/data/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import { selectCurrentUser } from "../../../../functions/user/actions";

const Card = ({ text, icon, vertical = true, description = "" }) => {
  const allData = useSelector(selectAllContent).pages;
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      onPress={() => {
        if (text === "Favoriten") navigation.navigate("FavoritesScreen");
        else {
          const id = getIdToSelection(allData, text);
          console.log(id);
          navigateToNextCMSPage(id, navigation);
        }
      }}
      style={{
        gap: 8,
        flex: 1,
        padding: 16,
        paddingVertical: 12,
        borderRadius: 12,
        backgroundColor: staticColors["light"].foreground,
      }}
    >
      <View
        style={{
          flexDirection: vertical ? "column" : "row",
          alignItems: vertical ? "flex-start" : "center",
          gap: 8,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: "#ddddddcc",
            padding: 10,
          }}
        >
          {typeof icon === "string" ? (
            <Feather name={icon as any} size={20} color="black" />
          ) : (
            <Image
              source={icon}
              style={{ width: 20, height: 20, resizeMode: "contain" }}
            />
          )}
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText,
            fontWeight: "600",
            flex: 1,
          }}
        >
          {text}
        </Text>
        {!vertical && <Feather name="chevron-right" size={20} color="black" />}
      </View>
      {!!description && (
        <Text
          style={{ marginTop: 8, fontSize: Sizes.boxText, fontWeight: "300" }}
        >
          {description}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const SectionGrid = ({ content }) => {
  const currentUser = useSelector(selectCurrentUser);

  return (
    <View
      style={{
        backgroundColor: staticColors["light"].background,
      }}
    >
      {/* Row 1 */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 24,
          marginBottom: 24,
          borderBottomColor: "#777",
          borderBottomWidth: 0.2,
        }}
      >
        <View style={{ flex: 1, marginRight: 8 }}>
          <Card
            text="Notfall"
            vertical={false}
            icon="alert-triangle"
            description="Schnellzugriffe für den Notfall"
          />
        </View>
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Card
            text="Favoriten"
            vertical={false}
            icon="heart"
            description="Eine Liste deiner Favoriten"
          />
        </View>
      </View>

      {/* Row 2 - "Medikationen" Title and 3 Cards */}
      <SectionHeaderText>Diagnostik & Therapie</SectionHeaderText>
      <Text
        style={{
          marginVertical: 8,
          fontWeight: "300",
          fontSize: Sizes.boxText,
        }}
      >
        Effizienter Überblick über Diagnostik, Algorithmen und
        Therapiemöglichkeiten von Erkrankungen und Symptomen
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 24,
          marginBottom: 24,
          marginTop: 8,
          borderBottomColor: "#777",
          borderBottomWidth: 0.2,
        }}
      >
        <View style={{ flex: 1, marginRight: 8 }}>
          <Card
            text="Erwachsene"
            icon={require("../../../../../assets/images/icons/adult.png")}
          />
        </View>
        <View style={{ flex: 1, marginHorizontal: 8 }}>
          <Card
            text="Kinder"
            icon={require("../../../../../assets/images/icons/kids.png")}
          />
        </View>
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Card
            text="Neonaten"
            icon={require("../../../../../assets/images/icons/neo.png")}
          />
        </View>
      </View>

      {/* Row 3 */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", gap: 20 }}>
          <Card
            text="Rechner"
            icon="cpu"
            vertical={false}
            description="Sammlung klinisch relevanter Rechner"
          />
          <Card
            text="Scores"
            icon="clipboard"
            vertical={false}
            description="Sammlung klinisch relevanter Scores"
          />
        </View>
      </View>

      {/* Row 4 */}
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ flex: 1 }}>
          <Card
            text="Interventionen, Prozeduren & Algorithmen"
            icon="activity"
            vertical={false}
            description="Sammlung von Interventionen & Prozeduren sowie vereinfachte Darstellung komplexer Diagnostik oder Therapiealgorithmen"
          />
        </View>
      </View>

      {/* Row 5 */}
      {(currentUser.user_type === "dev" || __DEV__) && (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            padding: 12,
            backgroundColor: "white",
            marginTop: 32,
            alignItems: "center",
            borderRadius: 12,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: Sizes.boxText,
            }}
          >
            Development Content ansehen
          </Text>
          <Feather name="chevron-right" size={20} color="black" />
        </TouchableOpacity>
      )}
    </View>
  );
};

function getIdToSelection(allData, title) {
  const page = allData.find((page) => page.title === getSerialisedTitle(title));
  return page?._id;
}

function getSerialisedTitle(title) {
  switch (title) {
    case "Diagnostik & Therapie":
    case "Erwachsene":
      return "FT - Erwachsene";
    case "Kinder":
      return "FT - Kinder";
    case "Neonaten":
      return "FT - Neonatologie und Säuglinge";
    case "Scores & Rechner":
      return "Scores & Rechner";
    case "Interventionen, Prozeduren & Algorithmen":
      return "Interventionen, Prozeduren und Algorithmen";
    default:
      return title;
  }
}

export default SectionGrid;
