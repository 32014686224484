import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import i18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";

import continue_web from "../../../assets/images/download-buttons/ContinueWithBrowser.png";
import download_ios from "../../../assets/images/download-buttons/DownloadAppStore.png";
import download_android from "../../../assets/images/download-buttons/DownloadPlayStore.png";
import getFontStyle from "../../functions/getFontSize";

export const MobileFirstLandingScreen = () => {
  const navigation = useNavigation();
  const [timesShown, setTimesShown] = useState(0);

  useEffect(() => {
    const fetchTimesShown = async () => {
      try {
        const value = await AsyncStorage.getItem("times_shown");
        const times = value ? parseInt(value, 10) : 0;
        setTimesShown(times);
        if (times > 15) {
          navigation.navigate("LaunchScreen");
        }
      } catch (error) {
        console.error("Error fetching times_shown", error);
      }
    };

    fetchTimesShown();
  }, [navigation]);

  const handleContinue = async () => {
    try {
      const newTimes = timesShown + 1;
      await AsyncStorage.setItem("times_shown", newTimes.toString());
      navigation.navigate("LaunchScreen");
    } catch (error) {
      console.error("Error updating times_shown", error);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.subtitle}>{i18n.t("already_know")}</Text>
        <Text style={styles.title}>
          <Text style={styles.appName}>LANDO</Text> APP
        </Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://apps.apple.com/de/app/mediceo/id1629718911"
              )
            }
          >
            <Image source={download_ios} style={styles.downloadButton} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://play.google.com/store/apps/details?id=com.mediceo.app"
              )
            }
          >
            <Image source={download_android} style={styles.downloadButton} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.footer}>
        <Text style={styles.footerText}>{i18n.t("continue_in_web")}</Text>
        <TouchableOpacity
          style={styles.continueButton}
          onPress={handleContinue}
        >
          <Image source={continue_web} style={styles.continueImage} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.ui.background,
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between",
  },

  header: {
    marginTop: 50,
    alignItems: "center",
  },
  subtitle: {
    fontSize: 16,
    color: colors.ui.textPrimary,
    marginBottom: 8,
  },
  title: {
    fontSize: 32,
    ...getFontStyle(700),
    color: colors.ui.textPrimary,
  },
  appName: {
    ...getFontStyle(800),
  },
  contentContainer: {
    width: "100%",
    flex: 1,
    alignItems: "center",
  },
  buttonsContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  downloadButton: {
    width: 200,
    height: 65,
    resizeMode: "contain",
    marginVertical: 12,
  },
  footer: {
    alignItems: "center",
    marginBottom: 30,
  },
  footerText: {
    fontSize: 14,
    color: colors.ui.textPrimary,
    marginBottom: 10,
    fontFamily: "HostGrotesk-Regular",
  },
  continueButton: {
    width: 140,
    height: 50,
  },
  continueImage: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
});
