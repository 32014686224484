import { View } from "react-native";

export default function BlurViewWrapper({ style, children }) {
  console.log("BlurViewWrapper", style);
  return (
    <View
      style={[
        style,
        {
          backdropFilter: "blur(15px)",
        },
      ]}
    >
      {children}
    </View>
  );
}
