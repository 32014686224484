import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";

import { SatelliteTile, useData } from "./PartnerDataProvider";
import { SmallPartnerCard } from "./SmallPartnerCard";
import getWidth from "../../hooks/getWidth";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import InViewPort from "../InViewComponent/InViewComponent";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { MIN_IMPRESSION_DURATION } from "./PrimeSection";

const chunkArray = (arr: SatelliteTile[], size: number): SatelliteTile[][] => {
  const chunks: any = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

const SatelliteSection: React.FC = () => {
  const { data, loading } = useData();

  const width = getWidth();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const satelliteTiles = data[dayOfMonth]?.sateliteTile ?? [];

  const [hasLoggedImpression, setHasLoggedImpression] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const groupedSatelliteTiles = chunkArray(satelliteTiles, 2);

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  const handleVisibilityChange = (isVisible: boolean) => {
    if (isVisible && !hasLoggedImpression) {
      if (!timer) {
        const newTimer = setTimeout(() => {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "satelite_section_impression",
            "SateliteSection",
            "Impression",
            {}
          );
          setHasLoggedImpression(true);
          setTimer(null);
        }, MIN_IMPRESSION_DURATION);
        setTimer(newTimer);
      }
    } else {
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    }
  };

  if (satelliteTiles.length === 0) return null;

  return (
    <InViewPort onChange={(isVisible) => handleVisibilityChange(isVisible)}>
      <View
        style={{
          gap: 20,
          alignItems: "center",
          borderBottomWidth: 0.3,
          borderBottomColor: "#bbb",
          paddingBottom: 32,
        }}
      >
        <CarouselComponent
          data={groupedSatelliteTiles}
          containerCustomStyle={{ overflow: "visible" }}
          renderItem={({ item }) => (
            <View
              style={{
                flexDirection: "column",
                overflow: "visible",
                //flexWrap: "column",
                justifyContent: "space-between",
                width: "100%", // Ensure grid takes full width
                gap: 12,
              }}
            >
              {item.map((gridItem, index) => (
                <SmallPartnerCard key={index} item={gridItem} />
              ))}
            </View>
          )}
          sliderWidth={width}
          //itemWidth={Math.min(width, 1000)} // Limits max width for consistent display
          autoplay
          autoplayDelay={2000}
          autoplayInterval={8000}
          loop
          vertical={false}
        />
      </View>
    </InViewPort>
  );
};

export default SatelliteSection;
