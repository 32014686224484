import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Feather } from "@expo/vector-icons";

import { useNavigation } from "@react-navigation/native";
import getPageContent from "../../../api/content/get-page-content";
import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  selectPatientHeaderExpanded,
  selectPatientHeaderHistoryExpanded,
  setPatientHeaderExpanded,
  setPatientHeaderHistoryExpanded,
  setPatientId,
} from "../../../functions/calculator/actions";
import configureAnimations from "../../../functions/configure-animations";
import { selectCurrentResultPage } from "../../../functions/current-result/actions";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import {
  addPageToPatientCreate,
  deletePageFromPatient,
} from "../../../functions/user/functions";
import { formatDate } from "../ResultPageHeaderComponent";
import alert from "../../CustomAlert";

export const PatientDataHeaderComponent = ({ style = {} }) => {
  const patientData = useSelector(selectPatientData);
  const dispatch = useDispatch();

  const expanded = useSelector(selectPatientHeaderExpanded);
  const currentPageContent = useSelector(selectCurrentResultPage);

  const historyExpanded = useSelector(selectPatientHeaderHistoryExpanded);

  const navigation = useNavigation();

  const pageIsInData = patientData?.data?.find(
    (page) => page.result_page_id === currentPageContent?._id
  );

  const warningLevel =
    patientData?.weight >= 300 ? 2 : patientData?.weight >= 200 ? 1 : 0;

  const currentFilteredHistoryItems =
    patientData?.data?.filter(
      (item) => item.result_page_id !== currentPageContent?._id
    ) ?? [];
  const currentHistoryItems = patientData?.data ?? [];

  if (!patientData) return null;

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("AddPatientScreen")}
      style={{
        zIndex: 100000,
        ...styles.container,
        ...{
          paddingHorizontal: 16,
          borderRadius: 20,
          shadowOffset: {
            width: 2,
            height: 2,
          },
          shadowOpacity: 0.12,
          shadowRadius: 4,
          elevation: 2,
        },
        ...style,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          onPress={() => {
            configureAnimations();
            dispatch(setPatientHeaderExpanded(!expanded));
          }}
          style={{ marginRight: 12 }}
        >
          <Feather name={expanded ? "chevron-up" : "chevron-down"} size={24} />
        </TouchableOpacity>
        <View style={styles.infoContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.label}>Kennung:</Text>
            <Text style={styles.value}>{patientData.name}</Text>
          </View>
          <View style={styles.detailsContainer}>
            <View style={styles.detailItem}>
              <Feather name="user" size={16} />
              <Text style={styles.label}>Gewicht:</Text>
              <Text style={styles.value}>{patientData.weight} kg</Text>
            </View>
            {patientData.height && (
              <View style={styles.detailItem}>
                <Feather name="move" size={16} />
                <Text style={styles.label}>Größe:</Text>
                <Text style={styles.value}>{patientData.height} cm</Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.actionsContainer}>
          <TouchableOpacity
            onPress={() => {
              alert(
                "Patienten entfernen",
                "Die Daten des Patienten werden deaktiviert. Du kannst ihn später aus der Historie wiederherstellen.",
                [
                  {
                    text: "Nein",
                    style: "cancel",
                  },
                  {
                    text: "Entfernen",
                    style: "destructive",
                    onPress: () => {
                      configureAnimations();
                      dispatch(setPatientId(null));
                      dispatch(clearAmount());
                    },
                  },
                ]
              );
            }}
            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          >
            <Feather name="x" size={20} />
          </TouchableOpacity>
        </View>
      </View>
      {expanded && (
        <View style={{}}>
          <WeightWarningLabel
            warningLevel={warningLevel}
            style={{
              marginHorizontal: 20,
              marginTop: 12,
            }}
          />

          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
              gap: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!pageIsInData && currentPageContent && (
              <TouchableOpacity
                activeOpacity={currentPageContent ? 0.7 : 0.5}
                onPress={() => {
                  if (!currentPageContent) return;
                  addPageToPatientCreate(patientData, currentPageContent?._id, [
                    {},
                  ]);
                }}
                style={{
                  paddingHorizontal: 8,
                  opacity: currentPageContent ? 1 : 0.5,
                  alignItems: "center",
                  flexDirection: "row",
                  borderRadius: 8,
                  borderWidth: 0.5,
                  padding: 8,
                  gap: 8,
                }}
              >
                <Text style={{ textAlign: "center", fontSize: Sizes.boxText }}>
                  Diese Seite dem Patienten hinzufügen
                </Text>
                <Feather name="plus" size={16} />
              </TouchableOpacity>
            )}

            {pageIsInData && (
              <View
                style={{ flexDirection: "row", alignItems: "center", gap: 20 }}
              >
                <TouchableOpacity
                  disabled={!currentPageContent}
                  onPress={() => {
                    deletePageFromPatient(
                      patientData,
                      currentPageContent._id,
                      pageIsInData
                    );
                  }}
                  style={{
                    paddingHorizontal: 8,
                    opacity: currentPageContent ? 1 : 0.5,
                    alignItems: "center",
                    flexDirection: "row",
                    borderRadius: 8,
                    borderWidth: 0.5,
                    padding: 8,
                    gap: 8,
                    flex: 1,
                    width: 250,
                  }}
                >
                  <Text
                    style={{
                      textAlign: "center",
                      fontSize: Sizes.boxText,
                      flex: 1,
                    }}
                  >
                    Seite Entfernen
                  </Text>
                  <Feather name="trash" size={16} />
                </TouchableOpacity>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Feather style={{}} name="check-circle" size={20} />
                </View>
              </View>
            )}
          </View>
          {historyExpanded && (
            <View
              style={{
                gap: 12,
                marginTop: 20,
              }}
            >
              {currentFilteredHistoryItems?.map((item) => {
                //TODO: memo pages
                const page = getPageContent(item.result_page_id);
                return (
                  <TouchableOpacity
                    key={item.id}
                    onPress={() =>
                      navigateToNextCMSPage(item.result_page_id, navigation)
                    }
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: Sizes.boxText - 2,
                          flex: 1,
                        }}
                      >
                        {page.title ?? page.fieldTitle}
                      </Text>

                      <Feather name="arrow-right" size={16} />
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {currentHistoryItems.length > 0 && (
              <TouchableOpacity
                disabled={currentFilteredHistoryItems.length === 0}
                style={{ flex: 1, flexDirection: "row", gap: 4 }}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={() => {
                  configureAnimations();
                  dispatch(setPatientHeaderHistoryExpanded(!historyExpanded));
                }}
              >
                <Text
                  style={{
                    fontSize: Sizes.boxText - 2,
                  }}
                >
                  {"Seitenhistorie (" + currentHistoryItems.length + ")"}
                </Text>

                {currentFilteredHistoryItems.length > 0 && (
                  <Feather
                    name={historyExpanded ? "chevron-up" : "chevron-down"}
                    size={16}
                  />
                )}
              </TouchableOpacity>
            )}
            {(patientData.modified_at ?? patientData.created_at) && (
              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontStyle: "italic",
                    fontSize: Sizes.boxText - 2,
                  }}
                >
                  {formatDate(
                    patientData.modified_at ?? patientData.created_at
                  )}
                </Text>
              </View>
            )}
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    marginBottom: 16,
    backgroundColor: staticColors.light.foreground,
  },
  infoContainer: {
    flex: 1,
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    gap: 4,
  },
  detailsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    marginBottom: 4,
    gap: 4,
  },
  label: {
    fontSize: Sizes.boxText,
  },
  value: {
    fontSize: Sizes.boxText,
    fontWeight: "600",
  },
  actionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
});

export const WeightWarningLabel = ({ warningLevel, style }) => {
  if (warningLevel === 0) return null;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: 12,
        ...style,
      }}
    >
      <Feather
        name="alert-triangle"
        size={16}
        color={warningLevel === 1 ? "orange" : "red"}
      />
      <Text
        style={{
          flex: 1,
          fontSize: Sizes.boxText,
          color: warningLevel === 1 ? "orange" : "red",
        }}
      >
        {warningLevel === 1
          ? "Das eingegebene Körpergewicht überschreitet die normale Körpergewichtsgrenze erheblich!"
          : "Das eingegebene Körpergewicht ist unplausibel! Bitte die Eingabe prüfen!"}
      </Text>
    </View>
  );
};
