export default {
  recently_used: "Recently used",
  welcome: "Welcome",
  home: "Home",
  search: "Search",
  toolbox: "Toolbox",
  more: "More",
  show_all: "Show all",
  show_less: "Show less",
  my_favorites: "My favorites",
  ask_lyra: "Ask LYRA",
  back: "back",
  our_recommendations: "Our recommendations",
  explore_mediceo: "Explore MEDICEO!",
  explore_detail:
    "Don't miss any helpful features - Discover the scope of MEDICEO here.",
  discover_now: "Discover now",
  table_of_contents: "Table of contents",
  calculators: "Calculators",
  welcome_back: "Welcome back",
  login_now: "Log in now and get started.",
  email: "Email",
  password: "Password",
  stay_signed_in: "Stay logged in",
  sign_in: "Log in",
  forgot_pw: "Forgot password?",
  register_now: "Register now?",
  done: "Done",
  doctor: "Doctor",
  non_med_prof: "Non-medical professionals",
  student: "Studierende",
  select_account_type: "Select account\ntype",
  confirm_selection: "Confirm selection",
  register_now_: "Register now",
  register: "Register",
  account_present: "I already have an account",
  log_in: "Log in",
  no_continue: "No, continue",
  disclaimer_nurse:
    "I hereby confirm that I am a 'non-medical professional' and will upload a confirming document within the next 3 weeks as part of the validation of my status. You will receive further information by email.",
  disclaimer_doc:
    "I hereby confirm that I am a licensed doctor or authorised to practise medicine.",
  Back: "Back",
  send: "Send",
  enter_login_data: "Set the login data for your MEDICEO account:",
  history: "History",
  no_results: "No results",
  load_more: "Load more",
  delete_all_search_results: "Delete all search results",
  delete_search_results: "Delete search results",
  result: "Result",
  content: "Content",
  recommend_mediceo: "Recommend MEDICEO",
  profile: "Profile",
  premium_free: "Premium for free",
  upgrade_free: "Upgrade for 0,00 €",
  premium_expires_in_start: "PREMIUM expires in",
  premium_expires_in_end: "",
  account_settings: "Account & Settings",
  my_account: "My account",
  my_cases: "My cases",
  kpi_slide_text: "Clinical decisions made via MEDICEO®",
  quote_stefan:
    "which content would you like to see next? Our medical research team is happy to help you.",
  default_search_placeholder: "Search our content",
  topics: "Topics",
  search_placeholder_detail: "Search ",
  favorites: "Favorites",
  cases: "Cases",
  case: "Case",
  export: "Export",
  pages: "Pages",
  personal: "Personal",
  good_morning: "Good morning",
  good_afternoon: "Good afternoon",
  good_evening: "Good evening",
  show_sources: "Show sources",
  hide_sources: "Hide sources",
  show_all_content: "Show all content",
  add_case: "Add case",
  recently_added: "Recently added",
  most_used: "Most used",
  empty_favorites_placeholder:
    "You haven't added any favorites yet. Add your first favorite now!",
  edit: "Edit",
  empty_cases_placeholder:
    "You haven't added any cases yet. Add your first case now!",
  add_favorites: "Add favorites",
  empty_history_placeholder: "You haven't viewed any content yet. Start now!",
  go_to_content: "Go to content",
  personal_search_placeholder: "Search your personal content",
  empty_export_placeholder:
    "You haven't exported any content yet. Add your first export now!",
  add_wishes: "Tell us your wishes",
  quote_leon:
    "100% process clarity with MEDICEO Enterprise? Integrate individual processes, SOPs, and other content into the in-house MEDICEO version.",
  emergency: "Emergency",
  diseases: "Diseases",
  interventions: "Interventions & Procedures",
  therapy_algorithms: "Therapy algorithms",
  substances: "Active ingredients & Medicines",
  scores: "Scores",
  checklists: "Checklists",
  emergency_description:
    "In this section, you will receive short and concise information on the management of acute emergencies.",
  fast_track_description:
    "In this section, you will find disease-specific medications and dosages through the fastest way 'Fast-Track'.",
  diseases_description:
    "In this section, you will receive comprehensive information about specific diseases, including their symptoms, diagnostic procedures, and treatment options.",
  interventions_procedures_description:
    "Here, interventions and procedures are described, including their indications, contraindications, equipment, preparations and precautions, monitoring, as well as technique and implementation.",
  therapy_algorithms_description:
    "This category offers therapy algorithms and treatment guidelines for various diseases or clinical scenarios to support a structured approach to therapy.",
  scores_description:
    "Here you will find information on various clinical rating scales and scores used to assess disease severity, prognosis, or treatment needs.",
  calculators_description:
    "This section provides various medical calculators and tools to perform calculations, such as unit conversions or body surface area calculations.",
  register_email: "Create Account",
  already_account: "Already have an account?",
  email_or_id: "Email or ID",
  agreeTerms: {
    confirm: "By continuing, you also confirm the",
    login: "logging in",
    registration: "registering",
    terms: "terms of use",
    privacy: "privacy policy",
    of: "of MEDICEO®.",
    and: "and the",
  },
  reset_password: {
    title: "Reset Password",
    greeting: "Enter your email and reset your password.",
    placeholder: "Email",
    reset: "Reset",
    success:
      "You will receive an email with further instructions in the next few minutes. (Please also check your spam folder.)",
    failure:
      "Failed to send the email. Please try again. If the problem persists, feel free to contact us",
    login: "Log in now",
  },
  role_selection: {
    doctor: "Doctor",
    i_am: "I am...",
    non_med_prof: "Non-doctor medical professional",
    student: "studying / in training",
    select_account_type: "Select Account Type",
    confirm_selection: "Confirm Selection",
    already_have_account: "Log in",
    not_medical_professional: "No medical professional",
  },
  agree_terms_registry: {
    confirm: "I hereby confirm that I accept the",
    terms: "terms of use",
    privacy: "privacy policy",
    of: "MEDICEO®.",
    and: "and the",
  },
  welcome_to: "Welcome to",
  hot_features: "Hot Features",
  continue_with_apple: "Continue with Apple",
  continue_with_google: "Continue with Google",
  already_know: "DO YOU ALREADY KNOW",
  non_medical_user: {
    request_access: "Request access",
    no_medical_user_subheading:
      "Please fill out the following fields to request access as non-medical user. Each registration is checked and manually approved by the MEDICEO team. We will inform you by email as soon as your access has been activated.",
    why_you_wanna_register: "why do you want to register?",
  },
  add_patient: "Create new patient",
  first_name: "First Name",
  last_name: "Last Name",
  continue_in_web: "CONTINUE IN THE WEBVERSION",
  min_eight_chars: "Password must contain at least 8 characters.",
  we_need_some_more_infos: "We need some more information from you",
  reason_for_info_collecting:
    "In order to offer you the greatest possible benefit as a medical device, we need some additional information. That won't take long - thank you for your understanding!",
  thanks_for_verify: "Thank you for verification. Now complete your profile 🚀",
  cancel_registration: "I want to cancel the registration",
  continue: "Continue",
  what_profession_do_you_pursue: "Welchem Beruf gehst du nach?",
  notes_fav_or_share: "Notes, Favorites and Share",
  notes_fav_or_share_description:
    "Here you can add your own notes, mark the page as a favorite or share a direct link with colleagues.",
  enter_you_searchterm: "Enter your search term here",
  edit_patient: "Edit Patient",
  create_patient: "Create Patient",
  identifier: "Identifier",
  complete_your_datas: "Complete your details now",
  beside_mediceo: "Besides MEDICEO, which tools do you use in everyday life?",
  thanks_for_complete_info:
    "Thank you for filling out your information. Now we can adapt MEDICEO to you in the best possible way.",
  thanks_for_feedback: "Feedback has been sent - Thank you!",
  send_feedback_now: "Send feedback now",
  enable_push_notifications:
    "To enable push notifications for MEDICEO, go to Settings > MEDICEO > Notifications and turn them on.",
  error_occurred_try_later: "An error has occurred. Please try again later",
  new_update_available: "New update available!",
  new_update_available_description:
    "A new update is available that includes new features and contains improvements. We recommend installing the update to use the app optimally.",
  update_now: "Update now",
  error_occurred_refresh_screen:
    "An error has occurred. Please refresh the content on the home screen",
  image_not_existing: "The image is not available",
  coming_soon: "Coming Soon",
  value_to_high: "The value you entered seems high. Are you sure?",
  value_to_low: "The value you entered seems low. Are you sure?",
  enter_data: "Daten eingeben",
  your_personal_area: "Your personal area",
  cases_fav_and_history:
    "Here you will find all your saved cases and favorites, as well as your history.",
  simple_patient_parameters: "Easy entry of patient parameters",
  simple_patient_parameters_desc:
    "Here you can enter patient parameters and the corresponding values   will automatically adjust in the text.",
  something_went_wrong: "Something went wrong, please try again",
  not_allowed_text_try_again:
    "You have entered text that is not allowed. Please change this. If the problem persists, contact us at support@mediceo.com",
};
