import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import getPageContent from "../../../../api/content/get-page-content";
import { colors } from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";

import configureAnimations from "../../../../functions/configure-animations";
import getFontStyle from "../../../../functions/getFontSize";
import {
  ChecklistInterpretation,
  getCheckedMainItems,
  getChecklistColor,
  isSublistChecked,
} from "../../../../screens/ChecklistScreen/ChecklistScreen";
import { CategoryIcon } from "../../../CategoryIcon";

export default function ChecklistFurtherActionBox({ box }) {
  const isPopover = true; //box.isPopover;

  const pageContent = useMemo(
    () => getPageContent(box.resultOrNodeID),
    [box.resultOrNodeID]
  );

  const navigation = useNavigation();

  const [expanded, setExpanded] = useState(false);

  const [interpretationContent, setInterpretationContent] =
    useState<any>(undefined);

  const listLength = pageContent?.checklistItems.filter(
    (item) => !item.isOptional && item.typeId === "checklistListElement"
  ).length;

  const completed = pageContent?.checklistItems.every((item) => {
    if (item.typeId !== "checklistListElement") return true;
    if (item.isOptional) {
      return true;
    }
    if (item.listElements) {
      return isSublistChecked(
        item.listElements,
        interpretationContent?.checkedItems
      );
    }
    return interpretationContent?.checkedItems?.has(item.id);
  });

  return (
    <View
      style={{
        marginTop: 20,
        borderRadius: 12,
        overflow: "hidden",
        paddingTop: expanded ? 12 : 0,
        paddingLeft: expanded ? 19 : 0,
        paddingRight: expanded ? 12 : 0,
        backgroundColor: colors.ui.cardBackground,
      }}
    >
      {!expanded && (
        <View
          style={{
            width: 7,
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,
            backgroundColor: getChecklistColor(completed),
            zIndex: 10,
          }}
        />
      )}

      <TouchableOpacity
        key={box.id}
        style={{
          flexDirection: "row",
          padding: expanded ? 8 : 16,
          paddingLeft: expanded ? 0 : 19,
          backgroundColor: expanded ? "transparent" : colors.ui.cardBackground,
          alignItems: "center",
        }}
        onPress={() => {
          configureAnimations();
          if (isPopover) {
            navigation.navigate("ChecklistScreen", {
              pageContent,
              setInterpretationContent,
              interpretationContent,
              modal: true,
            });
            return;
          }
          setExpanded((e) => !e);
        }}
      >
        <CategoryIcon title={"Checklisten"} size={26} />
        <View style={{ flex: 1, gap: 4 }}>
          <Text
            style={{
              fontSize: Sizes.boxText,
              color: colors.ui.textPrimary,
              ...getFontStyle(700),
            }}
          >
            {box.title}
          </Text>
          {!expanded && (
            <Text
              style={{
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
                ...getFontStyle(400, true),
              }}
            >
              {
                getCheckedMainItems(
                  pageContent?.checklistItems,
                  interpretationContent?.checkedItems
                ).size
              }{" "}
              von {listLength} Punkten erledigt
            </Text>
          )}
        </View>
        <Feather
          name={
            isPopover
              ? "chevron-right"
              : expanded
              ? "chevron-up"
              : "chevron-down"
          }
          size={24}
          color={colors.ui.textPrimary}
        />
      </TouchableOpacity>

      {/* {!isPopover && expanded && (
        <ChecklistComponent
          pageContent={pageContent}
          checkedItems={interpretationContent?.checkedItems}
          setCheckedItems={setCheckedItems}
        />
      )} */}
      {expanded && (
        <View
          style={{
            width: 7,
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,
            backgroundColor: getChecklistColor(completed),
            zIndex: 1000,
          }}
        />
      )}
      {expanded && (
        <ChecklistInterpretation
          style={{
            marginHorizontal: -24,
            marginTop: 12,
          }}
          checklistItems={pageContent?.checklistItems}
          checkedItems={interpretationContent?.checkedItems}
          completed={completed}
          listLength={listLength}
        />
      )}
    </View>
  );
}
