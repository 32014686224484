import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialAmounts: any = {};
const initialUnits: any = [];

const calculatorSlice = createSlice({
  name: "calculator",
  initialState: {
    amounts: initialAmounts,
    currentUnits: initialUnits,
    calculationVar: 0,
    gender: "m",
    formulaResult: null,
    resultingCalculation: null,
    scoreData: null,
    checklistData: null,
    checklistNote: "",
    patientData: null,
    patientHeaderExpanded: true,
  },
  reducers: {
    setAmount(state, action) {
      Object.assign(state.amounts, action.payload);
    },
    setPatientHeaderExpanded(state, action) {
      state.patientHeaderExpanded = action.payload;
    },
    setScoreData(state, action) {
      state.scoreData = { ...(state.scoreData ?? {}), ...action.payload };
    },
    setChecklistData(state, action) {
      state.checklistData = action.payload;
    },
    clearAmount(state) {
      state.amounts = {};
    },
    clearCurrentUnits(state) {
      state.currentUnits = [];
    },
    removeFromCurrentUnits(state, action) {
      state.currentUnits = state.currentUnits.filter(
        (unit: string) => unit !== action.payload
      );
    },
    addToCurrentUnits(state, action) {
      state.currentUnits = _.uniq([...state.currentUnits, action.payload]);
    },
    bulkAddToCurrentUnits(state, action) {
      state.currentUnits = _.uniq([...state.currentUnits, ...action.payload]);
    },
    setCalculationVar(state, action) {
      state.calculationVar = action.payload;
    },
    setFormulaResult(state, action) {
      state.formulaResult = action.payload;
    },
    setResultingCalculation(state, action) {
      state.resultingCalculation = action.payload;
    },
    setChecklistNote(state, action) {
      state.checklistNote = action.payload;
    },
    setPatientData(state, action) {
      // state.patientData = action.payload;
      // if (action.payload?.weight) {
      //   Object.assign(state.amounts, {
      //     kg: action.payload?.weight,
      //     g: action.payload?.weight * 1000,
      //     amount: action.payload?.weight,
      //   });
      //   if (!state.currentUnit) state.currentUnit = ["kg"];
      //   else if (Array.isArray(state.currentUnit)) {
      //     state.currentUnit = [...state.currentUnit, "kg"];
      //   } else {
      //     state.currentUnit = [state.currentUnit, "kg"];
      //   }
      // }
      // if (action.payload?.size) {
      //   Object.assign(state.amounts, {
      //     m: action.payload?.size / 100,
      //     cm: action.payload?.size,
      //     size: action.payload?.size,
      //   });
      //   // if (!state.currentUnit) state.currentUnit = ["cm", "m"];
      //   // else if (Array.isArray(state.currentUnit)) {
      //   //   state.currentUnit = [...state.currentUnit, "cm", "m"];
      //   // } else {
      //   //   state.currentUnit = [state.currentUnit, "cm", "m"];
      //   // }
      // }
      // configureAnimations();
    },
  },
});

export default calculatorSlice.reducer;
export const {
  setAmount,
  clearCurrentUnits,
  addToCurrentUnits,
  setCalculationVar,
  clearAmount,
  setFormulaResult,
  setResultingCalculation,
  setScoreData,
  setChecklistData,
  setChecklistNote,
  setPatientData,
  setPatientHeaderExpanded,
  bulkAddToCurrentUnits,
  removeFromCurrentUnits,
} = calculatorSlice.actions;

export const selectPatientHeaderExpanded = (state: any) =>
  state.calculatorReducer.patientHeaderExpanded;
export const selectPatientData = (state: any) =>
  state.calculatorReducer.patientData;
export const selectAllAmounts = (state: any) => state.calculatorReducer.amounts;
export const selectScoreData = (state: any) =>
  state.calculatorReducer.scoreData;
export const selectCurrentUnits = (state: any) =>
  state.calculatorReducer.currentUnits;
export const selectChecklistData = (state: any) =>
  state.calculatorReducer.checklistData;
export const selectCalculationVar = (state: any) =>
  state.calculatorReducer.calculationVar;
export const selectFormulaResult = (state: any) =>
  state.calculatorReducer.formulaResult;
export const selectResultingCalculation = (state: any) =>
  state.calculatorReducer.resultingCalculation;
export const selectChecklistNote = (state: any) =>
  state.calculatorReducer.checklistNote;
