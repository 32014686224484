import {
  Image,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";

import { Feather, Ionicons } from "@expo/vector-icons";
import * as ScreenOrientation from "expo-screen-orientation";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch } from "react-redux";
import i18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";

import AsyncStorage from "@react-native-async-storage/async-storage";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import { setOnlyRole } from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";
import { sendAuthCodeToServer } from "./LandingScreen/functions/ProviderSignIn";

export function RoleSelectionScreen({ route }) {
  const authData = route.params?.authData;
  const navigation = useNavigation();
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AsyncStorage.setItem("user", "null");
    AsyncStorage.setItem("selectedEnterprise", "null");
  }, []);

  useEffect(() => {
    configureAnimations();
  }, [currentIndex]);

  const data = [
    {
      title: i18n.t("role_selection.doctor"),
      image: require("../../../assets/images/characters/doctor.png"),
      user_type: "doctor",
    },
    {
      title: i18n.t("role_selection.non_med_prof"),
      image: require("../../../assets/images/characters/nurse.png"),
      user_type: "nurse",
    },
    {
      title: i18n.t("role_selection.student"),
      image: require("../../../assets/images/characters/student.png"),
      user_type: "student",
    },
  ];

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "role_selection_opened",
        "UserRoleSelectionScreen",
        "UserOpened"
      );
    });

    if (Platform.OS === "web") return;
    ScreenOrientation.unlockAsync();

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
      }}
    >
      <View
        style={{
          flex: 1,
          paddingVertical: Sizes.defaultContainerPadding,
          width: "100%",
        }}
      >
        <View
          style={{
            alignItems: "center",
            flex: 1,
            alignSelf: "stretch",
          }}
        >
          {/* <ProgressBar currentStep={2} subSteps={2} currentSubStep={0} /> */}
          <View
            style={{
              width: "100%",
              maxWidth: Sizes.containerWidth,
              flex: 1,
              alignItems: "flex-start",
            }}
          >
            <Text
              style={{
                color: Colors[colorScheme].text,
                fontSize: 32,
                fontWeight: "500",
                marginTop: 12,
                marginBottom: 12,
                letterSpacing: 0.7,
                marginHorizontal: 20,
              }}
            >
              {i18n.t("role_selection.i_am")}
            </Text>
            <View
              style={{
                flex: 1,

                width: "100%",
                maxWidth: Sizes.containerWidth,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                  flex: 1,
                }}
              >
                {data.map((d, index) => (
                  <SelectUserView
                    image={d.image}
                    index={index}
                    title={d.title}
                    key={d.title}
                    selected={index === currentIndex}
                    setCurrentIndex={setCurrentIndex}
                  />
                ))}
              </View>
              <DefaultButton
                loading={loading}
                type={ButtonTypes.Primary}
                action={async () => {
                  if (Platform.OS !== "web")
                    RNReactNativeHapticFeedback.trigger("impactLight");
                  dispatch(setOnlyRole(data[currentIndex].user_type));

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "role_selected",
                    "UserRoleSelectionScreen",
                    data[currentIndex].user_type
                  );
                  if (!authData) {
                    navigation.navigate("EnterLoginDataScreen", {
                      role: data[currentIndex].user_type,
                    });
                  } else {
                    setLoading(true);
                    const response = await sendAuthCodeToServer(
                      authData,
                      navigation,
                      data[currentIndex].user_type
                    );
                    if (response.status === 200) {
                      //TODO: ADD INFORMATION FROM AUTH
                    }
                    setLoading(false);
                  }
                }}
                style={{
                  margin: 16,
                  paddingVertical: 12,
                  marginTop: 32,
                  height: 55,
                }}
                textStyle={{ fontWeight: "bold", fontSize: 16 }}
                icon={<Feather name="arrow-right" size={24} />}
                title={i18n.t("role_selection.confirm_selection")}
              />
            </View>
            {!authData && (
              <View
                style={{
                  flexDirection: "row",
                  alignSelf: "center",
                  gap: 12,
                }}
              >
                <TouchableOpacity
                  style={{
                    padding: 12,
                    paddingHorizontal: 20,
                    borderRadius: 20,
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    backgroundColor: Colors[colorScheme].foreground,
                    alignSelf: "center",
                  }}
                  onPress={() => navigation.navigate("LoginScreen")}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "400",
                      color: Colors[colorScheme].text,
                    }}
                  >
                    {i18n.t("role_selection.already_have_account")}
                  </Text>
                  <Feather name="arrow-up-right" size={20} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    gap: 8,
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  onPress={() =>
                    navigation.navigate("NonMedicalRegistrationScreen")
                  }
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "400",
                      color: Colors[colorScheme].text,
                      textDecorationLine: "underline",
                    }}
                  >
                    {i18n.t("role_selection.not_medical_professional")}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}

const SelectUserView = ({ title, selected, setCurrentIndex, index, image }) => {
  const colorScheme = getColorScheme();

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => setCurrentIndex(index)}
      style={{
        flexDirection: "row",
        flex: 1,
        borderWidth: selected ? 4 : 0,
        borderColor: Colors[colorScheme].accent,
        backgroundColor: Colors[colorScheme].foreground,
        margin: 16,
        marginBottom: 5,
        borderRadius: 20,
        padding: 16,
        alignItems: "center",
      }}
    >
      <Image
        source={image}
        style={{
          height: "90%",
          alignSelf: "flex-end",
          flex: 1,
          marginLeft: -12,
          resizeMode: "contain",
        }}
      />
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "flex-start" }}
      >
        <Text
          style={{
            fontSize: 22,
            fontWeight: "500",
            color: Colors[colorScheme].text,
          }}
        >
          {title}
        </Text>
      </View>
      {selected && (
        <View
          style={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: -20,
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: 48,
              height: 48,
              borderRadius: 35,
              borderWidth: 4,
              backgroundColor: Colors[colorScheme].foreground,
              borderColor: Colors[colorScheme].accent,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ionicons
              name="checkmark"
              color={Colors[colorScheme].accent}
              size={30}
            />
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
};
