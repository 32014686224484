import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useRef, useState } from "react";
import { Platform, ScrollView, TouchableOpacity, View } from "react-native";
import Colors from "../../constants/static-colors";

import { Sizes } from "../../constants/static-sizes";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import getColorScheme from "../../hooks/useColorScheme";
import { Card } from "../../screens/tab-navigator-screens/components/Card";

export const HorizontalCardSlider = ({ data, header = "" }) => {
  const navigation = useNavigation();

  const scrollViewRef = useRef<any>();
  const [scrollEvent, setScrollEvent] = useState<any>(null);

  return (
    <View>
      <ScrollView
        horizontal
        ref={scrollViewRef}
        scrollEventThrottle={16}
        style={{
          ...Platform.select({
            ios: { overflow: "visible" },
            android: { marginHorizontal: -16 },
            web: { marginHorizontal: -Sizes.defaultContainerPadding },
          }),
        }}
        onScroll={(event) => setScrollEvent(event.nativeEvent)}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          flexDirection: "row",
          //  overflow: "visible"
          ...Platform.select({
            ios: { overflow: "visible" },
            android: { paddingHorizontal: 16 },
            web: { paddingHorizontal: Sizes.defaultContainerPadding },
          }),
        }}
      >
        {data.map((item, index) => {
          return (
            <View style={{ paddingRight: 20 }} key={item?._id + index}>
              <Card
                id={item?._id}
                title={item?.title}
                time={item?.timestamp}
                fixedWidth={200}
                sectionHeader={header}
                action={() => {
                  navigateToNextCMSPage(
                    item?._id ?? item?.legacy_id ?? item?.id,
                    navigation,
                    false,
                    item?.additionalData
                  );
                }}
              />
            </View>
          );
        })}
      </ScrollView>
      <ArrowForwardWeb scrollViewRef={scrollViewRef} {...{ scrollEvent }} />
      <ArrowBackWeb scrollViewRef={scrollViewRef} {...{ scrollEvent }} />
    </View>
  );
};

function isCloseToBottom({ layoutMeasurement, contentOffset, contentSize }) {
  return layoutMeasurement.width + contentOffset.x >= contentSize.width - 20;
}

function isCloseToTop({ contentOffset }) {
  return contentOffset.x === 0;
}

const ArrowForwardWeb = ({ scrollViewRef, scrollEvent }) => {
  const colorScheme = getColorScheme();
  if (Platform.OS !== "web") return null;

  function scrollAction() {
    scrollViewRef.current?.scrollTo({
      x: (scrollEvent?.contentOffset.x ?? 0) + 600,
      y: 0,
    });
  }
  if (scrollEvent && isCloseToBottom(scrollEvent)) return null;

  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        right: 12,
      }}
    >
      <TouchableOpacity
        onPress={scrollAction}
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          height: 42,
          borderRadius: 25,
          aspectRatio: 1,
          alignItems: "center",
          justifyContent: "center",
          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.25,
          shadowRadius: 6,
        }}
      >
        <Ionicons
          name="chevron-forward"
          size={24}
          color={Colors[colorScheme].text}
        />
      </TouchableOpacity>
    </View>
  );
};

const ArrowBackWeb = ({ scrollViewRef, scrollEvent }) => {
  const colorScheme = getColorScheme();
  if (Platform.OS !== "web") return null;

  function scrollAction() {
    scrollViewRef.current?.scrollTo({
      x: (scrollEvent?.contentOffset.x ?? 0) - 600,
      y: 0,
    });
  }
  if (!scrollEvent || isCloseToTop(scrollEvent)) return null;

  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        left: 12,
      }}
    >
      <TouchableOpacity
        onPress={scrollAction}
        style={{
          backgroundColor: Colors[colorScheme].foreground,
          height: 42,
          borderRadius: 25,
          aspectRatio: 1,
          alignItems: "center",
          justifyContent: "center",

          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.25,
          shadowRadius: 6,
        }}
      >
        <Ionicons
          name="chevron-back"
          size={24}
          color={Colors[colorScheme].text}
        />
      </TouchableOpacity>
    </View>
  );
};
