import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";

import { SatelliteTile, useData } from "./PartnerDataProvider";
import { SmallPartnerCard } from "./SmallPartnerCard";
import getWidth from "../../hooks/getWidth";
import CarouselComponent from "../CarouselComponent/CarouselComponent";

const chunkArray = (arr: SatelliteTile[], size: number): SatelliteTile[][] => {
  const chunks: any = [];
  for (let i = 0; i < arr.length; i += size) {
    chunks.push(arr.slice(i, i + size));
  }
  return chunks;
};

const SatelliteSection: React.FC = () => {
  const { data, loading } = useData();
  const [, setSatelliteIndex] = useState(0);

  const width = getWidth();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const satelliteTiles = data[dayOfMonth]?.sateliteTile ?? [];

  const groupedSatelliteTiles = chunkArray(satelliteTiles, 4);

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  return (
    <View
      style={{
        gap: 20,
        alignItems: "center",
        borderBottomWidth: 0.3,
        borderBottomColor: "#bbb",
        paddingBottom: 32,
        // paddingTop: 32,
        // borderTopWidth: 0.3,
        // borderTopColor: "#bbb",
      }}
    >
      <CarouselComponent
        data={groupedSatelliteTiles}
        containerCustomStyle={{ overflow: "visible" }}
        renderItem={({ item }) => (
          <View
            style={{
              flexDirection: "column",
              //flexWrap: "column",
              justifyContent: "space-between",
              width: "100%", // Ensure grid takes full width
              gap: 12,
            }}
          >
            {item.map((gridItem, index) => (
              <SmallPartnerCard key={index} item={gridItem} />
            ))}
          </View>
        )}
        sliderWidth={width}
        itemWidth={Math.min(
          groupedSatelliteTiles.length === 1 ? width * 0.85 : width * 0.7,
          1000
        )} // Limits max width for consistent display
        autoplay
        autoplayDelay={2000}
        autoplayInterval={8000}
        loop
        vertical={false}
        onSnapToItem={(index) => setSatelliteIndex(index)}
      />
    </View>
  );
};

export default SatelliteSection;
