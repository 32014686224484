import { useEffect, useMemo, useState } from "react";
import { Text, View } from "react-native";
import { useSelector } from "react-redux";
import { colors } from "../constants/static-colors";

import ImageWrapper from "../components/ImageWrapper";
import { getIsOffline } from "../components/search-tab/SearchResults";
import { selectEnterprise } from "../functions/enterprise/actions";
import getFontStyle from "../functions/getFontSize";
import { selectCurrentUser } from "../functions/user/actions";

export const UserIcon = ({
  style = {},

  size = 46,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const [isOffline, setIsOffline] = useState(false);

  const prefectProfilePicture = async (url) => {
    try {
      const response = await fetch(url, { method: "HEAD" });
      if (response.ok) {
        return true; // URL is valid
      }
      throw new Error("PDF is not accessible");
    } catch (error) {
      console.error("Error fetching PDF:", error);
      return false; // URL is not valid
    }
  };

  useEffect(() => {
    async function load() {
      const _isOffline = await getIsOffline();
      const _prefectProfilePicture = await prefectProfilePicture(
        currentUser?.profile_picture
      );
      setIsOffline(_isOffline || !_prefectProfilePicture);
    }
    load();
  }, []);

  return useMemo(
    () => (
      <View
        style={{
          width: size,
          height: size,
          borderWidth: 1,
          borderColor: colors.ui.border,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: size * 2,
          shadowOpacity: 0.1,
          overflow: "hidden",
          shadowOffset: { width: 0, height: 0 },
          shadowColor: "black",
          shadowRadius: 4,
          elevation: 1,
          backgroundColor: colors.brand.greys.gE5E5E5,
          ...style,
        }}
      >
        {currentUser?.profile_picture && !isOffline ? (
          <ImageWrapper
            style={{
              width: "100%",
              height: "100%",
              resizeMode: "contain",
              borderRadius: 46,
            }}
            source={{ uri: currentUser?.profile_picture }}
          />
        ) : (
          <EmptyProfile {...{ currentUser, size }} />
        )}
      </View>
    ),
    [size, currentUser, style]
  );
};

const EmptyProfile = ({ currentUser, style = {}, size }) => {
  const selectedEnterprise = useSelector(selectEnterprise);
  return (
    <View
      style={{
        backgroundColor: colors.ui.cardBackground,
        borderRadius: 100,
        width: "100%",
        height: "100%",
        aspectRatio: 1,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <Text
        style={{
          fontSize: size / 3,
          ...getFontStyle(500),
          opacity: 0.75,
          color: colors.ui.textPrimary,
        }}
      >
        {!currentUser?.firstname
          ? selectedEnterprise?.customer_name?.slice(0, 1)
          : (currentUser?.firstname ?? "G").slice(0, 1) +
            (currentUser?.lastname ?? "M").slice(0, 1)}
      </Text>
    </View>
  );
};
