import LargeStandardBox from "../LargeStandardBox";
import { InlineImage } from "../inline-image-types";

interface FirstResultProps {
  readonly title: string;
  readonly expandableContent: string | undefined;
  readonly inlineImage: InlineImage | undefined;
  readonly insertedContent?: any;
}

export default function ActionRecommendationBox(props: FirstResultProps) {
  return (
    <LargeStandardBox
      title={props.title}
      content={""}
      expandableContent={props.expandableContent}
      inlineImage={props.inlineImage}
      boxType="action"
      insertedContent={props.insertedContent}
    />
  );
}
