import { Text, View } from "react-native";

export default function SlideOne() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <Text
        style={{
          fontSize: 54,
          fontWeight: "500",
        }}
      >
        Dein neuer zuverlässiger Begleiter in der Klinik.
      </Text>
    </View>
  );
}
