import { Animated, StyleSheet, Text, View } from "react-native";
import { colors } from "../../../constants/static-colors";

interface ProgressIndicatorProps {
  isComplete: boolean;
  headerOpacityAnim: Animated.Value;
  currentQuestionNumber: number;
  maxPathLength: number;
  title: string;
}

export const ProgressIndicator = ({
  isComplete,
  headerOpacityAnim,
  currentQuestionNumber,
  maxPathLength,
  title,
}: ProgressIndicatorProps) => {
  return (
    <Animated.View
      style={{
        opacity: headerOpacityAnim,
      }}
    >
      {/* Progress indicator - only show when not complete */}
      {!isComplete && (
        <View style={styles.progressContainer}>
          <Text style={styles.progressText}>
            Schritt {currentQuestionNumber}/{maxPathLength}
          </Text>
        </View>
      )}

      {/* Heading from pageContent title - only show when not complete */}
      {!isComplete && <Text style={styles.heading}>{title}</Text>}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    alignItems: "flex-start",
    marginBottom: 10,
  },
  progressText: {
    fontSize: 16,
    fontWeight: "400",
    color: colors.ui.textSecondary,
  },
  heading: {
    fontSize: 24,
    fontWeight: "500",
    marginBottom: 30,
    color: colors.ui.textPrimary,
    fontFamily: "HostGrotesk-Regular",
  },
});
