import { Feather } from "@expo/vector-icons";
import { useRef, useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import ActionSheet from "react-native-actionsheet";
import { useSelector } from "react-redux";
import { UserIcon } from "../../../components/UserIconComponent";
import { colors } from "../../../constants/static-colors";
import { selectEnterprise } from "../../../functions/enterprise/actions";
import getFontStyle from "../../../functions/getFontSize";
import {
  deleteProfileImage,
  getImageFromLibrary,
  uploadProfileImage,
} from "../../../functions/profile/functions";
import { User } from "../../../functions/user/types";

// Beim Klick auf "Neu aus Galerie" aufrufen
async function selectNewProfilePicture(
  currentUser: User,
  setIsUploading: (val: boolean) => void
) {
  setIsUploading(true);
  try {
    const image = await getImageFromLibrary();
    if (image !== null) {
      await uploadProfileImage(image, currentUser);
    }
  } catch (error) {
    console.log("Fehler beim Auswählen eines neuen Profilbilds:", error);
  } finally {
    // Egal ob Erfolg oder Error -> wieder false
    setIsUploading(false);
  }
}

export function getDisplayName(currentUser: User, selectedEnterprise: any) {
  if (currentUser?.firstname) {
    return (
      getTitle(currentUser) +
      currentUser.firstname?.trim() +
      " " +
      currentUser.lastname?.trim()
    );
  } else {
    return selectedEnterprise?.customer_name;
  }
}

export default function UserComponent({ currentUser }: { currentUser: User }) {
  const selectedEnterprise = useSelector(selectEnterprise);

  return (
    <View style={styles.container}>
      <UserIconWithEdit currentUser={currentUser} />
      <View style={{ marginLeft: 20, flex: 1 }}>
        <Text
          style={{
            fontSize: 24,
            ...getFontStyle(500),
            color: colors.ui.textPrimary,
          }}
        >
          {getDisplayName(currentUser, selectedEnterprise)}
        </Text>
      </View>
    </View>
  );
}

export const UserIconWithEdit = ({
  currentUser,
  size = 92,
}: {
  currentUser: User;
  size?: number;
}) => {
  const actionSheet = useRef<any>();

  // State für Upload
  const [isUploading, setIsUploading] = useState(false);

  const pickerOptions = ["Neu aus Galerie", "Löschen", "Abbrechen"];

  const showActionSheet = () => {
    actionSheet.current?.show();
  };

  const handlePressOption = async (index: number) => {
    if (index === 0) {
      // "Neu aus Galerie"
      await selectNewProfilePicture(currentUser, setIsUploading);
    } else if (index === 1) {
      // "Löschen"
      setIsUploading(true);
      try {
        await deleteProfileImage();
      } catch (error) {
        console.log("Fehler beim Löschen:", error);
      } finally {
        setIsUploading(false);
      }
    }
    // Index === 2 => Abbrechen, kein weiterer Code
  };

  return (
    <>
      <View style={{ position: "relative" }}>
        {/* UserIcon oder wahlweise Loading-Spinner */}
        <UserIcon size={size} />

        {/* Kleiner Kamera-Button */}
        <TouchableOpacity
          hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
          style={styles.cameraButton}
          onPress={showActionSheet}
          disabled={isUploading}
        >
          <Feather name="camera" size={17} color={colors.ui.textPrimary} />
        </TouchableOpacity>

        {/* Wenn isUploading true ist, Overlay + Spinner anzeigen */}
        {isUploading && (
          <View style={styles.loadingOverlay}>
            <ActivityIndicator size="small" color="#fff" />
          </View>
        )}
      </View>

      <ActionSheet
        ref={actionSheet}
        title={"Profilbild bearbeiten"}
        options={pickerOptions}
        cancelButtonIndex={2}
        destructiveButtonIndex={1}
        onPress={(index) => handlePressOption(index)}
      />
    </>
  );
};

export function getTitle(currentUser: User) {
  if (!currentUser?.title) return "";
  return currentUser.title + " ";
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
    margin: 16,
    marginVertical: 8,
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: colors.ui.cardBackground,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cameraButton: {
    padding: 7,
    aspectRatio: 1,
    backgroundColor: colors.ui.background,
    borderWidth: 1,
    borderColor: "gray",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    borderRadius: 40,
    bottom: 0,
    right: 0,
    zIndex: 10,
  },
  loadingOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0,0,0,0.4)",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 999, // damit Overlay rund bleibt, wenn Icon rund ist
  },
});
