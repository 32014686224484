import { debounce } from "lodash";
import {
  Keyboard,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import Ionicons from "@expo/vector-icons/Ionicons";
import { useCallback, useState } from "react";
import { Sizes } from "../../constants/static-sizes";

import { useNavigation } from "@react-navigation/native";
import * as sha512 from "js-sha512";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useDispatch } from "react-redux";
import i18n from "../../../locales/i18n";
import apiCall from "../../api/api-call";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";
import { colors } from "../../constants/static-colors";
import { showInlineNotification } from "../../entry/Root";
import getFontStyle from "../../functions/getFontSize";
import { setCurrentUser } from "../../functions/user/actions";
import getUUID from "../../hooks/getUUID";
import { AgreeTermsView } from "./registry/EnterLoginData";

export default function NonMedicalRegistrationScreen() {
  const navigation = useNavigation();

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [registrationReason, setRegistrationReason] = useState<string>("");
  const [pwVisible, setPwVisible] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const dispatch = useDispatch();

  const isReady = () => {
    return (
      email.length > 5 &&
      password.length > 5 &&
      firstName &&
      lastName &&
      registrationReason
    );
  };

  const debouncedSetEmail = useCallback(debounce(setEmail, 100), []);

  async function nonMedicalUserRegistryCall() {
    Keyboard.dismiss();
    setIsWaiting(true);

    const hashedPassword = sha512.sha512(password);
    const body = {
      email: email,
      password: hashedPassword,
      firstname: firstName,
      lastname: lastName,
      reason: registrationReason,
      user_id: getUUID(),
    };

    const response = await apiCall(
      "/api/v4/users/non-medical",
      body,
      false,
      true,
      "POST"
    );

    dispatch(
      setCurrentUser({
        email: email,
        password: hashedPassword,
      })
    );

    setIsWaiting(false);

    if (response.status === 200) {
      navigation.navigate("WaitingForRegistrationScreen", {
        isNonMedicalUser: true,
      });
    } else {
      showInlineNotification({
        text: response.message ?? "Ein Fehler ist aufgetreten",
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAwareScrollView
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ alignItems: "center", flexGrow: 1 }}
      >
        <View style={styles.innerContainer}>
          <Text style={styles.heading}>
            {i18n.t("non_medical_user.request_access")}
          </Text>
          <Text style={styles.subHeading}>
            {i18n.locale === "en"
              ? i18n.t("non_medical_user.no_medical_user_subheading")
              : "Bitte fülle die folgenden Felder aus, um einen Zugang als nicht-medizinischer Benutzer anzufordern. Jede Registrierung wird überprüft und manuell vom LANDO-Team freigegeben. Wir informieren dich über E-Mail, sobald dein Zugang aktiviert wurde."}
          </Text>
          <View style={styles.textInputContainer}>
            <TextInput
              key={"firstName"}
              placeholder={i18n.t("first_name")}
              placeholderTextColor={colors.ui.textPlaceholder}
              style={styles.textField}
              onChangeText={(text) => setFirstName(text)}
            />
            <TextInput
              key={"lastName"}
              placeholder={i18n.t("last_name")}
              placeholderTextColor={colors.ui.textPlaceholder}
              style={styles.textField}
              onChangeText={(text) => setLastName(text)}
            />
            <TextInput
              textContentType={"username"}
              key={"email"}
              placeholder={"E-Mail"}
              placeholderTextColor={colors.ui.textPlaceholder}
              style={styles.textField}
              keyboardType="email-address"
              onChangeText={(text) => debouncedSetEmail(text)}
            />
            <View style={{ width: "100%", maxWidth: Sizes.containerWidth }}>
              <TextInput
                placeholder={"Passwort"}
                textContentType={"password"}
                key={"password"}
                autoComplete="password"
                placeholderTextColor={colors.ui.textPlaceholder}
                style={[styles.textField, { width: "100%" }]}
                secureTextEntry={pwVisible}
                onChangeText={(text) => {
                  setPassword(text);
                }}
              />
              <TouchableOpacity
                style={{ position: "absolute", right: 20, top: 11 }}
                onPress={() => setPwVisible(!pwVisible)}
              >
                <Ionicons
                  name={pwVisible ? "eye-outline" : "eye-off-outline"}
                  size={24}
                  color={colors.ui.textPrimary}
                />
              </TouchableOpacity>
            </View>
            <TextInput
              key={"registrationReason"}
              placeholder={i18n.t("non_medical_user.why_you_wanna_register")}
              placeholderTextColor={colors.ui.textPlaceholder}
              style={[styles.textField, styles.textArea]}
              multiline
              numberOfLines={4}
              onChangeText={(text) => setRegistrationReason(text)}
            />
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: Sizes.containerWidth,
                  marginTop: 30,
                }}
              >
                <DefaultButton
                  textStyle={{ ...getFontStyle(700), fontSize: 16 }}
                  type={ButtonTypes.Primary}
                  title={i18n.t("register")}
                  loading={isWaiting}
                  enabled={!!isReady()}
                  style={{ height: 50 }}
                  action={nonMedicalUserRegistryCall}
                  icon={<Ionicons name="arrow-forward" size={24} />}
                />
              </View>
            </View>
          </View>
        </View>
        <AgreeTermsView
          style={{
            marginHorizontal: 32,
            alignSelf: "center",
            position: "relative",
          }}
        />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    backgroundColor: colors.ui.background,
  },
  textInputContainer: {
    width: "100%",
    alignItems: "center",
  },
  innerContainer: {
    width: "100%",
    paddingTop: 32,

    flex: 1,
    alignItems: "center",
    padding: Sizes.defaultContainerPadding,
    justifyContent: "center",
  },
  textField: {
    backgroundColor: colors.ui.inputBackground,
    color: colors.ui.textPrimary,
    borderRadius: 12,
    width: "100%",
    height: 50,
    padding: 12,
    marginBottom: 12,
    maxWidth: Sizes.containerWidth,
    fontFamily: "HostGrotesk-Regular",
  },
  textArea: {
    height: 100,
    textAlignVertical: "top",
  },
  heading: {
    fontSize: 24,
    ...getFontStyle(700),
    color: colors.ui.textPrimary,
    marginBottom: 16,
  },
  subHeading: {
    fontSize: Sizes.boxText,
    color: colors.ui.textPrimary,
    marginBottom: 24,
    textAlign: "center",
    paddingHorizontal: 16,
  },
});
