import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  SafeAreaView,
  StyleSheet,
  StatusBar,
  Linking,
  Platform,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { DefaultButton, ButtonTypes } from "../components/DefaultButton"; // Ensure this path matches your button component
import i18n from "../../locales/i18n";

const UpdatePopoverScreen = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.fullScreenContainer}>
      <StatusBar barStyle="dark-content" />

      <View style={styles.fullScreenContent}>
        <TouchableOpacity
          style={styles.closeButton}
          onPress={() => navigation.goBack()}
        >
          <Feather name="x" size={30} color="#333" />
        </TouchableOpacity>

        <Text style={styles.title}>{i18n.t("new_update_available")}</Text>
        <Text style={styles.message}>
          {i18n.locale == "en"
            ? i18n.t("new_update_available_description")
            : "Es steht ein neues Update zur Verfügung, das neue Funktionen und Verbesserungen enthält. Wir empfehlen, das Update zu installieren, um die App optimal nutzen zu können."}
        </Text>

        <View style={styles.buttonContainer}>
          <DefaultButton
            style={styles.button}
            type={ButtonTypes.Primary}
            title={i18n.t("update_now")}
            action={() => {
              Linking.openURL(
                Platform.OS === "android"
                  ? "https://play.google.com/store/apps/details?id=com.mediceo.app&pli=1"
                  : "https://apps.apple.com/de/app/mediceo/id1629718911"
              );
            }}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  fullScreenContainer: {
    flex: 1,
    backgroundColor: "white",
  },
  fullScreenContent: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
  },
  closeButton: {
    position: "absolute",
    top: 20,
    right: 20,
  },
  title: {
    fontSize: 22,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  message: {
    fontSize: 16,
    color: "#666",
    textAlign: "center",
    marginBottom: 30, // Increased margin for better spacing
  },
  buttonContainer: {
    width: "100%",
    paddingHorizontal: 20,
  },
  button: {
    marginBottom: 10,
  },
});

export default UpdatePopoverScreen;
