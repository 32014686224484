import * as FileSystem from "expo-file-system";

const OFFLINE_DATA_PATH = `${FileSystem.documentDirectory}offlineData.json`;

export const saveDataToFile = async (data) => {
  try {
    console.log("Starting to save data to file...");
    const jsonData = JSON.stringify(data);
    //console.log("Data stringified:", jsonData);
    await FileSystem.writeAsStringAsync(OFFLINE_DATA_PATH, jsonData, {
      encoding: FileSystem.EncodingType.UTF8,
    });
    console.log("Data saved to file successfully");
  } catch (error) {
    console.error("Error saving data to file:", error);
  }
};

export const loadDataFromFile = async () => {
  try {
    console.log("Starting to load data from file...");
    const fileExists = await FileSystem.getInfoAsync(OFFLINE_DATA_PATH);
    console.log("File exists:", fileExists.exists);

    if (!fileExists.exists) {
      console.log("No offline data file found");
      return null;
    }

    const jsonData = await FileSystem.readAsStringAsync(OFFLINE_DATA_PATH, {
      encoding: FileSystem.EncodingType.UTF8,
    });
    //console.log("Data read from file:", jsonData);

    const parsedData = JSON.parse(jsonData);
    //console.log("Data parsed:", parsedData);
    console.log("Data loaded from file successfully");
    return parsedData;
  } catch (error) {
    console.error("Error loading data from file:", error);
    return null;
  }
};
