import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import AsyncStorage from "@react-native-async-storage/async-storage";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getFontStyle from "../../../functions/getFontSize";
import getWidth from "../../../hooks/getWidth";
import isLargerDevice from "../../../hooks/isLargerDevice";
import { SignInWithGoogleButton } from "./components/GoogleSignInButton";
import { SignInWithAppleButton } from "./components/AppleSignInButton";

export const CustomSignInButton = ({ provider, text, onPress, loading }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={loading}
      style={{
        opacity: loading ? 0.5 : 1,
        alignSelf: "stretch",
        borderWidth: 2,
        borderColor: colors.ui.textPrimary,
        borderRadius: 30,
        padding: 16,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          left: 20,
          position: "absolute",
        }}
      >
        {provider}
      </View>
      <Text
        style={{
          fontSize: 16,
          marginLeft: 8,
          color: colors.ui.textPrimary,
          ...getFontStyle(700),
          alignSelf: "center",
        }}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default function LandingScreen() {
  const navigation = useNavigation();
  // Record user interaction when screen opens
  useEffect(() => {
    AsyncStorage.setItem("user", "null");
    AsyncStorage.setItem("selectedEnterprise", "null");
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "landing_screen_opened",
      "LandingScreen",
      "UserOpened"
    );
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: colors.ui.background,
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      {/* Logo and Intro Section */}
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          marginVertical: 20,
        }}
      >
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            position: "absolute",
            top: isLargerDevice() ? 32 : 12,
            left: 0,
            right: 0,
            alignItems: "center",
          }}
        >
          <View
            style={{
              maxWidth: 700,
              width: "100%",
              flex: 1,
              alignItems: "flex-start",
              paddingHorizontal: Sizes.defaultContainerPadding,
            }}
          >
            <Text style={{ fontFamily: "HostGrotesk-Regular", fontSize: 24 }}>
              {i18n.t("welcome_to")}
            </Text>
            <View
              style={{
                aspectRatio: 3,
                width: Math.min(getWidth() * 0.7, 400),
              }}
            >
              <Image
                source={require("../../../../assets/logo/main/main_dark.png")}
                style={{
                  height: "100%",
                  resizeMode: "contain",
                  width: "100%",
                }}
              />
            </View>
          </View>
        </View>
        {/* <Image
          source={require("../../../../assets/images/landing_m.png")}
          style={{
            width: "200%",
            resizeMode: "contain",
            height: "50%",
            top: 100,
            right: 75,
            transform: [{ rotate: "14deg" }],
          }}
        /> */}
      </View>
      {/* Button Section */}
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            justifyContent: "center",
            padding: Sizes.defaultContainerPadding,
            width: "100%",
            maxWidth: 700,
          }}
        >
          {Platform.OS !== 'web' && (
            <View
              style={{
                gap: 12,
                marginBottom: 20,
              }}
            >
              <SignInWithGoogleButton />
              {Platform.OS === 'ios' && <SignInWithAppleButton />}
            </View>
          )}

          <DefaultButton
            textStyle={{ ...getFontStyle(700), fontSize: 16 }}
            style={{ height: 55, marginTop: -4 }}
            title={i18n.t("register_email")}
            type={ButtonTypes.Primary}
            action={() => navigation.navigate("RoleSelectionScreen")}
          />
          <DefaultButton
            textStyle={{ ...getFontStyle(700), fontSize: 16 }}
            style={{ height: 55, marginTop: 16 }}
            title={i18n.t("log_in")}
            type={ButtonTypes.Secondary}
            action={() => navigation.navigate("LoginScreen")}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}
