import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity, View } from "react-native";
import Colors from "../../../../constants/static-colors";
import { pSBC } from "../../../../functions/color-parser";
import { navigateToNextPageByCMSId } from "../../../../functions/navigation/helpers";
import getColorScheme from "../../../../hooks/useColorScheme";
import DefaultGradientBackground from "../../../DefaultGradientBackground";
import { BoxTextComponent } from "../../../custom-box-text/CustomBoxTextComponent";
import { getSharedStylesLargeContent } from "../../SharedStyles";

export function InfoBox({ title, color, id }) {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();
  const parsedColor =
    pSBC(colorScheme === "light" ? 0.7 : -0.915, color) ?? "black";
  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        backgroundColor: parsedColor,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigateToNextPageByCMSId(navigation, id, title, true);
        }}
        activeOpacity={0.7}
      >
        <View
          style={{
            borderRadius: 10,
            borderColor: color,
            overflow: "hidden",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <InfoIcon />
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingVertical: 16,
                paddingRight: 16,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  width: "90%",
                  alignItems: "center",
                }}
              >
                <BoxTextComponent
                  content={"<p>" + title + "</p>"}
                  style={getSharedStylesLargeContent(colorScheme)}
                />
              </View>

              <Feather
                name="chevron-right"
                size={24}
                style={{ marginLeft: 12 }}
                color={Colors[colorScheme].text}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const InfoIcon = () => {
  return (
    <View
      style={{
        borderRadius: 100,
        overflow: "hidden",
        marginRight: 12,
        aspectRatio: 1,
        height: 32,
        marginLeft: 16,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DefaultGradientBackground />
      <Ionicons
        name="information-circle-outline"
        size={20}
        style={{ marginLeft: 2, marginTop: 1 }}
      />
    </View>
  );
};
