import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Text, View } from "react-native";
import I18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";

export default function WarningModal({ open, onClose }) {
  const text =
    "Die Dosierungen und Behandlungsempfehlungen sind allgemeine Empfehlungen aus aktuellen Leitlinien und peer-reviewed Quellen und können unter gewissen Umständen abweichen. Bitte beachte individuelle Anpassungen für deine Patient:innen.\n\nDie Dosierungshilfen dienen als Instrument zur Berechnung und stellen keine umfassenden oder endgültigen Informationen über das Medikament und die zu applizierende Dosis. Überprüfe die Dosierung jedes Medikaments und konsultiere einen weiteren Arzt, wenn nötig.";

  const navigation = useNavigation();

  useEffect(() => {
    navigation.addListener("focus", () => {
      if (open)
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "warning_modal_presented",
          "WarningModal",
          "presented"
        );
    });
  }, []);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Feather
            name="alert-triangle"
            size={150}
            color={colors.semantic.error}
            style={{
              alignSelf: "center",
              opacity: 0.08,
            }}
          />
        </View>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("attention")}
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            fontFamily: "HostGrotesk-Regular",
            color: colors.ui.textPrimary,
          }}
        >
          {text}
        </Text>
        <DefaultButton
          type={ButtonTypes.Primary}
          action={onClose}
          style={{ marginTop: 20 }}
          title={I18n.t("close")}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
