import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useMemo } from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import i18n from "../../../../../locales/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import SectionHeaderText from "../../../../components/SectionHeaderText";
import { colors } from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getFontStyle from "../../../../functions/getFontSize";
import { useSelector } from "react-redux";
import { selectAllContent } from "../../../../functions/data/actions";
import { getIdToSelection } from "../../Search/components/ContentListComponent";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import { getIconToTitle } from "../../../../components/CategoryIcon";

export const HotFeaturesComponent = () => {
  const navigation = useNavigation();

  const medicalSubjectAreas =
    useSelector(selectAllContent).contentList?.content?.medicalSubjectAreaItems;

  const HotFeatureItem = ({ icon, text, action }: { icon; text; action? }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "hot_feature_selected",
            "HotFeaturesComponent",
            "UserPressedHotFeature",
            { feature: text }
          );
          if (action) action();
        }}
        style={{
          gap: 8,
          marginTop: 12,
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: 16,
          paddingVertical: 12,
          borderRadius: 12,
          backgroundColor: colors.ui.cardBackground,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: colors.brand.greys.gE5E5E5,
            padding: 10,
          }}
        >
          <Feather name={icon} size={20} color="black" />
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText + 1,
            ...getFontStyle(500),
            color: colors.ui.textPrimary,
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  };

  return useMemo(
    () => (
      <View>
        <SectionHeaderText>{i18n.t("hot_features")}</SectionHeaderText>
        {/* <View> */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <HotFeatureItem
            icon={"users"}
            text={"Patienten"}
            action={() => {
              navigation.navigate("PatientsScreen");
            }}
          />
          <HotFeatureItem
            icon={"heart"}
            text={i18n.t("favorites")}
            action={() => navigation.navigate("FavoritesScreen")}
          />
          <HotFeatureItem
            icon={getIconToTitle("Scores")}
            text={"Scores"}
            action={() => {
              const id = getIdToSelection(medicalSubjectAreas, "Scores");

              navigateToNextCMSPage(id, navigation);
            }}
          />
        </View>
      </View>
    ),
    []
  );
};
