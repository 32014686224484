import LottieView from "lottie-react-native";
import React, { useCallback, useEffect } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../../functions/user-interaction-handler";
import StreakModal, {
  getLastConsecutiveStreakIfYesterday,
} from "../../modals/StreakModal";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../../constants/static-colors";
import getFontStyle from "../../../functions/getFontSize";

// Actions / Selectors
import {
  selectCurrentStreakDays,
  selectShowLottie,
  selectShowStreakModal,
  selectWhichAnimation,
  setCurrentStreakDays,
  setShowLottie,
  setShowStreakModal,
  setWhichAnimation,
} from "../../../functions/streak/actions";

const StreakIndicator: React.FC = React.memo(() => {
  // Redux-State abrufen

  const showStreakModal = useSelector(selectShowStreakModal);
  // Falls du flameActive in Redux hältst, könntest du es hier abrufen:
  // const flameActive = useSelector(selectFlameActive);

  const dispatch = useDispatch();

  /**
   * useEffect: Beim ersten Rendern (Mount) laden wir die Daten.
   * Sofern du *nicht* möchtest, dass bei jedem Klick erneut
   * 'loadStreakData' ausgeführt wird, sollte es nur hier
   * (oder an anderen bewusst gewählten Stellen) aufgerufen werden.
   */

  /**
   * Render-Teil: Hier zeigen wir entweder eine Lottie-Animation
   * oder ein statisches Flammen-Icon an.
   */
  return (
    <>
      {/* Dein Streak-Modal */}
      <StreakModal
        open={showStreakModal}
        onClose={() => {
          dispatch(setShowStreakModal(false));
        }}
      />
      <StreakIcon />
    </>
  );
});

const StreakIcon: React.FC = React.memo(() => {
  const currentStreakDays = useSelector(selectCurrentStreakDays);

  const showLottie = useSelector(selectShowLottie);
  const whichAnimation = useSelector(selectWhichAnimation);
  const dispatch = useDispatch();

  /**
   * Diese Funktion lädt alle relevanten Daten:
   * - Aktuellen Streak (aus streakKey)
   * - Animation-Flags (aus animationFlagsKey)
   * - Entscheidet aufgrund der aktuellen Logik, ob wir heute animieren sollen
   * - Updated Redux-State und speichert die geänderten Flags wieder ab
   */
  const loadStreakData = useCallback(async () => {
    try {
      const streakKey = "streak_data";
      const animationFlagsKey = "streak_animation_flags";

      // 1) Streak aus dem Storage holen
      let storedData = getValueFromInteractions(streakKey) || "{}";
      while (typeof storedData === "string") {
        storedData = JSON.parse(storedData);
      }

      // Bestimme den aktuellen Streak z.B. mithilfe der Funktion getLastConsecutiveStreakIfYesterday
      const newCount = getLastConsecutiveStreakIfYesterday(storedData);

      // Speichere das in Redux
      dispatch(setCurrentStreakDays(newCount));

      // Logge es (Analytics, optional)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "streak_indicator",
        "streak_indicator",
        { currentStreakDays: newCount }
      );

      // 2) Flags für Animation aus dem Storage laden
      let flagsData = getValueFromInteractions(animationFlagsKey) || "{}";
      while (typeof flagsData === "string") {
        flagsData = JSON.parse(flagsData);
      }
      // Fallback, falls undefiniert
      if (!flagsData) flagsData = {};

      // Standardwerte setzen, falls nicht vorhanden
      if (typeof flagsData.firstEverDayOne === "undefined") {
        flagsData.firstEverDayOne = false;
      }
      if (typeof flagsData.flameActive === "undefined") {
        flagsData.flameActive = false;
      }
      if (!flagsData.lastAnimationDate) {
        flagsData.lastAnimationDate = null;
      }

      // 3) Prüfen, ob wir heute schon animiert haben
      const todayStr = new Date().toISOString().split("T")[0];
      const hasAnimatedToday = flagsData.lastAnimationDate === todayStr;
      // 4) Logik für Animation
      let showAnimation = false;
      let animationType: "flame" | "flame_off" | null = null;

      if (newCount === 1) {
        // Tag 1
        if (!flagsData.firstEverDayOne) {
          // → Das ist der erste Tag überhaupt
          if (!hasAnimatedToday) {
            showAnimation = true;
            animationType = "flame"; // Start-Animation (flame on)
            flagsData.lastAnimationDate = todayStr;
          }
          // Markiere, dass wir schon mal einen "ersten Tag" hatten
          flagsData.firstEverDayOne = true;
          // Flamme an, da es jetzt das "allererste Mal" Tag 1 ist
          flagsData.flameActive = true;
        } else {
          // → Tag 1 nach Abbruch
          if (!hasAnimatedToday) {
            showAnimation = true;
            animationType = "flame_off"; // Flamme aus
            flagsData.lastAnimationDate = todayStr;
          }
          // Nach Abbruch definierst du: Flamme aus
          flagsData.flameActive = false;
        }
      } else if (newCount === 2) {
        // Tag 2
        if (!hasAnimatedToday) {
          showAnimation = true;
          animationType = "flame"; // Flamme an
          flagsData.lastAnimationDate = todayStr;
        }
        flagsData.flameActive = true;
      } else {
        // Tag >= 3
        // Keine Animation mehr, aber Flamme bleibt an, wenn newCount >= 2
        flagsData.flameActive = newCount >= 2;
      }

      // 5) Redux-States updaten
      dispatch(setShowLottie(showAnimation));
      dispatch(setWhichAnimation(animationType));

      // Falls du flameActive in Redux verwaltest (z.B. setFlameActive):
      // dispatch(setFlameActive(flagsData.flameActive));

      // 6) Flags in den Storage zurückschreiben
      await addValueToInteractions(animationFlagsKey, flagsData);
    } catch (err) {
      console.warn("Error reading streak or animation data:", err);
    }
  }, [dispatch]);

  useEffect(() => {
    loadStreakData();
  }, [loadStreakData]);

  return (
    <TouchableOpacity
      // ACHTUNG: Prüfe, ob hier NICHT loadStreakData aufgerufen wird.
      // Du willst möglicherweise NUR das Modal öffnen, nicht erneut
      // die Streak-/Animationslogik durchführen.
      onPress={() => {
        dispatch(setShowStreakModal(true));
      }}
      activeOpacity={0.7}
    >
      {/* Falls Tag > 1, zeige den Zähler */}
      {currentStreakDays > 1 && (
        <Text
          style={{
            color: colors.ui.cardBackground,
            fontSize: 36,
            opacity: 0.5,
            position: "absolute",
            right: 36,
            zIndex: 1,
            top: showLottie ? 13 : 2,
            ...getFontStyle(700),
          }}
        >
          {currentStreakDays}
        </Text>
      )}

      {/* Hauptansicht: Lottie vs. statisches Icon */}
      <View style={{ zIndex: 12 }}>
        {/* Zeige Animation (flame oder flame_off), wenn showLottie === true */}
        {showLottie && whichAnimation === "flame" && (
          <LottieView
            source={require("../../../../assets/animations/flame.json")}
            autoPlay
            loop={false}
            style={{ width: 72, height: 72, marginRight: -12 }}
          />
        )}
        {showLottie && whichAnimation === "flame_off" && (
          <LottieView
            source={require("../../../../assets/animations/flame_off.json")}
            autoPlay
            loop={false}
            style={{ width: 60, height: 60, marginRight: -6 }}
          />
        )}

        {/* Falls keine Animation angezeigt wird, zeige statisches Icon */}
        {!showLottie && (
          <View>
            {currentStreakDays >= 2 ? (
              <Image
                source={require("../../../../assets/images/streaks/active.png")}
                style={{ width: 48, height: 48 }}
              />
            ) : (
              <Image
                source={require("../../../../assets/images/streaks/inactive.png")}
                style={{ width: 48, height: 48 }}
              />
            )}
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
});

StreakIcon.displayName = "StreakIcon";

StreakIndicator.displayName = "StreakIndicator";

export default StreakIndicator;
