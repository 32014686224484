import apiCall, { getURL } from "../../../../api/api-call";
import { store } from "../../../../functions/store";
import {
  setRefreshToken,
  setSessionToken,
} from "../../../../functions/user/actions";
import { getOfflineUser, setUser } from "../../../../functions/user/functions";
import { continueWithUser } from "../../LoginScreen";
import Keychain from "react-native-keychain";

export async function authenticateWithProvider(payload, navigation, provider) {
  try {
    console.log("PAYLOAD", payload);

    // API-Endpunkt dynamisch je nach Anbieter
    const endpoint =
      provider === "google" ? "/api/v4/auth/google" : "/api/v4/auth/apple";
    const response = await apiCall(endpoint, payload);

    console.log("response", response);

    if (response?.data) {
      const _response = await setUser(response.data);
      if (_response.valid) {
        if (response?.data?.user_type === "oauth") {
          navigation.navigate("RoleSelectionScreen", { authData: true });
        } else {
          continueWithUser(
            _response.userData,
            _response.valid,
            false,
            response.data.email,
            "",
            navigation,
            true,
            response.data.auth
          );
        }
      }
    }

    if (response?.data?.access_token && response?.data?.refresh_token) {
      await store.dispatch(
        storeToken(response.data.access_token, response?.data.refresh_token)
      );
    }

    return response;
  } catch (error) {
    console.error(`${provider} Authentication failed`, error);
    return null;
  }
}

/*export async function handleAppleRedirect(navigation) {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("id_token");
  console.log(token)
  const authorizationCode = urlParams.get("code");
  const idToken = urlParams.get("id_token");
  const user = urlParams.get("user");

  if (!authorizationCode) {
    console.error("Kein Authorization Code gefunden!");
    return;
  }

  let userData = {};
  if (user) {
    try {
      userData = JSON.parse(decodeURIComponent(user));
      console.log(user)
    } catch (e) {
      console.error("Fehler beim Parsen der User-Daten:", e);
    }
  }

  const payload = {
    authorizationCode,
    email: userData.email || "",
    fullName: {
      familyName: "",
      givenName: "",
      middleName: "",
      namePrefix: "",
      nameSuffix: "",
      nickname: "",
    },
    identityToken: idToken || "",
    realUserStatus: 0,
    state: urlParams.get("state") || "",
    user: userData.user || "",
  };

  authenticateWithApple(payload, navigation);
}
*/

// Tokens speichern
export const storeToken = (accessToken, refreshToken) => async (dispatch) => {
  if (refreshToken) {
    await Keychain.setGenericPassword(
      "auth",
      JSON.stringify({ accessToken, refreshToken })
    );
  }
  dispatch(setSessionToken(accessToken));
  dispatch(setRefreshToken(refreshToken));
};

export const getStoredTokens = async () => {
  const credentials = await Keychain.getGenericPassword();
  return credentials ? JSON.parse(credentials.password) : null;
};

export const clearTokens = async () => {
  try {
    await Keychain.resetGenericPassword();
  } catch (error) {
    console.error("Failed to clear tokens", error);
  }
};

export async function refreshAccessToken() {
  let tokens;
  try {
    tokens = await getStoredTokens();
  } catch (error) {
    console.error("Failed to get stored tokens", error);
    return null;
  }
  if (!tokens || !tokens.refreshToken) return null;
  try {
    const response = await fetch(getURL() + "/api/v4/auth/refresh", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refresh_token: tokens.refreshToken }),
    });
    console.log("Refresh Request: ", response);
    if (!response.ok) {
      await clearTokens();
      return null;
    }

    const data = await response.json();
    console.log(data);
    if (!data.access_token) return null;

    await store.dispatch(storeToken(data.access_token, data.refresh_token));
    return data.access_token;
  } catch (error) {
    console.error("Token refresh failed", error);
    return null;
  }
}

export const initializeAuth = async () => {
  const tokens = await getStoredTokens();
  if (tokens?.accessToken && tokens?.refreshToken) {
    store.dispatch(setSessionToken(tokens.accessToken));
    store.dispatch(setRefreshToken(tokens.refreshToken));
    await getOfflineUser();
    const r_token = await refreshAccessToken();
    if (!r_token) return false;
    return true;
  }

  return false;
};
