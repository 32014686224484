import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import LargeStandardBox from "../LargeStandardBox";

export default function getDosingRecommendationBox(box: ResultBox) {
  return (
    <LargeStandardBox
      key={box.id}
      inlineImage={undefined}
      title={parser(box.title)}
      content={box.content ? parser(box.content) : ""}
      iconType={box.Type}
      boxType="dosing"
      expandableContent={parser(box.expandableContent ?? "")}
    />
  );
}
