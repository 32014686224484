import { Image, Text, View } from "react-native";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideThree() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Text
          style={{
            fontSize: isLargerDevice() ? 54 : 36,
            ...getFontStyle(500),
            marginBottom: 32,
          }}
        >
          Als Medizinprodukt kannst du dich auf uns verlassen. 🤝
        </Text>
      </View>

      <Image
        source={
          isLargerDevice()
            ? require("../../../../assets/images/slide_three_large.png")
            : require("../../../../assets/images/ce_tut.png")
        }
        style={{
          width: "100%",
          resizeMode: "cover",
          aspectRatio: 0.67,
          alignSelf: "flex-end",
          marginRight: -16,
          flex: 3,
          marginTop: -32,
        }}
      />
    </View>
  );
}
