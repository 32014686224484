import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Platform, StatusBar, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import LargeScreenResultPageUserInteractionsComponent from "../components/LargeScreenResultPageUserInteractionsComponent";
import { ResultPagesWrapper } from "../components/ResultPagesWrapper";
import Colors from "../constants/static-colors";
import {
  selectFormulaResult,
  setResultingCalculation,
} from "../functions/calculator/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../functions/current-result/helpers";
import { selectCurrentUser } from "../functions/user/actions";
import getColorScheme from "../hooks/useColorScheme";
import { ResultPageContentWrapper } from "./ResultPageScreen/ResultPageScreen";
import { useState } from "react";

export default function InfoResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();

  const formulaResult = useSelector(selectFormulaResult);
  const hasResultingCalculation = pageContent.resultingCalculationFormula;
  const currentUser = useSelector(selectCurrentUser);
  const hasBookmark = getIsBookmarkToID(pageContent._id, pageContent.legacy_id);
  const note = getNoteToID(pageContent._id, pageContent.legacy_id);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const safelyEvaluateExpression = (expression, RESULT) => {
    // Define allowed variables and operations for safety

    const allowedOps = ["*", "/", "+", "-", "**"];

    // Check if expression contains only allowed operations and variables
    if (
      allowedOps.some((op) => expression.includes(op)) &&
      expression.includes("RESULT")
    ) {
      return new Function("RESULT", `return ${expression}`)(RESULT);
    } else {
      throw new Error("Unsafe expression detected");
    }
  };

  const insertCalculation = () => {
    // Extracting the expression inside the cur ly braces
    const expressionMatch = hasResultingCalculation.match(/\{(.*?)\}/);
    if (expressionMatch) {
      const expression = expressionMatch[1].trim();
      try {
        const evaluatedValue = safelyEvaluateExpression(
          expression,
          formulaResult
        );
        const roundedValue = Math.round(parseFloat(evaluatedValue) * 100) / 100;

        const updatedString = hasResultingCalculation.replace(
          `{${expression}}`,
          roundedValue
        );
        const inline = `<b style='color: ${Colors["light"].blue};'>${updatedString}</b>`;
        dispatch(setResultingCalculation(inline));
        navigation.goBack();
      } catch (error) {
        console.error("Error evaluating expression:", error);
      }
    }
  };

  return (
    <ResultPagesWrapper
      isModal
      navigation={navigation}
      patientData={null}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          action: "info_result_screen_opened",
          screen: "InfoResultPageScreen",
          values: "UserOpened",
          id: pageContent?._id,
        },
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            width: "100%",
            flex: 1,
          }}
        >
          <ResultPageContentWrapper
            pageContent={pageContent}
            targetSections={undefined}
            isExporting={false}
            setWarningModalVisible={() => {}}
            hasResultingCalculation={hasResultingCalculation}
            formulaResult={formulaResult}
            insertCalculation={insertCalculation}
          />
        </View>
      </View>
    </ResultPagesWrapper>
  );
}

const BackgroundIcon = () => {
  const colorScheme = getColorScheme();
  return (
    <Feather
      name="info"
      color={Colors[colorScheme].text}
      size={500}
      width={600}
      style={{
        position: "absolute",
        bottom: -100,
        left: -150,
        opacity: 0.03,
      }}
    />
  );
};
