import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Dimensions, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CarouselComponent from "../../components/CarouselComponent/CarouselComponent";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import { Dots } from "../../components/TutorialSliderComponent";
import { colors } from "../../constants/static-colors";
import RenderPage from "./components/OnboardingCarouselPage";
import jsonData from "./onboardingContent.json";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

const { width } = Dimensions.get("window");

const OnboardingScreen = ({ route }) => {
  const pageKey = route.params?.pageKey;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [data] = useState(jsonData[pageKey] || []);

  const navigation = useNavigation();

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "screen_view_onboarding",
      "OnboardingScreen",
      "View",
      {
        pageKey,
      }
    );
  }, []);

  // Simplified image mapping

  // Render for each carousel page
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.ui.background,
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: colors.ui.secondary,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingBottom: insets.bottom + 12,
        }}
      >
        {/* Carousel with horizontal swiping */}
        <View style={{ flex: 1, width: "100%" }}>
          <CarouselComponent
            data={data.map((li) => ({
              isStartPage: li?.isStartPage,
              image: li?.image,
              title: li?.title,
              description: li?.description,
            }))}
            renderItem={({ item }) => <RenderPage item={item} />}
            // Adjust these props as needed to improve horizontal scrolling:
            sliderWidth={width}
            itemWidth={width}
            autoplay
            lockScrollWhileSnapping
            loop
            autoplayInterval={10000}
            onSnapToItem={(index) => setCurrentIndex(index)}
          />
        </View>

        {/* Pagination Dots */}

        {data.length > 1 && (
          <View
            style={{
              marginVertical: 20,
            }}
          >
            <Dots current={currentIndex} amount={data.length - 1} />
          </View>
        )}

        {/* Bottom "Verstanden" button */}
        <DefaultButton
          title="Verstanden"
          type={ButtonTypes.Primary}
          action={() => navigation.goBack()}
          style={{ alignSelf: "center", paddingHorizontal: 32 }}
        />
      </View>
      {/* </Modal> */}
    </View>
  );
};

export default React.memo(OnboardingScreen);
