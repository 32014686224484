import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Linking, Platform, Text, View } from "react-native";
import i18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";

export default function ReferrerModal({ open, onClose }) {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.addListener("focus", () => {
      if (open)
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "warning_modal_presented",
          "ReferrerModal",
          "presented"
        );
    });
  }, []);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          Information
        </Text>
        <Text
          style={{
            fontSize: 14,
            lineHeight: 18,
            fontFamily: "HostGrotesk-Regular",
            color: colors.ui.textPrimary,
          }}
        >
          {i18n.t("info_about_regulatory")}
        </Text>
        <View style={{ flexDirection: "row", gap: 20, paddingHorizontal: 12 }}>
          <DefaultButton
            type={ButtonTypes.Primary}
            action={onClose}
            style={{ marginTop: 32, flex: 1 }}
            title={i18n.t("got_it")}
            icon={null}
            textStyle={{ textAlign: "center" }}
          />
          <DefaultButton
            type={ButtonTypes.Outline}
            action={() => {
              if (Platform.OS === "web") {
                window.open("https://direct.lando.health/", "_blank");
              } else {
                Linking.openURL("https://direct.lando.health/");
              }
            }}
            style={{ marginTop: 32, flex: 1 }}
            title={i18n.t("discover_lando")}
            icon={null}
            textStyle={{ textAlign: "center" }}
          />
        </View>
      </View>
    </CustomModal>
  );
}
