import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator as RNActivityIndicator,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { HintBox } from "../../components/boxes/box-types/hint-box/HintBox";
import AddCaseModal from "../../components/modals/AddCaseModal";
import CustomResultComponentSorter from "../../components/result/CustomResultComponents/CustomResultComponentSorter";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";
import TitleText from "../../components/result/TitleText";
import { default as Colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import { selectPatientData } from "../../functions/calculator/actions";
import {
  selectExportList,
  selectIsExporting,
  selectResultPageCaseContent,
  selectShowTitle,
  setIsExporting,
} from "../../functions/current-result/actions";
import { getNoteToID } from "../../functions/current-result/helpers";
import getColorScheme from "../../hooks/useColorScheme";
import ResultPageSkeleton from "../../components/ResultPageSkeleton/ResultPageSkeleton";

// const ResultPageSkeleton = lazy(
//   () => import("../../components/ResultPageSkeleton/ResultPageSkeleton")
// );

export function isResultPageWithSkeleton(type) {
  return (
    type === "erkrankungResultPageType" ||
    type === "interventionResultPageType" ||
    type === "drugResultPageType" ||
    type === "fastTrackResultPageType"
  );
}

export default function ResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const isExporting = useSelector(selectIsExporting);
  const exportList = useSelector(selectExportList);

  const [exportNameModalVisible, setExportNameModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();
  const { targetSections } = route.params;

  const insets = useSafeAreaInsets();

  const [loading, setLoading] = useState(true);
  const patientData = useSelector(selectPatientData);

  useEffect(() => {
    if (pageContent) {
      setLoading(false);
    }
  }, [pageContent]);

  return (
    <ResultPagesWrapper
      navigation={navigation}
      patientData={patientData}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          screen: "ResultPageScreen",
          action: "result_page_opened",
          id: pageContent?._id,
          values: pageContent?.title,
        },
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          flex: 1,
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <AddCaseModal
          type={"PDF_EXPORT"}
          open={exportNameModalVisible}
          onClose={() => {
            setExportNameModalVisible(false);
            dispatch(setIsExporting(false));
          }}
        />
        <View
          style={{
            width: "100%",
            backgroundColor: Colors[colorScheme].background,
            height: 12,
            marginTop: -12,
            zIndex: 50,
            elevation: 2,
          }}
        />
        {loading ? (
          <View
            style={{
              padding: 20,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RNActivityIndicator />
          </View>
        ) : (
          <ResultPageContentWrapper
            pageContent={pageContent}
            targetSections={targetSections}
            isExporting={isExporting}
            setWarningModalVisible={setWarningModalVisible}
          />
        )}
        {isExporting && (
          <View
            style={{
              backgroundColor: Colors[colorScheme].foreground,
              width: "100%",
              marginBottom: -50,
              padding: 20,
              paddingBottom: 62 + insets.bottom,
              borderTopColor: Colors[colorScheme].accentDark,
              elevation: 2,
              shadowColor: Colors[colorScheme].text,
              shadowOffset: {
                width: 0,
                height: -5,
              },
              shadowOpacity: 0.08,
              shadowRadius: 4,
              borderTopWidth: 1,
              gap: 12,
            }}
          >
            <Text
              style={{
                fontSize: Sizes.boxText,
                color: Colors[colorScheme].text,
              }}
            >
              Wähle die Boxen aus, die der Export beinhalten soll.
            </Text>
            <View
              style={{
                gap: 20,
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <DefaultButton
                style={{ flex: 1 }}
                title={"Export starten (" + exportList.length + ")"}
                action={async () => {
                  setExportNameModalVisible(true);
                }}
                type={ButtonTypes.Primary}
              />
              <TouchableOpacity
                onPress={() => {
                  dispatch(setIsExporting(false));
                }}
                style={{
                  padding: 8,
                  backgroundColor: Colors[colorScheme].red,
                  borderRadius: 50,
                }}
              >
                <Feather name="x" color="white" size={20} />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </ResultPagesWrapper>
  );
}

export const ResultPageContentWrapper = ({
  pageContent,
  targetSections,
  isExporting,
  setWarningModalVisible,
  hasResultingCalculation = false,
  formulaResult = undefined,
  insertCalculation = () => {},
}) => {
  const scrollViewRef = useRef<any>();
  const dispatch = useDispatch();
  const [remoteClose, setRemoteClose] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const scrollYRef = useRef(scrollY);
  useEffect(() => {
    scrollYRef.current = scrollY;
  }, [scrollY]);

  const showTitle = useSelector(selectShowTitle);
  const resultPageCaseContent = useSelector(selectResultPageCaseContent);
  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (isResultPageWithSkeleton(pageContent.type)) {
        setTimeout(() => {
          scrollViewRef.current?.scrollTo({
            y: scrollYRef.current + 1,
            animated: true,
          });
        }, 150);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <View
      style={{
        width: "100%",
        padding: Sizes.defaultContainerPadding,
      }}
    >
      <ResultPageUserInteractionsHeader
        {...{ pageContent, setWarningModalVisible }}
      />
      <View
        style={{
          width: "100%",
          minHeight: "85%",
          justifyContent: "flex-start",
        }}
      >
        <Suspense
          fallback={
            <View
              style={{
                flex: 1,
                backgroundColor: "red",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: Colors["light"].accent,
                  },
                }}
                size="small"
              />
            </View>
          }
        >
          <UserNoteView pageContent={pageContent} navigation={navigation} />
          <ResultPageContentView
            pageContent={pageContent}
            targetSections={targetSections}
            scrollY={scrollY}
            remoteClose={remoteClose}
            hasResultingCalculation={hasResultingCalculation}
            formulaResult={formulaResult}
            insertCalculation={insertCalculation}
          />
        </Suspense>
      </View>
    </View>
  );
};

const UserNoteView = ({ pageContent, navigation }) => {
  const note = getNoteToID(pageContent._id, pageContent.legacy_id);
  if (note?.showInResult && note.note !== "") {
    return (
      <TouchableOpacity
        style={{ marginBottom: -12 }}
        key={"NOTES"}
        onPress={() =>
          navigation.navigate("CreateNoteScreen", {
            note,
            currentDisplayID: pageContent._id,
          })
        }
      >
        <View
          style={{
            pointerEvents: "none",
          }}
        >
          <HintBox
            title={note.note}
            expandableContent={undefined}
            inlineImage={undefined}
            isNote={true}
          />
        </View>
      </TouchableOpacity>
    );
  }

  return null;
};

export const ResultPageContentView = ({
  pageContent,
  targetSections,
  remoteClose,
  hasResultingCalculation = false,
  formulaResult = undefined,
  insertCalculation = () => {},
  scrollY,
}) => {
  return (
    <>
      {isResultPageWithSkeleton(pageContent.type) ||
      JSON.stringify(pageContent).includes("multiContentSectionType") ? (
        <Suspense
          fallback={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: Colors["light"].accent,
                  },
                }}
                size="small"
              />
            </View>
          }
        >
          <ResultPageSkeleton
            targetSections={targetSections}
            remoteClose={remoteClose}
            data={pageContent}
            scrollY={scrollY}
          />
        </Suspense>
      ) : (
        <ResultBoxes {...{ pageContent }} />
      )}
      {pageContent.customPageContent && (
        <CustomResultComponentSorter pageContent={pageContent} />
      )}
      {hasResultingCalculation && (
        <DefaultButton
          style={{ marginTop: 20 }}
          type={ButtonTypes.Primary}
          title="Ergebnis einsetzen"
          enabled={formulaResult}
          action={insertCalculation}
        />
      )}
      <SourcesView {...{ pageContent }} />
    </>
  );
};

const RatingView = () => {
  const colorScheme = getColorScheme();
  async function sendFeedback(isPositive) {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "result_page_rated" + (isPositive ? "_positive" : "_negative"),
      "ResultPageScreen"
    );
    setTimeout(() => {
      showInlineNotification({
        text: "Feedback gesendet. Vielen Dank!",
        type: InlineNotificationType.SUCCESS,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }, 250);
  }

  return (
    <View style={{ flexDirection: "row", marginTop: 32, opacity: 0.45 }}>
      <TouchableOpacity
        onPress={() => sendFeedback(false)}
        style={{
          flex: 1,
          borderColor: Colors[colorScheme].red,
          padding: 8,
          borderRadius: 12,
          borderWidth: 2,
          marginRight: 12,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Feather name="thumbs-down" size={32} color={Colors[colorScheme].red} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => sendFeedback(true)}
        style={{
          flex: 1,
          padding: 8,
          borderWidth: 2,
          borderRadius: 12,
          marginLeft: 12,
          borderColor: Colors[colorScheme].green,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Feather name="thumbs-up" size={32} color={Colors[colorScheme].green} />
      </TouchableOpacity>
    </View>
  );
};

export const ResultPageUserInteractionsHeader = ({
  pageContent,
  setWarningModalVisible,
}) => {
  const colorScheme = getColorScheme();
  if (!pageContent) return null;
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 12,
          alignSelf: "stretch",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <TitleText
          style={{
            marginRight: 12,
            flex: 1,
          }}
          title={pageContent.title ?? pageContent.fieldTitle}
        />
        <View style={{ gap: 12, flexDirection: "row" }}>
          <TouchableOpacity
            style={{ marginLeft: 0 }}
            onPress={() => setWarningModalVisible((m) => !m)}
          >
            <Ionicons
              name="warning-outline"
              size={24}
              color={Colors[colorScheme].text}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
