import Colors, { colors } from "../../constants/static-colors";

import { NodePage, ResultPage, ScorePage } from "./load-all/types";

export function getPagesToIDs(ids, allPages) {
  const pages: any = [];
  if (ids?.[0]?.content !== undefined) ids = ids.map((item) => item._id);

  if (!ids) return [];

  for (const b of ids.filter((b) => typeof b === "string")) {
    let page = allPages.find(
      (page: ResultPage | NodePage | ScorePage) => page._id === b
    );

    if (!page) page = allPages.find((page) => page.content?.legacy_id === b);
    pages.push(page);
  }
  return pages ?? [];
}

function removeSupportListsTags(html) {
  // Define the regex to match all <!-- [if !supportLists]--> tags
  const regex = /<!--\s*\[if !supportLists\]-->/g;
  // Replace all matches with an empty string
  return html.replace(regex, "");
}

function ensureSpaceParser(content) {
  content = content
    .replace(/\s*lang="[^"]*"/g, "")
    .replace(/\s*class="MsoNormal"/g, "")
    .replace(/<\/?span[^>]*>/g, "");
  return content;
}

export function colorParser(c: string | undefined) {
  if (!c) return colors.ui.textPrimary;

  if (c === "red") {
    return colors.semantic.error;
  } else if (c === "green") {
    return colors.semantic.success;
  } else if (c === "gray") {
    return colors.semantic.disabled;
  } else if (c === "gold") {
    return colors.ui.primary;
  } else {
    return colors.semantic.disabled;
  }
}

function getColorToKey(input) {
  const regex = /\$KEY=\{(.*?)\}/g; // Notice the 'g' for global matching
  let match;

  // Loop over all matches in the string
  while ((match = regex.exec(input)) !== null) {
    const colorKey = match[1]; // Extracted colorKey

    // Getting the color hex value from the Colors object
    const colorHex = Colors.light[colorKey];

    if (colorHex) {
      // Replace the matched pattern with the actual hex color value
      input = input.replace(`$KEY={${colorKey}}`, colorHex);
    }
  }

  return input;
}

export function parser(input: string): string {
  let output = input || "";
  output = output.replace(/\n/g, "NEW LINE");

  output = output.replace(/<p> <\/p>\n/g, "<br>");

  output = output.replace(/<p><\/p>/g, "");

  // * Called three times to remove encapsulated tags
  output = removeEmptyTags(output);
  output = removeEmptyTags(output);
  output = removeEmptyTags(output);

  output = output.replace(/kgKG/g, "kg KG");

  // Remove the <colgroup> tags and their content
  output = output.replace(/<colgroup.*?>/g, "");
  // Remove only the closing </colgroup> tags
  output = output.replace(/<\/colgroup>/g, "");

  output = output.replace(/<col.*?>/g, "");
  // Remove only the closing </colgroup> tags
  output = output.replace(/<\/col>/g, "");

  output = removeClassAndLangAttributes(output);

  output = cleanStyles(output);

  output = getColorToKey(output);
  output = removeNonRgbHexColors(output);
  output = removeBlackOrWhiteColorStyles(output);

  output = output.replace(/NEW LINENEW LINE/g, "<br>");
  output = output.replace(/NEW LINE/g, "");

  output = output.replace(/\n<p><\/p>\n/g, "<br><br>");
  output = ensureSpaceParser(output);

  output = removeSupportListsTags(output);

  return output;
}

function removeClassAndLangAttributes(htmlString) {
  // This regular expression matches ` class="anything"` or ` lang="anything"`
  // It's important to note that this assumes double quotes are used.
  // If single quotes or no quotes can also be used, the regex needs to be adjusted accordingly.
  const regex = /\s(class|lang)="[^"]*"/g;

  // Replace matched patterns with an empty string
  return htmlString.replace(regex, "");
}

function removeNonRgbHexColors(styleString) {
  // This regex matches color properties that are not in RGB/RGBA or HEX format
  // It looks for color or background-color properties that do not start with 'rgb' or '#'
  // Note: This is a simple version and might need adjustments based on actual CSS content complexity
  const regex = /(\bcolor\b|\bbackground-color\b): ?(?!rgb|rgba|#)\w+;?/g;

  // Replace non-RGB/HEX color assignments with an empty string
  return styleString.replace(regex, "");
}

const removeBlackOrWhiteColorStyles = (str) => {
  str = str.replace(
    /(style\s*=\s*['"][^'"]*?\bcolor\s*:\s*)(black|#000(?![\da-f])|#000000|rgb\s*\(\s*0\s*,\s*0\s*,\s*0\s*\))(\s*;?[^'"]*['"])/gi,
    function (match, p1, p2, p3) {
      // Replace the black color value with "green" and reconstruct the style attribute
      return p1 + colors.ui.textPrimary + p3;
    }
  );
  // This regex looks for a style attribute (style="...") that includes a color property set to either black or white in various formats.
  // It then removes the entire style attribute. If you only want to remove the color property but keep other styles,
  // you'll need a more complex replacement strategy that captures and reconstructs the style attribute without the color style.

  return str;
};

function cleanStyles(htmlString) {
  return htmlString.replace(/style="[^"]*"/gi, function (match) {
    let newStyle = "";

    // Look for color, font-style, and padding-left properties and preserve them
    const colorMatch = match.match(/color:\s*[^;]+;/);
    const fontStyleMatch = match.match(/font-style:\s*[^;]+;/);
    const paddingLeftMatch = match.match(/padding-left:\s*[^;]+;/);

    if (colorMatch) newStyle += colorMatch[0];
    if (fontStyleMatch) newStyle += fontStyleMatch[0];
    if (paddingLeftMatch) newStyle += paddingLeftMatch[0];

    return newStyle ? `style="${newStyle.trim()}"` : "";
  });
}

function removeEmptyTags(htmlString: string): string {
  const regex = /<(?!\/?(th|tr|td)>)[^\/>]*(?:\/>|>(?:\s*|&nbsp;)*<\/[^>]+>)/g;
  const cleanedString = htmlString.replace(regex, "");

  return cleanedString;
}

