import { Ionicons } from "@expo/vector-icons";
import { default as LottieView } from "lottie-react-native";
import { useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  GestureResponderHandlers,
  PanResponder,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import checkmark from "../../../assets/animations/checkmark.json";
import error from "../../../assets/animations/error.json";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import DefaultGradientBackground from "./../DefaultGradientBackground";

interface InlineNotificationProps {
  text: any;
  type: InlineNotificationType;
  hasIcon?: boolean;
  direction?: InlineNotificationDirection;
  duration?: number;
  shouldShow: boolean;
  closeAction?: () => void;
  onPress?: () => void;
  swipeThreshold?: number; // optional threshold
}

export enum InlineNotificationDirection {
  FROM_TOP,
  FROM_BOTTOM,
}

export enum InlineNotificationType {
  ERROR,
  SUCCESS,
  NEUTRAL,
  INFO,
}

export const InlineNotificationComponent = (props: InlineNotificationProps) => {
  let { hasIcon, direction, duration, swipeThreshold } = props;
  const { closeAction, text, type, shouldShow, onPress } = props;

  const lottieRef = useRef<LottieView>(null);

  // For top or bottom transitions, we use an Animated.Value
  // that represents the container's "offset" from its final position.
  // e.g. from top: initial -200 -> to 60
  // from bottom: initial +200 -> to 60
  const move = useRef(new Animated.Value(-200)).current;

  // Provide default values if not passed in
  if (hasIcon === undefined) {
    hasIcon = true;
  }
  if (!direction) {
    direction = InlineNotificationDirection.FROM_TOP;
  }
  if (!duration) {
    duration = 2000;
  }
  if (!swipeThreshold) {
    swipeThreshold = 50; // the drag distance after which we dismiss
  }

  // Prepare the background color and Lottie source based on type
  let backgroundColor = "";
  let animationSource: any;
  let lottieHeight = 90;
  const isTip = type === InlineNotificationType.INFO;

  switch (type) {
    case InlineNotificationType.ERROR:
      backgroundColor = colors.semantic.error;
      lottieHeight = 70;
      animationSource = error;
      break;
    case InlineNotificationType.SUCCESS:
      backgroundColor = colors.semantic.success;
      animationSource = checkmark;
      break;
    case InlineNotificationType.NEUTRAL:
    case InlineNotificationType.INFO:
      backgroundColor = colors.semantic.disabled;
      animationSource = checkmark;
      break;
  }

  // figure out the final offset for the "moveIn" animation
  // '60' is your final top offset if from top, or bottom offset if from bottom
  // negative or positive depends on direction
  const finalOffset = 60; // visible position
  const initialOffset = -200; // offscreen if from top
  // if (direction === InlineNotificationDirection.FROM_BOTTOM) {
  //   // if from bottom, start from +200 -> 60

  //   move.setValue(initialOffset);
  // }

  // Move in animation
  const moveIn = () => {
    Animated.timing(move, {
      toValue: finalOffset,
      duration: 750,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      // Play the Lottie animation after fully visible
      lottieRef.current?.play();

      // If on web or no icon, auto-close after duration
      if (Platform.OS === "web" || !hasIcon) {
        setTimeout(() => {
          moveOut();
        }, duration);
      }
    });
  };

  // Move out animation
  const moveOut = () => {
    Animated.timing(move, {
      toValue: initialOffset,
      duration: 500,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      // Once the animation completes, call the close action if any
      if (closeAction) {
        closeAction();
      }
    });
  };

  // Set up a PanResponder to detect vertical drag.
  // If the user drags beyond "swipeThreshold", we dismiss.
  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: (_, gestureState) => {
        // Start capturing the gesture on any vertical move
        // that isn't trivial (1 or 2 px).
        return Math.abs(gestureState.dy) > 2;
      },
      onPanResponderMove: (_, gestureState) => {
        // Let the user drag the notification up/down
        // We add the gestureState.dy to the finalOffset to allow an immediate response
        const newOffset = finalOffset + gestureState.dy;
        move.setValue(newOffset);
      },
      onPanResponderRelease: (_, gestureState) => {
        // If user has swiped beyond threshold, dismiss
        if (Math.abs(gestureState.dy) > swipeThreshold!) {
          moveOut();
        } else {
          // otherwise, snap back
          moveIn();
        }
      },
    })
  ).current;

  // run moveIn animation whenever `shouldShow` changes
  useEffect(() => {
    if (shouldShow) {
      moveIn();
    }
    // If you're controlling show/hide from outside, you could optionally
    // also handle the "false" scenario to moveOut.
    else {
      moveOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShow]);

  // If `type` is undefined, render nothing
  if (type === undefined) return null;

  const positionStyles =
    direction === InlineNotificationDirection.FROM_BOTTOM
      ? { bottom: move }
      : { top: move };

  return (
    <Animated.View
      // We spread the panHandlers here to catch vertical swipes
      {...(panResponder.panHandlers as GestureResponderHandlers)}
      style={{
        position: "absolute",
        width: "100%",
        zIndex: 10000,
        overflow: "hidden",
        // The dynamic style for vertical offset:
        ...positionStyles,
      }}
    >
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          // user tapped on the notification
          onPress?.();
          // also auto-dismiss if you prefer
          moveOut();
        }}
        disabled={Platform.OS === "ios" && !onPress}
        style={{
          alignSelf: "stretch",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          elevation: 3,
        }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: Sizes.containerWidth,
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              alignSelf: "stretch",
              flex: 1,
              margin: 12,
              shadowColor: colors.ui.textPrimary,
              elevation: 2,
              shadowOffset: { width: 2, height: 2 },
              shadowRadius: 5,
              shadowOpacity: 0.2,
              borderRadius: 12,
              backgroundColor: backgroundColor,
              padding: 24,
            }}
          >
            {type === InlineNotificationType.SUCCESS && (
              <DefaultGradientBackground
                style={{ overflow: "hidden", borderRadius: 12 }}
              />
            )}
            <Text
              style={{
                color: "black",
                flex: 1,
                fontFamily: "HostGrotesk-Regular",
                marginRight: 42,
              }}
            >
              {text}
            </Text>

            {hasIcon ? (
              <View
                style={{
                  margin: -30,
                  marginVertical: -60,
                  alignItems: "center",
                  justifyContent: "center",
                  width: 72,
                }}
              >
                {isTip ? (
                  <Ionicons
                    name="bulb-sharp"
                    size={25}
                    color={colors.ui.textPrimary}
                    style={{
                      marginLeft: 12,
                      marginRight: 30,
                    }}
                  />
                ) : (
                  <LottieView
                    ref={lottieRef}
                    style={{ height: lottieHeight }}
                    source={animationSource}
                    autoPlay={false}
                    loop={false}
                    onAnimationFinish={() => {
                      // The Lottie animation finished
                      setTimeout(() => {
                        moveOut();
                      }, duration);
                    }}
                  />
                )}
              </View>
            ) : null}
          </View>
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
};
