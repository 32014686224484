import { useNavigation } from "@react-navigation/native";
import { useMemo, useState } from "react";
import {
  Platform,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import {
  selectCurrentResultPage,
  selectShowTitle,
} from "../../functions/current-result/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../../functions/current-result/helpers";
import { selectIsDemoMode } from "../../functions/navigation/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import isLargerDevice, { isWebAndNotMobile } from "../../hooks/isLargerDevice";
import LargeScreenResultPageUserInteractionsComponent from "../LargeScreenResultPageUserInteractionsComponent";

import { selectPatientData } from "../../functions/calculator/actions";

import { Feather } from "@expo/vector-icons";
import getFontStyle from "../../functions/getFontSize";
import { HorizontalLogo } from "../logos/HorizontalLogo";
import ReferrerModal from "../modals/ReferrerModal";
import { BackButton } from "./header-components/HeaderBackButton";
import { PatientDataHeaderComponent } from "./header-components/PatientDataHeaderComponent";

export default function ResultPageHeader({
  isModal = false,
  integrated = false,
}) {
  const insets = useSafeAreaInsets();
  const paddingTop = isLargerDevice() ? insets.top + 24 : insets.top;

  const isDemoMode = useSelector(selectIsDemoMode);

  const showTitle = useSelector(selectShowTitle);

  const [referrerModalOpen, setReferrerModalOpen] = useState(false);

  const currentResultPage = useSelector(selectCurrentResultPage);
  const currentUser = useSelector(selectCurrentUser);
  const navigation = useNavigation();
  const patientData = useSelector(selectPatientData);
  const note = useMemo(
    () => getNoteToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );
  const hasBookmark = useMemo(
    () =>
      getIsBookmarkToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );

  return (
    <>
      <ReferrerModal
        open={referrerModalOpen}
        onClose={() => setReferrerModalOpen(false)}
      />
      <View
        style={{
          backgroundColor: colors.ui.background,
          paddingHorizontal: integrated ? 0 : Sizes.defaultContainerPadding,
          zIndex: 10,
          left: 0,
          right: 0,
          paddingTop: integrated
            ? 24
            : isModal
            ? Platform.OS === "android"
              ? StatusBar.currentHeight
              : 8
            : Platform.OS === "android"
            ? StatusBar.currentHeight
            : paddingTop,
          flexDirection: "column",
        }}
      >
        {patientData && (
          <View style={{ marginBottom: -12 }}>
            <PatientDataHeaderComponent />
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingVertical: integrated ? 0 : 12,
            marginTop: 4,
            marginBottom: integrated ? 0 : isWebAndNotMobile() ? 8 : 0,
          }}
        >
          {!isDemoMode && !isModal && (
            <BackButton isModal={isModal} navigation={navigation} />
          )}

          {isDemoMode && (
            <View
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <HorizontalLogo />
              <TouchableOpacity
                onPress={() => setReferrerModalOpen(true)}
                style={{
                  padding: 12,
                  backgroundColor: colors.ui.primary,
                  borderRadius: 12,
                  alignItems: "center",
                  justifyContent: "center",
                  aspectRatio: 1,
                }}
              >
                <Feather name="info" size={20} color={colors.ui.textPrimary} />
              </TouchableOpacity>
            </View>
          )}

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              marginLeft: 8,
              marginRight: 12,
            }}
          >
            {showTitle && (
              <Text
                style={{
                  fontSize: 15,
                  marginBottom: -0.5,
                  ...getFontStyle(700),
                  color: colors.ui.textPrimary,
                }}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {currentResultPage.title ??
                  currentResultPage.pageTitle ??
                  currentResultPage.fieldTitle ??
                  ""}
              </Text>
            )}
          </View>
          <LargeScreenResultPageUserInteractionsComponent
            isDemoMode={isDemoMode}
            currentUser={currentUser}
            pageContent={currentResultPage}
            hasBookmark={hasBookmark}
            note={note}
          />
        </View>
      </View>
    </>
  );
}

export function formatDate(entry: string) {
  const date = new Date(entry); // Parse the ISO timestamp

  if (isNaN(date.getTime())) {
    return "Invalid"; // Check for invalid date
  }

  // Format the date in German locale with Germany's time zone
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "Europe/Berlin", // Explicitly adjust to German time zone
    hour12: false,
  };

  return date.toLocaleString("de-DE", options);
}

export function formatDateToDate(entry: string) {
  const date = new Date(entry); // Parse the ISO timestamp

  if (isNaN(date.getTime())) {
    return "Invalid"; // Check for invalid date
  }

  // Format the date in German locale with Germany's time zone
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "Europe/Berlin", // Explicitly adjust to German time zone
    hour12: false,
  };

  return date.toLocaleString("de-DE", options);
}
