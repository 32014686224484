import { useNavigation } from "@react-navigation/native";
import {
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { selectCurrentUser } from "../../functions/user/actions";

import { Feather } from "@expo/vector-icons";
import { addEventListener } from "@react-native-community/netinfo";
import { useEffect, useState } from "react";
import { selectEnterprise } from "../../functions/enterprise/actions";
import { selectTabSelection } from "../../functions/navigation/actions";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import getColorScheme from "../../hooks/useColorScheme";

import { LinearGradient } from "expo-linear-gradient";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { Sizes } from "../../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  selectPatientHeaderExpanded,
  setPatientData,
  setPatientHeaderExpanded,
} from "../../functions/calculator/actions";
import configureAnimations from "../../functions/configure-animations";
import { selectCurrentResultPage } from "../../functions/current-result/actions";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import BadgeComponent from "../BadgeComponent";
import { UserIcon } from "../UserIconComponent";
import { HorizontalLogo } from "../logos/HorizontalLogo";
import OfflineHintModal from "../modals/OfflineHintModal";
import { selectAllContent } from "../../functions/data/actions";
import { formatDate } from "./ResultPageHeaderComponent";

const LeftHeaderComponent = ({ title }) => {
  const navigation: any = useNavigation();

  const colorScheme = getColorScheme();
  const currentEnterprise = useSelector(selectEnterprise);

  const showLogo = currentEnterprise && currentEnterprise.logo?.show_logo;

  if (title.includes("Home"))
    return (
      <TouchableOpacity
        activeOpacity={0.65}
        style={{
          paddingVertical: 16,
          flexDirection: "row",
          alignItems: "center",

          gap: 10,
        }}
        hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
        onPress={() => {
          navigation.navigate("HomeScreen");
        }}
      >
        {showLogo && (
          <Image
            source={{ uri: currentEnterprise.logo?.file_ref }}
            style={{
              width: 80, //currentEnterprise?.logo?.logo_width ?? 45,
              height: 48,
              resizeMode: "contain",
            }}
          />
        )}

        {showLogo && (
          <Text
            style={{
              fontWeight: "700",
              fontSize: 20,
              marginBottom: 5,
              alignSelf: "flex-end",
              height: 28,
              color: Colors[colorScheme].text,
            }}
          >
            x
          </Text>
        )}
        <View>
          <HorizontalLogo />
        </View>
      </TouchableOpacity>
    );

  return (
    <Text
      style={{
        fontSize: 32,
        fontWeight: "700",
        height: 35,
        marginVertical: 16,
        color: Colors[colorScheme].text,
      }}
    >
      {title}
    </Text>
  );
};

export default function DefaultHeader({ height = 100, bottomOffset = 0 }) {
  const insets = useSafeAreaInsets();
  const colorScheme = getColorScheme();

  const currentTabSelection = useSelector(selectTabSelection);
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();
  const [isOffline, setIsOffline] = useState(false);
  const [showHintBadge, setShowHintBadge] = useState(false);
  const [showOfflineHint, setShowOfflineHint] = useState(false);

  const patientData = useSelector(selectPatientData);

  const imageToTitle = (title) => {
    if (title === "Home")
      return require(`../../../assets/images/header/home.png`);
    if (title === "Inhalte")
      return require(`../../../assets/images/header/content.png`);
    if (title === "Persönlich")
      return require(`../../../assets/images/header/personal.png`);
  };

  useEffect(() => {
    const unsubscribe = addEventListener((state) => {
      configureAnimations();
      setIsOffline(
        !state.isConnected ||
          (state.type === "cellular" &&
            state.details.cellularGeneration === "2g")
      );
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    async function checkShowBadge() {
      if (isOffline) {
        if (await handleOneTimeAction("show_offline_hint")) {
          setShowHintBadge(true);
        }
      }
    }

    checkShowBadge();
  }, [isOffline]);

  return (
    <View>
      <OfflineHintModal
        open={showOfflineHint}
        onClose={() => setShowOfflineHint(false)}
      />
      <View
        style={{
          position: "absolute",
          top: -150,
          left: -Sizes.defaultContainerPadding,
          right: -Sizes.defaultContainerPadding,
          zIndex: 0,
          overflow: "hidden",
          bottom: -36 + bottomOffset,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <Image
          source={imageToTitle(currentTabSelection)}
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
      <View
        style={{
          // paddingTop:
          //   (Platform.OS === "android"
          //     ? StatusBar.currentHeight ?? 12
          //     : insets.top) + (isLargerDevice() ? 20 : 0),

          height: height,
          marginBottom: bottomOffset,
        }}
      >
        {patientData && (
          <LinearGradient
            start={{ x: 1.0, y: 1.35 }}
            end={{ x: 1, y: 0 }}
            colors={[Colors["light"].background, Colors["light"].selectedTint]}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              zIndex: -10,
            }}
          />
        )}
        {!patientData && (
          <View
            style={{
              zIndex: 1,
              height: 70,
              paddingBottom: 4,
              //   backgroundColor: Colors[colorScheme].background,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <LeftHeaderComponent title={currentTabSelection} />
            <View
              style={{
                flexDirection: "row",
                gap: 20,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
                onPress={() => {
                  setShowHintBadge(false);
                  setShowOfflineHint(true);
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "offline_hint_modal_opened",
                    "DefaultHeader",
                    "User Opened Offline Hint Modal",
                    { isOffline }
                  );
                }}
              >
                {showHintBadge && <BadgeComponent text="1" />}
                {isOffline && (
                  <Feather
                    name="wifi-off"
                    color={Colors[colorScheme].text}
                    size={24}
                  />
                )}
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "more_screen_navigation",
                    "DefaultHeader",
                    "User Navigated to More Screen",
                    null
                  );

                  navigation.navigate("MoreScreen");
                }}
              >
                <UserIcon
                  {...{ colorScheme, currentUser }}
                  size={Platform.OS === "web" ? 52 : 48}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}

        <PatientDataHeaderComponent />
      </View>
    </View>
  );
}

export const PatientDataHeaderComponent = () => {
  const patientData = useSelector(selectPatientData);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const expanded = useSelector(selectPatientHeaderExpanded);
  const currentPageContent = useSelector(selectCurrentResultPage);
  const bmiPage = useSelector(selectAllContent).pages?.find((page) =>
    page.title.includes("BMI")
  );
  const warningLevel =
    patientData?.weight >= 300 ? 2 : patientData?.weight >= 200 ? 1 : 0;

  if (!patientData) return null;

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          onPress={() => {
            configureAnimations();
            dispatch(setPatientHeaderExpanded(!expanded));
          }}
          style={{ marginRight: 12 }}
        >
          <Feather name={expanded ? "chevron-up" : "chevron-down"} size={24} />
        </TouchableOpacity>
        <View style={styles.infoContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.label}>Kennung:</Text>
            <Text style={styles.value}>{patientData.name}</Text>
          </View>
          <View style={styles.detailsContainer}>
            <View style={styles.detailItem}>
              <Feather name="user" size={16} />
              <Text style={styles.label}>Gewicht:</Text>
              <Text style={styles.value}>{patientData.weight} kg</Text>
            </View>
            {patientData.size && (
              <View style={styles.detailItem}>
                <Feather name="move" size={16} />
                <Text style={styles.label}>Größe:</Text>
                <Text style={styles.value}>{patientData.size} cm</Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.actionsContainer}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("AddPatientScreen");
            }}
          >
            <Feather name="edit" size={20} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              dispatch(setPatientData(null));
              dispatch(clearAmount());
            }}
          >
            <Feather name="x" size={24} />
          </TouchableOpacity>
        </View>
      </View>
      {expanded && (
        <View style={{}}>
          <WeightWarningLabel
            warningLevel={warningLevel}
            style={{
              marginHorizontal: 20,
              marginTop: 12,
            }}
          />

          <View
            style={{
              marginTop: 20,
              flexDirection: "row",
              gap: 20,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
                borderRadius: 8,
                borderWidth: 0.5,
                padding: 8,
                paddingHorizontal: 12,
              }}
              onPress={() => {
                navigateToNextCMSPage(bmiPage._id, navigation, true);
              }}
            >
              <Text style={{ fontSize: Sizes.boxText }}>Mehr</Text>
              <Feather name="info" size={16} />
            </TouchableOpacity>
            <TouchableOpacity
              disabled={!currentPageContent}
              style={{
                paddingHorizontal: 12,
                opacity: currentPageContent ? 1 : 0.5,
                alignItems: "center",
                flexDirection: "row",
                borderRadius: 8,
                borderWidth: 0.5,
                padding: 8,
                gap: 8,
              }}
            >
              <Text style={{ textAlign: "center", fontSize: Sizes.boxText }}>
                Seite dem Patienten hinzufügen
              </Text>
              <Feather name="plus" size={16} />
            </TouchableOpacity>
          </View>
          {patientData.timestamp && (
            <Text
              style={{
                marginTop: 20,
                alignSelf: "flex-end",
                fontStyle: "italic",
                fontSize: Sizes.boxText,
              }}
            >
              {formatDate(patientData.timestamp)}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};

export const WeightWarningLabel = ({ warningLevel, style }) => {
  if (warningLevel === 0) return null;
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: 12,
        ...style,
      }}
    >
      <Feather
        name="alert-triangle"
        size={16}
        color={warningLevel === 1 ? "orange" : "red"}
      />
      <Text
        style={{
          flex: 1,
          fontSize: Sizes.boxText,
          color: warningLevel === 1 ? "orange" : "red",
        }}
      >
        {warningLevel === 1
          ? "Das eingegebene Körpergewicht überschreitet die normale Körpergewichtsgrenze erheblich!"
          : "Das eingegebene Körpergewicht ist unplausibel! Bitte die Eingabe prüfen!"}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderBottomColor: "rgba(0,0,0,0.1)",
    borderBottomWidth: 1,
    paddingVertical: 16,
    marginBottom: 16,
  },
  infoContainer: {
    flex: 1,
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    gap: 4,
  },
  detailsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    marginBottom: 4,
    gap: 4,
  },
  label: {
    fontSize: Sizes.boxText,
  },
  value: {
    fontSize: Sizes.boxText,
    fontWeight: "600",
  },
  actionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
});
