import React, { createContext, useContext, useEffect, useState } from "react";

interface Logo {
  logoName: string;
  logoRef: string;
  logoId: string;
  logoWidth: number;
  isDefault: boolean;
}

interface PrimeTile {
  slogan: string;
  logo: Logo;
}

export interface SatelliteTile {
  slogan: string;
}

interface DayData {
  primeTile: PrimeTile[];
  sateliteTile: SatelliteTile[];
  banner: BannerTile[];
}

interface BannerTile {
  slogan: string;
  logo: Logo;
}

interface DataState {
  [day: number]: DayData;
}

interface DataContextProps {
  data: DataState;
  loading: boolean;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<DataState>({});
  const [loading, setLoading] = useState(true);

  const today = new Date();
  const dayOfMonth = today.getDate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://dev.api.aristophania.mediceo.com/ads/campaign/external?year=${today.getFullYear()}&month=${
            today.getMonth() + 1
          }`
        );
        const result = await response.json();
        setData(result || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dayOfMonth]);

  return (
    <DataContext.Provider value={{ data, loading }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use data context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
