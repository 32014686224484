import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { Sizes } from "../constants/static-sizes";
import useScrollTopMargin from "../hooks/useScrollTopMargin";

const SkeletonLoadingAnimation = () => {
  const paddingTop = useScrollTopMargin();

  return (
    <View
      style={{
        flex: 1,
        overflow: "visible",
      }}
    >
      {/* Header with yellow background */}
      <View
        style={{
          backgroundColor: "#FFCC00", // Yellow background matching the design
          paddingTop: paddingTop,
          paddingHorizontal: Sizes.defaultContainerPadding,
          paddingBottom: 20,
          borderBottomRightRadius: 20,
          borderBottomLeftRadius: 20,
        }}
      >
        {/* Logo and profile area */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <SkeletonPlaceholder
            backgroundColor="#E0B000"
            highlightColor="#FFD633"
          >
            <SkeletonPlaceholder.Item
              width={120}
              height={30}
              borderRadius={4}
            />
          </SkeletonPlaceholder>

          <View style={{ flexDirection: "row" }}>
            <SkeletonPlaceholder
              backgroundColor="#E0B000"
              highlightColor="#FFD633"
            >
              <SkeletonPlaceholder.Item
                width={40}
                height={40}
                borderRadius={20}
                marginRight={10}
              />
            </SkeletonPlaceholder>
            <SkeletonPlaceholder
              backgroundColor="#E0B000"
              highlightColor="#FFD633"
            >
              <SkeletonPlaceholder.Item
                width={40}
                height={40}
                borderRadius={20}
              />
            </SkeletonPlaceholder>
          </View>
        </View>
        {/* Greeting text */}
        <SkeletonPlaceholder backgroundColor="#E0B000" highlightColor="#FFD633">
          <SkeletonPlaceholder.Item
            width={250}
            height={30}
            borderRadius={4}
            marginBottom={20}
          />
        </SkeletonPlaceholder>
        {/* Search bar
        <SkeletonPlaceholder backgroundColor="#E0B000" highlightColor="#FFD633">
          <SkeletonPlaceholder.Item
            width="100%"
            height={50}
            borderRadius={25}
          />
        </SkeletonPlaceholder> */}
      </View>

      {/* Content area with white background */}
      <View
        style={{
          backgroundColor: "#FFFFFF",
          flex: 1,
          paddingHorizontal: Sizes.defaultContainerPadding,
          paddingTop: 20,
          //borderTopLeftRadius: 20,
          //borderTopRightRadius: 20,
          marginTop: -20,
        }}
      >
        {/* Featured card */}
        <SkeletonPlaceholder>
          <SkeletonPlaceholder.Item marginBottom={30}>
            <SkeletonPlaceholder.Item
              width={150}
              height={20}
              borderRadius={4}
              marginBottom={10}
            />
            <SkeletonPlaceholder.Item
              width="100%"
              height={120}
              borderRadius={12}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder>

        {/* Häufig genutzt (Frequently used) section */}
        <SkeletonPlaceholder>
          <SkeletonPlaceholder.Item marginBottom={30}>
            <SkeletonPlaceholder.Item
              width={180}
              height={24}
              borderRadius={4}
              marginBottom={20}
            />

            <SkeletonPlaceholder.Item
              flexDirection="row"
              justifyContent="space-between"
            >
              {/* Three circular icons with labels */}
              <SkeletonPlaceholder.Item alignItems="center" width={100}>
                <SkeletonPlaceholder.Item
                  width={60}
                  height={60}
                  borderRadius={30}
                  marginBottom={10}
                />
                <SkeletonPlaceholder.Item
                  width={80}
                  height={20}
                  borderRadius={4}
                />
              </SkeletonPlaceholder.Item>

              <SkeletonPlaceholder.Item alignItems="center" width={100}>
                <SkeletonPlaceholder.Item
                  width={60}
                  height={60}
                  borderRadius={30}
                  marginBottom={10}
                />
                <SkeletonPlaceholder.Item
                  width={80}
                  height={20}
                  borderRadius={4}
                />
              </SkeletonPlaceholder.Item>

              <SkeletonPlaceholder.Item alignItems="center" width={100}>
                <SkeletonPlaceholder.Item
                  width={60}
                  height={60}
                  borderRadius={30}
                  marginBottom={10}
                />
                <SkeletonPlaceholder.Item
                  width={80}
                  height={20}
                  borderRadius={4}
                />
              </SkeletonPlaceholder.Item>
            </SkeletonPlaceholder.Item>
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder>

        {/* Kürzlich verwendet (Recently used) section */}
        <SkeletonPlaceholder>
          <SkeletonPlaceholder.Item marginBottom={20}>
            <SkeletonPlaceholder.Item
              flexDirection="row"
              alignItems="center"
              marginBottom={15}
            >
              <SkeletonPlaceholder.Item
                width={200}
                height={24}
                borderRadius={4}
              />
              <SkeletonPlaceholder.Item
                width={120}
                height={20}
                borderRadius={4}
                marginLeft="auto"
              />
            </SkeletonPlaceholder.Item>

            {/* Emergency cards */}
            <SkeletonPlaceholder.Item
              width="100%"
              height={80}
              borderRadius={12}
              marginBottom={15}
            />
            <SkeletonPlaceholder.Item
              width="100%"
              height={80}
              borderRadius={12}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder>
      </View>

      {/* Bottom navigation bar */}
      <View style={{ backgroundColor: "#FFFFFF" }}>
        <SkeletonPlaceholder>
          <SkeletonPlaceholder.Item
            flexDirection="row"
            justifyContent="space-around"
            paddingVertical={15}
            borderTopWidth={1}
            borderTopColor="#EEEEEE"
          >
            <SkeletonPlaceholder.Item
              width={50}
              height={50}
              borderRadius={25}
            />
            <SkeletonPlaceholder.Item
              width={50}
              height={50}
              borderRadius={25}
            />
            <SkeletonPlaceholder.Item
              width={50}
              height={50}
              borderRadius={25}
            />
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder>
      </View>
    </View>
  );
};

export default SkeletonLoadingAnimation;
