import messaging from "@react-native-firebase/messaging";
import { debounce } from "lodash";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";

import Ionicons from "@expo/vector-icons/Ionicons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Sizes } from "../../constants/static-sizes";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import chroma from "chroma-js";
import * as sha512 from "js-sha512";
import { useSelector } from "react-redux";
import I18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import CheckBox from "../../components/CheckBoxComponent";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import staticColors from "../../constants/static-colors";
import { setSelectedEnterprise } from "../../functions/enterprise/actions";
import { logout } from "../../functions/profile/functions";
import { store } from "../../functions/store";
import { selectCurrentUser } from "../../functions/user/actions";
import { setLogged, userLogin } from "../../functions/user/functions";
import getColorScheme from "../../hooks/useColorScheme";
import { APP_TARGET } from "../LaunchScreen";
import { AgreeTermsView } from "./registry/EnterLoginData";

async function loadCurrentEnterprise(data) {
  const enterpriseData = {
    internal_customer_id: data.internal_customer_id,
    customer_name: data.customer_name,
    colors: data.colors,
    logo: data.logo,
    default_language: data.logo?.default_language,
    dev_content_access: data.dev_content_access,
  };

  store.dispatch(setSelectedEnterprise(enterpriseData));
  await AsyncStorage.setItem(
    "selectedEnterprise",
    JSON.stringify(enterpriseData)
  );

  if (enterpriseData.default_language === "en") {
    I18n.locale = "en";
  }

  staticColors["light"].accentLight = enterpriseData.colors.accent_light;
  staticColors["light"].accent = enterpriseData.colors.accent_dark;
  staticColors["light"].accentDark = chroma(enterpriseData.colors.accent_dark)
    .darken(1)
    .hex();
  staticColors["light"].selectedTint = chroma(
    enterpriseData.colors.accent_light
  )
    .brighten(3)
    .hex();

  staticColors["dark"].accentLight = enterpriseData.colors.accent_light;
  staticColors["dark"].accent = enterpriseData.colors.accent_dark;
  staticColors["dark"].accentDark = chroma(enterpriseData.colors.accent_dark)
    .darken(1)
    .hex();
  staticColors["dark"].selectedTint = chroma(enterpriseData.colors.accent_light)
    .darken(3)
    .hex();
}

export async function continueWithUser(
  userData,
  valid,
  isEnterprise,
  _email,
  _password,
  navigation,
  stayLoggedIn,
  _auth = ""
) {
  if (isEnterprise) await loadCurrentEnterprise(userData);
  if (valid) {
    AnalyticsHandler.getInstance().logLogin();

    if (!userData) {
      navigation.reset({
        index: 0,
        routes: [{ name: "WaitingForRegistrationScreen" }],
      });
    } else if (!userData?.firstname && !isEnterprise) {
      navigation.reset({
        index: 0,
        routes: [{ name: "NewRegistryScreen" }],
      });
    } else if (userData) {
      if (stayLoggedIn) {
        setLogged(true, _email, _password, _auth);
      } else {
        setLogged(false, "", "", "");
      }
      const target = APP_TARGET;

      if (Platform.OS !== "web")
        await messaging().subscribeToTopic(userData.user_id);

      if (userData.pn_preference && Platform.OS !== "web") {
        for (const pref of userData.pn_preference) {
          switch (pref) {
            case "UPDATES":
              await messaging().subscribeToTopic("updates");
              break;
            case "MOTIVATIONAL":
              await messaging().subscribeToTopic("motivational");
              break;
          }
        }
      }

      navigation.reset({
        index: 0,
        routes: [{ name: target as any }],
      });
    }
  } else {
    // showInlineNotification({
    //   text: "Anmeldung fehlgeschlagen. Versuche es erneut!",
    //   type: InlineNotificationType.ERROR,
    //   direction: InlineNotificationDirection.FROM_TOP,
    // });
    // a
  }
}

export default function LoginScreen({ route }) {
  const enterpriseEmail = route.params?.email;
  const enterprisePassword = route.params?.password;

  const colorScheme = getColorScheme();
  const styles = useMemo(() => createStyles(colorScheme), [colorScheme]);
  const navigation = useNavigation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [pwVisible, setPwVisible] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const [stayLoggedIn, setStayLoggedIn] = useState(true);

  useEffect(() => {
    if (enterpriseEmail && enterprisePassword) {
      if (currentUser) logout(currentUser, null);

      loginUser(enterpriseEmail, enterprisePassword);
    }
  }, [enterpriseEmail, enterprisePassword]);

  const isReady = () => {
    if (password.length > 5) return true;
    return false;
  };

  const debouncedSetEmail = useCallback(debounce(setEmail, 100), []);

  async function loginUser(_email, _password) {
    Keyboard.dismiss();
    setIsWaiting(true);

    _password = sha512.sha512(_password);

    const response: any = await userLogin(_email.trim(), _password);

    await continueWithUser(
      response?.userData,
      response?.valid,
      response?.userData?.user_type === "enterprise-lite",
      _email,
      _password,
      navigation,
      stayLoggedIn
    );
    setIsWaiting(false);
  }

  return (
    <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        style={styles.keyboardAvoidingContainer}
        enabled={false}
      >
        <View style={styles.innerContainer}>
          <View style={styles.textInputContainer}>
            <TextInput
              textContentType={"username"}
              key={"email"}
              placeholder={I18n.t("email_or_id")}
              placeholderTextColor={staticColors[colorScheme].placeholder}
              style={styles.textField}
              keyboardType="email-address"
              onChangeText={(text) => debouncedSetEmail(text)}
            />
            <View style={{ width: "100%", maxWidth: Sizes.containerWidth }}>
              <TextInput
                placeholder={I18n.t("password")}
                textContentType={"password"}
                key={"password"}
                autoComplete="password"
                placeholderTextColor={staticColors[colorScheme].placeholder}
                style={[styles.textField, { width: "100%" }]}
                secureTextEntry={pwVisible}
                onChangeText={(text) => {
                  setPassword(text);
                }}
              />
              <TouchableOpacity
                style={{ position: "absolute", right: 20, top: 11 }}
                onPress={() => setPwVisible(!pwVisible)}
              >
                <Ionicons
                  name={pwVisible ? "eye-outline" : "eye-off-outline"}
                  size={24}
                  color={staticColors[colorScheme].text}
                />
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <CheckBox
                isSelected={stayLoggedIn}
                setSelected={setStayLoggedIn}
              />
              <TouchableOpacity onPress={() => setStayLoggedIn((s) => !s)}>
                <Text
                  style={{
                    marginLeft: 8,
                    fontWeight: "500",
                    fontSize: 14,
                    color: staticColors[colorScheme].text,
                  }}
                >
                  {I18n.t("stay_signed_in")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: Sizes.containerWidth,
                  marginTop: 30,
                }}
              >
                <DefaultButton
                  textStyle={{ fontWeight: "bold", fontSize: 16 }}
                  type={ButtonTypes.Primary}
                  title={I18n.t("sign_in")}
                  loading={isWaiting}
                  enabled={isReady()}
                  style={{ height: 50 }}
                  action={() => loginUser(email, password)}
                  icon={<Ionicons name="arrow-forward" size={24} />}
                />
              </View>
              <View
                style={{
                  flexDirection: "row",
                  paddingBottom: 10,
                  marginTop: 12,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate("ForgotPasswordScreen");
                  }}
                  style={{
                    padding: 12,
                    borderRadius: 20,
                    marginTop: 12,
                    backgroundColor: staticColors[colorScheme].foreground,
                  }}
                >
                  <Text style={styles.actionText}>{I18n.t("forgot_pw")}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
        <AgreeTermsView
          style={{ marginHorizontal: 32, alignSelf: "center" }}
          isLogin
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      backgroundColor: staticColors[scheme].background,
    },
    keyboardAvoidingContainer: {
      flex: 1,
      width: "100%",
    },
    textInputContainer: {
      width: "100%",
      alignItems: "center",
    },
    innerContainer: {
      width: "100%",
      paddingTop: 32,
      flex: 1,
      alignItems: "center",
      padding: Sizes.defaultContainerPadding,
      justifyContent: Platform.OS !== "web" ? "flex-start" : "center",
    },
    textField: {
      backgroundColor: staticColors[scheme].foreground,

      color: staticColors[scheme].text,

      borderRadius: 12,
      width: "100%",
      height: 50,
      padding: 12,

      marginBottom: 12,
      maxWidth: Sizes.containerWidth,
    },
    actionText: {
      marginLeft: 20,
      marginRight: 20,
      fontSize: 15,
      fontWeight: "400",
      color: staticColors[scheme].text,
    },
  });
  return styles;
};
