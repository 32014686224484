import { useMemo } from "react";
import { View } from "react-native";

import getColorScheme from "../../../hooks/useColorScheme";

import { getSharedStylesContent } from "../../boxes/SharedStyles";
import { BoxTextComponent } from "../../custom-box-text/CustomBoxTextComponent";

export const InlineTextType = ({ item }) => {
  const colorScheme = getColorScheme();

  return (
    <View style={{ marginTop: 8, marginHorizontal: -4, marginBottom: -20 }}>
      {useMemo(
        () => (
          <BoxTextComponent
            style={getSharedStylesContent("", colorScheme)}
            noBulletStyles
            content={item.content}
          />
        ),
        [item.content]
      )}
    </View>
  );
};
