import getPageContent from "../../../../api/content/get-page-content";

export function getSortedPatientsData(currentUser, query) {
  if (!currentUser) return [];
  let items = [...(currentUser.patients ?? [])];

  items = items.sort(
    (a, b) =>
      new Date(b.modified_at ?? b.created_at).getTime() -
      new Date(a.modified_at ?? a.created_at).getTime()
  );

  if (query) {
    items = items.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  return items;
}

export function getSortedExportData(currentUser) {
  if (!currentUser) return [];
  let items = [...(currentUser.user_pdf_export_urls ?? [])];

  items = items.sort(
    (a, b) =>
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  const enrichedData = items.map((item) => {
    const page = getPageContent(item.result_page_id);
    return {
      ...page,
      ...item,
    };
  });

  return enrichedData;
}
