import { I18n as _I18m } from "i18n-js";
import de from "./de";
import en from "./en";

const I18n = new _I18m({ de, en });

// // if (Platform.OS === "web" && window.location.href.includes("english-demo"))
// //   i18n.locale = "en";

I18n.locale = "de";
I18n.enableFallback = true;
I18n.defaultLocale = "de";

export default I18n;
