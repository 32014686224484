import { useCallback, useEffect, useRef, useState } from "react";
import {
  Animated,
  Dimensions,
  Keyboard,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CreateNotesScreenHeader from "../components/header/CreateNotesScreenHeaderComponent";
import { colors } from "../constants/static-colors";
import configureAnimations from "../functions/configure-animations";
import I18n from "../../locales/i18n";

function createContentStyle() {
  return {
    backgroundColor: colors.ui.cardBackground,
    color: colors.ui.textPrimary,
    caretColor: colors.ui.secondary,
    placeholderColor: colors.ui.textPlaceholder,
    contentCSSText: "font-size: 16px; min-height: 200px;",
  };
}

export function CreateNoteScreen({ route, navigation }) {
  const { note, currentDisplayID } = route.params;
  const richText = useRef<any>();

  const bottomSafeArea = useSafeAreaInsets().bottom;
  const [keyboardOffset, setKeyboardOffset] = useState(bottomSafeArea);
  const bottomToolbarOffset = useRef(new Animated.Value(0)).current;

  const [noteText, setNoteText] = useState(note?.note || "");
  const [noteID, setNoteID] = useState(note?.id || currentDisplayID);
  const [showCheck, setShowCheck] = useState(false);

  const handleChange = useCallback(
    (html) => {
      setShowCheck(note?.note !== html);
      setNoteText(html);
    },
    [note?.note]
  );

  const editorInitializedCallback = useCallback(() => {
    richText.current?.registerToolbar();
  }, []);

  useEffect(() => {
    if (!noteID) {
      setNoteID(currentDisplayID);
    }
  }, [currentDisplayID, noteID]);

  useEffect(() => {
    const onKeyboardShow = (event) => {
      const height = event.endCoordinates.height;

      setKeyboardOffset(height);
      configureAnimations();

      // Animate toolbar position
      Animated.timing(bottomToolbarOffset, {
        toValue: height,
        duration: 250,
        useNativeDriver: false,
      }).start();
    };

    const onKeyboardHide = () => {
      setKeyboardOffset(bottomSafeArea);
      configureAnimations();
      // Reset toolbar position
      Animated.timing(bottomToolbarOffset, {
        toValue: bottomSafeArea,
        duration: 250,
        useNativeDriver: false,
      }).start();
    };

    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardShow
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardHide
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [bottomToolbarOffset]);

  return (
    <SafeAreaView
      style={[styles.container, { backgroundColor: colors.ui.cardBackground }]}
    >
      {/* Header */}
      <CreateNotesScreenHeader
        note={{
          id: noteID,
          note: noteText,
          showInResult: true,
        }}
        navigation={navigation}
        showCheck={showCheck}
      />

      {/* Top Toolbar */}
      <RichToolbar
        editor={richText}
        style={[styles.richBar]}
        flatContainerStyle={styles.flatStyle}
        actions={[
          actions.undo,
          actions.redo,
          actions.alignLeft,
          actions.alignCenter,
          actions.alignRight,
        ]}
        selectedIconTint={colors.semantic.focus}
        disabledIconTint={colors.brand.greys.gD4D4D4}
      />

      {/* Editor Section */}
      <ScrollView
        style={[styles.scroll, { backgroundColor: colors.ui.cardBackground }]}
        keyboardShouldPersistTaps="handled"
      >
        <RichEditor
          ref={richText}
          style={styles.richEditor}
          editorStyle={createContentStyle()}
          initialContentHTML={note?.note || ""}
          onChange={handleChange}
          placeholder={I18n.t("write_your_note_here")}
          pasteAsPlainText={true}
          editorInitializedCallback={editorInitializedCallback}
        />
      </ScrollView>

      {/* Bottom Toolbar */}
      <Animated.View
        style={[
          styles.bottomToolbarContainer,
          { transform: [{ translateY: -keyboardOffset }] },
        ]}
      >
        <View style={[styles.bottomToolbar]}>
          <RichToolbar
            style={[styles.richBar]}
            flatContainerStyle={styles.flatStyle}
            editor={richText}
            actions={[
              actions.keyboard,
              actions.setBold,
              actions.setItalic,
              actions.setStrikethrough,
              actions.insertBulletsList,
              actions.insertOrderedList,
              actions.removeFormat,
            ]}
            selectedIconTint={colors.semantic.focus}
            disabledIconTint={colors.brand.greys.gD4D4D4}
          />
        </View>
      </Animated.View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  scroll: {
    flex: 1,
    width: "100%",
  },
  richEditor: {
    minHeight: Dimensions.get("screen").height - 250,
    flex: 1,
    paddingHorizontal: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ui.border,
  },
  richBar: {
    width: "100%",
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: colors.ui.border,
  },
  flatStyle: {
    paddingHorizontal: 12,
  },
  bottomToolbarContainer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  bottomToolbar: {
    width: "100%",
  },
});
