export interface GeneratedSource {
  link?: string;
  text: string;
  type: string;
}

export default function getGeneratedSource(
  source: any
): GeneratedSource | undefined {
  if (!source) return;

  const result: GeneratedSource = { text: "", type: source.item_type };

  switch (source.item_type) {
    case "Medizinische Fachinformation":
      if (source.links?.length > 0) {
        result.link = source.links[0].link || "#";
      } else if (source.attached_files?.length > 0) {
        result.link = source.attached_files[0].file_ref || "#";
      }
      result.text = `${source.source_data?.editor || ""}. ${
        source.title || ""
      }. ${getPublishingDate(
        source.source_data?.publishing_date
      )} [zitiert ${getCitationDate(source)}].`;
      break;

    case "Medizinisches Lehrbuch":
      result.text = `${source.source_data?.author_or_editor || ""}. ${
        source.title || ""
      }. ${source.source_data?.edition || ""}. ${
        source.source_data?.location || ""
      }: ${source.source_data?.publisher || ""}, ${getPublishingDate(
        source.source_data?.publishing_date
      )}.`;
      if (source.links?.[0]?.link) {
        result.link = source.links[0].link;
      }
      break;

    case "Medizinische Leitlinie":
      result.link = source.links?.[0]?.link || "#";
      result.text = `${source.source_data?.author_or_editor || ""}. ${
        source.title || ""
      }. ${source.source_data?.editor || ""}. ${getPublishingDate(
        source.source_data?.publishing_date
      )}. ${source.source_data?.edition || ""} [zitiert ${getCitationDate(
        source
      )}].`;
      break;

    case "Journal-Artikel / Artikel einer Fachzeitschrift":
      result.link = source.links?.[0]?.link || "#";
      result.text = `${source.source_data?.author_or_editor || ""}. ${
        source.title.trim() || ""
      }. ${source.source_data?.journals || ""}. ${getPublishingDate(
        source.source_data?.publishing_date
      )}; ${
        source.source_data?.band_and_volume || ""
      } [zitiert ${getCitationDate(source)}].`;
      break;

    case "Internetquelle":
      result.link = source.links?.[0]?.link || "#";
      const bandVolume = source.source_data?.band_and_volume
        ? source.source_data.band_and_volume + ". "
        : "";
      result.text = `${source.source_data?.author_or_editor || ""}. ${
        source.title.trim() || ""
      }. ${bandVolume}${getPublishingDate(
        source.source_data?.publishing_date
      )} [zitiert ${getCitationDate(source)}].`;
      break;

    case "PDF":
      result.link = source.links?.[0]?.link || "#";
      result.text = source.title || "";
      break;

    default:
      result.text = source.title || "";
      break;
  }

  // Remove any instances of "null"
  result.text = result.text.replace(/null/g, "");

  // Ensure no ".." or ". ." appears
  result.text = result.text.replace(/\.{2,}/g, ".").replace(/\. \./g, ".");
  result.text = result.text.replace(/ : /g, "");

  return result;
}

function getPublishingDate(dateString: string | undefined): string {
  if (!dateString) return "-";

  // Check if dateString is in ISO format (YYYY-MM-DDT00:00:00)
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}T00:00:00$/;
  if (isoDatePattern.test(dateString)) {
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString();
    }
  }

  return dateString;
}

function getCitationDate(source: any): string {
  const date = source.history.find(
    (item) => item.change_type === "creation"
  )?.modified_at;
  if (!date) return "-";

  return new Date(date).toLocaleDateString();
}

export interface TransformedSource {
  number: number;
  link?: string;
  text: string;
  type: string;
}

export function transformLegacySource(legacyHtml: string): TransformedSource[] {
  if (!legacyHtml) return [];

  // Use a global regex to match all <a> tags with href attributes.
  const anchorRegex = /<a[^>]+href="([^"]+)"[^>]*>([\s\S]*?)<\/a>/gi;
  const sources: TransformedSource[] = [];

  // Use matchAll to iterate over all matches.
  const matches = legacyHtml.matchAll(anchorRegex);
  for (const match of matches) {
    const link = match[1];
    let innerText = match[2].trim();

    // Extract a leading number followed by a dot if available.
    const numberRegex = /^(\d+)\.\s*(.*)$/;
    let number = 0;
    const numberMatch = innerText.match(numberRegex);
    if (numberMatch) {
      number = parseInt(numberMatch[1], 10);
      innerText = numberMatch[2].trim();
    }

    sources.push({
      number,
      link,
      text: innerText,
      type: "Quelle",
    });
  }

  return sources;
}
