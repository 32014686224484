import { StatusBar } from "expo-status-bar";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppState, LogBox, Platform, View } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider } from "react-redux";
import apiCall from "../api/api-call";
import staticColors from "../constants/static-colors";
import { store } from "../functions/store";
import useInitialAppStateLoading from "../hooks/loadInitialAppState";
import Navigation from "./NavigationIndex";
import Root, { RootProvider } from "./Root";

function App() {
  const isLoadingComplete = useInitialAppStateLoading();

  useEffect(() => {
    LogBox.ignoreAllLogs();
  }, []);

  useEffect(() => {
    const handleAppStateChange = (nextAppState) => {
      if (nextAppState === "active" && Platform.OS !== "web") {
        // Clear the app icon badge when the app is active
        //PushNotification.setApplicationIconBadgeNumber(0);
        // TODO: re-add remove all notification badges
        // Cancel any scheduled timeout if the app becomes active again
      }

      if (nextAppState === "background") {
        const currentUser = store.getState().userReducer.user;

        if (!currentUser) return;

        // Start a 1-minute delay when the app goes to the background

        // Make a request to your backend to trigger the push notification
        apiCall("/api/v4/push/trigger_background_pn/" + currentUser.user_id);
      }
    };

    const appStateListener = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      appStateListener.remove();
    };
  }, []);

  useEffect(() => {
    if (Platform.OS !== "web") return;

    const handleNavigation = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want to go back?";
    };

    window.addEventListener("beforeunload", handleNavigation);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handleNavigation);
    };
  }, []);

  if (!isLoadingComplete) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          flex: 1,
        }}
      ></View>
    );
  } else {
    return (
      <RootProvider>
        <Root>
          {Platform.OS === "web" && !__DEV__ && (
            <Helmet>
              <script type="text/javascript">{`
    !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
    posthog.init('phc_RYChOHs7WUKynt9w1LPcoGJgDKeVThBpV2kMBLJES6i',{api_host:'https://eu.posthog.com'})`}</script>
              <script type="text/javascript">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PSNCNFLF');`}</script>
            </Helmet>
          )}

          <Provider store={store}>
            <SafeAreaProvider
              style={{
                backgroundColor: "black",
                flex: 1,
                ...Platform.select({
                  web: { maxHeight: "100vh" as any },
                }),
              }}
            >
              <View
                style={{
                  flex: 1,
                  ...Platform.select({
                    web: {
                      backgroundColor: staticColors["light"].background,
                    },
                  }),
                }}
              >
                <View
                  style={{
                    maxWidth: 1300,
                    flex: 1,
                    alignSelf: "center",
                    width: "100%",
                    ...Platform.select({
                      web: {
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)", // Apply shadow on web
                        borderRadius: 8, // Optional: Adds rounded corners
                      },
                    }),
                  }}
                >
                  <PaperProvider>
                    <Navigation />
                    <StatusBar />
                  </PaperProvider>
                </View>
              </View>
            </SafeAreaProvider>
          </Provider>
        </Root>
      </RootProvider>
    );
  }
}

export default App;
