import { useEffect, useState } from "react";
import {
  Image,
  LayoutAnimation,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { batch, useDispatch, useSelector } from "react-redux";
import I18n from "../../locales/i18n";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  setResultingCalculation,
} from "../functions/calculator/actions";
import {
  setCurrentResultPage,
  setIsExporting,
  setShowTitle,
  shouldShowSources,
} from "../functions/current-result/actions";
import {
  getResultPagesCount,
  increaseResultPagesCount,
} from "../functions/current-result/helpers";
import getFontStyle from "../functions/getFontSize";
import { selectIsDemoMode } from "../functions/navigation/actions";
import { selectCurrentUser } from "../functions/user/actions";
import {
  addToUserHistory,
  showRatingIfApplicable,
} from "../functions/user/functions";
import ResultPageHeader, {
  formatDateToDate,
} from "./header/ResultPageHeaderComponent";
import RatingModal from "./modals/RatingModal";
import WarningModal from "./modals/WarningModal";
import WorkInProgressModal from "./modals/WorkInProgressModal";
import { Feather } from "@expo/vector-icons";

interface HistoryUseEffectObj {
  customFunction: (() => void) | null;
  addToHistory?: boolean;
  logUserScreenInteraction: {
    screen: string;
    action: string;
    values: string;
    id: string;
  };
}

interface ResultPagesWrapperProps {
  readonly navigation: any;
  readonly children: React.ReactNode;
  readonly pageContent: any;
  readonly warningModalVisible;
  readonly setWarningModalVisible: any;
  readonly historyUseEffectObj: HistoryUseEffectObj | null;

  readonly scrollViewRef?: any;
  readonly isModal?: boolean;
  readonly onScroll?: (event: any) => void;
  readonly scrollEventThrottle?: number;
  readonly onContentSizeChange?: (width: number, height: number) => void;
  readonly onLayout?: (event: any) => void;
}

export function ResultPagesWrapper({
  navigation,
  children,
  pageContent,
  warningModalVisible,
  setWarningModalVisible,
  historyUseEffectObj,
  scrollViewRef,
  isModal = false,
  onScroll,
  scrollEventThrottle,
  onContentSizeChange,
  onLayout,
}: ResultPagesWrapperProps) {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [didShowRating, setDidShowRating] = useState(false);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [workInProgressModalVisible, setWorkInProgressModalVisible] =
    useState(false);
  const isDemoMode = useSelector(selectIsDemoMode);
  const patientData = useSelector(selectPatientData);

  const inset = useSafeAreaInsets();

  const toggleWarningModal = () => {
    setWarningModalVisible((m) => !m);
  };

  async function checkIfRating() {
    if (didShowRating) return true;

    if (await showRatingIfApplicable()) {
      setRatingModalVisible(true);
      setDidShowRating(true);
      return false;
    }

    return true;
  }

  // Gemeinsame Methode zum Verlassen der Seite
  function storeActionsOnLeave() {
    dispatch(setShowTitle(false));
    batch(() => {
      if (!patientData) dispatch(clearAmount());
      dispatch(shouldShowSources(false));
      dispatch(setResultingCalculation(null));
      dispatch(setCurrentResultPage(null));
      dispatch(setIsExporting(false));
    });
  }

  // Animation entfernen, um wiederholte Logik zu sparen
  function removeAnimation() {
    LayoutAnimation.configureNext({
      duration: 120,
      create: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
  }

  useEffect(() => {
    dispatch(setShowTitle(false)); // Einheitliche Logik für ShowTitle
    increaseResultPagesCount();

    const shouldAddToHistory = historyUseEffectObj?.addToHistory ?? true;
    if (shouldAddToHistory) {
      addToUserHistory(historyUseEffectObj?.logUserScreenInteraction?.id);
    }

    if (historyUseEffectObj?.customFunction)
      historyUseEffectObj?.customFunction();

    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        historyUseEffectObj?.logUserScreenInteraction?.action,
        historyUseEffectObj?.logUserScreenInteraction?.screen,
        historyUseEffectObj?.logUserScreenInteraction?.values,
        historyUseEffectObj?.logUserScreenInteraction?.id
      );
      dispatch(setCurrentResultPage(pageContent));
    });

    const unsubscribeBeforeRemove = navigation.addListener(
      "beforeRemove",
      async (e) => {
        e.preventDefault();
        removeAnimation();

        const openedPages = getResultPagesCount();

        if (
          currentUser.has_rated ||
          openedPages % 5 !== 0 ||
          (await checkIfRating())
        ) {
          navigation.dispatch(e.data.action);
          storeActionsOnLeaveAsync();
        }
      }
    );

    return () => {
      unsubscribe();
      unsubscribeBeforeRemove();
    };
  }, [didShowRating]);

  async function storeActionsOnLeaveAsync() {
    storeActionsOnLeave();
  }

  useEffect(() => {
    if (!pageContent) return;

    if (isDemoMode) return;
    if (pageContent.internal_rating === 0) {
      setWorkInProgressModalVisible(true);
    }
  }, [pageContent, currentUser]);

  if (pageContent === undefined) return null;

  return (
    <>
      <RatingModal
        open={ratingModalVisible}
        onClose={() => {
          setRatingModalVisible(false);
          navigation.goBack();
        }}
      />
      <WarningModal open={warningModalVisible} onClose={toggleWarningModal} />

      <WorkInProgressModal
        setClosed={setWorkInProgressModalVisible}
        open={workInProgressModalVisible}
        onClose={() => {
          navigation.goBack();
          setWorkInProgressModalVisible(false);
        }}
      />
      {isModal && (
        <TouchableOpacity
          onPress={() => navigation.goBack()}
          style={{
            position: "absolute",
            left: Sizes.defaultContainerPadding,
            top: Sizes.defaultContainerPadding,
            zIndex: 1000,
            padding: 8,
            borderRadius: 20,
            backgroundColor: colors.ui.cardBackground,
            shadowColor: "#000",
            shadowOffset: { width: 1, height: 1 },
            shadowOpacity: 0.1,
            shadowRadius: 3,
            elevation: 2,
          }}
        >
          <Feather name="x" size={24} color={colors.ui.textPrimary} />
        </TouchableOpacity>
      )}
      <ScrollView
        ref={scrollViewRef}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        onScroll={onScroll}
        scrollEventThrottle={scrollEventThrottle}
        onContentSizeChange={onContentSizeChange}
        onLayout={onLayout}
        style={{
          width: "100%",
          flex: 1,
          backgroundColor: colors.ui.background,
          ...Platform.select({ ios: { overflow: "visible" } }),
        }}
        contentContainerStyle={{
          width: "100%",
          flexGrow: 1,
          paddingBottom: inset.bottom + 20,
        }}
        showsVerticalScrollIndicator={false}
      >
        <ResultPageHeader isModal={isModal} />

        {children}
        {pageContent.type !== "nodePage" && pageContent.last_modified_at && (
          <Text
            style={{
              paddingHorizontal: Sizes.defaultContainerPadding,
              fontSize: Sizes.boxText - 2,
              color: colors.ui.textSecondary,
              alignSelf: "flex-end",
              fontFamily: "HostGrotesk-Regular",
              marginBottom: 12,
            }}
          >
            Zuletzt aktualisiert:{" "}
            {formatDateToDate(pageContent.last_modified_at)}
          </Text>
        )}
        {pageContent?.tags?.length > 0 && (
          <View style={styles.tagsContainer}>
            <Text
              style={{
                fontSize: Sizes.boxText,

                marginBottom: 12,
                ...getFontStyle(600),
              }}
            >
              Tags
            </Text>
            <View style={styles.tagsList}>
              {pageContent.tags.map((tag, index) => (
                <View key={`${tag}-${index}`} style={styles.tag}>
                  <Text style={styles.tagText}>{tag}</Text>
                </View>
              ))}
            </View>
          </View>
        )}
        {isDemoMode && (
          <View
            style={{
              padding: 16,
              alignSelf: "center",

              alignItems: "center",
              gap: 12,
            }}
          >
            <Image
              source={require("../../assets/images/ce.png")}
              style={{
                width: 50,
                height: 50,
                resizeMode: "contain",
                opacity: 0.2,
              }}
            />
            <Text
              style={{
                fontSize: 12,
                color: "#999",
                fontFamily: "HostGrotesk-Regular",
              }}
            >
              {I18n.t("class_I_medical_device")}
            </Text>
          </View>
        )}
      </ScrollView>
      {/* Tags Section */}
    </>
  );
}

const styles = StyleSheet.create({
  tagsContainer: {
    marginTop: 20,
    paddingHorizontal: Sizes.defaultContainerPadding,
    paddingBottom: 20,
  },
  tagsList: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8, // Ensure spacing between tags
  },
  tag: {
    backgroundColor: colors.brand.greys.gE5E5E5, // Subtle background color for minimal look
    borderRadius: 12,
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginBottom: 8,
  },
  tagText: {
    fontSize: 10,
    color: "#333", // Muted text color for minimal style
    fontFamily: "HostGrotesk-Regular",
  },
});
