import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";

import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { Dimensions, Platform } from "react-native";

import { Feather } from "@expo/vector-icons";
import { colors } from "../constants/static-colors";
import { RootStackParamList, RootTabParamList } from "../types/types";

import DefaultBackHeader from "../components/header/DefaultBackHeaderComponent";
import ResultPageHeader from "../components/header/ResultPageHeaderComponent";

import CMSNodePageScreen from "../screens/CMSNodePageScreen";
import { CreateNoteScreen } from "../screens/CreateNoteScreen";
import FeedbackScreen from "../screens/FeedbackScreen";

import InfoResultPageScreen from "../screens/InfoResultPageScreen";
import LaunchScreen from "../screens/LaunchScreen";
import { ForgotPasswordScreen } from "../screens/login-registry/ForgotPasswordScreen";
import LandingScreen from "../screens/login-registry/LandingScreen/LandingScreen";
import LoginScreen from "../screens/login-registry/LoginScreen";
import { MobileFirstLandingScreen } from "../screens/login-registry/MobileFirstLanding";
import { PreLoginDatenschutz } from "../screens/login-registry/PreLoginDatenschutz";
import { PreLoginPrivacyPolicy } from "../screens/login-registry/PreLoginPrivacyPolicy";
import EnterLoginDataScreen from "../screens/login-registry/registry/EnterLoginData";
import NewRegistryScreen from "../screens/login-registry/registry/EnterUserInformationScreen";
import { RoleSelectionScreen } from "../screens/login-registry/RoleSelectionScreen";
import { WaitingForRegistrationScreen } from "../screens/login-registry/WaitingForRegistrationScreen";

import ResultPageScreen from "../screens/ResultPageScreen/ResultPageScreen";
import { TippsDetailScreen } from "../screens/TippsDetailScreen";
import TippsScreen from "../screens/TippsScreen";

import ScoreScreen from "../screens/ScoreScreen/ScoreScreen";
import HomeScreen from "../screens/tab-navigator-screens/HomeScreen/HomeScreen";
import SearchScreen from "../screens/tab-navigator-screens/Search/SearchScreen";

import UnitCalculatorScreen from "../screens/toolbox/unit-calculator/UnitCalculatorScreen";
import UnitCalculatorSubstancesScreen from "../screens/toolbox/unit-calculator/UnitCalculatorSubstancesScreen";
import { WebBrowserScreen } from "../screens/WebBrowserScreen";

import i18n from "../../locales/i18n";

import StoryScreen from "../screens/ChangelogStoryScreen";

import EnterAdditionalUserInformationScreen from "../screens/EnterAdditionalUserInformationScreen";

import SelectionInformationFromListComponent from "../screens/login-registry/registry/AdditionalInformation/SelectionInformationFromListComponent";
import UserEngagementScreen from "../screens/login-registry/registry/UserEngagementScreen";

import MoreScreen from "../screens/more/MoreScreen";
import { AccountScreen } from "../screens/more/profile/AccountScreen";
import { ImpressScreen } from "../screens/more/profile/legal/ImpressScreen";
import { LegalScreen } from "../screens/more/profile/legal/LegalScreen";
import { PrivacyPolicyScreen } from "../screens/more/profile/legal/PrivacyPolicyScreen";
import { TermsAndConditionsScreen } from "../screens/more/profile/legal/TermsAndConditionsScreen";
import DefaultScreen from "../screens/NavigationScreens/DefaultScreen";

import AddPatientScreen from "../screens/AddPatientScreen";
import ChecklistScreen from "../screens/ChecklistScreen/ChecklistScreen";
import ExportPreviewScreen from "../screens/ExportPreviewScreen";
import FavoritesScreen from "../screens/FavoritesScreen";
import GlobalSearchScreen from "../screens/GlobalSearchScreen";
import NonMedicalRegistrationScreen from "../screens/login-registry/NonMedicalRegistryScreen";
import GoogleMapsLocationAutoCompleteInputComponent from "../screens/login-registry/registry/AdditionalInformation/GoogleMapsLocationAutoCompleteInputComponent";
import EnterMissingInformationModalScreen from "../screens/login-registry/registry/EnterMissingInformationModal";
import { SoftChangeLogScreen } from "../screens/login-registry/SoftChangeLogScreen";
import ResultPageDemo from "../screens/NavigationScreens/ResultPageDemoRouter";
import NotesOverviewScreen from "../screens/NotesOverviewScreen";
import PushNotificationScreen from "../screens/PushNotificationScreen";
import PersonalContentScreen from "../screens/tab-navigator-screens/PersonalContent/PersonalContentScreen";
import UpdatePopover from "../screens/UpdateEnforceScreen";

//import dynamicLinks from "@react-native-firebase/dynamic-links";
import MobileTabBar from "../components/DefaultTabBar/components/MobileTabBar";
import { DataProvider } from "../components/partnerships/PartnerDataProvider";
import { ExpansionProvider } from "../components/ResultPageSkeleton/ExpansionProvider";
import { FlowchartScreen } from "../screens/FlowchartScreen/FlowchartScreen";
import HistoryScreen from "../screens/HistoryScreen";
import OnboardingScreen from "../screens/OnboardingScreen/OnboardingScreen";
import PatientsScreen from "../screens/PatientsScreen";
import StoriesScreen from "../screens/TutorialScreen/TutorialScreen";

import LiveContentPreviewScreen from "../screens/LiveContentPreviewScreen";
import { NotificationScreen } from "../screens/NotificationScreen";

const isMobileWeb =
  Platform.OS === "web" && Dimensions.get("window").width < 500;

export default function Navigation() {
  // useEffect(() => {
  //   const handleDynamicLink = async () => {
  //     console.log("Handling dynamic link");
  //     const link = await dynamicLinks().getInitialLink();
  //     console.log("LINK", link);
  //     if (link) {
  //       const urlParams = new URLSearchParams(link.url.split("?")[1]);
  //       const utmSource = urlParams.get("utm_source");
  //       const utmMedium = urlParams.get("utm_medium");
  //       const utmCampaign = urlParams.get("utm_campaign");
  //       console.log(utmSource, utmMedium, utmCampaign);
  //       // Hier die UTM-Parameter an HubSpot oder lokal speichern
  //     }
  //   };

  //   handleDynamicLink();
  // }, []);

  const linking = {
    prefixes: [
      "com.mediceo.app://",
      "app.mediceo.com://",
      "app.lando.health://",
    ],
    config: {
      screens: {
        ResultPageDemo: "direct",
        LaunchScreen: "*",
        LiveContentPreviewScreen: "content-preview",
      },
    },
  };

  return (
    <NavigationContainer
      linking={linking as any}
      fallback={LaunchScreen as any}
    >
      {/* <NotificationHandlingWrapper>
        <PostHogProvider client={posthogAsync as any}> */}
      <DataProvider>
        <ExpansionProvider>
          <RootNavigator />
        </ExpansionProvider>
      </DataProvider>
      {/* </PostHogProvider>
      </NotificationHandlingWrapper>  */}
    </NavigationContainer>
  );
}

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={
        isMobileWeb ? "MobileFirstLandingScreen" : "LaunchScreen"
      }
    >
      <Stack.Group
        screenOptions={{
          headerTintColor: colors.ui.textPrimary,
          headerStyle: {
            backgroundColor: colors.ui.background,
          },
        }}
      >
        <Stack.Screen
          name="LaunchScreen"
          component={LaunchScreen}
          options={{
            headerShown: false,
            title: "LANDO",
            animation: "none",
          }}
        />
        <Stack.Screen
          name="MobileFirstLandingScreen"
          component={MobileFirstLandingScreen}
          options={{ headerShown: false, title: "LANDO" }}
        />
        <Stack.Screen
          name="LandingScreen"
          component={LandingScreen}
          options={{ headerShown: false, title: "LANDO" }}
        />
        <Stack.Screen
          name="TutorialScreen"
          component={StoriesScreen}
          options={{ headerShown: false, title: "LANDO" }}
        />
        <Stack.Screen
          name="WaitingForRegistrationScreen"
          component={WaitingForRegistrationScreen}
          options={{ headerShown: false, title: "LANDO" }}
        />
        <Stack.Screen
          name="PreLoginDatenschutz"
          component={PreLoginDatenschutz}
          options={{ headerShown: true, title: "LANDO" }}
        />
        <Stack.Screen
          name="PreLoginPrivacyPolicy"
          component={PreLoginPrivacyPolicy}
          options={{ headerShown: true, title: "LANDO" }}
        />
        <Stack.Screen
          options={{
            title: "LANDO",
            header: () => <DefaultBackHeader title={i18n.t("log_in")} />,
          }}
          name="LoginScreen"
          component={LoginScreen}
        />
        <Stack.Screen
          options={{
            title: "LANDO",
            header: () => <DefaultBackHeader title={""} />,
          }}
          name="NonMedicalRegistrationScreen"
          component={NonMedicalRegistrationScreen}
        />
        <Stack.Screen
          name="NewRegistryScreen"
          component={NewRegistryScreen}
          options={{
            title: "LANDO",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="EnterLoginDataScreen"
          component={EnterLoginDataScreen}
          options={{
            title: "LANDO",
            header: () => <DefaultBackHeader />,
          }}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={{
            title: i18n.t("reset_password.title"),
            header: () => (
              <DefaultBackHeader title={i18n.t("reset_password.title")} />
            ),
          }}
        />
        <Stack.Screen
          name="SelectionInformationFromListComponent"
          component={SelectionInformationFromListComponent}
          options={{
            presentation: "modal",
            headerShown: false,
            title: "Auswahl",
          }}
        />
        <Stack.Screen
          name="EnterMissingInformationModalScreen"
          component={EnterMissingInformationModalScreen}
          options={{
            presentation: "card",
            headerShown: false,
            title: "",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          name="RoleSelectionScreen"
          component={RoleSelectionScreen}
          options={() => ({
            header: () => <DefaultBackHeader />,
            title: "LANDO",
          })}
        />
      </Stack.Group>
      <Stack.Group
        screenOptions={{
          title: "LANDO",
        }}
      >
        <Stack.Screen
          name="ResultPageScreen"
          component={ResultPageScreen}
          options={{ header: () => null, title: "LANDO" }}
        />
        <Stack.Screen
          name="ChecklistScreen"
          component={ChecklistScreen}
          options={({ route }) => ({
            header: () => null,
            title: "LANDO",
            presentation: route.params?.modal ? "modal" : "card", // Use 'card' or 'fullScreenModal' depending on what you want
          })}
        />
        <Stack.Screen
          name="AddPatientScreen"
          component={AddPatientScreen}
          options={() => ({
            header: () => <DefaultBackHeader isModal />,
            title: "LANDO",
            presentation: "modal",
          })}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            presentation: "containedModal",
          }}
          name="StoryScreen"
          component={StoryScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="DefaultScreen"
          component={DefaultScreen}
        />

        <Stack.Screen
          options={{ title: "LANDO", header: () => null }}
          name="LiveContentPreviewScreen"
          component={LiveContentPreviewScreen}
        />

        <Stack.Screen
          options={{ title: "LANDO", header: () => null }}
          name="FlowchartScreen"
          component={FlowchartScreen}
        />

        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="ResultPageDemo"
          component={ResultPageDemo}
        />
        <Stack.Screen
          options={({ route }) => ({
            headerShown: false,
            gestureEnabled: false,
            presentation: route.params?.useCardPresentation
              ? "card"
              : "containedModal",
          })}
          name="PushNotificationScreen"
          component={PushNotificationScreen}
        />
        <Stack.Screen
          options={({ route }) => ({
            headerShown: false,
            gestureEnabled: false,
            presentation: route.params?.useCardPresentation
              ? "card"
              : "containedModal",
          })}
          name="UpdateEnforcerScreen"
          component={UpdatePopover}
        />

        <Stack.Screen
          name="GoogleMapsLocationAutoCompleteInputComponent"
          component={GoogleMapsLocationAutoCompleteInputComponent}
          options={{
            presentation: "modal",
            title: "Auswahl",
            gestureEnabled: false,
          }}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader />,
          }}
          name="TippsScreen"
          component={TippsScreen}
        />
        <Stack.Screen
          options={{ header: () => <DefaultBackHeader />, title: "LANDO" }}
          name="TippsDetailScreen"
          component={TippsDetailScreen}
        />
        <Stack.Screen
          name="CMSNodePageScreen"
          component={CMSNodePageScreen}
          options={{ title: "LANDO", header: () => null }}
        />
        <Stack.Screen
          name="InfoResultPageScreen"
          component={InfoResultPageScreen}
          options={{
            headerShown: false,
            title: "LANDO",
            presentation: "modal",
          }}
        />
        <Stack.Screen
          name="CreateNoteScreen"
          component={CreateNoteScreen}
          options={{
            headerShown: false,
            title: "LANDO",
            presentation: "modal",
          }}
        />
        <Stack.Screen
          name="NotificationScreen"
          component={NotificationScreen}
          options={{
            title: "LANDO",
            header: () => <DefaultBackHeader title="Benachrichtigungen" />,
          }}
        />
        <Stack.Screen
          name="ScoreScreen"
          component={ScoreScreen}
          options={({ route }) => ({
            header: () => null,

            title: "LANDO",
            presentation: route.params?.modal ? "modal" : "card", // Use 'card' or 'fullScreenModal' depending on what you want
          })}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Impressum" />,
            title: "LANDO",
          }}
          name="ImpressScreen"
          component={ImpressScreen}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Datenschutzerklärung" />,
            title: "LANDO",
          }}
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
        />
        <Stack.Screen
          options={{
            header: () => <DefaultBackHeader title="Nutzungsbedingungen" />,
            title: "LANDO",
          }}
          name="TermsAndConditionsScreen"
          component={TermsAndConditionsScreen}
        />
        <Stack.Screen
          name="FeedbackScreen"
          component={FeedbackScreen}
          options={{ presentation: "containedModal", headerShown: false }}
        />

        <Stack.Screen
          name="WebBrowserScreen"
          component={WebBrowserScreen}
          options={{ presentation: "modal", headerShown: false }}
        />
        <Stack.Screen
          name="ExportPreviewScreen"
          component={ExportPreviewScreen}
          options={{ presentation: "modal", headerShown: false }}
        />
        <Stack.Screen
          name="MoreScreen"
          component={MoreScreen}
          options={{
            header: () => <DefaultBackHeader title="Account & Einstellungen" />,
          }}
        />

        <Stack.Screen
          name="LegalScreen"
          component={LegalScreen}
          options={{
            header: () => <DefaultBackHeader title="Rechtliches" />,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="SoftChangeLogScreen"
          component={SoftChangeLogScreen}
          options={{
            header: () => <DefaultBackHeader title="Update History" />,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="AccountScreen"
          component={AccountScreen}
          options={{
            header: () => <DefaultBackHeader title="Mein Konto" />,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="GlobalSearchScreen"
          component={GlobalSearchScreen}
          options={{
            header: () => null,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="FavoritesScreen"
          component={FavoritesScreen}
          options={{
            header: () => <DefaultBackHeader title="Meine Favoriten" />,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="PatientsScreen"
          component={PatientsScreen}
          options={({ route }) => ({
            header: () => (
              <DefaultBackHeader
                title="Meine Patienten"
                isModal={route.params?.modal}
              />
            ),
            title: "LANDO",
            presentation: route.params?.modal ? "modal" : "card", // Use 'card' or 'fullScreenModal' depending on what you want
          })}
        />
        <Stack.Screen
          name="HistoryScreen"
          component={HistoryScreen}
          options={{
            header: () => <DefaultBackHeader title="Mein Verlauf" />,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          name="NotesOverviewScreen"
          component={NotesOverviewScreen}
          options={{
            header: () => <DefaultBackHeader title="Meine Notizen" />,
            title: "LANDO",
          }}
        />

        <Stack.Screen
          options={{
            title: "LANDO",
            headerShown: false,
            presentation: "modal",
          }}
          name="EnterAdditionalUserInformationScreen"
          component={EnterAdditionalUserInformationScreen}
        />
        <Stack.Screen
          options={{
            title: "LANDO",
            header: () => <ResultPageHeader />,
          }}
          name="UnitCalculatorScreen"
          component={UnitCalculatorScreen}
        />
        <Stack.Screen
          options={{
            title: "LANDO",
            header: () => <ResultPageHeader />,
          }}
          name="UnitCalculatorScreenSubstances"
          component={UnitCalculatorSubstancesScreen}
        />
        <Stack.Screen
          options={{
            headerShown: false,
          }}
          name="UserEngagementScreen"
          component={UserEngagementScreen}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={{
            header: () => null,
            title: "LANDO",
          }}
        />
        <Stack.Screen
          options={{
            headerShown: false,
            presentation: "modal",
          }}
          name="OnboardingScreen"
          component={OnboardingScreen}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const tabBarIconSize = 22;

  return (
    <BottomTab.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: false,
        title: "LANDO",
      }}
      tabBar={(props) => <MobileTabBar {...props} />}
    >
      <BottomTab.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{
          tabBarLabel: "Home",
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"home"} size={tabBarIconSize} />
          ),
        }}
      />
      <BottomTab.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={{
          tabBarLabel: i18n.t("content"),
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"grid"} size={tabBarIconSize} />
          ),
        }}
      />

      <BottomTab.Screen
        name="PersonalContentScreen"
        component={PersonalContentScreen}
        options={{
          tabBarLabel: i18n.t("more"),
          tabBarIcon: ({ color }) => (
            <Feather color={color} name={"user"} size={tabBarIconSize} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}
