import { useEffect, useState } from "react";
import { Text, View } from "react-native";

import ScoreElementMultipleComponent from "./ScoreElementMultipleComponent";

import { Feather } from "@expo/vector-icons";
import I18n from "../../../../locales/i18n";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import ScoreElementToContent from "./ScoreElementToContent";

export default function ScoreElementConditionalComponent({
  data,
  setCurrentScore,
  index,
  setAnswers,

  answers,
}) {
  const [answerIndex, setAnswerIndex] = useState<number | null>(null); // 0 NEGATIVE, 1 POSITIVE, null NOT SET
  const itemData = getSelectedAnswer(data, answerIndex);

  const mappedData: any = {
    title: data.conditionTitle,
    scoreSelectionItems: [
      {
        value: null,
        title: data.positiveAnswerTitle,
      },
      {
        value: null,
        title: data.negativeAnswerTitle,
      },
    ],
  };

  useEffect(() => {
    if (answers.find((item) => item.value === data.negativeAnswerTitle))
      setAnswerIndex(0);
    else if (answers.find((item) => item.value === data.positiveAnswerTitle))
      setAnswerIndex(1);
  }, [answers]);

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.ui.border,
        borderRadius: 12,
        margin: 2,
        padding: 6,
        paddingBottom: answerIndex !== null ? 0 : 6,
        marginBottom: 20,
      }}
    >
      <ScoreElementMultipleComponent
        key={index}
        answers={answers}
        setAnswers={setAnswers}
        setCurrentScore={setCurrentScore}
        data={mappedData}
      />

      <Feather
        name="chevron-down"
        size={24}
        color={colors.ui.textSecondary}
        style={{ alignSelf: "center", marginBottom: 20 }}
      />
      {answerIndex !== null ? (
        <ScoreElementToContent
          setScore={setCurrentScore}
          answers={answers}
          setAnswers={setAnswers}
          index={index}
          scoreElement={itemData}
          isEuroscore={false}
          style={{
            marginBottom: 6,
          }}
        />
      ) : (
        <View
          style={{
            backgroundColor: colors.ui.cardBackground,
            padding: 16,
            borderWidth: 1,
            borderRadius: 12,
            borderColor: colors.ui.border,
          }}
        >
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              color: colors.ui.textPrimary,
              fontSize: Sizes.boxText,
            }}
          >
            {I18n.t("element_dependency_previous_selection")}
          </Text>
        </View>
      )}
    </View>
  );
}

function getSelectedAnswer(data, answerIndex) {
  if (answerIndex === 0) {
    return data.negativeConditionScoreElement[0];
  } else {
    return data.positiveConditionScoreElement[0];
  }
}
