import { RefreshControl } from "react-native";

import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import alert from "../components/CustomAlert";

import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import configureAnimations from "../functions/configure-animations";
import { selectCurrentUser } from "../functions/user/actions";
import { deletePatient, userLogin } from "../functions/user/functions";
import getColorScheme from "../hooks/useColorScheme";

import getPageContent from "../api/content/get-page-content";
import { PatientDataHeaderComponent } from "../components/header/header-components/PatientDataHeaderComponent";
import { formatDate } from "../components/header/ResultPageHeaderComponent";
import {
  clearAmount,
  fetchAndUpdateAmountsAndUnits,
  selectPatientData,
  setPatientId,
} from "../functions/calculator/actions";
import { setCurrentResultPage } from "../functions/current-result/actions";
import { navigateToNextCMSPage } from "../functions/navigation/helpers";
import { getSortedPatientsData } from "./tab-navigator-screens/PersonalContent/components/functions";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";

const PatientsScreen = () => {
  const currentUser = useSelector(selectCurrentUser);

  const patientData = useSelector(selectPatientData);

  const [editModeActive, setEditModeActive] = useState(false);
  const [searchModeActive, setSearchModeActive] = useState(false);

  const navigation = useNavigation();

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState(false);

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "screen_opened",
      "PatientsScreen",
      "ScreenOpened"
    );
  }, []);

  const debouncedDataOrganizer = useCallback(() => {
    let _data = getSortedPatientsData(currentUser, query);
    if (patientData) {
      _data = _data.filter((p) => p.id !== patientData.id);
    }
    if (JSON.stringify(_data) !== JSON.stringify(data)) setData(_data);
  }, [currentUser, patientData, query]);

  const colorScheme = getColorScheme();

  useEffect(() => {
    debouncedDataOrganizer();
  }, [currentUser.patients, patientData, query]);

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    userLogin(currentUser.email, currentUser.password).finally(() =>
      setRefreshing(false)
    );
  }, []);

  return (
    <ScrollView
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ paddingBottom: 130, flexGrow: 1 }}
      style={{ flex: 1, paddingHorizontal: Sizes.defaultContainerPadding }}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
          size={20}
        />
      }
    >
      <View
        style={{
          alignSelf: "stretch",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 24,
        }}
      >
        {!patientData ? (
          <DefaultButton
            title={"Patienten anlegen"}
            action={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "add_patient",
                "PatientsScreen",
                "User Added Patient"
              );
              navigation.navigate("AddPatientScreen");
            }}
            type={ButtonTypes.Primary}
            style={{ width: "100%" }}
            icon={<Feather name="plus" size={20} color="black" />}
          />
        ) : (
          <View
            style={{
              flex: 1,
              alignSelf: "stretch",
            }}
          >
            <Text
              style={{
                fontWeight: "bold",
                fontSize: Sizes.boxText,
                flex: 1,
                color: Colors[colorScheme].text,
                marginBottom: 12,
              }}
            >
              Aktueller Patient
            </Text>
            <PatientDataHeaderComponent
              style={{
                marginVertical: 0,
              }}
            />
          </View>
        )}
      </View>
      {currentUser.patients?.length > 0 && (
        <View
          style={{
            marginTop: 20,
            flexDirection: "row",
            alignItems: "flex-end",
            marginBottom: 8,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: Sizes.boxText,
              flex: 1,
              color: Colors[colorScheme].text,
            }}
          >
            Patientenhistorie
          </Text>
          {((currentUser.patients?.length !== 0 && data.length !== 0) ||
            query) && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 12,
              }}
            >
              <TouchableOpacity
                style={{
                  padding: 8,
                  paddingHorizontal: 16,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.1,
                  elevation: 2,
                  borderRadius: 50,
                  shadowRadius: 2.62,
                  backgroundColor: editModeActive
                    ? Colors[colorScheme].lightGray
                    : Colors[colorScheme].foreground,
                  flexDirection: "row",
                  gap: 8,
                  alignItems: "center",
                }}
                activeOpacity={0.7}
                onPress={() => {
                  configureAnimations();
                  setEditModeActive((e) => !e);
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "edit_mode",
                    "PatientsScreen",
                    "UserToggledEditMode"
                  );
                }}
              >
                <Feather
                  name="trash-2"
                  size={20}
                  color={Colors[colorScheme].text}
                />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  padding: 8,
                  paddingHorizontal: 16,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.1,
                  elevation: 2,
                  borderRadius: 50,
                  shadowRadius: 2.62,
                  backgroundColor: searchModeActive
                    ? Colors[colorScheme].lightGray
                    : Colors[colorScheme].foreground,
                  flexDirection: "row",
                  gap: 8,
                  alignItems: "center",
                }}
                activeOpacity={0.7}
                onPress={() => {
                  configureAnimations();
                  setSearchModeActive((e) => !e);
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "edit_mode",
                    "PatientsScreen",
                    "User Toggled Search"
                  );
                }}
              >
                <Feather name="search" size={20} />
              </TouchableOpacity>
            </View>
          )}
        </View>
      )}
      {searchModeActive && (
        <SearchBar
          shouldFocus
          style={{ marginTop: 20, marginBottom: 12 }}
          hideIcon
          {...{ selected, setSelected, query, setQuery }}
        />
      )}
      {data?.map((item, index) => {
        return (
          item && (
            <PatientCard
              {...{ item }}
              key={item.id + index}
              editMode={editModeActive}
              currentUser={currentUser}
            />
          )
        );
      })}
      {currentUser.patients?.length === 0 && (
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            alignItems: "center",
            flex: 1,
          }}
        >
          <View style={{ marginTop: 16 }}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                marginTop: 12,
                textAlign: "center",
              }}
            >
              {query
                ? "Keine Ergebnisse"
                : "Du hast noch keine Patienten angelegt. Beginne jetzt!"}
            </Text>
            <Image
              source={require("../../assets/images/characters/cases.png")}
              style={{
                alignSelf: "center",
                width: 250,
                height: 250,
                marginVertical: 32,
                resizeMode: "contain",
              }}
            />
          </View>
        </View>
      )}
      {currentUser.patients?.length !== 0 && data.length === 0 && (
        <Text
          style={{
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            marginTop: 32,
            fontStyle: "italic",
            textAlign: "center",
          }}
        >
          {query
            ? "Keine Ergebnisse"
            : "Du hast noch keine weiteren Patienten angelegt."}
        </Text>
      )}
    </ScrollView>
  );
};

export default PatientsScreen;

export const PatientCard = ({
  item,
  editMode = false,
}: {
  item: any;
  currentUser?: any;
  editMode?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const patientData = useSelector(selectPatientData);

  const pageCount = item.data?.length || 0;
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(setCurrentResultPage(null));
  }, []);

  const deletePatientConfirm = (action, patient) => {
    alert(
      "Patient: " + patient.name,
      "Möchtest du diesen Patienten wirklich löschen?",
      [
        {
          text: "Nein",
          style: "cancel",
        },
        {
          text: "Entfernen",
          style: "destructive",
          onPress: () => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "delete_patient_confirmed",
              "PatientsScreen",
              "UserDeletedPatient",
              { patient: patient.name }
            );
            configureAnimations();
            action();
          },
        },
      ]
    );
  };

  const navigateNonActiveConfirm = (_item) => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "navigate_to_non_active_patient",
      "PatientsScreen",
      "UserNavigatedToNonActivePatient"
    );
    alert(
      "Inaktiver Patient",
      "Du navigierst zu einer Seite, die nicht mit dem aktiven Patienten verknüpft ist. Möchtest du den Patienten aktivieren und fortfahren?",
      [
        {
          text: "Nicht aktivieren und fortfahren",
          style: "default",
          onPress: () =>
            navigateToNextCMSPage(_item.result_page_id, navigation),
        },
        {
          text: "Aktivieren und fortfahren",
          style: "default",
          onPress: () => {
            dispatch(clearAmount());
            dispatch(setPatientId(item.id));
            dispatch(fetchAndUpdateAmountsAndUnits());
            navigateToNextCMSPage(_item.result_page_id, navigation);
          },
        },
      ]
    );
  };

  const _deletePatient = async (patient) => {
    deletePatientConfirm(async () => {
      setLoading(true);
      configureAnimations();
      await deletePatient(patient.id);

      setLoading(false);
    }, patient);
  };

  const navigation = useNavigation();

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      {editMode && (
        <TouchableOpacity
          disabled={loading}
          style={{ ...styles.deleteButton, opacity: loading ? 0.5 : 1 }}
          onPress={() => _deletePatient(item)}
        >
          <Feather name="trash-2" size={24} color="red" />
        </TouchableOpacity>
      )}

      <View style={[styles.container, expanded && styles.expandedContainer]}>
        <TouchableOpacity
          style={styles.cardHeader}
          onPress={() => {
            configureAnimations();
            setExpanded(!expanded);
          }}
        >
          <View style={styles.infoContainer}>
            <View style={styles.topRow}>
              <Text style={styles.label}>Name:</Text>
              <Text style={styles.value}>{item.name}</Text>
            </View>
            <View style={styles.detailRow}>
              <View style={styles.detailItem}>
                <Feather name="user" size={16} />
                <Text style={styles.label}>Gewicht:</Text>
                <Text style={styles.value}>{item.weight} kg</Text>
              </View>
              {item.height && (
                <View style={styles.detailItem}>
                  <Feather name="move" size={16} />
                  <Text style={styles.label}>Größe:</Text>
                  <Text style={styles.value}>{item.height} cm</Text>
                </View>
              )}
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Feather
                name={expanded ? "chevron-up" : "chevron-down"}
                size={20}
                color="black"
              />

              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                }}
              >
                <Text style={styles.pageCount}>Seitenanzahl: {pageCount}</Text>
                {(item.modified_at ?? item.created_at) && (
                  <Text style={styles.timestamp}>
                    {formatDate(item.modified_at ?? item.created_at)}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </TouchableOpacity>

        {expanded && (
          <View style={styles.expandedContent}>
            <View
              style={{
                flex: 1,
                gap: 12,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              {item.data?.map((item) => {
                //TODO: memo pages
                const page = getPageContent(item.result_page_id);

                return (
                  <TouchableOpacity
                    key={item.id}
                    onPress={() => navigateNonActiveConfirm(item)}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: Sizes.boxText - 2,
                          flex: 1,
                        }}
                      >
                        {page.title ?? page.fieldTitle}
                      </Text>

                      <Feather name="arrow-right" size={16} />
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>

            {/* Activate Patient Icon Button */}
            <TouchableOpacity
              disabled={patientData}
              onPress={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "activate_patient",
                  "PatientsScreen",
                  "UserActivatedPatient",
                  { patient: item.name }
                );
                configureAnimations();
                dispatch(clearAmount());
                dispatch(setPatientId(item.id));
                dispatch(fetchAndUpdateAmountsAndUnits());
              }}
              style={{
                paddingHorizontal: 12,
                opacity: !patientData ? 1 : 0.5,
                alignItems: "center",
                alignSelf: "center",
                flexDirection: "row",
                borderRadius: 8,
                borderWidth: 0.5,
                marginTop: 12,
                padding: 8,
                gap: 8,
              }}
            >
              <Text style={{ textAlign: "center", fontSize: Sizes.boxText }}>
                Patienten aktivieren
              </Text>
              <Feather name="check" size={16} />
            </TouchableOpacity>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 8,
    marginVertical: 8,
    paddingHorizontal: 16,
    padding: 12,
    flex: 1,
  },
  expandedContainer: {
    paddingBottom: 12,
  },
  cardHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoContainer: {
    flex: 1,
  },
  topRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    gap: 4,
  },
  detailRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 8,
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    gap: 4,
  },
  label: {
    fontSize: Sizes.boxText,
  },
  value: {
    fontSize: Sizes.boxText,
    fontWeight: "600",
  },
  pageCount: {
    fontSize: Sizes.boxText - 2,
    color: "gray",
    marginTop: 8,
  },
  timestamp: {
    fontSize: Sizes.boxText - 3,
    color: "gray",
    fontStyle: "italic",
    marginTop: 4,
    alignSelf: "flex-end",
  },
  expandedContent: {
    marginTop: 12,
  },

  deleteButton: {
    marginRight: 16,
  },
});
