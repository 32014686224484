import { useSelector } from "react-redux";
import { selectEnterprise } from "../functions/enterprise/actions";
import { useFeatureFlag } from "posthog-react-native";

export default function useShowContentPartner() {
  const selectedEnterprise = useSelector(selectEnterprise);
  const showContentPartner = useFeatureFlag("content_partner");

  return !selectedEnterprise && showContentPartner;
}
