import LargeStandardBox from "../LargeStandardBox";
import { InlineImage } from "../inline-image-types";

interface FirstResultProps {
  title: string;
  expandableContent: string | undefined;
  inlineImage: InlineImage | undefined;
  hideIcon?: boolean;
  insertedContent?: any;
}

export function WarningBox(props: FirstResultProps) {
  return (
    <LargeStandardBox
      title={props.title}
      content={""}
      expandableContent={props.expandableContent}
      inlineImage={props.inlineImage}
      boxType="warning"
      insertedContent={props.insertedContent}
    />
  );
}
