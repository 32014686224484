import { useNavigation } from "@react-navigation/native";
import * as Application from "expo-application";
import { useEffect } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { setCalculationVar } from "../../../functions/calculator/actions";

import { Feather } from "@expo/vector-icons";
import { useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import ControlledScrollView from "../../../components/ControlledScrollView";
import PersonalContentHeader from "../../../components/header/PersonalContentScreenHeader";
import { selectAllContent } from "../../../functions/data/actions";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import handleOneTimeAction from "../../../functions/one-time-action-handler";
import getColorScheme from "../../../hooks/useColorScheme";
import useScrollTopMargin from "../../../hooks/useScrollTopMargin";

export default function PersonalContentScreen() {
  useEffect(() => {
    handleOneTimeAction("did_open_personal_screen");
  }, []);

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const colorScheme = getColorScheme();

  const scrollViewMarginTop = useScrollTopMargin();

  const versionRequirements = useSelector(selectAllContent)?.version;

  const latestVersion = versionRequirements?.reduce((latest, current) => {
    return new Date(current.timestamp) > new Date(latest.timestamp)
      ? current
      : latest;
  }, versionRequirements?.[0]);

  const versionIdentifier = latestVersion?.versionIdentifier;

  const bundleVersion = Application.nativeApplicationVersion;

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "personal_screen_opened",
        "PersonalContentScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("personal")));

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        paddingHorizontal: Sizes.defaultContainerPadding,
        flex: 1,
        marginTop: scrollViewMarginTop,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <ControlledScrollView
        style={{
          flex: 1,
          overflow: "visible",
          paddingTop: 0,
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150 }}
      >
        <PersonalContentHeader bottomOffset={75} />
        <View
          style={{
            gap: 24,
          }}
        >
          <Card
            action={() => navigation.navigate("FavoritesScreen")}
            icon="heart"
            title="Meine Favoriten"
            description="Verwalte deine Favoriten"
          />
          <Card
            action={() => navigation.navigate("HistoryScreen")}
            icon="rotate-cw"
            title="Mein Verlauf"
            description={"Alle von dir angesehenen Seiten"}
          />
          <Card
            icon="users"
            action={() => navigation.navigate("PatientsScreen")}
            title="Meine Patienten"
            description={"Verwalte deine Patienten"}
          />
          <Card
            icon="edit"
            action={() => navigation.navigate("NotesOverviewScreen")}
            title="Meine Notizen"
            description={"Verwalte deine Notizen"}
          />
          <Card
            action={() => navigation.navigate("MoreScreen")}
            icon="user"
            title="Mein Account & Einstellungen"
            description={"Verwalte deinen Account und deine Einstellungen"}
          />
          <View
            style={{
              flexDirection: "row",
              opacity: 0.5,
              paddingTop: 24,
              marginTop: 12,
              alignItems: "center",
              borderTopColor: "#ccc",
              borderTopWidth: 1,
            }}
          >
            <Image
              source={require("../../../../assets/images/ce.png")}
              style={{ height: 30, flex: 1, resizeMode: "contain" }}
            />
            <Text style={{ flex: 1, fontSize: Sizes.boxText }}>
              {"App-Version: " +
                bundleVersion +
                "\nContent-Version: " +
                versionIdentifier}
            </Text>
          </View>
        </View>
      </ControlledScrollView>
    </View>
  );
}

const Card = ({ icon, title, description, action }) => {
  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "personal_screen_card_selected",
          "PersonalContentScreen",
          "UserPressedCard",
          { title }
        );
        action && action();
      }}
      style={{
        backgroundColor: "white",
        padding: 16,
        borderRadius: 12,
        shadowColor: Colors["light"].text,
        shadowOffset: {
          width: 2,
          height: 2,
        },
        shadowOpacity: 0.12,
        shadowRadius: 4,
        flexDirection: "row",
        alignItems: "center",
        gap: 20,
      }}
    >
      <View
        style={{
          borderRadius: 24,
          backgroundColor: "#f4c430",
          padding: 8,
        }}
      >
        <Feather name={icon} size={16} color="black" />
      </View>
      <View style={{ flex: 1, gap: 4 }}>
        <Text
          style={{
            fontWeight: "600",
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontSize: Sizes.boxText,
            fontWeight: "300",
          }}
        >
          {description}
        </Text>
      </View>
      <Feather name="chevron-right" size={20} color="black" />
    </TouchableOpacity>
  );
};
