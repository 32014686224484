import { useSelector } from "react-redux";
import { selectAllContent } from "../functions/data/actions";

export default function useHasInternalContent() {
  const medicalSubjectAreas =
    useSelector(selectAllContent).contentList?.content?.medicalSubjectAreaItems;

  const internalContent = medicalSubjectAreas?.find(
    (area) => area.title === "Hausinterne Inhalte"
  );

  return internalContent?.initialPageId;
}
