import { Feather } from "@expo/vector-icons";
import { hyphenate } from "hyphen/de";
import { useEffect, useState } from "react";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { IconToCategory } from "../../../components/CategoryIcon";
import DefaultGradientBackground from "../../../components/DefaultGradientBackground";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import isLargerDevice, {
  isWebAndNotMobile,
} from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";
import { parseDate } from "../functions/helpers";

export const Card = ({
  title,
  id,
  fixedWidth,
  action,
  time,
  sectionHeader,
  clearDate = false,
}: {
  title: string;
  id: string;
  fixedWidth?: number;
  action?: () => void;
  time?: string;
  sectionHeader: string;
  clearDate?: boolean;
}) => {
  const [_title, setTitle] = useState(title ?? "");
  const colorScheme = getColorScheme();

  useEffect(() => {
    (async () => {
      const hyphenatedTitle = await hyphenateDe(title ?? "");
      setTitle(hyphenatedTitle);
    })();
  }, [title]);

  return (
    <TouchableOpacity
      onPress={() => {
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "card_selected",
          "Card",
          "UserPressedCard",
          { section: sectionHeader.split(" ").join("") }
        );
        if (action) action();
      }}
      style={{
        backgroundColor: Colors[colorScheme].foreground,
        flex: 1,
        borderRadius: 12,
        padding: isLargerDevice() ? 20 : 16,
        width: fixedWidth
          ? isLargerDevice()
            ? fixedWidth * (isWebAndNotMobile() ? 1.5 : 1.3)
            : fixedWidth
          : "auto",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Header Row with Icon and Chevron */}
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 8,
        }}
      >
        <CategoryIcon
          size={16}
          title={id}
          style={{
            zIndex: 10,
            alignSelf: "flex-start",
            borderRadius: 100,
            backgroundColor: "#ddddddcc",
            padding: isLargerDevice() ? 8 : 4,
          }}
        />
        <Feather name="chevron-right" size={20} color="black" />
      </View>

      {/* Title and Time */}
      <View style={{ flex: 1, width: "100%" }}>
        <Text
          android_hyphenationFrequency="full"
          style={{
            marginBottom: 0,
            fontSize: Sizes.boxText,
            lineHeight: Sizes.lineHeightBoxText,
            fontWeight: "600",
            alignSelf: "stretch",
            flexShrink: 1,
          }}
          numberOfLines={2}
          ellipsizeMode="tail"
        >
          {_title.trim() ?? title.trim()}
        </Text>
        {time && (
          <Text
            style={{
              fontSize: 10,
              color: Colors["light"].text,
              opacity: 0.5,
              paddingTop: 8,
            }}
          >
            {getStringToTime(time, clearDate)}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

// Hyphenation function
export async function hyphenateDe(text) {
  return await hyphenate(text);
}

// CategoryIcon Component
const CategoryIcon = ({ title, style = {}, size }) => {
  return (
    <View
      style={{
        aspectRatio: 1,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <IconToCategory iconColor={"black"} title={title} size={size} />
    </View>
  );
};

// Date formatting function
function getStringToTime(time: string | undefined, clearDate) {
  if (!time) return "";
  const date = parseDate(time);

  if (clearDate)
    return date.toLocaleTimeString("de-DE", {
      hour12: false,
      minute: "2-digit",
      hour: "2-digit",
    });
  return formatDate(date);
}

// Time difference formatting
function formatDate(date) {
  const now = new Date();
  const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInMinutes < 1) return "vor kurzem";
  if (diffInMinutes < 60) return `vor ${diffInMinutes} Minuten`;
  if (diffInHours < 24) return `vor ${diffInHours} Stunden`;
  if (diffInDays < 2) return "gestern";
  if (diffInDays < 7) return "diese Woche";
  return "vor einiger Zeit";
}
