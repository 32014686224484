import Ionicons from "@expo/vector-icons/Ionicons";
import { useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import Colors from "../../../../constants/static-colors";
import getColorScheme from "../../../../hooks/useColorScheme";

import configureAnimations from "../../../../functions/configure-animations";
import {
  NewCalculatorBox,
  shouldNotRenderNewCalcBox,
} from "./NewCalculatorBox";
import i18n from "../../../../../locales/i18n";
import { useSelector } from "react-redux";
import { selectPatientData } from "../../../../functions/calculator/actions";

export default function PatientDataComponentBox({ calculatorBoxes }) {
  const colorScheme = getColorScheme();
  const [expanded, setExpanded] = useState(true);
  const patientData = useSelector(selectPatientData);

  const _calculatorBoxes = useMemo(
    () =>
      calculatorBoxes
        .filter(
          (box) => !shouldNotRenderNewCalcBox({ units: box.units }, patientData)
        )
        .map((b) => (
          <NewCalculatorBox
            contained
            units={b.units}
            key={b.internalFieldTitle}
          />
        )),
    [calculatorBoxes]
  );

  if (_calculatorBoxes.filter((c) => c).length === 0) return null;

  return (
    <View
      key={"patient_data"}
      style={{
        borderRadius: 12,
        padding: 8,
        marginTop: 20,
        backgroundColor: Colors[colorScheme].lightGray,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          configureAnimations();
          setExpanded((e) => !e);
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text
            style={{
              flex: 1,
              margin: 8,
              fontSize: 13,
              fontWeight: "bold",
              color: Colors[colorScheme].text,
            }}
          >
            {i18n.locale === "de" ? "Daten eingeben" : i18n.t("enter_data")}
          </Text>

          <Ionicons
            name={expanded ? "chevron-down" : "chevron-up"}
            size={24}
            style={{ marginRight: 8 }}
            color={Colors[colorScheme].text}
          />
        </View>
      </TouchableOpacity>
      <View
        style={{ opacity: expanded ? 1 : 0, height: expanded ? "auto" : 0 }}
      >
        {_calculatorBoxes}
      </View>
    </View>
  );
}
