import _ from "lodash";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialAmounts: any = {};
const initialUnits: any = [];

const calculatorSlice = createSlice({
  name: "calculator",
  initialState: {
    amounts: initialAmounts,
    currentUnits: initialUnits,
    calculationVar: 0,
    gender: "m",
    formulaResult: null,
    resultingCalculation: null,
    scoreData: null,
    checklistData: null,
    checklistNote: "",
    patientId: null,
    patientHeaderExpanded: true,
    patientHeaderHistoryExpanded: false,
  },
  reducers: {
    setAmount(state, action) {
      Object.assign(state.amounts, action.payload);
    },
    setPatientHeaderExpanded(state, action) {
      state.patientHeaderExpanded = action.payload;
    },
    setPatientHeaderHistoryExpanded(state, action) {
      state.patientHeaderHistoryExpanded = action.payload;
    },

    setScoreData(state, action) {
      state.scoreData = { ...(state.scoreData ?? {}), ...action.payload };
    },
    setChecklistData(state, action) {
      state.checklistData = action.payload;
    },
    clearAmount(state) {
      state.amounts = {};
    },
    clearCurrentUnits(state) {
      state.currentUnits = [];
    },
    removeFromCurrentUnits(state, action) {
      state.currentUnits = state.currentUnits.filter(
        (unit: string) => unit !== action.payload
      );
    },
    addToCurrentUnits(state, action) {
      state.currentUnits = _.uniq([...state.currentUnits, action.payload]);
    },
    bulkAddToCurrentUnits(state, action) {
      state.currentUnits = _.uniq([...state.currentUnits, ...action.payload]);
    },
    setCalculationVar(state, action) {
      state.calculationVar = action.payload;
    },
    setFormulaResult(state, action) {
      state.formulaResult = action.payload;
    },
    setResultingCalculation(state, action) {
      state.resultingCalculation = action.payload;
    },
    setChecklistNote(state, action) {
      state.checklistNote = action.payload;
    },
    setPatientId(state, action) {
      state.patientId = action.payload;
    },
    updateAmountsAndUnits(state, action) {
      const { weight, size } = action.payload;

      if (weight) {
        Object.assign(state.amounts, {
          kg: weight,
          g: weight * 1000,
          amount: weight,
        });
        state.currentUnits = _.uniq([...state.currentUnits, "kg", "g"]);
      }
      if (size) {
        Object.assign(state.amounts, {
          m: size / 100,
          cm: size,
          size: size,
        });
        state.currentUnits = _.uniq([...state.currentUnits, "cm", "m"]);
      }
    },
  },
});

export default calculatorSlice.reducer;
export const {
  setAmount,
  setPatientId,
  clearCurrentUnits,
  addToCurrentUnits,
  setCalculationVar,
  clearAmount,
  setFormulaResult,
  setResultingCalculation,
  setScoreData,
  setChecklistData,
  setChecklistNote,
  setPatientHeaderExpanded,
  bulkAddToCurrentUnits,
  removeFromCurrentUnits,
  updateAmountsAndUnits,
  setPatientHeaderHistoryExpanded,
} = calculatorSlice.actions;

export const selectPatientHeaderExpanded = (state: any) =>
  state.calculatorReducer.patientHeaderExpanded;
export const selectPatientHeaderHistoryExpanded = (state: any) =>
  state.calculatorReducer.patientHeaderHistoryExpanded;
export const selectPatientData = (state: any) => {
  const patientId = state.calculatorReducer?.patientId;
  return state.userReducer?.user?.patients?.find(
    (patient: any) => patient.id === patientId
  );
};
export const selectAllAmounts = (state: any) => state.calculatorReducer.amounts;
export const selectScoreData = (state: any) =>
  state.calculatorReducer.scoreData;
export const selectCurrentUnits = (state: any) =>
  state.calculatorReducer.currentUnits;
export const selectChecklistData = (state: any) =>
  state.calculatorReducer.checklistData;
export const selectCalculationVar = (state: any) =>
  state.calculatorReducer.calculationVar;
export const selectFormulaResult = (state: any) =>
  state.calculatorReducer.formulaResult;
export const selectResultingCalculation = (state: any) =>
  state.calculatorReducer.resultingCalculation;
export const selectChecklistNote = (state: any) =>
  state.calculatorReducer.checklistNote;

export const fetchAndUpdateAmountsAndUnits = createAsyncThunk(
  "calculator/fetchAndUpdateAmountsAndUnits",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const patient = selectPatientData(state);

    if (patient) {
      const { weight, size } = patient;
      dispatch(updateAmountsAndUnits({ weight, size }));
    }
  }
);
