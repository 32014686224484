import { useCallback, useEffect, useRef, useState } from "react";
import {
  Animated,
  Dimensions,
  Keyboard,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import CreateNotesScreenHeader from "../components/header/CreateNotesScreenHeaderComponent";
import Colors from "../constants/static-colors";
import configureAnimations from "../functions/configure-animations";
import getColorScheme from "../hooks/useColorScheme";

function createContentStyle(theme) {
  return {
    backgroundColor: Colors[theme].foreground,
    color: theme === "light" ? "#000033" : "#fff",
    caretColor: theme === "light" ? "black" : "white",
    placeholderColor: theme === "light" ? "#a9a9a9" : "gray",
    contentCSSText: "font-size: 16px; min-height: 200px;",
  };
}

export function CreateNoteScreen({ route, navigation }) {
  const { note, currentDisplayID } = route.params;
  const richText = useRef();
  const colorScheme = getColorScheme();
  const isDark = colorScheme !== "light";
  const bottomSafeArea = useSafeAreaInsets().bottom;
  const [keyboardOffset, setKeyboardOffset] = useState(bottomSafeArea);
  const bottomToolbarOffset = useRef(new Animated.Value(0)).current;

  const [noteText, setNoteText] = useState(note?.note || "");
  const [noteID, setNoteID] = useState(note?.id || currentDisplayID);
  const [showCheck, setShowCheck] = useState(false);

  const handleChange = useCallback(
    (html) => {
      setShowCheck(note?.note !== html);
      setNoteText(html);
    },
    [note?.note]
  );

  const editorInitializedCallback = useCallback(() => {
    richText.current?.registerToolbar();
  }, []);

  useEffect(() => {
    if (!noteID) {
      setNoteID(currentDisplayID);
    }
  }, [currentDisplayID, noteID]);

  useEffect(() => {
    const onKeyboardShow = (event) => {
      const height = event.endCoordinates.height;

      setKeyboardOffset(height);
      configureAnimations();

      // Animate toolbar position
      Animated.timing(bottomToolbarOffset, {
        toValue: height,
        duration: 250,
        useNativeDriver: false,
      }).start();
    };

    const onKeyboardHide = () => {
      setKeyboardOffset(bottomSafeArea);
      configureAnimations();
      // Reset toolbar position
      Animated.timing(bottomToolbarOffset, {
        toValue: bottomSafeArea,
        duration: 250,
        useNativeDriver: false,
      }).start();
    };

    const keyboardDidShowListener = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardShow
    );
    const keyboardDidHideListener = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardHide
    );

    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [bottomToolbarOffset]);

  return (
    <SafeAreaView
      style={[
        styles.container,
        { backgroundColor: Colors[colorScheme].foreground },
      ]}
    >
      {/* Header */}
      <CreateNotesScreenHeader
        note={{
          id: noteID,
          note: noteText,
          showInResult: true,
        }}
        navigation={navigation}
        showCheck={showCheck}
      />

      {/* Top Toolbar */}
      <RichToolbar
        editor={richText}
        style={[styles.richBar, isDark && styles.richBarDark]}
        flatContainerStyle={styles.flatStyle}
        actions={[
          actions.undo,
          actions.redo,
          actions.alignLeft,
          actions.alignCenter,
          actions.alignRight,
        ]}
        selectedIconTint={Colors[colorScheme].accentDark}
        disabledIconTint={"#bfbfbf"}
      />

      {/* Editor Section */}
      <ScrollView
        style={[
          styles.scroll,
          isDark && { backgroundColor: Colors[colorScheme].background },
        ]}
        keyboardShouldPersistTaps="handled"
      >
        <RichEditor
          ref={richText}
          style={styles.richEditor}
          editorStyle={createContentStyle(colorScheme)}
          initialContentHTML={note?.note || ""}
          onChange={handleChange}
          placeholder="Schreibe deine Notiz hier..."
          pasteAsPlainText={true}
          editorInitializedCallback={editorInitializedCallback}
        />
      </ScrollView>

      {/* Bottom Toolbar */}
      <Animated.View
        style={[
          styles.bottomToolbarContainer,
          { transform: [{ translateY: -keyboardOffset }] },
        ]}
      >
        <View style={[styles.bottomToolbar]}>
          <RichToolbar
            style={[styles.richBar, isDark && styles.richBarDark]}
            flatContainerStyle={styles.flatStyle}
            editor={richText}
            actions={[
              actions.keyboard,
              actions.setBold,
              actions.setItalic,
              actions.setStrikethrough,
              actions.insertBulletsList,
              actions.insertOrderedList,
              actions.removeFormat,
            ]}
            selectedIconTint={Colors[colorScheme].accentDark}
            disabledIconTint={"#bfbfbf"}
          />
        </View>
      </Animated.View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  scroll: {
    flex: 1,
    width: "100%",
  },
  richEditor: {
    minHeight: Dimensions.get("screen").height - 250,
    flex: 1,
    paddingHorizontal: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: "#e3e3e3",
  },
  richBar: {
    width: "100%",
    borderTopWidth: StyleSheet.hairlineWidth,
    borderColor: "#efefef",
  },
  richBarDark: {
    backgroundColor: "#191d20",
    borderColor: "#696969",
  },
  flatStyle: {
    paddingHorizontal: 12,
  },
  bottomToolbarContainer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  bottomToolbar: {
    width: "100%",
  },
});
