import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import FurtherActionBox from "./FurtherActionBox";

export default function getFurtherActionBox(box: ResultBox) {
  const title = parser(box.title);

  return (
    <FurtherActionBox
      isComingSoon={box.isComingSoon}
      key={box.id}
      title={title}
      id={box.resultOrNodeId}
      inlineImage={box.image}
    />
  );
}
