import { Feather } from "@expo/vector-icons";
import { useEffect } from "react";
import { StyleSheet, Switch, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import {
  ButtonTypes,
  DefaultButton,
} from "../../../../components/DefaultButton";
import staticColors from "../../../../constants/static-colors";
import { addToUserCreationObject } from "../../../../functions/user/actions";
import {
  CheckComponent,
  InputWithTitle,
} from "./AdditionalInformationComponent";
import { ADDITIONAL_SPECIALTIES } from "./additional-specialities";
import { DOC_POSITIONS } from "./doc-positions";
import { DOC_SPECIALTIES } from "./specialities-doc";
import getColorScheme from "../../../../hooks/useColorScheme";
import { ClinicInputComponent } from "./GoogleMapsLocationAutoCompleteInputComponent";
import i18n from "../../../../../locales/i18n";

export const DocContent = ({
  setApproved,
  userCreationObject,
  approved,
  content,
  showHint,
  backAction,
  continueAction,
  loading,
}: {
  setApproved?: any;
  userCreationObject: any;
  approved?: any;
  content: any;
  showHint: any;
  backAction?: any;
  continueAction?: any;
  loading?: any;
}) => {
  const dispatch = useDispatch();
  const colorScheme = getColorScheme();
  const styles = getStyles(colorScheme);

  useEffect(() => {
    dispatch(addToUserCreationObject({ isSpecialistDoctor: false }));
  }, []);

  const isButtonEnabled = () => {
    // Check if clinic has a value and is not just whitespace
    const hasClinic =
      userCreationObject["clinic"] &&
      userCreationObject["clinic"].trim() !== "";

    // Check if specialty has a value
    const hasSpecialty = Boolean(userCreationObject["specialty"]);

    // Check if position has a value
    const hasPosition = Boolean(userCreationObject["position"]);

    // Determine if the user is a specialist doctor
    const isSpecialistDoctor = Boolean(
      userCreationObject["isSpecialistDoctor"]
    );

    // Define conditions for specialist and non-specialist forms
    const hasEntriesForSpecialist = hasSpecialty && hasClinic && hasPosition;
    const hasEntriesForNonSpecialist = hasClinic && hasSpecialty;

    // Choose the appropriate condition based on whether the user is a specialist doctor
    const isFormFilledOut = isSpecialistDoctor
      ? hasEntriesForSpecialist
      : hasEntriesForNonSpecialist;

    // Return based on the 'approved' variable, if it exists
    if (approved !== undefined) {
      return !!(isFormFilledOut && approved === true);
    }

    // Return the form filled out condition if 'approved' is not defined
    return isFormFilledOut;
  };

  const handleInputChange = (name, value) => {
    dispatch(
      addToUserCreationObject({
        ...userCreationObject,
        [name]: value,
      })
    );
  };

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text style={styles.textInputTitle}>Ich bin Facharzt / Fachärztin</Text>
        <Switch
          value={userCreationObject["isSpecialistDoctor"]}
          onValueChange={(value) => {
            dispatch(addToUserCreationObject({ isSpecialistDoctor: value }));
          }}
        />
      </View>
      {userCreationObject["isSpecialistDoctor"] ? (
        <SpecialistQuestions
          {...{ inputValues: userCreationObject, handleInputChange }}
        />
      ) : (
        <NoSpecialistQuestions
          {...{ inputValues: userCreationObject, handleInputChange }}
        />
      )}
      {approved !== undefined && setApproved && (
        <CheckComponent
          {...{
            userCreationObject,
            setApproved,
            approved,
            content,
            showHint,
          }}
        />
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 32,
          justifyContent: "center",
        }}
      >
        {backAction && (
          <DefaultButton
            title={i18n.t("back")}
            action={backAction}
            type={ButtonTypes.Outline}
            style={{ width: "30%" }}
          />
        )}
        <DefaultButton
          action={continueAction}
          title={i18n.t("continue")}
          textStyle={{ alignSelf: "flex-start" }}
          enabled={isButtonEnabled()}
          style={{
            width: "50%",
            marginTop: backAction ? 0 : 20,
            marginLeft: backAction ? 20 : 0,
            paddingHorizontal: 20,
          }}
          loading={loading}
          icon={<Feather name="arrow-right" size={24} />}
          type={ButtonTypes.Primary}
        />
      </View>
    </View>
  );
};

const NoSpecialistQuestions = ({ handleInputChange, inputValues }) => {
  return (
    <View>
      <InputWithTitle
        {...{
          handleInputChange,
          inputValues,
        }}
        presetSelection={DOC_SPECIALTIES}
        title="Aktuelle Fachrichtung"
        name="specialty"
      />
      <ClinicInputComponent inputValues={inputValues} />
    </View>
  );
};

const SpecialistQuestions = ({ handleInputChange, inputValues }) => {
  return (
    <View>
      <View>
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues,
          }}
          title="Fachrichtung"
          name="specialty"
          presetSelection={DOC_SPECIALTIES}
        />
      </View>
      <View>
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues,
          }}
          title="Zusatzbezeichnung (optional)"
          presetSelection={ADDITIONAL_SPECIALTIES}
          name="additional_specialty"
        />
      </View>
      <View>
        <ClinicInputComponent inputValues={inputValues} />
      </View>
      <View>
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues,
          }}
          title="Position"
          presetSelection={DOC_POSITIONS}
          name="position"
        />
      </View>
    </View>
  );
};

const getStyles = (colorScheme) => {
  const styles = StyleSheet.create({
    textInputTitle: {
      color: staticColors[colorScheme].text,
      fontSize: 16,
      fontWeight: "500",
    },
  });
  return styles;
};
