// PrimeSection.tsx
import React from "react";
import { ActivityIndicator, View } from "react-native";

import { useData } from "./PartnerDataProvider";
import LargePartnerCard from "./LargePartnerCard"; // Update path as needed
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import { Sizes } from "../../constants/static-sizes";

const PrimeSection: React.FC = () => {
  const { data, loading } = useData();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const primeTiles = data[dayOfMonth]?.primeTile || [];

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  return (
    <View
      style={{
        gap: 20,
        alignItems: "center",
        borderBottomColor: "#777",
        borderBottomWidth: 0.2,
        paddingBottom: 32,
        marginHorizontal: -Sizes.defaultContainerPadding,
      }}
    >
      <CarouselComponent
        data={primeTiles}
        autoplay
        autoplayInterval={8000}
        renderItem={({ item, index }) => (
          <LargePartnerCard item={item} index={index} />
        )}
      />
    </View>
  );
};

export default PrimeSection;
