import { Feather } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import I18n from "../../../locales/i18n";
import { posthog } from "../../api/analytics/posthog";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { selectCurrentUser } from "../../functions/user/actions";
import CustomModal from "./CustomModal";

const feedbackItems = [
  "Empfehlung eines Freundes",
  "Social Media",
  "Website",
  "Events/Messen",
  "E-Mail/Newsletter",
  "Sonstiges",
];

export default function UserSourceModal({ open, onClose }) {
  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 12, marginTop: 20 }}>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("how_did_you_hear_from_us")}
        </Text>
        {feedbackItems.map((item) => (
          <FeedbackItem key={item} title={item} onClose={onClose} />
        ))}
      </View>
    </CustomModal>
  );
}

const FeedbackItem = ({ title, onClose }) => {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        borderRadius: 12,
        padding: 12,
        alignItems: "center",
        marginVertical: 8,
        backgroundColor: colors.ui.cardBackground,
      }}
      onPress={async () => {
        posthog?.capture("user_source", {
          action: currentUser?.email,
          values: title,
        });
        onClose();
      }}
    >
      <Text
        style={{
          flex: 1,
          marginRight: 12,
          color: colors.ui.textPrimary,
          fontFamily: "HostGrotesk-Regular",
        }}
      >
        {title}
      </Text>
      <Feather name="chevron-right" size={24} color={colors.ui.textPrimary} />
    </TouchableOpacity>
  );
};
