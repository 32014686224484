import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { Sizes } from "../constants/static-sizes";
import { Title } from "react-native-paper";

const AdvancedResultPageSkeletonLoader = ({ showTitle }) => {
  return (
    <View
      style={{
        padding: showTitle ? Sizes.defaultContainerPadding : 0,
        flex: 1,
        width: "100%",
      }}
    >
      {/* Title and action buttons */}
      {showTitle && <TitleSkeleton />}

      {/* Content sections with varying layouts */}

      <ContentBoxSkeleton height={180} />

      <ContentBoxSkeleton height={50} />

      <ContentBoxSkeleton height={50} />

      <ContentBoxSkeleton height={120} />
      <ContentBoxSkeleton height={50} />

      {/* Detail box */}

      {/* Sources section */}
      <SourcesSkeleton />
    </View>
  );
};

const TitleSkeleton = () => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop={12}
        marginBottom={8}
      >
        {/* Title */}
        <SkeletonPlaceholder.Item width="70%" height={28} borderRadius={4} />

        {/* Action buttons */}
        <SkeletonPlaceholder.Item flexDirection="row" alignItems="center">
          <SkeletonPlaceholder.Item width={24} height={24} borderRadius={12} />
        </SkeletonPlaceholder.Item>
      </SkeletonPlaceholder.Item>
    </SkeletonPlaceholder>
  );
};

const ContentBoxSkeleton = ({ height = 150, marginTop = 16 }) => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item
        width="100%"
        height={height}
        borderRadius={10}
        marginTop={marginTop}
      />
    </SkeletonPlaceholder>
  );
};

const SourcesSkeleton = () => {
  return (
    <SkeletonPlaceholder>
      <SkeletonPlaceholder.Item marginTop={32}>
        {/* Sources title */}
        <SkeletonPlaceholder.Item width={120} height={20} borderRadius={4} />

        {/* Sources list */}
        <SkeletonPlaceholder.Item marginTop={12}>
          <SkeletonPlaceholder.Item
            width="100%"
            height={16}
            borderRadius={4}
            marginBottom={8}
          />
          <SkeletonPlaceholder.Item
            width="90%"
            height={16}
            borderRadius={4}
            marginBottom={8}
          />
          <SkeletonPlaceholder.Item width="80%" height={16} borderRadius={4} />
        </SkeletonPlaceholder.Item>
      </SkeletonPlaceholder.Item>
    </SkeletonPlaceholder>
  );
};

export default AdvancedResultPageSkeletonLoader;
