import { createSelector, createSlice } from "@reduxjs/toolkit";

const currentResultSlice = createSlice({
  name: "currentResult",
  initialState: {
    shouldShowSources: false,
    showTitle: false,
    currentResultPage: null,
    calculationBoxHeight: 0,
    remoteOpenSaveScoreModal: false,
    isExporting: false,
    exportList: [],
    resultPageSearchQuery: "",
  },
  reducers: {
    setIsExporting(state, action) {
      state.isExporting = action.payload;
      if (action.payload === false) {
        state.exportList = [];
      }
    },
    setRemoteOpenSaveScoreModal(state, action) {
      state.remoteOpenSaveScoreModal = action.payload;
    },
    setCalculationBoxHeight(state, action) {
      state.calculationBoxHeight = action.payload;
    },
    setShowTitle(state, action) {
      state.showTitle = action.payload;
    },
    setCurrentResultPage(state, action) {
      state.currentResultPage = action.payload;
    },

    shouldShowSources(state, action) {
      state.shouldShowSources = action.payload;
    },
    addToExportList(state, action) {
      state.exportList.push(action.payload);
    },
    removeFromExportList(state, action) {
      state.exportList = state.exportList.filter(
        (item) => item.id !== action.payload.id
      );
    },
    setResultPageSearchQuery(state, action) {
      state.resultPageSearchQuery = action.payload;
    },
  },
});

export const {
  shouldShowSources,

  setCurrentResultPage,
  setShowTitle,
  setRemoteOpenSaveScoreModal,
  setIsExporting,
  addToExportList,
  removeFromExportList,
  setResultPageSearchQuery,
  setCalculationBoxHeight,
} = currentResultSlice.actions;
export default currentResultSlice.reducer;

export const selectShowSources = (state) =>
  state.currentResultReducer.shouldShowSources;

export const selectShowTitle = (state) => state.currentResultReducer.showTitle;
export const selectCurrentResultPage = (state) =>
  state.currentResultReducer.currentResultPage;

export const selectCalculationBoxHeight = (state) =>
  state.currentResultReducer.calculationBoxHeight;
export const selectRemoteOpenSaveScoreModal = (state) =>
  state.currentResultReducer.remoteOpenSaveScoreModal;
export const selectIsExporting = (state) =>
  state.currentResultReducer.isExporting;
export const selectExportList = (state) =>
  state.currentResultReducer.exportList;

export const selectShouldShowSources = createSelector(
  (state) => state.currentResultReducer.shouldShowSources,
  (shouldShowSources) => shouldShowSources
);

export const selectResultPageSearchQuery = (state) =>
  state.currentResultReducer.resultPageSearchQuery;
