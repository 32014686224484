import { Ionicons } from "@expo/vector-icons";
import * as Google from "expo-auth-session/providers/google";
import * as AuthSession from "expo-auth-session";
import { useEffect, useState } from "react";
import { CustomSignInButton } from "../LandingScreen";
import { authenticateWithGoogle, authenticateWithProvider } from "../functions/ProviderSignIn";
import i18n from "../../../../../locales/i18n";
import { useNavigation } from "@react-navigation/native";

export function SignInWithGoogleButton() {
  const [loading, setLoading] = useState(false);
  const [request, response, promptAsync] = Google.useAuthRequest({
    // Configure the Google endpoint
    iosClientId:
      "7305114246-utnp025rfmg9gotab793ijakf08k8nm7.apps.googleusercontent.com",
    webClientId:
      "7305114246-g16fhrp0b8k3oqb2i6kbm4n04l8pfka8.apps.googleusercontent.com", //TODO: WEB CLIENT ID
    androidClientId: "7305114246-3umjj21jusbi57bjgpujpe7lh1mnq7jn.apps.googleusercontent.com",
    scopes: [
      "openid",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ],

    // Add a default redirect URI (you can update it as per your needs)
  });

  const navigation = useNavigation();

  useEffect(() => {
    if (response?.type === "success") {
      console.log(response);
      const { access_token, id_token } = response.params;
      authenticateWithProvider({ accessToken: access_token, idToken: id_token }, navigation, "google");
    }
  }, [response]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      if (request) {
        await promptAsync();
      } else {
        console.log("Google authentication request is not ready.");
      }
    } catch (e) {
      console.log("Something went wrong with Google auth", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomSignInButton
      loading={loading}
      provider={<Ionicons name="logo-google" size={20} color="black" />} // Assuming you have an appropriate icon for Google
      text={i18n.t("continue_with_google")}
      onPress={handleGoogleSignIn}
    />
  );
}

// "7305114246-utnp025rfmg9gotab793ijakf08k8nm7.apps.googleusercontent.com",
