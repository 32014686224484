import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { parser } from "../../api/content/data-helpers";
import { ResultBox } from "../../api/content/load-all/types";
import {
  addToExportList,
  removeFromExportList,
  selectExportList,
  selectIsExporting,
} from "../../functions/current-result/actions";
import CheckBox from "../CheckBoxComponent";
import LargeStandardBox from "./box-types/LargeStandardBox";
import getActionRecommendationBox from "./box-types/action-recommendation-box/ActionRecommendationBoxSorter";
import getArrowBox from "./box-types/arrow-box/ArrowBoxSorter";
import getCalculatorBox from "./box-types/calculator-box/CalculatorBoxSorter";
import ChecklistFurtherActionBox from "./box-types/checklist-further-action/ChecklistFurtherAction";
import getDosingRecommendationBox from "./box-types/dosing-recommendation-box/DosingRecommendationBoxSorter";
import getEasyReadingInfoBox from "./box-types/easy-reading-info-box/EasyReadingInfoSorter";
import FormulaContentContainer from "./box-types/formula-box/FormulaBoxComponent";
import getFurtherActionBox from "./box-types/further-action-box/FurtherActionBoxSorter";
import getHintBox from "./box-types/hint-box/HintBoxSorter";
import getInfoBox from "./box-types/info-box/InfoBoxSorter";
import getLargeContentBox from "./box-types/large-content-box/LargeContentBoxSorter";

import { NewCalculatorBox } from "./box-types/patient-data-box/NewCalculatorBox";
import PatientDataComponentBox from "./box-types/patient-data-box/PatientDataComponentBoxSorter";
import ScoreFurtherActionBox from "./box-types/score-further-acion/ScoreFurtherActionBox";

import getTextBox from "./box-types/text-box/TextBoxSorter";
import getWarningBox from "./box-types/warning-box/WarningBoxSorter";
import { useMemo } from "react";

export default function BoxComponent({
  box,
  collapsed = false,
}: {
  readonly box: ResultBox;
  readonly collapsed?: boolean;
}) {
  const isExporting = useSelector(selectIsExporting);
  const exportList = useSelector(selectExportList);
  const dispatch = useDispatch();

  const isSelected =
    exportList.find((_box) => _box?.id === box?.id) !== undefined;

  if (!box) return null;

  const bType = box.typeId;
  let returnBox;

  switch (bType) {
    case "actionRecommendationBox":
      returnBox = getActionRecommendationBox(box);
      break;
    case "warningBox":
      returnBox = getWarningBox(box);
      break;
    case "hintBox":
      returnBox = getHintBox(box);
      break;
    case "dosingRecommendationBox":
      returnBox = getDosingRecommendationBox(box);
      break;
    case "largeContentBox":
      returnBox = getLargeContentBox(box);
      break;
    case "infoBox":
      returnBox = getInfoBox(box);
      break;
    case "furtherResultPageBox":
      returnBox = getFurtherActionBox(box);
      break;
    case "textBox":
      returnBox = getTextBox(box);
      break;
    case "arrowBox":
      returnBox = getArrowBox(box);
      break;
    case "calculatorBox":
      returnBox = getCalculatorBox(box, collapsed);
      break;
    case "easyReadingInfoBox":
      returnBox = getEasyReadingInfoBox(box);
      break;
    case "patientDataComponent":
      returnBox = <PatientDataComponentBox key={box.id} {...box} />;
      break;
    case "formulaContentContainer":
      returnBox = <FormulaContentContainer key={box.id} {...box} />;
      break;
    case "newCalculatorBox":
      returnBox = (
        <NewCalculatorBox units={box.units} key={box.internalFieldTitle} />
      );
      break;
    case "expandableBox":
      returnBox = (
        <LargeStandardBox
          key={box.id}
          title={parser("<p>" + box.title + "</p>")}
          content={parser(box.content ?? "")}
          expandableContent={parser(box.expandableContent ?? "")}
          inlineImage={box.inlineImage?.[0] ?? box.inlineImage ?? undefined}
          boxType={box["Box Type"] ?? box["boxType"]}
        />
      );
      break;
    case "scoreFurtherActionBox":
      returnBox = <ScoreFurtherActionBox key={box.id} box={box} />;
      break;
    case "checkListFurtherActionBox":
      returnBox = <ChecklistFurtherActionBox key={box.id} box={box} />;
      break;
    default:
      returnBox = null;
  }

  return useMemo(
    () => (
      <View
        key={returnBox.key}
        style={{ flexDirection: "row", alignItems: "center", gap: 12 }}
      >
        <View style={{ flex: 1 }}>{returnBox}</View>
        {isExporting && !NON_EXPORTABLE_BOXES.includes(bType) && (
          <CheckBox
            isSelected={isSelected}
            setSelected={() => {
              if (isSelected) dispatch(removeFromExportList(box));
              else dispatch(addToExportList(box));
            }}
            style={{ marginTop: 20 }}
          />
        )}
      </View>
    ),
    [returnBox, isSelected, isExporting, dispatch, box, bType]
  );
}

const NON_EXPORTABLE_BOXES = [
  "searchBox",
  "calculatorBox",
  "furtherActionBox",
  "infoBox",
  "patientDataComponent",
  "scoreFurtherActionBox",
  "newCalculatorBox",
  "arrowBox",
  "checkListFurtherActionBox",
];
