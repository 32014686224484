import { Ionicons } from "@expo/vector-icons";
import { appleAuth } from "@invertase/react-native-apple-authentication";
import { useNavigation } from "@react-navigation/native";
import { useState } from "react";
import { Platform } from "react-native";
import i18n from "../../../../../locales/i18n";
import { authenticateWithProvider } from "../functions/ProviderSignIn";
import { CustomSignInButton } from "../LandingScreen";

export function SignInWithAppleButton() {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();

  function signInWithAppleWeb() {
    const clientId = "com.lando-health.web"; // Ersetze mit deiner Apple Client ID
    const redirectUri = "https://dev.app.mediceo.com/AppleWebRedirectScreen"; // Ersetze mit der Weiterleitungs-URL

    const url = `https://appleid.apple.com/auth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=name%20email&response_type=code&response_mode=form_post`;

    window.location.href = url;
    console.log("alles geklappt");
  }

  async function onAppleButtonPress() {
    if (Platform.OS === "web") {
      // Web-Alternative nutzen
      signInWithAppleWeb();
    } else {
      setLoading(true);
      try {
        const appleAuthRequestResponse = await appleAuth.performRequest({
          requestedOperation: appleAuth.Operation.LOGIN,
          requestedScopes: [appleAuth.Scope.EMAIL, appleAuth.Scope.FULL_NAME],
        });

        const credentialState = await appleAuth.getCredentialStateForUser(
          appleAuthRequestResponse.user
        );

        console.log(credentialState);

        if (credentialState === appleAuth.State.AUTHORIZED) {
          console.log("APPLE AUTHORIZATION CODE", appleAuthRequestResponse);

          const {
            authorizationCode,
            email,
            fullName,
            identityToken,
            realUserStatus,
            state,
            user,
          } = appleAuthRequestResponse;

          const payload = {
            authorizationCode: authorizationCode || "",
            email: email || "",
            fullName: {
              familyName: fullName?.familyName || "",
              givenName: fullName?.givenName || "",
              middleName: fullName?.middleName || "",
              namePrefix: fullName?.namePrefix || "",
              nameSuffix: fullName?.nameSuffix || "",
              nickname: fullName?.nickname || "",
            },
            identityToken: identityToken || "",
            realUserStatus: realUserStatus || "",
            state: state || "",
            user: user,
          };

          await authenticateWithProvider(payload, navigation, "apple");
        }
      } catch (e) {
        console.log("SOMETHING WENT WRONG WITH APPLE AUTHENTICATION", e);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <CustomSignInButton
      loading={loading}
      provider={<Ionicons name="logo-apple" size={20} color="black" />}
      text={i18n.t("continue_with_apple")}
      onPress={onAppleButtonPress}
    />
  );
}
