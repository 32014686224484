import { Feather } from "@expo/vector-icons";
import { useMemo } from "react";
import { DimensionValue, Text, View } from "react-native";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";

const Step = ({ number, done = false }) => {
  return useMemo(
    () => (
      <View
        style={{
          width: 32,
          height: 32,
          borderRadius: 16,
          zIndex: 10,
          alignItems: "center",
          justifyContent: "center",
          borderColor: done ? colors.ui.primary : colors.brand.greys.gD4D4D4,
          borderWidth: 2,
          backgroundColor: done
            ? colors.ui.primary
            : colors.brand.greys.gD4D4D4,
        }}
      >
        {number !== "3" ? (
          <Text
            style={{
              ...getFontStyle(700),
              fontSize: 15,
              color: "black",
            }}
          >
            {number}
          </Text>
        ) : (
          <Feather name="home" size={15} color={"black"} />
        )}
      </View>
    ),
    [number, done]
  );
};

const ProgressBar = ({ currentStep, subSteps, currentSubStep, style = {} }) => {
  const segmentProgress = (step) => {
    if (currentStep < step) return "0%";
    if (currentStep === step) return `${(currentSubStep / subSteps) * 100}%`;
    return "100%";
  };

  return useMemo(
    () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          zIndex: 10,
          paddingTop: 12,
          ...style,
        }}
      >
        <View
          style={{
            width: "100%",
            position: "absolute",
            zIndex: 9,
            height: 150,
            backgroundColor: colors.ui.background,
            bottom: 10,
          }}
        />
        <View
          style={{
            height: 10,
            zIndex: 10,
            marginHorizontal: -1,
            width: 30,
            backgroundColor:
              currentStep > 1 ||
              (currentStep === 1 && currentSubStep === subSteps)
                ? colors.ui.primary
                : colors.brand.greys.gD4D4D4,
          }}
        />
        <Step number="1" done={currentStep > 1} />
        <View
          style={{
            flex: 1,
            zIndex: 10,
            height: 10,
            width: "100%",
            backgroundColor: colors.brand.greys.gD4D4D4,
            marginHorizontal: -1,
          }}
        >
          <View
            style={{
              width: segmentProgress(2) as DimensionValue,
              height: 10,
              zIndex: 10,
              backgroundColor: colors.ui.primary,
            }}
          />
        </View>
        <Step number="2" done={currentStep > 2} />
        <View
          style={{
            flex: 1,
            zIndex: 10,
            height: 10,
            width: "100%",
            backgroundColor: colors.brand.greys.gD4D4D4,
            marginHorizontal: -1,
          }}
        >
          <View
            style={{
              width: segmentProgress(3) as DimensionValue,
              zIndex: 10,
              height: 10,
              backgroundColor: colors.ui.primary,
            }}
          />
        </View>
        <Step number="3" done={currentStep > 3} />
        <View
          style={{
            zIndex: 10,
            width: 30,
            height: 10,
            backgroundColor: colors.brand.greys.gD4D4D4,
            marginHorizontal: -1,
          }}
        />
      </View>
    ),
    [currentStep, currentSubStep, subSteps, style]
  );
};

export default ProgressBar;
