import { Image, Text, View } from "react-native";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideTwo() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Text
        style={{
          ...getFontStyle(500),
          fontSize: isLargerDevice() ? 58 : 36,
          marginBottom: 32,
        }}
      >
        Vertraue auf maximale{"\n"}Qualität. ✅
      </Text>
      <Text
        style={{
          ...getFontStyle(400),
          fontSize: isLargerDevice() ? 32 : 24,
        }}
      >
        Unsere Plattform integriert die neuesten Leitlinien, Studien und
        Expertenmeinungen.
      </Text>
      <Image
        source={require("../../../../assets/images/landing_m.png")}
        style={{
          width: "150%",
          maxWidth: 900,
          resizeMode: "contain",
          height: "50%",
          top: 100,
          right: 150,
          transform: [{ rotate: "14deg" }],
        }}
      />
    </View>
  );
}
