import { createSlice } from "@reduxjs/toolkit";

interface StreakState {
  currentStreakDays: number;
  showStreakModal: boolean;
  showLottie: boolean;
  whichAnimation: "flame" | "flame_off" | null;
}

const initialState: StreakState = {
  currentStreakDays: 0,
  showStreakModal: false,
  showLottie: false,
  whichAnimation: null,
};

const streakSlice = createSlice({
  name: "streak",
  initialState,
  reducers: {
    setCurrentStreakDays(state, action) {
      state.currentStreakDays = action.payload;
    },
    setShowStreakModal(state, action) {
      state.showStreakModal = action.payload;
    },
    setShowLottie(state, action) {
      state.showLottie = action.payload;
    },
    setWhichAnimation(state, action) {
      state.whichAnimation = action.payload;
    },
  },
});

export const {
  setCurrentStreakDays,
  setShowStreakModal,
  setShowLottie,
  setWhichAnimation,
} = streakSlice.actions;

export default streakSlice.reducer;

// Selectors
export const selectCurrentStreakDays = (state: any) =>
  state.streakReducer.currentStreakDays;
export const selectShowStreakModal = (state: any) =>
  state.streakReducer.showStreakModal;
export const selectShowLottie = (state: any) => state.streakReducer.showLottie;
export const selectWhichAnimation = (state: any) =>
  state.streakReducer.whichAnimation;
