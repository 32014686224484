import { useNavigation } from "@react-navigation/native";
import * as cheerio from "cheerio";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import RenderHTML from "react-native-render-html";
import { Cell, Table, TableWrapper } from "react-native-table-component";
import { useSelector } from "react-redux";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { selectResultPageSearchQuery } from "../functions/current-result/actions";
import { navigateToNextPageByCMSId } from "../functions/navigation/helpers";
import getWidth from "../hooks/getWidth";
import getColorScheme from "../hooks/useColorScheme";
import { hyphenateDe } from "../screens/tab-navigator-screens/components/Card";
import { getSharedStylesLargeContent } from "./boxes/SharedStyles";

function CustomLiRenderer({ TDefaultRenderer, color, ...props }) {
  const depth = useListDepth();

  const markers = props.tnode?.markers ?? "";
  const isUl = Object.keys(markers)?.[0]?.includes("ul");
  const renderIndex = props.renderIndex + 1;
  const colorScheme = getColorScheme();

  let bullet;

  switch (depth) {
    case 2:
      bullet = isUl ? (
        <View
          style={{
            position: "absolute",
            top: 8,
            left: -14,
            width: 8,
            height: 2,
            borderRadius: 5,
            backgroundColor: color,
          }}
        >
          {bullet}
        </View>
      ) : (
        <View
          style={{
            borderRadius: 100,
            backgroundColor: color,
            padding: 2,
            position: "absolute",
            left: -20,
            top: 0,
            aspectRatio: 1,
            minWidth: 20,
            height: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color: Colors[colorScheme].text,
            }}
          >
            {renderIndex}
          </Text>
        </View>
      );
      break;
    case 1:
    default:
      bullet = isUl ? (
        <View
          style={{
            position: "absolute",
            top: 8,
            left: -12,
            width: 5,
            height: 5,
            borderRadius: 5,
            backgroundColor: color,
          }}
        >
          {bullet}
        </View>
      ) : (
        <View
          style={{
            borderRadius: 100,
            backgroundColor: color,
            padding: 2,
            position: "absolute",
            left: -20,
            top: 0,
            aspectRatio: 1,
            width: 20,
            height: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 11,
              fontWeight: "bold",
              color: Colors[colorScheme].text,
            }}
          >
            {renderIndex}
          </Text>
        </View>
      );
  }
  return (
    <View style={{}}>
      <View style={{ marginLeft: 4 }}>
        {bullet}
        <TDefaultRenderer {...props} />
      </View>
    </View>
  );
}
function createRenderers(color, noBulletStyles) {
  return {
    table: CustomTableRenderer,
    li: (props) => (
      <CustomLiRenderer color={noBulletStyles ? "gray" : color} {...props} />
    ),
    ul: CustomListRenderer,
    ol: CustomListRenderer,
  };
}

/*
 * Diese Funktion soll die Tabellen Inhalte der einzelnen Zellen extrahieren
 * um anschließend in der eigenen
 */
function parseTableData(html) {
  const $ = cheerio.load(html);
  const rows: any = [];

  $("table tbody tr").each((rowIndex, row) => {
    const cells: any = [];
    $(row)
      .find("td")
      .each((cellIndex, cell) => {
        cells.push($(cell).html());
      });
    rows.push(cells);
  });

  return rows;
}

function serializeDomNode(domNode) {
  if (!domNode) return "";

  const { name, attribs = {}, children = [] } = domNode;

  // Opening tag with attributes
  let html = `<${name}`;
  for (const [key, value] of Object.entries(attribs)) {
    html += ` ${key}="${value}"`;
  }
  html += ">";

  // Recursively add children
  for (const child of children) {
    if (child.type === "text") {
      html += child.data || ""; // text node
    } else if (child.type === "tag") {
      html += serializeDomNode(child); // nested tag
    }
  }

  // Closing tag
  html += `</${name}>`;

  return html;
}

function CustomTableRenderer({ ...props }) {
  const html = serializeDomNode(props.tnode?.init?.domNode);

  return (
    <View>
      <CustomTableComponent html={html} />
    </View>
  );
}

function addHyphensToHeader(header) {
  return header.replace(/(\S{12})/g, "$1&shy;");
}

// TODO: improve styling for headerless tables

function CustomTableComponent({ html }) {
  const tableData: any = parseTableData(html);

  const width = getWidth();

  const tableHead = tableData[0];
  const tableBody = tableData.slice(1);

  const [formattedHead, setFormattedHead] = useState(tableHead);

  useEffect(() => {
    setFormattedHead(tableHead.map((text) => addHyphensToHeader(text)));
  }, [html]);

  const transposedData =
    formattedHead.length > 0
      ? formattedHead.map((header, headerIndex) => [
          header,
          ...tableBody.map((row) => row[headerIndex] || ""), // Handle missing cells
        ])
      : tableBody.map((row) => row.map((cell) => cell || ""));

  return (
    <ScrollView horizontal contentContainerStyle={styles.container}>
      <Table
        borderStyle={{
          borderWidth: 1,
          borderColor: "#C1C0B9",
        }}
      >
        {transposedData.map((rowData, index) => (
          <TableWrapper key={index} style={styles.row}>
            <Cell
              data={
                <RenderHTML
                  contentWidth={width}
                  source={{ html: `${rowData[0]}` }}
                  tagsStyles={
                    {
                      div: {
                        width: 180,
                      },
                      ...getSharedStylesLargeContent("light"),
                    } as any
                  }
                  renderers={createRenderers("#f4c430", false)}
                />
              }
              style={styles.headerCell}
              textStyle={styles.headText}
            />
            <TableWrapper style={styles.dataColumn}>
              {rowData.slice(1).map((cellData, cellIndex) => (
                <Cell
                  key={cellIndex}
                  data={
                    <RenderHTML
                      contentWidth={width}
                      source={{ html: `${cellData}` }}
                      tagsStyles={
                        {
                          div: { width: 180 },
                          ...getSharedStylesLargeContent("light"),
                        } as any
                      }
                      renderers={createRenderers("#f4c430", false)}
                    />
                  }
                  style={styles.dataCell}
                  textStyle={styles.dataText}
                />
              ))}
            </TableWrapper>
          </TableWrapper>
        ))}
      </Table>
    </ScrollView>
  );
}

const ListDepthContext = createContext(0);

function useListDepth() {
  return useContext(ListDepthContext);
}

function CustomListRenderer({ TDefaultRenderer, ...props }) {
  const depth = useListDepth();
  return (
    <ListDepthContext.Provider value={depth + 1}>
      <TDefaultRenderer {...props} style={{ ...props.style, marginTop: 8 }} />
    </ListDepthContext.Provider>
  );
}

export default function CustomRenderHTMLComponent({
  tagsStyles,
  source,
  contentWidth,
  color = Colors["light"].accent,
  noBulletStyles = false,
}: {
  tagsStyles: any;
  source: any;
  contentWidth?: number;
  color?: string;
  noBulletStyles?: boolean;
}) {
  let navigation;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigation = useNavigation();
  } catch {
    navigation = null;
  }

  const [htmlSource, setHtmlSource] = useState(source.html);
  const [sourceCache, setSourceCache] = useState(source.html);
  const searchQuery = useSelector(selectResultPageSearchQuery);

  useEffect(() => {
    //setHtmlSource(source.html);
    addHyphens();
  }, [source]);

  useEffect(() => {
    const highlightHtmlContent = (html, query) => {
      if (!query || query.length < 3) return html; // Fallback to original HTML if query is too short
      const regex = new RegExp(`(${query})`, "gi");
      return html.replace(regex, "<mark>$1</mark>");
    };

    const updatedHtml = highlightHtmlContent(source.html, searchQuery);

    setHtmlSource(updatedHtml); // Always update HTML source to trigger re-render
  }, [searchQuery, source.html]); // Ensure useEffect runs anytime these values change

  async function modifyContentUsingRegex(html) {
    // Regular expression to match content inside <p> and <b> tags
    const regex = /<(p|b)>([^<]+)<\/(p|b)>/g;

    let match;
    let newHtml = html;

    while ((match = regex.exec(html)) !== null) {
      // match[2] contains the text between the tags
      const originalText = match[2];

      const modifiedText = await hyphenateDe(originalText);

      // Replace the matched content with modified content
      newHtml = newHtml.replace(`>${originalText}<`, `>${modifiedText}<`);
    }

    return newHtml;
  }

  async function addHyphens() {
    if (sourceCache !== source.html) {
      setSourceCache(source.html);
      if (source.html.includes("table")) {
        const _source = await modifyContentUsingRegex(source.html);
        setHtmlSource(_source);
      }
    }
  }

  function interceptClicks(_, href: any) {
    href = href.replace(/<[^>]*>/g, "");

    if (
      !href.includes("inline:") &&
      !href.includes("inline_info:") &&
      !href.includes("about:///")
    ) {
      navigation.navigate("WebBrowserScreen", { url: href });
      return;
    }

    const targetInfo = href.includes("inline_info:");

    href = href.replace("inline:", "");
    href = href.replace("inline_info:", "");
    href = href.replace("about:///", "");

    navigateToNextPageByCMSId(navigation, href.trim(), "", targetInfo);
  }

  const dynamicRenderers = createRenderers(color, noBulletStyles);

  return useMemo(
    () => (
      <RenderHTML
        tagsStyles={{
          ...tagsStyles,
          mark: { backgroundColor: "#5DA3FE" },
        }}
        source={{ html: htmlSource }}
        contentWidth={contentWidth ?? Sizes.containerWidth}
        renderersProps={{
          a: {
            onPress: interceptClicks,
          },
          li: {
            color: "#f4c430",
          },
        }}
        renderers={dynamicRenderers}
      />
    ),
    [htmlSource, tagsStyles]
  );
}

const screenWidth = getWidth();

const styles = StyleSheet.create({
  container: { padding: 12, paddingRight: 5 },
  headerCell: {
    width: screenWidth * 0.3,
    backgroundColor: "#F2F2F7",
    padding: 8,
  },
  headText: { fontWeight: "bold", textAlign: "left" },
  row: { flexDirection: "row", backgroundColor: "#FFF" },
  dataColumn: { flexDirection: "row" },
  dataCell: {
    width: screenWidth * 0.45,
    padding: 8,
  },
  dataText: {
    textAlign: "left",
    fontSize: Sizes.boxText,
  },
});
