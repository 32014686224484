"use client";

import { useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { useDispatch } from "react-redux";
import getPageContent from "../../api/content/get-page-content";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { Sizes } from "../../constants/static-sizes";
import { setAdditionalFeedbackData } from "../../functions/current-result/actions";
import { AnswersBar } from "./components/AnswersBar";
import { MultiSelectQuestionContainer } from "./components/MultiSelectQuestionContainer";
import { PreviousAnswers } from "./components/PreviousAnswers";
import { ProgressIndicator } from "./components/ProgressIndicator";
import { QuestionContainer } from "./components/QuestionContainer";
import { ResultContainer } from "./components/ResultContainer";
import { useFlowchartAnimations } from "./hooks/useFlowchartAnimations";
import { getMaxPathLength } from "./utils/flowchart-utils";

export const FlowchartScreen = ({ navigation, route }) => {
  const { pageContent } = route.params;
  const questions = pageContent.options;

  // State management
  const [currentQuestionId, setCurrentQuestionId] = useState<string>("start");
  const [answers, setAnswers] = useState<string[]>([]);
  const [outputId, setOutputId] = useState<string>("");
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [resultContent, setResultContent] = useState<any>(null);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const dispatch = useDispatch();

  // Animations
  const {
    scaleAnim,
    opacityAnim,
    answersContainerAnim,
    answersOpacityAnim,
    previousAnswersHeightAnim,
    previousAnswersOpacityAnim,
    resultContentAnim,
    resultOpacityAnim,
    questionContainerAnim,
    questionPositionAnim,
    headerOpacityAnim,
    animateToResultView,
    animateBackToQuestions,
    animateNewQuestion,
    resetAnimations,
  } = useFlowchartAnimations();

  // Compute the maximum path length once (memoized)
  const maxPathLength = useMemo(
    () => getMaxPathLength(questions, "start"),
    [questions]
  );

  /**
   * Clear all state to start fresh
   */
  const clearState = () => {
    setCurrentQuestionId("start");
    setAnswers([]);
    setOutputId("");
    setIsComplete(false);
    setResultContent(null);
    dispatch(setAdditionalFeedbackData({}));
  };

  const handleMultiSelectContinue = (
    selectedCodes: string[],
    nextQuestionID?: string
  ) => {
    // Get the current question to access the selected options' text
    const currentQuestion = questions.find(
      (q: any) => q.internalID === currentQuestionId
    );

    if (currentQuestion) {
      // Get the text of all selected options
      const selectedOptions = currentQuestion.answers.filter((option) =>
        selectedCodes.includes(option.code)
      );

      // Join all selected option texts with commas
      const selectionText = selectedOptions
        .map((option) => option.text)
        .join(" ||| ");

      // Add the combined text as a single answer
      setAnswers((prev) => [...prev, selectionText]);

      // Combine all selected codes with hyphens and add to outputId
      const combinedCode = selectedCodes.join("-");
      const newOutputId = `${outputId}${combinedCode}-`;
      setOutputId(newOutputId);

      // If there's a next question, proceed
      if (nextQuestionID) {
        setCurrentQuestionId(nextQuestionID);
      } else {
        // If no next question, finish the questionnaire

        finishQuestionnaire(newOutputId);
      }
    }
  };

  /**
   * Handle the user selecting an answer
   */
  const handleAnswer = (selectedOption: any) => {
    // Append the chosen answer text and code to state
    setAnswers((prevAnswers) => [...prevAnswers, selectedOption.text]);
    const newOutputId = `${outputId}${selectedOption.code}-`;
    setOutputId(newOutputId);

    // If the selected answer has a nextQuestionID, proceed
    if (selectedOption.nextQuestionID) {
      setCurrentQuestionId(selectedOption.nextQuestionID);
    } else {
      // If no next question, finish the questionnaire
      finishQuestionnaire(newOutputId);
    }
  };

  /**
   * End the questionnaire and animate to result view
   */
  const finishQuestionnaire = (newOutputId) => {
    newOutputId = newOutputId.slice(0, -1);

    const matchedMapping = pageContent.mapping.find((item) => {
      const stripHtmlTags = (str) => str.replace(/<\/?[^>]+(>|$)/g, "");
      const itemOptionsArray = stripHtmlTags(item.optionsID).split("-");
      const newOutputArray = newOutputId.split("-");

      return itemOptionsArray.every(
        (id, index) => id === newOutputArray[index]
      );
    });

    if (matchedMapping && matchedMapping.resultPageID) {
      const pageContent = getPageContent(matchedMapping.resultPageID);

      setResultContent(pageContent);
      setIsComplete(true);
      if (!pageContent) {
        console.error("No result page found for output ID:", newOutputId);
        return;
      }
      dispatch(
        setAdditionalFeedbackData({
          title: pageContent.title,
          id: pageContent._id,
        })
      );

      // Start the transition animations
      animateToResultView();
    } else {
      console.error("No result page found for output ID:", newOutputId);
    }
  };

  /**
   * Go back to the questionnaire
   */
  const handleBackToQuestions = () => {
    animateBackToQuestions(() => {
      setIsComplete(false);
      setResultContent(null);
    });
  };

  // Get the current question by matching internalID
  const currentQuestion =
    questions.find((q: any) => q.internalID === currentQuestionId) ||
    questions[0];

  // The current step number on the user's path
  const currentQuestionNumber = answers.length + 1;

  // Animate in each new question
  useEffect(() => {
    if (!isComplete) {
      animateNewQuestion();
    }
  }, [currentQuestionId, isComplete]);

  // Reset animation values when component mounts
  useEffect(() => {
    resetAnimations();
  }, []);

  const isMultiple = currentQuestion?.answers.find((answer) =>
    answer.text.includes("gebrechlich")
  );

  return (
    <ResultPagesWrapper
      navigation={navigation}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        addToHistory: true,
        logUserScreenInteraction: {
          screen: "NodepageContentScreen",
          action: "node_pageContent_opened",
          values: pageContent?.title,
          id: pageContent?._id,
        },
      }}
    >
      <View style={{ paddingHorizontal: Sizes.defaultContainerPadding }}>
        {/* Progress indicator and heading */}
        <ProgressIndicator
          isComplete={isComplete}
          headerOpacityAnim={headerOpacityAnim}
          currentQuestionNumber={currentQuestionNumber}
          maxPathLength={maxPathLength}
          title={pageContent.title}
        />

        {/* Previously selected answers */}
        {!isComplete && answers.length > 0 && (
          <PreviousAnswers
            answers={answers}
            previousAnswersOpacityAnim={previousAnswersOpacityAnim}
            previousAnswersHeightAnim={previousAnswersHeightAnim}
          />
        )}

        {/* Horizontal answers bar - shown when result is displayed */}
        {isComplete && (
          <AnswersBar
            answers={answers}
            answersOpacityAnim={answersOpacityAnim}
            answersContainerAnim={answersContainerAnim}
            onReset={() => {
              handleBackToQuestions();
              clearState();
            }}
          />
        )}

        {/* Conditionally render either questions or results */}
        {!isComplete ? (
          !isMultiple ? (
            <QuestionContainer
              currentQuestion={currentQuestion}
              opacityAnim={opacityAnim}
              questionContainerAnim={questionContainerAnim}
              scaleAnim={scaleAnim}
              questionPositionAnim={questionPositionAnim}
              onAnswerSelected={handleAnswer}
            />
          ) : (
            <MultiSelectQuestionContainer
              question={currentQuestion}
              opacityAnim={opacityAnim}
              questionContainerAnim={questionContainerAnim}
              scaleAnim={scaleAnim}
              questionPositionAnim={questionPositionAnim}
              onContinue={handleMultiSelectContinue}
            />
          )
        ) : (
          <ResultContainer
            resultContent={resultContent}
            resultOpacityAnim={resultOpacityAnim}
            resultContentAnim={resultContentAnim}
          />
        )}
      </View>
    </ResultPagesWrapper>
  );
};
