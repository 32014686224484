import { default as LottieView } from "lottie-react-native";
import { useEffect, useRef } from "react";
import {
  Animated,
  Easing,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { Ionicons } from "@expo/vector-icons";
import checkmark from "../../../assets/animations/checkmark.json";
import error from "../../../assets/animations/error.json";
import { Sizes } from "../../constants/static-sizes";
import getColorScheme from "../../hooks/useColorScheme";
import DefaultGradientBackground from "../DefaultGradientBackground";
import staticColors from "../../constants/static-colors";

export enum InlineNotificationDirection {
  FROM_TOP,
  FROM_BOTTOM,
}

export enum InlineNotificationType {
  ERROR,
  SUCCESS,
  NEUTRAL,
  INFO,
}

export const InlineNotificationComponent = ({
  text,
  type = InlineNotificationType.INFO,
  hasIcon = true,
  direction = InlineNotificationDirection.FROM_TOP,
  duration = 2000,
  shouldShow,
  closeAction,
  onPress,
}) => {
  const colorScheme = getColorScheme();
  const lottieRef = useRef();
  const move = useRef(new Animated.Value(-200)).current;

  let backgroundColor = staticColors["light"].foreground;
  let animationSource;
  let height = 90;

  switch (type) {
    case InlineNotificationType.ERROR:
      backgroundColor = staticColors["light"].red;
      animationSource = error;
      height = 70;
      break;
    case InlineNotificationType.SUCCESS:
      backgroundColor = staticColors["light"].accentDark;
      animationSource = checkmark;
      break;
    case InlineNotificationType.INFO:
      backgroundColor = staticColors["light"].foreground;
      animationSource = checkmark;
      break;
  }

  let moveProp = { top: move };
  if (direction === InlineNotificationDirection.FROM_BOTTOM) {
    moveProp = { bottom: move };
  }

  const moveIn = () => {
    Animated.timing(move, {
      toValue: 60,
      duration: 750,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      lottieRef.current?.play();

      if (!hasIcon || Platform.OS === "web") {
        setTimeout(() => moveOut(), duration);
      }
    });
  };

  const moveOut = () => {
    Animated.timing(move, {
      toValue: -200,
      duration: 1000,
      easing: Easing.out(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      if (closeAction) closeAction();
    });
  };

  useEffect(() => {
    if (shouldShow) moveIn();
  }, [shouldShow]);

  if (!shouldShow) return null;

  return (
    <Animated.ScrollView
      onScrollEndDrag={(e) => {
        if (
          e.nativeEvent.contentOffset.y < -50 ||
          e.nativeEvent.contentOffset.y > 50
        )
          moveOut();
      }}
      style={{
        position: "absolute",
        ...moveProp,
        width: "100%",
        zIndex: 10000,
        overflow: "hidden",
      }}
    >
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => {
          onPress?.();
          moveOut();
        }}
        disabled={!onPress}
        style={{
          alignSelf: "stretch",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <View
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: Sizes.containerWidth,
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              alignSelf: "stretch",
              margin: 12,
              shadowColor: staticColors["light"].text,
              elevation: 2,
              shadowOffset: { width: 2, height: 2 },
              shadowRadius: 5,
              shadowOpacity: 0.2,
              borderRadius: 12,
              backgroundColor,
              padding: 24,
            }}
          >
            {type === InlineNotificationType.SUCCESS && (
              <DefaultGradientBackground
                style={{ overflow: "hidden", borderRadius: 12 }}
              />
            )}
            <Text style={{ color: "black", flex: 1, marginRight: 42 }}>
              {text}
            </Text>
            {hasIcon && (
              <View
                style={{
                  margin: -30,
                  alignItems: "center",
                  justifyContent: "center",
                  width: 72,
                }}
              >
                {type === InlineNotificationType.INFO ? (
                  <Ionicons
                    name="bulb-sharp"
                    size={25}
                    color={staticColors[colorScheme].text}
                    style={{ marginLeft: 12 }}
                  />
                ) : (
                  <LottieView
                    ref={lottieRef}
                    style={{ height }}
                    source={animationSource}
                    autoPlay={false}
                    loop={false}
                    onAnimationFinish={() =>
                      setTimeout(() => moveOut(), duration)
                    }
                  />
                )}
              </View>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </Animated.ScrollView>
  );
};

export default InlineNotificationComponent;
