import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import LargeStandardBox from "../LargeStandardBox";

export default function getLargeContentBox(box: ResultBox) {
  return (
    <LargeStandardBox
      key={box.id}
      title={parser(box.title)}
      content={parser(box.content!)}
      inlineImage={box.inlineImage?.[0] ?? box.inlineImage ?? box.image}
      boxType="largeContent"
      expandableContent={parser(box.expandableContent ?? "")}
    />
  );
}
