import { useState, useEffect } from "react";
import i18n from "../../locales/i18n";

const useGreeting = () => {
  const [greeting, setGreeting] = useState(getGreeting());

  useEffect(() => {
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  return greeting;
};

const getGreeting = () => {
  const currentHour = new Date().getHours();
  if (currentHour < 12) {
    return i18n.t("good_morning");
  } else if (currentHour < 18) {
    return i18n.t("good_afternoon");
  } else {
    return i18n.t("good_evening");
  }
};

export default useGreeting;
