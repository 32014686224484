"use client";

import { Feather } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Easing,
  LayoutAnimation,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch } from "react-redux";
import { loadPushNotifications } from "../api/content/load-all/load-all-content";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { setUnreadNotificationCount } from "../functions/data/actions";
import { isWebAndNotMobile } from "../hooks/isLargerDevice";
import { navigateToNextCMSPage } from "../functions/navigation/helpers";
import NotificationSkeletonLoader from "../components/NotificationsSkeletonLoader";

export const NotificationScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isWeb = Platform.OS === "web";

  useEffect(() => {
    async function loadNotification() {
      setIsLoading(true);
      try {
        const notifications = await loadPushNotifications();
        console.log("Notifications", notifications);
        const sortedNotifications = notifications?.sort((a, b) => {
          return new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime();
        });
        setNotifications(sortedNotifications || []);
        dispatch(setUnreadNotificationCount(0));
        AsyncStorage.setItem(
          "readNotifications",
          notifications?.length?.toString() || "0"
        );
      } catch (error) {
        console.error("Failed to load notifications:", error);
      } finally {
        setIsLoading(false);
      }
    }
    loadNotification();
  }, []);

  const handleManageSettings = () => {
    navigation.navigate("PushNotificationScreen", {
      isFromSettings: true,
    });
  };

  return (
    <View
      style={{
        backgroundColor: colors.ui.background,
        flex: 1,
        paddingHorizontal: isWebAndNotMobile() ? 32 : 10,
        paddingTop: 16,
      }}
    >
      {!isWeb && (
        <TouchableOpacity
          style={{
            alignSelf: "flex-end",
            backgroundColor: colors.ui.cardBackground,
            padding: 12,
            borderRadius: 50,
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.15,
            zIndex: 100,
            flexDirection: "row",
            gap: 4,
          }}
          onPress={handleManageSettings}
        >
          <Text
            style={{
              fontSize: Sizes.boxText,
              fontWeight: "500",
              color: colors.ui.textPrimary,
              marginRight: 8,
              fontFamily: "HostGrotesk-Regular",
            }}
          >
            Einstellungen
          </Text>
          <Feather name="settings" size={18} color={colors.ui.textPrimary} />
        </TouchableOpacity>
      )}

      {isLoading ? (
        <NotificationSkeletonLoader />
      ) : notifications && notifications.length > 0 ? (
        <ScrollView
          contentContainerStyle={{ paddingTop: 12, paddingBottom: 20 }}
          showsVerticalScrollIndicator={false}
        >
          {notifications.map((pn, index) => (
            <NotificationItem key={index} pn={pn} navigation={navigation} />
          ))}
        </ScrollView>
      ) : (
        <EmptyNotificationState />
      )}
    </View>
  );
};

const EmptyNotificationState = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingBottom: 40, // Add some padding at the bottom for better visual balance
      }}
    >
      <View
        style={{
          width: 80,
          height: 80,
          borderRadius: 40,
          backgroundColor: colors.ui.cardBackground,
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
          shadowColor: "#000",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.1,
          shadowRadius: 3,
        }}
      >
        <Feather name="bell-off" size={36} color={colors.ui.textPrimary} />
      </View>
      <Text
        style={{
          fontSize: 20,
          fontWeight: "700",
          color: colors.ui.textPrimary,
          marginBottom: 10,
          textAlign: "center",
        }}
      >
        Keine Benachrichtigungen
      </Text>
      <Text
        style={{
          fontSize: 16,
          color: colors.ui.textSecondary || "#666",
          textAlign: "center",
          lineHeight: 22,
        }}
      >
        Du hast derzeit keine Benachrichtigungen. Neue Mitteilungen erscheinen
        hier.
      </Text>
    </View>
  );
};

const NotificationItem = ({
  pn,
  navigation,
}: {
  pn: any;
  navigation?: any;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Animation values
  const fadeAnim = useRef(new Animated.Value(1)).current;
  const scaleAnim = useRef(new Animated.Value(1)).current;
  const buttonFadeAnim = useRef(new Animated.Value(0)).current;

  const date = new Date(pn?.sent_at);
  const formattedDate = date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Determine if notification is recent (within last 24 hours)
  const isRecent = new Date().getTime() - date.getTime() < 86400000;

  const handleVisitPage = () => {
    if (pn?.page_id && navigation) {
      // Press animation for the button
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 0.95,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
      ]).start(() => {
        navigation.navigate("PageDetail", { pageId: pn.page_id });
      });
      navigateToNextCMSPage(pn.page_id, navigation);
    }
  };

  const handlePress = () => {
    // Configure the animation for expanding/collapsing
    LayoutAnimation.configureNext(
      LayoutAnimation.create(
        200,
        LayoutAnimation.Types.easeInEaseOut,
        LayoutAnimation.Properties.opacity
      )
    );

    // Toggle expanded state
    setIsExpanded(!isExpanded);

    // Animate the button fade-in when expanding
    if (!isExpanded) {
      Animated.timing(buttonFadeAnim, {
        toValue: 1,
        duration: 300,
        delay: 100,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }).start();
    }
  };

  const handlePressIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 0.95,
      duration: 100,
      useNativeDriver: true,
    }).start();
  };

  const handlePressOut = () => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  // Reset button animation when collapsed
  useEffect(() => {
    if (!isExpanded) {
      buttonFadeAnim.setValue(0);
    }
  }, [isExpanded]);

  return (
    <TouchableOpacity
      style={{
        marginBottom: 10,
        borderRadius: 12,
        overflow: "hidden",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
      }}
      onPress={handlePress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      activeOpacity={1}
    >
      <Animated.View
        style={{
          flexDirection: "row",
          borderLeftWidth: 3,
          borderLeftColor: isRecent
            ? colors.ui.primary
            : colors.ui.secondary || "#888",
          backgroundColor: colors.ui.cardBackground,
          opacity: fadeAnim,
          transform: [{ scale: fadeAnim }],
        }}
      >
        {/* Left indicator and icon container */}

        {/* Content container */}
        <View
          style={{
            flex: 1,
            padding: 14,
          }}
        >
          {/* Date now appears at the top */}
          <Text
            style={{
              fontSize: 11,
              color: colors.ui.textSecondary,
              marginBottom: 4,
            }}
          >
            {formattedDate}
          </Text>

          {/* Title row */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginBottom: isExpanded ? 6 : 0,
            }}
          >
            <Text
              style={{
                fontSize: 15,
                fontWeight: "600",
                color: colors.ui.textPrimary,
                flex: 1,
                marginRight: 8,
                lineHeight: 20,
              }}
              numberOfLines={isExpanded ? undefined : 2}
            >
              {pn?.title}
            </Text>

            <Animated.View
              style={{
                transform: [{ rotate: isExpanded ? "180deg" : "0deg" }],
              }}
            >
              <Feather
                name="chevron-down"
                size={14}
                color={colors.ui.textSecondary}
              />
            </Animated.View>
          </View>

          {/* Expandable content */}
          {isExpanded && (
            <View style={{ marginTop: 6 }}>
              <Text
                style={{
                  fontSize: 13,
                  lineHeight: 18,
                  color: colors.ui.textSecondary,
                  marginBottom: pn?.page_id ? 10 : 0,
                }}
              >
                {pn?.body}
              </Text>

              {/* Visit page button - only shown if page_id exists */}
              {pn?.page_id && (
                <Animated.View
                  style={{
                    opacity: buttonFadeAnim,
                    transform: [
                      {
                        translateY: buttonFadeAnim.interpolate({
                          inputRange: [0, 1],
                          outputRange: [5, 0],
                        }),
                      },
                    ],
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      alignSelf: "flex-start",
                      marginTop: 6,
                      paddingVertical: 6,
                      paddingHorizontal: 12,
                      borderRadius: 16,
                      backgroundColor: `${colors.ui.secondary}15`,
                    }}
                    onPress={handleVisitPage}
                    activeOpacity={0.7}
                  >
                    <Feather
                      name="external-link"
                      size={12}
                      color={colors.ui.secondary}
                      style={{ marginRight: 4 }}
                    />
                    <Text
                      style={{
                        fontSize: 12,
                        color: colors.ui.secondary,
                        fontWeight: "500",
                      }}
                    >
                      Seite öffnen
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
              )}
            </View>
          )}
        </View>
      </Animated.View>
    </TouchableOpacity>
  );
};
