import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import isLargerDevice from "../../hooks/isLargerDevice";

const LargePartnerCard = ({ item, index }) => {
  return (
    <TouchableOpacity style={styles.largeCard}>
      <View
        style={{
          borderRadius: 12,
          overflow: "hidden",
          aspectRatio: isLargerDevice() ? 6.3 : 2.87,
        }}
      >
        <Image
          source={
            isLargerDevice()
              ? require("../../../assets/images/cp_ipad.png")
              : index % 2 === 0
              ? require("../../../assets/images/cp_ph.png")
              : require("../../../assets/images/cp_ph_2.png")
          }
          style={{
            width: "100%",
            height: "100%",
            resizeMode: "cover",
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

// Styles for the components
const styles = StyleSheet.create({
  largeCard: {
    borderRadius: 12,

    width: "100%",

    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    backgroundColor: "white",
  },
});

export default LargePartnerCard;
