import { useMemo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import getFontStyle from "../functions/getFontSize";
import DefaultGradientBackground from "./DefaultGradientBackground";

export enum ButtonTypes {
  Primary = "primary",
  Secondary = "secondary",
  Outline = "outline",
  Destruktive = "destruktive",
}

export function DefaultButton({
  title,
  type,
  action,
  loading = false,
  enabled = true,
  icon,
  style,
  textStyle,
}: {
  title: string;
  type: ButtonTypes;
  action: () => void;
  loading?: boolean;
  enabled?: boolean;
  icon?: React.ReactNode;
  style?: any;
  textStyle?: any;
}) {
  return useMemo(
    () => (
      <TouchableOpacity
        disabled={!enabled || loading}
        style={{
          overflow: "hidden",
          opacity: !loading && enabled ? 1 : 0.5,
          ...getStyleToType(type),
          ...style,
        }}
        onPress={action}
      >
        {type === ButtonTypes.Primary && <DefaultGradientBackground />}
        {!loading && (
          <Text
            style={{
              fontSize: Sizes.boxText + 2,
              ...getFontStyle(500),
              ...textStyleToType(type),
              ...textStyle,
            }}
          >
            {title}
          </Text>
        )}
        {!loading && (
          <View style={{ position: "absolute", right: 12 }}>{icon}</View>
        )}
        {loading && (
          <View style={{ alignSelf: "center", height: 20 }}>
            <ActivityIndicator
              theme={{
                colors: {
                  ...DefaultTheme.colors,
                  primary:
                    type !== ButtonTypes.Primary
                      ? colors.ui.textPrimary
                      : "black",
                },
              }}
              size={20}
            />
          </View>
        )}
      </TouchableOpacity>
    ),
    [title, type, action, loading, enabled, icon, style, textStyle]
  );
}

function textStyleToType(type: ButtonTypes) {
  switch (type) {
    case ButtonTypes.Primary:
      return {
        color: "black",
      };
    case ButtonTypes.Outline:
      return {
        color: colors.ui.textPrimary,
      };
    case ButtonTypes.Destruktive:
      return {
        color: colors.semantic.error,
      };
  }
}

function getStyleToType(type: ButtonTypes) {
  switch (type) {
    case ButtonTypes.Primary:
      return {
        alignSelf: "stretch",
        padding: 12,
        justifyContent: "center",
        borderRadius: 30,
        backgroundColor: colors.ui.primary,
        alignItems: "center",
        borderColor: colors.ui.primary,
      };
    case ButtonTypes.Outline:
      return {
        alignSelf: "stretch",
        padding: 12,
        justifyContent: "center",
        borderRadius: 30,
        borderWidth: 2,
        borderColor: colors.ui.textPrimary,
        alignItems: "center",
      };
    case ButtonTypes.Destruktive:
      return {
        alignSelf: "stretch",
        padding: 12,
        justifyContent: "center",
        borderRadius: 30,
        borderColor: colors.semantic.error,
        borderWidth: 2,
        alignItems: "center",
      };
    case ButtonTypes.Secondary:
      return {
        alignSelf: "stretch",
        padding: 12,
        justifyContent: "center",
        borderRadius: 30,
        backgroundColor: colors.ui.cardBackground,
        alignItems: "center",
        borderColor: colors.ui.cardBackground,
      };
  }
}
