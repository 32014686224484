import { SafeAreaView, StatusBar, StyleSheet } from "react-native";
import SlideFive from "./Slides/SlideFive";
import SlideFour from "./Slides/SlideFour";
import SlideOne from "./Slides/SlideOne";
import SlideSeven from "./Slides/SlideSeven";
import SlideSix from "./Slides/SlideSix";
import SlideThree from "./Slides/SlideThree";
import SlideTwo from "./Slides/SlideTwo";
import Stories from "./StoriesComponent";
import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";

const StoriesScreen = ({ route }) => {
  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tutorial_screen_opened",
      "TutorialScreen",
      "UserOpened",
      null
    );
  }, []);

  const isFromSettings = route?.params?.isFromSettings ?? false;
  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar barStyle="dark-content" />
      <Stories
        storySets={[
          [
            <SlideOne key="one" />,
            <SlideTwo key="two" />,
            <SlideThree key="three" />,
          ],
          [
            <SlideFour key="four" />,
            <SlideFive key="five" />,
            <SlideSix key="six" />,
          ],
          [<SlideSeven key="seven" isFromSettings={isFromSettings} />],
        ]}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

export default StoriesScreen;
