import { Text, View } from "react-native";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import { useNavigation } from "@react-navigation/native";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideSeven({ isFromSettings }) {
  const navigation = useNavigation();
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        paddingBottom: 100,
      }}
    >
      <Text
        style={{
          fontSize: isLargerDevice() ? 72 : 48,
          fontWeight: "500",
          marginBottom: 32,
        }}
      >
        {"Gleich\ngeschafft 😍"}
      </Text>
      <Text
        style={{
          fontSize: isLargerDevice() ? 32 : 24,
        }}
      >
        Ein paar letzte Fragen, damit wir das Nutzungserlebnis optimal auf
        deinen Fachbereich zuschneiden können.
      </Text>

      <DefaultButton
        title="Los geht's"
        action={() => {
          if (isFromSettings) {
            navigation.goBack();
            return;
          }
          navigation.reset({
            index: 0,
            routes: [
              {
                name: "NewRegistryScreen",
              },
            ],
          });
        }}
        type={ButtonTypes.Primary}
        style={{
          marginTop: 24,
          width: "80%",
          alignSelf: "center",
          zIndex: 1000,
          position: "absolute",
          bottom: 48,
        }}
      />
    </View>
  );
}
