import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Feather } from "@expo/vector-icons";
import { colors } from "../../../constants/static-colors";
import getWidth from "../../../hooks/getWidth";

interface AnswersBarProps {
  answers: string[];
  answersOpacityAnim: Animated.Value;
  answersContainerAnim: Animated.Value;
  onReset: () => void;
}

export const AnswersBar = ({
  answers,
  answersOpacityAnim,
  answersContainerAnim,
  onReset,
}: AnswersBarProps) => {
  return (
    <Animated.View
      style={[
        styles.answersBarContainer,
        {
          opacity: answersOpacityAnim,
          transform: [{ translateY: answersContainerAnim }],
        },
      ]}
    >
      <View style={styles.answersBarContent}>
        {answers
          .filter((answer) => answer)
          .flatMap((answer) => answer.split(" ||| "))
          .map((answer, index) => (
            <View key={index} style={styles.answerChip}>
              <Text
                style={styles.answerChipText}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {answer}
              </Text>
            </View>
          ))}
      </View>
      <TouchableOpacity
        style={styles.resetButton}
        onPress={onReset}
        activeOpacity={0.8}
      >
        <Feather name="refresh-cw" size={18} color={colors.brand.greys.white} />
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  answersBarContainer: {
    backgroundColor: colors.ui.background,
    paddingVertical: 20,
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: colors.ui.border,
    zIndex: 10,
    flexDirection: "row",
    gap: 12,
  },
  answersBarContent: {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 8,
    flex: 1,
    marginTop: 20,
  },
  answerChip: {
    backgroundColor: colors.brand.greens.g339988,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 20,
    maxWidth: (getWidth() - 60) * 0.4,
    shadowColor: colors.brand.greys.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    elevation: 2,
  },
  answerChipText: {
    color: colors.brand.greys.white,
    fontSize: 13,
    fontFamily: "HostGrotesk-Regular",
  },
  resetButton: {
    backgroundColor: colors.brand.greens.g339988,
    padding: 10,
    borderRadius: 8,
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
  },
});
