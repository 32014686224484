import { Feather } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging from "@react-native-firebase/messaging";
import { useEffect, useState } from "react";
import {
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  Switch,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../locales/i18n";
import { posthog } from "../api/analytics/posthog";
import apiCall from "../api/api-call";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import ProgressBar from "../components/registry/ProgressBar";
import staticColors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { showInlineNotification } from "../entry/Root";
import { store } from "../functions/store";
import {
  changeUserInformation,
  selectCurrentUser,
} from "../functions/user/actions";
import { updateOfflineUser } from "../functions/user/functions";
import getColorScheme from "../hooks/useColorScheme";
import { APP_TARGET } from "./LaunchScreen";

export default function PushNotificationScreen({ navigation, route }) {
  const isFromSettings = route.params?.isFromSettings;
  const preventClosing = route.params?.preventClosing;

  const dispatch = useDispatch();
  const [updatesEnabled, setUpdatesEnabled] = useState(true);
  const [motivationEnabled, setMotivationEnabled] = useState(true);

  const currentUser = useSelector(selectCurrentUser);

  const [loading, setLoading] = useState(false);
  const colorScheme = getColorScheme();

  async function getPreviousState(key) {
    const value = (await AsyncStorage.getItem(key)) ?? "true";
    return value === "true";
  }

  useEffect(() => {
    posthog?.capture("push_notification_preferences_opened");
    async function loadPrevStates() {
      const prevUpdatesEnabled = await getPreviousState("updatesEnabled");
      const prevMotivationEnabled = await getPreviousState("motivationEnabled");

      setUpdatesEnabled(prevUpdatesEnabled);
      setMotivationEnabled(prevMotivationEnabled);
    }
    if (isFromSettings) loadPrevStates();
  }, []);

  async function requestUserPermission() {
    const authStatus = await messaging().requestPermission();
    const enabled =
      authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
      authStatus === messaging.AuthorizationStatus.PROVISIONAL;

    return enabled;
  }

  function continueAction() {
    setLoading(false);

    if (preventClosing) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: APP_TARGET,
            state: {
              routes: [
                {
                  name: "HomeScreen",
                  params: { showUserModal: true },
                },
              ],
            },
          },
        ],
      });
      return;
    }
    if (isFromSettings) navigation.goBack();
    else {
      navigation.reset({
        index: 0,
        routes: [{ name: APP_TARGET as any }],
      });
    }
  }

  async function handleButtonPress() {
    if (Platform.OS !== "web")
      RNReactNativeHapticFeedback.trigger("impactLight");
    posthog?.capture("push_notification_preferences_saved");

    setLoading(true);
    try {
      if (updatesEnabled || motivationEnabled) {
        const enabled = await requestUserPermission();
        if (!enabled) {
          showInlineNotification({
            text:
              i18n.locale === "de"
                ? "Um Push-Benachrichtigungen für MEDICEO zu aktivieren, gehe zu Einstellungen > MEDICEO > Benachrichtigungen und schalte sie ein."
                : i18n.t("send_feedback_now"),
            type: InlineNotificationType.ERROR,
          });
          continueAction();
          return;
        }
        await messaging().registerDeviceForRemoteMessages();
      }

      const prevUpdatesEnabled = await getPreviousState("updatesEnabled");
      const prevMotivationEnabled = await getPreviousState("motivationEnabled");

      const pnPreference: string[] = [];

      // Handle "Updates" topic

      if (updatesEnabled) {
        posthog?.capture("push_notification_updates_enabled");
        pnPreference.push("UPDATES");

        await messaging().subscribeToTopic("updates");
      } else if (prevUpdatesEnabled) {
        posthog?.capture("push_notification_updates_disabled");
        await messaging().unsubscribeFromTopic("updates");
      } else {
        posthog?.capture("push_notification_updates_disabled");
      }

      // Handle "Motivational" topic
      if (motivationEnabled) {
        pnPreference.push("MOTIVATIONAL");
        posthog?.capture("push_notification_motivation_enabled");
        await messaging().subscribeToTopic("motivational");
      } else if (prevMotivationEnabled) {
        await messaging().unsubscribeFromTopic("motivational");
        posthog?.capture("push_notification_motivation_disabled");
      } else {
        posthog?.capture("push_notification_motivation_disabled");
      }

      await messaging().subscribeToTopic(currentUser.user_id);
      // Update the saved states after changes
      await AsyncStorage.setItem("updatesEnabled", updatesEnabled.toString());
      await AsyncStorage.setItem(
        "motivationEnabled",
        motivationEnabled.toString()
      );

      try {
        await apiCall(
          "/api/v2/users/pn-preferences",
          {
            pn_preference: pnPreference,
          },
          false,
          false,
          "PUT"
        );
        dispatch(
          changeUserInformation({
            indexKey: "pn_preference",
            value: pnPreference,
          })
        );
        updateOfflineUser(store.getState().userReducer.user as any);
      } catch {
        showInlineNotification({
          text:
            i18n.locale === "de"
              ? "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."
              : i18n.t("error_occurred_try_later"),
          type: InlineNotificationType.ERROR,
        });
      }
    } finally {
      continueAction();
    }
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        backgroundColor: staticColors[colorScheme].background,
      }}
    >
      {!isFromSettings ? (
        <ProgressBar currentStep={3} subSteps={8} currentSubStep={8} />
      ) : (
        !preventClosing && (
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            style={{
              alignSelf: "flex-end",
              padding: 20,
              marginBottom: -20,
              marginTop: -20,
            }}
          >
            <Feather
              name="x"
              size={32}
              color={staticColors[colorScheme].text}
            />
          </TouchableOpacity>
        )
      )}

      <Image
        style={{
          flex: 1,
          resizeMode: "contain",
          ...Platform.select({ android: { paddingTop: 20 } }),
        }}
        source={require("../../assets/images/characters/push-new.png")}
      />
      <View
        style={{
          alignSelf: "stretch",
          flex: 3,
          marginTop: 32,
          margin: Sizes.defaultContainerPadding,
        }}
      >
        <Text
          style={{
            fontSize: 26,
            fontWeight: "500",
            marginBottom: 20,
            color: staticColors[colorScheme].text,
          }}
        >
          Setze deine Notification Präferenzen
        </Text>
        <PushOption
          title="Updates & Informationen"
          text="Erhalte Benachrichtigungen über inhaltliche Aktualisierungen und Informationen."
          enabled={updatesEnabled}
          setEnabled={setUpdatesEnabled}
        />
        <PushOption
          last
          title="Inspiration & Insights"
          text="Spannende Fakten, Einblicke und mehr!"
          enabled={motivationEnabled}
          setEnabled={setMotivationEnabled}
        />
      </View>
      <DefaultButton
        style={{
          margin: Sizes.defaultContainerPadding,
          height: 55,
          marginTop: 48,
          marginBottom: 24,
        }}
        loading={loading}
        type={ButtonTypes.Primary}
        title={
          isFromSettings ? "Speichern und schließen" : "Speichern und weiter"
        }
        action={handleButtonPress}
      />
    </SafeAreaView>
  );
}

const PushOption = ({ title, text, enabled, setEnabled, last = false }) => {
  const colorScheme = getColorScheme();

  return (
    <View
      style={{
        flexDirection: "row",
        alignSelf: "stretch",
        paddingVertical: 24,
        borderBottomColor: staticColors[colorScheme].gray,
        borderBottomWidth: last ? 0 : 0.3,
        gap: 16,
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, gap: 4 }}>
        <Text
          style={{
            fontSize: 16,
            color: staticColors[colorScheme].text,
            fontWeight: "600",
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            fontWeight: "400",
            fontSize: 13,
            color: staticColors[colorScheme].text,
            opacity: 0.75,
          }}
        >
          {text}
        </Text>
      </View>
      <Switch
        value={enabled}
        onValueChange={(newValue) => setEnabled(newValue)}
      />
    </View>
  );
};
