import { Text, View } from "react-native";
import { colors } from "../constants/static-colors";
import getFontStyle from "../functions/getFontSize";

export default function BadgeComponent({
  text,
  condition,
  size,
  style = {},
}: {
  readonly text: string;
  readonly condition?: boolean;
  readonly size?: number;
  readonly style?: any;
}) {
  if (!size) size = 18;
  if (condition) return null;
  return (
    <View
      style={{
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.35,
        shadowRadius: 3.84,
        // Schatten für Android
        elevation: 5,

        minWidth: size,
        height: size,
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        borderRadius: size / 2,
        backgroundColor: colors.ui.secondary,
        position: "absolute",
        top: -size / 2,
        right: -size / 2,
        ...style,
      }}
    >
      <Text
        style={{
          color: colors.brand.greys.white,
          fontSize: (size / 3) * 2,
          fontStyle: "normal",
          ...getFontStyle(700),
        }}
      >
        {text}
      </Text>
    </View>
  );
}
