import { AlphabetList } from "react-native-section-alphabet-list";

import { useMemo } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getColorScheme from "../../../../hooks/useColorScheme";

import { useNavigation } from "@react-navigation/native";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";

export default function LexiconComponent() {
  const sortPagesIntoSections = (pages) => {
    const sections = pages.map((page) => {
      // Use 'title' or 'fieldTitle' as the key
      const value = page.title || page.fieldTitle;
      const key = page._id || page.id;

      return {
        key,
        value,
        ...page,
      };
    });

    return sections;
  };

  const allPages =
    useSelector((state: any) => state.dataReducer.allData?.pages)
      ?.filter(
        (page: any) => page.content.path && page.content?.path?.length > 0
      )
      ?.filter((page) => page.content_type_id !== "nodePage") ?? [];

  const sections = useMemo(
    () => sortPagesIntoSections([...allPages]),
    [allPages]
  );

  console.log(
    JSON.stringify(
      allPages.map((page) => page.fieldTitle ?? page.title),
      null,
      2
    )
  );

  const colorScheme = getColorScheme();

  const navigation = useNavigation();

  return (
    <AlphabetList
      contentContainerStyle={{ paddingBottom: 110 }}
      data={sections}
      style={{ flex: 1 }}
      renderCustomIndexLetter={() => <></>}
      renderCustomItem={(item: any) => (
        <TouchableOpacity
          onPress={() => {
            navigateToNextCMSPage(item._id, navigation);
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "lexicon_item_selected",
              "LexiconComponent",
              "User Selected Lexicon Item",
              { itemId: item._id, itemTitle: item.value }
            );
          }}
          style={{
            padding: 12,
            borderBottomWidth: 1,
            borderBottomColor: "#e0e0e0",
          }}
        >
          <Text
            style={{
              fontSize: Sizes.boxText,
              color: staticColors[colorScheme].text,
              opacity: 0.8,
            }}
          >
            {item.value}
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "300",
              color: staticColors[colorScheme].text,
              opacity: 0.75,
              marginTop: 4,
            }}
          >
            {item.content.path
              ?.slice(0, item.content.path?.length - 1)
              .join(", ")}
          </Text>
        </TouchableOpacity>
      )}
      renderCustomSectionHeader={(section) => (
        <View
          style={{
            backgroundColor: staticColors[colorScheme].lightGray,
            paddingVertical: 5,
            paddingHorizontal: 10,
          }}
        >
          <Text
            style={{
              fontSize: 15,
              fontWeight: "bold",
              color: staticColors[colorScheme].text,
            }}
          >
            {section.title}
          </Text>
        </View>
      )}
    />
  );
}
