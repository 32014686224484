export const isInformationMissingDoc = (currentUser) => {
  // Check if clinic has a value and is not just whitespace
  const hasClinic =
    currentUser["clinic"] && currentUser["clinic"].trim() !== "";

  // Check if specialty has a value
  const hasSpecialty = Boolean(currentUser["specialty"]);

  // Check if position has a value (for specialists)
  const hasPosition = Boolean(currentUser["position"]);

  // Determine if the user is a specialist doctor
  const isSpecialistDoctor = Boolean(currentUser["isSpecialistDoctor"]);

  // Define conditions for specialist and non-specialist forms
  const isSpecialistMissingInfo = !(hasSpecialty && hasClinic && hasPosition);
  const isNonSpecialistMissingInfo = !(hasClinic && hasSpecialty);

  // Return true if any information is missing based on whether the user is a specialist or not
  return isSpecialistDoctor
    ? isSpecialistMissingInfo
    : isNonSpecialistMissingInfo;
};

export const isInformationMissingStudent = (currentUser) => {
  const {
    university,
    progression_level,
    semester,
    academic_degree,
    student_type,
  } = currentUser;

  if (student_type === "nursing_apprentice") {
    const isMissing =
      !university ||
      university.trim() === "" ||
      !progression_level ||
      progression_level.trim() === "";

    return !!isMissing;
  }

  if (student_type === "medical_student") {
    const isMissing =
      !university ||
      university.trim() === "" ||
      !semester ||
      isNaN(semester) ||
      !academic_degree ||
      academic_degree.trim() === "";

    return !!isMissing;
  }

  return true;
};

export const isInformationMissingNurse = (currentUser) => {
  const isMissing =
    !currentUser.job_description?.trim() || !currentUser.clinic?.trim();

  return isMissing;
};
