import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { Text, TouchableOpacity, View } from "react-native";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";

export const SearchHistoryItem = ({
  title,
  issueSearchWithQuery,
  deleteMode,
  isHistory,
  deleteItem,
  index,
}) => {
  return (
    <View
      style={{
        flexDirection: "row",
        flex: 1,
        marginHorizontal: 0,
        backgroundColor: colors.ui.background,
        alignItems: "center",
      }}
    >
      {deleteMode && (
        <TouchableOpacity
          style={{ marginRight: 12 }}
          onPress={() => deleteItem(index)}
        >
          <Ionicons name="close" size={24} color={colors.semantic.error} />
        </TouchableOpacity>
      )}
      <TouchableOpacity
        onPress={() => {
          issueSearchWithQuery(title);
        }}
        style={{
          flexDirection: "row",
          paddingVertical: 8,
          alignItems: "center",
          flex: 1,
          opacity: 0.75,
        }}
      >
        {isHistory ? (
          <Ionicons
            name="time-outline"
            size={24}
            style={{ marginRight: 20 }}
            color={colors.ui.textPrimary}
          />
        ) : (
          <Ionicons
            name="trending-up-outline"
            size={24}
            style={{ marginRight: 20 }}
            color={colors.ui.textPrimary}
          />
        )}
        <Text
          style={{
            color: colors.ui.textPrimary,
            fontSize: Sizes.boxText + 1,
            marginRight: 12,
            flex: 1,
            fontFamily: "HostGrotesk-Regular",
          }}
        >
          {title}
        </Text>
        <Feather name="chevron-right" size={24} color={colors.ui.textPrimary} />
      </TouchableOpacity>
    </View>
  );
};
