import { Animated, StyleSheet, Text, View } from "react-native";
import { colors } from "../../../constants/static-colors";

interface PreviousAnswersProps {
  answers: string[];
  previousAnswersOpacityAnim: Animated.Value;
  previousAnswersHeightAnim: Animated.Value;
}

export const PreviousAnswers = ({
  answers,
  previousAnswersOpacityAnim,
  previousAnswersHeightAnim,
}: PreviousAnswersProps) => {
  return (
    <Animated.View
      style={{
        opacity: previousAnswersOpacityAnim,
        transform: [{ scale: previousAnswersHeightAnim }],
      }}
    >
      <View style={styles.previousAnswersContainer}>
        {answers.map((answer, index) => (
          <View key={index} style={styles.previousAnswer}>
            <Text style={styles.previousAnswerText}>{answer}</Text>
          </View>
        ))}
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  previousAnswersContainer: {
    marginBottom: 0,
  },
  previousAnswer: {
    backgroundColor: colors.ui.cardBackground,
    padding: 12,
    borderRadius: 8,
    marginBottom: 12,
    borderWidth: 1,
    borderColor: colors.ui.border,
  },
  previousAnswerText: {
    fontSize: 13,
    color: colors.ui.textSecondary,
    fontFamily: "HostGrotesk-Regular",
  },
});
