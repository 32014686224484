import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import {
  Dimensions,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { useDispatch } from "react-redux";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { addToUserCreationObject } from "../../../../functions/user/actions";
import getColorScheme from "../../../../hooks/useColorScheme";

export function ClinicInputComponent({
  inputValues,
  indexKey = "clinic",
  title = "In welchem Krankenhaus / Praxis / Gesundheitseinrichtung arbeitest du?",
  placeholder = "z.B. Uniklinikum Frankfurt",
}) {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  return (
    <View style={{ marginTop: 24, marginBottom: title ? 0 : 12 }}>
      {!!title && (
        <Text
          style={{
            color: Colors[colorScheme].text,
            fontSize: 16,
            fontWeight: "500",
          }}
        >
          {title}
        </Text>
      )}
      <TouchableOpacity
        onPress={() =>
          navigation.navigate("GoogleMapsLocationAutoCompleteInputComponent", {
            indexKey,
          })
        }
      >
        <View>
          <View
            key={indexKey}
            style={{
              backgroundColor: Colors[colorScheme].foreground,
              maxWidth: Sizes.containerWidth,
              borderRadius: 12,
              marginTop: 8,
              width: "100%",
              height: 50,
              padding: 12,
              zIndex: 0,
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            {inputValues[indexKey] ? (
              <Text
                numberOfLines={1}
                style={{
                  color: Colors[colorScheme].text,
                  fontWeight: "400",
                }}
              >
                {inputValues[indexKey]}
              </Text>
            ) : (
              <Text
                numberOfLines={1}
                style={{
                  color: Colors[colorScheme].placeholder,
                  fontWeight: "400",
                }}
              >
                {placeholder ?? title ?? ""}
              </Text>
            )}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default function GoogleMapsLocationAutoCompleteInputComponent({
  route,
}) {
  const navigation = useNavigation();
  const indexKey = route.params.indexKey;
  const [customInput, setCustomInput] = useState("");

  const ref = useRef<any>(null);

  useEffect(() => {
    if (!ref.current) return;
    const interval = setInterval(() => {
      setCustomInput(ref.current?.getAddressText());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current?.focus();
    }
  }, [ref.current]);

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();

  const handlePlaceSelect = (data, details) => {
    const item = data.structured_formatting.main_text;
    const location = details.geometry.location;

    dispatch(addToUserCreationObject({ [indexKey]: item }));
    dispatch(addToUserCreationObject({ latitude: location?.lat }));
    dispatch(addToUserCreationObject({ longitude: location?.lng }));

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "added_information_from_list",
      "SelectInformationFromListComponent",
      "InformationAdded",
      { indexKey, item }
    );

    navigation.goBack();
  };

  const handleCustomInput = () => {
    dispatch(addToUserCreationObject({ [indexKey]: customInput }));
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "added_custom_information",
      "SelectInformationFromListComponent",
      "InformationAdded",
      { indexKey, item: customInput }
    );
    navigation.goBack();
  };

  return (
    <SafeAreaView
      style={{
        height: "100%",
        width: "100%",
        flex: 1,
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <ScrollView
        nestedScrollEnabled
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: "100%",
            alignItems: "center",
          }}
        >
          <GooglePlacesAutocomplete
            ref={ref}
            placeholder="Suche..."
            minLength={2}
            fetchDetails={true}
            onPress={(data, details) => handlePlaceSelect(data, details)}
            query={{
              key: "AIzaSyDGpjdtL4uaGLL3lgjNorY0RgQwxVdulGQ",
              language: "en",
              types:
                indexKey === "clinic"
                  ? "hospital|doctor|health"
                  : "university|school",
            }}
            requestUrl={{
              useOnPlatform: "web", // Use the appropriate platform for requests
              url: " https://maps.googleapis.com/maps/api", // Direct request URL
            }}
            keepResultsAfterBlur
            keyboardShouldPersistTaps="always"
            renderLeftButton={() => (
              <View style={{ margin: 16, marginRight: 12 }}>
                <Ionicons name="search" size={18} color="black" />
              </View>
            )}
            enablePoweredByContainer={false}
            styles={{
              textInputContainer: {
                overflow: "hidden",
                borderWidth: 1,
                borderColor: Colors[colorScheme].accent,
                backgroundColor: Colors[colorScheme].foreground,
                borderRadius: 12,
                alignItems: "center",
                marginHorizontal: 12,
              },

              container: {
                alignSelf: "stretch",
                marginTop: 12,
              },
              textInput: {
                color: Colors[colorScheme].text,
                fontSize: 15,
                autoCorrect: false, // Disable autocorrect
                autoComplete: "off", // Disable autocomplete

                marginBottom: 0,
              },
              predefinedPlacesDescription: {
                color: Colors[colorScheme].text,
              },
              listView: {
                marginTop: 12,
                height: "auto",
                backgroundColor: Colors[colorScheme].foreground,
                borderBottomColor: "gray",
                borderBottomWidth: 0.25,
              },
              poweredContainer: { height: 0 },
              powered: { height: 0 },
            }}
            debounce={200}
            renderRow={(data) => {
              return (
                <TouchableOpacity
                  style={{
                    maxWidth: Dimensions.get("window").width - 24,
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    gap: 8,
                    padding: 8,
                  }}
                  // onPress={() => {
                  //   // handlePlaceSelect(data, details);
                  // }}
                  disabled
                >
                  <Text style={{ flex: 1 }}>{data.description}</Text>
                  <Feather
                    name="chevron-right"
                    size={24}
                    color={Colors[colorScheme].text}
                    style={{ marginLeft: 20 }}
                  />
                </TouchableOpacity>
              );
            }}
            listEmptyComponent={
              <View
                style={{
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <Text style={{ color: Colors[colorScheme].text }}>
                  Keine Ergebnisse
                </Text>
              </View>
            }
            onFail={(error) => {
              console.error("Google Places API error:", error);
              // Handle the error gracefully here if needed
            }}
          />
        </View>

        {!!customInput && (
          <TouchableOpacity
            style={{
              alignSelf: "stretch",
              borderBottomColor: "lightgray",
              borderBottomWidth: 1,
              padding: 20,
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              marginBottom: 20,

              backgroundColor: Colors[colorScheme].foreground,
            }}
            onPress={handleCustomInput}
          >
            <Text style={{ flex: 1 }}>{`"${customInput}" verwenden`}</Text>
            <Feather
              name="chevron-right"
              size={24}
              color={Colors[colorScheme].text}
              style={{ marginLeft: 20 }}
            />
          </TouchableOpacity>
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
