import { store } from "../store";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../user-interaction-handler";
import { Note, User } from "../user/types";

export async function increaseResultPagesCount() {
  let openedResultPages = getResultPagesCount();
  openedResultPages = openedResultPages + 1;
  await addValueToInteractions("openedPages", openedResultPages + "");
}

export function getResultPagesCount() {
  let openedResultPagesUnparsed = getValueFromInteractions("openedPages");
  if (!openedResultPagesUnparsed) openedResultPagesUnparsed = "0";
  return parseInt(openedResultPagesUnparsed);
}

export function getIsBookmarkToID(id: string, legacy_id: string) {
  const currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser?.firstname && currentUser?.user_type !== "enterprise-lite")
    return false;

  return (
    currentUser?.bookmarks.find((item) => item.result_page_id === id) ||
    currentUser?.bookmarks.find((item) => item.result_page_id === legacy_id)
  );
}

export function getNoteToID(id: string, legacy_id: string) {
  const currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser) return undefined;

  const note = currentUser.notes?.find(
    (note: Note) => note.id === id || note.id === legacy_id
  );

  return note;
}

export function isNumeric(str: any) {
  if (typeof str !== "string") return false; // we only process strings!
  return (
    !isNaN(parseInt(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
