export default {
  recently_used: "Kürzlich verwendet",
  welcome: "Willkommen",
  home: "Home",
  search: "Suche",
  toolbox: "Toolbox",
  more: "Mehr",
  show_all: "Alle anzeigen",
  show_less: "Weniger anzeigen",
  my_favorites: "Meine Favoriten",
  ask_lyra: "Frag LYRA",
  back: "zurück",
  our_recommendations: "Schon gesehen?",
  explore_mediceo: "MEDICEO kennenlernen!",
  explore_detail:
    "Verpasse keine hilfreichen Funktionen - Entdecke hier den Umfang von MEDICEO.",
  discover_now: "Jetzt entdecken",
  table_of_contents: "Inhaltsverzeichnis",
  calculators: "Rechner",
  welcome_back: "Willkommen zurück",
  login_now: "Jetzt einloggen und loslegen.",
  email: "E-Mail",
  password: "Passwort",
  stay_signed_in: "Angemeldet bleiben",
  sign_in: "Anmelden",
  forgot_pw: "Passwort vergessen?",
  register_now: "Jetzt registrieren?",
  register: "Registrieren",
  done: "Fertig",
  doctor: "Arzt / Ärztin",
  title: "Titel",
  non_med_prof: "Nicht-ärztliches Fachpersonal",
  student: "Studierende",
  select_account_type: "Kontotyp auswählen",
  confirm_selection: "Auswahl bestätigen",
  register_now_: "Jetzt registrieren",
  account_present: "Ich habe schon einen Account",
  log_in: "Anmelden",
  no_continue: "Nein, weiter",
  disclaimer_nurse:
    "Hiermit bestätige ich die Zugehörigkeit zu 'Nicht-ärztlichem Fachpersonal' und lade im Zuge der Validierung meines Status' innerhalb der nächsten 3 Wochen ein bestätigendes Dokument hoch. Weitere Informationen dazu bekommst Du per Mail.",
  disclaimer_doc:
    "Hiermit bestätige ich, dass ich als Arzt / Ärztin approbiert oder zur Ausübung des ärztlichen Berufes befugt bin.",
  Back: "Zurück",
  send: "E-Mail verifizieren",
  enter_login_data: "Deine Zugangsdaten: E-Mail und Passwort",
  history: "Verlauf",
  no_results: "Keine Ergebnisse",
  load_more: "Mehr laden",
  delete_all_search_results: "Alle Suchergebnisse löschen",
  delete_search_results: "Suchergebnisse löschen",
  result: "Resultat",
  content: "Inhalte",
  recommend_mediceo: "MEDICEO empfehlen",
  profile: "Profil",
  premium_free: "Kostenlos Premium",
  upgrade_free: "Für 0,00 € upgraden",
  premium_expires_in_start: "PREMIUM läuft in",
  premium_expires_in_end: "ab",
  account_settings: "Konto & Einstellungen",
  my_account: "Mein Konto",
  my_cases: "Meine Fälle",
  kpi_slide_text: "Klinische Entscheidungen über MEDICEO® getroffen",
  quote_stefan:
    "welche Inhalte wünschst du dir als nächstes? Unser Medical Research Team bereitet komplexe medizinische Themen für den täglichen Gebrauch auf.",
  default_search_placeholder: "Durchsuche den gesamten Inhalt",
  search_placeholder_detail: "Durchsuche ",
  topics: "Themenbereiche",
  favorites: "Favoriten",
  cases: "Fälle",
  export: "Export",
  case: "Fall",
  pages: "Seiten",
  personal: "Persönlich",
  good_morning: "Guten Morgen",
  good_afternoon: "Guten Tag",
  good_evening: "Guten Abend",
  show_sources: "Quellen anzeigen",
  hide_sources: "Quellen ausblenden",
  show_all_content: "Alle Inhalte anzeigen",
  add_case: "Fall hinzufügen",
  recently_added: "Kürzlich hinzugefügt",
  most_used: "Meist verwendet",
  empty_favorites_placeholder:
    "Du hast noch keine Favoriten hinzugefügt. Klicke auf das Herz neben einem Inhalt, um ihn als Favorit zu speichern.",
  edit: "Bearbeiten",
  empty_cases_placeholder:
    "Du hast noch keine Fälle hinzugefügt. Füge einen Fall hinzu, um diesen hier zu sehen.",
  add_favorites: "Favoriten hinzufügen",
  empty_history_placeholder:
    " Du hast noch keine Seite angesehen. Alle angesehenen Seiten werden hier gespeichert.",
  go_to_content: "Zu den Inhalten",
  personal_search_placeholder: "Durchsuche deine persönlichen Inhalte",
  empty_export_placeholder:
    "Du hast noch keinen Export hinzugefügt. Füge einen Export hinzu, um diesen hier zu sehen.",
  add_wishes: "Jetzt Wünsche äußern",
  quote_leon:
    "hausinterne SOPs jederzeit und ortsunabhängig verfügbar - MEDICEO ist 100% KHZG förderfähig.",
  diseases: "Erkrankungen",
  emergency: "Notfall",
  interventions: "Interventionen & Prozeduren",
  therapy_algorithms: "Therapiealgorithmen",
  substances: "Wirkstoffe & Arzneimittel",
  scores: "Scores",
  checklists: "Checklisten",
  emergency_description:
    "In diesem Bereich erhältst Du kurz und prägnant die wichtigsten Informationen zum Management von akuten Notfällen.",
  fast_track_description:
    "In diesem Bereich erreichst du über den schnellsten Weg 'Fast-Track' erkrankungsspezifische Medikamente und Dosierungen.",
  diseases_description:
    "In diesem Bereich erhältst du umfassende Informationen über spezifische Krankheitsbilder, einschließlich ihrer Symptome, Diagnoseverfahren und Behandlungsoptionen.",
  interventions_procedures_description:
    "Hier werden Interventionen und Prozeduren beschrieben, einschließlich ihrer Indikationen, Kontraindikationen, Equipment, Vorbereitungen und Vorsichtsmaßnahmen, Monitoring sowie Technik und Durchführung.",
  therapy_algorithms_description:
    "Diese Kategorie bietet Therapiealgorithmen und Behandlungsleitlinien für verschiedene Krankheitsbilder oder klinische Szenarien, um eine strukturierte Herangehensweise an die Therapie zu unterstützen.",
  scores_description:
    "Hier findest du Informationen zu verschiedenen klinischen Bewertungsskalen und Scores, die zur Einschätzung von Krankheitsschwere, Prognose oder Behandlungsbedarf verwendet werden.",
  calculators_description:
    "In dieser Rubrik stehen verschiedene medizinische Rechner und Werkzeuge zur Verfügung, um Berechnungen durchzuführen, wie zum Beispiel Umrechnen von Einheiten oder Berechnung der Körperoberfläche.",
  register_email: "Mit E-Mail registrieren",
  already_account: "Du hast schon einen Account?",
  email_or_id: "E-Mail (allgemein) oder Nutzerkennung (für Enterprise-Kunden)",
  agreeTerms: {
    confirm: "Indem du fortfährst bestätigst du auch die",
    login: "dem Login",
    registration: "der Registrierung",
    terms: "Nutzungsbedingungen",
    privacy: "Datenschutzerklärung",
    of: "von MEDICEO®.",
    and: "und die",
  },
  reset_password: {
    title: "Passwort zurücksetzen",
    greeting: "E-Mail eingeben und Passwort zurücksetzen.",
    placeholder: "E-Mail",
    reset: "Zurücksetzen",
    success:
      "Du bekommst in den nächsten Minuten eine E-Mail mit den weiteren Schritten. (Prüfe auch deinen Spam-Ordner.)",
    failure:
      "Das Senden der E-Mail ist fehlgeschlagen. Bitte probiere es erneut. Sollte das Problem weiterhin bestehen, kontaktiere gerne",
    login: "Jetzt anmelden",
  },
  role_selection: {
    doctor: "Arzt / Ärztin",
    non_med_prof: "Nicht-ärztliches Fachpersonal",
    student: "Im Studium / Ausbildung",
    select_account_type: "Kontotyp auswählen",
    confirm_selection: "Auswahl bestätigen",
    already_have_account: "Log in",
    not_medical_professional: "Kein Mediziner",
    i_am: "Ich bin...",
  },
  agree_terms_registry: {
    confirm: "Hiermit bestätige ich, dass ich die",
    terms: "Nutzungsbedingungen",
    privacy: "Datenschutzerklärung",
    of: "von MEDICEO® akzeptiere.",
    and: "und die",
  },
  welcome_to: "Willkommen bei",
  add_patient: "Neuen Patienten anlegen",
  hot_features: "Häufig genutzt",
  continue_with_apple: "Mit Apple fortfahren",
  continue_with_google: "Mit Google fortfahren",
  already_know: "KENNEN SIE SCHON DIE",
  non_medical_user: {
    request_access: "Zugriff anfordern",
    no_medical_user_subheading:
      "Bitte fülle die folgenden Felder aus, um einen Zugang als nicht-medizinischer Benutzer anzufordern. Jede Registrierung wird überprüft und manuell vom MEDICEO-Team freigegeben. Wir informieren dich über E-Mail, sobald dein Zugang aktiviert wurde.",
    why_you_wanna_register: "Warum möchtest du dich registrieren?",
  },
  first_name: "Vorname",
  last_name: "Nachname",
  continue_in_web: "WEITER IN DER WEBVERSION",
  min_eight_chars: "Passwort muss mind. 8 Zeichen enthalten.",
  thanks_for_verify:
    "Vielen Dank für die Verifizierung. Vervollständige nun dein Profil 🚀",
  cancel_registration: "Ich möchte die Registrierung abbrechen",
  continue: "Weiter",
  what_profession_do_you_pursue: "What profession do you pursue?",
  notes_fav_or_share: "Notizen, Favoriten und Teilen",
  notes_fav_or_share_description:
    "Hier kannst du eigene Notizen hinzufügen, die Seite als Favorit markieren oder einen direkten Link mit Kollegen und Kolleginnen teilen.",
  edit_patient: "Patienten bearbeiten",
  create_patient: "Patienten anlegen",
  identifier: "Kennung",
  send_feedback_now: "Jetzt Feedback absenden",
  new_update_available: "Neues Update verfügbar!",
  update_now: "Jetzt aktualisieren",
  image_not_existing: "Das Bild ist nicht vorhanden",
  coming_soon: "Bald verfügbar",
};
