import { Feather } from "@expo/vector-icons";
import { Image, View } from "react-native";
import staticColors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

export const CategoryIcon = ({
  style = {},
  title,
  size = 44,
  iconColor,
}: {
  style?: any;
  title: string;
  size?: number;
  iconColor?: any;
}) => {
  return (
    <View
      style={{
        aspectRatio: 1,
        padding: 0,
        marginRight: 12,

        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <IconToCategory
        iconColor={iconColor}
        title={title}
        size={size}
        style={style}
      />
    </View>
  );
};

export function IconToCategory({
  title,
  size = 24,
  style,
  iconColor = null,
}: {
  readonly title: string;
  readonly size?: number;
  readonly style?: any;
  readonly iconColor?: any;
}) {
  const colorScheme = getColorScheme();
  const iconSource = getIconToTitle(title);
  const imageSource = getImageToTitle(title);

  if (imageSource)
    return (
      <Image
        source={imageSource}
        style={{
          ...style,
          width: size,
          height: size,
          resizeMode: "contain",
          margin: 2,
          tintColor: iconColor ?? staticColors[colorScheme].text,
        }}
      />
    );
  else if (iconSource)
    return (
      <Feather
        name={iconSource}
        size={size}
        style={{ padding: 2, ...style }}
        color={iconColor ?? staticColors[colorScheme].text}
      />
    );
  else
    return (
      <Feather
        name="file-text"
        size={size}
        style={{ padding: 2, ...style }}
        color={iconColor ?? staticColors[colorScheme].text}
      />
    );
}

export function getIconToTitle(title: string) {
  switch (title) {
    case "Rechner":
      return "cpu";
    case "Scores":
      return "clipboard";
    case "Interventionen, Prozeduren & Algorithmen":
    case "Interventionen, Prozeduren und Algorithmen":
      return "activity";
    case "Notfall":
      return "alert-triangle";
    case "Favoriten":
      return "heart";
    default:
      return null;
  }
}

export function getImageToTitle(title: string) {
  switch (title) {
    case "Erwachsene":
      return require("../../assets/images/icons/adult.png");
    case "Kinder":
      return require("../../assets/images/icons/kids.png");
    case "Neonaten":
      return require("../../assets/images/icons/neo.png");
    case "Neonatologie und Säuglinge":
      return require("../../assets/images/icons/neo.png");
    default:
      return null;
  }
}
