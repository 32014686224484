import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import ReactNativeModal from "react-native-modal";

import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";

import staticColors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { selectPatientData } from "../../../functions/calculator/actions";

import { selectIsDemoMode } from "../../../functions/navigation/actions";
import getColorScheme from "../../../hooks/useColorScheme";
import { isResultPageWithSkeleton } from "../ResultPageScreen";
import { useExpansion } from "../../../components/ResultPageSkeleton/ExpansionProvider";

export function omitUndefinedDeep(obj) {
  if (_.isArray(obj)) {
    return obj.map(omitUndefinedDeep);
  } else if (_.isPlainObject(obj)) {
    return _.omitBy(_.mapValues(obj, omitUndefinedDeep), _.isUndefined);
  } else if (obj instanceof Set) {
    return Array.from(obj).map(omitUndefinedDeep);
  } else {
    return obj;
  }
}

const ExpandableFABMenu = ({ style = {}, small = false, pageContent }) => {
  const navigation = useNavigation() as any;

  const { closeAll, expandedItems } = useExpansion();

  const colorScheme = getColorScheme();
  const isDemoMode = useSelector(selectIsDemoMode);
  const [menuOpen, setMenuOpen] = useState(false);

  const patientData = useSelector(selectPatientData);

  const [shouldRenderMenuItems, setShouldRenderMenuItems] = useState(false);

  const animationController = useRef(new Animated.Value(0)).current; // For animation

  const ref = useRef(null);

  // Animate menu expansion and collapse
  useEffect(() => {
    if (menuOpen) setShouldRenderMenuItems(true);
    Animated.timing(animationController, {
      toValue: menuOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      if (!menuOpen) setShouldRenderMenuItems(false);
    });
  }, [menuOpen]);

  // Animated styles for menu items
  const menuItemStyle = (index) => ({
    transform: [
      {
        translateY: animationController.interpolate({
          inputRange: [0, 1],
          outputRange: [20 * (index + 1), 0], // Adjust based on item index and desired effect
        }),
      },
    ],
    opacity: animationController,
  });

  if (isDemoMode) return null;

  return (
    <View
      style={{
        position: "absolute",
        bottom: 50,
        zIndex: 1000000000,
        right: Sizes.defaultContainerPadding,
        ...style,
      }}
    >
      <ReactNativeModal
        isVisible={menuOpen}
        onBackdropPress={() => setMenuOpen(false)}
        backdropColor="black"
        backdropOpacity={0.5}
        backdropTransitionOutTiming={0}
        style={{
          margin: 0,
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {shouldRenderMenuItems && (
          <View
            style={{
              position: "absolute",
              right: Sizes.defaultContainerPadding + 8,
              bottom: Sizes.defaultContainerPadding + 50,
            }}
          >
            {/* Back to Home Button */}
            <Animated.View style={[styles.menuItem, menuItemStyle(1)]}>
              <TouchableOpacity
                onPress={() => {
                  setMenuOpen(false);
                  setTimeout(() => {
                    navigation.popToTop();
                  }, 250);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "fab_menu_item_selected",
                    "ExpandableFABMenu",
                    "Back to Home Selected",
                    null // If there's a relevant ID or context, include it here
                  );
                }}
                style={{
                  ...styles.actionButton,
                  backgroundColor: staticColors[colorScheme].foreground,
                  shadowColor: staticColors[colorScheme].text,
                }}
              >
                <Feather
                  name="home"
                  size={24}
                  color={staticColors[colorScheme].text}
                />
                <Text
                  style={{
                    ...styles.actionText,
                    color: staticColors[colorScheme].text,
                  }}
                >
                  Home
                </Text>
              </TouchableOpacity>
            </Animated.View>

            {/* Back to Home Button */}
            <Animated.View style={[styles.menuItem, menuItemStyle(2)]}>
              <TouchableOpacity
                disabled={!menuOpen}
                onPress={() => {
                  setMenuOpen(false);

                  setTimeout(() => {
                    navigation.navigate("FeedbackScreen", {
                      pageContent,
                    });
                  }, 500);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "fab_menu_item_selected",
                    "ExpandableFABMenu",
                    "Feedback Selected",
                    pageContent?._id // Assuming pageContent might have an _id field
                  );
                }}
                style={{
                  ...styles.actionButton,
                  backgroundColor: staticColors[colorScheme].foreground,
                  shadowColor: staticColors[colorScheme].text,
                }}
              >
                <Ionicons
                  name="chatbubble-ellipses-outline"
                  size={24}
                  color={staticColors[colorScheme].text}
                />
                <Text
                  style={{
                    ...styles.actionText,
                    color: staticColors[colorScheme].text,
                  }}
                >
                  Feedback
                </Text>
              </TouchableOpacity>
            </Animated.View>
            {/* Collapse Page Button */}
            {isResultPageWithSkeleton(pageContent.type) && (
              <Animated.View style={[styles.menuItem, menuItemStyle(3), {}]}>
                <TouchableOpacity
                  disabled={
                    !expandedItems || Object.keys(expandedItems)?.length === 0
                  }
                  onPress={() => {
                    setTimeout(() => {
                      closeAll();
                    }, 500);

                    // Track collapse action
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "fab_menu_collapse_action",
                      "ExpandableFABMenu",
                      "Collapse Action Taken",
                      pageContent?._id // Assuming pageContent might have an _id field
                    );
                    setMenuOpen(false);
                  }}
                  style={{
                    ...styles.actionButton,
                    backgroundColor: staticColors[colorScheme].foreground,
                    opacity:
                      !expandedItems || Object.keys(expandedItems)?.length === 0
                        ? 0.5
                        : 1,
                  }}
                >
                  <Feather
                    name="minimize"
                    size={24}
                    color={staticColors[colorScheme].text}
                  />
                  <Text
                    style={{
                      ...styles.actionText,
                      color: staticColors[colorScheme].text,
                    }}
                  >
                    Einklappen
                  </Text>
                </TouchableOpacity>
              </Animated.View>
            )}
            {!patientData && (
              <>
                <Animated.View style={[styles.menuItem, menuItemStyle(4)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      setMenuOpen(false);
                      setTimeout(() => {
                        navigation.navigate("AddPatientScreen", {
                          isFromContentPage: true,
                        });
                      }, 500);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_item_selected",
                        "ExpandableFABMenu",
                        "Add Patient Selected",
                        null
                      );
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: staticColors[colorScheme].foreground,
                      shadowColor: staticColors[colorScheme].text,
                    }}
                  >
                    <Feather
                      name="user-plus"
                      size={24}
                      color={staticColors[colorScheme].text}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: staticColors[colorScheme].text,
                      }}
                    >
                      Patienten anlegen
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
                <Animated.View style={[styles.menuItem, menuItemStyle(4)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      setMenuOpen(false);
                      setTimeout(() => {
                        navigation.navigate("PatientsScreen", {
                          modal: true,
                        });
                      }, 500);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_item_selected",
                        "ExpandableFABMenu",
                        "Select Patients Selected",
                        null // If there's a relevant ID or context, include it here
                      );
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: staticColors[colorScheme].foreground,
                      shadowColor: staticColors[colorScheme].text,
                    }}
                  >
                    <Feather
                      name="users"
                      size={24}
                      color={staticColors[colorScheme].text}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: staticColors[colorScheme].text,
                      }}
                    >
                      Patienten auswählen
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
              </>
            )}
          </View>
        )}
      </ReactNativeModal>
      <FloatingActionButton
        small={small}
        _ref={ref}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        colorScheme={colorScheme}
        pageContent={pageContent}
      />
    </View>
  );
};

const FloatingActionButton = ({
  _ref,
  menuOpen,
  setMenuOpen,
  colorScheme,
  pageContent,
  small,
}) => {
  const webShadowStyle = {
    boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.12)", // Customize as needed
  } as any;

  return (
    <TouchableOpacity
      ref={_ref}
      onPress={() => {
        setMenuOpen(!menuOpen);
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          menuOpen ? "fab_menu_closed" : "fab_menu_opened",
          "ExpandableFABMenu",
          menuOpen ? "FAB Menu Closed" : "FAB Menu Opened",
          pageContent?._id // Assuming pageContent might have an _id field
        );
      }}
      style={[
        {
          backgroundColor: staticColors[colorScheme].foreground,
          shadowColor: staticColors[colorScheme].text,
          padding: small ? 12 : 16,
          borderRadius: 50,
          elevation: 2,
          aspectRatio: 1,
          shadowOffset: { width: 1, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 5,
        },
        Platform.OS === "web" ? webShadowStyle : {},
      ]}
    >
      <Feather
        size={small ? 20 : 28}
        color={staticColors[colorScheme].text}
        name={"menu"}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  menuItem: {
    marginBottom: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",

    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 20,
    elevation: 3,

    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  actionText: {
    marginLeft: 8,
    fontWeight: "bold",
  },
});

export default ExpandableFABMenu;
