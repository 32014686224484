import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { default as LottieView } from "lottie-react-native";
import { useEffect, useRef, useState } from "react";
import {
  AppState,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { openInbox } from "react-native-email-link";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch, useSelector } from "react-redux";

import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";

import I18n from "../../../locales/i18n";
import ProgressBar from "../../components/registry/ProgressBar";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import configureAnimations from "../../functions/configure-animations";
import getFontStyle from "../../functions/getFontSize";
import { setShowNavbar } from "../../functions/navigation/actions";
import { logout } from "../../functions/profile/functions";
import {
  selectCurrentUser,
  selectUserCreationObject,
} from "../../functions/user/actions";
import { setLogged, userLogin } from "../../functions/user/functions";
import { sendVerificationMail } from "../../functions/user/functions/registry";

export function WaitingForRegistrationScreen({ navigation, route }) {
  const isNonMedicalUser = route.params?.isNonMedicalUser;

  const currentUser = useSelector(selectCurrentUser);
  const userCreationObject = useSelector(selectUserCreationObject);

  const [loading, setLoading] = useState(false);
  const [didLoad, setDidLoad] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const lottieRef = useRef<any>();

  const email = userCreationObject.email ?? currentUser.email;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowNavbar(false));
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "waiting_for_registration_screen_opened",
      "WaitingForUserInteractionScreen",
      "UserOpened"
    );
    const appStateListener = AppState.addEventListener(
      "change",
      (nextAppState) => {
        if (nextAppState === "active") {
          checkIsVerified();
        }
      }
    );
    return () => {
      appStateListener.remove();
    };
  }, []);

  async function checkIsVerified() {
    setLoading(true);
    const response: any = await userLogin(
      currentUser.email,
      currentUser.password
    );

    const userData = response.userData;

    console.log("userData", userData);

    if (userData.status !== "active") {
      setLoading(false);
      showInlineNotification({
        text: "E-Mail noch nicht bestätigt.",
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
      return false;
    }
    if (Platform.OS !== "web")
      RNReactNativeHapticFeedback.trigger("notificationSuccess");
    setLogged(true, currentUser.email, currentUser.password, "null");

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "user_verified",
      "WaitingForUserInteractionScreen",
      "UserIsVerified"
    );

    AnalyticsHandler.getInstance().logLogin();
    configureAnimations();
    setDidLoad(true);

    if (Platform.OS === "web") {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "NewRegistryScreen",
          },
        ],
      });
    } else {
      lottieRef?.current?.play();
    }
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.ui.background,
        marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressBar
          style={{ position: "absolute", top: 20 }}
          currentStep={3}
          subSteps={3}
          currentSubStep={0}
        />
        <TopComponent lottieRef={lottieRef} />
        {!didLoad && (
          <View style={{ alignItems: "center" }}>
            <Text style={styles.thankYouText}>{I18n.t("thank_you")}</Text>
            <Text style={styles.registryText}>
              {I18n.t("for_registration")}
            </Text>
            {!isNonMedicalUser ? (
              <Text style={styles.confirmText}>
                {I18n.t("confirm_via_your_email")}
                <Text
                  style={{
                    ...getFontStyle(700),
                  }}
                >
                  {" "}
                  {email}{" "}
                </Text>
                {I18n.t("your_registration")}.{"\n"}
                {I18n.t("check_your_spam_folder")}
              </Text>
            ) : (
              <Text style={styles.confirmText}>
                {I18n.t("we_will_review_your_request")}
              </Text>
            )}
          </View>
        )}
        {!didLoad && (
          <View style={styles.buttonContainer}>
            <View
              style={{
                width: "100%",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              {!isNonMedicalUser && (
                <DefaultButton
                  type={ButtonTypes.Primary}
                  title={I18n.t("open_emails")}
                  icon={<Ionicons name="mail" size={24} />}
                  action={() => {
                    openInbox();
                  }}
                />
              )}

              <DefaultButton
                action={checkIsVerified}
                style={{ marginTop: 12 }}
                type={ButtonTypes.Outline}
                title={I18n.t("check_verification")}
                loading={loading}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                alignSelf: "stretch",
                marginVertical: 20,
                justifyContent: "space-around",
              }}
            >
              {!isNonMedicalUser && (
                <TouchableOpacity
                  disabled={loadingResendEmail}
                  style={{ opacity: loadingResendEmail ? 0.3 : 1 }}
                  onPress={async () => {
                    setLoadingResendEmail(true);
                    const success = await sendVerificationMail(email);
                    setLoadingResendEmail(false);
                    if (success) {
                      showInlineNotification({
                        text: I18n.t("email_resented"),
                        type: InlineNotificationType.SUCCESS,
                        direction: InlineNotificationDirection.FROM_BOTTOM,
                      });
                    } else {
                      showInlineNotification({
                        text: I18n.t("sending_email_failed"),
                        type: InlineNotificationType.ERROR,
                        direction: InlineNotificationDirection.FROM_BOTTOM,
                      });
                    }
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "HostGrotesk-Regular",
                      color: colors.ui.textPrimary,
                      fontSize: 12,
                      textDecorationLine: "underline",
                    }}
                  >
                    {I18n.t("resend_email")}
                  </Text>
                </TouchableOpacity>
              )}

              <TouchableOpacity
                onPress={() => {
                  logout(currentUser, navigation);
                }}
                style={{}}
              >
                <Text
                  style={{
                    fontFamily: "HostGrotesk-Regular",
                    color: colors.ui.textPrimary,
                    fontSize: 12,
                    textDecorationLine: "underline",
                  }}
                >
                  {I18n.t("back_to_login")}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
}

const TopComponent = ({ lottieRef }) => {
  const navigation = useNavigation();

  if (Platform.OS === "web") {
    return (
      <Image
        source={require("../../../assets/logo/main/main_dark.png")}
        style={{
          height: 80,
          width: "90%",
          resizeMode: "contain",
          marginTop: 200,
          marginBottom: 48,
        }}
      />
    );
  }
  return (
    <View
      style={{
        height: 250,
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <LottieView
        ref={lottieRef}
        style={{
          height: 250,
          alignSelf: "center",
        }}
        source={require("../../../assets/animations/mail.json")}
        autoPlay={false}
        loop={false}
        onAnimationFinish={() => {
          setTimeout(() => {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: "NewRegistryScreen",
                },
              ],
            });
          }, 250);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  thankYouText: {
    fontSize: 36,
    color: colors.ui.textPrimary,
    ...getFontStyle(700),
    letterSpacing: 0.05,
  },
  registryText: {
    fontSize: 18,
    color: colors.ui.textPrimary,
    ...getFontStyle(700),
  },
  confirmText: {
    fontSize: 12,
    color: colors.ui.textPrimary,
    ...getFontStyle(400),
    marginTop: 38,
    textAlign: "center",
    paddingLeft: 32,
    paddingRight: 32,
  },
  buttonContainer: {
    width: "85%",
    maxWidth: Sizes.containerWidth,
    flex: 1,
    alignItems: "center",
  },
});
