import { useMemo } from "react";
import { View } from "react-native";
import { useSelector } from "react-redux";
import { selectResultPageSearchQuery } from "../../functions/current-result/actions";
import { getItemTitle, isBaseTypeBox } from "./functions";
import SkeletonItem from "./SkeletonItem";

const NON_CONTENT_TAGS = ["typeId", "key", "title", "id", "color"];

export default function ResultPageSkeleton({
  data,
  scrollY,
  depth = 0,
}: {
  readonly data: any;
  readonly scrollY: number;
  readonly depth?: number;
}) {
  const resultPageSearchQuery = useSelector(selectResultPageSearchQuery);

  const renderData = useMemo(() => {
    // Define the keys you want to check for
    const checkKeys = ["diagnostik"];
    // Check if both keys exist in the data object
    const bothKeysExist = checkKeys.every((key) =>
      Object.keys(data).includes(key)
    );

    let keysToProcess = Object.keys(data)
      .filter(
        (key) =>
          ![
            "detailPath",
            "path",
            "sources",
            "appendedSources",
            "tags",
          ].includes(key)
      )
      .filter((key) => typeof data[key] === "object" && data[key] !== null); // Ensures only valid objects are processed

    // If both keys exist, prioritize them in the specified order
    if (bothKeysExist) {
      const priorityKeys = keysToProcess.filter((key) =>
        checkKeys.includes(key)
      );
      const otherKeys = keysToProcess.filter((key) => !checkKeys.includes(key));
      // Prioritize "diagnostik" while keeping their relative order unchanged
      keysToProcess = [...priorityKeys, ...otherKeys];
    }

    let items = keysToProcess
      .flatMap((key) => {
        if (Array.isArray(data[key]) && data[key].length < 2) {
          return { ...data[key][0], key };
        } else if (Array.isArray(data[key])) {
          return data[key].flatMap((item) => ({
            ...item,
            key: getItemTitle(item),
          }));
        }
      })
      .filter((item) => item)
      .filter(
        (item) =>
          Object.keys(item).filter((key) => !NON_CONTENT_TAGS.includes(key))
            .length > 0
      );

    // Filter items based on the resultPageSearchQuery if it's longer than 2 characters
    if (resultPageSearchQuery && resultPageSearchQuery.length > 2) {
      items = items.filter(
        (item) =>
          item.key === "floatingCalculatorBox" ||
          item.typeId === "calculatorBox" ||
          JSON.stringify(item)
            .toLowerCase()
            .includes(resultPageSearchQuery.toLowerCase())
      );
    }

    return items;
  }, [data, resultPageSearchQuery]); // Include resultPageSearchQuery in the dependency array

  const skeletonIsFirstItemAndNested =
    depth > 0 && !isBaseTypeBox(renderData?.[0]);

  return useMemo(
    () => (
      <View style={{ marginTop: skeletonIsFirstItemAndNested ? 0 : 16 }}>
        {renderData
          ?.slice()
          ?.sort((a, b) => {
            // Check if either item is "calculatorBox" or "newCalculatorBox"
            const isACalculatorBox =
              a.typeId === "calculatorBox" || a.typeId === "newCalculatorBox";
            const isBCalculatorBox =
              b.typeId === "calculatorBox" || b.typeId === "newCalculatorBox";

            // Sort "calculatorBox" and "newCalculatorBox" items first
            if (isACalculatorBox && !isBCalculatorBox) return -1; // a goes before b
            if (!isACalculatorBox && isBCalculatorBox) return 1; // b goes before a

            // Otherwise, maintain their original order
            return 0;
          })
          .map((item, index) => {
            return (
              <SkeletonItem
                key={item.id + "-" + index}
                {...{
                  item,
                  depth,
                  scrollY,
                  index,
                }}
              />
            );
          })}
      </View>
    ),
    [renderData, depth, scrollY]
  );
}
