import { store } from "./store";
import { synchronizeInteraction } from "./sync-offline-interaction-handler";
import { User } from "./user/types";

// Global queue for interaction updates
let interactionUpdateQueue = Promise.resolve();

/**
 * Helper that ensures only one interaction update
 * runs at a time by chaining promises in 'interactionUpdateQueue'.
 */
function enqueueInteractionUpdate(fn) {
  interactionUpdateQueue = interactionUpdateQueue.then(() => fn());
  return interactionUpdateQueue;
}

/**
 * Adds a key/value to the user's interactions in a queued fashion.
 * Uses `synchronizeInteraction` under the hood and updates Redux.
 */
export async function addValueToInteractions(key, value) {
  return enqueueInteractionUpdate(async () => {
    const currentUser = store.getState().userReducer.user as User | null;
    if (!currentUser) return;

    if (typeof key !== "string") {
      key = String(key);
    }
    if (typeof value !== "string") {
      value = JSON.stringify(value);
    }

    let _interactions = [...(currentUser.interaction_records ?? [])];
    _interactions = _interactions.filter((i) => !Object.keys(i).includes(key));
    _interactions.push({ [key]: value });

    // Use synchronizeInteraction rather than direct apiCall
    await synchronizeInteraction({
      action: "addValueToInteractions",
      payload: { interaction_records: _interactions },
      dispatch: null,
    });
  });
}

/**
 * Retrieves a value from user interactions by key.
 */
export function getValueFromInteractions(key) {
  const currentUser = store.getState().userReducer.user as User | null;
  if (!currentUser) return null;

  const record = currentUser.interaction_records?.find((i) =>
    Object.keys(i).includes(key)
  );
  return record ? record[key] : null;
}
