import {
  getPlaceholder,
  keyValueUnit,
} from "../../../../functions/calculator/utilites";
import { NewCalculatorBox } from "../patient-data-box/NewCalculatorBox";

const SIZE = "Körpergröße";

const getUnitForDefault = (unit, key) => {
  const placeholder = getPlaceholder(unit, unit.includes(SIZE));
  const data = {
    key: key,
    title: key,
    placeholder: placeholder,
    maxValue: undefined,
    minValue: undefined,
    conversionUnits: [],
  };

  return data;
};

export function CalculatorBox({ title = "", collapsed = false, unit = "" }) {
  const key =
    unit ??
    keyValueUnit().find((item) =>
      title.toLowerCase().includes(item.value.toLowerCase())
    )?.key;

  const conversedUnit = getUnitForDefault(title, key);

  return (
    <NewCalculatorBox
      units={[conversedUnit]}
      contained={collapsed}
      title={title}
    />
  );
}
