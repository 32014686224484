import { useMemo, useRef, useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";

import { Feather } from "@expo/vector-icons";
import { useFeatureFlag } from "posthog-react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ResultPageHeader from "../../components/header/ResultPageHeaderComponent";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { default as Colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../../functions/current-result/helpers";
import { selectAllContent } from "../../functions/data/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import getColorScheme from "../../hooks/useColorScheme";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";
import ScoreElements from "./components/ScoreElements";
import ScoreInterpretation from "./components/ScoreInterpretation";
import ScoreOverview from "./components/ScoreOverview";

export default function ScoreScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const bottomInsets = useSafeAreaInsets().bottom;

  const showOverview = useFeatureFlag("show_score_overview");

  const setInterpretationContent = route.params?.setInterpretationContent;
  const additionalCaseData = route.params?.additionalCaseData;
  const [informationHeight, setInformationHeight] = useState(0);
  const colorScheme = getColorScheme();
  const scrollViewRef = useRef<any>();
  const [answers, setAnswers] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.answers ?? []
  );

  const [interpretationItem, setInterpretationItem] = useState<any>(null);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [currentSelectionIndex, setCurrentSelectionIndex] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.currentSelectionIndex ?? 0
  );
  const currentUser = useSelector(selectCurrentUser);
  const euroscoreId =
    useSelector(selectAllContent).medicalCategories.content?.euroscoreId;
  const hasBookmark = useMemo(
    () => getIsBookmarkToID(pageContent._id, pageContent.legacy_id),
    [pageContent, currentUser]
  );
  const note = useMemo(
    () => getNoteToID(pageContent._id, pageContent.legacy_id),
    [pageContent, currentUser]
  );
  const { isAssistant } = route.params;
  const isEuroscore = pageContent._id === euroscoreId;
  const [score, setScore] = useState(
    additionalCaseData?.caseData?.[pageContent._id]?.score ?? null
  );

  const onlyScoreElements = showOverview
    ? true
    : pageContent?.scoreElements?.every(
        (element) =>
          element.typeId === "scoreElement" ||
          element.typeId === "scoreElementMultiple"
      );

  const [overviewExpanded, setOverviewExpanded] = useState(false);
  const [interpretationCollapsed, setInterpretationCollapsed] = useState(false);

  const isModal = false; //route.params?.modal && Platform.OS === "web";

  return (
    <>
      <ResultPagesWrapper
        isModal={route.params?.modal}
        showFloatingActionButton={false}
        scrollViewRef={scrollViewRef}
        navigation={navigation}
        pageContent={pageContent}
        warningModalVisible={warningModalVisible}
        setWarningModalVisible={setWarningModalVisible}
        historyUseEffectObj={{
          customFunction: null,
          logUserScreenInteraction: {
            screen: "ScoreScreen",
            action: "score_screen_opened",
            values: pageContent?.title,
            id: pageContent?._id,
          },
        }}
      >
        <SafeAreaView
          style={[
            styles.safeArea,
            { backgroundColor: Colors[colorScheme].background },
          ]}
        >
          <View
            style={[
              isModal && styles.modalSafeArea,
              { flex: 1, alignSelf: "stretch" },
            ]}
          >
            <View style={[styles.container, isModal && styles.modalContainer]}>
              <View
                style={{
                  width: "100%",
                  backgroundColor: Colors[colorScheme].background,
                  height: 12,
                  marginTop: -12,
                  zIndex: 50,
                  elevation: 2,
                }}
              />
              <View style={{ width: "100%", flex: 1 }}>
                <View
                  style={{
                    alignSelf: "stretch",
                  }}
                >
                  {isModal && <ResultPageHeader isModal integrated />}
                </View>
                <View
                  style={{
                    width: "100%",
                    padding: Sizes.defaultContainerPadding,
                  }}
                >
                  <ResultPageUserInteractionsHeader
                    {...{
                      isAssistant,
                      pageContent,
                      hasBookmark,
                      setWarningModalVisible,
                      note,
                      currentUser,
                    }}
                  />
                  {onlyScoreElements && (
                    <TouchableOpacity
                      onPress={() => {
                        configureAnimations();
                        AnalyticsHandler.getInstance().logUserScreenInteraction(
                          "overview_expanded",
                          "ScoreScreen",
                          overviewExpanded ? "collapsed" : "expanded",
                          pageContent._id
                        );
                        setInterpretationCollapsed(!overviewExpanded);
                        setOverviewExpanded((prev) => !prev);
                      }}
                      style={{
                        marginTop: 12,
                        marginBottom: 12,
                        padding: 8,
                        paddingHorizontal: 20,
                        borderRadius: 20,
                        flexDirection: "row",
                        gap: 8,
                        alignItems: "center",
                        backgroundColor: Colors[colorScheme].foreground,
                        alignSelf: "flex-start",
                      }}
                    >
                      <Text>
                        Übersicht {overviewExpanded ? "ausblenden" : "anzeigen"}
                      </Text>
                      <Feather
                        name={overviewExpanded ? "chevron-up" : "chevron-down"}
                        size={20}
                        color={Colors[colorScheme].text}
                      />
                    </TouchableOpacity>
                  )}
                  {overviewExpanded && (
                    <ScoreOverview pageContent={pageContent} />
                  )}
                  <View
                    key={"RES_BOXES"}
                    style={{ width: "100%", justifyContent: "center" }}
                  >
                    <View
                      onLayout={(e) => {
                        if (informationHeight === 0)
                          setInformationHeight(e.nativeEvent.layout.height);
                      }}
                    >
                      <ResultBoxes pageContent={{ boxes: pageContent.boxes }} />
                    </View>
                    <ScoreElements
                      scrollViewRef={scrollViewRef}
                      data={pageContent}
                      {...{
                        answers,
                        setAnswers,
                        score,
                        setScore,
                        informationHeight,
                        isEuroscore,
                        setCurrentSelectionIndex,
                        currentSelectionIndex,
                      }}
                    />
                  </View>
                  <View
                    style={{
                      marginVertical: 20,
                      flexDirection: "row",
                      alignItems: "center",
                      flex: 1,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        height: 1,
                        backgroundColor: Colors[colorScheme].text,
                      }}
                    />
                    <Text
                      style={{
                        fontSize: Sizes.boxText + 4,
                        marginLeft: 20,
                        fontWeight: "500",
                        color: Colors[colorScheme].text,
                      }}
                    >
                      Punkte: {Math.round((score ?? 0) * 100) / 100}
                    </Text>
                  </View>
                  <View style={{ alignSelf: "stretch" }}>
                    <SourcesView {...{ pageContent }} />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </SafeAreaView>
      </ResultPagesWrapper>
      <View style={{ paddingBottom: bottomInsets }}>
        {((pageContent.scoreInterpretationElements &&
          pageContent.scoreInterpretationElements.length > 0) ||
          isEuroscore) && (
          <ScoreInterpretation
            collapsed={interpretationCollapsed}
            setCollapsed={setInterpretationCollapsed}
            item={interpretationItem}
            setItem={setInterpretationItem}
            setInterpretationContent={setInterpretationContent}
            isEuroscore={isEuroscore}
            answers={answers}
            data={pageContent}
            score={score}
            {...{ currentSelectionIndex, setCurrentSelectionIndex }}
            id={pageContent._id}
          />
        )}
      </View>
    </>
  );
}

const styles = StyleSheet.create({
  safeArea: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  modalSafeArea: {
    alignSelf: "center",
    width: "80%",
    height: "80%",
    marginVertical: 40,
    borderRadius: 15,
    overflow: "hidden",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    elevation: 5,
    backgroundColor: Colors["light"].background,
  },
  container: {
    flex: 1,
    alignSelf: "stretch",
  },
  modalContainer: {
    borderRadius: 15,
    marginHorizontal: "5%",
    overflow: "hidden",
  },
});
