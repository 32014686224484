import { useNavigation } from "@react-navigation/native";
import { Text, TouchableOpacity, View } from "react-native";

import { Feather } from "@expo/vector-icons";
import i18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import getFontStyle from "../../functions/getFontSize";
import { navigateFromSearch } from "../../functions/navigation/helpers";
import { IconToCategory } from "../CategoryIcon";

export const SearchResultItem = ({
  item,
  searchQuery,
  additionalAction = () => {},
}) => {
  const navigation = useNavigation();

  const typePrefix = getTypePrefix(item.content_type_id);

  return (
    <View
      style={{
        width: "100%",
        padding: 12,
        borderRadius: 12,

        backgroundColor: colors.ui.cardBackground,
        marginVertical: 8,
      }}
    >
      <TouchableOpacity
        style={{ width: "100%" }}
        onPress={() => {
          additionalAction();
          AnalyticsHandler.getInstance().logSearch(
            "search_item_selected",
            searchQuery,
            "global_search",
            true,
            item._id
          );

          navigateFromSearch(item, navigation);
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <CategoryIcon
                title={item.content?.path?.[0] ?? ""}
                size={16}
                style={{
                  zIndex: 10,
                  alignSelf: "flex-start",
                  borderRadius: 100,
                  backgroundColor: colors.brand.greys.gE5E5E5,
                  padding: 4,
                }}
              />

              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "HostGrotesk-Regular",
                  color: colors.ui.textPrimary,
                }}
              >
                {item.content?.path?.length > 1 && typePrefix + " in  "}
                <Text
                  style={{
                    color: colors.ui.accent,
                    ...getFontStyle(700),
                  }}
                >
                  {item.content?.path?.[0]}
                </Text>
              </Text>
            </View>

            <View style={{ marginBottom: 0, gap: 8, flex: 1 }}>
              <Text
                style={{
                  ...getFontStyle(700),
                  color: colors.ui.textPrimary,
                  fontSize: Sizes.boxText,
                  marginBottom: 0,
                }}
              >
                {(item.title ?? item.fieldTitle)?.trim()}
              </Text>
            </View>
          </View>
          <Feather
            name="chevron-right"
            size={20}
            color={colors.ui.textPrimary}
            style={{
              marginLeft: 20,
              opacity: 0.5,
            }}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const CategoryIcon = ({ title, style = {}, size }) => {
  return (
    <View
      style={{
        aspectRatio: 1,
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <IconToCategory iconColor={"black"} title={title} size={size} />
    </View>
  );
};

function getTypePrefix(type) {
  switch (type) {
    case "scorePage":
      return "Score";
    case "resultPage":
      return i18n.t("result");
    case "nodePage":
      return i18n.t("content");
    default:
      return "Resultat";
  }
}
