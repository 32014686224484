import { useNavigation } from "@react-navigation/native";
import getColorScheme from "../../../../hooks/useColorScheme";
import { TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import Colors from "../../../../constants/static-colors";
import { Feather } from "@expo/vector-icons";
import { Text } from "react-native-paper";
import { Sizes } from "../../../../constants/static-sizes";
import { useSelector } from "react-redux";
import { selectPatientData } from "../../../../functions/calculator/actions";
import SectionHeaderText from "../../../../components/SectionHeaderText";
import i18n from "../../../../../locales/i18n";

export const HotFeaturesComponent = () => {
  const navigation = useNavigation();

  const HotFeatureItem = ({
    icon,
    text,
    tIndex,
    action,
  }: {
    icon;
    text;
    tIndex?;
    action?;
  }) => {
    const colorScheme = getColorScheme();

    return (
      <TouchableOpacity
        onPress={() => {
          if (action) action();
          else
            navigation.navigate("PersonalContentScreen", { selection: tIndex });
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "hot_feature_clicked",
            text,
            "Hot Feature Clicked"
          );
        }}
        style={{
          gap: 8,
          marginTop: 12,
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: 16,
          paddingVertical: 12,
          borderRadius: 12,
          backgroundColor: Colors[colorScheme].foreground,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: "#ddddddcc",
            padding: 10,
          }}
        >
          <Feather name={icon} size={20} color="black" />
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText + 1,
            fontWeight: "500",
            color: Colors[colorScheme].text,
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  };

  const patientData = useSelector(selectPatientData);

  return (
    <View>
      <SectionHeaderText>{i18n.t("hot_features")}</SectionHeaderText>
      {/* <View> */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 20,
        }}
      >
        {/* <HotFeatureItem
            icon={patientData ? "edit" : "plus"}
            text={
              patientData ? "Patienten bearbeiten" : "Neuen Patienten anlegen"
            }
            action={() => {
              navigation.navigate("AddPatientScreen");
            }}
          /> */}
        <HotFeatureItem
          icon={"heart"}
          text={i18n.t("favorites")}
          action={() => navigation.navigate("FavoritesScreen")}
        />
        {/* </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}
        > */}
        <HotFeatureItem icon={"save"} text={i18n.t("cases")} tIndex={1} />
        <HotFeatureItem
          icon="rotate-cw"
          text={i18n.t("history")}
          action={() => navigation.navigate("HistoryScreen")}
        />
        {/*   */}
      </View>
    </View>
  );
};
