import { Ionicons } from "@expo/vector-icons";
import NetInfo from "@react-native-community/netinfo";
import { debounce } from "lodash";
import { MeiliSearch } from "meilisearch";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import i18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { loadEnvironment } from "../../api/content/load-all/load-all-content";
import Colors from "../../constants/static-colors";
import fallbackSearch from "../../functions/search/fallback-search";
import { addValueToInteractions } from "../../functions/user-interaction-handler";
import getColorScheme from "../../hooks/useColorScheme";

import { checkInternetConnection } from "react-native-offline";
import { useSelector } from "react-redux";
import { Sizes } from "../../constants/static-sizes";
import { selectAllContent } from "../../functions/data/actions";
import { selectEnterprise } from "../../functions/enterprise/actions";
import { getSharedStylesContent } from "../boxes/SharedStyles";
import { BoxTextComponent } from "../custom-box-text/CustomBoxTextComponent";
import DefaultActivityIndicator from "../DefaultActivityIndicator";
import { SearchResultItem } from "../search/SearchResultItem";

const AMOUNT = 10;

export async function getIsOffline() {
  if (Platform.OS === "web") return false;
  const isConnected = await checkInternetConnection();
  return !isConnected;
}

function getSearchUrl(exportArea, lang = "de", customerId = null) {
  if (!customerId) {
    return `${exportArea}_${lang}_base`;
  } else {
    return `${exportArea}_${lang}_enterprise_${customerId}`;
  }
}

export default function SearchResults({
  query,
  setQuery,
  filters,
  isGlobalSearch = false,
}) {
  const [results, setResults] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchHistory, setSearchHistory] = useState<string[]>([]);

  const [totalEstimates, setTotalEstimates] = useState(0);
  const [isOfflineMode, setIsOfflineMode] = useState(false);
  const colorScheme = getColorScheme();
  const currentEnterprise = useSelector(selectEnterprise);

  const allPages = useSelector(selectAllContent).pages;

  const client = new MeiliSearch({
    host: "https://meilisearch.medi.ceo/",
    apiKey: "^Hg$Fgt@!PVcS4Lj5omF#J7i$Bqa",
  });

  const addToSearchHistory = useCallback(
    debounce(async (item) => {
      const temp = searchHistory.slice();
      temp.unshift(item);
      setSearchHistory(temp);

      await addValueToInteractions("search_history", JSON.stringify(temp));
      AnalyticsHandler.getInstance().logSearch(
        "search_issued",
        query,
        "global_search"
      );
    }, 2000),
    [searchHistory]
  );

  const search = useCallback(
    debounce(async (query, add = false) => {
      if (query === "") {
        setResults([]);
        return;
      }

      try {
        const state = await NetInfo.fetch();

        let response;
        if (
          !state.isConnected ||
          (state.type === "cellular" &&
            state.details.cellularGeneration === "2g")
        ) {
          setIsOfflineMode(true);
          response = fallbackSearch(
            query,
            10,
            currentPage * AMOUNT,
            filters,
            allPages
          );
        } else {
          setIsOfflineMode(false);
          const searchFilters =
            filters.length > 0
              ? `content.path IN [${filters
                  .map((filter) => `"${filter}"`)
                  .join(", ")}]`
              : undefined;
          const environment = await loadEnvironment();
          const url = getSearchUrl(
            environment,
            "de",
            currentEnterprise?.internal_customer_id
          );

          response = await client.index(url).search(query, {
            limit: 10,
            offset: currentPage * AMOUNT,
            filter: searchFilters,
            showRankingScore: true,
            showMatchesPosition: true,
          });
        }

        if (!add) {
          setResults(response.hits);
        } else if (add) setResults((res) => res.concat(response.hits));

        setTotalEstimates(response.estimatedTotalHits);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }, 250),
    [currentPage, filters]
  );

  useEffect(() => {
    setLoading(true);
    search(query, true);
  }, [currentPage]);

  useEffect(() => {
    setData(results);
    if (searchHistory[0] !== query && query.length > 1)
      addToSearchHistory(query);
  }, [results, filters]);

  useEffect(() => {
    setLoading(true);
    setCurrentPage(0);
    if (query.length > 1) search(query);
    else if (query.length === 0) setData([]);
  }, [query, filters]);

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 50; // you can adjust this threshold
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  return (
    <View
      style={{
        flex: 1,

        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <ScrollView
        keyboardShouldPersistTaps="handled"
        keyboardDismissMode="on-drag"
        onTouchStart={(e) => e.preventDefault()}
        style={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          backgroundColor: Colors[colorScheme].background,
          flexGrow: 1,
          paddingBottom: 20,
        }}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            if (!loading && (currentPage + 1) * AMOUNT < totalEstimates) {
              setCurrentPage((c) => c + 1);
            }
          }
        }}
        scrollEventThrottle={50}
      >
        {query.length < 2 && isGlobalSearch && <EmptySearch />}

        {totalEstimates > 0 && !loading && query.length > 1 && (
          <Text
            style={{
              color: Colors[colorScheme].text,
              opacity: 0.5,
              alignSelf: "flex-end",
              fontSize: 12,
              marginTop: 8,
            }}
          >
            {totalEstimates} Ergebnisse
          </Text>
        )}
        {isOfflineMode && (
          <Text
            style={{
              color: Colors[colorScheme].text,
              opacity: 0.5,
              alignSelf: "flex-end",
              fontSize: 12,
              marginTop: 8,
            }}
          >
            Suche ist offline, Ergebnisse können abweichen.
          </Text>
        )}
        {loading && query.length > 1 && currentPage === 0 ? (
          <DefaultActivityIndicator style={{ marginTop: 20 }} />
        ) : (
          <ResultList data={data} query={query} isOfflineMode={isOfflineMode} />
        )}
        {loading && query.length > 1 && currentPage !== 0 && (
          <View style={{ paddingVertical: 20 }}>
            <DefaultActivityIndicator />
          </View>
        )}
        {!loading &&
          query.length > 1 &&
          (currentPage + 1) * AMOUNT < totalEstimates && (
            <TouchableOpacity
              style={{
                flexDirection: "row",
                alignSelf: "center",
                alignItems: "center",
              }}
              onPress={() => setCurrentPage((c) => c + 1)}
            >
              <Text
                style={{
                  color: Colors[colorScheme].accent,
                  fontSize: 14,
                  marginVertical: 20,
                  marginRight: 4,
                }}
              >
                {i18n.t("load_more")}
              </Text>
              <Ionicons
                name="chevron-down"
                size={20}
                color={Colors[colorScheme].accent}
              />
            </TouchableOpacity>
          )}
      </ScrollView>
    </View>
  );
}

const ResultList = ({ data, query, isOfflineMode }) => {
  const colorScheme = getColorScheme();

  const topResults = data
    .filter((item) => {
      if (isOfflineMode) return true;
      else return item._rankingScore > 0.95;
    })
    .map((result, index) => (
      <View key={result._id + index} style={{ flexDirection: "row" }}>
        <SearchResultItem searchQuery={query} item={result} />
      </View>
    ));

  // Filter items with _rankingScore lower than or equal to 0.9
  const otherResults = data
    .filter((item) => {
      if (isOfflineMode) return false;
      else return item._rankingScore <= 0.95;
    })
    .map((result, index) => (
      <View key={result._id + index} style={{ flexDirection: "row" }}>
        <SearchResultItem searchQuery={query} item={result} />
      </View>
    ));

  if (data.length === 0 && query.length > 2)
    return (
      <View
        style={{
          marginTop: 20,
        }}
      >
        <Text
          style={{
            fontStyle: "italic",
            color: Colors[colorScheme].text,
          }}
        >
          Keine Ergebnisse
        </Text>
      </View>
    );
  else if (query.length <= 2) return null;
  else
    return (
      <View style={{ flex: 1 }}>
        {topResults.length > 0 && (
          <View>
            <View
              style={{
                //backgroundColor: Colors[colorScheme].lightGray,
                marginTop: 16,
                alignSelf: "stretch",
              }}
            >
              <Text
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: Colors[colorScheme].text,
                  marginBottom: 8,
                }}
              >
                Top Ergebnisse
              </Text>
            </View>
            {topResults}
          </View>
        )}
        {otherResults.length > 0 && (
          <View>
            <View
              style={{
                //backgroundColor: Colors[colorScheme].lightGray,
                marginTop: 32,
                alignSelf: "stretch",
              }}
            >
              <Text
                style={{
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: Colors[colorScheme].text,
                  marginBottom: 8,
                }}
              >
                Weitere Ergebnisse
              </Text>
            </View>
            {otherResults}
          </View>
        )}
      </View>
    );
};

const EmptySearch = () => {
  return useMemo(
    () => (
      <View
        style={{
          flex: 1,
        }}
      >
        <View style={{ marginTop: 32, alignItems: "center", flex: 1 }}>
          <Text
            style={{
              fontSize: Sizes.boxText,
              marginBottom: 16,
            }}
          >
            Folgende Bereiche werden durchsucht...
          </Text>
          <View style={{ alignItems: "flex-start", flex: 1 }}>
            <BoxTextComponent
              content={`<div>
  <ul>
    <li>Notfall</li>
    <li>Diagnostik und Therapie (Erwachsene, Kinder, Neonaten)</li>
    <li>Scores & Rechner</li>
    <li>Interventionen, Prozeduren & Algorithmen</li>
  </ul>
</div>`}
              style={getSharedStylesContent("", "light")}
            />
          </View>
        </View>
      </View>
    ),
    []
  );
};
