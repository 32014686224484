import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useRef, useState } from "react";
import { Platform, StatusBar, StyleSheet, Text, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import {
  selectCurrentHighlightSection,
  selectCurrentResultPage,
  selectResultPageCaseContent,
  selectShowTitle,
} from "../../functions/current-result/actions";
import {
  getIsBookmarkToID,
  getNoteToID,
} from "../../functions/current-result/helpers";
import { selectIsDemoMode } from "../../functions/navigation/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import isLargerDevice, { isWebAndNotMobile } from "../../hooks/isLargerDevice";
import getColorScheme from "../../hooks/useColorScheme";
import LargeScreenResultPageUserInteractionsComponent from "../LargeScreenResultPageUserInteractionsComponent";

import { LinearGradient } from "expo-linear-gradient";
import { selectPatientData } from "../../functions/calculator/actions";
import { PatientDataHeaderComponent } from "./DefaultHeaderComponents";
import { BackButton } from "./header-components/HeaderBackButton";

export default function ResultPageHeader({
  isModal = false,
  integrated = false,
}) {
  const insets = useSafeAreaInsets();
  const paddingTop = isLargerDevice() ? insets.top + 24 : insets.top;
  const colorScheme = getColorScheme();

  const isDemoMode = useSelector(selectIsDemoMode);

  const highlightedSection = useSelector(selectCurrentHighlightSection);

  const resultPageCaseContent = useSelector(selectResultPageCaseContent);

  const calcBoxRef = useRef(null);

  const showTitle = useSelector(selectShowTitle);
  const [height, setHeight] = useState(48);
  const currentResultPage = useSelector(selectCurrentResultPage);
  const currentUser = useSelector(selectCurrentUser);
  const navigation = useNavigation();
  const patientData = useSelector(selectPatientData);
  const note = useMemo(
    () => getNoteToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );
  const hasBookmark = useMemo(
    () =>
      getIsBookmarkToID(currentResultPage?._id, currentResultPage?.legacy_id),
    [currentResultPage, currentUser]
  );

  // ...

  useEffect(() => {
    if (Platform.OS === "ios" && highlightedSection) configureAnimations(250);
  }, [highlightedSection]);

  return (
    <View
      style={{
        backgroundColor: Colors[colorScheme].background,
        paddingHorizontal: integrated ? 0 : Sizes.defaultContainerPadding,
        zIndex: 10,
        left: 0,
        right: 0,
        paddingTop: integrated
          ? 24
          : isModal
          ? Platform.OS === "android"
            ? StatusBar.currentHeight
            : 0
          : Platform.OS === "android"
          ? StatusBar.currentHeight
          : paddingTop,
        flexDirection: "column",
      }}
    >
      {patientData && (
        <LinearGradient
          start={{ x: 1.0, y: 1 }}
          end={{ x: 1, y: 0 }}
          colors={[Colors["light"].background, Colors["light"].selectedTint]}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            zIndex: -10,
          }}
        />
      )}
      {patientData && (
        <View style={{ marginBottom: -12 }}>
          <PatientDataHeaderComponent />
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingVertical: integrated ? 0 : 12,
          marginTop: 4,
          marginBottom: integrated ? 0 : isWebAndNotMobile() ? 8 : 0,
        }}
      >
        {!isDemoMode && (
          <BackButton isModal={isModal} navigation={navigation} />
        )}

        <View
          style={{
            flex: 1,
            justifyContent: "center",
            marginLeft: 8,
            marginRight: 12,
          }}
        >
          {showTitle && (
            <Text
              style={{
                fontSize: 15,
                marginBottom: -0.5,
                fontWeight: "700",
                color: Colors[colorScheme].text,
              }}
              ellipsizeMode="tail"
              numberOfLines={1}
            >
              {currentResultPage.title ??
                currentResultPage.pageTitle ??
                currentResultPage.fieldTitle ??
                ""}
            </Text>
          )}
        </View>
        <LargeScreenResultPageUserInteractionsComponent
          isDemoMode={isDemoMode}
          currentUser={currentUser}
          pageContent={currentResultPage}
          hasBookmark={hasBookmark}
          note={note}
        />
      </View>
      <View
        onLayout={(event) => {
          setHeight(event.nativeEvent.layout.height);
        }}
        style={{
          position: "absolute",
          bottom: -height,
          backgroundColor: Colors[colorScheme].background,
          opacity: 1,
          left: 0,
          zIndex: 10,
          right: 0,
        }}
      >
        {resultPageCaseContent && (
          <View
            ref={calcBoxRef}
            style={{
              width: "100%",
              zIndex: 5,
              opacity: 1,
              marginBottom: 16,
              flexDirection: "row",
              borderTopColor: colorScheme === "light" ? "#ddd" : "#444",
              borderTopWidth: StyleSheet.hairlineWidth,
              paddingTop: 12,
              backgroundColor: Colors[colorScheme].background,
              paddingHorizontal: Sizes.defaultContainerPadding,
              gap: 12,
              alignItems: "center",
            }}
          >
            {resultPageCaseContent && (
              <View style={{ alignItems: "flex-end", flex: 1 }}>
                <Text
                  style={{
                    fontSize: Sizes.boxText,
                    fontWeight: "bold",
                    marginBottom: 2,
                    color: Colors[colorScheme].text,
                  }}
                >
                  {resultPageCaseContent?.note}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: Colors[colorScheme].text,
                    opacity: 0.5,
                  }}
                  numberOfLines={3}
                >
                  {formatDate(resultPageCaseContent.last_updated)}
                </Text>
              </View>
            )}
          </View>
        )}
        <View
          style={{
            paddingHorizontal: Sizes.defaultContainerPadding,
            backgroundColor: Colors[colorScheme].background,
            zIndex: 5,
            opacity: 1,
          }}
        >
          {highlightedSection}
        </View>
        {(resultPageCaseContent || highlightedSection) && (
          <View
            style={{
              shadowColor: Colors[colorScheme].text,
              shadowOffset: {
                width: 0,
                height: 3,
              },
              shadowOpacity: 0.08,
              shadowRadius: 4,
              width: "100%",
              backgroundColor: Colors[colorScheme].background,
              height: 30,
              marginTop: -30,
              opacity: 1,
              zIndex: -10,
              elevation: 2,
            }}
          />
        )}
      </View>
    </View>
  );
}

export function formatDate(entry: string) {
  entry = entry.replace(/^(\d{3})/, "$1").split(".")[0];

  const date = new Date(entry);
  if (isNaN(date.getTime())) {
    return "Invalid";
  }

  const germanFormattedDate = date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour12: false,
    minute: "2-digit",
    hour: "2-digit",
  });
  return germanFormattedDate;
}
