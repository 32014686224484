import { Text, TouchableOpacity, View } from "react-native";

import { useEffect } from "react";
import I18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import CustomModal from "./CustomModal";

export default function RegistryHintModal({ open, onClose }) {
  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "registry_hint_modal_presented",
        "RegistryHintModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <View
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></View>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("why_we_need_this_infos")}
        </Text>
        <Text
          style={{
            fontSize: 14,
            fontFamily: "HostGrotesk-Regular",
            lineHeight: 18,
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("reason_for_more_infos")}
        </Text>
        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          text={I18n.t("got_it")}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}

const DefaultButton = ({
  text,
  icon,
  style,
  action,
}: {
  text: string;
  icon?: any;
  style?: any;
  action?: any;
}) => {
  return (
    <TouchableOpacity
      onPress={action}
      style={{
        alignSelf: "stretch",
        paddingHorizontal: 16,
        paddingVertical: 10,
        borderRadius: 12,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.ui.primary,
        ...style,
      }}
    >
      <Text
        style={{
          fontSize: 16,
          ...getFontStyle(500),
        }}
      >
        {text}
      </Text>
      <View style={{ position: "absolute", right: 16 }}>{icon}</View>
    </TouchableOpacity>
  );
};
