"use client";

import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { lazy, Suspense, useEffect, useState } from "react";
import { Platform, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { HintBox } from "../../components/boxes/box-types/hint-box/HintBox";
const ResultPageSkeleton = lazy(
  () => import("../../components/ResultPageSkeleton/ResultPageSkeleton")
);

import AdvancedResultPageSkeletonLoader from "../../components/AdvancedResultPageLoadingSkeleton";
import { getSharedStylesLargeContent } from "../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../components/custom-box-text/CustomBoxTextComponent";
import CustomResultComponentSorter from "../../components/result/CustomResultComponents/CustomResultComponentSorter";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";
import TitleText from "../../components/result/TitleText";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import { selectCurrentUser } from "../../functions/user/actions";
import type { Note } from "../../functions/user/types";

export function isResultPageWithSkeleton(type) {
  return (
    type === "erkrankungResultPageType" ||
    type === "interventionResultPageType" ||
    type === "drugResultPageType" ||
    type === "fastTrackResultPageType"
  );
}

export default function ResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contentReady, setContentReady] = useState(false);

  // Add a minimum loading time to ensure the loading indicator is visible
  useEffect(() => {
    if (pageContent) {
      // Set a minimum loading time of 500ms to ensure the loading indicator is visible

      setContentReady(true);
    }
  }, [pageContent]);

  // Only stop loading when content is ready and minimum time has passed
  useEffect(() => {
    if (contentReady) {
      setLoading(false);
    }
  }, [contentReady]);

  useEffect(() => {
    async function checkFirstTimeOnboarding() {
      if (Platform.OS === "web") return;
      if (pageContent.floatingCalculatorBox) {
        if (await handleOneTimeAction("resultpage_onboarding_calculator")) {
          navigation.navigate("OnboardingScreen", {
            pageKey: "calculator",
          });
        }
      } else {
        if (await handleOneTimeAction("resultpage_onboarding_boxes")) {
          navigation.navigate("OnboardingScreen", {
            pageKey: "boxes",
          });
        }
      }
    }
    setTimeout(() => {
      checkFirstTimeOnboarding();
    }, 750);
  }, []);

  return (
    <ResultPagesWrapper
      navigation={navigation}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          screen: "ResultPageScreen",
          action: "result_page_opened",
          id: pageContent?._id,
          values: pageContent?.title,
        },
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          flex: 1,
        }}
      >
        <View
          style={{
            width: "100%",
            height: 12,
            marginTop: -12,
            zIndex: 50,
            elevation: 2,
          }}
        />
        {loading ? (
          <AdvancedResultPageSkeletonLoader showTitle={true} />
        ) : (
          <ResultPageContentWrapper
            pageContent={pageContent}
            setWarningModalVisible={setWarningModalVisible}
          />
        )}
      </View>
    </ResultPagesWrapper>
  );
}

export const ResultPageContentWrapper = ({
  pageContent,
  setWarningModalVisible,
}) => {
  const navigation = useNavigation();

  return (
    <View
      style={{
        width: "100%",
        padding: Sizes.defaultContainerPadding,
      }}
    >
      <ResultPageUserInteractionsHeader
        {...{ pageContent, setWarningModalVisible }}
      />
      <View
        style={{
          width: "100%",
          minHeight: "85%",
          justifyContent: "flex-start",
        }}
      >
        <Suspense
          fallback={<AdvancedResultPageSkeletonLoader showTitle={false} />}
        >
          <UserNoteView pageContent={pageContent} navigation={navigation} />
          <ResultPageContentView pageContent={pageContent} />
        </Suspense>
      </View>
    </View>
  );
};

const UserNoteView = ({ pageContent, navigation }) => {
  const currentUser = useSelector(selectCurrentUser);

  const note = currentUser.notes?.find(
    (note: Note) =>
      note.id === pageContent._id || note.id === pageContent.legacy_id
  );

  if (note?.showInResult && note.note !== "") {
    return (
      <TouchableOpacity
        key={"NOTES"}
        onPress={() =>
          navigation.navigate("CreateNoteScreen", {
            note,
            currentDisplayID: pageContent._id,
          })
        }
      >
        <View
          style={{
            pointerEvents: "none",
          }}
        >
          <HintBox
            title={note.note}
            expandableContent={undefined}
            inlineImage={undefined}
            isNote={true}
          />
        </View>
      </TouchableOpacity>
    );
  }

  return null;
};

export const ResultPageContentView = ({ pageContent }) => {
  const detailPage = pageContent["detailPage:ResultOrNodeId"];

  return (
    <View style={{ flex: 1 }}>
      {isResultPageWithSkeleton(pageContent.type) ||
      JSON.stringify(pageContent).includes("multiContentSectionType") ? (
        <Suspense
          fallback={<AdvancedResultPageSkeletonLoader showTitle={false} />}
        >
          <ResultPageSkeleton data={pageContent} />
        </Suspense>
      ) : (
        <ResultBoxes {...{ pageContent }} />
      )}
      {pageContent.customPageContent && (
        <CustomResultComponentSorter pageContent={pageContent} />
      )}
      {detailPage && (
        <DetailBox
          title={"<b>Weitere Informationen</b> zu " + pageContent.title}
          id={detailPage}
        />
      )}
      <SourcesView {...{ pageContent }} />
    </View>
  );
};

const DetailBox = ({ title, id }) => {
  const navigation = useNavigation();

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        backgroundColor: colors.ui.cardBackground,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigateToNextCMSPage(id, navigation);
        }}
        activeOpacity={0.7}
      >
        <View
          style={{
            borderRadius: 10,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View
            style={{
              borderRadius: 100,
              overflow: "hidden",
              marginRight: 12,
              aspectRatio: 1,
              height: 32,
              marginLeft: 16,
              alignItems: "center",
              backgroundColor: colors.brand.greys.gE5E5E5,
              justifyContent: "center",
            }}
          >
            <Ionicons
              name="glasses-outline"
              size={20}
              style={{ marginLeft: 2, marginTop: 1 }}
            />
          </View>
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                paddingVertical: 16,
                paddingRight: 16,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <BoxTextComponent
                  content={"<p>" + title + "</p>"}
                  style={getSharedStylesLargeContent()}
                />
              </View>
              <View>
                <Feather
                  name="chevron-right"
                  size={24}
                  style={{ marginLeft: 12 }}
                  color={colors.ui.textPrimary}
                />
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export const ResultPageUserInteractionsHeader = ({
  pageContent,
  setWarningModalVisible,
}) => {
  if (!pageContent) return null;
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 12,
          alignSelf: "stretch",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <TitleText
          style={{
            marginRight: 12,
            flex: 1,
          }}
          title={pageContent.title ?? pageContent.fieldTitle}
        />
        <View style={{ gap: 12, flexDirection: "row" }}>
          <TouchableOpacity
            style={{ marginLeft: 0 }}
            onPress={() => setWarningModalVisible((m) => !m)}
          >
            <Ionicons
              name="warning-outline"
              size={24}
              color={colors.ui.textPrimary}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
