export default {
  recently_used: "Kürzlich hinzugefügt",
  welcome: "Willkommen",
  home: "Home",
  search: "Suche",
  toolbox: "Toolbox",
  more: "Mehr",
  show_all: "Alle anzeigen",
  show_less: "Weniger anzeigen",
  my_favorites: "Meine Favoriten",
  ask_lyra: "Frag LYRA",
  back: "zurück",
  our_recommendations: "Schon gesehen?",
  explore: "LANDO kennenlernen!",
  explore_detail:
    "Verpasse keine hilfreichen Funktionen - Entdecke hier den Umfang von LANDO.",
  discover_now: "Jetzt entdecken",
  table_of_contents: "Inhaltsverzeichnis",
  calculators: "Rechner",
  welcome_back: "Willkommen zurück",
  login_now: "Jetzt einloggen und loslegen.",
  email: "E-Mail",
  password: "Passwort",
  stay_signed_in: "Angemeldet bleiben",
  sign_in: "Anmelden",
  forgot_pw: "Passwort vergessen?",
  register_now: "Jetzt registrieren?",
  register: "Registrieren",
  done: "Fertig",
  doctor: "Arzt / Ärztin",
  title: "Titel",
  non_med_prof: "Nicht-ärztliches Fachpersonal",
  student: "Studierende",
  select_account_type: "Kontotyp auswählen",
  confirm_selection: "Auswahl bestätigen",
  register_now_: "Jetzt registrieren",
  account_present: "Ich habe schon einen Account",
  log_in: "Anmelden",
  no_continue: "Nein, weiter",
  disclaimer_nurse:
    "Hiermit bestätige ich die Zugehörigkeit zu 'Nicht-ärztlichem Fachpersonal' und lade im Zuge der Validierung meines Status' innerhalb der nächsten 3 Wochen ein bestätigendes Dokument hoch. Weitere Informationen dazu bekommst Du per Mail.",
  disclaimer_doc:
    "Hiermit bestätige ich, dass ich als Arzt / Ärztin approbiert oder zur Ausübung des ärztlichen Berufes befugt bin.",
  Back: "Zurück",
  send: "E-Mail verifizieren",
  enter_login_data: "Deine Zugangsdaten: E-Mail und Passwort",
  history: "Verlauf",
  no_results: "Keine Ergebnisse",
  load_more: "Mehr laden",
  delete_all_search_results: "Alle Suchergebnisse löschen",
  delete_search_results: "Suchergebnisse löschen",
  result: "Resultat",
  content: "Inhalte",
  recommend: "LANDO empfehlen",
  profile: "Profil",
  premium_free: "Kostenlos Premium",
  upgrade_free: "Für 0,00 € upgraden",
  premium_expires_in_start: "PREMIUM läuft in",
  premium_expires_in_end: "ab",
  account_settings: "Konto & Einstellungen",
  my_account: "Mein Konto",
  my_cases: "Meine Fälle",
  kpi_slide_text: "Klinische Entscheidungen über LANDO® getroffen",
  quote_stefan:
    "welche Inhalte wünschst du dir als nächstes? Unser Medical Research Team bereitet komplexe medizinische Themen für den täglichen Gebrauch auf.",
  default_search_placeholder: "Durchsuche den gesamten Inhalt",
  search_placeholder_detail: "Durchsuche ",
  topics: "Themenbereiche",
  favorites: "Favoriten",
  cases: "Fälle",
  export: "Export",
  case: "Fall",
  pages: "Seiten",
  personal: "Persönlich",
  good_morning: "Guten Morgen",
  good_afternoon: "Guten Tag",
  good_evening: "Guten Abend",
  show_sources: "Quellen anzeigen",
  hide_sources: "Quellen ausblenden",
  show_all_content: "Alle Inhalte anzeigen",
  add_case: "Fall hinzufügen",
  recently_added: "Kürzlich hinzugefügt",
  most_used: "Meist verwendet",
  empty_favorites_placeholder:
    "Du hast noch keine Favoriten hinzugefügt. Klicke auf das Herz neben einem Inhalt, um ihn als Favorit zu speichern.",
  edit: "Bearbeiten",

  add_favorites: "Favoriten hinzufügen",
  empty_history_placeholder:
    " Du hast noch keine Seite angesehen. Alle angesehenen Seiten werden hier gespeichert.",
  go_to_content: "Zu den Inhalten",
  personal_search_placeholder: "Durchsuche deine persönlichen Inhalte",
  empty_export_placeholder:
    "Du hast noch keinen Export hinzugefügt. Füge einen Export hinzu, um diesen hier zu sehen.",
  add_wishes: "Jetzt Wünsche äußern",
  quote_leon:
    "hausinterne SOPs jederzeit und ortsunabhängig verfügbar - LANDO ist 100% KHZG förderfähig.",
  diseases: "Erkrankungen",
  emergency: "Notfall",
  interventions: "Interventionen & Prozeduren",
  therapy_algorithms: "Therapiealgorithmen",
  substances: "Wirkstoffe & Arzneimittel",
  scores: "Scores",
  checklists: "Checklisten",
  emergency_description:
    "In diesem Bereich erhältst Du kurz und prägnant die wichtigsten Informationen zum Management von akuten Notfällen.",
  fast_track_description:
    "In diesem Bereich erreichst du über den schnellsten Weg 'Fast-Track' erkrankungsspezifische Medikamente und Dosierungen.",
  diseases_description:
    "In diesem Bereich erhältst du umfassende Informationen über spezifische Krankheitsbilder, einschließlich ihrer Symptome, Diagnoseverfahren und Behandlungsoptionen.",
  interventions_procedures_description:
    "Hier werden Interventionen und Prozeduren beschrieben, einschließlich ihrer Indikationen, Kontraindikationen, Equipment, Vorbereitungen und Vorsichtsmaßnahmen, Monitoring sowie Technik und Durchführung.",
  therapy_algorithms_description:
    "Diese Kategorie bietet Therapiealgorithmen und Behandlungsleitlinien für verschiedene Krankheitsbilder oder klinische Szenarien, um eine strukturierte Herangehensweise an die Therapie zu unterstützen.",
  scores_description:
    "Hier findest du Informationen zu verschiedenen klinischen Bewertungsskalen und Scores, die zur Einschätzung von Krankheitsschwere, Prognose oder Behandlungsbedarf verwendet werden.",
  calculators_description:
    "In dieser Rubrik stehen verschiedene medizinische Rechner und Werkzeuge zur Verfügung, um Berechnungen durchzuführen, wie zum Beispiel Umrechnen von Einheiten oder Berechnung der Körperoberfläche.",
  register_email: "Mit E-Mail registrieren",
  already_account: "Du hast schon einen Account?",
  email_or_id: "E-Mail (allgemein) oder Nutzerkennung (für Enterprise-Kunden)",
  agreeTerms: {
    confirm: "Indem du fortfährst bestätigst du auch die",
    login: "dem Login",
    registration: "der Registrierung",
    terms: "Nutzungsbedingungen",
    privacy: "Datenschutzerklärung",
    of: "von LANDO®.",
    and: "und die",
  },
  reset_password: {
    title: "Passwort zurücksetzen",
    greeting: "E-Mail eingeben und Passwort zurücksetzen.",
    placeholder: "E-Mail",
    reset: "Zurücksetzen",
    success:
      "Du bekommst in den nächsten Minuten eine E-Mail mit den weiteren Schritten. (Prüfe auch deinen Spam-Ordner.)",
    failure:
      "Das Senden der E-Mail ist fehlgeschlagen. Bitte probiere es erneut. Sollte das Problem weiterhin bestehen, kontaktiere gerne",
    login: "Jetzt anmelden",
  },
  role_selection: {
    doctor: "Arzt / Ärztin",
    non_med_prof: "Nicht-ärztliches Fachpersonal",
    student: "Im Studium / Ausbildung",
    select_account_type: "Kontotyp auswählen",
    confirm_selection: "Auswahl bestätigen",
    already_have_account: "Log in",
    not_medical_professional: "Kein Mediziner",
    i_am: "Ich bin...",
  },
  agree_terms_registry: {
    confirm: "Hiermit bestätige ich, dass ich die",
    terms: "Nutzungsbedingungen",
    privacy: "Datenschutzerklärung",
    of: "von LANDO® akzeptiere.",
    and: "und die",
  },
  welcome_to: "Willkommen bei",
  add_patient: "Neuen Patienten anlegen",
  hot_features: "Häufig genutzt",
  continue_with_apple: "Mit Apple fortfahren",
  continue_with_google: "Mit Google fortfahren",
  already_know: "KENNEN SIE SCHON DIE",
  non_medical_user: {
    request_access: "Zugriff anfordern",
    no_medical_user_subheading:
      "Bitte fülle die folgenden Felder aus, um einen Zugang als nicht-medizinischer Benutzer anzufordern. Jede Registrierung wird überprüft und manuell vom LANDO-Team freigegeben. Wir informieren dich über E-Mail, sobald dein Zugang aktiviert wurde.",
    why_you_wanna_register: "Warum möchtest du dich registrieren?",
  },
  first_name: "Vorname",
  last_name: "Nachname",
  continue_in_web: "WEITER IN DER WEBVERSION",
  min_eight_chars: "Passwort muss mind. 8 Zeichen enthalten.",
  thanks_for_verify:
    "Vielen Dank für die Verifizierung. Vervollständige nun dein Profil 🚀",
  cancel_registration: "Ich möchte die Registrierung abbrechen",
  continue: "Weiter",
  what_profession_do_you_pursue: "What profession do you pursue?",
  notes_fav_or_share: "Notizen, Favoriten und Teilen",
  notes_fav_or_share_description:
    "Hier kannst du eigene Notizen hinzufügen, die Seite als Favorit markieren oder einen direkten Link mit Kollegen und Kolleginnen teilen.",
  edit_patient: "Patienten bearbeiten",
  create_patient: "Patienten anlegen",
  identifier: "Kennung",
  send_feedback_now: "Jetzt Feedback absenden",
  new_update_available: "Neues Update verfügbar!",
  update_now: "Jetzt aktualisieren",
  image_not_existing: "Das Bild ist nicht vorhanden",
  coming_soon: "Bald verfügbar",
  got_it: "Verstanden",
  note_contraindications: "Hinweis: Kontraindikation",
  contraindications_description:
    "Hier sind ausschließlich besondere Kontraindikationen für diesen Inhalt aufgeführt.Weitere Kontraindikationen des jeweils verwendeten Arzneimittels müssen anhand der Fachinformation geprüft werden.",
  lando_is_offline: "Lando ist Offline",
  lando_is_offline_description:
    "No worries! Of course, you can easily use LANDO offline. Um but making sure you always stay up to date, we ask you to connect the app to the Internet once every 10 days connect.This way you won't miss any of our updates.",
  discover_lando: "LANDO Entdecken",
  info_about_regulatory:
    "LANDO ist gemäß der Verordnung (EU) 2017/745 (MDR) ein Medizinprodukt, welches als Produkt der Klasse I klassifiziert ist.Die hier dargestellten Inhalte richten sich ausschließlich an medizinisches Fachpersonal und setzt entsprechende Fachkenntnisse für die sichere Anwendung voraus. \n\n Bitte beachten Sie, dass die Nutzung von LANDO bestimmten regulatorischen Anforderungen unterliegt.Für die Inhalte und die Einhaltung der regulatorischen Vorgaben dieses Produkts ist LANDO verantwortlich.",
  why_we_need_this_infos: "Warum wir diese Informationen benötigen",
  reason_for_more_infos:
    "Als Medizinprodukt müssen wir sicherstellen, dass unsere App ausschließlich von Ärzten sowie Angehörigen nicht- ärztlichen Fachpersonals genutzt wird. \n\nDiese Information ist notwendig, um die korrekte und sichere Verwendung von LANDO zu gewährleisten und wird selbstverständlich streng vertraulich behandelt.\n \nVielen Dank für das Verständnis und die Unterstützung bei der Aufrechterhaltung der Sicherheit und Qualität der App.",
  font_size: "Schriftgröße:",
  size_of_this_text_will_change:
    "Die Größe dieses Textes ändert sich mit deiner Eingabe.",
  enter_new_password: "Neues Passwort eingeben",
  change_password_failed: "Passwort ändern fehlschlagen",
  change_password_succesfull: "Passwort ändern erfolgreich",
  apply: "Anwenden",
  change_now: "Jetzt ändern",
  changing_infos_succesfull: "Ändern der Information erfolgreich!",
  how_do_you_like_lando: "Wie gefällt dir Lando?",
  bad: "Schlecht",
  good: "Gut",
  how_did_you_hear_from_us: "Wie hast du von uns mitbekommen?",
  attention: "Achtung!",
  maintenance_work: "Wartungsarbeiten",
  maintenance_work_description:
    "Auf dieser Seite nehmen wir gerade Wartungsarbeiten vor. Bald ist der Inhalt wieder verfügbar.",
  watch_anyway: "Trotzdem ansehen (nicht empfohlen)",
  close: "Schließen",
  in_house_content: "Hausinterne Inhalte",
  fold_in: "Einklappen",
  select_patient: "Patient auswählen",
  description: "Beschreibung",
  features: "Features",
  thank_you: "VIELEN DANK",
  for_registration: "FÜR DIE REGISTRIERUNG",
  your_registration: "deine Registrierung",
  confirm_via_your_email: "Bitte bestätige über deine E-Mail",
  check_your_spam_folder: "Tipp: Überprüfe auch deinen Spam-Ordner.",
  we_will_review_your_request:
    "Das LANDO-Team überprüft deine Anfrage. Du erhältst eine E-Mail, sobald dein Account zugelassen ist.",
  open_emails: "E-Mails öffnen",
  check_verification: "Verifizierung überprüfen",
  email_resened: "E-Mail erneut versendet.",
  resend_email: "E-Mail erneut senden",
  sending_email_failed:
    "Das Senden der E-Mail ist fehlgeschlagen. Bitte probiere es erneut. Sollte das Problem weiterhin bestehen, kontaktiere support@landohealth.com.",
  back_to_login: "Zurück zum Login",
  manage_environment: "Environment verwalten",
  legal: "Rechtliches",
  manage_notifications: "Benachrichtigungen verwalten",
  adjust_font_size: "Schriftgröße anpassen",
  help_and_support: "Hilfe & Support",
  instructions_for_use: "Gebrauchsanweisung",
  contact_us: "Kontaktiere uns",
  rate_us: "Bewerte uns",
  more_from_lando: "Mehr von LANDO",
  element_dependency_previous_selection:
    "Das Element ist abhängig von der vorherigen Auswahl.",
  please_answer_above_questions:
    "Bitte beantworte o. g. Fragen, um ein Ergebnis zu erhalten.",
  transfer_result: "Ergebnis übertragen",
  points: "Punkte",
  risk_factor: "Risikofaktor",
  value: "Wert",
  selection: "Auswahl",
  overview: "Übersicht",
  hide: "ausblenden",
  show: "anzeigen",
  seek: "Suchen",
  content_explored: "Inhalt erkundet",
  time_in_app: "Zeit in der App",
  lando_classified_medical_product:
    "LANDO ist ein klassifiziertes Medizinprodukt, das den höchsten Goldstandard an klinischer Qualität und klinischer Nutzbarkeit erfüllt.",
  just_updated: "Gerade aktualisiert",
  delete_case_confirmation: "Möchtest du diesen Fall wirklich löschen?",
  no: "Nein",
  remove: "Entfernen",
  manage_favorites: "Verwalte deine Favoriten",
  my_history: "Mein Verlauf",
  my_history_description: "Alle von dir angesehenen Seiten",
  my_patients: "Meine Patienten",
  manage_patients: "Verwalte deine Patienten",
  my_notes: "Meine Notizen",
  manage_your_notes: "Verwalte deine Notizen",
  my_account_and_settings: "Mein Account & Einstellungen",
  manage_account_and_settings:
    "Verwalte deinen Account und deine Einstellungen",
  diagnostics_and_therapy: "Diagnostik & Therapie",
  overview_diagnostics_algorithms_therapies:
    "Effizienter Überblick über Diagnostik, Algorithmen und Therapiemöglichkeiten von Erkrankungen und Symptomen",
  quick_access_emergencies: "Schnellzugriffe für den Notfall",
  list_of_favorites: "Eine Liste deiner Favoriten",
  adult: "Erwachsene",
  children: "Kinder",
  neonates: "Neonaten",
  clinical_relevant_calculators: "Sammlung klinisch relevanter Rechner",
  clinical_relevant_scores: "Sammlung klinisch relevanter Scores",
  interventions_procedures_algorithms:
    "Interventionen, Prozeduren & Algorithmen",
  interventions_procedures_diagnostics_therapy_algorithms:
    "Sammlung von Interventionen & Prozeduren sowie vereinfachte Darstellung komplexer Diagnostik oder Therapiealgorithmen",
  edit_patients: "Patienten bearbeiten",
  create_new_patient: "Neuen Patienten anlegen",
  weight: "Gewicht",
  body_size: "Körpergröße",
  save_changes: "Anderung speichern",
  write_your_note_here: "Schreibe deine Notiz hier...",
  your_feedback: "Dein Feedback...",
  gloabl_search: "Globale Suche",
  no_notes_added_yet:
    "Du hast noch keine Notizen hinzugefügt. Klicke auf den Stift auf einer Inhaltsseite, um eine Notiz hinzuzufügen.",
  current_patient: "Aktueller Patienten",
  patient_history: "Patientenhistorie",
  inactive_patient: "Inaktiver Patient",
  page_not_linked_to_active_patient:
    "Du navigierst zu einer Seite, die nicht mit dem aktiven Patienten verknüpft ist. Möchtest du den Patienten aktivieren und fortfahren?",
  not_active_and_continue: "Nicht aktivieren und fortfahren",
  active_and_continue: "Aktivieren und fortfahren",
  number_of_page: "Seitenanzahl",
  save_and_close: "Speichern und Schließen",
  save_and_continue: "Speichern und weiter",
  go_online_to_load_page: "Gehe online, um diese Seite zu laden",
  pdf_could_not_be_loaded: "PDF konnte nicht geladen werden.",
  what_can_we_improve: "Das tut uns leid, was können wir verbessern?",
  thanks_for_your_feedback: "Danke für das Feedback",
};
