import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import staticColors from "../constants/static-colors";

export default function DefaultActivityIndicator({
  size = "small",
  style,
}: {
  readonly size?: any;
  readonly style?: any;
}) {
  return (
    <ActivityIndicator
      style={style}
      theme={{
        colors: {
          ...DefaultTheme.colors,
          primary: staticColors["light"].accent,
        },
      }}
      size={size}
    />
  );
}
