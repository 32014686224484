import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging from "@react-native-firebase/messaging";
import * as ImagePicker from "expo-image-picker";
import { Platform } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { posthog } from "../../api/analytics/posthog";
import apiCall from "../../api/api-call";
import { InlineNotificationType } from "../../components/Notifications/NotificationComponent";
import { showInlineNotification } from "../../entry/Root";
import { clearTokens } from "../../screens/login-registry/LandingScreen/functions/ProviderSignIn";
import { setSelectedEnterprise } from "../enterprise/actions";
import { store } from "../store";
import {
  removeCurrentUser,
  setProfilePicture,
  setSessionToken,
} from "../user/actions";
import { reloadUserObject, setLogged } from "../user/functions";

export async function getImageFromLibrary() {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 4],
    quality: 0,
  });

  if (!result.canceled && result.assets?.[0]?.uri) {
    return result.assets[0].uri;
  } else {
    return null;
  }
}

export async function uploadProfileImage(imageURI: string) {
  try {
    // FormData erstellen
    const formData = new FormData();

    const fileType = imageURI.split(".").pop();
    formData.append("file", {
      uri: imageURI,
      type: `image/${fileType}`,
      name: `profile_picture.${fileType}`,
    } as any);

    // An unser Backend schicken
    const result = await apiCall(
      "/api/v4/push/profile-picture",
      formData,
      false, // isRetry
      false, // noEnc (wir wollen den Authorization Header mitschicken)
      "POST"
    );

    if (result?.status === 200 && result.data?.url) {
      // Speichere die neue Bild-URL im State
      await reloadUserObject();
    } else if (result?.status === 400) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Dateityp nicht erlaubt oder Dateigröße überschritten.",
      });
    } else if (result?.status === 500) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Interner Serverfehler beim Upload. Versuche es erneut.",
      });
    } else {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Etwas ist schief gelaufen. Versuche es erneut.",
      });
    }
  } catch (error) {
    showInlineNotification({
      type: InlineNotificationType.ERROR,
      text: "Etwas ist schief gelaufen. Versuche es erneut.",
    });
  }
}

export async function deleteProfileImage() {
  try {
    const result = await apiCall(
      "/api/v4/push/profile-picture",
      undefined, // kein Body
      false, // isRetry
      false, // noEnc
      "DELETE"
    );
    /**
     * SUCC_STATUS_CODE: 204
     * {
     *   "message": "Profile picture deleted"
     * }
     * FAILED_STATUS_CODE: 404
     */
    if (result?.status === 204) {
      // Frontend-Store zurücksetzen
      store.dispatch(setProfilePicture(""));
    } else if (result?.status === 404) {
      console.warn("Kein Profilbild vorhanden bzw. nicht gefunden.");
    } else {
      console.warn("Unbekannter Fehler:", result);
    }
  } catch (error) {
    console.error("Fehler beim Löschen des Profilbildes:", error);
  }
}

export async function logout(currentUser, navigation) {
  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "user_logged_out",
    "ProfileScreen",
    "UserLoggedOut"
  );
  store.dispatch(setSelectedEnterprise(null));

  if (Platform.OS !== "web") {
    await messaging().unsubscribeFromTopic(currentUser?.user_id ?? "");
    await messaging().unsubscribeFromTopic("updates");
    await messaging().unsubscribeFromTopic("motivational");
  }

  posthog?.reset();
  navigation?.reset({
    index: 0,
    routes: [{ name: "LandingScreen" }],
  });

  store.dispatch(removeCurrentUser());
  store.dispatch(setSessionToken(undefined));

  await AsyncStorage.setItem("user", "null");
  await AsyncStorage.setItem("selectedEnterprise", "null");
  await setLogged(false, "", "", "");

  await clearTokens();
}
