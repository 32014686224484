import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Image, Platform, View } from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { useDispatch } from "react-redux";
import launch from "../../assets/images/launch_short_reverse.gif";
import logo_white from "../../assets/images/logo_weiß.png";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { getIsOffline } from "../components/search-tab/SearchResults";
import Colors from "../constants/static-colors";
import {
  setNavigationIntent,
  setShowNavbar,
} from "../functions/navigation/actions";
import { store } from "../functions/store";
import {
  getOfflineUser,
  getUserDataForLoggedIn,
  isLogged,
} from "../functions/user/functions";
import { User } from "../functions/user/types";

export const APP_TARGET = "BottomTabNavigator";

export default function LaunchScreen({ route }) {
  useEffect(() => {
    if (route.params?.pageId) {
      dispatch(setNavigationIntent(route.params.pageId));
    }
  }, [route]);

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowNavbar(false));

    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve("timeout");
      }, 1200);
    });

    const asyncOperations = async () => {
      if (!(await isLogged())) {
        return "LandingScreen";
      }

      if (!(await syncUserData())) {
        return "LandingScreen";
      }

      AnalyticsHandler.getInstance().logLogin();
      const updatedUser = store.getState().userReducer.user as User;

      if (Object.keys(updatedUser).length === 0) return "LandingScreen";
      if (
        updatedUser.status === "active" &&
        (updatedUser.firstname || updatedUser.user_type === "enterprise-lite")
      ) {
        return APP_TARGET;
      } else if (updatedUser.status === "pending") {
        return "WaitingForRegistrationScreen";
      } else if (!updatedUser.firstname) {
        return "NewRegistryScreen";
      } else {
        return "LandingScreen";
      }
    };

    Promise.all([timeoutPromise, asyncOperations()]).then((results) => {
      const navigationTarget = results.find(
        (result) => result !== "timeout"
      ) as string | undefined;
      if (navigationTarget) {
        navigate(navigationTarget);
      } else {
        navigate("LandingScreen");
      }
    });
  }, []);

  const navigate = (target: any) => {
    navigation.reset({ index: 0, routes: [{ name: target }] });
  };

  async function syncUserData() {
    if (await getIsOffline()) {
      await getOfflineUser();
      return true;
    }

    const success = await getUserDataForLoggedIn();

    return success;
  }

  const renderContent = () => {
    if (Platform.OS !== "android") {
      return <Image source={launch} style={getImageStyle()} />;
    } else {
      return (
        <View style={getCenteredViewStyle()}>
          <ActivityIndicator
            style={{ position: "absolute", bottom: 120 }}
            theme={{
              colors: {
                ...DefaultTheme.colors,
                primary: Colors["light"].accent,
              },
            }}
            size={36}
          />
          <Image source={logo_white} style={getImageStyle()} />
        </View>
      );
    }
  };

  const getImageStyle: any = () => ({
    width: "100%",
    height: "100%",
    resizeMode: "contain",
    ...Platform.select({
      web: { height: "80vh", marginBottom: 50, resizeMode: "contain" },
    }),
  });

  const getCenteredViewStyle: any = () => ({
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
      }}
    >
      {renderContent()}
      <Image
        source={require("../../assets/images/ce.png")}
        style={{
          position: "absolute",
          bottom: 50,
          width: 50,
          height: 50,
          resizeMode: "contain",
          opacity: 0.2,
          tintColor: "white",
        }}
      />
    </View>
  );
}
