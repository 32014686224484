import { useEffect, useState } from "react";
import { LayoutAnimation, Platform, ScrollView } from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import {
  clearAmount,
  setResultingCalculation,
} from "../functions/calculator/actions";
import { configureExpansionAnimation } from "../functions/configure-animations";
import {
  setCurrentHighlightSection,
  setCurrentResultPage,
  setIsExporting,
  setResultPageCaseContent,
  setShowTitle,
  shouldShowSources,
} from "../functions/current-result/actions";
import {
  getResultPagesCount,
  increaseResultPagesCount,
} from "../functions/current-result/helpers";
import { selectIsDemoMode } from "../functions/navigation/actions";
import { selectCurrentUser } from "../functions/user/actions";
import {
  addToUserHistory,
  showRatingIfApplicable,
} from "../functions/user/functions";
import ExpandableFABMenu from "../screens/ResultPageScreen/components/ExpandableFABMenu";
import { isResultPageWithSkeleton } from "../screens/ResultPageScreen/ResultPageScreen";
import ControlledScrollView from "./ControlledScrollView";
import ResultPageHeader from "./header/ResultPageHeaderComponent";
import RatingModal from "./modals/RatingModal";
import WarningModal from "./modals/WarningModal";
import WorkInProgressModal from "./modals/WorkInProgressModal";
import PatientDataComponentBox from "./boxes/box-types/patient-data-box/PatientDataComponentBoxSorter";

interface HistoryUseEffectObj {
  customFunction: (() => void) | null;
  addToHistory?: boolean;
  logUserScreenInteraction: {
    screen: string;
    action: string;
    values: string;
    id: string;
  };
}

interface ResultPagesWrapperProps {
  readonly navigation: any;
  readonly children: React.ReactNode;
  readonly patientData: any;
  readonly pageContent: any;
  readonly warningModalVisible;
  readonly setWarningModalVisible: any;
  readonly historyUseEffectObj: HistoryUseEffectObj | null;
  readonly showFloatingActionButton?: boolean;
  readonly scrollViewRef?: any;
  readonly isModal?: boolean;
}

export function ResultPagesWrapper({
  navigation,
  children,
  patientData,
  pageContent,
  warningModalVisible,
  setWarningModalVisible,
  historyUseEffectObj,
  showFloatingActionButton = true,
  scrollViewRef,
  isModal = false,
}: ResultPagesWrapperProps) {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [didShowRating, setDidShowRating] = useState(false);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [workInProgressModalVisible, setWorkInProgressModalVisible] =
    useState(false);
  const isDemoMode = useSelector(selectIsDemoMode);

  const toggleWarningModal = () => {
    setWarningModalVisible((m) => !m);
  };

  async function checkIfRating() {
    if (didShowRating) return true;

    if (await showRatingIfApplicable()) {
      setRatingModalVisible(true);
      setDidShowRating(true);
      return false;
    }

    return true;
  }

  // Gemeinsame Methode zum Verlassen der Seite
  function storeActionsOnLeave() {
    dispatch(setShowTitle(false));
    batch(() => {
      if (!patientData) dispatch(clearAmount());
      dispatch(shouldShowSources(false));
      dispatch(setResultingCalculation(null));

      dispatch(setCurrentHighlightSection(null));
      dispatch(setResultPageCaseContent(null));
      dispatch(setIsExporting(false));
    });
  }

  // Animation entfernen, um wiederholte Logik zu sparen
  function removeAnimation() {
    LayoutAnimation.configureNext({
      duration: 120,
      create: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
  }

  useEffect(() => {
    dispatch(setShowTitle(false)); // Einheitliche Logik für ShowTitle
    increaseResultPagesCount();

    const shouldAddToHistory = historyUseEffectObj?.addToHistory ?? true;
    if (shouldAddToHistory) {
      addToUserHistory(historyUseEffectObj?.logUserScreenInteraction?.id);
    }

    if (historyUseEffectObj?.customFunction)
      historyUseEffectObj?.customFunction();

    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        historyUseEffectObj?.logUserScreenInteraction?.action,
        historyUseEffectObj?.logUserScreenInteraction?.screen,
        historyUseEffectObj?.logUserScreenInteraction?.values,
        historyUseEffectObj?.logUserScreenInteraction?.id
      );
    });

    const unsubscribeBeforeRemove = navigation.addListener(
      "beforeRemove",
      async (e) => {
        e.preventDefault();
        removeAnimation();

        const openedPages = getResultPagesCount();

        if (
          currentUser.has_rated ||
          openedPages % 5 !== 0 ||
          (await checkIfRating())
        ) {
          navigation.dispatch(e.data.action);
          storeActionsOnLeaveAsync();
        }
      }
    );

    return () => {
      unsubscribe();
      unsubscribeBeforeRemove();
    };
  }, [didShowRating]);

  async function storeActionsOnLeaveAsync() {
    storeActionsOnLeave();
  }

  useEffect(() => {
    if (!pageContent) return;
    dispatch(setCurrentResultPage(pageContent));
    if (isDemoMode) return;
    if (pageContent.internal_rating === 0) {
      setWorkInProgressModalVisible(true);
    }
  }, [pageContent, currentUser]);

  if (pageContent === undefined) return null;

  return (
    <>
      <RatingModal
        open={ratingModalVisible}
        onClose={() => {
          setRatingModalVisible(false);
          navigation.goBack();
        }}
      />
      <WarningModal open={warningModalVisible} onClose={toggleWarningModal} />

      <WorkInProgressModal
        setClosed={setWorkInProgressModalVisible}
        open={workInProgressModalVisible}
        onClose={() => {
          navigation.goBack();
          setWorkInProgressModalVisible(false);
        }}
      />

      <ScrollView
        ref={scrollViewRef}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        style={{
          width: "100%",
          flex: 1,
          ...Platform.select({ ios: { overflow: "visible" } }),
        }}
        contentContainerStyle={{
          width: "100%",

          flexGrow: 1,
          paddingBottom: showFloatingActionButton ? 130 : 20,
        }}
        showsVerticalScrollIndicator={false}
      >
        <ResultPageHeader isModal={isModal} />
        {children}
      </ScrollView>
      {showFloatingActionButton && (
        <ExpandableFABMenu
          pageContent={pageContent}
          showCollapse={isResultPageWithSkeleton}
          collapseAction={() => {
            configureExpansionAnimation();
            //setRemoteClose((r) => !r);
            dispatch(shouldShowSources(false));
            //scrollViewRef.current?.scrollTo({ y: 0, animated: true });
          }}
        />
      )}
    </>
  );
}
