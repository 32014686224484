import getPageContent from "../../api/content/get-page-content";
import { clearAmount, selectPatientData } from "../calculator/actions";

import { store } from "../store";

export function navigateToNextPageByCMSId(
  navigation: any,
  id: string,
  title: string,
  modalPresentation?: boolean
) {
  navigateToNextCMSPage(id, navigation, modalPresentation);
}

export async function navigateToNextCMSPage(
  id: string,
  navigation: any,
  modalPresentation = false,
  additionalProps: any = {}
) {
  const state = store.getState();
  const patientData = selectPatientData(state);

  if (!additionalProps?.additionalCaseData && !patientData) {
    store.dispatch(clearAmount());
  }

  const pageContent = getPageContent(id);

  if (!pageContent) return;

  const screenShotUri = "";
  // if (modalPresentation && Platform.OS === "web") {
  //   screenShotUri = await captureScreen({
  //     format: "jpg",
  //     quality: 0.0,
  //   });
  // }

  if (pageContent.type === "scorePage") {
    navigation.push("ScoreScreen", {
      pageContent: pageContent,
      ...additionalProps,
      modal: modalPresentation,
      screenShotUri,
    });
  } else if (pageContent.type === "nodePage") {
    navigation.push("CMSNodePageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
  } else if (pageContent.type === "checklistPageType") {
    navigation.push("ChecklistScreen", {
      pageContent,
      modal: modalPresentation,
      ...additionalProps,
    });
  } else if (modalPresentation) {
    navigation.push("InfoResultPageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
  } else
    navigation.push("ResultPageScreen", {
      pageContent: pageContent,
      ...additionalProps,
    });
}

export function navigateFromSearch(
  item: any,
  navigation: any,
  assistantParams?: any
) {
  navigateToNextCMSPage(item._id, navigation, false, assistantParams);
}
