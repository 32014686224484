import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native";
import { colors } from "../constants/static-colors";

export default function CheckBox({ isSelected, setSelected, style = {} }) {
  return (
    <TouchableOpacity
      disabled={!setSelected}
      onPress={() => setSelected((s) => !s)}
      style={{
        borderRadius: 6,
        aspectRatio: 1,
        width: 20,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: !isSelected ? colors.ui.textPrimary : colors.ui.primary,
        backgroundColor: !isSelected
          ? colors.ui.transparent
          : colors.ui.primary,
        ...style,
      }}
    >
      {isSelected && (
        <Ionicons name="checkmark-sharp" size={17} color={"white"} />
      )}
    </TouchableOpacity>
  );
}
