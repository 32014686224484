import { motion } from "framer-motion";

export default function AnimatedWrapper({ children, style }) {
  return children;
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        layout: {
          duration: 0.15, // Customize the duration of the layout animation
        },
        opacity: {
          duration: 0.3,
        },
      }}
      style={style}
      layout
    >
      {children}
    </motion.div>
  );
}
