import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { Sizes } from "../../constants/static-sizes";
import getFontStyle from "../../functions/getFontSize";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";

const SearchCard = ({ item }) => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={styles.largeCard}
      onPress={() => {
        if (item.isInternalStatic) {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "search_section_card_clicked",
            "SearchSectionCard",
            "UserClicked",
            {
              campaignTrackingId: "static",
              tileTrackingId: item.resultpageId,
            }
          );
        } else {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "search_section_card_clicked",
            "SearchSectionCard",
            "UserClicked",
            {
              campaignTrackingId: item.campaignTrackingId,
              tileTrackingId: item.tileTrackingId,
            }
          );
        }

        navigateToNextCMSPage(item.resultpageId, navigation);
      }}
    >
      {/* Logo as a blurred image positioned in the top-left */}

      <View style={styles.textContainer}>
        <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
          <View style={{ gap: 8, flex: 1 }}>
            <Text
              android_hyphenationFrequency="full"
              style={styles.cardText}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {item?.title || "Beispielüberschrift: Banneranzeige"}
            </Text>
          </View>
          <Feather
            name="chevron-right"
            size={20}
            color="black"
            style={styles.icon}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

// Styles for the components
const styles = StyleSheet.create({
  largeCard: {
    borderRadius: 12,
    shadowColor: "#000",
    shadowOffset: {
      width: 2,
      height: 2,
    },
    elevation: 2,
    shadowOpacity: 0.05,
    shadowRadius: 2,
    marginBottom: 8,
    width: "100%",
    //height: 50, // Adjust height as needed
    padding: 12, // Padding inside the card for content
    backgroundColor: "white",
    height: 60,
  },

  textContainer: {
    flex: 1,
  },
  cardText: {
    fontSize: Sizes.boxText,
    color: "black",
    ...getFontStyle(500),
  },
  icon: {
    marginLeft: 12,
  },
});

export default SearchCard;
