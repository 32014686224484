// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Carousel.css */

/* Wrapper to allow each slide to be centered and partially visible */
.carousel-slide-wrapper {
  width: 100%; /* Ensures full width */
  display: flex;
  justify-content: center; /* Center each slide */
  padding: 0 10px; /* Controls how much of the inactive slides are visible */
}

/* Slide styling for inactive and active slides */
.carousel-slide {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.4;
  transform: scale(0.8);
  max-width: 1000px; /* Limit max width for the active slide */
  width: 100%; /* Ensures the slide itself is responsive */
}

/* Active slide styles */
.slick-center .carousel-slide {
  opacity: 1 !important;
  transform: scale(1) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/CarouselComponent/Carousel.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB,qEAAqE;AACrE;EACE,WAAW,EAAE,uBAAuB;EACpC,aAAa;EACb,uBAAuB,EAAE,sBAAsB;EAC/C,eAAe,EAAE,yDAAyD;AAC5E;;AAEA,iDAAiD;AACjD;EACE,kDAAkD;EAClD,YAAY;EACZ,qBAAqB;EACrB,iBAAiB,EAAE,yCAAyC;EAC5D,WAAW,EAAE,2CAA2C;AAC1D;;AAEA,wBAAwB;AACxB;EACE,qBAAqB;EACrB,8BAA8B;AAChC","sourcesContent":["/* Carousel.css */\n\n/* Wrapper to allow each slide to be centered and partially visible */\n.carousel-slide-wrapper {\n  width: 100%; /* Ensures full width */\n  display: flex;\n  justify-content: center; /* Center each slide */\n  padding: 0 10px; /* Controls how much of the inactive slides are visible */\n}\n\n/* Slide styling for inactive and active slides */\n.carousel-slide {\n  transition: transform 0.5s ease, opacity 0.5s ease;\n  opacity: 0.4;\n  transform: scale(0.8);\n  max-width: 1000px; /* Limit max width for the active slide */\n  width: 100%; /* Ensures the slide itself is responsive */\n}\n\n/* Active slide styles */\n.slick-center .carousel-slide {\n  opacity: 1 !important;\n  transform: scale(1) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
