import { Image, Text, View } from "react-native";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideFour() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <Text
        style={{
          fontSize: isLargerDevice() ? 54 : 36,
          fontWeight: "500",
          marginBottom: 32,
        }}
      >
        Freue dich auf maßgeschneiderte Tools. 🩺
      </Text>

      <View
        style={{
          flexDirection: "row",
          gap: 12,
          marginLeft: 16,
        }}
      >
        <Text
          style={{
            fontSize: isLargerDevice() ? 32 : 24,
          }}
        >
          •
        </Text>
        <Text
          style={{
            fontSize: isLargerDevice() ? 32 : 24,
          }}
        >
          unverzichtbare Scores
        </Text>
      </View>
      <View
        style={{
          flexDirection: "row",
          gap: 12,
          marginLeft: 16,
          marginTop: 12,
        }}
      >
        <Text
          style={{
            fontSize: isLargerDevice() ? 32 : 24,
          }}
        >
          •
        </Text>
        <Text
          style={{
            fontSize: isLargerDevice() ? 32 : 24,
          }}
        >
          medikamentenspezifische Dosierungsrechner
        </Text>
      </View>
      <Image
        source={
          isLargerDevice()
            ? require("../../../../assets/images/slide_four_large.png")
            : require("../../../../assets/images/tut_scores_rechner.png")
        }
        style={{
          width: "100%",
          height: isLargerDevice() ? "50%" : 200,
          resizeMode: "contain",
          marginTop: 48,
          alignSelf: "flex-end",
          marginRight: -16,
        }}
      />
    </View>
  );
}
