import { Text, View } from "react-native";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideOne() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        padding: 16,
        alignItems: "flex-start",
        width: "100%",
        height: "100%",
      }}
    >
      <Text
        style={{
          fontSize: isLargerDevice() ? 73 : 54,
          ...getFontStyle(500),
        }}
      >
        Dein neuer zuverlässiger Begleiter in der Klinik.
      </Text>
    </View>
  );
}
