import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { ActivityIndicator, Text, View } from "react-native";
import { useDispatch } from "react-redux";
import loadAllData from "../../api/content/load-all/load-all-content";
import { setIsDemoMode } from "../../functions/navigation/actions";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";

const VAR = "c7386d99-f4a3-4056-bdfb-f8ad5942be60";

export default function ResultPageDemo({ route }) {
  const pageId = route.params?.pageId;

  const authId = route.params?.authId;

  const navigation = useNavigation();
  const dispatch = useDispatch();

  async function load() {
    if (!pageId || authId !== VAR) return;
    await loadAllData();
    dispatch(setIsDemoMode(true));
    navigateToNextCMSPage(pageId, navigation);
  }

  useEffect(() => {
    load();
  }, []);

  if (authId !== VAR) {
    return (
      <View
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Invalid Auth ID</Text>
      </View>
    );
  }

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ActivityIndicator size={"large"}></ActivityIndicator>
    </View>
  );
}
