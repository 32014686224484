import { LinearGradient } from "expo-linear-gradient";
import staticColors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";

export default function DefaultGradientBackground({ style = {} }) {
  const colorScheme = getColorScheme();

  return (
    <LinearGradient
      start={{ x: 0.0, y: 1.0 }}
      end={{ x: 0.4, y: 0.0 }}
      colors={[
        staticColors[colorScheme].accentLight,
        staticColors[colorScheme].accent,
      ]}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -10,
        ...style,
      }}
    />
  );
}

export function CustomGradientBackground({ style = {} }) {
  return (
    <LinearGradient
      start={{ x: 0.0, y: 1 }}
      end={{ x: 1, y: 0.0 }}
      colors={["#702C08", "#D68E61"]}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -10,
        ...style,
      }}
    />
  );
}
