import { Feather } from "@expo/vector-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";

import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations from "../../functions/configure-animations";
import { selectAllContent } from "../../functions/data/actions";

import I18n from "../../../locales/i18n";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { selectPatientData } from "../../functions/calculator/actions";
import { setScoreData } from "../../functions/current-result/actions";
import getFontStyle from "../../functions/getFontSize";
import handleOneTimeAction from "../../functions/one-time-action-handler";
import { addToPagePatientUpdate } from "../../functions/user/functions";
import { isWebAndNotMobile } from "../../hooks/isLargerDevice";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";
import ScoreElements from "./components/ScoreElements";
import ScoreInterpretation from "./components/ScoreInterpretation";
import ScoreOverview from "./components/ScoreOverview";

export default function ScoreScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const dispatch = useDispatch();
  const insets = useSafeAreaInsets();
  const setInterpretationContent = route.params?.setInterpretationContent;
  const interpretationContent = route.params?.interpretationContent;

  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [interpretationItem, setInterpretationItem] = useState(null);

  const [score, setScore] = useState(interpretationContent?.score ?? 0);
  const [answers, setAnswers] = useState(interpretationContent?.answers ?? []);

  // patient data logic
  const patientData = useSelector(selectPatientData);

  const [activePatientPage, setActivePatientPage] = useState<any>(null);

  useEffect(() => {
    const currentPage = patientData?.data?.find(
      (page) => page.result_page_id === pageContent._id
    );
    setActivePatientPage(currentPage);

    setAnswers(currentPage?.data?.[0]?.answers ?? answers);
    setScore(currentPage?.data?.[0]?.score ?? score);
  }, [pageContent._id, patientData]);

  useEffect(() => {
    if (activePatientPage) {
      if (
        activePatientPage.data?.[0]?.answers !== answers ||
        activePatientPage.data?.[0]?.score !== score
      ) {
        addToPagePatientUpdate(patientData, pageContent._id, [
          { answers, score },
        ]);
      }
    }
  }, [answers, score]);

  //sync with redux

  useEffect(() => {
    dispatch(setScoreData({ score, answers }));
    return () => {
      dispatch(setScoreData(null));
    };
  }, [answers, score]);

  // Some logic to check if this is a “euroscore”
  const euroscoreId =
    useSelector(selectAllContent)?.medicalCategories?.content?.euroscoreId;
  const isEuroscore = pageContent._id === euroscoreId;

  const [overviewExpanded, setOverviewExpanded] = useState(false);

  const onlyScoreElements = pageContent?.scoreElements?.every(
    (elem) =>
      elem.typeId === "scoreElement" || elem.typeId === "scoreElementMultiple"
  );

  // For pinning logic:
  const scrollViewRef = useRef(null);
  const [showPinnedInterpretation, setShowPinnedInterpretation] =
    useState(false);
  const [interpretationLayout, setInterpretationLayout] = useState({
    y: 0,
    height: 0,
  });
  const windowHeight = Dimensions.get("window").height;

  /**
   * Store the position/height of the ScoreInterpretation container
   */
  const handleInterpretationLayout = useCallback((event) => {
    const { y, height } = event.nativeEvent.layout;
    setInterpretationLayout({ y, height });
  }, []);

  /**
   * Called on scroll → checks if ScoreInterpretation is out of view
   */
  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const offsetY = event.nativeEvent.contentOffset.y;
      const { y, height } = interpretationLayout;

      // "bottom" in scroll view coordinates:
      const interpretationBottom = y + height;
      // If bottom is offscreen (beyond visible area):
      if (
        interpretationBottom - offsetY >
        windowHeight * (isWebAndNotMobile() ? 1 : 0.8)
      ) {
        configureAnimations();
        setShowPinnedInterpretation(true);
      } else {
        setShowPinnedInterpretation(false);
      }
    },
    [interpretationLayout, windowHeight]
  );

  useEffect(() => {
    async function checkFirstTimeOnboarding() {
      if (Platform.OS === "web") return;
      if (await handleOneTimeAction("scores_onboarding")) {
        setTimeout(() => {
          navigation.navigate("OnboardingScreen", { pageKey: "scores" });
        }, 750);
      }
    }

    checkFirstTimeOnboarding();
  }, []);

  return (
    <>
      <ResultPagesWrapper
        isModal={route.params?.modal}
        showFloatingActionButton={false}
        navigation={navigation}
        pageContent={pageContent}
        warningModalVisible={warningModalVisible}
        setWarningModalVisible={setWarningModalVisible}
        historyUseEffectObj={{
          customFunction: null,
          logUserScreenInteraction: {
            screen: "ScoreScreen",
            action: "score_screen_opened",
            values: pageContent?.title,
            id: pageContent?._id,
          },
        }}
        // Forward scroll events to `handleScroll`:
        onScroll={handleScroll}
        scrollEventThrottle={16}
        scrollViewRef={scrollViewRef}
      >
        <View style={{ padding: Sizes.defaultContainerPadding }}>
          {/* Header (if needed) */}
          <ResultPageUserInteractionsHeader
            pageContent={pageContent}
            setWarningModalVisible={setWarningModalVisible}
          />

          {/* Toggle for "Übersicht" if you have only score elements */}
          {onlyScoreElements && (
            <View style={{ marginTop: 12, marginBottom: 12 }}>
              <TouchableOpacity
                style={styles.overviewToggle}
                onPress={() => {
                  configureAnimations();
                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "overview_expanded",
                    "ScoreScreen",
                    overviewExpanded ? "collapsed" : "expanded",
                    pageContent._id
                  );

                  setOverviewExpanded(!overviewExpanded);
                }}
              >
                <Text
                  style={{
                    ...getFontStyle(400),
                    fontSize: Sizes.boxText,
                  }}
                >
                  {I18n.t("overview")}{" "}
                  {overviewExpanded ? I18n.t("hide") : I18n.t("show")}
                </Text>
                <Feather
                  name={overviewExpanded ? "chevron-up" : "chevron-down"}
                  size={20}
                  color={colors.ui.textPrimary}
                  style={{ marginLeft: 8 }}
                />
              </TouchableOpacity>
            </View>
          )}

          {overviewExpanded && <ScoreOverview pageContent={pageContent} />}

          {/* Additional info (Boxes) */}
          <View>
            <ResultBoxes pageContent={{ boxes: pageContent.boxes }} />
          </View>

          {/* Score input elements */}
          <ScoreElements
            data={pageContent}
            isEuroscore={isEuroscore}
            answers={answers}
            setAnswers={setAnswers}
            setScore={setScore}
          />

          {/* Inline ScoreInterpretation */}
          {((pageContent.scoreInterpretationElements &&
            pageContent.scoreInterpretationElements.length > 0) ||
            isEuroscore) && (
            <View
              style={{ marginTop: 12 }}
              onLayout={handleInterpretationLayout}
            >
              <ScoreInterpretation
                score={score}
                answers={answers}
                data={pageContent}
                isEuroscore={isEuroscore}
                setInterpretationContent={setInterpretationContent}
                item={interpretationItem}
                setItem={setInterpretationItem}
              />
            </View>
          )}

          {/* Sources, references, etc. */}
          <View style={{ alignSelf: "stretch" }}>
            <SourcesView pageContent={pageContent} />
          </View>
        </View>
      </ResultPagesWrapper>

      {/* Pinned ScoreInterpretation if inline is out of view */}
      {showPinnedInterpretation && (
        <View
          style={[styles.pinnedContainer, { paddingBottom: insets.bottom }]}
        >
          <ScoreInterpretation
            data={pageContent}
            score={score}
            answers={answers}
            isEuroscore={isEuroscore}
            setInterpretationContent={setInterpretationContent}
            item={interpretationItem}
            setItem={setInterpretationItem}
            // You can force a "collapsed" or "expanded" state if needed:
            collapsed={false}
          />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  overviewToggle: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    paddingHorizontal: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2.62,
    elevation: 2,
    borderRadius: 20,
    backgroundColor: colors.ui.inputBackground,
    alignSelf: "flex-start",
  },
  pinnedContainer: {
    position: "absolute",
    left: Sizes.defaultContainerPadding,
    right: Sizes.defaultContainerPadding,
    bottom: 0,
    borderTopWidth: 1,
    borderTopColor: "#ccc",
    backgroundColor: "#fff",
  },
});
