import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import i18n from "../../../../../locales/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import SectionHeaderText from "../../../../components/SectionHeaderText";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import getColorScheme from "../../../../hooks/useColorScheme";
import { useMemo } from "react";

export const HotFeaturesComponent = () => {
  const navigation = useNavigation();

  const HotFeatureItem = ({ icon, text, action }: { icon; text; action? }) => {
    const colorScheme = getColorScheme();

    return (
      <TouchableOpacity
        onPress={() => {
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "hot_feature_selected",
            "HotFeaturesComponent",
            "UserPressedHotFeature",
            { feature: text }
          );
          if (action) action();
        }}
        style={{
          gap: 8,
          marginTop: 12,
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: 16,
          paddingVertical: 12,
          borderRadius: 12,
          backgroundColor: Colors[colorScheme].foreground,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: "#ddddddcc",
            padding: 10,
          }}
        >
          <Feather name={icon} size={20} color="black" />
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText + 1,
            fontWeight: "500",
            color: Colors[colorScheme].text,
          }}
        >
          {text}
        </Text>
      </TouchableOpacity>
    );
  };

  return useMemo(
    () => (
      <View>
        <SectionHeaderText>{i18n.t("hot_features")}</SectionHeaderText>
        {/* <View> */}
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 20,
          }}
        >
          <HotFeatureItem
            icon={"users"}
            text={"Patienten"}
            action={() => {
              navigation.navigate("PatientsScreen");
            }}
          />
          <HotFeatureItem
            icon={"heart"}
            text={i18n.t("favorites")}
            action={() => navigation.navigate("FavoritesScreen")}
          />
          <HotFeatureItem
            icon="rotate-cw"
            text={i18n.t("history")}
            action={() => navigation.navigate("HistoryScreen")}
          />
        </View>
      </View>
    ),
    []
  );
};
