/* eslint-disable react/no-unescaped-entities */
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { TippsOverviewPage, TippsPage } from "../api/content/load-all/types";
import ImageWrapper from "../components/ImageWrapper";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { selectAllContent } from "../functions/data/actions";
import getFontStyle from "../functions/getFontSize";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../functions/user-interaction-handler";
import { isWebAndNotMobile } from "../hooks/isLargerDevice";

export default function TippsScreen({ navigation }) {
  const data = useSelector(selectAllContent)?.tippsData?.content;

  const [clickedItems, setClickedItems] = useState<string[]>([]);

  useLayoutEffect(() => {
    (async () => {
      const data = await getValueFromInteractions("clickedItems");
      if (data) setClickedItems(data.split("$$"));
    })();
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "tips_screen_opened",
        "TipsScreen",
        "UserOpened"
      );
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const _clickedItems = clickedItems.slice();
    if (!_clickedItems.includes("TIPPS-SCREEN"))
      _clickedItems.push("TIPPS-SCREEN");
    addValueToInteractions("clickedItems", _clickedItems.join("$$"));
  }, [clickedItems]);

  if (data === undefined) return null;
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: colors.ui.background,
        alignItems: "center",
      }}
    >
      {isWebAndNotMobile() && (
        <Image
          source={require("../../assets/images/OnlyYellow.png")}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: -10,
            resizeMode: "cover",
          }}
        />
      )}
      <View style={{ flex: 1, width: "100%" }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: "center",
            alignSelf: "center",
            maxWidth: Sizes.containerWidth,
          }}
          style={{
            alignSelf: "stretch",
          }}
        >
          <View
            style={{
              flex: 1,
              width: "100%",
            }}
          >
            <FeaturedImageComponent data={data.firstComponent} />
            <ListComponent
              setClickedItems={setClickedItems}
              data={data}
              clickedItems={clickedItems}
            />
            {/* <FeedbackContainer /> */}
          </View>
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

const ListComponent = ({
  data,
  clickedItems,
  setClickedItems,
}: {
  data: TippsOverviewPage;
  clickedItems: any[];
  setClickedItems: any;
}) => {
  return (
    <View style={{}}>
      <View
        style={{
          backgroundColor: colors.ui.background,
        }}
      >
        {data.listItems
          .filter((tip) => tip)
          .map((item: TippsPage, index: number) => {
            return (
              <ListItem
                data={item}
                key={item.pageTitle}
                setClickedItems={setClickedItems}
                clickedItems={clickedItems}
                isLast={index === data.listItems.length - 1}
              />
            );
          })}
      </View>
    </View>
  );
};

const ListItem = ({ isLast, data, clickedItems, setClickedItems }) => {
  const navigation = useNavigation();

  const title = removeTagsFromString(data?.pageTitle);

  return (
    <TouchableOpacity
      style={{ flex: 1, width: "100%" }}
      onPress={() => {
        navigation.navigate("TippsDetailScreen", { tipContent: data });
        if (!clickedItems.includes(title))
          setClickedItems((items: string) => [...items, title]);
      }}
    >
      <View
        style={{
          flexDirection: "row",
          padding: 12,
          width: "100%",
          borderBottomWidth: isLast ? 0 : 1,
          alignItems: "center",
          flex: 1,
          borderBottomColor: colors.brand.greys.gE5E5E5,
        }}
      >
        <Image
          source={{
            uri: data?.image?.file_ref ?? "",
          }}
          style={{
            width: 100,
            aspectRatio: 2 / 3,
            marginRight: 16,
            borderRadius: 4,
            resizeMode: "contain",
          }}
        />
        <View style={{ flex: 1 }}>
          <Text
            style={{
              ...getFontStyle(700),
              color: colors.ui.textPrimary,
              fontSize: 16,
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              flex: 1,
              color: colors.ui.textPrimary,
              marginTop: 4,
              fontSize: 13,
            }}
          >
            {data.pageSubtitle ?? ""}
          </Text>
        </View>
        <Ionicons
          name="chevron-forward-outline"
          size={18}
          style={{ marginLeft: 16 }}
          color={colors.ui.textPrimary}
        />
      </View>
      {/* <BadgeComponent text={"1"} condition={clickedItems.includes(title)} /> */}
    </TouchableOpacity>
  );
};

const FeaturedImageComponent = ({ data }) => {
  return (
    <View
      style={{
        alignSelf: "stretch",
        backgroundColor: colors.ui.background,
      }}
    >
      <ImageWrapper
        source={{
          uri: data[0].listItems?.[0]?.image?.file_ref,
        }}
        style={{
          width: "100%",
          aspectRatio: 3 / 2,
          backgroundColor: "transparent",
        }}
      />
    </View>
  );
};

export function removeTagsFromString(input) {
  if (!input) return "";
  return input.replace(/<[^>]*>/g, "");
}
