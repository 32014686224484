import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Text, TextInput, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";

import I18n from "../../locales/i18n";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { WeightWarningLabel } from "../components/header/header-components/PatientDataHeaderComponent";
import TitleText from "../components/result/TitleText";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import {
  clearAmount,
  fetchAndUpdateAmountsAndUnits,
  selectPatientData,
  setPatientId,
} from "../functions/calculator/actions";
import { getRandom16DigitNumber } from "../functions/calculator/utilites";
import configureAnimations from "../functions/configure-animations";
import getFontStyle from "../functions/getFontSize";
import { store } from "../functions/store";
import { addPatient, updatePatient } from "../functions/user/functions";

export default function AddPatientScreen() {
  const patientData = useSelector(selectPatientData);
  const [loading, setLoading] = useState(false);

  const [patient, setPatient] = useState<any>({
    name: patientData?.name ?? "",
    weight: patientData?.weight?.toString() ?? "",
    id: getRandom16DigitNumber(),
    height: patientData?.height?.toString() ?? "",
  });

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "screen_opened",
      "AddPatientScreen",
      "ScreenOpened"
    );
  }, []);

  useEffect(() => {
    if (patientData) {
      setPatient({
        name: patientData.name ?? "",
        weight: patientData.weight?.toString() ?? "",
        height: patientData.height?.toString() ?? "",
        id: patientData.id,
      });
    } else {
      setPatient({
        name: "",
        weight: "",
        id: getRandom16DigitNumber(),
        height: "",
      });
    }
  }, [patientData]);

  const navigation = useNavigation();
  const dispatch = useDispatch<typeof store.dispatch>();

  const warningLevel =
    parseFloat(patient.weight) >= 300
      ? 2
      : parseFloat(patient.weight) >= 200
      ? 1
      : 0;

  const handleNameChange = (name: string) => {
    setPatient({ ...patient, name });
  };

  const handleWeightChange = (weight: string) => {
    setPatient({ ...patient, weight });
  };

  const handleHeightChange = (height: string) => {
    setPatient({ ...patient, height });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const patientToSubmit = {
      ...patient,
      weight: patient.weight ? parseFloat(patient.weight) : undefined,
      height: patient.height ? parseFloat(patient.height) : undefined,
    };

    if (patientData) {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "patient_updated",
        "AddPatientScreen",
        "PatientUpdated",
        { patientId: patient.id }
      );
      await updatePatient(patientToSubmit);

      dispatch(fetchAndUpdateAmountsAndUnits());

      navigation.goBack();
      setLoading(false);
      return;
    }
    const patientId = await addPatient(patientToSubmit);

    dispatch(clearAmount());
    dispatch(setPatientId(patientId));
    dispatch(fetchAndUpdateAmountsAndUnits());

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "patient_added",
      "AddPatientScreen",
      "PatientAdded",
      { patientId }
    );

    setLoading(false);

    navigation.goBack();
  };

  useEffect(() => {
    configureAnimations();
  }, [warningLevel]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          flex: 1,
          width: "100%",
        }}
        contentContainerStyle={{
          flexGrow: 1,
          width: "100%",
          flex: 1,
          padding: Sizes.defaultContainerPadding,
        }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        <TitleText
          title={
            patientData ? I18n.t("edit_patients") : I18n.t("create_new_patient")
          }
          style={{ marginBottom: 24 }}
        />
        <Text style={styles.text}>{I18n.t("identifier")}</Text>
        <TextInput
          style={styles.input}
          placeholder={I18n.t("identifier")}
          value={patient.name}
          autoFocus={patient.name === ""}
          onChangeText={handleNameChange}
        />
        <Text style={styles.text}>Gewicht (in KG)</Text>
        <TextInput
          style={styles.input}
          placeholder={I18n.t("weight") + " [in kg]"}
          value={patient.weight}
          onChangeText={handleWeightChange}
          keyboardType="numeric"
        />
        <WeightWarningLabel
          warningLevel={warningLevel}
          style={{
            marginHorizontal: 20,
            marginBottom: 12,
          }}
        />
        <Text style={styles.text}>
          {I18n.t("body_size")} [in cm] (optional)
        </Text>
        <TextInput
          style={styles.input}
          placeholder={I18n.t("body_size") + " [in cm] (optional)"}
          value={patient.height}
          onChangeText={handleHeightChange}
          keyboardType="numeric"
        />
        <View
          style={{
            gap: 20,
            marginTop: 30,
          }}
        >
          <DefaultButton
            loading={loading}
            title={patientData ? I18n.t("save_changes") : I18n.t("continue")}
            enabled={
              patient.name?.length > 0 &&
              !isNaN(parseInt(patient.weight)) &&
              (patient.height === "" || !isNaN(parseInt(patient.height))) &&
              (patientData
                ? patient?.name !== patientData?.name ||
                  patient?.weight !== (patientData?.weight ?? "").toString() ||
                  patient?.height !== (patientData?.height ?? "").toString()
                : true)
            }
            icon={
              <Feather
                name={patientData ? "save" : "arrow-up-right"}
                size={20}
                color="black"
              />
            }
            action={handleSubmit}
            type={ButtonTypes.Primary}
            //style={{ flex: 1 }}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,

    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 24,
    backgroundColor: colors.ui.background,
  },
  text: {
    alignSelf: "flex-start",
    fontSize: Sizes.boxText,
    ...getFontStyle(600),
    marginTop: 12,
  },
  input: {
    backgroundColor: colors.ui.inputBackground,
    color: colors.ui.textPrimary,
    //maxWidth: Sizes.containerWidth,
    marginBottom: 8,
    borderRadius: 12,
    marginTop: 8,
    width: "100%",
    alignSelf: "stretch",
    height: 50,
    padding: 12,
    fontFamily: "HostGrotesk-Regular",
    zIndex: 0,
  },
});
