import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useRef } from "react";
import {
  Animated,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  selectShowSources,
  shouldShowSources,
} from "../../../functions/current-result/actions";
import getFontStyle from "../../../functions/getFontSize";
import DefaultGradientBackground from "../../DefaultGradientBackground";
import getGeneratedSource, {
  transformLegacySource,
} from "./components/CitationGenerator";

export const SourcesView = ({ pageContent }) => {
  const showSources = useSelector(selectShowSources);
  const dispatch = useDispatch();
  const rotateAnim = useRef(new Animated.Value(0)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const sources = useMemo(() => {
    if (pageContent.sources && pageContent.sources !== "") {
      return pageContent.sources;
    } else if (
      pageContent.appendedSources &&
      pageContent.appendedSources.length !== 0
    ) {
      return pageContent.appendedSources;
    }
    return null;
  }, [pageContent]);

  const isNodePage = pageContent.type === "nodePage";

  useEffect(() => {
    Animated.timing(rotateAnim, {
      toValue: showSources ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();

    if (showSources) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }).start();
    } else {
      fadeAnim.setValue(0);
    }
  }, [showSources, rotateAnim, fadeAnim]);

  const rotateIcon = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "180deg"],
  });

  if ((isNodePage && !showSources) || !sources) return null;

  const toggleSources = () => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "sources_pressed",
      "SourcesView",
      "sources pressed",
      !showSources
    );
    dispatch(shouldShowSources(!showSources));
  };

  if (!sources) return null;
  return (
    <View style={styles.sourcesContainer}>
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={toggleSources}
        style={styles.sourcesCard}
      >
        <View style={styles.headerRow}>
          <SourcesIcon />
          <Text style={styles.headerText}>
            {showSources ? i18n.t("hide_sources") : i18n.t("show_sources")}
          </Text>
          {isNodePage ? (
            <Feather name="x" size={22} color={colors.ui.textPrimary} />
          ) : (
            <Animated.View style={{ transform: [{ rotate: rotateIcon }] }}>
              <Feather
                name="chevron-down"
                size={22}
                color={colors.ui.textPrimary}
                style={{ opacity: 0.6 }}
              />
            </Animated.View>
          )}
        </View>

        {showSources && (
          <Animated.View style={{ opacity: fadeAnim }}>
            <Sources sources={sources} />
          </Animated.View>
        )}
      </TouchableOpacity>
    </View>
  );
};

const CitationItem = ({ number, citation, onPressLink }) => {
  return (
    <View style={styles.citationContainer}>
      <View style={styles.citationRow}>
        <Text style={styles.citationNumber}>{number}.</Text>
        <View style={styles.citationContent}>
          {citation.type && (
            <Text style={styles.sourceType}>{citation.type}</Text>
          )}
          <Text style={styles.citationText}>{citation.text}</Text>
          {citation.link && (
            <TouchableOpacity
              onPress={() => onPressLink(citation.link)}
              hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
              style={styles.linkButton}
            >
              <Text style={styles.linkText}>Quelle anzeigen</Text>
              <Feather
                name="external-link"
                size={10}
                color="#0066cc"
                style={{ marginLeft: 4 }}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

const Sources = ({ sources }) => {
  const navigation = useNavigation();

  const citations = useMemo(() => {
    if (typeof sources === "string") {
      return transformLegacySource(sources);
    }
    // Sort sources by an index property and generate citation objects
    const sortedSources = [...sources].sort((s1, s2) => s1.index - s2.index);
    return sortedSources.map((source) => getGeneratedSource(source));
  }, [sources]);

  const onLinkPress = (href) => {
    navigation.navigate("WebBrowserScreen", { url: href });
  };

  return (
    <View style={styles.sourcesListContainer}>
      <FlatList
        data={citations}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item, index }) => (
          <CitationItem
            number={index + 1}
            citation={item}
            onPressLink={onLinkPress}
          />
        )}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.listContent}
      />
    </View>
  );
};

const SourcesIcon = () => {
  return (
    <View style={styles.iconContainer}>
      <DefaultGradientBackground />
      <Feather name="file-text" size={20} color="#fff" />
    </View>
  );
};

const styles = StyleSheet.create({
  sourcesContainer: {
    marginTop: 32,
    borderTopWidth: 1,
    borderTopColor: colors.ui.border,
    paddingTop: 32,
  },
  sourcesCard: {
    backgroundColor: colors.ui.cardBackground,
    padding: Sizes.defaultBoxPadding,
    paddingVertical: Sizes.defaultBoxPadding + 4,
    width: "100%",
    borderRadius: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 8,
    elevation: 2,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerText: {
    ...getFontStyle(600),
    fontSize: Sizes.boxText,
    flex: 1,
    color: colors.ui.textPrimary,
  },
  sourcesListContainer: {
    marginTop: 16,
    alignSelf: "stretch",
    flex: 1,
  },
  listContent: {
    paddingHorizontal: 4,
  },
  citationContainer: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  citationRow: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
  citationNumber: {
    fontSize: 10,
    fontWeight: "600",
    color: "#333",
    marginRight: 6,
  },
  citationContent: {
    flex: 1,
  },
  sourceType: {
    fontSize: 10,
    fontWeight: "600",
    color: "#333",
    marginBottom: 4,
  },
  citationText: {
    fontSize: 10,
    lineHeight: 16,
    color: "#444",
    marginBottom: 6,
  },
  linkButton: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  linkText: {
    fontSize: 10,
    color: "#0066cc",
    textDecorationLine: "underline",
  },
  separator: {
    height: 8,
  },
  iconContainer: {
    padding: 10,
    borderRadius: 12,
    overflow: "hidden",
    aspectRatio: 1,
    marginRight: 12,
    alignItems: "center",
    height: 42,
    justifyContent: "center",
  },
});
