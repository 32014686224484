import { store } from "../../functions/store";

import { NodePage, ResultPage, ScorePage } from "./load-all/types";
import parsePageForNewSources from "./new-source-parser";

export default function getPageContent(id: string | undefined) {
  if (!id) return null;
  return getContent(id);
}

function getContent(id: string) {
  const allData = store.getState().dataReducer.allData;

  let _pageData = allData?.pages?.find(
    (page: ResultPage | NodePage | ScorePage) => page._id === id
  );
  if (!_pageData)
    _pageData = allData?.pages?.find(
      (page: any) => page.content?.legacy_id === id
    );

  if (!_pageData) return null;

  const pageData = JSON.parse(JSON.stringify(_pageData));

  const pageContent = {
    _id: pageData._id,
    ...pageData.content,
    internal_rating: pageData.internal_rating,
    type: pageData.content_type_id,
    created_at: pageData.created_at,
    last_modified_at: pageData.last_modified_at,
    tags: pageData.tags,
  };

  delete pageContent.quellen;

  if (pageContent?.newSources) {
    parsePageForNewSources(pageContent);
  }

  return pageContent;
}
