"use client";

import { useRef } from "react";
import { Animated, Easing } from "react-native";

export const useFlowchartAnimations = () => {
  // Animation references
  const scaleAnim = useRef(new Animated.Value(1)).current;
  const opacityAnim = useRef(new Animated.Value(1)).current;
  const answersContainerAnim = useRef(new Animated.Value(0)).current;
  const answersOpacityAnim = useRef(new Animated.Value(1)).current;
  const previousAnswersHeightAnim = useRef(new Animated.Value(1)).current;
  const previousAnswersOpacityAnim = useRef(new Animated.Value(1)).current;
  const resultContentAnim = useRef(new Animated.Value(50)).current;
  const resultOpacityAnim = useRef(new Animated.Value(0)).current;
  const questionContainerAnim = useRef(new Animated.Value(1)).current;
  const questionPositionAnim = useRef(new Animated.Value(0)).current;
  const headerOpacityAnim = useRef(new Animated.Value(1)).current;

  /**
   * Animate the transition to the result view
   */
  const animateToResultView = () => {
    // First, fade out the header, previous answers, and question container
    Animated.parallel([
      // Fade out header
      Animated.timing(headerOpacityAnim, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      // Fade out previous answers
      Animated.timing(previousAnswersOpacityAnim, {
        toValue: 0,
        duration: 250,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      // Fade out question container
      Animated.timing(questionContainerAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      // Move question container down slightly as it fades out
      Animated.timing(questionPositionAnim, {
        toValue: 50,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
    ]).start(() => {
      // Reset result content animation values
      resultContentAnim.setValue(100);
      resultOpacityAnim.setValue(0);

      // Then, slide the answers to the top and animate in the result content
      Animated.stagger(150, [
        // Slide answers to the top with a nice bounce effect
        Animated.spring(answersContainerAnim, {
          toValue: -32,
          friction: 8, // Higher friction means less oscillation
          tension: 40, // Lower tension means slower movement
          useNativeDriver: true,
        }),
        // Animate in the result content with a spring effect
        Animated.parallel([
          Animated.spring(resultContentAnim, {
            toValue: -50,
            friction: 8,
            tension: 50,
            useNativeDriver: true,
          }),
          Animated.timing(resultOpacityAnim, {
            toValue: 1,
            duration: 400,
            useNativeDriver: true,
            easing: Easing.out(Easing.ease),
          }),
        ]),
      ]).start();
    });
  };

  /**
   * Animate back to the questionnaire
   */
  const animateBackToQuestions = (onComplete: () => void) => {
    // First, fade out the result content
    Animated.parallel([
      // Slide the answers back down
      Animated.timing(answersContainerAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }),
      // Fade out and move the result content
      Animated.timing(resultOpacityAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.in(Easing.ease),
      }),
      Animated.timing(resultContentAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.in(Easing.ease),
      }),
    ]).start(() => {
      // Then fade in the question container and header
      Animated.parallel([
        // Fade in header
        Animated.timing(headerOpacityAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
        // Fade in previous answers
        Animated.timing(previousAnswersOpacityAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
        // Fade in question container
        Animated.timing(questionContainerAnim, {
          toValue: 1,
          duration: 350,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
        // Reset question position
        Animated.timing(questionPositionAnim, {
          toValue: 0,
          duration: 350,
          useNativeDriver: true,
          easing: Easing.out(Easing.ease),
        }),
      ]).start(onComplete);
    });
  };

  /**
   * Animate in a new question
   */
  const animateNewQuestion = () => {
    scaleAnim.setValue(0.95);
    opacityAnim.setValue(0);

    Animated.parallel([
      Animated.timing(scaleAnim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }),
      Animated.timing(opacityAnim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }),
    ]).start();
  };

  /**
   * Small press feedback animation
   */
  const animatePressEffect = () => {
    Animated.sequence([
      Animated.timing(scaleAnim, {
        toValue: 0.95,
        duration: 80,
        useNativeDriver: true,
      }),
      Animated.timing(scaleAnim, {
        toValue: 1,
        duration: 80,
        useNativeDriver: true,
      }),
    ]).start();
  };

  /**
   * Reset all animation values
   */
  const resetAnimations = () => {
    answersContainerAnim.setValue(0);
    answersOpacityAnim.setValue(1);
    previousAnswersHeightAnim.setValue(1);
    previousAnswersOpacityAnim.setValue(1);
    resultContentAnim.setValue(50);
    resultOpacityAnim.setValue(0);
    questionContainerAnim.setValue(1);
    questionPositionAnim.setValue(0);
    headerOpacityAnim.setValue(1);
  };

  return {
    // Animation values
    scaleAnim,
    opacityAnim,
    answersContainerAnim,
    answersOpacityAnim,
    previousAnswersHeightAnim,
    previousAnswersOpacityAnim,
    resultContentAnim,
    resultOpacityAnim,
    questionContainerAnim,
    questionPositionAnim,
    headerOpacityAnim,

    // Animation functions
    animateToResultView,
    animateBackToQuestions,
    animateNewQuestion,
    animatePressEffect,
    resetAnimations,
  };
};
