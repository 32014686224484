import he from "he";
import { useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { Feather } from "@expo/vector-icons";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { useSelector } from "react-redux";
import i18n from "../../locales/i18n";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import ResultBoxes from "../components/result/ResultBoxComponent";
import { SourcesView } from "../components/result/SourcesView/SourcesView";
import TitleText from "../components/result/TitleText";
import { ResultPagesWrapper } from "../components/ResultPagesWrapper";
import SearchResults from "../components/search-tab/SearchResults";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { showInlineNotification } from "../entry/Root";
import { selectPatientData } from "../functions/calculator/actions";
import configureAnimations from "../functions/configure-animations";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../functions/user-interaction-handler";
import getColorScheme from "../hooks/useColorScheme";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";
import { HorizontalCardSlider } from "../components/home-tab/HorizontalCardSlider";
import getPageContent from "../api/content/get-page-content";
import FurtherActionBox from "../components/boxes/box-types/further-action-box/FurtherActionBox";
import getFurtherActionBox from "../components/boxes/box-types/further-action-box/FurtherActionBoxSorter";

export default function CMSNodePageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const filters = [pageContent.fieldTitle];
  const [selected, setSelected] = useState(false);
  const [infoTextCollapsed, setInfoTextCollapsed] = useState(true);
  const patientData = useSelector(selectPatientData);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [customScoreData, setCustomScoreData] = useState([]);
  const [customCalcData, setCustomCalcData] = useState([]);

  useEffect(() => {
    if (pageContent.fieldTitle !== "Scores & Rechner") return;

    function getBoxesFromContent(target) {
      console.log("target", target);
      const page = getPageContent(target.resultOrNodeId);
      if (!page) return [];
      return page.boxes.slice(0, 5);
    }

    const scores = getBoxesFromContent(
      pageContent.boxes.find((b) => b.fieldTitle === "Scores")
    );
    console.log("scores", scores);

    const calculators = getBoxesFromContent(
      pageContent.boxes.find((b) => b.fieldTitle === "Rechner")
    );
    console.log("calculators", calculators);

    setCustomScoreData(scores);
    setCustomCalcData(calculators);
  }, [pageContent]);

  const scoreId = undefined;
  //useSelector(selectAllContent).medicalCategories.content.scorePageId;

  const isScorePage = pageContent?._id === scoreId;

  const infoText = getTextToTitle(pageContent?.fieldTitle);

  useEffect(() => {
    if (!infoText) return;

    const _eval = getValueFromInteractions("info_text_" + infoText);

    if (!_eval) {
      setInfoTextCollapsed(false);
    }
  }, [infoText]);

  if (!pageContent) {
    navigation.goBack();
    showInlineNotification({
      text:
        i18n.locale === "de"
          ? "Ein Fehler ist aufgetreten. Bitte aktualisiere die Inhalte auf dem Home Screen"
          : i18n.t("error_occurred_refresh_screen"),
      type: InlineNotificationType.ERROR,
    });

    return null;
  }

  return (
    <ResultPagesWrapper
      navigation={navigation}
      patientData={patientData}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          screen: "NodePageScreen",
          action: "node_page_opened",
          values: pageContent?.title,
          id: pageContent?._id,
        },
      }}
    >
      <SafeAreaView
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          //flex: 1,
          flex: 1,
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: Colors[colorScheme].background,
            zIndex: 10,
            paddingHorizontal: Sizes.defaultContainerPadding,
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <TitleText
                style={{ flexShrink: 1 }}
                title={pageContent.fieldTitle}
              />
              {infoTextCollapsed && !!infoText && (
                <Feather
                  name="info"
                  size={20}
                  color={Colors[colorScheme].text}
                  onPress={() => {
                    configureAnimations();
                    setInfoTextCollapsed(false);
                  }}
                />
              )}
            </View>
            {!infoTextCollapsed && (
              <TouchableOpacity
                onPress={() => {
                  configureAnimations();
                  setInfoTextCollapsed(true);
                  addValueToInteractions("info_text_" + infoText, "true");
                }}
                style={{
                  flexDirection: "row",
                  gap: 12,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    marginTop: 8,
                    flex: 1,
                    fontSize: Sizes.boxText,
                    color: Colors[colorScheme].text,
                  }}
                >
                  {infoText}
                </Text>
                <Feather
                  name="chevron-down"
                  size={24}
                  color={Colors[colorScheme].text}
                />
              </TouchableOpacity>
            )}
          </View>
          <SearchBar
            collapsed
            style={{ marginTop: 20, marginBottom: 16 }}
            placeholder={
              i18n.t("search_placeholder_detail") + pageContent.fieldTitle
            }
            query={query}
            setQuery={setQuery}
            selected={selected}
            setSelected={setSelected}
          />
        </View>
        {!selected ? (
          <View
            style={{
              paddingHorizontal: Sizes.defaultContainerPadding,
              width: "100%",
              flex: 1,
            }}
          >
            <View
              style={{
                width: "100%",
                flex: 1,

                justifyContent: "center",
              }}
            >
              {pageContent.fieldTitle === "Scores & Rechner" ? (
                <View style={{ gap: 16 }}>
                  {getFurtherActionBox({
                    ...pageContent.boxes.find(
                      (box) => box.fieldTitle === "Scores"
                    ),
                  })}
                  {/* Render horizontal scroll for the first box */}
                  <Text
                    style={{
                      fontSize: Sizes.boxText,
                      fontWeight: "600",
                      fontStyle: "italic",
                      marginTop: 16,
                    }}
                  >
                    Top Scores
                  </Text>
                  <HorizontalCardSlider
                    header="Scores"
                    data={customScoreData.map((item: any) => ({
                      ...item,
                      _id: item.resultOrNodeId,
                      title: he.decode(stripHtmlTags(item.title)), // Normalize title to plain text
                    }))}
                  />
                  <View
                    style={{
                      borderBottomColor: "#777",
                      borderBottomWidth: 0.2,
                      paddingTop: 24,
                    }}
                  />
                  <View style={{ marginTop: 12, gap: 16 }}>
                    {getFurtherActionBox({
                      ...pageContent.boxes.find(
                        (box) => box.fieldTitle === "Rechner"
                      ),
                    })}
                    {/* Render horizontal scroll for the second box */}
                    <Text
                      style={{
                        fontSize: Sizes.boxText,
                        fontWeight: "600",
                        fontStyle: "italic",
                        marginTop: 16,
                      }}
                    >
                      Top Rechner
                    </Text>
                    <HorizontalCardSlider
                      header="Rechner"
                      data={customCalcData.map((item: any) => ({
                        ...item,
                        _id: item.resultOrNodeId,
                        title: he.decode(stripHtmlTags(item.title)), // Normalize title to plain text
                      }))}
                    />
                  </View>
                </View>
              ) : (
                <ResultBoxes
                  pageContent={
                    isScorePage ? getSortedNodes(pageContent) : pageContent
                  }
                />
              )}
            </View>
            <SourcesView {...{ pageContent }} />
          </View>
        ) : (
          <View
            style={{
              padding: Sizes.defaultContainerPadding,
              flex: 1,
              width: "100%",
            }}
          >
            <SearchResults {...{ query, setQuery, filters }} />
          </View>
        )}
      </SafeAreaView>
    </ResultPagesWrapper>
  );
}

function getSortedNodes(pageContent) {
  const _pageContent = JSON.parse(JSON.stringify(pageContent));
  const boxes = _pageContent.boxes ?? _pageContent.resultBoxes;

  _pageContent.boxes = boxes.sort((d1, d2) => {
    if (
      d1.typeId === "furtherResultPageBox" &&
      d2.typeId !== "furtherResultPageBox"
    ) {
      return 1;
    }
    if (
      d1.typeId !== "furtherResultPageBox" &&
      d2.typeId === "furtherResultPageBox"
    ) {
      return -1;
    }
    return d1.fieldTitle.localeCompare(d2.fieldTitle);
  });

  return _pageContent;
}

function stripHtmlTags(html) {
  return html.replace(/<[^>]*>/g, ""); // Remove all HTML tags
}

function getTextToTitle(title) {
  switch (title) {
    case i18n.t("emergency"):
      return i18n.t("emergency_description");
    case "Fast-Track":
      return i18n.t("fast_track_description");
    case i18n.t("diseases"):
      return i18n.t("diseases_description");
    case i18n.t("interventions"):
      return i18n.t("interventions_procedures_description");
    case i18n.t("therapy_algorithms"):
      return i18n.t("therapy_algorithms_description");
    case i18n.t("scores"):
      return i18n.t("scores_description");
    case i18n.t("calculators"):
      return i18n.t("calculators_description");
    default:
      return "";
  }
}
