// PrimeSection.tsx
import React from "react";
import { ActivityIndicator, View } from "react-native";

import { Sizes } from "../../constants/static-sizes";
import CarouselComponent from "../CarouselComponent/CarouselComponent";
import LargePartnerCard from "./LargePartnerCard"; // Update path as needed
import { useData } from "./PartnerDataProvider";

const PrimeSection: React.FC = () => {
  const { data, loading } = useData();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const primeTiles = data[dayOfMonth]?.primeTile || [];

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  return (
    <View
      style={{
        borderBottomColor: "#bbb",
        borderBottomWidth: 0.3,
        paddingBottom: 32,
      }}
    >
      <View
        style={{
          marginHorizontal: -Sizes.defaultContainerPadding,
          gap: 20,
          alignItems: "center",
        }}
      >
        <CarouselComponent
          data={primeTiles}
          autoplay
          autoplayInterval={8000}
          renderItem={({ item }) => <LargePartnerCard item={item} />}
        />
      </View>
    </View>
  );
};

export default PrimeSection;
