import React, { useState } from "react";
import { ActivityIndicator, View } from "react-native";

import { SatelliteTile, useData } from "./PartnerDataProvider";
import { SmallPartnerCard } from "./SmallPartnerCard";
import getWidth from "../../hooks/getWidth";
import CarouselComponent from "../CarouselComponent/CarouselComponent";

// TODO: fix web display
const SatelliteSection: React.FC = () => {
  const { data, loading } = useData();
  const [satelliteIndex, setSatelliteIndex] = useState(0);

  const width = getWidth();

  const today = new Date();
  const dayOfMonth = today.getDate();
  const satelliteTiles = data[dayOfMonth]?.sateliteTile || [];

  // Helper function to create a dummy item for empty grid spots
  const createDummyItem = (): SatelliteTile => ({
    slogan: "Entdecke MEDICEO!",
  });
  const groupedSatelliteTiles: SatelliteTile[][] = [];

  // Group items into chunks of 4 for the grid layout
  for (let i = 0; i < satelliteTiles.length; i += 4) {
    const chunk = satelliteTiles.slice(i, i + 4);
    while (chunk.length < 4) {
      chunk.push(createDummyItem());
    }
    groupedSatelliteTiles.push(chunk);
  }

  if (loading) {
    return <ActivityIndicator size="small" color="gray" />;
  }

  return (
    <View
      style={{
        gap: 20,
        alignItems: "center",
        borderBottomColor: "#777",
        borderBottomWidth: 0.2,
        paddingBottom: 32,
        paddingTop: 32,
        borderTopColor: "#777",
        borderTopWidth: 0.2,
      }}
    >
      <CarouselComponent
        data={groupedSatelliteTiles}
        containerCustomStyle={{ overflow: "visible" }}
        renderItem={({ item }) => (
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%", // Ensure grid takes full width
              gap: 12,
            }}
          >
            {item.map((gridItem, index) => (
              <SmallPartnerCard key={index} item={gridItem} />
            ))}
          </View>
        )}
        sliderWidth={width}
        itemWidth={Math.min(width * 0.85, 1000)} // Limits max width for consistent display
        autoplay
        autoplayDelay={2000}
        autoplayInterval={8000}
        loop
        vertical={false}
        onSnapToItem={(index) => setSatelliteIndex(index)}
      />
    </View>
  );
};

export default SatelliteSection;
