import React, { ReactNode } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  ScrollViewProps,
} from "react-native";
import { useDispatch } from "react-redux";

interface ControlledScrollViewProps extends ScrollViewProps {
  /**
   * Optional callback function triggered on scroll events.
   * Allows passing a custom function to listen to scroll events.
   * @param event - The native scroll event triggered on scroll.
   */
  onScrollEvent?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
  /**
   * Children elements to be rendered inside the ScrollView.
   */
  children: ReactNode;
}

/**
 * ControlledScrollView is a wrapper around the native ScrollView component.
 * It dispatches an isScrolled state based on the scroll offset.
 *
 * @param {ControlledScrollViewProps} props - Properties to control ScrollView behavior.
 * @returns A ScrollView component with added functionality.
 */
const ControlledScrollView: React.FC<ControlledScrollViewProps> = ({
  children,
  onScrollEvent,
  ...props
}) => {
  const dispatch = useDispatch();

  /**
   * Handles the scroll event, setting isScrolled to true if the scroll offset is non-zero,
   * and setting it back to false if scrolled to the top (offset == 0).
   * @param event - The native scroll event from ScrollView.
   */
  const onScrollHandler = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const offsetY = event.nativeEvent.contentOffset.y;

    //dispatch(setIsScrolling(offsetY > 0)); // Set isScrolled based on offset (true if offset != 0, false if offset == 0)

    if (onScrollEvent) {
      onScrollEvent(event); // Calls the custom onScroll event if provided
    }
  };

  return (
    <ScrollView
      {...props} // Passes all props to ScrollView
      onScroll={onScrollHandler} // Attaches the custom scroll handler
      scrollEventThrottle={16} // Optional throttle for smoother performance
    >
      {children}
    </ScrollView>
  );
};

export default ControlledScrollView;
