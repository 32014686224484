import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo } from "react";
import {
  FlatList,
  Image,
  Platform,
  SafeAreaView,
  Text,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import getPageContent from "../api/content/get-page-content";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { navigateToNextCMSPage } from "../functions/navigation/helpers";
import handleOneTimeAction from "../functions/one-time-action-handler";
import { selectCurrentUser } from "../functions/user/actions";
import { Card } from "./tab-navigator-screens/components/Card";
import I18n from "../../locales/i18n";

export default function NotesOverviewScreen() {
  const currentUser = useSelector(selectCurrentUser);

  const notes = useMemo(
    () =>
      currentUser.notes
        .map((note) => {
          const page = getPageContent(note.id);
          if (!page) return null;
          return {
            ...note,
            page,
          };
        })
        .filter((note) => note !== null)
        .filter((note) => note.note !== "")
        .reverse(),
    [currentUser.notes]
  );
  const navigation = useNavigation();

  useEffect(() => {
    async function checkFirstTimeOnboarding() {
      if (Platform.OS === "web") return;
      if (await handleOneTimeAction("notes_onboarding")) {
        setTimeout(() => {
          navigation.navigate("OnboardingScreen", {
            pageKey: "notes",
          });
        }, 750);
      }
    }

    checkFirstTimeOnboarding();
  }, []);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "screen_view_notes",
      "NotesScreen",
      "View"
    );
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: colors.ui.background,
      }}
    >
      {notes.length > 0 && (
        <FlatList
          data={notes}
          contentContainerStyle={{
            paddingVertical: Sizes.defaultContainerPadding,
            //gap: isLargerDevice() ? 32 : 16,
            paddingHorizontal: Sizes.defaultContainerPadding,
          }}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => {
            //TODO: memo pages

            const page = item.page;

            return (
              <View style={{ marginBottom: 20 }}>
                <Card
                  title={page.title ?? page.fieldTitle}
                  time={item.timestamp}
                  action={() => {
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "notes_item_selected",
                      "NotesScreen",
                      item.title,
                      page._id
                    );
                    navigateToNextCMSPage(page?._id, navigation);
                  }}
                  sectionHeader="Notes"
                  id={page?._id}
                  body={item.note}
                />
              </View>
            );
          }}
        />
      )}
      {notes?.length === 0 && (
        <View
          style={{
            margin: Sizes.defaultContainerPadding,
            alignItems: "center",
          }}
        >
          <View style={{}}>
            <Text
              style={{
                fontFamily: "HostGrotesk-Regular",
                color: colors.ui.textPrimary,
                marginTop: 12,
                textAlign: "center",
              }}
            >
              {I18n.t("no_notes_added_yet")}
            </Text>
            <Image
              source={require("../../assets/images/characters/shortcuts.png")}
              style={{
                alignSelf: "center",
                width: 250,
                height: 250,
                marginVertical: 32,
                resizeMode: "contain",
              }}
            />
          </View>
        </View>
      )}
    </SafeAreaView>
  );
}
