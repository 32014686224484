import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { Platform, Share, Text, TouchableOpacity, View } from "react-native";
import { SceneMap, TabBar, TabView } from "react-native-tab-view";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { setCalculationVar } from "../../../functions/calculator/actions";

import { Feather } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ControlledScrollView from "../../../components/ControlledScrollView";
import DefaultHeader from "../../../components/header/DefaultHeaderComponents";
import SearchSection from "../../../components/partnerships/SearchSection";
import { selectAllContent } from "../../../functions/data/actions";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import { isWebAndNotMobile } from "../../../hooks/isLargerDevice";
import getColorScheme from "../../../hooks/useColorScheme";
import useScrollTopMargin from "../../../hooks/useScrollTopMargin";
import { SearchBar } from "../components/SearchBar";
import ContentListComponent from "./components/ContentListComponent";
import LexiconComponent from "./components/LexiconComponent";

export default function SearchScreen() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const insets = useSafeAreaInsets();

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const [filters, setFilters] = useState([]);

  const paddingTop = useScrollTopMargin();

  const allData = useSelector(selectAllContent);

  const contentList = allData.contentList?.content?.medicalSubjectAreaItems;

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_screen_opened",
        "SearchScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("content")));

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        overflow: "visible",
        //backgroundColor: Colors[colorScheme].background,
      }}
    >
      <ControlledScrollView
        style={{
          flex: 1,
          padding: Sizes.defaultContainerPadding,
          paddingTop: 0,
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150, paddingTop }}
      >
        <DefaultHeader height={150} />
        <TouchableOpacity
          style={{
            flexDirection: "row",
            alignSelf: "center",
            padding: 12,
            gap: 6,
            position: "absolute",
            top: 78,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: Colors[colorScheme].foreground + "55",
            borderRadius: 30,
            paddingVertical: 10,
          }}
          onPress={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "share_app_selected",
              "SearchScreen",
              "User Selected Share Apo"
            );

            if (Platform.OS === "web") {
              navigator.clipboard.writeText("https://app.mediceo.com");
              alert("Link in die Zwischenablage kopiert!");
              return;
            }
            Share.share({
              message: "https://app.mediceo.com",
            });
          }}
        >
          <Text
            style={{
              fontSize: Sizes.boxText,
              fontWeight: "600",
            }}
          >
            App teilen
          </Text>
          <Feather name="share" size={18} color="black" />
        </TouchableOpacity>
        <View
          style={{
            marginBottom: 24,
          }}
        >
          <SearchBar
            action={() => {
              navigation.navigate("GlobalSearchScreen");
            }}
            origin="Search"
            placeholder={i18n.t("default_search_placeholder")}
            {...{ query, setQuery, selected, setSelected, filters, setFilters }}
            style={{ marginBottom: 8, marginTop: 8 }}
          />
        </View>
        <SearchSection />
        <ContentListComponent content={contentList} />
      </ControlledScrollView>
    </View>
  );
}

const TabbedView = ({ content }) => {
  const renderScene = useMemo(
    () =>
      SceneMap({
        first: () => <LexiconComponent />,
        second: () => <ContentListComponent content={content} />,
      }),
    [content]
  );

  const [index, setIndex] = useState(0);
  const [routes] = useState([
    { key: "second", title: i18n.t("topics") },
    { key: "first", title: i18n.t("content") },
  ]);

  const handleIndexChange = (newIndex) => {
    // Logging the tab switch event
    const newRoute = routes[newIndex];
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "tab_switch",
      "TabbedView",
      "User Switched Tab",
      {
        newTabIndex: newIndex,
        newTabKey: newRoute.key,
        newTabTitle: newRoute.title,
      }
    );

    // Updating the index to reflect the change
    setIndex(newIndex);
  };

  const colorScheme = getColorScheme();

  return (
    <View
      style={{
        flex: 1,
        marginHorizontal: -Sizes.defaultContainerPadding,

        marginTop: isWebAndNotMobile() ? 20 : 0,
      }}
    >
      <TabView
        renderTabBar={(props) => renderTabBar(props, colorScheme)}
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={handleIndexChange}
      />
    </View>
  );
};

export const renderTabBar = (props, colorScheme) => {
  return (
    <View
      style={{
        shadowColor: Colors[colorScheme].text,
        shadowOffset: {
          width: 0,
          height: 3,
        },
        paddingHorizontal: Sizes.defaultContainerPadding,
        marginHorizontal: -Sizes.defaultContainerPadding,
        shadowOpacity: 0.08,
        shadowRadius: 4,
        zIndex: 10,
        elevation: 2,
        overflow: "visible",
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <TabBar
        {...props}
        indicatorStyle={{
          backgroundColor: Colors[colorScheme].accent,
          paddingTop: 4,
        }}
        style={{ backgroundColor: "transparent" }}
        renderLabel={({ route, focused }) => (
          <Text
            style={{
              color: focused
                ? Colors[colorScheme].text
                : Colors[colorScheme].gray,
              fontSize: Sizes.boxText,
              textAlign: "center",
              textTransform: "none",
              fontWeight: "500",
            }}
          >
            {route.title}
          </Text>
        )}
        tabStyle={{
          borderBottomColor: Colors[colorScheme].accent,
          borderBottomWidth: 1,
        }}
      />
    </View>
  );
};
