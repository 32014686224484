"use client";

import { Feather } from "@expo/vector-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { transformPageData } from "../api/content/get-page-content";
import { selectCurrentUser } from "../functions/user/actions";
import CMSNodePageScreen from "./CMSNodePageScreen";
import ChecklistScreen from "./ChecklistScreen/ChecklistScreen";
// import { FlowchartScreen } from "./FlowchartScreen";
import { colors } from "../constants/static-colors";
import { FlowchartScreen } from "./FlowchartScreen/FlowchartScreen";
import ResultPageScreen from "./ResultPageScreen/ResultPageScreen";
import ScoreScreen from "./ScoreScreen/ScoreScreen";
import loadAllData from "../api/content/load-all/load-all-content";

// Import your screen components

// Types
type DisplayMode = "mobile" | "tablet" | "desktop";

export const renderContentComponent = (pageContent, navigation) => {
  if (!pageContent) return null;

  // Create route params object similar to what navigateToNextCMSPage would pass
  const routeParams = {
    pageContent: pageContent,
    // Add any additional props that might be needed
  };

  switch (pageContent.type) {
    case "scorePage":
      return (
        <ScoreScreen navigation={navigation} route={{ params: routeParams }} />
      );
    case "nodePage":
      return (
        <CMSNodePageScreen
          navigation={navigation}
          route={{ params: routeParams }}
        />
      );
    case "checklistPageType":
      return <ChecklistScreen route={{ params: routeParams }} />;
    case "optionTreePage":
      return (
        <FlowchartScreen
          navigation={navigation}
          route={{ params: routeParams }}
        />
      );
    default:
      return (
        <ResultPageScreen
          navigation={navigation}
          route={{ params: routeParams }}
        />
      );
  }
};

const LiveContentPreviewScreen = ({ route, navigation }) => {
  const serverUrl = "https://api.aristophania.mediceo.com";
  const language = "de";
  const isDev = process.env.NODE_ENV === "development";
  const { envId, contentId, userId } = route.params;

  const currentUser = useSelector(selectCurrentUser);
  const userRole = currentUser?.role;

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [displayMode, setDisplayMode] = useState<DisplayMode>("mobile");
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [pageContent, setPageContent] = useState<any>(null);

  const [infoCollapsed, setInfoCollapsed] = useState<boolean>(false);
  const infoHeight = useRef(new Animated.Value(100)).current;

  useEffect(() => {
    loadAllData(undefined, "staging");
  }, []);

  const toggleInfoCollapse = () => {
    setInfoCollapsed(!infoCollapsed);
    Animated.timing(infoHeight, {
      toValue: infoCollapsed ? 180 : 50, // Adjust these values as needed
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  // Check if user has access
  const hasAccess = userRole === "dev" || isDev;

  // Get dimensions based on display mode
  const getDimensions = () => {
    switch (displayMode) {
      case "mobile":
        return { width: 550, height: "100%" };
      case "tablet":
        return { width: 1000, height: "100%" };
      case "desktop":
        return { width: "100%", height: "100%" };
      default:
        return { width: 550, height: "100%" };
    }
  };

  const fetchContent = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const url = `${serverUrl}/env/content/${envId}/${contentId}?user_id=${userId}&language=${language}&parsed_sources=True`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();

      const page = transformPageData(data?.data);
      // Get the page content using your existing function
      setPageContent(page);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
      console.error("Error fetching content:", err);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [envId, contentId, userId]);

  useEffect(() => {
    if (hasAccess) {
      fetchContent();
    }
  }, [hasAccess, fetchContent]);

  const onRefresh = () => {
    setRefreshing(true);
    fetchContent();
  };

  // Render the appropriate component based on content type

  if (!hasAccess) {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.accessDenied}>
          <Feather name="lock" size={48} color="#FF6B6B" />
          <Text style={styles.accessDeniedText}>Access Denied</Text>
          <Text style={styles.accessDeniedSubtext}>
            Only developers can access this testing tool.
          </Text>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={[styles.contentContainer, { width: getDimensions().width }]}>
        {loading ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color="#0066CC" />
            <Text style={styles.loadingText}>Loading content...</Text>
          </View>
        ) : error ? (
          <View style={styles.errorContainer}>
            <Feather name="alert-circle" size={48} color="#FF6B6B" />
            <Text style={styles.errorText}>Error loading content</Text>
            <Text style={styles.errorSubtext}>{error}</Text>
            <TouchableOpacity style={styles.retryButton} onPress={fetchContent}>
              <Text style={styles.retryButtonText}>Retry</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <ScrollView
            style={styles.contentScroll}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          >
            <View style={styles.contentWrapper}>
              {renderContentComponent(pageContent, navigation)}
            </View>
          </ScrollView>
        )}
      </View>
      <View style={styles.header}>
        <Text style={styles.title}>CMS Live Preview</Text>
        <View style={styles.controls}>
          <View style={styles.displayModeContainer}>
            <TouchableOpacity
              style={[
                styles.modeButton,
                displayMode === "mobile" && styles.activeMode,
              ]}
              onPress={() => setDisplayMode("mobile")}
            >
              <Feather
                name="smartphone"
                size={20}
                color={displayMode === "mobile" ? "#fff" : "#333"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.modeButton,
                displayMode === "tablet" && styles.activeMode,
              ]}
              onPress={() => setDisplayMode("tablet")}
            >
              <Feather
                name="tablet"
                size={20}
                color={displayMode === "tablet" ? "#fff" : "#333"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.modeButton,
                displayMode === "desktop" && styles.activeMode,
              ]}
              onPress={() => setDisplayMode("desktop")}
            >
              <Feather
                name="monitor"
                size={20}
                color={displayMode === "desktop" ? "#fff" : "#333"}
              />
            </TouchableOpacity>
          </View>
          <TouchableOpacity style={styles.refreshButton} onPress={onRefresh}>
            <Feather name="refresh-cw" size={20} color="#fff" />
          </TouchableOpacity>
        </View>
      </View>

      <Animated.View style={[styles.infoContainer, { height: infoHeight }]}>
        <TouchableOpacity
          onPress={toggleInfoCollapse}
          style={styles.infoHeader}
        >
          <Text style={styles.infoHeaderText}>Info</Text>
          <Feather
            name={infoCollapsed ? "chevron-up" : "chevron-down"}
            size={20}
            color={colors.brand.greys.g737373}
          />
        </TouchableOpacity>
        {!infoCollapsed && (
          <View>
            <Text style={styles.infoText}>
              Environment: <Text style={styles.infoValue}>{envId}</Text>
            </Text>
            <Text style={styles.infoText}>
              Content ID: <Text style={styles.infoValue}>{contentId}</Text>
            </Text>
            <Text style={styles.infoText}>
              User ID: <Text style={styles.infoValue}>{userId}</Text>
            </Text>
            <Text style={styles.infoText}>
              Language: <Text style={styles.infoValue}>{language}</Text>
            </Text>
            {pageContent && (
              <Text style={styles.infoText}>
                Content Type:{" "}
                <Text style={styles.infoValue}>
                  {pageContent.type || "standard"}
                </Text>
              </Text>
            )}
          </View>
        )}
      </Animated.View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.ui.background,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    backgroundColor: colors.ui.cardBackground,
    borderTopWidth: 1,
    borderTopColor: colors.ui.border,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.brand.greys.black,
  },
  controls: {
    flexDirection: "row",
    alignItems: "center",
  },
  displayModeContainer: {
    flexDirection: "row",
    backgroundColor: colors.brand.greys.gF5F5F5,
    borderRadius: 8,
    marginRight: 8,
  },
  modeButton: {
    padding: 8,
    borderRadius: 8,
  },
  activeMode: {
    backgroundColor: colors.ui.accent,
  },
  refreshButton: {
    backgroundColor: colors.ui.accent,
    padding: 8,
    borderRadius: 8,
  },
  infoContainer: {
    backgroundColor: colors.ui.cardBackground,
    borderTopWidth: 1,
    borderTopColor: colors.ui.border,
    overflow: "hidden",
  },
  infoHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 12,
    borderBottomWidth: 1,
    borderBottomColor: colors.ui.divider,
  },
  infoHeaderText: {
    fontSize: 16,
    fontWeight: "bold",
    color: colors.brand.greys.black,
  },
  infoText: {
    fontSize: 12,
    color: colors.brand.greys.g737373,
    marginBottom: 2,
    padding: 4,
  },
  infoValue: {
    fontWeight: "bold",
    color: colors.brand.greys.black,
  },
  contentContainer: {
    flex: 1,
    alignSelf: "center",
    backgroundColor: colors.ui.cardBackground,
    borderWidth: 1,
    borderColor: colors.ui.border,
    overflow: "hidden",
  },
  contentScroll: {
    flex: 1,
  },
  contentWrapper: {
    padding: 16,
    position: "relative",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  loadingText: {
    marginTop: 12,
    color: colors.brand.greys.g737373,
    fontSize: 16,
  },
  errorContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  errorText: {
    marginTop: 12,
    color: colors.brand.greys.black,
    fontSize: 18,
    fontWeight: "bold",
  },
  errorSubtext: {
    marginTop: 8,
    color: colors.brand.greys.g737373,
    fontSize: 14,
    textAlign: "center",
  },
  retryButton: {
    marginTop: 16,
    backgroundColor: colors.ui.accent,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 8,
  },
  retryButtonText: {
    color: colors.brand.greys.white,
    fontWeight: "bold",
  },
  accessDenied: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  accessDeniedText: {
    marginTop: 12,
    color: colors.brand.greys.black,
    fontSize: 18,
    fontWeight: "bold",
  },
  accessDeniedSubtext: {
    marginTop: 8,
    color: colors.brand.greys.g737373,
    fontSize: 14,
    textAlign: "center",
  },
});

export default LiveContentPreviewScreen;
