import { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import getPageContent from "../../../api/content/get-page-content";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  selectPatientHeaderExpanded,
  selectPatientHeaderHistoryExpanded,
  setPatientHeaderExpanded,
  setPatientHeaderHistoryExpanded,
  setPatientId,
} from "../../../functions/calculator/actions";
import configureAnimations from "../../../functions/configure-animations";
import {
  selectCurrentResultPage,
  selectScoreData,
} from "../../../functions/current-result/actions";
import getFontStyle from "../../../functions/getFontSize";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import {
  addPageToPatientCreate,
  deletePageFromPatient,
} from "../../../functions/user/functions";
import alert from "../../CustomAlert";
import { formatDate } from "../ResultPageHeaderComponent";

export const PatientDataHeaderComponent = ({ style = {} }) => {
  const [isAddingPage, setIsAddingPage] = useState(false);
  const [isRemovingPage, setIsRemovingPage] = useState(false);

  const patientData = useSelector(selectPatientData);
  const dispatch = useDispatch();

  const expanded = useSelector(selectPatientHeaderExpanded);
  const currentPageContent = useSelector(selectCurrentResultPage);
  const historyExpanded = useSelector(selectPatientHeaderHistoryExpanded);
  const navigation = useNavigation();

  const scoreData = useSelector(selectScoreData);

  const pageIsInData = patientData?.data?.find(
    (page) => page.result_page_id === currentPageContent?._id
  );

  const warningLevel =
    patientData?.weight >= 300 ? 2 : patientData?.weight >= 200 ? 1 : 0;

  const currentFilteredHistoryItems =
    patientData?.data?.filter(
      (item) => item.result_page_id !== currentPageContent?._id
    ) ?? [];
  const currentHistoryItems = patientData?.data ?? [];

  if (!patientData) return null;

  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("AddPatientScreen")}
      style={{
        zIndex: 100000,
        ...styles.container,
        paddingHorizontal: 16,
        borderRadius: 20,
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.12,
        shadowRadius: 4,
        elevation: 2,
        ...style,
      }}
    >
      {/* Header: Name, Weight, etc. */}
      <View style={styles.headerRow}>
        <TouchableOpacity
          hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          onPress={() => {
            configureAnimations();
            dispatch(setPatientHeaderExpanded(!expanded));
          }}
          style={{ marginRight: 12 }}
        >
          <Feather name={expanded ? "chevron-up" : "chevron-down"} size={24} />
        </TouchableOpacity>
        <View style={styles.infoContainer}>
          <View style={styles.nameContainer}>
            <Text style={styles.label}>Kennung:</Text>
            <Text style={styles.value}>{patientData.name}</Text>
          </View>
          <View style={styles.detailsContainer}>
            <View style={styles.detailItem}>
              <Feather name="user" size={16} />
              <Text style={styles.label}>Gewicht:</Text>
              <Text style={styles.value}>{patientData.weight} kg</Text>
            </View>
            {patientData.height && (
              <View style={styles.detailItem}>
                <Feather name="move" size={16} />
                <Text style={styles.label}>Größe:</Text>
                <Text style={styles.value}>{patientData.height} cm</Text>
              </View>
            )}
          </View>
        </View>
        <View style={styles.actionsContainer}>
          <TouchableOpacity
            onPress={() => {
              alert(
                "Patienten entfernen",
                "Die Daten des Patienten werden deaktiviert. Du kannst ihn später aus der Historie wiederherstellen.",
                [
                  {
                    text: "Nein",
                    style: "cancel",
                  },
                  {
                    text: "Entfernen",
                    style: "destructive",
                    onPress: () => {
                      configureAnimations();
                      dispatch(setPatientId(null));
                      dispatch(clearAmount());
                    },
                  },
                ]
              );
            }}
            hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
          >
            <Feather name="x" size={20} />
          </TouchableOpacity>
        </View>
      </View>

      {/* Expanded section */}
      {expanded && (
        <View>
          <WeightWarningLabel
            warningLevel={warningLevel}
            style={{
              marginHorizontal: 20,
              marginTop: 12,
            }}
          />

          <View style={styles.addRemoveRow}>
            {/* --- ADD PAGE BUTTON --- */}
            {!pageIsInData && currentPageContent && (
              <TouchableOpacity
                disabled={isAddingPage || !currentPageContent}
                activeOpacity={0.7}
                onPress={async () => {
                  if (!currentPageContent) return;
                  try {
                    setIsAddingPage(true);
                    const payload = scoreData ? scoreData : {};
                    await addPageToPatientCreate(
                      patientData,
                      currentPageContent._id,
                      [payload]
                    );
                  } catch (error) {
                    console.log("Error adding page:", error);
                  } finally {
                    setIsAddingPage(false);
                  }
                }}
                style={[
                  styles.button,
                  // reduce opacity if disabled
                  (isAddingPage || !currentPageContent) && { opacity: 0.5 },
                ]}
              >
                <Text style={styles.buttonText}>
                  Diese Seite dem Patienten hinzufügen
                </Text>
                <Feather name="plus" size={16} />
              </TouchableOpacity>
            )}

            {/* --- REMOVE PAGE BUTTON --- */}
            {pageIsInData && (
              <View
                style={{ flexDirection: "row", alignItems: "center", gap: 20 }}
              >
                <TouchableOpacity
                  disabled={isRemovingPage || !currentPageContent}
                  activeOpacity={0.7}
                  onPress={async () => {
                    if (!currentPageContent) return;
                    try {
                      setIsRemovingPage(true);
                      await deletePageFromPatient(
                        patientData,
                        currentPageContent._id,
                        pageIsInData
                      );
                    } catch (error) {
                      console.log("Error removing page:", error);
                    } finally {
                      setIsRemovingPage(false);
                    }
                  }}
                  style={[
                    styles.button,
                    { width: 250 },
                    (isRemovingPage || !currentPageContent) && { opacity: 0.5 },
                  ]}
                >
                  <Text style={[styles.buttonText, { flex: 1 }]}>
                    Seite Entfernen
                  </Text>
                  <Feather name="trash" size={16} />
                </TouchableOpacity>
                <View style={{ flex: 1, alignItems: "flex-end" }}>
                  <Feather style={{}} name="check-circle" size={20} />
                </View>
              </View>
            )}
          </View>

          {/* History Items */}
          {historyExpanded && (
            <View style={{ gap: 12, marginTop: 20 }}>
              {currentFilteredHistoryItems?.map((item) => {
                const page = getPageContent(item.result_page_id);
                return (
                  <TouchableOpacity
                    key={item.id}
                    onPress={() =>
                      navigateToNextCMSPage(item.result_page_id, navigation)
                    }
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Text style={styles.historyItemText}>
                        {page.title ?? page.fieldTitle}
                      </Text>
                      <Feather name="arrow-right" size={16} />
                    </View>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}

          {/* Footer Row: History Toggle & Last Modified */}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            {currentHistoryItems.length > 0 && (
              <TouchableOpacity
                disabled={currentFilteredHistoryItems.length === 0}
                style={{ flex: 1, flexDirection: "row", gap: 4 }}
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={() => {
                  configureAnimations();
                  dispatch(setPatientHeaderHistoryExpanded(!historyExpanded));
                }}
              >
                <Text style={styles.historyLabel}>
                  {"Seitenhistorie (" + currentHistoryItems.length + ")"}
                </Text>
                {currentFilteredHistoryItems.length > 0 && (
                  <Feather
                    name={historyExpanded ? "chevron-up" : "chevron-down"}
                    size={16}
                  />
                )}
              </TouchableOpacity>
            )}

            {(patientData.modified_at ?? patientData.created_at) && (
              <View style={{ flex: 1 }}>
                <Text style={styles.dateLabel}>
                  {formatDate(
                    patientData.modified_at ?? patientData.created_at
                  )}
                </Text>
              </View>
            )}
          </View>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    marginBottom: 16,
    backgroundColor: colors.ui.cardBackground,
  },
  headerRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoContainer: {
    flex: 1,
  },
  nameContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
    gap: 4,
  },
  detailsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  detailItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
    marginBottom: 4,
    gap: 4,
  },
  label: {
    fontSize: Sizes.boxText,
    fontFamily: "HostGrotesk-Regular",
  },
  value: {
    fontSize: Sizes.boxText,
    ...getFontStyle(600),
  },
  actionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  addRemoveRow: {
    marginTop: 20,
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    paddingHorizontal: 8,
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
    borderWidth: 0.5,
    padding: 8,
    gap: 8,
  },
  buttonText: {
    textAlign: "center",
    fontSize: Sizes.boxText,
    fontFamily: "HostGrotesk-Regular",
  },
  historyItemText: {
    fontSize: Sizes.boxText - 2,
    flex: 1,
    fontFamily: "HostGrotesk-Regular",
  },
  historyLabel: {
    fontSize: Sizes.boxText - 2,
    fontFamily: "HostGrotesk-Regular",
  },
  dateLabel: {
    alignSelf: "flex-end",
    ...getFontStyle(500, true),
    fontSize: Sizes.boxText - 2,
  },
});

export const WeightWarningLabel = ({ warningLevel, style }) => {
  if (warningLevel === 0) return null;
  return (
    <View
      style={[{ flexDirection: "row", alignItems: "center", gap: 12 }, style]}
    >
      <Feather
        name="alert-triangle"
        size={16}
        color={warningLevel === 1 ? "orange" : "red"}
      />
      <Text
        style={{
          flex: 1,
          fontSize: Sizes.boxText,
          color: warningLevel === 1 ? "orange" : "red",
          fontFamily: "HostGrotesk-Regular",
        }}
      >
        {warningLevel === 1
          ? "Das eingegebene Körpergewicht überschreitet die normale Körpergewichtsgrenze erheblich!"
          : "Das eingegebene Körpergewicht ist unplausibel! Bitte die Eingabe prüfen!"}
      </Text>
    </View>
  );
};
