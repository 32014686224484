import { useMemo, useState } from "react";
import { FlatList, Text, TouchableOpacity, View } from "react-native";
import {
  ScoreElementMultiple,
  ScoreSelectionItem,
} from "../../../api/content/load-all/types";
import { getSharedStylesContent } from "../../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";
import { colors } from "../../../constants/static-colors";
import getFontStyle from "../../../functions/getFontSize";
import getWidth from "../../../hooks/getWidth";

export default function ScoreElementMultipleComponent({
  data,
  setCurrentScore,
  setAnswers,
  answers,
  style = {},
}: {
  readonly data: ScoreElementMultiple;
  readonly setCurrentScore: any;
  readonly setAnswers: any;
  readonly answers: any;
  readonly style?: any;
}) {
  const [width, setWidth] = useState(0);

  /**
   * Utility to remove HTML tags for length checking
   */
  const stripHtml = (str: string) => str.replace(/<[^>]+>/g, "");

  const screenWidth = getWidth();

  const { maxTitleLength, maxItemLength } = useMemo(() => {
    if (screenWidth < 360) {
      // Very small screens, be more strict
      return { maxTitleLength: 20, maxItemLength: 20 };
    } else if (screenWidth < 480) {
      // Small to medium
      return { maxTitleLength: 30, maxItemLength: 20 };
    } else if (screenWidth < 600) {
      // Medium screens, a bit more lenient
      return { maxTitleLength: 100, maxItemLength: 60 };
    } else {
      // Larger screens
      return { maxTitleLength: 150, maxItemLength: 100 };
    }
  }, [screenWidth]);

  /**
   * Decide whether we need "column" layout:
   * - If `data.title` is longer than 40 chars (once HTML is stripped), OR
   * - Any item.title in `scoreSelectionItems` is longer than 40 chars
   */
  const useColumn = useMemo(() => {
    // Check main title
    if (stripHtml(data.title).length > maxTitleLength) {
      return true;
    }
    // Check each item’s title
    return data.scoreSelectionItems.some(
      (it) => stripHtml(it.title).length > maxItemLength
    );
  }, [data]);

  // Current answer for this question, if any
  const currentAnswer = answers.find((element) => element.title === data.title);

  // The main selection logic that was previously in ScoreElementItem
  const handleItemPress = (item: ScoreSelectionItem) => {
    // 1) Update total score
    setCurrentScore((s: number | null) => {
      const oldScore = currentAnswer?.score
        ? parseFloat(currentAnswer.score)
        : 0;
      let newValue = item.value;
      if (typeof newValue === "string") {
        newValue = parseFloat(newValue);
      }
      if (!s) s = 0;
      return s + (newValue || 0) - oldScore;
    });

    // 2) Possibly store an additional interpretation if it exists
    const additionalScoreInterpretation =
      data.additionalScoreInterpretations?.find(
        (_item) => _item.referenceItemTitle === item.title
      );

    // 3) Update the answer in the global answers array
    setAnswers((arr: any[]) => {
      const filtered = arr.filter((element) => element.title !== data.title);
      return [
        ...filtered,
        {
          title: data.title,
          value: item.title,
          score: item.value,
          data: data.scoreSelectionItems,
          additionalScoreInterpretation,
        },
      ];
    });

    // 4) Navigation/scroll logic, if desired
    // (Removed the old "shouldChange" snippet here for brevity;
    // you can reinsert if needed)
  };

  return (
    <View
      key={data.title}
      style={{
        borderRadius: 10,
        backgroundColor: colors.ui.cardBackground,
        borderColor: colors.ui.border,
        borderWidth: 1,
        marginBottom: 20,
        padding: 12,
        gap: 12,
        /** Switch between "row" and "column" based on text length. */
        flexDirection: useColumn ? "column" : "row",
        ...style,
      }}
    >
      {/* 
        Top-left portion with the question’s title (or “header”).
        If you don’t need collapsible logic anymore, you can keep 
        this simple. 
      */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flex: useColumn ? undefined : 1,
        }}
      >
        {/* Left side: Title text */}
        <View style={{ flex: 1 }}>
          <View
            style={{
              maxWidth: "100%",
              alignSelf: width === 0 ? "stretch" : "flex-start",
            }}
            onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
          >
            {width !== 0 && <TextItem data={data} isHeader />}
          </View>
        </View>
      </View>

      {/* The list of selection items.
          We set flex=1 so it can wrap under the title if we're in column mode. */}
      <FlatList
        scrollEnabled={false}
        data={data.scoreSelectionItems}
        style={{
          width: "100%",
          flex: useColumn ? undefined : 1,
        }}
        contentContainerStyle={{ gap: 8 }}
        keyExtractor={(_, i) => String(i)}
        renderItem={({ item }) => {
          const isSelected = currentAnswer?.value === item.title;
          return (
            <TouchableOpacity
              onPress={() => handleItemPress(item)}
              style={{
                paddingHorizontal: 12,
                borderRadius: 6,
                paddingVertical: 8,

                flexDirection: "row",
                borderColor: isSelected ? colors.ui.primary : colors.ui.border,
                borderWidth: 1,
                alignSelf: "stretch",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: !isSelected
                  ? colors.ui.cardBackground
                  : colors.ui.highlight,
              }}
            >
              <ScoreElementItem item={item} />
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
}

/**
 * A simple display-only row for each item, with no onPress
 * (logic is handled above).
 */
const ScoreElementItem = ({ item }: { item: ScoreSelectionItem }) => {
  return (
    <>
      {/* Title */}
      <View style={{ flex: 1, marginRight: 20 }}>
        <TextItem data={item} isHeader={false} />
      </View>

      {/* Numeric value (if any) */}
      {item.value !== null && (
        <Text
          style={{
            ...getFontStyle(700),
            color: colors.ui.textPrimary,
            opacity: 0.75,
            fontSize: 20,
            marginRight: 12,
            marginLeft: 12,
          }}
        >
          {Math.round(item.value * 100) / 100}
        </Text>
      )}
    </>
  );
};

/** Renders the text with BoxTextComponent, adding <p> if needed. */
const TextItem = ({
  data,
  isHeader,
}: {
  data: { title: string };
  isHeader: boolean;
}) => {
  return useMemo(() => {
    const contentStr = isHeader
      ? "<b>" + getText(data.title) + "</b>"
      : getText(data.title);

    return (
      <View style={{ marginTop: -4, marginBottom: 8 }}>
        <BoxTextComponent
          style={getSharedStylesContent(data.title)}
          content={contentStr}
        />
      </View>
    );
  }, [data.title, isHeader]);
};

/** Wrap text in <p> if not already present */
function getText(text: string) {
  return text.includes("<p>") ? text : "<p>" + text + "</p>";
}
