import i18n from "../../../../locales/i18n";
import { queries } from "../../../../static-content/sql-prevention/queries";
import apiCall from "../../../api/api-call";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../../components/Notifications/NotificationComponent";
import { showInlineNotification } from "../../../entry/Root";
import getUUID from "../../../hooks/getUUID";
import { store } from "../../store";
import { setCurrentUser } from "../actions";
import { setLogged, userLogin } from "../functions";

export const validateEmail = (email: string): boolean => {
  const r = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return r !== null;
};

function checkInputs(text: string[]) {
  const ra: boolean[] = [];
  for (const t of text) {
    if (t === undefined) continue;
    ra.push(checkIfHasQuery(t));
  }
  if (ra.includes(false)) return false;
  return true;
}

function checkIfHasQuery(text: string) {
  for (const i of queries) {
    if (text.includes(i)) return false;
  }
  return true;
}

async function newRegisterUser(email, password, user_type) {
  const uuid = getUUID();

  const user = {
    email,
    password,
    user_id: uuid,
    user_type,
    user_source:
      "utm_term=&utm_campaign=03+%7C%C2%A0Video+%7C%C2%A0Awareness&utm_source=social&utm_medium=ppc&hsa_acc=1122334455&hsa_cam=3344556677&hsa_grp=5566778899&hsa_ad=7766554433&hsa_src=c&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=social&hsa_ver=3&gad_source=2&gclid=ABC987654321",
  };
  const response = await apiCall(
    "/api/v3/users",
    user,
    false,
    true,
    "POST",
    true
  );

  if (response?.status === 201) {
    setLogged(true, email, password, "");
    store.dispatch(setCurrentUser(user));
    return { status: true };
  } else {
    // showInlineNotification({
    //   text: response?.message ?? "Ein Fehler ist aufgetreten",
    //   type: InlineNotificationType.ERROR,
    //   direction: InlineNotificationDirection.FROM_BOTTOM,
    // });

    return { status: false };
  }
}

export async function registerCall() {
  const user = store.getState().userReducer.userCreationObject as any;

  if (!allInputsValid(user)) return { status: false };

  const response = await newRegisterUser(
    user.email,
    user.password,
    user.user_type
  );

  if (!response) return { status: false };
  return response;
}

function allInputsValid(user: any) {
  if (
    !checkInputs([
      user.email,
      user.firstname,
      user.lastname,
      user.password,
      user.clinic,
      user.semester,
      user.title,
    ])
  ) {
    showInlineNotification({
      text:
        i18n.locale == "de"
          ? "Du hast einen Text eingegeben, der nicht erlaubt ist. Bitte ändere diesen. Falls das Problem weiter bestehen sollte, kontaktiere uns unter support@mediceo.com"
          : i18n.t("not_allowed_text_try_again"),
      type: InlineNotificationType.ERROR,
      direction: InlineNotificationDirection.FROM_BOTTOM,
    });
    return false;
  }

  return true;
}

export async function sendVerificationMail(email: string) {
  try {
    const response = await apiCall("/backend/send_verify", {
      user: email,
    });
    return response.status === 200;
  } catch {
    return false;
  }
}

export async function addAdditionalUserInformation(userCreationObject) {
  const currentUser = store.getState().userReducer.user as any;
  const user_type = userCreationObject.user_type;
  let body = {};

  if (user_type === "doctor" || user_type === "dev") {
    if (!userCreationObject.position)
      userCreationObject.position = "Assistenzarzt";

    body = {
      firstname: userCreationObject.firstname ?? currentUser.firstname ?? "",
      lastname: userCreationObject.lastname ?? currentUser.lastname ?? "",
      title: userCreationObject.title ?? currentUser.title ?? "",
      position: userCreationObject.position ?? currentUser.position ?? "",
      specialty: userCreationObject.specialty ?? currentUser.specialty ?? "",
      clinic: userCreationObject.clinic ?? currentUser.clinic ?? "",
      latitude: userCreationObject.latitude ?? currentUser.latitude ?? "",
      longitude: userCreationObject.longitude ?? currentUser.longitude ?? "",
      additional_specialty:
        userCreationObject.additional_specialty ??
        currentUser.additional_specialty ??
        "",
    };
  } else if (user_type === "nurse") {
    body = {
      firstname: userCreationObject.firstname ?? currentUser.firstname ?? "",
      lastname: userCreationObject.lastname ?? currentUser.lastname ?? "",
      job_description:
        userCreationObject.job_description ?? currentUser.job_description ?? "",
      clinic: userCreationObject.clinic ?? currentUser.clinic ?? "",
      latitude: userCreationObject.latitude ?? currentUser.latitude ?? "",
      longitude: userCreationObject.longitude ?? currentUser.longitude ?? "",
      position: "",
      specialty: "",
      station: "",
    };
  } else if (user_type === "student") {
    body = {
      firstname: userCreationObject.firstname ?? currentUser.firstname ?? "",
      lastname: userCreationObject.lastname ?? currentUser.lastname ?? "",
      semester: userCreationObject.semester ?? currentUser.semester ?? 0,
      latitude: userCreationObject.latitude ?? currentUser.latitude ?? "",
      longitude: userCreationObject.longitude ?? currentUser.longitude ?? "",
      preferred_specialization:
        userCreationObject.preferred_specialization ??
        currentUser.preferred_specialization ??
        "",
      university: userCreationObject.university ?? currentUser.university ?? "",
      student_type: userCreationObject.student_type ?? currentUser.student_type,
      academic_degree:
        userCreationObject.academic_degree ?? currentUser.academic_degree ?? "",
      progression_level:
        userCreationObject.progression_level ??
        currentUser.progression_level ??
        "",
      progression_unit:
        userCreationObject.progression_unit ??
        currentUser.progression_unit ??
        "",
      job: userCreationObject.job ?? currentUser.job ?? "",
    };
  }

  const response = await apiCall("/api/v4/users", body, false, false, "PUT");

  if (response?.status === 200) {
    await userLogin(currentUser.email, currentUser.password);
    return { status: true };
  } else {
    showInlineNotification({
      text: "Ein Fehler ist aufgetreten",
      type: InlineNotificationType.ERROR,
      direction: InlineNotificationDirection.FROM_BOTTOM,
    });
    return { status: false };
  }
}
