import { Feather } from "@expo/vector-icons";
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import I18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { selectEnterprise } from "../../functions/enterprise/actions";
import getFontStyle from "../../functions/getFontSize";
import isLargerDevice from "../../hooks/isLargerDevice";

const HospitalRulesCard = ({ onPress }) => {
  // You can handle onPress logic here—e.g., navigate somewhere
  const handlePress = () => {
    onPress();
  };

  const currentEnterprise = useSelector(selectEnterprise);

  return (
    <TouchableOpacity style={styles.cardContainer} onPress={handlePress}>
      <ImageBackground
        source={require("../../../assets/images/cards/bg_darmstadt.png")} // or { uri: "https://..." }
        style={styles.bgImage}
        imageStyle={styles.roundedCorners}
      >
        {/* You can overlay text or icons on the background here */}
        <View style={styles.textContainer}>
          <View style={{ flex: 1 }}>
            <Text style={styles.title}>{I18n.t("in_house_content")}</Text>
            <Text style={styles.subTitle}>
              {currentEnterprise.customer_name}
            </Text>
          </View>
          <Feather
            name="chevron-right"
            size={24}
            color={colors.ui.textPrimary}
          />
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: "100%",
    borderRadius: 12,
    overflow: "hidden",
    backgroundColor: colors.ui.cardBackground,

    alignSelf: "center",
    marginVertical: 8,
    flex: 1,
    // Optional shadow for iOS + Android
    shadowColor: colors.ui.textPrimary,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.05,
    shadowRadius: 2,
    elevation: 2,
  },
  bgImage: {
    width: "100%",
    // Adjust this aspect ratio or use a fixed height:
    // height: 200, // for a fixed height
    justifyContent: "center",
    alignItems: "flex-start",
    flex: 1,
  },
  roundedCorners: {
    borderRadius: 12,
  },
  textContainer: {
    margin: isLargerDevice() ? 18 : 12,
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    alignSelf: "stretch",
  },
  title: {
    fontSize: 18,
    ...getFontStyle(700),
    marginBottom: 4,
    color: colors.ui.textPrimary,
  },
  subTitle: {
    fontSize: Sizes.boxText - 1,
    marginBottom: 8,
    fontFamily: "HostGrotesk-Regular",
    color: colors.ui.textPrimary,
  },
});

export default HospitalRulesCard;
