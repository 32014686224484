export default {
  light: {
    text: "#0A0A0A",
    placeholder: "#A3A3A3",

    // A slightly darker background than pure white
    background: "#F2F2F7", // iOS-like light gray
    foreground: "#FFFFFF", // Still pure white for cards

    inputBackground: "#F5F5F5", // Another light gray

    primary: "#FED500",
    accentDark: "#21534D",
    selectedTint: "#FFFAD2",

    secondary: "#21534D",

    gray: "#D4D4D4",
    darkGray: "#737373",
    lightGray: "#E5E5E5",
    veryLightGray: "#F5F5F5",

    blue: "#45b5d8",
    lightBlue: "#d1eef6",
    green: "#339988",
    red: "#FF4040",
    transparent: "transparent",
  },
};

// lightTheme.js

// colors.js

export const colors = {
  //
  // 1. BRAND COLORS
  //
  brand: {
    // --- YELLOWS ---
    yellows: {
      fac401: "#FAC401",
      face00: "#FACE00",
      fed500: "#FED500", // primary brand color
      ffdd00: "#FFDD00",
      ffe410: "#FFE410",
      ffe755: "#FFE755",
      ffe973: "#FFE973",
      ffeb8d: "#FFEB8D",
      fff1a5: "#FFF1A5",
      fff6bc: "#FFF6BC",
      fffad2: "#FFFAD2",
      fffef7: "#FFFEF7",
    },

    // --- GREENS ---
    greens: {
      // Darker tones first
      g081a20: "#081A20",
      g0c2725: "#0C2725",
      g213e31: "#213E31",
      g21534d: "#21534D", // “accentGreen” in older examples
      g22635a: "#22635A",
      g277a6e: "#277A6E",
      g339988: "#339988", // “tealGreen” in older examples
    },

    // --- GREYS & NEUTRALS ---
    greys: {
      black: "#0A0A0A",
      g171717: "#171717",
      g262626: "#262626",
      g404040: "#404040",
      g525252: "#525252",
      g737373: "#737373",
      gA3A3A3: "#A3A3A3",
      gD4D4D4: "#D4D4D4",
      gE5E5E5: "#E5E5E5",
      gF5F5F5: "#F5F5F5",
      gFAFAFA: "#FAFAFA",
      white: "#FFFFFF",
    },
  },

  //
  // 2. SEMANTIC & STATE COLORS
  //
  //   Commonly used for feedback, alerts, disabled states, etc.
  //   These can be brand-derived or standalone.
  //
  semantic: {
    success: "#339988", // uses brand teal
    error: "#FF4040", // bright error red
    warning: "#FFA500", // typical “warning orange”
    info: "#45b5d8", // a light blue for info messages
    focus: "#21534D", // could use a darker green for focus rings
    disabled: "#A3A3A3", // or a lighter grey for disabled elements
  },

  //
  // 3. EVERYDAY UI USAGE
  //
  //   These map more directly to typical UI roles:
  //   - text colors (primary, secondary, placeholder)
  //   - backgrounds (app background, cards, inputs)
  //   - borders, dividers, etc.
  //
  ui: {
    // Text
    textPrimary: "#0A0A0A", // brand.greys.black
    textSecondary: "#737373", // brand.greys.g737373
    textPlaceholder: "#A3A3A3", // brand.greys.gA3A3A3

    // Backgrounds
    background: "#f5f5f5", // iOS-like light gray
    cardBackground: "#fff",
    inputBackground: "#FAFAFA",

    // Brand usage
    primary: "#FED500", // brand yellow
    secondary: "#21534D", // brand dark green
    accent: "#339988", // brand teal
    highlight: "#FFFAD2", // lighter brand yellow for a “selected tint”

    // Borders
    border: "#D4D4D4",
    divider: "#E5E5E5",

    // Transparency
    transparent: "transparent",
  },
};
