import { MaterialIcons } from "@expo/vector-icons";
import { useEffect } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { useSelector } from "react-redux";
import I18n from "../../../locales/i18n";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { formatDate } from "../../components/header/ResultPageHeaderComponent";
import { colors } from "../../constants/static-colors";
import { selectAllContent } from "../../functions/data/actions";
import getFontStyle from "../../functions/getFontSize";
import { isWebAndNotMobile } from "../../hooks/isLargerDevice";

export const SoftChangeLogScreen = () => {
  const allData = useSelector(selectAllContent);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "soft_changelog_screen_opened",
      "SoftChangeLogScreen",
      "UserOpened"
    );
  }, []);

  return (
    <View
      style={{
        backgroundColor: colors.ui.background,
        flex: 1,
        paddingHorizontal: isWebAndNotMobile() ? 32 : 0,
      }}
    >
      <ScrollView contentContainerStyle={{ paddingTop: 12 }}>
        {allData?.version &&
          [...allData.version]
            .sort(
              (a: { timestamp: string }, b: { timestamp: string }) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .map((log: any, index: number) => (
              <ChangeLog
                key={index}
                timestamp={log.timestamp}
                title={log.title}
                versionIdentifier={log.versionIdentifier}
                description={log.body}
                features={log.tags}
              />
            ))}
      </ScrollView>
    </View>
  );
};

const ChangeLog = ({
  timestamp,
  title,
  versionIdentifier,
  features,
  description,
}) => {
  return (
    <View style={{ ...styles.container }}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>

        <Text style={styles.version}>v{versionIdentifier}</Text>
      </View>

      <View style={{ flexDirection: "row" }}>
        <MaterialIcons
          style={{ marginRight: 3 }}
          name="schedule"
          size={14}
          color="#999"
        />
        <Text style={styles.timestamp}>{formatDate(timestamp)}</Text>
      </View>

      <Text style={styles.sectionTitle}>{I18n.t("description")}:</Text>
      <Text style={styles.description}>{description}</Text>

      <Text style={styles.sectionTitle}>{I18n.t("features")}:</Text>
      <View style={styles.featuresContainer}>
        {features.map((feature, index) => (
          <View key={index} style={styles.featureItem}>
            <MaterialIcons
              name="add-circle"
              size={16}
              color="green"
              style={styles.featureIcon}
            />
            <Text style={styles.featureText}>{feature}</Text>
          </View>
        ))}
      </View>
      <View style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
  title: {
    fontSize: 24,
    ...getFontStyle(700),
    flex: 1,
  },
  version: {
    fontSize: 14,
    ...getFontStyle(600),
  },
  timestamp: {
    fontSize: 12,
    color: "#999",
    marginBottom: 12,
    ...getFontStyle(400),
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: 14,
    ...getFontStyle(600),
    marginBottom: 6,
    marginTop: 12,
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
  },
  featuresContainer: {
    marginTop: 8,
  },
  featureItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 4,
  },
  featureIcon: {
    marginRight: 6,
  },
  featureText: {
    fontSize: 14,
    ...getFontStyle(400),
  },
  divider: {
    height: 0.3,
    backgroundColor: "#bbb",
    marginTop: 40,
  },
});
