import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { posthog } from "../api/analytics/posthog";
import CheckBox from "../components/CheckBoxComponent";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../components/Notifications/NotificationComponent";

import staticColors from "../constants/static-colors";
import { showInlineNotification } from "../entry/Root";
import {
  addToUserCreationObject,
  selectCurrentUser,
  selectUserCreationObject,
} from "../functions/user/actions";
import { addAdditionalUserInformation } from "../functions/user/functions/registry";
import getColorScheme from "../hooks/useColorScheme";
import { AdditionalInformationView } from "./login-registry/registry/AdditionalInformation/AdditionalInformationComponent";
import { userLogin } from "../functions/user/functions";
import configureAnimations from "../functions/configure-animations";
import i18n from "../../locales/i18n";

export default function EnterAdditionalUserInformationScreen() {
  const userCreationObject = useSelector(selectUserCreationObject);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const colorScheme = getColorScheme();

  useEffect(() => {
    dispatch(addToUserCreationObject({ ...currentUser }));
  }, []);

  async function continueAction() {
    configureAnimations();
    setLoading(true);
    await addAdditionalUserInformation({ ...userCreationObject });
    await userLogin(userCreationObject.email, userCreationObject.password);
    setLoading(false);
    setIndex(1);
  }

  return (
    <SafeAreaView
      style={{
        margin: 16,
        justifyContent: "flex-start",
        marginTop: 32,
        flex: 1,
      }}
    >
      <ScrollView>
        <Text
          style={{
            color: staticColors[colorScheme].text,
            fontWeight: "500",
            fontSize: 32,
          }}
        >
          {i18n.locale === "de"
            ? "Vervollständige jetzt deine Daten"
            : i18n.t("complete_your_datas")}
        </Text>
        {index === 0 && (
          <AdditionalInformationView
            userCreationObject={userCreationObject}
            content={{}}
            showHint={false}
            continueAction={continueAction}
            loading={loading}
          />
        )}
        {index === 1 && <OtherSoftwareUsed />}
      </ScrollView>
    </SafeAreaView>
  );
}

const softwareOptions = [
  "UpToDate (Wolters Kluwer)",
  "ClinicalKey (Elsevier)",
  "Lexicomp (Wolters Kluwer)",
  "eRef (Thieme)",
  "Perfusorenkochbuch",
  "PediHelp",
];

const OtherSoftwareUsed = () => {
  const navigation = useNavigation();
  const [selected, setSelected] = useState(
    Array(softwareOptions.length).fill(false)
  );

  const colorScheme = getColorScheme();

  const toggleSelect = (index) => {
    setSelected((prevSelected) =>
      prevSelected.map((item, idx) => (idx === index ? !item : item))
    );
  };

  return (
    <View>
      <Text
        style={{
          color: staticColors[colorScheme].text,
          fontSize: 18,
          fontWeight: "500",
          marginTop: 32,
          marginBottom: 20,
        }}
      >
        {i18n.locale == "de"
          ? "Welche Tools verwendest du neben MEDICEO im Alltag?"
          : i18n.t("beside_mediceo")}
      </Text>
      {softwareOptions.map((software, index) => (
        <TouchableOpacity
          key={software}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 12,
          }}
          onPress={() => toggleSelect(index)}
        >
          <CheckBox
            isSelected={selected[index]}
            setSelected={() => toggleSelect(index)}
          />
          <Text
            style={{
              color: staticColors[colorScheme].text,
              marginLeft: 12,
              fontSize: 15,
            }}
          >
            {software}
          </Text>
        </TouchableOpacity>
      ))}
      <DefaultButton
        title="Abschließen"
        action={() => {
          posthog?.capture("user_completed_additional_information", {
            softwareOptions: softwareOptions.filter(
              (_, index) => selected[index]
            ),
          });
          showInlineNotification({
            text:
              i18n.locale == "de"
                ? "Vielen Dank für das Ausfüllen deiner Informationen. Jetzt können wir MEDICEO bestmöglich an dich anpassen."
                : i18n.t("thanks_for_complete_info"),
            type: InlineNotificationType.SUCCESS,
            direction: InlineNotificationDirection.FROM_BOTTOM,
            duration: 5000,
          });
          navigation.goBack();
        }}
        style={{
          width: 200,
          marginTop: 32,
          alignSelf: "center",
        }}
        type={ButtonTypes.Primary}
      />
    </View>
  );
};
