import React from "react";
import { Dimensions, Image, ScrollView, Text, View } from "react-native";
import CustomRenderHTMLComponent from "../../../components/CustomRenderHTMLComponent";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

const { width } = Dimensions.get("window");

const RenderPage = ({ item }) => {
  const mapImage = {
    "Favouriten.png": require("../../../../assets/images/onboardingImages/Favoriten.png"),
    "Notizen.png": require("../../../../assets/images/onboardingImages/Notizen.png"),
    "Notizen-2.png": require("../../../../assets/images/onboardingImages/Notizen-3.png"),
    "Patient anlegen.png": require("../../../../assets/images/onboardingImages/Patient anlegen.png"),
    "Patient anlegen-2.png": require("../../../../assets/images/onboardingImages/Patient anlegen-2.png"),
    "Patient anlegen-3.png": require("../../../../assets/images/onboardingImages/Patient anlegen-3.png"),
    "Patient anlegen-5.png": require("../../../../assets/images/onboardingImages/Patient anlegen-5.png"),
    "Rechner.png": require("../../../../assets/images/onboardingImages/Rechner.png"),
    "Rechner-2.png": require("../../../../assets/images/onboardingImages/Rechner-2.png"),
    "Rechner-3.png": require("../../../../assets/images/onboardingImages/Rechner-3.png"),
    "Rechner-4.png": require("../../../../assets/images/onboardingImages/Rechner-4.png"),
    "Boxes.png": require("../../../../assets/images/onboardingImages/Boxes.png"),
    "Boxes-2.png": require("../../../../assets/images/onboardingImages/Boxes-2.png"),
    "Boxes-3.png": require("../../../../assets/images/onboardingImages/Boxes-3.png"),
    "Boxes-4.png": require("../../../../assets/images/onboardingImages/Boxes-4.png"),
    "SCORES.png": require("../../../../assets/images/onboardingImages/SCORES.png"),
    "SCORES-2.png": require("../../../../assets/images/onboardingImages/SCORES-2.png"),
    "SCORES-3.png": require("../../../../assets/images/onboardingImages/SCORES-3.png"),
    "SCORES-4.png": require("../../../../assets/images/onboardingImages/SCORES-4.png"),
  };

  // Constrain the container on iPad so it doesn't get too wide.
  const containerWidth = isLargerDevice() ? Math.min(width, 700) : width;

  return (
    <ScrollView
      style={{
        flex: 1,
        width: containerWidth,
        alignSelf: "center",
      }}
      showsVerticalScrollIndicator={false}
      nestedScrollEnabled
      contentContainerStyle={{ flexGrow: 1 }}
    >
      {item.image && (
        <View
          style={{
            width: containerWidth,
            //paddingBottom: 20,
            backgroundColor: "white",
            borderBottomLeftRadius: 30,
            borderBottomRightRadius: 30,
            alignSelf: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Image
            source={mapImage[item?.image]}
            style={{
              // Let the image expand to full width of the container
              width: "100%",
              // Use aspectRatio + height: undefined to let "contain" scale properly
              height: undefined,
              aspectRatio: isLargerDevice()
                ? 16 / 9
                : item.isStartPage
                ? 1 / 1
                : 1 / 0.8, // Adjust this if needed
              resizeMode: isLargerDevice() ? "contain" : "cover",
            }}
          />
        </View>
      )}

      <View
        style={{
          marginTop: 24,
          alignItems: "center",
          backgroundColor: colors.brand.greens.g081a20,
          paddingVertical: 6,
          paddingHorizontal: 32,
          borderRadius: 20,
          marginBottom: 20,
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            color: colors.brand.yellows.fac401,
            ...getFontStyle(600),
            fontSize: Sizes.boxText,
          }}
        >
          Feature Guide
        </Text>
      </View>

      <View
        style={{
          paddingHorizontal: Sizes.defaultContainerPadding,
          flex: 1,
          width: containerWidth,
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            fontSize: 24,
            ...getFontStyle(800),
            color: "white",
            textAlign: "left",
            marginBottom: 10,
          }}
        >
          {item.title}
        </Text>
        <View style={{ flex: 1 }}>
          <CustomRenderHTMLComponent
            source={{ html: item.description }}
            tagsStyles={{
              body: {
                color: "white",
                margin: 0,
              },
              p: {
                fontSize: 15,
                lineHeight: 22,
              },
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default RenderPage;
