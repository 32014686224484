import i18n from "../../locales/i18n";
import apiCall from "../api/api-call";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import { getIsOffline } from "../components/search-tab/SearchResults";
import { showInlineNotification } from "../entry/Root";
import { omitUndefinedDeep } from "../screens/ResultPageScreen/components/ExpandableFABMenu";
import { setResultPageCaseContent } from "./current-result/actions";
import { store } from "./store";
import { setCurrentUser } from "./user/actions";
import { updateOfflineUser } from "./user/functions";

export async function updateCase(case_id, case_data, currentUser, note) {
  const body = {
    case_data: omitUndefinedDeep(case_data),
    case_id,
    note,
  };

  let allCases;
  let newCase;

  if (await getIsOffline()) {
    allCases = [...JSON.parse(JSON.stringify(currentUser?.cases ?? []))];
    newCase = allCases.find((_case) => _case.case_id === case_id);
    if (!newCase) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text:
          i18n.locale == "de"
            ? "Etwas ist schief gegangen, bitte versuche es erneut"
            : i18n.t("something_went_wrong"),
      });
      return;
    }
    newCase.case_data = case_data;
    newCase.case_id = case_id;
    newCase.note = note;
    newCase.last_updated = new Date().toISOString();

    allCases = allCases.filter((_case) => _case.case_id !== case_id);
    allCases.push(newCase);
  } else {
    const response = await apiCall(
      "/api/v3/users/case",
      body,
      false,
      false,
      "PUT"
    );
    if (response?.data?.cases) {
      newCase = response.data.cases.find((c) => c.case_id === case_id);
      allCases = response?.data?.cases;
    } else {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text:
          i18n.locale == "de"
            ? "Etwas ist schief gegangen, bitte versuche es erneut"
            : i18n.t("something_went_wrong"),
      });
    }
  }

  const _user = { ...currentUser };
  _user.cases = allCases;
  store.dispatch(setCurrentUser(_user));

  store.dispatch(
    setResultPageCaseContent({
      note: newCase.note,
      last_updated: newCase.last_updated,
      caseData: newCase.case_data,
      caseId: newCase.case_id,
    })
  );
  updateOfflineUser(_user);
}
