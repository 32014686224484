import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../components/Notifications/NotificationComponent";
import { showInlineNotification } from "../entry/Root";
import { store } from "../functions/store";
import { setSessionToken } from "../functions/user/actions";

const IS_LOCAL = false; // !! CHANGE BEFORE LIVE
const URL_DEV = IS_LOCAL
  ? "http://localhost:8004"
  : "https://live.api.medi.ceo"; //"http://0.0.0.0:8004"; //"https://live.api.medi.ceo"; //"http://0.0.0.0:8004";
const URL_PROD = "https://live.api.medi.ceo";

const DEFAULT_HEADERS = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

export default async function apiCall(
  slug: string,
  body?: any,
  isRetry = false,
  noEnc = false,
  method = "POST",
  notify = false
): Promise<any> {
  const sessionToken = store.getState().userReducer.sessionToken;

  const headers: any = { ...DEFAULT_HEADERS };

  if (!noEnc) {
    headers.Authorization = `Bearer ${sessionToken}`;
  }

  const options: RequestInit = {
    method,
    headers,
  };

  if (method !== "GET" && method !== "DELETE" && body) {
    options.body = JSON.stringify(body);
  }

  let response: Response;
  const signal = new AbortController();
  setTimeout(() => signal?.abort(), 10000);
  options.signal = signal.signal;

  try {
    const url = getURL() + slug;

    response = await fetch(url, options);
    if (response.status === 422) {
      await response.json();

      throw new Error();
    }
    if (response.status === 500) throw new Error();
  } catch (error) {
    console.log("ERROR", error);
    if (notify)
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
        text: "Ein Serverfehler ist aufgetreten. Versuche es erneut.",
      });
    return;
  }

  if (shouldRetry(response, noEnc, isRetry)) {
    const user = store.getState().userReducer.user;
    try {
      await loginCall(user.password, user.email);
      return apiCall(slug, body, true, noEnc, method);
    } catch (e) {
      if (notify)
        showInlineNotification({
          type: InlineNotificationType.ERROR,
          text: "Ein Serverfehler ist aufgetreten. Versuche es erneut.",
        });
      return;
    }
  }

  if (!response.ok) {
    const responseText = await response.text();

    let detail = JSON.parse(responseText).detail;
    if (!detail || typeof detail !== "string")
      detail = "Ein unbekannter Fehler ist aufgetreten.";

    if (notify)
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: detail,
      });
    return;
  }

  const data = await response.json();

  return { data, status: response.status };
}

function shouldRetry(
  response: Response,
  noEnc: boolean,
  isRetry: boolean
): boolean {
  return (
    (response.status === 403 || response.status === 401) && !noEnc && !isRetry
  );
}

export async function loginCall(hashedPassword: string, user: string) {
  const endpoint = "/api/v4/auth/login";
  try {
    const signal = new AbortController();
    setTimeout(() => signal?.abort(), 7500);

    const response = await fetch(getURL() + endpoint, {
      method: "POST",
      signal: signal.signal,
      body: JSON.stringify({
        email: user.trim(),
        password: hashedPassword.trim(),
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    // TODO: rethink logic

    if (response.status === 403) {
      return { status: 403 };
    } else if (response.status === 401 || response.status === 500) {
      throw new Error();
    } else if (response.status === 200) {
      const data = await response.json();

      if (data.access_token === undefined) throw new Error();
      store.dispatch(setSessionToken(data.access_token));
      //loadAllData(data?.internal_customer_id);
      return { userData: data };
    } else if (response.status === 422) {
      throw new Error();
    } else if (response.status === 423) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Dein Account wurde noch nicht aktiviert.",
      });
    } else if (response.status === 418) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Deine Registrierungsanfrage wurde abgelehnt.",
      });
    } else {
      throw new Error();
    }
  } catch (e) {
    throw new Error();
  }
}

export function getURL() {
  const useDev = __DEV__ || store.getState().userReducer?.user?.role === "dev";

  if (useDev) return URL_DEV;
  else return URL_PROD;
}
