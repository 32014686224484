import { HTMLElementModel, HTMLContentModel } from "react-native-render-html";
import { useNavigation } from "@react-navigation/native";
import * as cheerio from "cheerio";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RenderHTML from "react-native-render-html";
import { Cell, Table, TableWrapper } from "react-native-table-component";
import { useSelector } from "react-redux";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { selectResultPageSearchQuery } from "../functions/current-result/actions";
import getFontStyle from "../functions/getFontSize";
import { navigateToNextPageByCMSId } from "../functions/navigation/helpers";
import getWidth from "../hooks/getWidth";
import { hyphenateDe } from "../screens/tab-navigator-screens/components/Card";
import { getSharedStylesLargeContent } from "./boxes/SharedStyles";
import PopupModal from "./modals/PopupModal";
import { Portal } from "react-native-paper";

function CustomLiRenderer({ TDefaultRenderer, color, ...props }) {
  const depth = useListDepth();

  const markers = props.tnode?.markers ?? "";
  const isUl = Object.keys(markers)?.[0]?.includes("ul");
  const renderIndex = props.renderIndex + 1;

  let bullet;

  switch (depth) {
    case 2:
      bullet = isUl ? (
        <View
          style={{
            position: "absolute",
            top: 8,
            left: -14,
            width: 8,
            height: 2,
            borderRadius: 5,
            backgroundColor: color,
          }}
        >
          {bullet}
        </View>
      ) : (
        <View
          style={{
            borderRadius: 100,
            backgroundColor: color,
            padding: 2,
            position: "absolute",
            left: -20,
            top: 0,
            aspectRatio: 1,
            minWidth: 20,
            height: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 11,
              ...getFontStyle(700),
              color: colors.ui.textPrimary,
            }}
          >
            {renderIndex}
          </Text>
        </View>
      );
      break;
    case 1:
    default:
      bullet = isUl ? (
        <View
          style={{
            position: "absolute",
            top: 8,
            left: -12,
            width: 5,
            height: 5,
            borderRadius: 5,
            backgroundColor: color,
          }}
        >
          {bullet}
        </View>
      ) : (
        <View
          style={{
            borderRadius: 100,
            backgroundColor: color,
            padding: 2,
            position: "absolute",
            left: -20,
            top: 0,
            aspectRatio: 1,
            width: 20,
            height: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 11,
              ...getFontStyle(700),
              color: "white",
            }}
          >
            {renderIndex}
          </Text>
        </View>
      );
  }
  return (
    <View style={{}}>
      <View style={{ marginLeft: 4 }}>
        {bullet}
        <TDefaultRenderer {...props} />
      </View>
    </View>
  );
}

// Add this new renderer function to your file
function InfoIconRenderer({ TDefaultRenderer, ...props }) {
  const { title, description } = props.tnode?.attributes || {};

  const [open, setOpen] = useState(false);

  const showInfoPopup = () => {
    setOpen(true);
  };

  return (
    <>
      <Portal>
        <PopupModal
          title={title || "Information"}
          description={description || ""}
          open={open}
          onClose={() => setOpen(false)}
        />
      </Portal>
      <TouchableOpacity
        onPress={showInfoPopup}
        hitSlop={{
          top: 10,
          bottom: 10,
          left: 10,
          right: 10,
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 14,
          height: 14,
          borderRadius: 9,
          marginTop: -1,
          backgroundColor: colors.ui.accent,
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 9,
            fontWeight: "800",
            textAlign: "center",
          }}
        >
          i
        </Text>
      </TouchableOpacity>
    </>
  );
}

function createRenderers(color, noBulletStyles) {
  const renderers = {
    table: CustomTableRenderer,
    li: (props) => (
      <CustomLiRenderer color={noBulletStyles ? "gray" : color} {...props} />
    ),
    ul: CustomListRenderer,
    ol: CustomListRenderer,
    "info-icon": InfoIconRenderer,
  };

  return renderers;
}

/*
 * Diese Funktion soll die Tabellen Inhalte der einzelnen Zellen extrahieren
 * um anschließend in der eigenen
 */
function parseTableData(html) {
  const $ = cheerio.load(html);
  const rows: any = [];

  const firstRow = $("table tbody tr").first();
  const firstRowCells = firstRow.find("td");

  // Falls die erste Zeile weniger als 3 Spalten hat, breche die Verarbeitung ab

  $("table tbody tr").each((rowIndex, row) => {
    const cells: any = [];
    $(row)
      .find("td, th")
      .each((cellIndex, cell) => {
        cells.push($(cell).html());
      });
    rows.push(cells);
  });

  return rows;
}

function serializeDomNode(domNode) {
  if (!domNode) return "";

  const { name, attribs = {}, children = [] } = domNode;

  // Opening tag with attributes
  let html = `<${name}`;
  for (const [key, value] of Object.entries(attribs)) {
    html += ` ${key}="${value}"`;
  }
  html += ">";

  // Recursively add children
  for (const child of children) {
    if (child.type === "text") {
      html += child.data || ""; // text node
    } else if (child.type === "tag") {
      html += serializeDomNode(child); // nested tag
    }
  }

  // Closing tag
  html += `</${name}>`;

  return html;
}

function CustomTableRenderer({ ...props }) {
  const html = serializeDomNode(props.tnode?.init?.domNode);

  return (
    <View>
      <CustomTableComponent html={html} />
    </View>
  );
}

function addHyphensToHeader(header) {
  return header.replace(/(\S{12})/g, "$1&shy;");
}

// TODO: improve styling for headerless tables

function CustomTableComponent({ html }) {
  const tableData: any = parseTableData(html);

  if (tableData?.length === 0) {
    return null;
  }

  const width = getWidth();
  const tableHead = tableData[0];
  const tableBody = tableData.slice(1);

  const [formattedHead, setFormattedHead] = useState(tableHead);

  useEffect(() => {
    setFormattedHead(tableHead.map((text) => addHyphensToHeader(text)));
  }, [html]);

  // Prüfen, ob die Tabelle mindestens 3 Spalten hat
  const shouldTranspose = tableHead.length >= 3;

  const transformedData = shouldTranspose
    ? formattedHead.map((header, headerIndex) => [
        header,
        ...tableBody.map((row) => row[headerIndex] || ""),
      ])
    : tableData; // Falls weniger als 3 Spalten, bleibt die Tabelle normal

  return (
    <ScrollView horizontal contentContainerStyle={styles.container}>
      <Table
        borderStyle={{
          borderWidth: 1,
          borderColor: colors.ui.border,
        }}
      >
        {transformedData.map((rowData, index) => (
          <TableWrapper key={index} style={styles.row}>
            {rowData.map((cellData, cellIndex) => (
              <Cell
                key={cellIndex}
                data={
                  <RenderHTML
                    contentWidth={width}
                    systemFonts={["HostGrotesk-Regular"]}
                    source={{ html: `${cellData}` }}
                    tagsStyles={
                      {
                        div: { width: 180 },
                        ...getSharedStylesLargeContent(),
                        body: {
                          ...getSharedStylesLargeContent().body,
                          fontFamily: "HostGrotesk-Regular",
                        },
                      } as any
                    }
                    renderers={createRenderers(colors.ui.primary, false)}
                  />
                }
                style={cellIndex === 0 ? styles.headerCell : styles.dataCell}
                textStyle={cellIndex === 0 ? styles.headText : styles.dataText}
              />
            ))}
          </TableWrapper>
        ))}
      </Table>
    </ScrollView>
  );
}

const ListDepthContext = createContext(0);

function useListDepth() {
  return useContext(ListDepthContext);
}

function CustomListRenderer({ TDefaultRenderer, ...props }) {
  const depth = useListDepth();
  return (
    <ListDepthContext.Provider value={depth + 1}>
      <TDefaultRenderer {...props} style={{ ...props.style, marginTop: 8 }} />
    </ListDepthContext.Provider>
  );
}

export default function CustomRenderHTMLComponent({
  tagsStyles,
  source,
  contentWidth,
  color = colors.ui.primary,
  noBulletStyles = false,
}: {
  tagsStyles: any;
  source: any;
  contentWidth?: number;
  color?: string;
  noBulletStyles?: boolean;
}) {
  let navigation;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    navigation = useNavigation();
  } catch {
    navigation = null;
  }

  const [htmlSource, setHtmlSource] = useState(source.html);
  const [sourceCache, setSourceCache] = useState(source.html);
  const searchQuery = useSelector(selectResultPageSearchQuery);

  useEffect(() => {
    //setHtmlSource(source.html);
    addHyphens();
  }, [source]);

  useEffect(() => {
    const highlightHtmlContent = (html, query) => {
      if (!query || query.length < 3) return html; // Fallback to original HTML if query is too short
      const regex = new RegExp(`(${query})`, "gi");
      return html.replace(regex, "<mark>$1</mark>");
    };

    const updatedHtml = highlightHtmlContent(source.html, searchQuery);

    setHtmlSource(updatedHtml); // Always update HTML source to trigger re-render
  }, [searchQuery, source.html]); // Ensure useEffect runs anytime these values change

  async function modifyContentUsingRegex(html) {
    // Regular expression to match content inside <p> and <b> tags
    const regex = /<(p|b)>([^<]+)<\/(p|b)>/g;

    let match;
    let newHtml = html;

    while ((match = regex.exec(html)) !== null) {
      // match[2] contains the text between the tags
      const originalText = match[2];

      const modifiedText = await hyphenateDe(originalText);

      // Replace the matched content with modified content
      newHtml = newHtml.replace(`>${originalText}<`, `>${modifiedText}<`);
    }

    return newHtml;
  }

  async function addHyphens() {
    if (sourceCache !== source.html) {
      setSourceCache(source.html);
      if (source.html.includes("table")) {
        const _source = await modifyContentUsingRegex(source.html);
        setHtmlSource(_source);
      }
    }
  }

  function interceptClicks(_, href: any) {
    href = href.replace(/<[^>]*>/g, "");

    if (
      !href.includes("inline:") &&
      !href.includes("inline_info:") &&
      !href.includes("about:///")
    ) {
      navigation.navigate("WebBrowserScreen", { url: href });
      return;
    }

    const targetInfo = href.includes("inline_info:");

    href = href.replace("inline:", "");
    href = href.replace("inline_info:", "");
    href = href.replace("about:///", "");

    navigateToNextPageByCMSId(navigation, href.trim(), "", targetInfo);
  }

  const dynamicRenderers = createRenderers(color, noBulletStyles);

  return useMemo(
    () => (
      <RenderHTML
        tagsStyles={{
          ...tagsStyles,
          mark: { backgroundColor: colors.ui.secondary },
          body: {
            ...tagsStyles.body,
            fontFamily: "HostGrotesk-Regular",
          },
        }}
        systemFonts={["HostGrotesk-Regular"]}
        source={{ html: htmlSource }}
        contentWidth={contentWidth ?? Sizes.containerWidth}
        renderersProps={{
          a: {
            onPress: interceptClicks,
          },
          li: {
            color: colors.ui.primary,
          },
        }}
        renderers={dynamicRenderers}
        customHTMLElementModels={customHTMLElementModels}
      />
    ),
    [htmlSource, tagsStyles]
  );
}

// Define the custom element model for info-icon
const infoIconModel = HTMLElementModel.fromCustomModel({
  tagName: "info-icon",
  contentModel: HTMLContentModel.mixed, // Change to inline
  isVoid: true,
});

// Create a map of custom element models

// Create a map of custom element models
const customHTMLElementModels = {
  "info-icon": infoIconModel,
};

const screenWidth = getWidth();

const styles = StyleSheet.create({
  container: { padding: 12, paddingRight: 5 },
  headerCell: {
    width: screenWidth * 0.3,
    backgroundColor: colors.brand.greys.gE5E5E5,
    padding: 8,
  },
  headText: { ...getFontStyle(700), textAlign: "left" },
  row: { flexDirection: "row", backgroundColor: colors.ui.cardBackground },

  dataCell: {
    width: screenWidth * 0.45,
    padding: 8,
  },
  dataText: {
    textAlign: "left",
    fontSize: Sizes.boxText,
  },
});
