import { Feather } from "@expo/vector-icons";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { Suspense, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator as RNActivityIndicator,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { ActivityIndicator, DefaultTheme } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";
import ResultPageSkeleton from "../../components/ResultPageSkeleton/ResultPageSkeleton";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { HintBox } from "../../components/boxes/box-types/hint-box/HintBox";

import { getSharedStylesLargeContent } from "../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../components/custom-box-text/CustomBoxTextComponent";
import CustomResultComponentSorter from "../../components/result/CustomResultComponents/CustomResultComponentSorter";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { SourcesView } from "../../components/result/SourcesView/SourcesView";
import TitleText from "../../components/result/TitleText";
import { default as Colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import {
  selectExportList,
  selectIsExporting,
  setIsExporting,
} from "../../functions/current-result/actions";
import { navigateToNextCMSPage } from "../../functions/navigation/helpers";
import { selectCurrentUser } from "../../functions/user/actions";
import { Note } from "../../functions/user/types";
import getColorScheme from "../../hooks/useColorScheme";

// const ResultPageSkeleton = lazy(
//   () => import("../../components/ResultPageSkeleton/ResultPageSkeleton")
// );import { selectCurrentUser } from '../../functions/user/actions';

export function isResultPageWithSkeleton(type) {
  return (
    type === "erkrankungResultPageType" ||
    type === "interventionResultPageType" ||
    type === "drugResultPageType" ||
    type === "fastTrackResultPageType"
  );
}

export default function ResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const isExporting = useSelector(selectIsExporting);
  const exportList = useSelector(selectExportList);

  const [exportNameModalVisible, setExportNameModalVisible] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();

  const insets = useSafeAreaInsets();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (pageContent) {
      setLoading(false);
    }
  }, [pageContent]);

  // return null;

  return (
    <ResultPagesWrapper
      navigation={navigation}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          screen: "ResultPageScreen",
          action: "result_page_opened",
          id: pageContent?._id,
          values: pageContent?.title,
        },
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          flex: 1,
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: Colors[colorScheme].background,
            height: 12,
            marginTop: -12,
            zIndex: 50,
            elevation: 2,
          }}
        />
        {loading ? (
          <View
            style={{
              padding: 20,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RNActivityIndicator />
          </View>
        ) : (
          <ResultPageContentWrapper
            pageContent={pageContent}
            setWarningModalVisible={setWarningModalVisible}
          />
        )}
        {isExporting && (
          <View
            style={{
              backgroundColor: Colors[colorScheme].foreground,
              width: "100%",
              marginBottom: -50,
              padding: 20,
              paddingBottom: 62 + insets.bottom,
              borderTopColor: Colors[colorScheme].accentDark,
              elevation: 2,
              shadowColor: Colors[colorScheme].text,
              shadowOffset: {
                width: 0,
                height: -5,
              },
              shadowOpacity: 0.08,
              shadowRadius: 4,
              borderTopWidth: 1,
              gap: 12,
            }}
          >
            <Text
              style={{
                fontSize: Sizes.boxText,
                color: Colors[colorScheme].text,
              }}
            >
              Wähle die Boxen aus, die der Export beinhalten soll.
            </Text>
            <View
              style={{
                gap: 20,
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <DefaultButton
                style={{ flex: 1 }}
                title={"Export starten (" + exportList.length + ")"}
                action={async () => {
                  setExportNameModalVisible(true);
                }}
                type={ButtonTypes.Primary}
              />
              <TouchableOpacity
                onPress={() => {
                  dispatch(setIsExporting(false));
                }}
                style={{
                  padding: 8,
                  backgroundColor: Colors[colorScheme].red,
                  borderRadius: 50,
                }}
              >
                <Feather name="x" color="white" size={20} />
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </ResultPagesWrapper>
  );
}

export const ResultPageContentWrapper = ({
  pageContent,
  setWarningModalVisible,
}) => {
  const scrollViewRef = useRef<any>();

  const [scrollY] = useState(0);
  const scrollYRef = useRef(scrollY);
  useEffect(() => {
    scrollYRef.current = scrollY;
  }, [scrollY]);

  const navigation = useNavigation();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (isResultPageWithSkeleton(pageContent.type)) {
        setTimeout(() => {
          scrollViewRef.current?.scrollTo({
            y: scrollYRef.current + 1,
            animated: true,
          });
        }, 150);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <View
      style={{
        width: "100%",
        padding: Sizes.defaultContainerPadding,
      }}
    >
      <ResultPageUserInteractionsHeader
        {...{ pageContent, setWarningModalVisible }}
      />
      <View
        style={{
          width: "100%",
          minHeight: "85%",
          justifyContent: "flex-start",
        }}
      >
        <Suspense
          fallback={
            <View
              style={{
                flex: 1,
                backgroundColor: "red",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: Colors["light"].accent,
                  },
                }}
                size="small"
              />
            </View>
          }
        >
          <UserNoteView pageContent={pageContent} navigation={navigation} />
          <ResultPageContentView pageContent={pageContent} scrollY={scrollY} />
        </Suspense>
      </View>
    </View>
  );
};

const UserNoteView = ({ pageContent, navigation }) => {
  const currentUser = useSelector(selectCurrentUser);

  const note = currentUser.notes?.find(
    (note: Note) =>
      note.id === pageContent._id || note.id === pageContent.legacy_id
  );

  if (note?.showInResult && note.note !== "") {
    return (
      <TouchableOpacity
        style={{ marginBottom: -12 }}
        key={"NOTES"}
        onPress={() =>
          navigation.navigate("CreateNoteScreen", {
            note,
            currentDisplayID: pageContent._id,
          })
        }
      >
        <View
          style={{
            pointerEvents: "none",
          }}
        >
          <HintBox
            title={note.note}
            expandableContent={undefined}
            inlineImage={undefined}
            isNote={true}
          />
        </View>
      </TouchableOpacity>
    );
  }

  return null;
};

export const ResultPageContentView = ({ pageContent, scrollY }) => {
  const detailPage = pageContent["detailPage:ResultOrNodeId"];

  return (
    <View style={{ flex: 1 }}>
      {isResultPageWithSkeleton(pageContent.type) ||
      JSON.stringify(pageContent).includes("multiContentSectionType") ? (
        <Suspense
          fallback={
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ActivityIndicator
                theme={{
                  colors: {
                    ...DefaultTheme.colors,
                    primary: Colors["light"].accent,
                  },
                }}
                size="small"
              />
            </View>
          }
        >
          <ResultPageSkeleton data={pageContent} scrollY={scrollY} />
        </Suspense>
      ) : (
        <ResultBoxes {...{ pageContent }} />
      )}
      {pageContent.customPageContent && (
        <CustomResultComponentSorter pageContent={pageContent} />
      )}
      {detailPage && (
        <DetailBox
          title={"<b>Weitere Informationen</b> zu " + pageContent.title}
          id={detailPage}
        />
      )}
      <SourcesView {...{ pageContent }} />
    </View>
  );
};

const DetailBox = ({ title, id }) => {
  const navigation = useNavigation();

  return (
    <View
      style={{
        marginTop: 20,
        width: "100%",
        backgroundColor: Colors.light.foreground,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigateToNextCMSPage(id, navigation);
        }}
        activeOpacity={0.7}
      >
        <View
          style={{
            borderRadius: 10,
            overflow: "hidden",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <View
            style={{
              borderRadius: 100,
              overflow: "hidden",
              marginRight: 12,
              aspectRatio: 1,
              height: 32,
              marginLeft: 16,
              alignItems: "center",
              backgroundColor: Colors.light.lightGray,
              justifyContent: "center",
            }}
          >
            <Ionicons
              name="glasses-outline"
              size={20}
              style={{ marginLeft: 2, marginTop: 1 }}
            />
          </View>
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingVertical: 16,
                paddingRight: 16,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <BoxTextComponent
                  content={"<p>" + title + "</p>"}
                  style={getSharedStylesLargeContent("light")}
                />
              </View>

              <Feather
                name="chevron-right"
                size={24}
                style={{ marginLeft: 12 }}
                color={Colors["light"].text}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const RatingView = () => {
  const colorScheme = getColorScheme();
  async function sendFeedback(isPositive) {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "result_page_rated" + (isPositive ? "_positive" : "_negative"),
      "ResultPageScreen"
    );
    setTimeout(() => {
      showInlineNotification({
        text: "Feedback gesendet. Vielen Dank!",
        type: InlineNotificationType.SUCCESS,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }, 250);
  }

  return (
    <View style={{ flexDirection: "row", marginTop: 32, opacity: 0.45 }}>
      <TouchableOpacity
        onPress={() => sendFeedback(false)}
        style={{
          flex: 1,
          borderColor: Colors[colorScheme].red,
          padding: 8,
          borderRadius: 12,
          borderWidth: 2,
          marginRight: 12,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Feather name="thumbs-down" size={32} color={Colors[colorScheme].red} />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => sendFeedback(true)}
        style={{
          flex: 1,
          padding: 8,
          borderWidth: 2,
          borderRadius: 12,
          marginLeft: 12,
          borderColor: Colors[colorScheme].green,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Feather name="thumbs-up" size={32} color={Colors[colorScheme].green} />
      </TouchableOpacity>
    </View>
  );
};

export const ResultPageUserInteractionsHeader = ({
  pageContent,
  setWarningModalVisible,
}) => {
  const colorScheme = getColorScheme();
  if (!pageContent) return null;
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          marginTop: 12,
          alignSelf: "stretch",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <TitleText
          style={{
            marginRight: 12,
            flex: 1,
          }}
          title={pageContent.title ?? pageContent.fieldTitle}
        />
        <View style={{ gap: 12, flexDirection: "row" }}>
          <TouchableOpacity
            style={{ marginLeft: 0 }}
            onPress={() => setWarningModalVisible((m) => !m)}
          >
            <Ionicons
              name="warning-outline"
              size={24}
              color={Colors[colorScheme].text}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
