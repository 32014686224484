import Slider from "@react-native-community/slider";
import { useState } from "react";
import { Platform, Text, View } from "react-native";
import RNRestart from "react-native-restart";

import AsyncStorage from "@react-native-async-storage/async-storage";
import I18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import getFontStyle from "../../functions/getFontSize";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";
// import other necessary components and styles

export default function ChangeFontSizeModal({ open, onClose }) {
  const [fontSize, setFontSize] = useState(Sizes.boxText); // Assuming 16 is the default font size

  const handleSliderChange = (value) => {
    setFontSize(value);
  };

  // Replace this with your actual font size change logic
  const applyFontSize = async () => {
    await AsyncStorage.setItem("fontSize", fontSize.toString());
    if (Platform.OS === "web") {
      window.location.reload();
    } else {
      RNRestart.Restart();
    }

    onClose();
  };

  return (
    <CustomModal open={open} onClose={onClose}>
      <View style={{}}>
        <Text
          style={{
            marginBottom: 20,
            fontSize: 24,
            ...getFontStyle(500),
            color: colors.ui.textPrimary,
          }}
        >
          {I18n.t("font_size")} {fontSize}
        </Text>
        <Text
          style={{
            fontSize: fontSize + 1,
            color: colors.ui.textPrimary,
            fontFamily: "HostGrotesk-Regular",
          }}
        >
          {I18n.t("size_of_this_text_will_change")}
        </Text>
        <Slider
          style={{ width: "100%", height: 40, marginVertical: 20 }}
          minimumValue={10}
          maximumValue={20}
          step={1}
          value={fontSize}
          onValueChange={handleSliderChange}
          minimumTrackTintColor={colors.ui.primary}
          maximumTrackTintColor="#d3d3d3"
          thumbTintColor={colors.ui.primary}
        />
        <DefaultButton
          title="Anwenden"
          action={applyFontSize}
          type={ButtonTypes.Primary}
          // other necessary props
        />
      </View>
    </CustomModal>
  );
}
