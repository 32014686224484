import { Text, View } from "react-native";

import { useEffect } from "react";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { getSharedStylesLargeContent } from "../boxes/SharedStyles";
import { BoxTextComponent } from "../custom-box-text/CustomBoxTextComponent";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";

export default function PopupModal({ open, onClose, title, description }) {
  useEffect(() => {
    if (open)
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "contraindication_hint_modal",
        "PopupModal",
        "presented"
      );
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
            color: colors.ui.textPrimary,
          }}
        >
          {title}
        </Text>

        <BoxTextComponent
          color={"grey"}
          style={getSharedStylesLargeContent()}
          content={description}
        />

        <DefaultButton
          action={onClose}
          style={{ marginTop: 20 }}
          title={"Schließen"}
          type={ButtonTypes.Primary}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
