import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { default as LottieView } from "lottie-react-native";
import { useEffect, useRef, useState } from "react";
import {
  AppState,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { openInbox } from "react-native-email-link";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../assets/images/logo.png";
import logo_white from "../../../assets/images/logo_weiß.png";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";

import ProgressBar from "../../components/registry/ProgressBar";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import configureAnimations from "../../functions/configure-animations";
import { setShowNavbar } from "../../functions/navigation/actions";
import { logout } from "../../functions/profile/functions";
import {
  selectCurrentUser,
  selectUserCreationObject,
} from "../../functions/user/actions";
import { setLogged, userLogin } from "../../functions/user/functions";
import { sendVerificationMail } from "../../functions/user/functions/registry";
import getColorScheme from "../../hooks/useColorScheme";

export function WaitingForRegistrationScreen({ navigation, route }) {
  const isNonMedicalUser = route.params?.isNonMedicalUser;
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);
  const currentUser = useSelector(selectCurrentUser);
  const userCreationObject = useSelector(selectUserCreationObject);

  const [loading, setLoading] = useState(false);
  const [didLoad, setDidLoad] = useState(false);
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const lottieRef = useRef<any>();

  const email = userCreationObject.email ?? currentUser.email;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowNavbar(false));
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "waiting_for_registration_screen_opened",
      "WaitingForUserInteractionScreen",
      "UserOpened"
    );
    const appStateListener = AppState.addEventListener(
      "change",
      (nextAppState) => {
        if (nextAppState === "active") {
          checkIsVerified();
        }
      }
    );
    return () => {
      appStateListener.remove();
    };
  }, []);

  async function checkIsVerified() {
    setLoading(true);
    const data = await userLogin(currentUser.email, currentUser.password);

    if (data.userData?.status !== "active") {
      setLoading(false);
      showInlineNotification({
        text: "E-Mail noch nicht bestätigt.",
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
      return false;
    }
    if (Platform.OS !== "web")
      RNReactNativeHapticFeedback.trigger("notificationSuccess");
    setLogged(true, currentUser.email, currentUser.password, "null");

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "user_verified",
      "WaitingForUserInteractionScreen",
      "UserIsVerified"
    );
    AnalyticsHandler.getInstance().logLogin();
    configureAnimations();
    setDidLoad(true);

    if (Platform.OS === "web") {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "TutorialScreen",
          },
        ],
      });
    } else {
      lottieRef?.current?.play();
    }
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors[colorScheme].background,
        marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <View
        style={{
          flex: 1,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ProgressBar
          style={{ position: "absolute", top: 20 }}
          currentStep={3}
          subSteps={3}
          currentSubStep={0}
        />
        <TopComponent lottieRef={lottieRef} />
        {!didLoad && (
          <View style={{ alignItems: "center" }}>
            <Text style={styles.thankYouText}>VIELEN DANK</Text>
            <Text style={styles.registryText}>FÜR DIE REGISTRIERUNG</Text>
            {!isNonMedicalUser ? (
              <Text style={styles.confirmText}>
                Bitte bestätige über deine E-Mail
                <Text style={{ fontWeight: "bold" }}> {email} </Text>
                deine Registrierung.{"\n"}Tipp: Überprüfe auch deinen
                Spam-Ordner.
              </Text>
            ) : (
              <Text style={styles.confirmText}>
                Das MEDICEO-Team überprüft deine Anfrage. Du erhältst eine
                E-Mail, sobald dein Account zugelassen ist.
              </Text>
            )}
          </View>
        )}
        {!didLoad && (
          <View style={styles.buttonContainer}>
            <View
              style={{
                width: "100%",
                alignSelf: "center",
                alignItems: "center",
                justifyContent: "center",
                flex: 1,
              }}
            >
              {!isNonMedicalUser && (
                <DefaultButton
                  type={ButtonTypes.Primary}
                  title="E-Mails öffnen"
                  icon={<Ionicons name="mail" size={24} />}
                  action={() => {
                    openInbox();
                  }}
                />
              )}

              <DefaultButton
                action={checkIsVerified}
                style={{ marginTop: 12 }}
                type={ButtonTypes.Outline}
                title="Verifizierung überprüfen"
                loading={loading}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                alignSelf: "stretch",
                marginVertical: 20,
                justifyContent: "space-around",
              }}
            >
              {!isNonMedicalUser && (
                <TouchableOpacity
                  disabled={loadingResendEmail}
                  style={{ opacity: loadingResendEmail ? 0.3 : 1 }}
                  onPress={async () => {
                    setLoadingResendEmail(true);
                    const success = await sendVerificationMail(email);
                    setLoadingResendEmail(false);
                    if (success) {
                      showInlineNotification({
                        text: "E-Mail erneut versendet.",
                        type: InlineNotificationType.SUCCESS,
                        direction: InlineNotificationDirection.FROM_BOTTOM,
                      });
                    } else {
                      showInlineNotification({
                        text: "Das Senden der E-Mail ist fehlgeschlagen. Bitte probiere es erneut. Sollte das Problem weiterhin bestehen, kontaktiere support@mediceo.com.",
                        type: InlineNotificationType.ERROR,
                        direction: InlineNotificationDirection.FROM_BOTTOM,
                      });
                    }
                  }}
                >
                  <Text
                    style={{
                      color: Colors[colorScheme].text,
                      fontSize: 12,
                      textDecorationLine: "underline",
                    }}
                  >
                    E-Mail erneut senden
                  </Text>
                </TouchableOpacity>
              )}

              <TouchableOpacity
                onPress={() => {
                  logout(currentUser, navigation);
                }}
                style={{}}
              >
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                    fontSize: 12,
                    textDecorationLine: "underline",
                  }}
                >
                  Zurück zum Login
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
}

const TopComponent = ({ lottieRef }) => {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  if (Platform.OS === "web") {
    return (
      <Image
        source={colorScheme === "light" ? logo : logo_white}
        style={{
          height: 200,
          width: "90%",
          resizeMode: "contain",
          marginTop: 200,
          ...Platform.select({ web: { height: "20vh" as any } }),
        }}
      />
    );
  }
  return (
    <View
      style={{
        height: 250,
        alignItems: "center",
        flex: 1,
        justifyContent: "center",
      }}
    >
      <LottieView
        ref={lottieRef}
        style={{
          height: 250,
          alignSelf: "center",
        }}
        source={require("../../../assets/animations/mail.json")}
        autoPlay={false}
        loop={false}
        onAnimationFinish={() => {
          setTimeout(() => {
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: "TutorialScreen",
                },
              ],
            });
          }, 250);
        }}
      />
    </View>
  );
};

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    thankYouText: {
      fontSize: 36,
      color: Colors[scheme].text,
      fontWeight: "700",
      letterSpacing: 0.05,
    },
    registryText: {
      fontSize: 18,
      color: Colors[scheme].text,
      fontWeight: "700",
    },
    confirmText: {
      fontSize: 12,
      color: Colors[scheme].text,
      fontWeight: "400",
      marginTop: 38,
      textAlign: "center",
      paddingLeft: 32,
      paddingRight: 32,
    },
    buttonContainer: {
      width: "85%",
      maxWidth: Sizes.containerWidth,
      flex: 1,
      alignItems: "center",
    },
  });
  return styles;
};
