import { Platform, TextStyle } from "react-native";

const getFontStyle = (weight, isItalic = false): TextStyle => {
  if (Platform.OS === "android") {
    const fontMapping = {
      100: isItalic ? "HostGrotesk-LightItalic" : "HostGrotesk-Light",
      200: isItalic ? "HostGrotesk-LightItalic" : "HostGrotesk-Light",
      300: isItalic ? "HostGrotesk-LightItalic" : "HostGrotesk-Light",
      400: isItalic ? "HostGrotesk-Regular" : "HostGrotesk-Regular",
      500: isItalic ? "HostGrotesk-MediumItalic" : "HostGrotesk-Medium",
      600: isItalic ? "HostGrotesk-SemiBoldItalic" : "HostGrotesk-SemiBold",
      700: isItalic ? "HostGrotesk-BoldItalic" : "HostGrotesk-Bold",
      800: isItalic ? "HostGrotesk-ExtraBoldItalic" : "HostGrotesk-ExtraBold",
      900: isItalic ? "HostGrotesk-ExtraBoldItalic" : "HostGrotesk-ExtraBold",
    };

    return { fontFamily: fontMapping[weight] || "HostGrotesk-Regular" };
  }

  // iOS can use fontWeight and fontStyle normally
  return { fontWeight: weight, fontStyle: isItalic ? "italic" : "normal" };
};

export default getFontStyle;
