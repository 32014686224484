import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Platform, Share, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  selectPatientData,
  setCalculationVar,
} from "../../../functions/calculator/actions";

import { Feather } from "@expo/vector-icons";
import { StatusBar } from "expo-status-bar";
import ControlledScrollView from "../../../components/ControlledScrollView";
import DefaultHeader from "../../../components/header/DefaultHeaderComponents";
import HospitalRulesCard from "../../../components/partnerships/DarmstadtCard";
import SearchSection from "../../../components/partnerships/SearchSection";
import getFontStyle from "../../../functions/getFontSize";
import { setCurrentTabSelection } from "../../../functions/navigation/actions";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import useHasInternalContent from "../../../hooks/useHasInternalContent";
import useScrollTopMargin from "../../../hooks/useScrollTopMargin";
import useShowContentPartner from "../../../hooks/useShowContentPartner";
import { SearchBar } from "../components/SearchBar";
import ContentListComponent from "./components/ContentListComponent";

export default function SearchScreen() {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const showContentPartner = useShowContentPartner();

  const paddingTop = useScrollTopMargin();
  const patientData = useSelector(selectPatientData);

  const internalContent = useHasInternalContent();

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_screen_opened",
        "SearchScreen",
        "UserOpened",
        null
      );
      dispatch(setCurrentTabSelection(i18n.t("content")));

      dispatch(setCalculationVar(0));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: colors.ui.background,
        overflow: "visible",
      }}
    >
      <StatusBar style="light" />
      <ControlledScrollView
        style={{
          flex: 1,
          padding: Sizes.defaultContainerPadding,
          paddingTop: 0,
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ paddingBottom: 150, paddingTop }}
      >
        <DefaultHeader height={150} />
        {!patientData && (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignSelf: "center",
              padding: 12,
              gap: 6,
              position: "absolute",
              top: 83 + paddingTop,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.brand.greys.white + "55",
              borderRadius: 30,
              paddingVertical: 10,
            }}
            onPress={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "share_app_selected",
                "SearchScreen",
                "User Selected Share App"
              );

              if (Platform.OS === "web") {
                navigator.clipboard.writeText("https://app.lando.health");
                alert("Link in die Zwischenablage kopiert!");
                return;
              }
              Share.share({
                message: "https://app.lando.health",
              });
            }}
          >
            <Text
              style={{
                fontSize: Sizes.boxText,
                ...getFontStyle(600),
                color: colors.brand.greys.white,
              }}
            >
              App teilen
            </Text>
            <Feather name="share" size={18} color={colors.brand.greys.white} />
          </TouchableOpacity>
        )}
        <View
          style={{
            marginBottom: 24,
          }}
        >
          <SearchBar
            action={() => {
              navigation.navigate("GlobalSearchScreen");
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "global_search_selected",
                "HomeScreen",
                "SearchBar"
              );
            }}
            placeholder={i18n.t("default_search_placeholder")}
            style={{ marginBottom: 8, marginTop: 8 }}
          />
        </View>
        {showContentPartner && <SearchSection />}
        {internalContent && (
          <View style={{ marginBottom: 32 }}>
            <HospitalRulesCard
              onPress={() =>
                navigateToNextCMSPage(internalContent.initialPageId, navigation)
              }
            />
          </View>
        )}

        <ContentListComponent />
      </ControlledScrollView>
    </View>
  );
}
