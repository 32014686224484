import { Feather } from "@expo/vector-icons";
import { useIsFocused } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { AppState, AppStateStatus, Text, View } from "react-native";
import { useSelector } from "react-redux";
import apiCall from "../../../../api/api-call";
import InViewPort from "../../../../components/InViewComponent/InViewComponent";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { selectAllContent } from "../../../../functions/data/actions";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../../../functions/user-interaction-handler";
import { isResultPageWithSkeleton } from "../../../ResultPageScreen/ResultPageScreen";

//TODO: add offline capabilities

// DEBOUNCE

const PersonalKPIs = () => {
  const [pagesExplored, setPagesExplored] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [timeSpent, setTimeSpent] = useState(0);
  const timeRef = useRef(-1);

  const allData = useSelector(selectAllContent);
  const [appState, setAppState] = useState(AppState.currentState);
  const isFocused = useIsFocused(); // React Navigation hook

  const percentageExplored = Math.min(
    Math.round((pagesExplored / totalPages) * 100),
    100
  );

  // Fetch Total Pages and Pages Explored
  const fetchData = useCallback(
    _.debounce(async () => {
      const response = await apiCall(
        "/api/v2/kpi/views/unique/",
        null,
        false,
        false,
        "GET"
      );

      setPagesExplored(response?.data?.unique_views ?? 0);

      const pages = allData?.pages?.filter(
        (page) =>
          ["scorePage", "resultPage"].includes(page.content_type_id) ||
          isResultPageWithSkeleton(page.content_type_id)
      )?.length;

      setTotalPages(pages ?? 1); // Avoid division by 0
    }, 300), // 300ms debounce delay
    [allData]
  );

  // Trigger fetch when the component is in view or regains focus
  useEffect(() => {
    if (isFocused) {
      fetchData();
    }
  }, [isFocused, fetchData]);

  useEffect(() => {
    const saveInterval = setInterval(async () => {
      if (timeRef.current > 10)
        await addValueToInteractions("timeSpent", timeRef.current);
    }, 30000);

    return () => {
      clearInterval(saveInterval);
    };
  }, []);

  useEffect(() => {
    // Fetch the stored time when the component mounts
    const storedTime = getValueFromInteractions("timeSpent");

    if (storedTime) {
      timeRef.current = Number(storedTime);
      setTimeSpent(timeRef.current); // Update state to reflect initial value
    }

    const interval = setInterval(() => {
      timeRef.current += 1; // Increment ref value
      setTimeSpent(timeRef.current); // Update state to reflect initial value
    }, 1000);

    // Cleanup on unmount
    return () => {
      clearInterval(interval); // Stop the timer
      if (timeRef.current > 10)
        addValueToInteractions("timeSpent", timeRef.current); // Save final timeSpent
    };
  }, []);

  // Effect to handle app state changes
  useEffect(() => {
    const handleAppStateChange = async (nextAppState: AppStateStatus) => {
      if (
        appState.match(/active/) &&
        nextAppState.match(/inactive|background/)
      ) {
        // Save timeSpent when app goes to the background
        if (timeRef.current > 10)
          await addValueToInteractions("timeSpent", timeRef.current);
      } else if (
        appState.match(/inactive|background/) &&
        nextAppState.match(/active/)
      ) {
        // Restore timeSpent when app comes back to the foreground
        const storedTime = getValueFromInteractions("timeSpent");

        if (storedTime) {
          setTimeSpent(Number(storedTime) || 0);
        }
      }
      setAppState(nextAppState); // Update current app state
    };

    const appStateListener = AppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => {
      appStateListener.remove(); // Remove AppState listener
    };
  }, [appState, timeSpent]);

  return (
    <InViewPort onChange={fetchData}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: 12,
          flex: 1,
        }}
      >
        {/* Left Card */}
        <View
          style={{
            flex: 1,
            marginRight: 12,
            padding: 12,
            borderRadius: 12,
            backgroundColor: "#FFF",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "600",
                color: Colors.light.accent,
                marginRight: 8,
                flex: 1,
              }}
            >
              Inhalt erkundet
            </Text>
            <View
              style={{
                padding: 5,
                borderRadius: 100,
                backgroundColor: "#d2d2d2",
              }}
            >
              <Feather name="file-text" size={16} color="black" />
            </View>
          </View>
          <Text
            style={{
              fontSize: 48,
              fontWeight: "700",
            }}
          >
            {percentageExplored}%
          </Text>
          <View
            style={{
              width: "100%",
              height: 24,
              borderColor: "#E0E0E0",
              borderWidth: 1,
              borderRadius: 8,
              marginTop: 8,
              overflow: "hidden",
            }}
          >
            <LinearGradient
              colors={["#FFD22E", "#FE9506"]}
              start={[1, 0]}
              end={[0, 0]}
              style={{
                height: "100%",
                width: `${percentageExplored}%`,
              }}
            />
          </View>
        </View>

        {/* Right Card */}
        <View
          style={{
            flex: 1,
            padding: 12,
            borderRadius: 12,
            backgroundColor: "#FFF",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            alignSelf: "stretch",
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "600",
                flex: 1,
                marginRight: 8,
              }}
            >
              Zeit in der App
            </Text>
            <View
              style={{
                padding: 5,
                borderRadius: 100,
                backgroundColor: "#d2d2d2",
              }}
            >
              <Feather name="clock" size={16} color="black" />
            </View>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            <Text
              style={{
                alignSelf: "center",
                marginTop: 8,
                fontSize: 32,
                color: Colors.light.accent,
                fontWeight: "700",
              }}
            >
              {String(Math.floor(timeSpent / 3600)).padStart(2, "0")}:
              {String(Math.floor((timeSpent % 3600) / 60)).padStart(2, "0")}:
              {String(timeSpent % 60).padStart(2, "0")}
            </Text>
          </View>
        </View>
      </View>
    </InViewPort>
  );
};

export default PersonalKPIs;
