import { useState } from "react";
import { View } from "react-native";
import I18n from "../../locales/i18n";
import SearchResults from "../components/search-tab/SearchResults";
import { Sizes } from "../constants/static-sizes";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";
import { ScrollView } from "react-native";

export default function GlobalSearchScreen() {
  const [selected, setSelected] = useState(true);
  const [query, setQuery] = useState("");

  const [filters, setFilters] = useState([]);

  return (
    <View style={{ padding: Sizes.defaultContainerPadding, flex: 1 }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <SearchBar
          shouldFocus
          origin="Search"
          placeholder={I18n.t("default_search_placeholder")}
          {...{ query, setQuery, selected, setSelected, filters, setFilters }}
          style={{ marginBottom: 8, marginTop: 8 }}
        />

        <View
          style={{
            flex: 1,
            alignSelf: "center",
            //marginHorizontal: Sizes.defaultContainerPadding,
          }}
        >
          <SearchResults isGlobalSearch {...{ query, setQuery, filters }} />
        </View>
      </ScrollView>
    </View>
  );
}
