/**
 * Returns the maximum path length (deepest chain of answers)
 * reachable from the given starting internalID.
 */
export function getMaxPathLength(questions: any[], startId = "start"): number {
  const visited = new Map<string, number>();

  function dfs(questionId: string): number {
    if (visited.has(questionId)) {
      return visited.get(questionId)!;
    }

    const question = questions.find((q) => q.internalID === questionId);
    if (!question) {
      visited.set(questionId, 0);
      return 0;
    }

    if (!question.answers || question.answers.length === 0) {
      visited.set(questionId, 1);
      return 1;
    }

    let maxDepth = 0;
    for (const answer of question.answers) {
      if (answer.nextQuestionID) {
        if (questions.some((q) => q.internalID === answer.nextQuestionID)) {
          const childDepth = dfs(answer.nextQuestionID);
          if (childDepth > maxDepth) {
            maxDepth = childDepth;
          }
        }
      }
    }

    visited.set(questionId, maxDepth + 1);
    return maxDepth + 1;
  }

  return dfs(startId);
}
