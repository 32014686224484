import { Animated, StyleSheet, Text, TouchableOpacity } from "react-native";
import { colors } from "../../../constants/static-colors";

interface QuestionContainerProps {
  currentQuestion: any;
  opacityAnim: Animated.Value;
  questionContainerAnim: Animated.Value;
  scaleAnim: Animated.Value;
  questionPositionAnim: Animated.Value;
  onAnswerSelected: (option: any) => void;
}

export const QuestionContainer = ({
  currentQuestion,
  opacityAnim,
  questionContainerAnim,
  scaleAnim,
  questionPositionAnim,
  onAnswerSelected,
}: QuestionContainerProps) => {
  return (
    <Animated.View
      style={[
        styles.questionContainer,
        {
          opacity: Animated.multiply(opacityAnim, questionContainerAnim),
          transform: [
            { scale: scaleAnim },
            { translateY: questionPositionAnim },
          ],
        },
      ]}
    >
      {/* Render each answer as a button */}
      {currentQuestion.answers.map((option: any, index: number) => (
        <TouchableOpacity
          key={index}
          style={styles.optionButton}
          onPress={() => onAnswerSelected(option)}
          activeOpacity={0.8}
        >
          <Text style={styles.optionText}>{option.text}</Text>
        </TouchableOpacity>
      ))}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  questionContainer: {
    backgroundColor: colors.ui.cardBackground,
    padding: 20,
    borderRadius: 16,
    shadowColor: colors.brand.greys.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: colors.ui.border,
    overflow: "hidden",
  },
  questionText: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 20,
    color: colors.ui.textPrimary,
    fontFamily: "HostGrotesk-Regular",
  },
  optionButton: {
    backgroundColor: colors.brand.greens.g339988,
    padding: 15,
    borderRadius: 12,
    marginBottom: 12,
  },
  optionText: {
    color: colors.brand.greys.white,
    fontSize: 15,
    textAlign: "center",
    fontFamily: "HostGrotesk-Regular",
    fontWeight: "500",
  },
});
