import { useEffect, useState } from "react";
import { Text } from "react-native";
import Colors from "../../constants/static-colors";
import getColorScheme from "../../hooks/useColorScheme";
import { hyphenateDe } from "../../screens/tab-navigator-screens/components/Card";

export default function TitleText({
  title,
  style,
}: {
  readonly title: string;
  readonly style?: any;
}) {
  const colorScheme = getColorScheme();
  const [_title, setTitle] = useState(title);

  useEffect(() => {
    async function load() {
      const hyp = await hyphenateDe(title);
      setTitle(hyp);
    }

    load();
  }, [title]);

  return (
    <Text
      style={{
        ...style,
        fontWeight: "500",
        //flex: 1,
        fontSize: 24,
        textAlign: "left",
        color: Colors[colorScheme].text,
      }}
    >
      {_title.trim()}
    </Text>
  );
}
