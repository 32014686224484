import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Animated,
  Easing,
  Keyboard,
  SafeAreaView,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import CheckBox from "../../../components/CheckBoxComponent";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";

import * as sha512 from "js-sha512";

import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { addValueToInteractions } from "../../../functions/user-interaction-handler";
import { addToUserCreationObject } from "../../../functions/user/actions";
import {
  registerCall,
  validateEmail,
} from "../../../functions/user/functions/registry";
import getColorScheme from "../../../hooks/useColorScheme";
import { LoadingView } from "./EnterUserInformationScreen";

export default function EnterLoginDataScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [selected, setSelected] = useState(false);
  const usernameRef = useRef<any>();
  const colorScheme = getColorScheme();
  const [pwVisible, setPwVisible] = useState(true);
  const moveUpAnim = useRef(new Animated.Value(0)).current;
  const [waiting, setWaiting] = useState(false);
  const navigation = useNavigation();
  const dispatch = useDispatch();

  function onKeyboardDidShow(e: any) {
    moveUp(e.endCoordinates.height * 0.6);
  }

  function onKeyboardDidHide() {
    moveDown();
  }

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "enter_login_data_screen",
      "RegistrationScreen",
      "RegistrationOpened"
    );
  }, []);

  async function continueAction() {
    RNReactNativeHapticFeedback.trigger("impactLight");
    const _password = sha512.sha512(password);
    const userData = {
      email: username.trim().toLowerCase(),
      password: _password,
    };
    dispatch(addToUserCreationObject(userData));
    setWaiting(true);
    const response = await registerCall();

    if (response.status) {
      await addValueToInteractions("show_terms_hint", "false");
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "registration_sent",
        "RegistrationScreen",
        "RegistrationSent",
        username.trim().toLowerCase()
      );
      navigation.reset({
        index: 0,
        routes: [{ name: "WaitingForRegistrationScreen" }],
      });
    } else {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "registration_failed",
        response,
        "RegistrationFailed",
        username.trim().toLowerCase()
      );
      setWaiting(false);
    }
  }

  const moveUp = (to: number) => {
    clearTimeout(stopDown);
    Animated.timing(moveUpAnim, {
      toValue: to,
      duration: 300,
      easing: Easing.in(Easing.quad),
      useNativeDriver: false,
    }).start();
  };

  let stopDown: any;

  const moveDown = () => {
    stopDown = setTimeout(() => {
      Animated.timing(moveUpAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in(Easing.quad),
        useNativeDriver: false,
      }).start();
    }, 500);
  };
  useEffect(() => {
    const showSubscription = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardDidShow
    );
    const hideSubscription = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardDidHide
    );

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
        justifyContent: "center",
        marginVertical: Sizes.defaultContainerPadding,
      }}
    >
      <ScrollView
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        showsHorizontalScrollIndicator={false}
        style={{
          flexGrow: 1,
          flex: 1,
          alignSelf: "stretch",
        }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <Animated.View
          style={{
            width: "100%",
            padding: 20,
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            maxWidth: Sizes.containerWidth,
          }}
        >
          <View style={{ width: "100%" }}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                fontSize: 24,
                fontWeight: "500",
              }}
            >
              {i18n.t("enter_login_data")}
            </Text>
            <TextInput
              key={"username"}
              textContentType={"username"}
              ref={usernameRef}
              autoFocus
              keyboardType={"email-address"}
              onChangeText={(t) => setUsername(t.trim())}
              placeholderTextColor={Colors[colorScheme].placeholder}
              style={{
                backgroundColor: Colors[colorScheme].foreground,
                color: Colors[colorScheme].text,
                maxWidth: Sizes.containerWidth,
                borderRadius: 12,
                marginTop: 30,
                width: "100%",
                height: 50,
                padding: 12,
                marginBottom: 12,
                zIndex: 0,
              }}
              placeholder={"E-Mail Adresse"}
            />
            <View
              style={{
                width: "100%",
                borderRadius: 12,
                marginTop: 12,
                marginBottom: 12,
              }}
            >
              <TextInput
                textContentType={"newPassword"}
                secureTextEntry={pwVisible}
                keyboardType={"default"}
                onChangeText={(t) => setPassword(t.trim())}
                style={{
                  backgroundColor: Colors[colorScheme].inputBackground,
                  color: Colors[colorScheme].text,
                  overflow: "hidden",
                  maxWidth: Sizes.containerWidth,
                  borderRadius: 12,
                  width: "100%",
                  height: 50,
                  padding: 12,
                  zIndex: 0,
                }}
                placeholderTextColor={Colors[colorScheme].placeholder}
                placeholder={"*********"}
              />
              <TouchableOpacity
                style={{ position: "absolute", right: 20, top: 11 }}
                onPress={() => setPwVisible(!pwVisible)}
              >
                <Ionicons
                  name={pwVisible ? "eye-outline" : "eye-off-outline"}
                  size={24}
                  color={Colors[colorScheme].text}
                />
              </TouchableOpacity>
              {password.length > 0 && password.length < 8 && (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 8,
                    paddingHorizontal: 12,
                  }}
                >
                  <Ionicons
                    name="warning-outline"
                    color={Colors[colorScheme].red}
                    size={18}
                  />
                  <Text
                    style={{
                      color: Colors[colorScheme].red,
                      marginLeft: 4,
                    }}
                  >
                    {i18n.t("min_eight_chars")}
                  </Text>
                </View>
              )}
            </View>
          </View>

          <AgreeTermsOptIn selected={selected} setSelected={setSelected} />
          {waiting ? (
            <LoadingView />
          ) : (
            <ButtonView
              continueAction={continueAction}
              email={validateEmail(username) && selected}
              password={!!password && password.length >= 8}
            />
          )}
        </Animated.View>
      </ScrollView>
    </SafeAreaView>
  );
}

const AgreeTermsOptIn = ({ selected, setSelected }) => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "login_data_agree_changed",
      "EnterLoginDataScreen",
      "BooleanChange",
      selected
    );
  }, [selected]);

  return (
    <TouchableOpacity
      onPress={() => setSelected(!selected)}
      style={{
        flexDirection: "row",
        alignItems: "center",
        gap: 12,
        marginHorizontal: 24,
        marginTop: 20,
        maxWidth: 500,
      }}
    >
      <CheckBox {...{ isSelected: selected, setSelected }} />
      <Text
        style={{
          color: Colors[colorScheme].text,
          fontSize: 12,
        }}
      >
        {i18n.t("agree_terms_registry.confirm")}{" "}
        <Text
          onPress={() => navigation.navigate("PreLoginDatenschutz")}
          style={{ color: Colors[colorScheme].accentDark, fontSize: 12 }}
        >
          {i18n.t("agree_terms_registry.terms")}
        </Text>{" "}
        {i18n.t("agree_terms_registry.and")}{" "}
        <Text
          onPress={() => navigation.navigate("PreLoginPrivacyPolicy")}
          style={{ color: Colors[colorScheme].accentDark, fontSize: 12 }}
        >
          {i18n.t("agree_terms_registry.privacy")}
        </Text>{" "}
        {i18n.t("agree_terms_registry.of")}
      </Text>
    </TouchableOpacity>
  );
};

export const AgreeTermsView = ({ style, isLogin = false }) => {
  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  return useMemo(
    () => (
      <View style={{ position: "absolute", bottom: 20, ...style }}>
        <Text
          style={{
            color: Colors[colorScheme].text,
            fontSize: 12,
            marginTop: 8,
            textAlign: "center",
          }}
        >
          {i18n.t("agreeTerms.confirm")}{" "}
          <Text
            onPress={() => navigation.navigate("PreLoginDatenschutz")}
            style={{ color: Colors[colorScheme].accent, fontSize: 12 }}
          >
            {i18n.t("agreeTerms.terms")}
          </Text>{" "}
          {i18n.t("agreeTerms.and")}{" "}
          <Text
            onPress={() => navigation.navigate("PreLoginPrivacyPolicy")}
            style={{ color: Colors[colorScheme].accent, fontSize: 12 }}
          >
            {i18n.t("agreeTerms.privacy")}
          </Text>{" "}
          {i18n.t("agreeTerms.of")}
        </Text>
      </View>
    ),
    [style, isLogin, colorScheme, navigation]
  );
};

const ButtonView = ({ email, password, continueAction }) => {
  const navigation = useNavigation();
  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 30,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DefaultButton
        type={ButtonTypes.Outline}
        title={i18n.t("Back")}
        action={() => navigation.goBack()}
        style={{ width: "30%" }}
      />
      <DefaultButton
        type={ButtonTypes.Primary}
        title={i18n.t("send")}
        action={continueAction}
        enabled={email && password}
        style={{ width: "60%", marginLeft: 20 }}
        textStyle={{
          alignSelf: "flex-start",
          paddingLeft: 8,
          paddingRight: 20,
        }}
        icon={<Ionicons name="arrow-forward" size={24} />}
      />
    </View>
  );
};
