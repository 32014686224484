import messaging from "@react-native-firebase/messaging";
import { useNavigation } from "@react-navigation/native";
import { Platform, Text, View } from "react-native";

import { useEffect } from "react";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch, useSelector } from "react-redux";
import getPageContent from "../api/content/get-page-content";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";

import {
  changeUserInformation,
  selectCurrentUser,
} from "../functions/user/actions";
import { showInlineNotification } from "./Root";
import { APP_TARGET } from "../screens/LaunchScreen";

export const NotificationHandlingWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const navigation = useNavigation();

  const checkForInitialNotification = async (navigateToScreen) => {
    const remoteMessage = await messaging().getInitialNotification();

    if (remoteMessage) {
      setTimeout(() => {
        navigateToScreen(remoteMessage);
      }, 4000);
    }
  };

  const navigateToScreen = async (remoteMessage, add = true) => {
    const pageId = remoteMessage.data?.pageId;

    if (pageId) {
      if (pageId === "home") {
        navigation.navigate(APP_TARGET);
        return;
      } else if (pageId === "tipps") {
        navigation.navigate("TippsScreen");
        return;
      }
      const pageContent = getPageContent(pageId);

      if (add) {
        if (pageContent)
          dispatch(
            changeUserInformation({
              indexKey: "receivedSharedPages",
              value: [
                { pageId, from: remoteMessage.notification.title },
                ...(currentUser?.receivedSharedPages ?? []),
              ],
            })
          );
      }

      setTimeout(async () => {
        if (pageContent) {
          if (pageContent.type === "scorePage") {
            navigation.navigate("ScoreScreen", {
              pageContent: pageContent,
            });
          } else if (pageContent.type === "nodePage") {
            navigation.navigate("CMSNodePageScreen", {
              pageContent: pageContent,
            });
          } else
            navigation.navigate("ResultPageScreen", {
              pageContent: pageContent,
            });
        }
      }, 250);
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") return;
    checkForInitialNotification(navigateToScreen);
    const onNotificationOpened = messaging().onNotificationOpenedApp(
      (remoteMessage) => {
        navigateToScreen(remoteMessage);
      }
    );

    const onMessage = messaging().onMessage(async (remoteMessage) => {
      if (!remoteMessage?.notification) return;

      const pageId = remoteMessage.data?.pageId;

      if (pageId)
        dispatch(
          changeUserInformation({
            indexKey: "receivedSharedPages",
            value: [
              { pageId, from: remoteMessage.notification.title },
              ...(currentUser?.receivedSharedPages ?? []),
            ],
          })
        );

      RNReactNativeHapticFeedback.trigger("notificationSuccess");
      showInlineNotification({
        text: (
          <Text>
            <Text style={{ fontWeight: "bold", fontSize: 15 }}>
              {remoteMessage.notification.title}
              {"\n"}
            </Text>
            {remoteMessage.notification.body}
          </Text>
        ),
        type: InlineNotificationType.NEUTRAL,
        hasIcon: false,
        duration: 3500,
        onPress: () =>
          setTimeout(() => navigateToScreen(remoteMessage, false), 100),
      });
    });

    return () => {
      onNotificationOpened();
      onMessage();
    };
  }, [messaging]);

  return (
    <View
      style={{
        flexDirection: "row",
        flex: 1,
        ...Platform.select({
          web: { maxHeight: "100vh" as any, overflow: "hidden" },
        }),
        backgroundColor: "black",
        overflow: "hidden",
      }}
    >
      {children}
    </View>
  );
};
