import Ionicons from "@expo/vector-icons/Ionicons";

import { useCallback, useEffect, useRef } from "react";
import {
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";

import { debounce } from "lodash";
import I18n from "../../../../locales/i18n";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import configureAnimations from "../../../functions/configure-animations";
import getFontStyle from "../../../functions/getFontSize";

export const SearchBar = ({
  style,
  selected,
  setSelected,
  query,
  setQuery,
  placeholder = "Suche",
  barStyle,
  collapsed = false,
  action,
  shouldFocus = false,
  hideIcon = false,
}: {
  style?: any;
  selected?: boolean;
  setSelected?: any;
  query?: string;
  setQuery?: any;
  placeholder?: any;
  collapsed?: boolean;
  action?: any;
  barStyle?: any;
  shouldFocus?: boolean;
  hideIcon?: boolean;
}) => {
  const textInputRef = useRef<any>();

  const debouncedUpdate = useCallback(
    debounce((query) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_initiated",
        "SearchBar",
        "User Initiated Search",
        { query }
      );
    }, 500),
    []
  );

  useEffect(() => {
    configureAnimations();
    if (!selected && query !== "") {
      AnalyticsHandler.getInstance().logSearch(
        "search_failed",
        query,
        "global_search",
        "dismissed"
      );
    }
  }, [selected]);

  useEffect(() => {
    if (shouldFocus) textInputRef.current?.focus();
  }, [shouldFocus]);

  return (
    <View
      style={{
        ...style,
        shadowColor: colors.ui.textPrimary,
        shadowOffset: {
          width: 2,
          height: 2,
        },
        elevation: 3,
        shadowOpacity: 0.12,
        shadowRadius: 4,
        borderRadius: 20,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          if (action) {
            action();
          }
        }}
        disabled={!action}
        activeOpacity={0.75}
        key={placeholder}
        style={{ borderRadius: 20 }}
      >
        <View
          pointerEvents={action ? "none" : "auto"}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: colors.ui.inputBackground,
              flex: 1,
              borderRadius: 20,
              paddingLeft: 16,
              paddingRight: 16,
              ...barStyle,
            }}
          >
            <Ionicons
              name="search"
              size={24}
              color={colors.brand.greys.gD4D4D4}
            />
            <TextInput
              ref={textInputRef}
              key={placeholder}
              defaultValue={query}
              onFocus={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "search_bar_focused",
                  "SearchBar",
                  "User Focused Search Bar",
                  null
                );

                setSelected(true);
                configureAnimations();
              }}
              onBlur={(e) => {
                if (Platform.OS === "web") {
                  e.preventDefault();
                }

                // if (query === "") setSelected(false);
              }}
              placeholderTextColor={colors.ui.textPlaceholder}
              placeholder={placeholder}
              style={{
                flex: 1,
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
                paddingHorizontal: 16,
                paddingVertical: collapsed && !selected ? 12 : 20,
                ...Platform.select({ android: { paddingVertical: 12 } }),
                paddingLeft: 0,
                marginLeft: 12,
                fontFamily: "HostGrotesk-Regular",
              }}
              onChangeText={(query) => {
                debouncedUpdate(query);
                setQuery(query);
              }}
            />
            {selected && !hideIcon ? (
              <TouchableOpacity
                style={{
                  backgroundColor: colors.ui.primary,
                  borderRadius: 100,
                  padding: 4,
                }}
                onPress={() => {
                  setQuery("");
                  textInputRef?.current.clear();

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "search_cancelled",
                    "SearchBar",
                    "User Cancelled Search",
                    { query }
                  );

                  textInputRef.current?.blur();
                  setSelected(false);
                }}
              >
                <Ionicons name="close" size={18} color={"black"} />
              </TouchableOpacity>
            ) : (
              !collapsed &&
              !hideIcon && (
                <View
                  pointerEvents="none"
                  style={{
                    backgroundColor: colors.ui.primary,
                    padding: 8,
                    paddingHorizontal: 12,
                    borderRadius: 20,
                  }}
                >
                  <Text
                    style={{
                      ...getFontStyle(500),
                      color: colors.brand.greys.black,
                    }}
                  >
                    {I18n.t("seek")}
                  </Text>
                </View>
              )
            )}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};
