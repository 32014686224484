import { LinearGradient } from "expo-linear-gradient";
import { colors } from "../constants/static-colors";

export default function DefaultGradientBackground({ style = {} }) {
  return (
    <LinearGradient
      start={{ x: 0.0, y: 1.0 }}
      end={{ x: 0.4, y: 0.0 }}
      colors={[colors.ui.primary, colors.ui.primary]}
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: -10,
        ...style,
      }}
    />
  );
}
