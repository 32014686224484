/* eslint-disable @typescript-eslint/no-var-requires */
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Font from "expo-font";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import i18n from "../../locales/i18n";
import { posthogAsync } from "../api/analytics/posthog";
import { Sizes } from "../constants/static-sizes";
import { setSelectedEnterprise } from "../functions/enterprise/actions";
import { store } from "../functions/store";

export default function useInitialAppStateLoading() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  async function getData() {
    try {
      // * await posthog initialization
      await posthogAsync;

      // * Load fonts
      if (Platform.OS === "web" || Platform.OS === "android") {
        await Font.loadAsync({
          "HostGrotesk-Bold": require("../../assets/fonts/static/HostGrotesk-Bold.ttf"),
          "HostGrotesk-BoldItalic": require("../../assets/fonts/static/HostGrotesk-BoldItalic.ttf"),
          "HostGrotesk-ExtraBold": require("../../assets/fonts/static/HostGrotesk-ExtraBold.ttf"),
          "HostGrotesk-ExtraBoldItalic": require("../../assets/fonts/static/HostGrotesk-ExtraBoldItalic.ttf"),
          "HostGrotesk-Light": require("../../assets/fonts/static/HostGrotesk-Light.ttf"),
          "HostGrotesk-LightItalic": require("../../assets/fonts/static/HostGrotesk-LightItalic.ttf"),
          "HostGrotesk-Medium": require("../../assets/fonts/static/HostGrotesk-Medium.ttf"),
          "HostGrotesk-MediumItalic": require("../../assets/fonts/static/HostGrotesk-MediumItalic.ttf"),
          "HostGrotesk-Regular": require("../../assets/fonts/static/HostGrotesk-Regular.ttf"),
          "HostGrotesk-SemiBold": require("../../assets/fonts/static/HostGrotesk-SemiBold.ttf"),
          "HostGrotesk-SemiBoldItalic": require("../../assets/fonts/static/HostGrotesk-SemiBoldItalic.ttf"),
        });
      } else {
        await Font.loadAsync({
          HostGrotesk: require("../../assets/fonts/HostGrotesk-VariableFont_wght.ttf"),
          "HostGrotesk-Italic": require("../../assets/fonts/HostGrotesk-Italic-VariableFont_wght.ttf"),
        });
      }

      // * await font size settings
      let fontSize = await AsyncStorage.getItem("fontSize");
      if (!fontSize) fontSize = Sizes.boxText + "";
      Sizes.boxText = parseInt(fontSize);
      Sizes.lineHeightBoxText = parseInt(fontSize) + 5;

      const selectedEnterpriseData = await AsyncStorage.getItem(
        "selectedEnterprise"
      );
      if (selectedEnterpriseData) {
        const selectedEnterprise = JSON.parse(selectedEnterpriseData);
        if (selectedEnterprise) {
          if (selectedEnterprise.default_language === "en") {
            i18n.locale = "en";
          }
          store.dispatch(setSelectedEnterprise(selectedEnterprise));
        }
      }
    } catch (error) {
      console.error("Error loading app state or fonts:", error);
    }

    setIsLoadingComplete(true);
  }

  useEffect(() => {
    getData();
  }, []);

  return isLoadingComplete;
}
