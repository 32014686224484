import I18n from "../../../locales/i18n";

export const isBaseTypeBox = (boxType) => {
  const baseTypes = [
    "warningBox",
    "hintBox",
    "infoBox",
    "textBox",
    "arrowBox",
    "searchBox",
    "expandableBox",
    "dosingRecommendationBox",
    "largeContentBox",
    "easyReadingInfoBox",
    "furtherActionBox",
    "actionRecommendationBox",
    "scoreFurtherActionBox",
    "checklistFurtherActionBox",
    "patientDataComponentBox",
    "formulaContentContainer",
    "newCalculatorBox",
    "floatingCalculatorBox",
    "calculatorBox",
  ];
  if (baseTypes.includes(boxType)) return true;
};

export function getMarginToDepth(depth) {
  if (depth === 0) return 0;
  else return 7;
}

export function getItemTitle(item) {
  let title = item.title ?? item.titel ?? reverseCamelCase(item.key);
  if (I18n.locale === "en") {
    title = getTranslatedItemTitle(title);
  }
  return title;
}

function getTranslatedItemTitle(title) {
  // Extract the main part of the title without the source numbers
  const mainTitle = title.split("$")[0].trim();

  // Translate the main title
  let translatedTitle;
  switch (mainTitle) {
    case "Symptomorientierte Anamnese":
      translatedTitle = "Symptom-oriented anamnesis";
      break;
    case "Prähospital":
      translatedTitle = "Prehospital";
      break;
    case "Intrahospital":
      translatedTitle = "In-hospital";
      break;
    case "Diagnostik":
      translatedTitle = "Diagnostic";
      break;
    default:
      translatedTitle = mainTitle;
  }

  // Reattach the source numbers to the translated title
  const sources = title.match(/\$source_\d+\$/g) || [];
  return translatedTitle + " " + sources.join(" ");
}

export function reverseCamelCase(s: string) {
  if (!s) return "";
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
