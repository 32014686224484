// Carousel.web.tsx (for web platform)
import React from "react";
import SlickCarousel from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./Carousel.css"; // Custom CSS for inactive slide styling

interface CarouselProps {
  data: Array<any>;
  autoplay?: boolean;
  autoplayInterval?: number;
  renderItem: ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }) => React.ReactNode;
  [key: string]: any; // Pass any other props dynamically
}

const CarouselComponent: React.FC<CarouselProps> = ({
  data,
  autoplay = true,
  autoplayInterval = 8000,
  renderItem,
  ...rest
}) => {
  const settings = {
    dots: false, // Remove pagination dots
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: autoplay,
    autoplaySpeed: autoplayInterval,
    centerMode: true, // Center the active slide
    centerPadding: "20%", // Ensures partial visibility of inactive slides
    ...rest, // Pass other props
  };

  return (
    <div style={{ width: "100%", margin: "0 auto", position: "relative" }}>
      <SlickCarousel {...settings}>
        {data.map((item, index) => (
          <div key={index} className="carousel-slide-wrapper">
            <div className="carousel-slide">{renderItem({ item, index })}</div>
          </div>
        ))}
      </SlickCarousel>
    </div>
  );
};

export default CarouselComponent;
