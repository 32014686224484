// ScoreInterpretation.tsx

import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Dimensions,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import I18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import AnimatedWrapper from "../../../components/AnimatedWrapper/AnimatedWrapper";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import ResultBoxes from "../../../components/result/ResultBoxComponent";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import configureAnimations from "../../../functions/configure-animations";
import {
  selectRemoteOpenSaveScoreModal,
  setRemoteOpenSaveScoreModal,
} from "../../../functions/current-result/actions";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export function getScoreInterpretationBoxes(score, data, answers, isEuroscore) {
  const calc = (val) => {
    const y = -5.324537 + val;
    const result = Math.E ** y / (1 + Math.E ** y);
    return Math.round(result * 10000) / 100;
  };

  const baseItem = data?.find(
    (entry) => score >= entry.lowerBound && score <= entry.upperBound
  );
  const _item = baseItem ? { ...baseItem } : { interpretationBoxes: [] };

  if (isEuroscore) {
    _item.interpretationBoxes = [
      {
        title: `<p>Die vorhergesagte Sterblichkeit beträgt <b>${calc(
          score
        )}%</b></p>`,
        typeId: "hintBox",
      },
    ];
  }

  // Additional user-supplied hints if score < 5
  const additionalInterpretations = answers
    .map((answer) => answer.additionalScoreInterpretation)
    .filter(Boolean)
    .flatMap((interpretation) => interpretation.interpretationBoxes);

  if (additionalInterpretations.length > 0 && score < 5) {
    _item.interpretationBoxes = [
      ..._item.interpretationBoxes,
      ...additionalInterpretations,
    ];
  }

  AnalyticsHandler.getInstance().logUserScreenInteraction(
    "score_changed",
    "ScoreScreen",
    score,
    "Has interpretation: " + !!_item
  );

  //configureAnimations();
  return _item;
}

const ScoreInterpretation = ({
  data,
  isEuroscore,
  setInterpretationContent,
  item,
  setItem,
  collapsed = true,
  score,
  answers,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const remoteOpenSaveScoreModal = useSelector(selectRemoteOpenSaveScoreModal);
  const scoreInterpretationElements = data?.scoreInterpretationElements;

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    // Recalculate interpretation boxes whenever score or answers change
    setItem(
      getScoreInterpretationBoxes(
        score,
        scoreInterpretationElements,
        answers,
        isEuroscore
      )
    );
  }, [score, answers, scoreInterpretationElements]);

  // Close any remote-triggered modals if needed
  useEffect(() => {
    if (remoteOpenSaveScoreModal) {
      dispatch(setRemoteOpenSaveScoreModal(false));
    }
  }, [remoteOpenSaveScoreModal]);

  const hasInterpretationData =
    item?.interpretationBoxes && item?.interpretationBoxes.length > 0;

  return (
    <AnimatedWrapper style={{ width: "100%", alignSelf: "stretch" }}>
      <View
        style={{
          padding: 16,
          paddingBottom: collapsed ? 16 : 24,
          backgroundColor: colors.ui.highlight,
          borderWidth: 1,
          marginBottom: hidden ? -40 : 0,
          maxHeight: collapsed
            ? "auto"
            : hidden
            ? 100
            : (Dimensions.get("window").height / 3) * 1.5,

          borderColor: colors.ui.primary,
          ...(collapsed
            ? {}
            : {
                shadowColor: colors.ui.textPrimary,
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
              }),
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: 20,
          }}
        >
          <Text
            style={{
              flex: 1,
              fontSize: 24,
              ...getFontStyle(800),
              color: colors.ui.textPrimary,
            }}
          >
            {Math.round((score ?? 0) * 100) / 100}{" "}
            <Text
              style={{
                ...getFontStyle(400),
                fontSize: Sizes.boxText,
              }}
            >
              {" "}
              {I18n.t("points")}
            </Text>
          </Text>
          {!collapsed && (
            <TouchableOpacity
              onPress={() => {
                configureAnimations();
                setHidden((c) => !c);
              }}
              hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
            >
              <Ionicons
                name={hidden ? "chevron-up" : "chevron-down"}
                size={24}
                color={colors.ui.textPrimary}
              />
            </TouchableOpacity>
          )}
        </View>

        <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
          {hasInterpretationData ? (
            <ScrollView
              scrollEnabled={!collapsed}
              horizontal={false}
              showsVerticalScrollIndicator={false}
              style={{
                maxHeight: collapsed ? "auto" : isLargerDevice() ? 300 : 200,
              }}
              contentContainerStyle={{
                marginTop: -20,
              }}
            >
              <ResultBoxes pageContent={{ boxes: item?.interpretationBoxes }} />
            </ScrollView>
          ) : (
            <Text
              style={{
                fontFamily: "HostGrotesk-Regular",
                marginTop: 16,
                fontSize: Sizes.boxText,
                color: colors.ui.textPrimary,
              }}
            >
              {I18n.t("please_answer_above_questions")}
            </Text>
          )}
        </View>

        {/* Optional "Ergebnis übertragen" Button */}
        {setInterpretationContent && (
          <View style={{ marginTop: 20 }}>
            <DefaultButton
              title={I18n.t("transfer_result")}
              action={() => {
                setInterpretationContent({ score, answers });
                navigation.goBack();
              }}
              type={ButtonTypes.Primary}
              style={{ marginHorizontal: 20 }}
            />
          </View>
        )}
      </View>
    </AnimatedWrapper>
  );
};

export default ScoreInterpretation;
