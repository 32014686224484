import { colors } from "../../constants/static-colors";
import { store } from "../../functions/store";

export default function getLinkParsedContent(content: string) {
  const resultingCalculation =
    store.getState().calculatorReducer.resultingCalculation;

  if (!content) return "";

  // Process $LINK and $LINK_INFO tokens first
  content = content.replace(
    /\$(LINK|LINK_INFO)\{([^}]+)\}/g,
    (match, type, link) => {
      let target = "";
      const pageIdentifier = type === "LINK_INFO" ? "inline_info:" : "inline:";

      if (!link) link = "";
      link = link.replace(/,.*/, (match) => {
        target = match.slice(1).trim();
        return "";
      });
      target = target.replace(/<[^>]*>/g, "");

      return (
        "<a style='color: " +
        colors.ui.textPrimary +
        "; text-decoration: none' href='" +
        pageIdentifier +
        target +
        "'><span style='text-decoration: underline; text-decoration-color:" +
        colors.ui.textPrimary +
        ";'>" +
        link +
        "</span> <span style='display: inline-block; font-weight: 800; color: " +
        colors.ui.accent +
        "'>ⓘ</span></a>" +
        (resultingCalculation ?? "")
      );
    }
  );

  // Process $POPUP tokens
  // The regex expects a title and description separated by "|||"
  content = content.replace(
    /\$POPUP\{([^|]+)\|\|\|([^}]+)\}/g,
    (match, popupTitle, popupDescription) => {
      // Sanitize inputs for React Native context
      const sanitizedTitle = popupTitle.replace(
        /['"`<>]/g,
        (char) =>
          ({
            "'": "&apos;",
            '"': "&quot;",
            "`": "&grave;",
            "<": "&lt;",
            ">": "&gt;",
          }[char])
      );
      const sanitizedDesc = popupDescription.replace(
        /['"`<>]/g,
        (char) =>
          ({
            "'": "&apos;",
            '"': "&quot;",
            "`": "&grave;",
            "<": "&lt;",
            ">": "&gt;",
          }[char])
      );

      return `<info-icon title="${sanitizedTitle}" description="${sanitizedDesc}"></info-icon>`;
    }
  );

  return content;
}
