import { useNavigation } from "@react-navigation/native";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Animated,
  Dimensions,
  Easing,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useSelector } from "react-redux";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import {
  default as Colors,
  default as staticColors,
} from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import configureAnimations from "../../../functions/configure-animations";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../../functions/user-interaction-handler";
import { selectUserCreationObject } from "../../../functions/user/actions";
import { userLogin } from "../../../functions/user/functions";
import { addAdditionalUserInformation } from "../../../functions/user/functions/registry";
import getColorScheme from "../../../hooks/useColorScheme";
import { AdditionalInformationView } from "./AdditionalInformation/AdditionalInformationComponent";
import i18n from "../../../../locales/i18n";

export default function NewRegistryScreen() {
  const moveAnim = useRef(new Animated.Value(0)).current;
  const userCreationObject = useSelector(selectUserCreationObject);
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewCount, setViewCount] = useState(0); // Track view count

  // Fetch the view count when the component mounts
  useEffect(() => {
    const storedViewCount =
      getValueFromInteractions("screen_view_count_missing_information") || 0;
    setViewCount(storedViewCount);
    // Increment the view count and store it
    addValueToInteractions(
      "screen_view_count_missing_information",
      storedViewCount + 1
    );
  }, []);

  const moveOut = useCallback(() => {
    Animated.timing(moveAnim, {
      toValue: -Dimensions.get("window").width,
      duration: 300,
      easing: Easing.in(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(moveAnim, {
        toValue: Dimensions.get("window").width,
        duration: 0,
        useNativeDriver: false,
      }).start();
      Animated.timing(moveAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    });
  }, [moveAnim]);

  const continueAction = () => {
    RNReactNativeHapticFeedback.trigger("impactLight");
    if (currentIndex === 0) {
      moveOut();
      setTimeout(() => setCurrentIndex(1), 350);
    } else {
      // Proceed with the additional information step here
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <Animated.View
        style={{
          flex: 1,
          width: "100%",
          transform: [{ translateX: moveAnim }],
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {currentIndex === 0 ? (
          <View
            style={{
              padding: 20,
              maxWidth: Sizes.containerWidth,
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                color: staticColors[colorScheme].text,
                fontWeight: "500",
                fontSize: 24,
                textAlign: "center",
              }}
            >
              {i18n.locale === "de"
                ? "Wir brauchen noch ein paar Infos von dir"
                : i18n.t("we_need_some_more_infos")}
            </Text>
            <Text
              style={{
                color: staticColors[colorScheme].text,
                fontSize: 16,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              {i18n.locale === "de"
                ? "Um dir als Medizinprodukt den größtmöglichen Nutzen zu bieten, benötigen wir noch einige zusätzliche Informationen. Das dauert nicht lange – vielen Dank für dein Verständnis!"
                : i18n.t("reason_for_info_collecting")}
            </Text>

            <DefaultButton
              title="Los geht's!"
              action={continueAction}
              style={{
                marginTop: 40,
                alignSelf: "center",
                width: 200,
              }}
              type={ButtonTypes.Primary}
            />
          </View>
        ) : (
          <AdditionalInformationView
            userCreationObject={userCreationObject}
            content={{}}
            showHint={false}
            continueAction={async () => {
              configureAnimations();
              setLoading(true);
              await addAdditionalUserInformation({ ...userCreationObject });
              await userLogin(
                userCreationObject.email,
                userCreationObject.password
              );
              setLoading(false);
              navigation.goBack();
            }}
            loading={loading}
          />
        )}
      </Animated.View>
      {viewCount < 2 && (
        <TouchableOpacity>
          <Text
            style={{
              color: Colors[colorScheme].text,
              fontSize: Sizes.boxText,
              fontWeight: "500",
              textDecorationLine: "underline",
              marginTop: 20,
              marginBottom: 20,
              opacity: 0.5,
            }}
            onPress={() => navigation.goBack()}
          >
            Jetzt nicht
          </Text>
        </TouchableOpacity>
      )}
    </SafeAreaView>
  );
}
