import { useEffect, useMemo, useState } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { useNavigation, useRoute } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { colors } from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { showLightboxImage } from "../../../../entry/Root";

import { navigateToNextPageByCMSId } from "../../../../functions/navigation/helpers";

import { Feather } from "@expo/vector-icons";
import i18n from "../../../../../locales/i18n";
import getPageContent from "../../../../api/content/get-page-content";
import configureAnimations from "../../../../functions/configure-animations";
import getFontStyle from "../../../../functions/getFontSize";
import {
  addValueToInteractions,
  getValueFromInteractions,
} from "../../../../functions/user-interaction-handler";
import { selectCurrentUser } from "../../../../functions/user/actions";
import CustomRenderHTMLComponent from "../../../CustomRenderHTMLComponent";
import ImageWrapper from "../../../ImageWrapper";
import { InlineImage } from "../inline-image-types";

interface FurtherActionBoxProps {
  readonly title: string;
  readonly id?: string;
  readonly inlineImage?: InlineImage;
  readonly isPlaceholder?: boolean;
  readonly isComingSoon?: boolean;
  readonly customAction?: any;
}

export default function FurtherActionBox(props: FurtherActionBoxProps) {
  const navigation = useNavigation();
  const route = useRoute();
  const currentUser = useSelector(selectCurrentUser);

  const isComingSoon = props.isComingSoon;

  const isDevUser = __DEV__ || currentUser.user_type === "dev";

  const targetPage = useMemo(() => getPageContent(props.id), [props.id]);

  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      configureAnimations();
      setShowBadge(false);
    }, 2500);
  }, []);

  const indicators = useMemo(() => {
    if (!targetPage || isComingSoon) return null;

    const now = new Date();

    const fourWeeksAgo = new Date();
    fourWeeksAgo.setDate(now.getDate() - 150); // 4 weeks ago

    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(now.getDate() - 14); // 2 weeks ago

    const createdAt = targetPage?.created_at
      ? new Date(targetPage?.created_at)
      : null;

    const userHasNotClicked =
      getValueFromInteractions("clicked_" + props.id) !== "true";

    const isNew = userHasNotClicked && createdAt && createdAt > fourWeeksAgo; // Created within last 4 weeks

    return { isNew };
  }, [targetPage]);

  return (
    <View
      style={{
        width: "100%",
        ...Platform.select({ web: { cursor: "pointer" } }),
      }}
    >
      <TouchableOpacity
        disabled={props.isPlaceholder || (isComingSoon && !isDevUser)}
        onPress={async () => {
          if (indicators?.isNew) {
            await addValueToInteractions("clicked_" + props.id, "true");
          }

          if (props.customAction) {
            props.customAction();
            return;
          }
          if (route.name === "InfoResultPageScreen") navigation.goBack();

          navigateToNextPageByCMSId(navigation, props.id!, props.title);
        }}
        onLongPress={() => {
          if (props.inlineImage !== undefined)
            showLightboxImage({
              uri:
                props.inlineImage.image?.file_ref ??
                "https:" + props.inlineImage.url,
            });
        }}
        style={[
          styles.container,
          {
            opacity: isComingSoon ? 0.35 : 1,
            backgroundColor: indicators?.isNew
              ? colors.ui.highlight
              : colors.ui.cardBackground,
          },
        ]}
      >
        <View
          style={{
            ...Platform.select({ ios: { flex: 1 }, android: { flex: 1 } }),
            marginRight: 12,
            flex: 1,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              //...Platform.select({ ios: { flex: 1 }, android: { flex: 1 } }),
              flex: 1,
              alignItems: "center",
            }}
          >
            {showBadge && indicators?.isNew && (
              <View
                style={{
                  padding: 4,
                  marginRight: 12,
                  backgroundColor: colors.ui.primary,
                  borderRadius: 4,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    ...getFontStyle(500),
                  }}
                >
                  Neu
                </Text>
              </View>
            )}
            <View style={{ flex: 1 }}>
              {useMemo(
                () => (
                  <CustomRenderHTMLComponent
                    color={colors.ui.textPrimary}
                    contentWidth={Sizes.containerWidth}
                    tagsStyles={htmlStyle()}
                    source={{
                      html: props.title,
                    }}
                  />
                ),
                []
              )}
            </View>
          </View>
          {isComingSoon && (
            <Text
              style={{
                ...getFontStyle(500, true),
                fontSize: Sizes.boxText - 2,
                marginTop: -8,
                marginBottom: 8,
                color: colors.ui.textPrimary,
              }}
            >
              {i18n.t("coming_soon")}
            </Text>
          )}
          {props.inlineImage !== undefined && (
            <ImageWrapper
              style={{
                alignSelf: "stretch",
                marginBottom: 5,
                borderRadius: 6,
                flex: 1,
                ...Platform.select({ web: { maxHeight: "20vh" } }),
                resizeMode: "contain",
              }}
              source={{
                uri:
                  props.inlineImage.image?.file_ref ??
                  "https:" + props.inlineImage.url,
              }}
            />
          )}
        </View>
        <Feather
          style={{ marginLeft: 12 }}
          name="chevron-right"
          color={colors.ui.textPrimary}
          size={24}
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    paddingVertical: 12,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const htmlStyle = () => ({
  body: {
    marginVertical: 12,
    marginRight: 12,
  },
  p: {
    color: colors.ui.textPrimary,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    fontWeight: 600,
    margin: 0,
  },
  b: {
    color: colors.ui.textPrimary,
    fontSize: Sizes.boxText,

    lineHeight: Sizes.lineHeightBoxText,
    fontWeight: 700,
    marginRight: 20,
  },
  ul: {
    margin: 0,
    fontSize: Sizes.boxText,
    lineHeight: Sizes.lineHeightBoxText,
    marginRight: 8,
    color: colors.ui.textPrimary,
    marginBottom: 2,
  },
  li: {
    marginLeft: 6,
    marginBottom: 5,
    color: colors.ui.textPrimary,
  },
});
