import { useEffect, useRef, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import Carousel from "react-native-snap-carousel";
import getFontStyle from "../../../functions/getFontSize";
import getWidth from "../../../hooks/getWidth";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function SlideSix() {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",

        alignItems: "flex-start",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Image
        source={require("../../../../assets/images/slide_six_tutorial.png")}
        style={{
          height: isLargerDevice() ? "70%" : "50%",
          width: "80%",
          alignSelf: "center",
          resizeMode: "contain",
          margin: 16,
        }}
      />

      <Text
        style={{
          fontSize: 16,
          ...getFontStyle(600),
          textAlign: "center",
          alignSelf: "center",
          marginTop: 32,
        }}
      >
        {"LANDO's \nMedical Advisory Board"}
      </Text>
      <AdvisoryCarousel />
    </View>
  );
}

const teamMembers = [
  {
    name: "UNIV.-PROF. DR. MED. JOCHEN HINKELBEIN",
    title:
      "Klinikdirektor der Universitätsklinik für Anästhesiologie, Intensiv- und Notfallmedizin, Universitätsklinikum Minden",
    image: require("../../../../assets/images/advisory/DRJH_Websize2.png"),
  },
  {
    name: "DR. MED. THOMAS RÖSEL",
    title:
      "Chefarzt der Klinik für Anästhesiologie, Intensiv- und Schmerztherapie, Notfallmedizin, Ärztliches Qualitätsmanagement, GLG Prenzlau",
    image: require("../../../../assets/images/advisory/TR_websize.png"),
  },
  {
    name: "PRIV.-DOZ. DR. MED. HANS-JÖRG HIPPE",
    title:
      "Chefarzt der Kardiologie, Innere Medizin und Internistische Intensivmedizin, Marienhospital Witten",
    image: require("../../../../assets/images/advisory/DRJH_websize.png"),
  },
  {
    name: "DR. MED. RICARDO SCHNAUDIGEL",
    title:
      "Facharzt für Anästhesiologie, Intensivmedizin, Universitätsklinikum Frankfurt, Goethe Universität",
    image: require("../../../../assets/images/advisory/DRRS_websize.png"),
  },
  {
    name: "DR. MED. UNIV. MARTIN CALINEATA",
    title:
      "Facharzt für Anästhesiologie, Notfallmedizin, Universitätsklinikum Frankfurt",
    image: require("../../../../assets/images/advisory/Calineata.png"),
  },
  {
    name: "DR. MED. VALENTIN GABELMANN",
    title:
      "Arzt, Master of Health Business Administration, Klinik für Kardiologie, Universitätsklinikum Freiburg",
    image: require("../../../../assets/images/advisory/DRVG_websize.png"),
  },
  {
    name: "WANJA WOLF",
    title:
      "Pflegerische Leitung Konservative Intensivstation, Fachweiterbildung für Anästhesie und Intensivpflege",
    image: require("../../../../assets/images/advisory/WanjawolfBild.jpg"),
  },
];

const viewportWidth = getWidth();

//TODO: change inactive slide

const AdvisoryCarousel = () => {
  const carouselRef = useRef(null);
  const [, setActiveIndex] = useState(0);

  useEffect(() => {
    // const interval = setInterval(() => {
    //   carouselRef.current.snapToNext();
    // }, 3000); // Adjust the interval as needed for autorotate speed
    // return () => clearInterval(interval);
  }, []);

  const renderItem = ({ item, index }) => {
    return (
      <View style={styles.card}>
        <View
          style={{
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 5 },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 3,
            backgroundColor: "white",
            borderRadius: 100,
            marginBottom: 20,
          }}
        >
          <Image source={item.image} style={styles.image} />
        </View>

        {
          <>
            <Text style={styles.name}>{item.name}</Text>
            <Text style={styles.title}>{item.title}</Text>
          </>
        }
      </View>
    );
  };

  return (
    <Carousel
      ref={carouselRef}
      data={teamMembers}
      renderItem={renderItem}
      sliderWidth={viewportWidth}
      itemWidth={viewportWidth * 0.6} // Adjust the item width as per design
      loop={true}
      vertical={false}
      autoplay={true}
      autoplayInterval={1200} // Interval for autoplay
      inactiveSlideScale={0.95}
      inactiveSlideOpacity={0.4}
      onSnapToItem={(index) => setActiveIndex(index)} // Update active index
    />
  );
};

const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    borderRadius: 8,
    padding: 20,
    margin: 10,
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 50,
  },
  name: {
    fontSize: 16,
    ...getFontStyle(600),
    textAlign: "center",
  },
  title: {
    marginTop: 12,
    fontSize: 8,
    ...getFontStyle(300),
    textAlign: "center",
    color: "#000",
  },
});
