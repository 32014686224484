import { SafeAreaView, View } from "react-native";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { PrivacyPolicyContent } from "../more/profile/legal/PrivacyPolicyScreen";

export function PreLoginPrivacyPolicy() {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.ui.background,
      }}
    >
      <View
        style={{
          flex: 1,
          justifyContent: "flex-start",
          width: "100%",
          alignItems: "center",
          backgroundColor: colors.ui.background,
        }}
      >
        <View
          style={{
            marginTop: 0,
            width: "100%",
            alignItems: "center",
            flex: 1,
            overflow: "visible",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              width: "100%",
              alignItems: "center",
              height: "100%",
              maxWidth: Sizes.containerWidth,
            }}
          >
            <PrivacyPolicyContent />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
}
