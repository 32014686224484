import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ResultBox } from "../../api/content/load-all/types";
import { selectResultingCalculation } from "../../functions/calculator/actions";
import { selectResultPageSearchQuery } from "../../functions/current-result/actions";
import { selectCurrentUser } from "../../functions/user/actions";
import BoxComponent from "../boxes/BoxSorter";

export default function ResultBoxes({ pageContent }: { pageContent: any }) {
  const currentUser = useSelector(selectCurrentUser);
  const resultingCalculation = useSelector(selectResultingCalculation);
  const [boxData, setBoxData] = useState<any>([]);
  const resultPageSearchQuery = useSelector(selectResultPageSearchQuery);

  useEffect(() => {
    processBoxes();
  }, [pageContent, resultingCalculation, currentUser, resultPageSearchQuery]);

  function processBoxes() {
    let boxesData = pageContent.boxes ?? pageContent.resultBoxes ?? [];
    const ids = new Set();
    boxesData = boxesData.filter((box) => {
      if (!ids.has(box?.id) && box) {
        ids.add(box.id);
        return true;
      }
      return false;
    });

    let boxes: any = [];

    if (boxesData)
      boxes = boxesData.map((data: ResultBox) => (
        <BoxComponent key={data.id} box={data} />
      ));

    setBoxData(boxes);
  }

  return boxData;
}
