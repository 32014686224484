import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useRef, useState } from "react";
import { SafeAreaView, Share, TouchableOpacity, View } from "react-native";
import ReactNativeBlobUtil from "react-native-blob-util";
import apiCall from "../api/api-call";
import staticColors from "../constants/static-colors";
import getColorScheme from "../hooks/useColorScheme";
import { PDFView } from "./WebBrowserScreen";

export default function ExportPreviewScreen({ route }) {
  const targetExport = route.params.export;
  const [exportUrl, setExportUrl] = useState(targetExport.url);
  const pdfViewRef = useRef();
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  const shareFile = async (url: string) => {
    try {
      const downloadDest = `${
        ReactNativeBlobUtil.fs.dirs.DownloadDir
      }/MEDICEO Export ${new Date().toLocaleDateString()}.pdf`; // Add your file extension

      const response = await ReactNativeBlobUtil.fetch("GET", url);

      const status = response.info().status;

      if (status === 200) {
        // We are successful, let's write the file
        const base64Str = await response.base64();
        await ReactNativeBlobUtil.fs.writeFile(
          downloadDest,
          base64Str,
          "base64"
        );
        const shareOptions = {
          title: "Teile deinen MEDICEO Export",
          url: `file://${downloadDest}`,
          type: "application/pdf", // Add your file type
        };
        await Share.share(shareOptions);
      } else {
        console.log("Error downloading file:", status);
      }
    } catch (error) {
      console.log("Error downloading file:", error);
    }
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 16,
          borderBottomWidth: 1,
          borderBottomColor: staticColors[colorScheme].gray,
          alignItems: "center",
        }}
      >
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Feather name="x" size={24} color={staticColors[colorScheme].text} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => shareFile(exportUrl)}>
          <Feather
            name="share"
            size={24}
            color={staticColors[colorScheme].text}
          />
        </TouchableOpacity>
      </View>
      <PDFView
        onError={async () => {
          console.log("error");
          const response = await apiCall(
            "/api/v3/pdf?filename=" + targetExport.filename,
            null,
            false,
            false,
            "GET"
          );

          if (response?.data?.url) setExportUrl(response.data.url);
        }}
        url={exportUrl}
        pdfViewRef={pdfViewRef}
        page={0}
      />
    </SafeAreaView>
  );
}
