import { Feather } from "@expo/vector-icons";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { colors } from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";

import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import I18n from "../../../../../locales/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import {
  getIconToTitle,
  getImageToTitle,
} from "../../../../components/CategoryIcon";
import SectionHeaderText from "../../../../components/SectionHeaderText";
import { selectAllContent } from "../../../../functions/data/actions";
import getFontStyle from "../../../../functions/getFontSize";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import { selectCurrentUser } from "../../../../functions/user/actions";

const Card = ({ text, vertical = true, description = "" }) => {
  const navigation = useNavigation();
  const medicalSubjectAreas =
    useSelector(selectAllContent).contentList?.content?.medicalSubjectAreaItems;

  const iconSource = getIconToTitle(text);
  const imageSource = getImageToTitle(text);

  return (
    <TouchableOpacity
      onPress={() => {
        let id = "Favoriten";
        if (text === "Favoriten") navigation.navigate("FavoritesScreen");
        else {
          id = getIdToSelection(medicalSubjectAreas, text);

          navigateToNextCMSPage(id, navigation);
        }
        AnalyticsHandler.getInstance().logUserScreenInteraction(
          "content_card_selected",
          "ContentListComponent",
          "User Selected Content Card",
          { contentId: id, contentType: text }
        );
      }}
      style={{
        gap: 8,
        flex: 1,
        padding: 16,
        paddingVertical: 12,
        borderRadius: 12,
        backgroundColor: colors.ui.cardBackground,
      }}
    >
      <View
        style={{
          flexDirection: vertical ? "column" : "row",
          alignItems: vertical ? "flex-start" : "center",
          gap: 8,
        }}
      >
        <View
          style={{
            borderRadius: 24,
            backgroundColor: colors.brand.greys.gE5E5E5,
            padding: 10,
          }}
        >
          {iconSource && <Feather name={iconSource} size={20} color="black" />}

          {imageSource && (
            <Image
              source={imageSource}
              style={{ width: 20, height: 20, resizeMode: "contain" }}
            />
          )}
        </View>
        <Text
          style={{
            fontSize: Sizes.boxText,
            ...getFontStyle(600),
            flex: 1,
          }}
        >
          {text}
        </Text>
        {!vertical && <Feather name="chevron-right" size={20} color="black" />}
      </View>
      {!!description && (
        <Text
          style={{
            marginTop: 8,
            fontSize: Sizes.boxText,
            ...getFontStyle(300),
          }}
        >
          {description}
        </Text>
      )}
    </TouchableOpacity>
  );
};

const SectionGrid = () => {
  const currentUser = useSelector(selectCurrentUser);

  const navigation = useNavigation();

  return (
    <View>
      {/* Row 1 */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 24,
          marginBottom: 24,
          borderBottomColor: colors.ui.divider,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ flex: 1, marginRight: 8 }}>
          <Card
            text="Notfall"
            vertical={false}
            description={I18n.t("quick_access_emergencies")}
          />
        </View>
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Card
            text="Favoriten"
            vertical={false}
            description={I18n.t("list_of_favorites")}
          />
        </View>
      </View>

      {/* Row 2 - "Medikationen" Title and 3 Cards */}
      <SectionHeaderText>{I18n.t("diagnostics_and_therapy")}</SectionHeaderText>
      <Text
        style={{
          ...getFontStyle(300),
          marginVertical: 8,
          fontSize: Sizes.boxText,
        }}
      >
        {I18n.t("overview_diagnostics_algorithms_therapies")}
      </Text>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          paddingBottom: 24,
          marginBottom: 24,
          marginTop: 8,
          borderBottomColor: colors.ui.divider,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ flex: 1, marginRight: 8 }}>
          <Card text={I18n.t("adult")} />
        </View>
        <View style={{ flex: 1, marginHorizontal: 8 }}>
          <Card text={I18n.t("children")} />
        </View>
        <View style={{ flex: 1, marginLeft: 8 }}>
          <Card text={I18n.t("neonates")} />
        </View>
      </View>

      {/* Row 3 */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 16,
        }}
      >
        <View style={{ flex: 1, flexDirection: "row", gap: 20 }}>
          <Card
            text={I18n.t("calculators")}
            vertical={false}
            description={I18n.t("clinical_relevant_calculators")}
          />
          <Card
            text={I18n.t("scores")}
            vertical={false}
            description={I18n.t("clinical_relevant_scores")}
          />
        </View>
      </View>

      {/* Row 4 */}
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ flex: 1 }}>
          <Card
            text={I18n.t("interventions_procedures_algorithms")}
            vertical={false}
            description={I18n.t(
              "interventions_procedures_diagnostics_therapy_algorithms"
            )}
          />
        </View>
      </View>

      {/* Row 5 */}
      {(currentUser.user_type === "dev" || __DEV__) && (
        <TouchableOpacity
          style={{
            flexDirection: "row",
            padding: 12,
            backgroundColor: "white",
            marginTop: 32,
            alignItems: "center",
            borderRadius: 12,
          }}
          onPress={() =>
            navigateToNextCMSPage("673e17d8905c8f452c652e1d", navigation)
          }
        >
          <Text
            style={{
              fontFamily: "HostGrotesk-Regular",
              flex: 1,
              fontSize: Sizes.boxText,
            }}
          >
            Development Content ansehen
          </Text>
          <Feather name="chevron-right" size={20} color="black" />
        </TouchableOpacity>
      )}
    </View>
  );
};

export function getIdToSelection(allData, title) {
  if (!allData) return;
  const page = allData.find((item) => item.title.trim() === title.trim());
  return page?.initialPageId;
}

export default SectionGrid;
