import { parser } from "../../../../api/content/data-helpers";
import { ResultBox } from "../../../../api/content/load-all/types";
import ActionRecommendationBox from "./ActionRecommendationBox";

export default function getActionRecommendationBox(box: ResultBox) {
  let expandableContent = box.expandableContent;
  if (expandableContent !== undefined)
    expandableContent = parser(expandableContent);

  if (box?.image?.isTitle) {
    box.image = {
      isImageInTitle: box.image.isTitle,
      image: {
        file_ref: box.image.url ?? "",
        file_name: box.image.url ?? "",
        file_id: box.image.url ?? "",
      },
      isInExpandable: box.image.isInExpandable,
    };
  }

  return (
    <ActionRecommendationBox
      key={box.id}
      title={parser(box.title)}
      expandableContent={
        expandableContent ? parser(expandableContent) : undefined
      }
      inlineImage={box.inlineImage ?? box.image}
    />
  );
}
