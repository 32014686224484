import { Ionicons } from "@expo/vector-icons";
import { TouchableOpacity, View } from "react-native";
import { colors } from "../constants/static-colors";

export const ArrowForwardWeb = ({ sliderRef }) => {
  const index = sliderRef.current?.currentIndex;

  function forwardAction() {
    sliderRef.current?.snapToNext(false);
  }
  if (index > 3) return null;
  return (
    <View
      style={{
        position: "absolute",
        top: 20,
        bottom: 0,
        height: "100%",
        width: "30%",
        alignItems: "center",
        justifyContent: "center",
        right: 12,
      }}
    >
      <TouchableOpacity
        onPress={forwardAction}
        style={{
          backgroundColor: colors.ui.cardBackground,
          height: 36,
          borderRadius: 25,
          aspectRatio: 1,
          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          elevation: 2,
          shadowOpacity: 0.12,
          shadowRadius: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Ionicons
          name="chevron-forward"
          size={24}
          color={colors.ui.textPrimary}
        />
      </TouchableOpacity>
    </View>
  );
};

export const ArrowBackWeb = ({ sliderRef }) => {
  const index = sliderRef.current?.currentIndex;

  function backwardsAction() {
    sliderRef.current?.snapToPrev(false);
  }
  if (index === 0 || !index) return null;
  return (
    <View
      style={{
        position: "absolute",
        top: 20,
        bottom: 0,
        height: "100%",
        width: "30%",
        alignItems: "center",
        justifyContent: "center",
        left: 12,
      }}
    >
      <TouchableOpacity
        onPress={backwardsAction}
        style={{
          backgroundColor: colors.ui.cardBackground,
          height: 36,
          borderRadius: 25,
          aspectRatio: 1,
          shadowColor: "black",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.12,
          shadowRadius: 4,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Ionicons name="chevron-back" size={24} color={colors.ui.textPrimary} />
      </TouchableOpacity>
    </View>
  );
};

export function Dots({ current, amount }) {
  const n: number[] = [];
  for (let i = 0; i <= amount; i++) {
    n.push(i);
  }

  return (
    <View style={{ flexDirection: "row" }}>
      {n.map((t, index) => {
        const selected = t === current;
        return (
          <View
            key={index}
            style={{
              backgroundColor: selected
                ? colors.ui.primary
                : colors.semantic.disabled,
              height: 10,
              width: selected ? 34 : 10,
              borderRadius: 5,
              marginRight: 12,
            }}
          />
        );
      })}
    </View>
  );
}
