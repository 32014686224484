import { Feather } from "@expo/vector-icons";
import React, { useState } from "react";
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { colors } from "../../../constants/static-colors";
import { Button } from "./Button";

interface MultiSelectQuestionContainerProps {
  question: any;
  opacityAnim: Animated.Value;
  questionContainerAnim: Animated.Value;
  scaleAnim: Animated.Value;
  questionPositionAnim: Animated.Value;
  onContinue: (selectedOptions: string[], nextQuestionID?: string) => void;
}

export const MultiSelectQuestionContainer = ({
  question,
  opacityAnim,
  questionContainerAnim,
  scaleAnim,
  questionPositionAnim,
  onContinue,
}: MultiSelectQuestionContainerProps) => {
  const [selectedOptionIds, setSelectedOptionIds] = useState<string[]>([]);

  const handleOptionSelect = (optionId: string) => {
    setSelectedOptionIds((prev) => {
      // If already selected, remove it; otherwise, add it
      if (prev.includes(optionId)) {
        return prev.filter((id) => id !== optionId);
      } else {
        return [...prev, optionId];
      }
    });
  };

  const handleContinue = () => {
    // Get the selected options' codes to pass to the parent component
    const selectedCodes = question?.answers
      .filter((option) => selectedOptionIds.includes(option.id))
      .map((option) => option.code);

    onContinue(selectedCodes, undefined);
  };

  return (
    <Animated.View
      style={[
        styles.container,
        {
          opacity: Animated.multiply(opacityAnim, questionContainerAnim),
          transform: [
            { scale: scaleAnim },
            { translateY: questionPositionAnim },
          ],
        },
      ]}
    >
      {question.text && (
        <Text style={styles.questionText}>{question?.text}</Text>
      )}

      <View style={styles.optionsContainer}>
        {question.answers.map((option) => {
          const isSelected = selectedOptionIds.includes(option.id);

          return (
            <TouchableOpacity
              key={option.id}
              style={[styles.optionButton, isSelected && styles.selectedOption]}
              onPress={() => handleOptionSelect(option.id)}
              activeOpacity={0.8}
            >
              <Text
                style={[
                  styles.optionText,
                  isSelected && styles.selectedOptionText,
                ]}
              >
                {option?.text}
              </Text>

              {isSelected && (
                <View style={styles.checkContainer}>
                  <Feather
                    name="check"
                    size={20}
                    color={colors.brand.greens.g339988}
                  />
                </View>
              )}
            </TouchableOpacity>
          );
        })}
      </View>

      <View style={styles.buttonContainer}>
        <Button
          title="Weiter"
          onPress={handleContinue}
          style={styles.continueButton}
        />
      </View>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.ui.cardBackground,
    padding: 20,
    borderRadius: 16,
    shadowColor: colors.brand.greys.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    marginBottom: 20,
    borderWidth: 1,
    borderColor: colors.ui.border,
    overflow: "hidden",
  },
  questionText: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 20,
    color: colors.ui.textPrimary,
    fontFamily: "HostGrotesk-Regular",
  },
  optionsContainer: {
    marginBottom: 20,
  },
  optionButton: {
    backgroundColor: colors.ui.background,
    padding: 15,
    borderRadius: 12,
    marginBottom: 12,
    borderWidth: 1,
    borderColor: colors.ui.border,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  selectedOption: {
    backgroundColor: colors.ui.highlight,
    borderColor: colors.brand.greens.g339988,
  },
  optionText: {
    color: colors.ui.textPrimary,
    fontSize: 15,
    fontFamily: "HostGrotesk-Regular",
    flex: 1,
  },
  selectedOptionText: {
    fontWeight: "500",
    color: colors.brand.greens.g339988,
  },
  checkContainer: {
    marginLeft: 10,
  },
  buttonContainer: {
    marginTop: 0,
  },
  continueButton: {
    backgroundColor: colors.brand.greens.g339988,
  },
  disabledButton: {
    backgroundColor: colors.semantic.disabled,
  },
});
