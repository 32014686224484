import SegmentedControl from "@react-native-segmented-control/segmented-control";
import { debounce } from "lodash";

import { useCallback, useEffect, useState } from "react";
import { Platform, StyleSheet, Text, TextInput, View } from "react-native";
import { Portal } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../../locales/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import { Unit } from "../../../../api/content/load-all/types";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { showInlineNotification } from "../../../../entry/Root";
import {
  addToCurrentUnits,
  bulkAddToCurrentUnits,
  removeFromCurrentUnits,
  selectAllAmounts,
  selectPatientData,
  setAmount,
} from "../../../../functions/calculator/actions";
import configureAnimations from "../../../../functions/configure-animations";
import { setCalculationBoxHeight } from "../../../../functions/current-result/actions";
import { store } from "../../../../functions/store";
import getColorScheme from "../../../../hooks/useColorScheme";
import useScrollTopMargin from "../../../../hooks/useScrollTopMargin";
import BlurViewWrapper from "../../../BlurViewWrapper/BlurViewWrapper";
import InViewPort from "../../../InViewComponent";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../../Notifications/NotificationComponent";

interface AddWeightAgeBox {
  readonly units: Unit[];
  readonly contained?: boolean;
  readonly title?: string;
}

export function shouldNotRenderNewCalcBox(props, patientData) {
  if (
    patientData?.weight &&
    (props.units.find((unit) => unit.title === "kg") ||
      props.units.find((unit) => unit.title === "g"))
  )
    return true;

  console.log("PATIENT", !!patientData?.size);

  if (
    patientData?.size &&
    (props.units.find((unit) => unit.title === "cm") ||
      props.units.find((unit) => unit.title === "m"))
  )
    return true;

  return false;
}

export function NewCalculatorBox(props: AddWeightAgeBox) {
  const patientData = useSelector(selectPatientData);
  const colorScheme = getColorScheme();
  const allAmounts = useSelector(selectAllAmounts);
  const [index, setIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(undefined);

  const currentUnit = props.units?.[index] ?? undefined;
  const marginTop = useScrollTopMargin();
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const [insertedBoxHeight, setInsertedBoxHeight] = useState(0);
  const [inView, setInView] = useState(true);
  const [isVisible, setIsVisible] = useState(true); // Start as visible, will update based on InView detection

  const dispatch = useDispatch();

  const [text, setText] = useState<string>(
    allAmounts?.[currentUnit.key] ? allAmounts?.[currentUnit.key] + "" : ""
  );

  useEffect(() => {
    if (inView) {
      dispatch(setCalculationBoxHeight(0));
    } else {
      dispatch(setCalculationBoxHeight(insertedBoxHeight));
    }
  }, [insertedBoxHeight, inView]);

  useEffect(() => {
    configureAnimations();
    // Debounce visibility update to avoid flickering

    setIsVisible(inView);
  }, [inView]);

  useEffect(() => {
    if (prevIndex !== undefined)
      resetValues(props.units[prevIndex].key, currentUnit.conversionUnits);

    if (text !== "")
      setValues(text, currentUnit.key, currentUnit.conversionUnits);
  }, [index]);

  if (shouldNotRenderNewCalcBox(props, patientData)) return null;

  return (
    <InViewPort onChange={(isInView) => setInView(isInView)} viewOffset={10}>
      <CalculatorBoxContent
        {...{
          props,
          buttonEnabled,
          setButtonEnabled,
          text,
          setText,
          currentUnit,
          setIndex,
          setPrevIndex,
        }}
      />
      {!isVisible && (
        <Portal>
          <View
            key={isVisible ? "visible" : "hidden"}
            onLayout={(e) => {
              setInsertedBoxHeight(e.nativeEvent.layout.height);
            }}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          >
            <BlurViewWrapper
              style={{
                width: "100%",
                zIndex: 5,
                opacity: 1,
                paddingBottom: 16,
                flexDirection: "row",
                paddingTop: marginTop - 16,
                backgroundColor: Colors[colorScheme].background + "cc",
                paddingHorizontal: Sizes.defaultContainerPadding,

                alignItems: "center",
              }}
            >
              <View
                style={{
                  flex: 2,
                }}
              >
                <CalculatorBoxContent
                  {...{
                    props: { contained: true, units: props.units },
                    buttonEnabled,
                    setButtonEnabled,
                    text,
                    setText,
                    currentUnit,
                    setIndex,
                    setPrevIndex,
                  }}
                />
              </View>
            </BlurViewWrapper>
          </View>
        </Portal>
      )}
    </InViewPort>
  );
}

const CalculatorBoxContent = ({
  props,
  buttonEnabled,
  setButtonEnabled,
  text,
  setText,
  currentUnit,
  setIndex,
  setPrevIndex,
}) => {
  const colorScheme = getColorScheme();

  const styles = createStyles(colorScheme, props.contained);

  const checkBounds = useCallback(
    debounce((currentUnit: Unit, value: string) => {
      const number = parseFloat(value);

      function showUpperNotification() {
        showInlineNotification({
          text:
            i18n.locale === "de"
              ? "Der eingegebene Wert scheint hoch zu sein. Bist du sicher?"
              : i18n.t("value_to_high"),
          type: InlineNotificationType.ERROR,
          direction: InlineNotificationDirection.FROM_TOP,
        });
      }
      function showLowerNotification() {
        showInlineNotification({
          text:
            i18n.locale === "de"
              ? "Der eingegebene Wert scheint niedrig zu sein. Bist du sicher?"
              : i18n.t("value_to_low"),
          type: InlineNotificationType.ERROR,
          direction: InlineNotificationDirection.FROM_TOP,
        });
      }

      if (
        number === undefined ||
        !currentUnit.maxValue ||
        !currentUnit.minValue
      )
        return;

      if (number > currentUnit.maxValue) {
        showUpperNotification();
      }
      if (number < currentUnit.minValue) {
        showLowerNotification();
      }
    }, 1500),
    []
  );

  const debouncedUpdate = useCallback(
    debounce((currentUnit, t) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "new_calculator_input_changed",
        "NewCalculatorBox",
        "User Updated Calculator Input",
        { key: currentUnit.key, newValue: t }
      );
    }, 500),
    []
  );

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <View
            style={{
              ...styles.result_container,
              borderWidth: text ? 2 : 0,
              borderColor: Colors[colorScheme].blue,
            }}
          >
            <View
              style={[
                styles.sidebar,
                {
                  backgroundColor: buttonEnabled
                    ? Colors[colorScheme].blue
                    : Colors[colorScheme].darkGray,
                },
              ]}
            />
            <View style={styles.content}>
              <TextInput
                style={{
                  fontWeight: "500",
                  fontSize: Sizes.boxText,
                  marginRight: Sizes.defaultBoxPadding,
                  flex: 1,
                  paddingVertical:
                    Platform.OS === "android"
                      ? 12
                      : props.contained
                      ? 14
                      : Sizes.defaultBoxPadding + 8,
                  color: Colors[colorScheme].text,
                }}
                placeholderTextColor={Colors[colorScheme].placeholder}
                placeholder={currentUnit?.placeholder}
                keyboardType={"numeric"}
                value={text}
                onChangeText={(t) => {
                  setText(t);
                  debouncedUpdate(currentUnit, t);

                  if (t === "") {
                    resetValues(currentUnit.key, currentUnit.conversionUnits);
                    setButtonEnabled(false);
                  } else {
                    checkBounds(currentUnit, t);
                    setValues(t, currentUnit.key, currentUnit.conversionUnits);
                    setButtonEnabled(true);
                  }
                }}
              />
              <GetUnitComponent
                units={props.units}
                setIndex={setIndex}
                setPrevIndex={setPrevIndex}
                collapsed={props.contained}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

function resetValues(key, comparableUnits) {
  store.dispatch(removeFromCurrentUnits(key));
  store.dispatch(setAmount({ [key]: undefined }));
  if (!comparableUnits) return;
  for (const unit of comparableUnits) {
    store.dispatch(setAmount({ [unit.unitToConvertInto[0].key]: undefined }));
    store.dispatch(removeFromCurrentUnits(unit.unitToConvertInto[0].key));
  }
}

function setValues(text, key, comparableUnits) {
  if (typeof text !== "string") return;
  const value = parseFloat(text?.replace(/,/g, "."));
  store.dispatch(setAmount({ [key]: value }));

  if (!comparableUnits) {
    store.dispatch(addToCurrentUnits(key));
    return;
  }

  store.dispatch(
    bulkAddToCurrentUnits([
      key,
      ...comparableUnits.map((u) => u.unitToConvertInto[0].key),
    ])
  );
  for (const unit of comparableUnits) {
    store.dispatch(
      setAmount({
        [unit.unitToConvertInto[0].key]: value * unit.conversionMultiplier,
      })
    );
  }
}

const GetUnitComponent = ({ units, setIndex, setPrevIndex, collapsed }) => {
  if (units.length === 1)
    return <UnitComponent collapsed={collapsed} title={units[0].title} />;
  else
    return (
      <UnitSelector
        units={units}
        setIndex={setIndex}
        setPrevIndex={setPrevIndex}
      />
    );
};

const UnitSelector = ({ units, setIndex, setPrevIndex }) => {
  const colorScheme = getColorScheme();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "new_calculator_unit_changed",
      "NewCalculatorBox",
      "User Updated Unit",
      { unit: units[selectedIndex].title }
    );
  }, [selectedIndex]);

  return (
    <View style={{ flex: 0.8 }}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <SegmentedControl
        values={units.map((u) => u.title)}
        selectedIndex={selectedIndex}
        tintColor={Colors[colorScheme].blue}
        backgroundColor={Colors[colorScheme].lightGray}
        fontStyle={{
          color: Colors[colorScheme].text,
          fontSize: 12,
          fontWeight: "800",
        }}
        activeFontStyle={{
          color: Colors[colorScheme].background,
          fontSize: 12,
          fontWeight: "800",
        }}
        onChange={(event) => {
          setPrevIndex(selectedIndex);
          setIndex(event.nativeEvent.selectedSegmentIndex);
          setSelectedIndex(event.nativeEvent.selectedSegmentIndex);
        }}
      />
    </View>
  );
};

const UnitComponent = ({ title, collapsed }) => {
  const colorScheme = getColorScheme();
  return (
    <View
      style={{
        padding: collapsed ? 4 : 6,
        borderRadius: 4,
        borderWidth: 2,
        borderColor: Colors[colorScheme].blue,
      }}
    >
      <Text
        style={{
          fontWeight: "bold",
          fontSize: collapsed ? 13 : 15,
          color: Colors[colorScheme].blue,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const createStyles = (scheme, contained) => {
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      overflow: "visible",
    },
    innerContainer: {
      shadowColor: Colors[scheme].text,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      borderRadius: 20,
      shadowOpacity: contained ? 0.05 : 0.1,
      shadowRadius: 4,
      width: "100%",
      overflow: "visible",
      elevation: 2,
    },
    result_container: {
      borderRadius: contained ? 8 : 12,
      flexDirection: "row",
      overflow: "hidden",
      //flex: 1,
      backgroundColor: Colors[scheme].foreground,
      //borderWidth: 2,
      borderColor: Colors[scheme].blue,
      elevation: 3,
    },
    sidebar: {
      width: 0,
      height: "100%",
    },
    content: {
      paddingRight: 12,
      paddingLeft: 12,
      flex: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "row",
    },
  });
  return styles;
};
