import { Feather } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useCallback, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import getPageContent from "../../../../api/content/get-page-content";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { selectAllContent } from "../../../../functions/data/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import getColorScheme from "../../../../hooks/useColorScheme";

import isLargerDevice from "../../../../hooks/isLargerDevice";
import { getScoreInterpretationBoxes } from "../../../../screens/ScoreScreen/components/ScoreInterpretation";
import { IconToCategory } from "../../../CategoryIcon";
import ResultBoxes from "../../../result/ResultBoxComponent";

export default function ScoreFurtherActionBox({ box }) {
  const [interpretationContent, setInterpretationContent] =
    useState<any>(undefined);

  const euroscoreId =
    useSelector(selectAllContent).medicalCategories?.content?.euroscoreId;

  const [interpretationBoxes, setInterpretationBoxes] =
    useState<any>(undefined);

  const navigation = useNavigation();
  const colorScheme = getColorScheme();

  useFocusEffect(
    useCallback(() => {
      if (interpretationContent?.caseData?.[box.scoreId]?.answers) {
        const scoreData = getPageContent(box.scoreId);
        const _interpretationBoxes = getScoreInterpretationBoxes(
          interpretationContent?.caseData?.[box.scoreId]?.score,
          scoreData?.scoreInterpretationElements,
          interpretationContent?.caseData?.[box.scoreId]?.answers,
          box.scoreId === euroscoreId
        );

        setInterpretationBoxes(_interpretationBoxes?.interpretationBoxes);
      }
    }, [interpretationContent, box.scoreId, euroscoreId])
  );

  return (
    <View style={{ marginTop: 20, borderRadius: 12, overflow: "hidden" }}>
      <View
        style={{
          width: 7,
          top: 0,
          bottom: 0,
          position: "absolute",
          left: 0,
          backgroundColor: staticColors[colorScheme].gray,
          zIndex: 10,
        }}
      />

      <TouchableOpacity
        key={box.id}
        style={{
          flexDirection: "row",
          padding: 16,
          paddingLeft: 19,
          backgroundColor: staticColors[colorScheme].foreground,
          alignItems: "center",
        }}
        onPress={() => {
          navigateToNextCMSPage(box.scoreId, navigation, true, {
            setInterpretationContent: setInterpretationContent,
            additionalCaseData: interpretationContent,
          });
        }}
      >
        <View
          style={{
            aspectRatio: 1,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
            alignSelf: "flex-start",
            borderRadius: 100,
            backgroundColor: "#ddddddcc",
            padding: isLargerDevice() ? 8 : 4,
            marginRight: 12,
          }}
        >
          <IconToCategory iconColor={"black"} title={"Scores"} size={16} />
        </View>

        <Text
          style={{
            fontWeight: "600",
            fontSize: Sizes.boxText,
            flex: 1,
          }}
        >
          {box.title}
        </Text>
        <Feather
          name="chevron-right"
          size={24}
          color={staticColors[colorScheme].text}
        />
      </TouchableOpacity>

      {interpretationContent && (
        <View
          style={{
            padding: 12,
            paddingLeft: 19,
            paddingTop: 0,
            borderTopColor: staticColors[colorScheme].gray,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderBottomEndRadius: 12,
            borderBottomStartRadius: 12,
            backgroundColor: staticColors[colorScheme].background,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            <ResultBoxes
              pageContent={{
                boxes: interpretationBoxes,
              }}
            />
          </View>
          <TouchableOpacity
            onPress={() => setInterpretationContent(undefined)}
            style={{ marginTop: 20, marginLeft: 12 }}
          >
            <Feather
              name="x-circle"
              size={24}
              color={staticColors[colorScheme].text}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}
