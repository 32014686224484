import { Feather } from "@expo/vector-icons";
import { Text, TouchableOpacity, View } from "react-native";
import { colors } from "../../../../constants/static-colors";
import getFontStyle from "../../../../functions/getFontSize";
import { addValueToInteractions } from "../../../../functions/user-interaction-handler";

export const MaintenanceInfoComponent = ({ info }) => {
  return (
    <View
      style={{
        backgroundColor: colors.semantic.error,
        padding: 12,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <Text
          style={{
            ...getFontStyle(700),
            color: "white",
          }}
        >
          {info}
        </Text>
      </View>
      <TouchableOpacity
        style={{
          marginLeft: 20,
        }}
        onPress={() => addValueToInteractions("hide_maintenance_info", "true")}
      >
        <Feather name="x" size={24} color="white" />
      </TouchableOpacity>
    </View>
  );
};
