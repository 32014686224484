import { useSelector } from "react-redux";
import CustomRenderHTMLComponent from "../CustomRenderHTMLComponent";
import transformDefaultHtmlContent from "./helpers/default-content-transformation";
import {
  selectAllAmounts,
  selectCurrentUnits,
} from "../../functions/calculator/actions";
import { selectShouldShowSources } from "../../functions/current-result/actions";

export const BoxTextComponent = ({
  content,
  style,
  color,
  noBulletStyles = false,
}: {
  readonly content: string | undefined;
  readonly style: any;
  readonly color?: string;
  readonly noBulletStyles?: boolean;
}) => {
  const allUnits = useSelector(selectAllAmounts);

  const currentUnits = useSelector(selectCurrentUnits);
  const showSources = useSelector(selectShouldShowSources);

  const _content = transformDefaultHtmlContent(
    content,
    showSources,
    allUnits,
    currentUnits
  );

  return (
    <CustomRenderHTMLComponent
      tagsStyles={style}
      color={color}
      source={{
        html: _content,
      }}
      noBulletStyles={noBulletStyles}
    />
  );
};
