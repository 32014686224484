import { Feather } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useCallback, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useSelector } from "react-redux";
import getPageContent from "../../../../api/content/get-page-content";
import { colors } from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import { selectAllContent } from "../../../../functions/data/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";

import getFontStyle from "../../../../functions/getFontSize";
import isLargerDevice from "../../../../hooks/isLargerDevice";
import { getScoreInterpretationBoxes } from "../../../../screens/ScoreScreen/components/ScoreInterpretation";
import { IconToCategory } from "../../../CategoryIcon";
import ResultBoxes from "../../../result/ResultBoxComponent";

export default function ScoreFurtherActionBox({ box }) {
  const [interpretationContent, setInterpretationContent] =
    useState<any>(undefined);

  const euroscoreId =
    useSelector(selectAllContent).medicalCategories?.content?.euroscoreId;

  const [interpretationBoxes, setInterpretationBoxes] =
    useState<any>(undefined);
  const [score, setScore] = useState<any>(undefined);

  const navigation = useNavigation();

  useFocusEffect(
    useCallback(() => {
      if (interpretationContent?.answers) {
        const scoreData = getPageContent(box.scoreId);
        const _interpretationBoxes = getScoreInterpretationBoxes(
          interpretationContent?.score,
          scoreData?.scoreInterpretationElements,
          interpretationContent?.answers,
          box.scoreId === euroscoreId
        );
        setScore(interpretationContent?.score);
        setInterpretationBoxes(_interpretationBoxes?.interpretationBoxes);
      }
    }, [interpretationContent, box.scoreId, euroscoreId])
  );

  return (
    <View style={{ marginTop: 20, borderRadius: 12, overflow: "hidden" }}>
      <View
        style={{
          width: 7,
          top: 0,
          bottom: 0,
          position: "absolute",
          left: 0,
          backgroundColor: colors.semantic.disabled,
          zIndex: 10,
        }}
      />

      <TouchableOpacity
        key={box.id}
        style={{
          flexDirection: "row",
          padding: 16,
          paddingLeft: 19,
          backgroundColor: colors.ui.cardBackground,
          alignItems: "center",
        }}
        onPress={() => {
          navigateToNextCMSPage(box.scoreId, navigation, true, {
            setInterpretationContent,
            interpretationContent,
          });
        }}
      >
        <View
          style={{
            aspectRatio: 1,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
            alignSelf: "flex-start",
            borderRadius: 100,
            backgroundColor: "#ddddddcc",
            padding: isLargerDevice() ? 8 : 4,
            marginRight: 12,
          }}
        >
          <IconToCategory iconColor={"black"} title={"Scores"} size={16} />
        </View>

        <Text
          style={{
            ...getFontStyle(600),
            fontSize: Sizes.boxText,
            flex: 1,
          }}
        >
          {box.title}
        </Text>
        <Feather name="chevron-right" size={24} color={colors.ui.textPrimary} />
      </TouchableOpacity>

      {interpretationContent && (
        <View
          style={{
            padding: 12,
            paddingLeft: 19,
            paddingTop: 0,
            borderTopColor: colors.semantic.disabled,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderBottomEndRadius: 12,
            borderBottomStartRadius: 12,
            backgroundColor: colors.ui.background,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            <Text
              style={{
                flex: 1,
                paddingLeft: 12,
                paddingTop: 12,
                paddingRight: 12,
                marginTop: 8,
                fontSize: 20,
                ...getFontStyle(800),
                color: colors.ui.textPrimary,
              }}
            >
              {score}{" "}
              <Text
                style={{
                  fontSize: Sizes.boxText,
                  ...getFontStyle(400),
                }}
              >
                Punkte
              </Text>
            </Text>
            <View style={{ flex: 1 }}>
              <ResultBoxes
                pageContent={{
                  boxes: interpretationBoxes,
                }}
              />
            </View>
          </View>
          <TouchableOpacity
            onPress={() => setInterpretationContent(undefined)}
            style={{ marginTop: 20, marginLeft: isLargerDevice() ? 20 : 12 }}
          >
            <Feather name="x-circle" size={24} color={colors.ui.textPrimary} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}
