import { useState } from "react";
import { SafeAreaView, View } from "react-native";

import i18n from "../../locales/i18n";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import ResultBoxes from "../components/result/ResultBoxComponent";
import { SourcesView } from "../components/result/SourcesView/SourcesView";
import TitleText from "../components/result/TitleText";
import { ResultPagesWrapper } from "../components/ResultPagesWrapper";
import SearchResults from "../components/search-tab/SearchResults";
import Colors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { showInlineNotification } from "../entry/Root";
import getColorScheme from "../hooks/useColorScheme";
import { SearchBar } from "./tab-navigator-screens/components/SearchBar";

export default function CMSNodePageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const colorScheme = getColorScheme();
  const [query, setQuery] = useState("");
  const filters = [pageContent.fieldTitle];
  const [selected, setSelected] = useState(false);
  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const scoreId = undefined;

  const isScorePage = pageContent?._id === scoreId;

  if (!pageContent) {
    navigation.goBack();
    showInlineNotification({
      text:
        i18n.locale === "de"
          ? "Ein Fehler ist aufgetreten. Bitte aktualisiere die Inhalte auf dem Home Screen"
          : i18n.t("error_occurred_refresh_screen"),
      type: InlineNotificationType.ERROR,
    });

    return null;
  }

  return (
    <ResultPagesWrapper
      navigation={navigation}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        addToHistory: false,
        logUserScreenInteraction: {
          screen: "NodePageScreen",
          action: "node_page_opened",
          values: pageContent?.title,
          id: pageContent?._id,
        },
      }}
    >
      <SafeAreaView
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          //flex: 1,
          flex: 1,
          backgroundColor: Colors[colorScheme].background,
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: Colors[colorScheme].background,
            zIndex: 10,
            paddingHorizontal: Sizes.defaultContainerPadding,
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 8,
              }}
            >
              <TitleText
                style={{ flexShrink: 1 }}
                title={pageContent.fieldTitle}
              />
            </View>
          </View>
          <SearchBar
            collapsed
            style={{ marginTop: 20, marginBottom: 16 }}
            placeholder={
              i18n.t("search_placeholder_detail") + pageContent.fieldTitle
            }
            query={query}
            setQuery={setQuery}
            selected={selected}
            setSelected={setSelected}
          />
        </View>
        {!selected ? (
          <View
            style={{
              paddingHorizontal: Sizes.defaultContainerPadding,
              width: "100%",
              flex: 1,
            }}
          >
            <View
              style={{
                width: "100%",
                flex: 1,

                justifyContent: "center",
              }}
            >
              <ResultBoxes
                pageContent={
                  isScorePage ? getSortedNodes(pageContent) : pageContent
                }
              />
            </View>
            <SourcesView {...{ pageContent }} />
          </View>
        ) : (
          <View
            style={{
              padding: Sizes.defaultContainerPadding,
              flex: 1,
              width: "100%",
            }}
          >
            <SearchResults {...{ query, setQuery, filters }} />
          </View>
        )}
      </SafeAreaView>
    </ResultPagesWrapper>
  );
}

function getSortedNodes(pageContent) {
  const _pageContent = JSON.parse(JSON.stringify(pageContent));
  const boxes = _pageContent.boxes ?? _pageContent.resultBoxes;

  _pageContent.boxes = boxes.sort((d1, d2) => {
    if (
      d1.typeId === "furtherResultPageBox" &&
      d2.typeId !== "furtherResultPageBox"
    ) {
      return 1;
    }
    if (
      d1.typeId !== "furtherResultPageBox" &&
      d2.typeId === "furtherResultPageBox"
    ) {
      return -1;
    }
    return d1.fieldTitle.localeCompare(d2.fieldTitle);
  });

  return _pageContent;
}
