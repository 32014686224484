import { useMemo } from "react";
import { View } from "react-native";
import ScoreElementToContent from "./ScoreElementToContent";

const ScoreElements = ({
  data,
  isEuroscore,
  answers,
  setAnswers,
  setScore,
}) => {
  return useMemo(
    () => (
      <View style={{ marginTop: 20 }}>
        {data.scoreElements?.map((scoreElement, index) => {
          return (
            <ScoreElementToContent
              key={index}
              {...{
                index,
                scoreElement,
                answers,
                setAnswers,
                setScore,
                isEuroscore,
              }}
            />
          );
        })}
      </View>
    ),
    [data.scoreElements, isEuroscore, answers, setAnswers, setScore]
  );
};

export default ScoreElements;
