import {
  Feather,
  FontAwesome,
  FontAwesome5,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";

import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import { showLightboxImage } from "../../../entry/Root";
import { configureExpansionAnimation } from "../../../functions/configure-animations";
import AnimatedWrapper from "../../AnimatedWrapper/AnimatedWrapper";
import { BoxTextComponent } from "../../custom-box-text/CustomBoxTextComponent";
import ImageWrapper from "../../ImageWrapper";
import {
  getSharedStylesDosingRecommendation,
  getSharedStylesLargeContent,
} from "../SharedStyles";

import { InlineImage } from "./inline-image-types";
import getFontStyle from "../../../functions/getFontSize";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import { useNavigation } from "@react-navigation/native";

const INTERACTIONS_MAPPING = {
  edoxaban: "65776f86df2065a5368be145",
  apixaban: "65776f86df2065a5368be142",
  rivaroxaban: "65776f86df2065a5368be143",
  dabigatran: "65776f86df2065a5368be144",
};

function getColorToType(type) {
  if (!type) type = "";
  switch (type.toLowerCase()) {
    case "warning":
      return colors.semantic.error;
    case "hint":
      return colors.brand.greys.g737373;
    case "action":
      return colors.semantic.success;
    case "dosing":
      return colors.semantic.info;
    case "largecontent":
      return colors.ui.primary;
    default:
      return colors.semantic.success;
  }
}

export default function LargeStandardBox({
  title,
  content,
  inlineImage,
  iconType,
  boxType,
  expandableContent,
  insertedContent,
  isNote,
}: {
  readonly title: string;
  readonly content: string;
  readonly inlineImage?: InlineImage;
  readonly iconType?: string;
  readonly boxType?: string;
  readonly expandableContent?: string;
  readonly insertedContent?: any;
  readonly isNote?: boolean;
}) {
  const boxAccentColor = getColorToType(boxType);

  const backgroundColor = colors.ui.cardBackground;

  const [expanded, setExpanded] = useState(false);

  const styles = getStyles(
    boxAccentColor,
    content || expanded || insertedContent,
    backgroundColor
  );

  const hasInlineImage = inlineImage?.image?.file_ref ?? inlineImage?.url;

  const imageInExpandable = inlineImage?.isInExpandable;
  const navigation = useNavigation();

  const imageURI =
    inlineImage?.image?.file_ref ?? "https://" + inlineImage?.url;

  // Determine if content is expandable
  const isExpandable =
    (!content && expandableContent) ||
    imageInExpandable ||
    (content && expandableContent);

  // Handle expansion toggle
  const handleToggleExpand = () => {
    configureExpansionAnimation();
    setExpanded((e) => !e);

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      expanded ? "content_collapsed" : "content_expanded",
      "LargeStandardBox",
      `User ${expanded ? "Collapsed" : "Expanded"} Content`,
      { title }
    );
  };

  const isNOACTemp = title.includes("&reg;");

  return (
    <View style={styles.outerContainer}>
      <View style={styles.innerContainer}>
        <View style={styles.contentContainer}>
          {/* <View style={styles.accentBar} /> */}

          <View style={styles.columnContainer}>
            <TouchableOpacity
              disabled={!isExpandable}
              hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
              onPress={handleToggleExpand}
              style={styles.titleContainer}
            >
              {isNote && (
                <View style={{ flexDirection: "row", gap: 4 }}>
                  <Feather
                    name="edit"
                    size={20}
                    color={colors.ui.textPrimary}
                    style={{
                      opacity: 0.5,
                    }}
                  />
                </View>
              )}
              <View style={{ flex: 1, marginRight: 8 }}>
                <BoxTextComponent
                  style={getSharedStylesDosingRecommendation(
                    colors.ui.textPrimary
                  )}
                  color={boxAccentColor}
                  content={isNote ? title : "<b>" + title + "</b>"}
                />
              </View>
              {iconType && (
                <View style={{ marginRight: 8 }}>
                  <IconToType type={iconType} />
                </View>
              )}
              {isNOACTemp && (
                <TouchableOpacity
                  onPress={() => {
                    const id = Object.keys(INTERACTIONS_MAPPING).find((key) =>
                      title.toLowerCase().includes(key)
                    );
                    if (id)
                      navigateToNextCMSPage(
                        INTERACTIONS_MAPPING[id],
                        navigation,
                        true
                      );
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      color: colors.ui.textPrimary,
                      ...getFontStyle(400),
                      fontFamily: "HostGrotesk-Regular",
                      textDecorationLine: "underline",
                    }}
                  >
                    Interaktionen
                  </Text>
                </TouchableOpacity>
              )}

              {/* New modern expand button - positioned on the right side */}
              {isExpandable && (
                <View
                  style={[
                    styles.expandButton,
                    expanded && styles.expandButtonActive,
                  ]}
                >
                  <Feather
                    name={expanded ? "chevron-up" : "chevron-down"}
                    size={18}
                    color={expanded ? "#FFF" : colors.ui.textPrimary}
                  />
                </View>
              )}
            </TouchableOpacity>

            {(!!content || expanded || insertedContent) && (
              <TouchableOpacity
                hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
                onPress={handleToggleExpand}
                disabled={!(content && expandableContent)}
                style={{
                  ...styles.textContainer,
                }}
              >
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  {!insertedContent && (
                    <BoxTextComponent
                      color={boxAccentColor}
                      style={getSharedStylesLargeContent()}
                      content={content}
                    />
                  )}
                  {insertedContent && (
                    <View style={{ flex: 1 }}>{insertedContent}</View>
                  )}
                </View>
              </TouchableOpacity>
            )}

            {hasInlineImage && !imageInExpandable && (
              <TouchableOpacity
                onLongPress={() =>
                  showLightboxImage({
                    uri: imageURI,
                  })
                }
                onPress={() =>
                  showLightboxImage({
                    uri: imageURI,
                  })
                }
                style={styles.imageContainer}
              >
                <ImageWrapper
                  style={{
                    ...styles.imageStyle,
                    aspectRatio: inlineImage?.aspectRatio
                      ? 1 / inlineImage?.aspectRatio
                      : 9 / 16,

                    maxHeight: 700,
                    width: "100%",
                    alignSelf: "center",
                  }}
                  source={{ uri: imageURI }}
                />
              </TouchableOpacity>
            )}
            <AnimatedWrapper style={{ width: "100%", height: "100%" }}>
              {expanded && (
                <View style={{ ...styles.textContainer }}>
                  <View style={{ flex: 1, marginTop: -20 }}>
                    <BoxTextComponent
                      color={boxAccentColor}
                      style={getSharedStylesLargeContent()}
                      content={expandableContent}
                    />
                    {hasInlineImage && imageInExpandable && (
                      <TouchableOpacity
                        onLongPress={() =>
                          showLightboxImage({
                            uri: imageURI,
                          })
                        }
                        onPress={() =>
                          showLightboxImage({
                            uri: imageURI,
                          })
                        }
                        style={styles.imageContainer}
                      >
                        <ImageWrapper
                          style={{
                            ...styles.imageStyle,
                            aspectRatio: inlineImage?.aspectRatio
                              ? 1 / inlineImage?.aspectRatio
                              : 9 / 16,

                            maxHeight: 700,
                            width: "100%",
                            alignSelf: "center",
                          }}
                          source={{ uri: imageURI }}
                        />
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              )}
            </AnimatedWrapper>
          </View>
        </View>
      </View>
    </View>
  );
}

const getStyles = (boxAccentColor, showBorder, backgroundColor) => {
  const styles = StyleSheet.create({
    outerContainer: {
      marginTop: 20,
      width: "100%",
      overflow: "hidden",
    },
    innerContainer: {
      borderRadius: 10,
      backgroundColor,
      overflow: "hidden",
      borderLeftColor: boxAccentColor,
      borderLeftWidth: 5,
    },
    contentContainer: {
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
    },
    columnContainer: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
    titleContainer: {
      padding: Sizes.defaultBoxPadding,
      width: "100%",
      flexDirection: "row",
      borderBottomColor: boxAccentColor,
      alignItems: "center",
      borderBottomWidth: showBorder ? 2 : 0,
      gap: 8,
    },
    // New modern expand button styles
    expandButton: {
      width: 32,
      height: 32,
      marginVertical: -4,
      borderRadius: 16,
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      justifyContent: "center",
      alignItems: "center",
    },
    expandButtonActive: {
      backgroundColor: boxAccentColor,
    },
    textContainer: {
      marginHorizontal: Sizes.defaultBoxPadding,
      paddingVertical: Sizes.defaultBoxPadding,
      alignSelf: "stretch",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      gap: 18,
    },
    imageContainer: {
      alignItems: "center",
      alignSelf: "stretch",
      overflow: "hidden",
      paddingRight: 3.5,
    },
    imageStyle: {
      flex: 1,
      resizeMode: "contain",
      marginBottom: 4,
      borderBottomEndRadius: 8,
      marginTop: 0,
      width: "100%",
      alignSelf: "stretch",
    },
  });
  return styles;
};

const IconToType = ({ type }) => {
  switch (type) {
    case "temperature":
      return (
        <FontAwesome
          name="thermometer-half"
          size={20}
          color={colors.ui.textPrimary}
        />
      );
    case "material":
      return (
        <FontAwesome5 name="tools" size={18} color={colors.ui.textPrimary} />
      );
    case "labor":
      return (
        <MaterialCommunityIcons
          name="test-tube"
          size={20}
          color={colors.ui.textPrimary}
        />
      );
    case "cardio":
      return (
        <Feather name="activity" size={20} color={colors.ui.textPrimary} />
      );
    case "Kein Icon":
      return null;
    case "medication":
    default:
      return (
        <MaterialCommunityIcons
          name="pill"
          size={20}
          color={colors.ui.textPrimary}
        />
      );
  }
};
