import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import { colors } from "../constants/static-colors";

const NotificationSkeletonLoader = () => {
  return (
    <View style={{ flex: 1, paddingTop: 12, paddingBottom: 20 }}>
      {/* Generate multiple skeleton items */}
      {[1, 2, 3, 4].map((_, index) => (
        <NotificationItemSkeleton key={index} />
      ))}
    </View>
  );
};

const NotificationItemSkeleton = () => {
  return (
    <View
      style={{
        marginBottom: 10,
        borderRadius: 12,
        overflow: "hidden",
        shadowColor: "#000",
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        elevation: 2,
        backgroundColor: colors.ui.cardBackground,
        borderLeftWidth: 3,
        borderLeftColor: colors.ui.secondary || "#888",
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <SkeletonPlaceholder
          backgroundColor={colors.ui.background}
          highlightColor="#f2f8fc"
        >
          <SkeletonPlaceholder.Item
            flexDirection="row"
            alignItems="flex-start"
            padding={14}
          >
            {/* Date */}
            <SkeletonPlaceholder.Item width="100%">
              <SkeletonPlaceholder.Item
                width={80}
                height={10}
                borderRadius={4}
                marginBottom={8}
              />

              {/* Title */}
              <SkeletonPlaceholder.Item
                width="95%"
                height={16}
                borderRadius={4}
                marginBottom={6}
              />
              <SkeletonPlaceholder.Item
                width="70%"
                height={16}
                borderRadius={4}
              />
              <SkeletonPlaceholder.Item
                width="70%"
                height={16}
                borderRadius={4}
              />
              <SkeletonPlaceholder.Item
                width="70%"
                height={16}
                borderRadius={4}
              />
            </SkeletonPlaceholder.Item>
          </SkeletonPlaceholder.Item>
        </SkeletonPlaceholder>
      </View>
    </View>
  );
};

export default NotificationSkeletonLoader;
