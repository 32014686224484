import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "currentUser",
  initialState: {
    user: {},
    sessionToken: "",
    userCreationObject: {},
  },
  reducers: {
    setCurrentUser(state, action) {
      state.user = action.payload;
    },
    setOnlyRole(state, action) {
      state.userCreationObject.user_type = action.payload;
    },
    removeCurrentUser(state) {
      state.user = {};
      state.userCreationObject = {};
    },
    setProfilePicture(state, action) {
      state.user.profile_picture = action.payload;
    },
    changePassword(state, action) {
      state.user.password = action.payload;
    },
    changeUserInformation(state, action) {
      state.user[action.payload.indexKey] = action.payload.value;
    },
    removeBookmarkFromUser(state, action) {
      const bookmarkId = action.payload;
      state.user.bookmarks = state.user.bookmarks.filter(
        (item) => item.result_page_id !== bookmarkId
      );
    },
    addBookmarkToUser(state, action) {
      state.user.bookmarks.push(action.payload);
    },

    addToUserPageHistory(state, action) {
      if (!state.user.result_page_history?.history) {
        state.user.result_page_history = { history: [] };
      }
      state.user.result_page_history.history.push(action.payload);
    },
    updateNoteInUser(state, action) {
      const updatedNote = action.payload;
      state.user.notes = state.user.notes.filter(
        (note) => note.id !== updatedNote.id
      );
      state.user.notes.push(updatedNote);
    },
    deleteNoteFromUser(state, action) {
      state.user.notes = state.user.notes.filter(
        (note) => action.payload !== note.id
      );
    },

    updatePatientInUser(state, action) {
      const updatedPatient = action.payload;
      state.user.patients = state.user.patients.filter(
        (patient) => patient.id !== updatedPatient.id
      );
      state.user.patients.push(updatedPatient);
    },
    deletePatientFromUser(state, action) {
      state.user.patients = state.user.patients.filter(
        (patient) => action.payload !== patient.id
      );
    },

    setHasRated(state, action) {
      state.user.has_rated = action.payload;
    },

    setSessionToken(state, action) {
      state.sessionToken = action.payload;
    },
    addToUserCreationObject(state, action) {
      Object.assign(state.userCreationObject, action.payload);
    },
    setUserInteractionRecords(state, action) {
      state.user.interaction_records = action.payload;
    },
  },
});

export const {
  setCurrentUser,
  setOnlyRole,
  removeCurrentUser,
  setProfilePicture,
  changePassword,
  changeUserInformation,
  updateNoteInUser,
  addToUserCreationObject,
  removeBookmarkFromUser,
  addBookmarkToUser,
  setSessionToken,
  setHasRated,
  addToUserPageHistory,
  deleteNoteFromUser,
  setUserInteractionRecords,
  updatePatientInUser,
  deletePatientFromUser,
} = userSlice.actions;
export default userSlice.reducer;
export const selectCurrentUser = (state) => state.userReducer.user;
export const selectUserCreationObject = (state) =>
  state.userReducer.userCreationObject;
