import { useEffect, useState } from "react";
import { Text } from "react-native";
import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { hyphenateDe } from "../../screens/tab-navigator-screens/components/Card";

export default function TitleText({
  title,
  style,
}: {
  readonly title: string;
  readonly style?: any;
}) {
  const [_title, setTitle] = useState(title);

  useEffect(() => {
    async function load() {
      const hyp = await hyphenateDe(title);
      setTitle(hyp);
    }

    load();
  }, [title]);

  return (
    <Text
      style={{
        ...style,
        ...getFontStyle(500),
        fontSize: 24,
        textAlign: "left",
        color: colors.ui.textPrimary,
      }}
    >
      {_title.trim()}
    </Text>
  );
}
