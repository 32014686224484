import { View } from "react-native";
import { colorParser } from "../../../api/content/data-helpers";
import TextBox from "../../../components/boxes/box-types/text-box/TextBox";
import ScoreElementComponent from "./ScoreElementComponent";
import ScoreElementConditionalComponent from "./ScoreElementConditionalComponent";
import { ScoreElementInlineScore } from "./ScoreElementInlineInterpretationComponent";
import ScoreElementInput from "./ScoreElementInput";
import ScoreElementMultipleComponent from "./ScoreElementMultipleComponent";

const ScoreElementToContent = ({
  scrollViewRef,
  setScore,
  answers,
  informationHeight,
  setAnswers,
  index,
  d,
  setShouldChange,
  isEuroscore,
  shouldChange,
  setCurrentSelectionIndex,
  currentSelectionIndex,
  style = {},
}) => {
  if (d.type === "text") {
    if (index === currentSelectionIndex) setCurrentSelectionIndex(index + 1);
    return (
      <View style={{ paddingBottom: 20 }}>
        <TextBox title={d.title} color={colorParser("gold")} />
      </View>
    );
  }

  if (d.typeId === "scoreElement") {
    return (
      <ScoreElementComponent
        key={index}
        index={index}
        setShouldChange={setShouldChange}
        setAnswers={setAnswers}
        shouldChange={shouldChange}
        setCurrentScore={setScore}
        answers={answers}
        scrollViewRef={scrollViewRef}
        isSelecting={index === currentSelectionIndex}
        setCurrentSelectionIndex={setCurrentSelectionIndex}
        data={d}
        informationHeight={informationHeight}
      />
    );
  } else if (d.typeId === "scoreElementInput") {
    return (
      <ScoreElementInput
        setCurrentSelectionIndex={setCurrentSelectionIndex}
        shouldChange={shouldChange}
        setShouldChange={setShouldChange}
        key={index}
        setCurrentScore={setScore}
        setAnswers={setAnswers}
        answers={answers}
        isSelecting={index === currentSelectionIndex}
        data={d}
        isEuroscore={isEuroscore}
        informationHeight={informationHeight}
        scrollViewRef={scrollViewRef}
      />
    );
  } else if (d.typeId === "scoreElementMultiple") {
    return (
      <ScoreElementMultipleComponent
        key={index}
        answers={answers}
        informationHeight={informationHeight}
        setAnswers={setAnswers}
        index={index}
        setShouldChange={setShouldChange}
        shouldChange={shouldChange}
        setCurrentScore={setScore}
        scrollViewRef={scrollViewRef}
        isSelecting={index === currentSelectionIndex}
        setCurrentSelectionIndex={setCurrentSelectionIndex}
        data={d}
        style={style}
      />
    );
  } else if (d.typeId === "scoreElementWithConditional") {
    return (
      <ScoreElementConditionalComponent
        key={index}
        answers={answers}
        informationHeight={informationHeight}
        setAnswers={setAnswers}
        index={index}
        setShouldChange={setShouldChange}
        shouldChange={shouldChange}
        setCurrentScore={setScore}
        scrollViewRef={scrollViewRef}
        isSelecting={index === currentSelectionIndex}
        setCurrentSelectionIndex={setCurrentSelectionIndex}
        data={d}
      />
    );
  } else if (d.typeId === "scoreElementInlineScore") {
    return <ScoreElementInlineScore key={d._id} data={d} setScore={setScore} />;
  }
};

export default ScoreElementToContent;
