import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Platform, Text, View } from "react-native";
import { useSelector } from "react-redux";
import Colors from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { selectPatientData } from "../../functions/calculator/actions";
import getColorScheme from "../../hooks/useColorScheme";
import useScrollTopMargin from "../../hooks/useScrollTopMargin";
import { BackButton } from "./header-components/HeaderBackButton";
import { PatientDataHeaderComponent } from "./header-components/PatientDataHeaderComponent";

export default function DefaultBackHeader({
  title = "",
  isModal = false,
  showPatientData = false,
}) {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const [backButtonWidth, setBackButtonWidth] = useState(0);

  const patientData = useSelector(selectPatientData);

  // Define padding top with consideration for Android's status bar height
  const scrollMarginTop = useScrollTopMargin();
  const headerPaddingTop = isModal ? 16 : scrollMarginTop + 4;

  return (
    <View
      style={[
        {
          backgroundColor: Colors[colorScheme].background,
          paddingTop: headerPaddingTop,
          paddingBottom: 12,
          paddingHorizontal: Sizes.defaultContainerPadding,
          width: "100%",
        },
        Platform.OS === "web"
          ? {
              borderBottomWidth: 0.3,
              borderBottomColor: "#bbb",
            }
          : {
              shadowColor: Colors[colorScheme].text,
              shadowOffset: { width: 0, height: 3 },
              shadowOpacity: 0.1,
              shadowRadius: 4,
              elevation: 2,
            },
      ]}
    >
      {showPatientData && patientData && <PatientDataHeaderComponent />}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* Back Button */}
        <View
          onLayout={(event) => {
            const { width } = event.nativeEvent.layout;
            setBackButtonWidth(width);
          }}
        >
          <BackButton isModal={isModal} navigation={navigation} />
        </View>

        {/* Header Title */}
        <Text
          style={{
            fontSize: 18,
            fontWeight: "500",
            color: Colors[colorScheme].text,
            textAlign: "center",
            flex: 1,
          }}
        >
          {title}
        </Text>

        {/* Spacer view with dynamic width */}
        <View style={{ width: backButtonWidth }} />
      </View>
    </View>
  );
}
