import { Feather, Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import ReactNativeModal from "react-native-modal";
import { useDispatch, useSelector } from "react-redux";

import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { selectPatientData } from "../functions/calculator/actions";
import configureAnimations from "../functions/configure-animations";
import { setResultPageSearchQuery } from "../functions/current-result/actions";
import { selectIsDemoMode } from "../functions/navigation/actions";

import i18n from "../../locales/i18n";
import { addBookmark, removeBookmark } from "../functions/user/functions";
import isLargerDevice from "../hooks/isLargerDevice";
import ShareOptionModal from "./modals/ShareOptionsModal";

// If your “collapse” logic uses this:

import I18n from "../../locales/i18n";
import { useExpansion } from "../components/ResultPageSkeleton/ExpansionProvider";
import getFontStyle from "../functions/getFontSize";
import { isResultPageWithSkeleton } from "../screens/ResultPageScreen/ResultPageScreen";

/**
 * LargeScreenResultPageUserInteractionsComponent
 * - We removed the old floating FAB entirely.
 * - The “More” button triggers the *same* animated open FAB style menu at the bottom-right.
 */
export default function LargeScreenResultPageUserInteractionsComponent({
  hasBookmark,
  pageContent,
  note,
  currentUser,
  isDemoMode = false,
}) {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  // For collapsible pages (if your page supports it)
  const { closeAll, expandedItems } = useExpansion();

  const isDemoModeRedux = useSelector(selectIsDemoMode);
  const patientData = useSelector(selectPatientData);

  const [favoriteLoading, setFavoriteLoading] = useState(false);
  const [shareModalVisible, setShareModalVisible] = useState(false);

  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useState("");

  // Our new "menu open" state for the old FAB menu, triggered by the More button
  const [menuOpen, setMenuOpen] = useState(false);
  const [shouldRenderMenuItems, setShouldRenderMenuItems] = useState(false);

  // The animation controller for the old FAB item transitions
  const animationController = useRef(new Animated.Value(0)).current;

  // 1) Kick off the animations whenever menuOpen changes
  useEffect(() => {
    if (menuOpen) {
      setShouldRenderMenuItems(true);
    }
    Animated.timing(animationController, {
      toValue: menuOpen ? 1 : 0,
      duration: 200,
      useNativeDriver: true,
    }).start(() => {
      if (!menuOpen) {
        setShouldRenderMenuItems(false);
      }
    });
  }, [menuOpen]);

  // 2) The interpolation for each menu item, as in the old ExpandableFABMenu
  const menuItemStyle = (index: number) => ({
    transform: [
      {
        translateY: animationController.interpolate({
          inputRange: [0, 1],
          outputRange: [20 * (index + 1), 0],
        }),
      },
    ],
    opacity: animationController,
  });

  // If your page is read-only in demo mode, skip

  // Keep the search query in Redux
  useEffect(() => {
    dispatch(setResultPageSearchQuery(query));
  }, [query]);

  if (isDemoModeRedux || isDemoMode) {
    return <View style={{ height: 30 }} />;
  }

  const closeShareModal = () => setShareModalVisible(false);

  return (
    <>
      {/* The share modal (unchanged) */}
      <ShareOptionModal
        pageContent={pageContent}
        open={shareModalVisible}
        onClose={closeShareModal}
      />

      {/* If searching, show the search bar */}
      {isSearching && (
        <View style={{ flex: 1000 }}>
          <ResultPageSearchBar
            query={query}
            setQuery={setQuery}
            closeAction={() => {
              configureAnimations();
              setIsSearching(false);
            }}
          />
        </View>
      )}

      {/* Otherwise, show the normal “header row” icons */}
      {!isSearching && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 4,
            marginRight: isLargerDevice() ? 16 : 0,
          }}
        >
          {/* Note / pencil icon */}
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("CreateNoteScreen", {
                note,
                currentDisplayID: pageContent._id,
              });
            }}
          >
            <Ionicons
              name="create-outline"
              size={26}
              color={colors.ui.textPrimary}
            />
          </TouchableOpacity>

          {/* Bookmark / Heart */}
          <View style={{ marginLeft: 16 }}>
            <TouchableOpacity
              onPress={async () => {
                setFavoriteLoading(true);
                try {
                  if (hasBookmark) {
                    const bookmark = currentUser?.bookmarks?.find(
                      (b) => b.result_page_id === pageContent._id
                    );
                    if (bookmark) {
                      // user had a bookmark with ID => remove it
                      await removeBookmark(pageContent._id);
                    } else {
                      // possibly remove by legacy_id
                      await removeBookmark(pageContent.legacy_id);
                    }
                  } else {
                    // create bookmark
                    await addBookmark(pageContent._id);
                  }
                } finally {
                  setFavoriteLoading(false);
                }
              }}
            >
              <View
                style={{
                  opacity: favoriteLoading ? 0.5 : 1,
                  pointerEvents: favoriteLoading ? "none" : "auto",
                }}
              >
                <Ionicons
                  name={hasBookmark ? "heart-sharp" : "heart-outline"}
                  size={28}
                  color={colors.ui.textPrimary}
                />
                {hasBookmark && (
                  <Ionicons
                    name={"heart-sharp"}
                    size={28}
                    style={{ position: "absolute", width: "50%" }}
                    color={colors.ui.textPrimary}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>

          {/* Share icon */}
          <View style={{ marginLeft: 16 }}>
            <TouchableOpacity
              onPress={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "share_modal_opened",
                  "LargeScreenResultPageUserInteractionsComponent",
                  "User Opened Share Modal",
                  { pageId: pageContent._id, pageTitle: pageContent?.title }
                );
                setShareModalVisible(true);
              }}
            >
              <Feather name="share" size={24} color={colors.ui.textPrimary} />
            </TouchableOpacity>
          </View>

          {/* If not a “scorePage” or “nodePage”, show search icon */}
          {pageContent?.type !== "scorePage" &&
            pageContent?.type !== "nodePage" && (
              <View style={{ marginLeft: 16 }}>
                <TouchableOpacity
                  onPress={() => {
                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "search_page_pressed",
                      "LargeScreenResultPageUserInteractionsComponent",
                      "User Pressed Search on Result Page",
                      { pageId: pageContent._id, pageTitle: pageContent?.title }
                    );
                    setIsSearching(true);
                    configureAnimations();
                  }}
                >
                  <Feather
                    name="search"
                    size={24}
                    color={colors.ui.textPrimary}
                  />
                </TouchableOpacity>
              </View>
            )}

          {/* The “More” icon. Tapping it opens the old FAB-style menu. */}
          <View style={{ marginLeft: 16 }}>
            <TouchableOpacity onPress={() => setMenuOpen(true)}>
              <Feather
                name="more-horizontal"
                size={24}
                color={colors.ui.textPrimary}
              />
            </TouchableOpacity>
          </View>
        </View>
      )}

      {/* This is the *exact style* of your old open FAB menu, triggered by “More.” */}
      <ReactNativeModal
        isVisible={menuOpen}
        onBackdropPress={() => setMenuOpen(false)}
        backdropColor="black"
        backdropOpacity={0.5}
        backdropTransitionOutTiming={0}
        style={{
          margin: 0,
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        {shouldRenderMenuItems && (
          <View
            style={{
              position: "absolute",
              right: Sizes.defaultContainerPadding + 8,
              bottom: Sizes.defaultContainerPadding + 50,
            }}
          >
            {/* HOME */}
            <Animated.View style={[styles.menuItem, menuItemStyle(1)]}>
              <TouchableOpacity
                onPress={() => {
                  setMenuOpen(false);
                  setTimeout(() => {
                    (navigation as any).popToTop();
                  }, 250);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "fab_menu_item_selected",
                    "LargeScreenResultPageUserInteractionsComponent",
                    "Back to Home Selected",
                    null
                  );
                }}
                style={{
                  ...styles.actionButton,
                  backgroundColor: colors.ui.cardBackground,
                  shadowColor: colors.ui.textPrimary,
                }}
              >
                <Feather name="home" size={24} color={colors.ui.textPrimary} />
                <Text
                  style={{
                    ...styles.actionText,
                    color: colors.ui.textPrimary,
                    fontFamily: "HostGrotesk-Regular",
                  }}
                >
                  Home
                </Text>
              </TouchableOpacity>
            </Animated.View>

            {/* FEEDBACK */}
            <Animated.View style={[styles.menuItem, menuItemStyle(2)]}>
              <TouchableOpacity
                disabled={!menuOpen}
                onPress={() => {
                  setMenuOpen(false);
                  setTimeout(() => {
                    navigation.navigate("FeedbackScreen", {
                      pageContent,
                    });
                  }, 500);

                  AnalyticsHandler.getInstance().logUserScreenInteraction(
                    "fab_menu_item_selected",
                    "LargeScreenResultPageUserInteractionsComponent",
                    "Feedback Selected",
                    pageContent?._id
                  );
                }}
                style={{
                  ...styles.actionButton,
                  backgroundColor: colors.ui.cardBackground,
                  shadowColor: colors.ui.textPrimary,
                }}
              >
                <Ionicons
                  name="chatbubble-ellipses-outline"
                  size={24}
                  color={colors.ui.textPrimary}
                />
                <Text
                  style={{
                    ...styles.actionText,
                    color: colors.ui.textPrimary,
                    fontFamily: "HostGrotesk-Regular",
                  }}
                >
                  Feedback
                </Text>
              </TouchableOpacity>
            </Animated.View>

            {/* COLLAPSE (EINKLAPPEN) */}
            {isResultPageWithSkeleton(pageContent?.type) && (
              <Animated.View style={[styles.menuItem, menuItemStyle(3)]}>
                <TouchableOpacity
                  disabled={
                    !expandedItems || Object.keys(expandedItems)?.length === 0
                  }
                  onPress={() => {
                    setMenuOpen(false);
                    setTimeout(() => {
                      closeAll();
                    }, 500);

                    AnalyticsHandler.getInstance().logUserScreenInteraction(
                      "fab_menu_collapse_action",
                      "LargeScreenResultPageUserInteractionsComponent",
                      "Collapse Action Taken",
                      pageContent?._id
                    );
                  }}
                  style={{
                    ...styles.actionButton,
                    backgroundColor: colors.ui.cardBackground,
                    opacity:
                      !expandedItems || Object.keys(expandedItems)?.length === 0
                        ? 0.5
                        : 1,
                  }}
                >
                  <Feather
                    name="minimize"
                    size={24}
                    color={colors.ui.textPrimary}
                  />
                  <Text
                    style={{
                      ...styles.actionText,
                      color: colors.ui.textPrimary,
                      fontFamily: "HostGrotesk-Regular",
                    }}
                  >
                    {I18n.t("fold_in")}
                  </Text>
                </TouchableOpacity>
              </Animated.View>
            )}

            {/* ADD/SELECT PATIENT (Only if no patientData) */}
            {!patientData && (
              <>
                <Animated.View style={[styles.menuItem, menuItemStyle(4)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      setMenuOpen(false);
                      setTimeout(() => {
                        navigation.navigate("AddPatientScreen", {
                          isFromContentPage: true,
                        });
                      }, 500);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_item_selected",
                        "LargeScreenResultPageUserInteractionsComponent",
                        "Add Patient Selected",
                        null
                      );
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: colors.ui.cardBackground,
                      shadowColor: colors.ui.textPrimary,
                    }}
                  >
                    <Feather
                      name="user-plus"
                      size={24}
                      color={colors.ui.textPrimary}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: colors.ui.textPrimary,
                        fontFamily: "HostGrotesk-Regular",
                      }}
                    >
                      {I18n.t("create_patient")}
                    </Text>
                  </TouchableOpacity>
                </Animated.View>

                <Animated.View style={[styles.menuItem, menuItemStyle(5)]}>
                  <TouchableOpacity
                    disabled={!menuOpen}
                    onPress={() => {
                      setMenuOpen(false);
                      setTimeout(() => {
                        navigation.navigate("PatientsScreen", {
                          modal: true,
                        });
                      }, 500);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "fab_menu_item_selected",
                        "LargeScreenResultPageUserInteractionsComponent",
                        "Select Patients Selected",
                        null
                      );
                    }}
                    style={{
                      ...styles.actionButton,
                      backgroundColor: colors.ui.cardBackground,
                      shadowColor: colors.ui.textPrimary,
                    }}
                  >
                    <Feather
                      name="users"
                      size={24}
                      color={colors.ui.textPrimary}
                    />
                    <Text
                      style={{
                        ...styles.actionText,
                        color: colors.ui.textPrimary,
                        fontFamily: "HostGrotesk-Regular",
                      }}
                    >
                      Patienten auswählen
                    </Text>
                  </TouchableOpacity>
                </Animated.View>
              </>
            )}
          </View>
        )}
      </ReactNativeModal>
    </>
  );
}

/**
 * Keep the same search bar code for searching.
 * (Unchanged from your example.)
 */
function ResultPageSearchBar({ query, setQuery, closeAction }) {
  const textInputRef = useRef<any>(null);

  const debouncedUpdate = useCallback(
    debounce((q) => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "search_initiated_on_result_page",
        "SearchBarOnResultPage",
        "User Initiated Search on Result Page",
        { query: q }
      );
    }, 500),
    []
  );

  useEffect(() => {
    if (textInputRef.current) textInputRef.current.focus();
  }, [textInputRef]);

  return (
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          borderRadius: 12,
          paddingLeft: 16,
          paddingRight: 16,
          height: 50,
          backgroundColor: colors.ui.inputBackground,
          shadowColor: colors.ui.textPrimary,
          shadowOffset: { width: 1, height: 1 },
          shadowOpacity: 0.1,
          shadowRadius: 1,
        }}
      >
        <Ionicons name="search" size={24} color={colors.brand.greys.gD4D4D4} />
        <TextInput
          ref={textInputRef}
          defaultValue={query}
          onFocus={() => {
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "search_bar_focused_on_result_page",
              "SearchBarOnResultPage",
              "User Focused Search Bar on Result Page"
            );
            configureAnimations();
          }}
          placeholderTextColor={colors.ui.textPlaceholder}
          placeholder={
            i18n.locale === "en"
              ? i18n.t("enter_you_searchterm")
              : "Gib hier deinen Suchbegriff ein"
          }
          style={{
            flex: 1,
            color: colors.ui.textPrimary,
            fontSize: Sizes.boxText,
            paddingHorizontal: 16,
            ...Platform.select({ android: { paddingVertical: 8 } }),
            paddingLeft: 0,
            marginLeft: 12,
            fontFamily: "HostGrotesk-Regular",
          }}
          onChangeText={(val) => {
            debouncedUpdate(val);
            setQuery(val);
          }}
        />
        <TouchableOpacity
          onPress={() => {
            setQuery("");
            textInputRef?.current.clear();
            AnalyticsHandler.getInstance().logUserScreenInteraction(
              "search_cancelled_on_result_page",
              "SearchBarOnResultPage",
              "User Cancelled Search on Result Page",
              { query }
            );
            closeAction();
            textInputRef.current?.blur();
          }}
        >
          <Ionicons
            name="close"
            size={18}
            color={colors.brand.greys.gD4D4D4}
            style={{ marginLeft: 8 }}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
}

/** The same styles from your old ExpandableFABMenu. */
const styles = StyleSheet.create({
  menuItem: {
    marginBottom: 16,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  actionButton: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 20,
    elevation: 3,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  actionText: {
    marginLeft: 8,
    ...getFontStyle(700),
  },
});
