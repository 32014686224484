import React, { Component } from "react";
import { Dimensions, View, ViewStyle } from "react-native";

interface IState {
  rectTop: number;
  rectBottom: number;
  rectWidth?: number;
}

export interface IProps {
  disabled?: boolean;
  viewOffset?: number; // new prop for adjusting viewport offset
  onChange?: (isVisible: boolean) => void;
  delay?: number;
  children?: React.ReactNode;
  style?: ViewStyle; // Adding style prop for View
}

class InViewPort extends Component<IProps, IState> {
  private myview: View | null = null;
  private interval: NodeJS.Timeout | null = null;
  private lastValue: boolean | null = null;

  constructor(props: IProps) {
    super(props);
    this.state = { rectTop: 0, rectBottom: 0 };
  }

  componentDidMount() {
    if (!this.props.disabled) {
      this.startWatching();
    }
  }

  componentWillUnmount() {
    this.stopWatching();
  }

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.disabled) {
      this.stopWatching();
    } else if (this.props.disabled !== nextProps.disabled) {
      this.lastValue = null;
      this.startWatching();
    }
  }

  startWatching() {
    if (this.interval) {
      return;
    }
    this.interval = setInterval(() => {
      if (!this.myview) {
        return;
      }
      this.myview.measure((x, y, width, height, pageX, pageY) => {
        this.setState({
          rectTop: pageY,
          rectBottom: pageY + height,
          rectWidth: pageX + width,
        });
      });
      this.isInViewPort();
    }, this.props.delay || 100);
  }

  stopWatching() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  isInViewPort() {
    const window = Dimensions.get("window");
    const offset = (this.props.viewOffset || 0) * window.height * 0.01;

    // If isFocused is true, always return true
    const isVisible =
      this.state.rectBottom > offset &&
      this.state.rectTop < window.height &&
      (this.state.rectWidth || 0) > 0 &&
      (this.state.rectWidth || 0) <= window.width;

    if (this.lastValue !== isVisible) {
      this.lastValue = isVisible;
      this.props.onChange && this.props.onChange(isVisible);
    }
  }

  render() {
    return (
      <View
        ref={(component) => {
          this.myview = component;
        }}
        style={this.props.style}
        {...this.props}
      >
        {this.props.children}
      </View>
    );
  }
}

export default InViewPort;
