import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../components/Notifications/NotificationComponent";
import { showInlineNotification } from "../entry/Root";
import { logout } from "../functions/profile/functions";
import { store } from "../functions/store";
import { setSessionToken } from "../functions/user/actions";
import { refreshAccessToken } from "../screens/login-registry/LandingScreen/functions/ProviderSignIn";

// Beispiel: apiCall.ts

const URL_DEV = "https://live.api.medi.ceo";
const URL_PROD = "https://live.api.medi.ceo";

const DEFAULT_HEADERS = {
  Accept: "*/*",
  "Content-Type": "application/json",
};

export default async function apiCall(
  slug: string,
  body?: any,
  isRetry = false,
  noEnc = false,
  method = "POST",
  notify = false
): Promise<any> {
  const state = store.getState();
  const sessionToken = state.userReducer.sessionToken;

  const headers: any = { ...DEFAULT_HEADERS };

  if (!noEnc) {
    headers.Authorization = `Bearer ${sessionToken}`;
  }

  const options: RequestInit = {
    method,
    headers,
  };

  if (body instanceof FormData) {
    options.body = body;
  } else if (method !== "GET" && body) {
    options.body = JSON.stringify(body);
  }

  let response: Response;
  const abortCtl = new AbortController();
  setTimeout(() => abortCtl.abort(), 10000);
  options.signal = abortCtl.signal;

  try {
    const url = getURL() + slug;
    //if (slug.includes("interaction_records")) console.log(url);
    response = await fetch(url, options);

    console.log("RES", response, "is retry", isRetry);

    if (response.status === 422) {
      await response.json();
      throw new Error();
    }

    if (response.status === 401 || (response.status === 403 && !isRetry)) {
      const newAccessToken = await refreshAccessToken();
      console.log("New Access Token", newAccessToken);
      if (newAccessToken) {
        return apiCall(slug, body, true, noEnc, method, notify);
      } else {
        console.log("should retry check");
        if (shouldRetry(response, noEnc, isRetry)) {
          console.log("should retry called");
          const user = store.getState().userReducer.user;
          try {
            await loginCall(user.password, user.email);
            console.log("login call for new token");
            return apiCall(slug, body, true, noEnc, method);
          } catch (e) {
            if (notify)
              showInlineNotification({
                type: InlineNotificationType.ERROR,
                text: "Ein Serverfehler ist aufgetreten. Versuche es erneut.",
              });
            return;
          }
        } else {
          await logout(state.userReducer.user, null);
        }
      }
    }

    if (response.status === 500) {
      throw new Error();
    }
  } catch (error) {
    if (notify) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
        text: "Ein Serverfehler ist aufgetreten. Versuche es erneut.",
      });
    }
    return;
  }

  // Falls die Response nicht ok ist, brechen wir (wie gehabt)
  if (!response.ok) {
    const responseText = await response.text();
    let detail: string | undefined;
    try {
      detail = JSON.parse(responseText).detail;
    } catch {
      detail = "Unbekannter Fehler.";
    }

    if (response.status === 409) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_TOP,
        text: detail,
      });
      throw new Error();
    }

    if (notify) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: detail ?? "Ein unbekannter Fehler ist aufgetreten.",
      });
    }
    return { status: response.status };
  }

  if (response.status === 204) {
    return { status: 204 };
  }

  const data = await response.json();
  return { data, status: response.status };
}

function shouldRetry(
  response: Response,
  noEnc: boolean,
  isRetry: boolean
): boolean {
  return (
    (response.status === 403 || response.status === 401) && !noEnc && !isRetry
  );
}

export async function loginCall(hashedPassword: string, user: string) {
  const endpoint = "/api/v4/auth/login";
  console.log("Login ausgeführt");

  try {
    const signal = new AbortController();
    setTimeout(() => signal?.abort(), 7500);

    const response = await fetch(getURL() + endpoint, {
      method: "POST",
      signal: signal.signal,
      body: JSON.stringify({
        email: user.trim(),
        password: hashedPassword.trim(),
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (response.status === 403) {
      return { status: 403 };
    } else if (response.status === 401 || response.status === 500) {
      throw new Error();
    } else if (response.status === 200) {
      const data = await response.json();

      if (data.access_token === undefined) throw new Error();
      store.dispatch(setSessionToken(data.access_token));
      //loadAllData(data?.internal_customer_id);
      return { userData: data };
    } else if (response.status === 422) {
      throw new Error();
    } else if (response.status === 423) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Dein Account wurde noch nicht aktiviert.",
      });
    } else if (response.status === 418) {
      showInlineNotification({
        type: InlineNotificationType.ERROR,
        text: "Deine Registrierungsanfrage wurde abgelehnt.",
      });
    } else {
      throw new Error();
    }
  } catch (e) {
    throw new Error();
  }
}

export function getURL() {
  const useDev = __DEV__ || store.getState().userReducer?.user?.role === "dev";

  if (useDev) return URL_DEV;
  else return URL_PROD;
}
