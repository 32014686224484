import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectAllAmounts,
  selectCurrentUnits,
} from "../../functions/calculator/actions";
import { selectShouldShowSources } from "../../functions/current-result/actions";
import CustomRenderHTMLComponent from "../CustomRenderHTMLComponent";
import transformDefaultHtmlContent from "./helpers/default-content-transformation";

export const BoxTextComponent = ({
  content,
  style,
  color,
  noBulletStyles = false,
}: {
  readonly content: string | undefined;
  readonly style: any;
  readonly color?: string;
  readonly noBulletStyles?: boolean;
}) => {
  const allUnits = useSelector(selectAllAmounts);

  const currentUnit = useSelector(selectCurrentUnits);

  const showSources = useSelector(selectShouldShowSources);
  const _content = transformDefaultHtmlContent(
    content,
    showSources,
    allUnits,
    currentUnit
  );

  // Use memoized content in component to avoid unnecessary renders
  return useMemo(
    () => (
      <CustomRenderHTMLComponent
        tagsStyles={style}
        color={color}
        source={{
          html: _content,
        }}
        noBulletStyles={noBulletStyles}
      />
    ),
    [_content, color, noBulletStyles, style]
  );
};
