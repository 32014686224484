import React from "react";
import { View, Text, StyleSheet, SafeAreaView, StatusBar } from "react-native";
import Stories from "./StoriesComponent";
import SlideOne from "./Slides/SlideOne";
import SlideTwo from "./Slides/SlideTwo";
import SlideThree from "./Slides/SlideThree";
import SlideFour from "./Slides/SlideFour";
import SlideFive from "./Slides/SlideFive";
import SlideSix from "./Slides/SlideSix";
import SlideSeven from "./Slides/SlideSeven";

const StoriesScreen = () => {
  return (
    <SafeAreaView style={styles.safeArea}>
      <StatusBar barStyle="dark-content" />
      <Stories
        storySets={[
          [<SlideOne />, <SlideTwo />, <SlideThree />],
          [<SlideFour />, <SlideFive />, <SlideSix />],
          [<SlideSeven />],
        ]}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: "#fff",
  },
  storyContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  storyText: {
    fontSize: 24,
    color: "#fff",
    fontWeight: "bold",
  },
});

export default StoriesScreen;
