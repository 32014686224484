import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import getPageContent from "../../../../api/content/get-page-content";
import staticColors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import {
  selectResultPageCaseContent,
  setCurrentHighlightSection,
} from "../../../../functions/current-result/actions";
import { selectAllContent } from "../../../../functions/data/actions";
import { navigateToNextCMSPage } from "../../../../functions/navigation/helpers";
import getColorScheme from "../../../../hooks/useColorScheme";

import { getScoreInterpretationBoxes } from "../../../../screens/ScoreScreen/components/ScoreInterpretation";
import { CategoryIcon } from "../../../CategoryIcon";
import ResultBoxes from "../../../result/ResultBoxComponent";

export default function ScoreFurtherActionBox({ box }) {
  const [interpretationContent, setInterpretationContent] =
    useState<any>(undefined);

  const euroscoreId =
    useSelector(selectAllContent).medicalCategories.content.euroscoreId;

  const resultPageCaseContent = useSelector(selectResultPageCaseContent);

  const [interpretationBoxes, setInterpretationBoxes] =
    useState<any>(undefined);

  useEffect(() => {
    const currentPageContent = resultPageCaseContent?.caseData?.[box.scoreId];
    if (currentPageContent) {
      setInterpretationContent({
        caseData: {
          [box.scoreId]: {
            score: currentPageContent.score,
            answers: currentPageContent.answers,
            currentSelectionIndex: currentPageContent.currentSelectionIndex,
          },
        },
      });
    }
  }, [resultPageCaseContent]);

  const navigation = useNavigation();
  const colorScheme = getColorScheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (interpretationContent?.caseData?.[box.scoreId]?.answers) {
      const scoreData = getPageContent(box.scoreId);
      const _interpretationBoxes = getScoreInterpretationBoxes(
        interpretationContent?.caseData?.[box.scoreId]?.score,
        scoreData?.scoreInterpretationElements,
        interpretationContent?.caseData?.[box.scoreId]?.answers,
        box.scoreId === euroscoreId
      );

      setInterpretationBoxes(_interpretationBoxes?.interpretationBoxes);
    }
  }, [interpretationContent]);

  return (
    <View style={{ marginTop: 20, borderRadius: 12, overflow: "hidden" }}>
      <View
        style={{
          width: 7,
          top: 0,
          bottom: 0,
          position: "absolute",
          left: 0,
          backgroundColor: staticColors[colorScheme].gray,
          zIndex: 10,
        }}
      />

      <TouchableOpacity
        key={box.id}
        style={{
          flexDirection: "row",
          padding: 16,
          paddingLeft: 19,
          backgroundColor: staticColors[colorScheme].foreground,
          alignItems: "center",
        }}
        onPress={() => {
          dispatch(setCurrentHighlightSection(null));
          navigateToNextCMSPage(box.scoreId, navigation, true, {
            setInterpretationContent: setInterpretationContent,
            additionalCaseData: interpretationContent,
          });
        }}
      >
        <CategoryIcon title={"Scores"} size={24} />
        <Text
          style={{
            fontWeight: "700",
            fontSize: Sizes.boxText,
            flex: 1,
          }}
        >
          {box.title}
        </Text>
        <Feather
          name="chevron-right"
          size={24}
          color={staticColors[colorScheme].text}
        />
      </TouchableOpacity>

      {interpretationContent && (
        <View
          style={{
            padding: 12,
            paddingLeft: 19,
            paddingTop: 0,
            borderTopColor: staticColors[colorScheme].gray,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderBottomEndRadius: 12,
            borderBottomStartRadius: 12,
            backgroundColor: staticColors[colorScheme].background,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
          }}
        >
          <View style={{ flex: 1 }}>
            <ResultBoxes
              pageContent={{
                boxes: interpretationBoxes,
              }}
            />
          </View>
          <TouchableOpacity
            onPress={() => setInterpretationContent(undefined)}
            style={{ marginTop: 20, marginLeft: 12 }}
          >
            <Feather
              name="x-circle"
              size={24}
              color={staticColors[colorScheme].text}
            />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}
