import { Text, TextInput, TouchableOpacity, View } from "react-native";

import { Ionicons } from "@expo/vector-icons";
import { useState } from "react";

import { useSelector } from "react-redux";
import I18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import getFontStyle from "../../functions/getFontSize";
import { selectCurrentUser } from "../../functions/user/actions";
import { changePwAndSendMail } from "../../functions/user/functions";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../Notifications/NotificationComponent";
import CustomModal from "./CustomModal";

export default function ChangePasswordModal({ open, onClose }) {
  const [secureEntry, setSecureEntry] = useState(true);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  async function changePassword() {
    setLoading(true);
    const success = await changePwAndSendMail(currentUser?.password, password);
    onClose();
    setLoading(false);

    if (!success) {
      showInlineNotification({
        text: I18n.t("change_password_failed"),
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    } else {
      showInlineNotification({
        text: I18n.t("change_password_succesfull"),
        type: InlineNotificationType.SUCCESS,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }
  }

  return (
    <CustomModal {...{ open, onClose }}>
      <View style={{ marginBottom: 30 }}>
        <Text
          style={{
            ...getFontStyle(500),
            fontSize: 20,
            marginBottom: 12,
          }}
        >
          {I18n.t("enter_new_password")}
        </Text>

        <View
          style={{
            width: "100%",
            maxWidth: Sizes.containerWidth,
            marginTop: 12,
            marginBottom: 20,
          }}
        >
          <TextInput
            placeholder="Passwort"
            placeholderTextColor={colors.ui.textPlaceholder}
            style={{
              backgroundColor: colors.ui.inputBackground,
              color: colors.ui.textPrimary,
              borderRadius: 12,
              height: 50,
              padding: 12,
              fontFamily: "HostGrotesk-Regular",
            }}
            secureTextEntry={secureEntry}
            onChangeText={(text) => {
              setPassword(text);
            }}
          />
          <TouchableOpacity
            hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
            style={{ position: "absolute", right: 20, top: 11 }}
            onPress={() => setSecureEntry(!secureEntry)}
          >
            <Ionicons
              name={secureEntry ? "eye-outline" : "eye-off-outline"}
              size={24}
              color={colors.ui.textPrimary}
            />
          </TouchableOpacity>
        </View>

        <DefaultButton
          action={changePassword}
          loading={loading}
          style={{ marginTop: 20 }}
          title="Jetzt ändern"
          type={ButtonTypes.Primary}
          enabled={password.length > 7}
          icon={null}
        />
      </View>
    </CustomModal>
  );
}
