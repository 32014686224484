import { useNavigation } from "@react-navigation/native";
import getColorScheme from "../../../../hooks/useColorScheme";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import { addValueToInteractions } from "../../../../functions/user-interaction-handler";
import { Text, TouchableOpacity, View } from "react-native";
import DefaultGradientBackground from "../../../../components/DefaultGradientBackground";
import { Feather, Ionicons } from "@expo/vector-icons";
import {
  ButtonTypes,
  DefaultButton,
} from "../../../../components/DefaultButton";
import Colors from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import i18n from "../../../../../locales/i18n";

export const TippsContainer = () => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  return (
    <View
      style={{
        width: "100%",
        backgroundColor: Colors[colorScheme].foreground,
        borderRadius: 10,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("TippsScreen");
          // Tracking navigation to TippsScreen
          AnalyticsHandler.getInstance().logUserScreenInteraction(
            "navigate_to_tipps_screen",
            "TippsContainer",
            "User Navigated"
          );
        }}
        activeOpacity={0.3}
      >
        <View
          style={{
            borderRadius: 10,

            overflow: "hidden",
            flexDirection: "row",
            alignItems: "center",
            padding: 5,
          }}
        >
          <InfoIcon />
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                paddingVertical: 16,
                paddingRight: 16,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  flex: 1,
                  width: "90%",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: Colors[colorScheme].text,
                    fontSize: Sizes.boxText,
                    lineHeight: Sizes.lineHeightBoxText,
                    fontWeight: "500",
                    marginLeft: 12,
                  }}
                >
                  {i18n.t("explore_mediceo")}
                </Text>
              </View>

              <Feather
                name="chevron-right"
                size={24}
                style={{ marginLeft: 12 }}
                color={Colors[colorScheme].text}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const InfoIcon = () => {
  return (
    <View
      style={{
        borderRadius: 100,
        overflow: "hidden",

        aspectRatio: 1,
        height: 44,
        marginLeft: 16,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DefaultGradientBackground />
      <Feather name="info" size={24} style={{}} />
    </View>
  );
};
