import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import i18n from "../../../../../locales/i18n";
import { AnalyticsHandler } from "../../../../api/analytics/AnalyticsHandler";
import CheckBox from "../../../../components/CheckBoxComponent";
import {
  default as Colors,
  default as staticColors,
} from "../../../../constants/static-colors";
import { Sizes } from "../../../../constants/static-sizes";
import configureAnimations from "../../../../functions/configure-animations";
import getColorScheme from "../../../../hooks/useColorScheme";
import { DocContent } from "./DocContentComponent";
import { NurseContent } from "./NurseContentComponent";
import { StudentContent } from "./StudentContent";

export const AdditionalInformationView = ({
  userCreationObject,
  showHint,
  content,
  backAction,
  continueAction,
  loading,
}: {
  userCreationObject: any;
  showHint: any;
  content: any;

  backAction?: any;
  loading: any;
  continueAction: any;
}) => {
  const analyticsHandler = AnalyticsHandler.getInstance();

  useEffect(() => {
    configureAnimations();
    analyticsHandler.logUserScreenInteraction(
      "is_specialist_doctor_changed",
      "AdditionalInformationComponent",
      "BooleanChange",
      userCreationObject["isSpecialistDoctor"]
    );
  }, [userCreationObject["isSpecialistDoctor"]]);

  return (
    <KeyboardAwareScrollView
      style={{
        width: "100%",
        flex: 1,
        height: "100%",
        maxWidth: Sizes.containerWidth,
      }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        flexGrow: 1,
        justifyContent: "center",
        paddingTop: 50,
        paddingBottom: 20,
        paddingHorizontal: 0,
      }}
    >
      {(userCreationObject["user_type"] === "doc" ||
        userCreationObject["user_type"] === "doctor" ||
        userCreationObject["user_type"] === "dev") && (
        <DocContent
          {...{
            userCreationObject,

            content,

            backAction,
            continueAction,
            loading,
          }}
        />
      )}
      {userCreationObject["user_type"] === "nurse" && (
        <NurseContent
          {...{
            userCreationObject,

            content,

            backAction,
            continueAction,
            loading,
          }}
        />
      )}
      {userCreationObject["user_type"] === "student" && (
        <StudentContent
          {...{
            userCreationObject,

            content,

            backAction,
            continueAction,
            loading,
          }}
        />
      )}
      <WhyWeNeedThisInformation showHint={showHint} />
    </KeyboardAwareScrollView>
  );
};

export const InputWithTitle = ({
  title,
  name,
  inputValues,
  handleInputChange,
  presetSelection,
  keyboardType = "default",
  placeholder,
}: {
  title: string | null;
  name: string;
  inputValues: any;
  handleInputChange: any;
  presetSelection?: any;
  keyboardType?: any;
  placeholder?: string;
}) => {
  const colorScheme = getColorScheme();
  const navigation = useNavigation();

  const styles = getStyles(colorScheme);

  const analyticsHandler = AnalyticsHandler.getInstance();

  const debouncedUpdate = useCallback(
    debounce((inputValues) => {
      analyticsHandler.logUserScreenInteraction(
        "user_input_change",
        "AdditionalInformationComponent",
        name,
        inputValues[name]
      );
    }, 500),
    []
  );

  useEffect(() => {
    configureAnimations();
    debouncedUpdate(inputValues);
  }, [inputValues[name]]);

  if (presetSelection)
    return (
      <View style={{ marginTop: 24, marginBottom: title ? 0 : 12 }}>
        {!!title && <Text style={styles.textInputTitle}>{title}</Text>}
        <TouchableOpacity
          disabled={!presetSelection}
          onPress={() =>
            navigation.navigate("SelectionInformationFromListComponent", {
              data: presetSelection,
              indexKey: name,
            })
          }
        >
          <View>
            <View
              key={name}
              style={{
                backgroundColor: Colors[colorScheme].foreground,
                maxWidth: Sizes.containerWidth,
                borderRadius: 12,
                marginTop: 8,
                width: "100%",
                height: 50,
                padding: 12,
                zIndex: 0,
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              {inputValues[name] ? (
                <Text
                  numberOfLines={1}
                  style={{
                    color: Colors[colorScheme].text,
                    fontWeight: "400",
                  }}
                >
                  {inputValues[name]}
                </Text>
              ) : (
                <Text
                  numberOfLines={1}
                  style={{
                    color: Colors[colorScheme].placeholder,
                    fontWeight: "400",
                  }}
                >
                  {placeholder ?? title ?? ""}
                </Text>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  else
    return (
      <View style={{ marginTop: 24, marginBottom: title ? 0 : 12 }}>
        {!!title && <Text style={styles.textInputTitle}>{title}</Text>}
        <TouchableOpacity
          disabled={!presetSelection}
          onPress={() =>
            navigation.navigate("SelectionInformationFromListComponent", {
              data: presetSelection,
              indexKey: name,
            })
          }
        >
          <View>
            <TextInput
              keyboardType={keyboardType}
              placeholderTextColor={Colors[colorScheme].placeholder}
              placeholder={placeholder ?? title ?? ""}
              onChangeText={(t) => handleInputChange(name, t)}
              value={inputValues[name]}
              key={name}
              style={{
                backgroundColor: Colors[colorScheme].foreground,
                color: Colors[colorScheme].text,
                maxWidth: Sizes.containerWidth,
                borderRadius: 12,
                marginTop: 8,
                width: "100%",
                height: 50,
                padding: 12,
                zIndex: 0,
              }}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
};

export const WhyWeNeedThisInformation = ({ showHint }) => {
  const colorScheme = getColorScheme();
  return (
    <TouchableOpacity
      onPress={showHint}
      style={{
        marginTop: 32,
        flexDirection: "row",
        gap: 6,
        alignSelf: "center",
        alignItems: "center",
        marginBottom: 0,
      }}
    >
      <Feather name="info" size={18} color={Colors[colorScheme].accentDark} />
      <Text style={{ fontSize: 12, color: Colors[colorScheme].accentDark }}>
        Warum benötigen wir diese Informationen?
      </Text>
    </TouchableOpacity>
  );
};

export const CheckComponent = ({ showHint, setApproved, approved, role }) => {
  const colorScheme = getColorScheme();

  const textToRole = (role) => {
    switch (role) {
      case "doctor":
        return i18n.t("disclaimer_doc");
      case "nurse":
        return "Hiermit bestätige ich, dass ich in einem Beruf aus der Gruppe medizinische Fachberufe ausgebildet bin.";
      case "student":
        return "Hiermit bestätige ich, dass ich mich in einem medizinischen Beruf in der Ausbildung befinde.";
      default:
        return "";
    }
  };

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "approved_doc_change",
      "DocContent",
      "BooleanChange",
      approved
    );
  }, [approved]);

  return useMemo(
    () => (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
          maxWidth: 500,
          marginBottom: 20,
        }}
        onPress={() => setApproved((s) => !s)}
      >
        <CheckBox isSelected={approved} setSelected={setApproved} />
        <Text
          style={{
            fontSize: 12,
            marginLeft: 12,
            color: Colors[colorScheme].text,
            flex: 1,
          }}
        >
          {textToRole(role)}
        </Text>
      </TouchableOpacity>
    ),
    [approved, setApproved, showHint]
  );
};

const getStyles = (colorScheme) => {
  const styles = StyleSheet.create({
    textInputTitle: {
      color: staticColors[colorScheme].text,
      fontSize: 16,
      fontWeight: "500",
    },
  });
  return styles;
};
