import { colors } from "../../../../constants/static-colors";

export const COLOR_MAPPINGS = {
  Blue297_monochrome: {
    color: "#85C7E3",
    text: colors.ui.textPrimary,
  },
  White_black_frame: {
    color: "white",
    text: "black",
    color_frame: "black",
  },
  Green367_monochrome: {
    color: "#A3D963",
    text: colors.ui.textPrimary,
  },
  White_monochrome: {
    color: "white",
    text: colors.ui.textPrimary,
  },
  Red193_ProcessBlue_diagonal_geteilt: {
    color: "#0068CC",
    text: "white",
    color_triangle: "#BF1238",
  },
  Orange151_monochrome: {
    color: "#FF6600",
    text: "white",
  },
  Grey401_monochrome: {
    color: "#C2B8AB",
    text: colors.ui.textPrimary,
  },
  Green349_Pink227_diagonal_geteilt: {
    color: "#AB035C",
    text: "white",
    color_triangle: "#006338",
  },
  White_WarmRed_horizontal_geteilt: {
    color: "#F64029",
    text: colors.ui.textPrimary,
    color_top: "white",
  },
  Black_WarmRed_horizontal_geteilt: {
    color: "#F64029",
    text: "white",
    color_top: "black",
  },
  ProcessYellowC_monochrome: {
    color: "#FFFF00",
    text: colors.ui.textPrimary,
  },
  White_Violet256_frame_striped: {
    color: "white",
    text: colors.ui.textPrimary,
    color_frame: "#DEBFD9",
  },
  Black_Violet256_horizontal_geteilt: {
    color: "#DEBFD9",
    text: "white",
    color_top: "black",
  },
  Violet256_monochrome: {
    color: "#DEBFD9",
    text: colors.ui.textPrimary,
  },
  White_Orange151_frame_striped: {
    color: "white",
    text: colors.ui.textPrimary,
    color_frame: "#F64029",
  },
  White_WarmRed_frame_striped: {
    color: "white",
    text: colors.ui.textPrimary,
    color_frame: "#FF6600",
  },
  White_Blue297_frame_striped: {
    color: "white",
    text: colors.ui.textPrimary,
    color_frame: "#85C7E3",
  },
  Salmon156_monochrome: {
    color: "#EDC282",
    text: colors.ui.textPrimary,
  },
  Grey431_Purple_diagonal_geteilt: {
    color: "#636B70",
    text: "white",
    color_triangle: "#BA1FB5",
  },
  Blue72_Brown464_diagonal_geteilt: {
    color: "#2905A1",
    text: "white",
    color_triangle: "#82591F",
  },
  Brown175_Tan458_diagonal_geteilt: {
    color: "#70331F",
    text: "white",
    color_triangle: "#D9CC61",
  },
  default: { color: colors.brand.greens.g22635a, text: "white" },
};
