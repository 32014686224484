import getLinkParsedContent from "../../../api/content/inline-link-parser";
import { getSourceParsedText } from "../../../api/content/source-parser";
import getUnitParsedText, {
  getStrictIndicesOfUnitWithTime,
} from "./default-unit-parser";

export default function transformDefaultHtmlContent(
  content,
  shouldShowSources,
  allUnits,
  currentUnits
) {
  // Ensure initial content is processed once
  let _content = (content ?? "")
    .replace(/&nbsp;/g, "")
    .replace(/ {2,}/g, " ")
    .replace(/–/g, "-")
    .replace(/&ndash;/g, "-")
    .replace(/μ/g, "µ")
    .replace(/&micro;/g, "µ")
    .replace(/<nocalc>/g, "&lt;nocalc&gt;")
    .replace(/<\/nocalc>/g, "&lt;/nocalc&gt;")
    .replace(/\s/g, " ")
    .replace(/\u2013|\u2014/g, "-")
    .replace(/(\w+)\s?\/\s?(\w+)/g, "$1/$2")
    .replace(/&nbsp;/g, " ")
    .replace(/\s+/g, " ")
    .replace(/&mu;/g, "µ");

  // Parse sources and links once if dependencies change
  _content = getSourceParsedText(_content, shouldShowSources);
  _content = getLinkParsedContent(_content);

  const _currentUnits = currentUnits;

  if (_currentUnits.length === 0) _content = transformContentNoAmount(_content);

  for (const _unit of _currentUnits) {
    const amount = getAmounts(allUnits, _unit);

    if (amount === undefined) _content = transformContentNoAmount(_content);
    else {
      const indices = getStrictIndicesOfUnitWithTime(_unit, _content, true);

      if (indices.length !== 0) {
        _content = getUnitParsedText(_content, amount, _unit);
      }
    }
  }

  return _content;
}

function transformContentNoAmount(_content) {
  _content = _content
    .replace(/&lt;nocalc&gt;/g, "")
    .replace(/&lt;\/nocalc&gt;/g, "");

  // Regex für Formeln mit Zeiteinheit
  const regexWithTimeUnit =
    /(\d+(?:,\d+)?|\d+)\s+\$MAX=(\d+(?:,\d+)?|\d+)\s*([\wµ.]+)\/([\wµ]+)\/([\wµ]+)\s*(KG)?/g;

  // Regex für Formeln ohne Zeiteinheit
  const regexWithoutTimeUnit =
    /(\d+(?:,\d+)?|\d+)\s+\$MAX=(\d+(?:,\d+)?|\d+)\s*([\wµ.]+)\/([\wµ]+)\s*(KG)?/g;

  // Verarbeiten von Formeln mit Zeiteinheit
  _content = _content.replace(
    regexWithTimeUnit,
    (match, X, Y, n, m, time, kg) => {
      return `${X} ${n}/${m}/${time} ${
        kg ? kg + " " : ""
      }(max. ${Y} ${n}/${time}) `;
    }
  );

  // Verarbeiten von Formeln ohne Zeiteinheit
  _content = _content.replace(regexWithoutTimeUnit, (match, X, Y, n, m, kg) => {
    return `${X} ${n}/${m} ${kg ? kg + " " : ""}(max. ${Y} ${n}) `;
  });

  _content = _content.replace(/<p>\$adjust_weight<\/p>/g, "");

  return _content;
}

function getAmounts(allUnits, unit) {
  // Return appropriate amounts for current unit context
  if (allUnits?.amount !== undefined) return allUnits.amount;
  else if (allUnits?.weight !== undefined) {
    return allUnits.weight;
  }

  return allUnits?.[unit];
}
