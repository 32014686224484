import { Feather } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import messaging from "@react-native-firebase/messaging";
import { useEffect, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../locales/i18n";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { posthog } from "../api/analytics/posthog";
import apiCall from "../api/api-call";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import ProgressBar from "../components/registry/ProgressBar";
import { colors } from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import { showInlineNotification } from "../entry/Root";
import { store } from "../functions/store";
import {
  changeUserInformation,
  selectCurrentUser,
} from "../functions/user/actions";

import configureAnimations from "../functions/configure-animations";
import getFontStyle from "../functions/getFontSize";
import { updateOfflineUser } from "../functions/user/functions";
import { APP_TARGET } from "./LaunchScreen";

export default function PushNotificationScreen({ navigation, route }) {
  const isFromSettings = route.params?.isFromSettings;
  const preventClosing = route.params?.preventClosing;

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "push_notification_preferences",
      "PushNotificationScreen",
      "Opened",
      {
        isFromSettings,
        preventClosing,
      }
    );
  }, []);

  async function getPreviousState(key) {
    const value = (await AsyncStorage.getItem(key)) ?? "true";
    return value === "true";
  }

  async function requestUserPermission() {
    const authStatus = await messaging().requestPermission();
    const enabled =
      authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
      authStatus === messaging.AuthorizationStatus.PROVISIONAL;

    return enabled;
  }

  function continueAction() {
    setLoading(false);

    if (preventClosing) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: APP_TARGET,
            state: {
              routes: [
                {
                  name: "HomeScreen",
                  params: { showUserModal: true },
                },
              ],
            },
          },
        ],
      });
      return;
    }
    if (isFromSettings) navigation.goBack();
    else {
      navigation.reset({
        index: 0,
        routes: [{ name: APP_TARGET as any }],
      });
    }
  }

  async function handleNotificationChoice(choice) {
    configureAnimations();
    if (Platform.OS !== "web")
      RNReactNativeHapticFeedback.trigger("impactLight");
    posthog?.capture("push_notification_preferences_saved");

    setLoading(true);
    try {
      if (choice) {
        const enabled = await requestUserPermission();
        if (!enabled) {
          showInlineNotification({
            text:
              i18n.locale === "de"
                ? "Um Push-Benachrichtigungen für LANDO zu aktivieren, gehe zu Einstellungen > LANDO > Benachrichtigungen und schalte sie ein."
                : i18n.t("send_feedback_now"),
            type: InlineNotificationType.ERROR,
          });
          continueAction();
          return;
        }
        await messaging().registerDeviceForRemoteMessages();
      }

      const prevNotificationsEnabled = await getPreviousState(
        "notificationsEnabled"
      );

      const pnPreference: string[] = [];

      if (choice) {
        posthog?.capture("push_notifications_enabled");
        pnPreference.push("UPDATES", "MOTIVATIONAL");
        await messaging().subscribeToTopic("updates");
        await messaging().subscribeToTopic("motivational");
      } else if (prevNotificationsEnabled) {
        posthog?.capture("push_notifications_disabled");
        await messaging().unsubscribeFromTopic("updates");
        await messaging().unsubscribeFromTopic("motivational");
      } else {
        posthog?.capture("push_notifications_disabled");
      }

      await messaging().subscribeToTopic(currentUser.user_id);
      await AsyncStorage.setItem("notificationsEnabled", choice.toString());

      try {
        await apiCall(
          "/api/v2/users/pn-preferences",
          {
            pn_preference: pnPreference,
          },
          false,
          false,
          "PUT"
        );
        dispatch(
          changeUserInformation({
            indexKey: "pn_preference",
            value: pnPreference,
          })
        );
        updateOfflineUser(store.getState().userReducer.user as any);
      } catch {
        showInlineNotification({
          text:
            i18n.locale === "de"
              ? "Ein Fehler ist aufgetreten. Bitte versuche es später erneut."
              : i18n.t("error_occurred_try_later"),
          type: InlineNotificationType.ERROR,
        });
      }
    } finally {
      continueAction();
    }
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        marginTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
        backgroundColor: colors.ui.background,
      }}
    >
      {!isFromSettings ? (
        <ProgressBar currentStep={3} subSteps={8} currentSubStep={8} />
      ) : (
        !preventClosing && (
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            style={{
              alignSelf: "flex-end",
              padding: 20,
              marginBottom: -20,
              marginTop: -20,
            }}
          >
            <Feather name="x" size={32} color={colors.ui.textPrimary} />
          </TouchableOpacity>
        )
      )}

      <Image
        style={{
          flex: 1,
          resizeMode: "contain",
          ...Platform.select({ android: { paddingTop: 20 } }),
        }}
        source={require("../../assets/images/characters/push-new.png")}
      />
      <View
        style={{
          alignSelf: "stretch",
          flex: 3,
          marginTop: 32,
          margin: Sizes.defaultContainerPadding,
        }}
      >
        <Text
          style={{
            fontSize: 26,
            ...getFontStyle(500),
            marginBottom: 20,
            color: colors.ui.textPrimary,
          }}
        >
          Möchtest du Push-Benachrichtigungen erhalten?
        </Text>
        <Text
          style={{
            ...getFontStyle(400),
            fontSize: 16,
            color: colors.ui.textPrimary,
            opacity: 0.75,
            marginBottom: 32,
            flex: 1,
          }}
        >
          Du bekommst inhaltliche Updates von LANDO. Keine Sorge, du kannst dich
          jederzeit abmelden.
        </Text>
        <View style={{ flexShrink: 0, gap: 20 }}>
          {!loading ? (
            <>
              <DefaultButton
                style={{}}
                loading={loading}
                type={ButtonTypes.Primary}
                title="Ja"
                action={() => handleNotificationChoice(true)}
              />
              <DefaultButton
                style={{}}
                loading={loading}
                type={ButtonTypes.Secondary}
                title="Nein"
                action={() => handleNotificationChoice(false)}
              />
            </>
          ) : (
            <ActivityIndicator
              size="small"
              style={{ marginBottom: 12 }}
              color={colors.ui.accent}
            />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
