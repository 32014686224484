import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Text, TextInput, View } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ChecklistComponent } from "../../components/ChecklistComponent";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import ResultBoxes from "../../components/result/ResultBoxComponent";
import { ResultPagesWrapper } from "../../components/ResultPagesWrapper";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { ResultPageUserInteractionsHeader } from "../ResultPageScreen/ResultPageScreen";

import { useDispatch, useSelector } from "react-redux";
import { selectPatientData } from "../../functions/calculator/actions";
import { setChecklistData } from "../../functions/current-result/actions";
import getFontStyle from "../../functions/getFontSize";
import { addToPagePatientUpdate } from "../../functions/user/functions";

export const isSublistChecked = (sublist, checkedItems) => {
  if (!sublist) return false; // Ensures there's a sublist to check

  return sublist.every((subItem) => {
    // First, check if the subItem itself is optional; if so, it's considered "checked"
    if (subItem.isOptional) {
      return true;
    }
    // Check if the subItem is checked
    const isCurrentItemChecked = checkedItems?.has(subItem.id);

    // If the subItem has further subItems (i.e., a nested sublist), check them recursively
    if (subItem.listElements) {
      return isSublistChecked(subItem.listElements, checkedItems);
    }

    // If there are no further nested subItems, return the check status of the current item
    return isCurrentItemChecked;
  });
};

const ChecklistScreen = ({ route }) => {
  const pageContent = route.params.pageContent;
  const dispatch = useDispatch();

  const setInterpretationContent = route.params?.setInterpretationContent;
  const interpretationContent = route.params?.interpretationContent;

  const [warningModalVisible, setWarningModalVisible] = useState(false);

  const [checkedItems, setCheckedItems] = useState(
    interpretationContent?.checklistItems ?? new Set()
  );

  const [note, setNote] = useState(interpretationContent?.note ?? "");

  // patient data logic
  const patientData = useSelector(selectPatientData);

  const [activePatientPage, setActivePatientPage] = useState<any>(null);

  useEffect(() => {
    const currentPage = patientData?.data?.find(
      (page) => page.result_page_id === pageContent._id
    );
    setActivePatientPage(currentPage);

    setCheckedItems(currentPage?.data?.[0]?.checkedItems ?? checkedItems);
    setNote(currentPage?.data?.[0]?.note ?? note);
  }, [pageContent._id, patientData]);

  useEffect(() => {
    if (activePatientPage) {
      if (
        activePatientPage.data?.[0]?.checkedItems !== checkedItems ||
        activePatientPage.data?.[0]?.note !== note
      ) {
        addToPagePatientUpdate(patientData, pageContent._id, [
          { checkedItems, note },
        ]);
      }
    }
    // TODO: debounce with note
  }, [checkedItems, note]);

  // synch redux
  useEffect(() => {
    //TODO Debounce note
    setInterpretationContent({ checkedItems, note });
    dispatch(setChecklistData({ checkedItems, note }));
    return () => {
      dispatch(setChecklistData(null));
    };
  }, [checkedItems, note]);

  const navigation = useNavigation();

  const inset = useSafeAreaInsets();

  const listLength = pageContent?.checklistItems.filter(
    (item) => !item.isOptional && item.typeId === "checklistListElement"
  ).length;

  const completed = pageContent?.checklistItems.every((item) => {
    if (item.typeId !== "checklistListElement") return true;
    if (item.isOptional) {
      return true;
    }
    if (item.listElements) {
      return isSublistChecked(item.listElements, checkedItems);
    }
    return checkedItems?.has(item.id);
  });

  return (
    <View style={{ flex: 1 }}>
      <ResultPagesWrapper
        isModal={route.params?.modal}
        navigation={navigation}
        pageContent={pageContent}
        warningModalVisible={warningModalVisible}
        setWarningModalVisible={setWarningModalVisible}
        historyUseEffectObj={{
          customFunction: null,
          logUserScreenInteraction: {
            screen: "ChecklistPage",
            action: "checklist_screen_opened",
            id: pageContent?._id,
            values: pageContent?.title,
          },
        }}
      >
        <View
          style={{
            width: "100%",
            zIndex: 10,
            paddingHorizontal: Sizes.defaultContainerPadding,
          }}
        >
          <ResultPageUserInteractionsHeader
            setWarningModalVisible={setWarningModalVisible}
            pageContent={pageContent}
          />
          {pageContent?.nonChecklistContent && (
            <ResultBoxes
              pageContent={{ boxes: pageContent?.nonChecklistContent }}
            />
          )}
          <ChecklistComponent
            {...{ checkedItems, setCheckedItems, pageContent }}
          />
          <Text
            style={{
              ...getFontStyle(600),
              marginTop: 20,
              fontSize: Sizes.boxText,
            }}
          >
            Bemerkungen
          </Text>
          <TextInput
            defaultValue={note}
            onChangeText={(text) => {
              setNote(text);
            }}
            placeholder="Füge hier weitere Notizen hinzu..."
            multiline
            placeholderTextColor={colors.ui.textPlaceholder}
            style={{
              color: colors.ui.textPrimary,
              backgroundColor: colors.ui.cardBackground,
              borderRadius: 8,
              padding: 12,
              paddingTop: 12,
              height: 100,
              marginTop: 12,
              fontFamily: "HostGrotesk-Regular",
            }}
          />
        </View>
      </ResultPagesWrapper>
      <View>
        <ChecklistInterpretation
          checklistItems={pageContent?.checklistItems}
          isRemoteScreen={setInterpretationContent}
          style={{ padding: 16, paddingBottom: inset.bottom + 16 }}
          {...{ completed, checkedItems, pageContent, listLength }}
        />
      </View>
    </View>
  );
};

export const getCheckedMainItems = (checklistItems, checkedItems) => {
  if (!checklistItems) return new Set();
  return new Set(
    checklistItems
      .filter((item) => !item.isOptional)
      .filter((item) => {
        if (item.listElements) {
          return isSublistChecked(item.listElements, checkedItems);
        }
        return checkedItems?.has(item.id);
      })
      .map((item) => item.id)
  );
};

export const ChecklistInterpretation = ({
  completed,
  checkedItems,
  style,
  listLength,
  isRemoteScreen = false,
  checklistItems,
}) => {
  const navigation = useNavigation();

  const filteredChecklistItems = checklistItems.filter(
    (item) => item.typeId === "checklistListElement"
  );

  return (
    <View
      style={{
        padding: 12,
        alignItems: "center",
        backgroundColor: getChecklistColor(completed),
        ...style,
      }}
    >
      <Text
        style={{
          ...getFontStyle(700),
          fontSize: Sizes.boxText,
        }}
      >{`${
        getCheckedMainItems(filteredChecklistItems, checkedItems).size
      } von ${listLength} Punkten erledigt`}</Text>
      {isRemoteScreen && (
        <DefaultButton
          title="Ergebnis übertragen"
          action={() => {
            navigation.goBack();
          }}
          type={ButtonTypes.Primary}
          style={{ marginHorizontal: 20, marginTop: 12 }}
        />
      )}
    </View>
  );
};

export function getChecklistColor(completed) {
  return completed ? colors.semantic.success : colors.semantic.error;
}

export default ChecklistScreen;
