import Ionicons from "@expo/vector-icons/Ionicons";
import { useNavigation } from "@react-navigation/native";
import { default as LottieView } from "lottie-react-native";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  Easing,
  Keyboard,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import RNReactNativeHapticFeedback from "react-native-haptic-feedback";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../../../locales/i18n";
import { AnalyticsHandler } from "../../../api/analytics/AnalyticsHandler";
import { ButtonTypes, DefaultButton } from "../../../components/DefaultButton";
import { DefaultInputComponent } from "../../../components/default-input/DefaultInputComponent";

import RegistryHintModal from "../../../components/modals/RegistryHintModal";
import ProgressBar from "../../../components/registry/ProgressBar";
import Colors from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import configureAnimations from "../../../functions/configure-animations";
import { logout } from "../../../functions/profile/functions";
import {
  addToUserCreationObject,
  selectCurrentUser,
  selectUserCreationObject,
} from "../../../functions/user/actions";
import { addAdditionalUserInformation } from "../../../functions/user/functions/registry";
import getColorScheme from "../../../hooks/useColorScheme";
import { APP_TARGET } from "../../LaunchScreen";
import {
  AdditionalInformationView,
  InputWithTitle,
} from "./AdditionalInformation/AdditionalInformationComponent";
import { contentDoc, contentNurse, contentStudent } from "./static";
import { contentDocEN, contentNurseEN, contentStudentEN } from "./static-en";

function assignContent(user_type: string) {
  const isEn = i18n.locale.includes("en");

  switch (user_type) {
    case "doctor":
    case "doc":
      return isEn ? contentDocEN : contentDoc();

    case "student":
      return isEn ? contentStudentEN : contentStudent();

    case "nurse":
      return isEn ? contentNurseEN : contentNurse();
    default:
      return contentDoc();
  }
}

export default function NewRegistryScreen() {
  const moveAnim = useRef(new Animated.Value(0)).current;
  const moveUpAnim = useRef(new Animated.Value(200)).current;
  const textFieldRef = useRef<any>();

  const userCreationObject = useSelector(selectUserCreationObject);
  const currentUser = useSelector(selectCurrentUser);

  const content = assignContent(userCreationObject.user_type);

  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showHintModal, setShowHintModal] = useState(false);

  const [loading, setLoading] = useState(false);

  let stopDown: any;
  const isAdditionalInformation =
    content[currentIndex]?.key === "additional_info";

  const moveUp = useCallback(
    (to: number) => {
      clearTimeout(stopDown);
      Animated.timing(moveUpAnim, {
        toValue: to,
        duration: 300,
        easing: Easing.in(Easing.quad),
        useNativeDriver: false,
      }).start();
    },
    [moveUpAnim]
  );

  useEffect(() => {
    if (currentUser.user_type === "non_medical") {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: "UserEngagementScreen",
          },
        ],
      });
    }
  }, []);

  useEffect(() => {
    i18n.locale = "de";

    if (!userCreationObject.user_type)
      dispatch(addToUserCreationObject({ user_type: currentUser?.user_type }));

    if (currentUser?.student_type && !userCreationObject.student_type) {
      dispatch(
        addToUserCreationObject({ student_type: currentUser?.student_type })
      );
    }
  }, [currentUser]);

  // useEffect(() => {
  //   showInlineNotification({
  //     text: i18n.t("thanks_for_verify"),
  //     type: InlineNotificationType.SUCCESS,
  //   });
  // }, []);

  const moveDown = useCallback(() => {
    stopDown = setTimeout(() => {
      Animated.timing(moveUpAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.in(Easing.quad),
        useNativeDriver: false,
      }).start();
    }, 500);
  }, [moveUpAnim]);

  const moveOut = useCallback(() => {
    Animated.timing(moveAnim, {
      toValue: -Dimensions.get("window").width,
      duration: 300,
      easing: Easing.in(Easing.cubic),
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(moveAnim, {
        toValue: Dimensions.get("window").width,
        duration: 0,
        useNativeDriver: false,
      }).start();
      Animated.timing(moveAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start();
    });
  }, [moveAnim]);

  async function continueAction() {
    if (Platform.OS !== "web")
      RNReactNativeHapticFeedback.trigger("impactLight");

    if (currentIndex === content.length - 1) {
      setLoading(true);
      const result = await addAdditionalUserInformation({
        ...userCreationObject,
      });
      setLoading(false);
      if (result?.status) {
        AnalyticsHandler.getInstance().logLogin();
        if (Platform.OS === "web") {
          navigation.reset({
            index: 0,
            routes: [{ name: APP_TARGET as any }],
          });
        } else {
          navigation.navigate("UserEngagementScreen");
        }
      }
      return;
    }

    const object = {};
    object[content[currentIndex]?.key] = value.trim();

    dispatch(addToUserCreationObject(object));

    if (Platform.OS !== "web") moveOut();

    setTimeout(() => {
      setCurrentIndex((i) => i + 1);
      textFieldRef?.current?.focus();
    }, 350);
  }

  function backAction() {
    configureAnimations();
    if (currentIndex === 0) navigation.goBack();
    else setCurrentIndex((i) => i - 1);
  }

  function showHint() {
    Keyboard.dismiss();
    setShowHintModal(true);
  }

  function onKeyboardDidShow(e: any) {
    moveUp(e.endCoordinates.height * 0.8);
  }

  function onKeyboardDidHide() {
    moveDown();
  }

  useEffect(() => {
    if (userCreationObject[content[currentIndex]?.key]) {
      setValue(userCreationObject[content[currentIndex]?.key]);
    }
  }, [userCreationObject]);

  const handleInputChange = (name, value) => {
    dispatch(
      addToUserCreationObject({
        ...userCreationObject,
        [name]: value,
      })
    );
  };

  useEffect(() => {
    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "registry_step_" + currentIndex,
      "RegistryScreen",
      "UserSelectedNextStep, Step " + currentIndex,
      value
    );

    if (!userCreationObject || content.length - 1 < currentIndex) return;
    if (userCreationObject[content[currentIndex]?.key]) {
      setValue(userCreationObject[content[currentIndex]?.key]);
    } else {
      setValue("");
    }
  }, [currentIndex]);

  useEffect(() => {
    textFieldRef?.current?.focus();

    const showSubscription = Keyboard.addListener(
      "keyboardDidShow",
      onKeyboardDidShow
    );
    const hideSubscription = Keyboard.addListener(
      "keyboardDidHide",
      onKeyboardDidHide
    );

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  if (content.length === 0) return null;
  if (content[currentIndex] === undefined) navigation.goBack();

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
        alignItems: "center",
        //...Platform.select({ web: { maxWidth: "100vw" } }),
        justifyContent: "center",
        overflow: "hidden",
        paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
      }}
    >
      <RegistryHintModal
        open={showHintModal}
        onClose={() => setShowHintModal(false)}
      />
      <ProgressBar
        style={{}}
        currentStep={3}
        subSteps={8}
        currentSubStep={currentIndex + 1}
      />
      <Animated.View
        style={{
          flex: 1,
          //...Platform.select({ web: { maxWidth: "100vw" } }),
          width: "100%",
          marginBottom: isAdditionalInformation
            ? 0
            : Platform.OS === "web"
            ? 30
            : moveUpAnim,
          transform: [{ translateX: moveAnim }],
          padding: 20,
          alignItems: "center",
          justifyContent: isAdditionalInformation ? "flex-start" : "center",
        }}
      >
        {!isAdditionalInformation && (
          <View style={{ maxWidth: Sizes.containerWidth, width: "100%" }}>
            <Text
              style={{
                color: Colors[colorScheme].text,
                fontSize: 32,
                fontWeight: "500",
              }}
            >
              {content[currentIndex].title}
            </Text>
            {content[currentIndex].textInput !== undefined &&
              content[currentIndex]?.key !== "title" && (
                <TextInputComponent
                  value={value}
                  textFieldRef={textFieldRef}
                  setValue={setValue}
                  textObject={content[currentIndex].textInput}
                />
              )}
            {content[currentIndex]?.key === "title" && (
              <InputWithTitle
                title={null}
                name="title"
                placeholder="Dr. / Prof. Dr. / etc."
                presetSelection={[
                  "Dr.",
                  "Prof. Dr.",
                  "Prof.",
                  "PD Dr.",
                  "Dr. med.",
                  "Dr. med. dent.",
                  "Dr. med. vet.",
                  "Dr. rer. nat.",
                  "Dr. rer. pol.",
                  "Dr. rer. oec.",
                  "Dr.-Ing.",
                  "Dr. phil.",
                  "Dr. jur.",
                  "Dr. theol.",
                  "Dr. habil.",
                  "Dr. rer. medic.",
                  "Dr. rer. cur.",
                  "Prof. Dr. med.",
                  "Prof. Dr. med. dent.",
                  "Prof. Dr. med. vet.",
                  "Prof. Dr. rer. nat.",
                  "Prof. Dr. rer. pol.",
                  "Prof. Dr. rer. oec.",
                  "Prof. Dr.-Ing.",
                  "Prof. Dr. phil.",
                  "Prof. Dr. jur.",
                  "Prof. Dr. theol.",
                  "Prof. Dr. habil.",
                  "Prof. Dr. rer. medic.",
                  "Prof. Dr. rer. cur.",
                ]}
                inputValues={userCreationObject}
                handleInputChange={handleInputChange}
              />
            )}
            <ButtonView
              loading={loading}
              currentIndex={currentIndex}
              content={content}
              backAction={backAction}
              continueAction={continueAction}
              value={value}
            />
          </View>
        )}
        {isAdditionalInformation && (
          <AdditionalInformationView
            userCreationObject={userCreationObject}
            content={content[currentIndex]}
            showHint={showHint}
            backAction={backAction}
            loading={loading}
            continueAction={continueAction}
          />
        )}
      </Animated.View>
      <TouchableOpacity>
        <Text
          style={{
            color: Colors[colorScheme].text,
            fontSize: Sizes.boxText,
            fontWeight: "500",
            textDecorationLine: "underline",
            marginTop: 20,
            marginBottom: 20,
            opacity: 0.5,
          }}
          onPress={() => {
            logout(currentUser, navigation);
          }}
        >
          {i18n.t("cancel_registration")}
        </Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
}

export const LoadingView = () => {
  if (Platform.OS === "web")
    return (
      <View
        style={{ height: 120, alignItems: "center", justifyContent: "center" }}
      >
        <ActivityIndicator />
      </View>
    );
  return (
    <LottieView
      style={{
        width: 250,
        height: 120,
        alignSelf: "center",
      }}
      source={require("../../../../assets/animations/loading_dots.json")}
      autoPlay
      loop
    />
  );
};

const ButtonView = ({
  currentIndex,
  backAction,
  continueAction,
  value,
  content,

  loading,
}) => {
  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        marginTop: 30,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DefaultButton
        title={content[currentIndex].buttonBack}
        action={backAction}
        enabled={currentIndex !== 0}
        type={ButtonTypes.Outline}
        style={{ width: "30%" }}
      />
      <DefaultButton
        type={ButtonTypes.Primary}
        title={getButtonTitle(
          content[currentIndex]?.key,
          value,
          content[currentIndex].buttonForward
        )}
        loading={loading}
        textStyle={{ alignSelf: "flex-start" }}
        action={continueAction}
        enabled={getIsContinue(content[currentIndex], value) as boolean}
        icon={<Ionicons name="arrow-forward" size={24} />}
        style={{ width: "50%", marginLeft: 20, paddingHorizontal: 20 }}
      />
    </View>
  );
};

const getButtonTitle = (key, value, text) => {
  if (key === "title") {
    if (value && value !== "") {
      return text;
    }
    return i18n.t("no_continue");
  }
  return text;
};

function getIsContinue(content: any, value: string) {
  switch (content?.key) {
    case "clinic":
      return value !== "" && value;
    case "title":
      return true;
    default:
      return value.trim() !== "" && value && containsOnlySelectedChars(value);
  }
}
function containsOnlySelectedChars(text) {
  return /^[\u0020-\u00FF\u0400-\u04FF]*$/.test(text);
}

const TextInputComponent = ({ textObject, value, setValue, textFieldRef }) => {
  return (
    <View style={{ width: "100%" }}>
      <DefaultInputComponent
        textFieldRef={textFieldRef}
        textObject={textObject}
        setValue={setValue}
        value={value}
      />
    </View>
  );
};
