import { Image, Text, View } from "react-native";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../functions/user/actions";

import { useState } from "react";

import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import getFontStyle from "../../functions/getFontSize";
import useScrollTopMargin from "../../hooks/useScrollTopMargin";
import {
  getDisplayName,
  UserIconWithEdit,
} from "../../screens/more/profile/UserComponent";
import OfflineHintModal from "../modals/OfflineHintModal";

export default function PersonalContentHeader({
  height = 150,
  bottomOffset = 0,
}) {
  const currentUser = useSelector(selectCurrentUser);

  const [showOfflineHint, setShowOfflineHint] = useState(false);

  const topMargin = useScrollTopMargin();

  return (
    <View>
      <OfflineHintModal
        open={showOfflineHint}
        onClose={() => setShowOfflineHint(false)}
      />
      <View
        style={{
          position: "absolute",
          alignItems: "flex-end",
          justifyContent: "flex-end",

          left: -Sizes.defaultContainerPadding,
          right: -Sizes.defaultContainerPadding,
          zIndex: 0,
          bottom: -36 + bottomOffset,
        }}
      >
        <View
          style={{
            left: 0,
            right: 0,
            top: -300,
            bottom: 100,
            position: "absolute",
            zIndex: 0,
          }}
        />
        <Image
          source={require(`../../../assets/images/header/personal.png`)}
          style={{
            width: "100%",

            height: "auto",
            aspectRatio: 1.3,

            resizeMode: "cover",
          }}
        />
      </View>
      <View
        style={{
          // paddingTop:
          //   (Platform.OS === "android"
          //     ? StatusBar.currentHeight ?? 12
          //     : insets.top) + (isLargerDevice() ? 20 : 0),

          height: height,
          marginBottom: bottomOffset,
        }}
      >
        <View
          style={{
            zIndex: 1,
            paddingTop: topMargin / 2,
            paddingBottom: 4,
            //   backgroundColor: colors.ui.background,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "stretch",
            }}
          >
            <UserIconWithEdit size={100} currentUser={currentUser} />
            <Text
              style={{
                fontSize: 24,
                marginTop: 12,
                ...getFontStyle(700),
                color: colors.ui.textPrimary,
              }}
            >
              {getDisplayName(currentUser, null)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
