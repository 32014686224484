import { Animated, StyleSheet } from "react-native";
import { ResultPageContentView } from "../../ResultPageScreen/ResultPageScreen";

interface ResultContainerProps {
  resultContent: any;
  resultOpacityAnim: Animated.Value;
  resultContentAnim: Animated.Value;
}

export const ResultContainer = ({
  resultContent,
  resultOpacityAnim,
  resultContentAnim,
}: ResultContainerProps) => {
  return (
    <Animated.View
      style={[
        styles.resultContainer,
        {
          opacity: resultOpacityAnim,
          transform: [{ translateY: resultContentAnim }],
        },
      ]}
    >
      {resultContent && <ResultPageContentView pageContent={resultContent} />}
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  resultContainer: {},
});
