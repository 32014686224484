import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { Image, Platform, View } from "react-native";
import { useDispatch } from "react-redux";

import { StatusBar } from "expo-status-bar";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { getIsOffline } from "../components/search-tab/SearchResults";
import { colors } from "../constants/static-colors";
import {
  setNavigationIntent,
  setShowNavbar,
} from "../functions/navigation/actions";
import { store } from "../functions/store";
import {
  getOfflineUser,
  getUserDataForLoggedIn,
  isLogged,
} from "../functions/user/functions";
import { User } from "../functions/user/types";
import { initializeAuth } from "./login-registry/LandingScreen/functions/ProviderSignIn";

export const APP_TARGET = "BottomTabNavigator";

export default function LaunchScreen({ route }) {
  useEffect(() => {
    if (route.params?.pageId) {
      dispatch(setNavigationIntent(route.params.pageId));
    }
  }, [route]);

  const navigation = useNavigation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowNavbar(false));

    const asyncOperations = async () => {
      if (!(await isLogged())) {
        return "LandingScreen";
      }

      console.log("Apple Login erkannt:", route.params);
      if (route.params?.code) {
        console.log("Apple Login erkannt:", route.params);

        const success = await (route.params.code,
        route.params.id_token,
        route.params.user);
        if (success) {
          return APP_TARGET; // Weiter zum Dashboard
        }
      }

      if (!(await syncUserData()) && !(await initializeAuth())) {
        console.log("Nicht eingeloggt");
        return "LandingScreen";
      }

      AnalyticsHandler.getInstance().logLogin();
      const updatedUser = store.getState().userReducer.user as User;
      if (Object.keys(updatedUser).length === 0) return "LandingScreen";
      if (
        updatedUser.status === "active" &&
        (updatedUser.firstname || updatedUser.user_type === "enterprise-lite")
      ) {
        return APP_TARGET;
      } else if (updatedUser.status === "pending") {
        return "WaitingForRegistrationScreen";
      } else if (!updatedUser.firstname) {
        return "NewRegistryScreen";
      } else {
        return "LandingScreen";
      }
    };

    Promise.all([asyncOperations()]).then((results) => {
      const navigationTarget = results.find(
        (result) => result !== undefined
      ) as string | undefined;
      if (navigationTarget) {
        navigate(navigationTarget);
      } else {
        navigate("LandingScreen");
      }
    });
  }, []);

  const navigate = (target: any) => {
    navigation.reset({ index: 0, routes: [{ name: target }] });
  };

  async function syncUserData() {
    if (await getIsOffline()) {
      await getOfflineUser();
      return true;
    }

    const success = await getUserDataForLoggedIn();

    return success;
  }

  const renderContent = () => {
    return (
      <View style={getCenteredViewStyle()}>
        <StatusBar style="light" />
        <Image
          source={require("../../assets/logo/logomark/logomark_yellow.png")}
          style={getImageStyle()}
        />
      </View>
    );
    //}
  };

  const getImageStyle: any = () => ({
    width: "60%",
    height: "100%",
    maxWidth: 200,
    resizeMode: "contain",
    ...Platform.select({
      web: { height: "80vh", marginBottom: 50, resizeMode: "contain" },
    }),
  });

  const getCenteredViewStyle: any = () => ({
    width: "60%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.brand.greens.g0c2725,
      }}
    >
      {renderContent()}
      <Image
        source={require("../../assets/images/ce.png")}
        style={{
          position: "absolute",
          bottom: 50,
          width: 50,
          height: 50,
          resizeMode: "contain",
          opacity: 0.2,
          tintColor: "white",
        }}
      />
    </View>
  );
}
