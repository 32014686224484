import {
  StyleSheet,
  Text,
  TouchableOpacity,
  type ViewStyle,
} from "react-native";
import { colors } from "../../../constants/static-colors";

interface ButtonProps {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  style?: ViewStyle;
  textStyle?: ViewStyle;
}

export const Button = ({
  title,
  onPress,
  disabled = false,
  style,
  textStyle,
}: ButtonProps) => {
  return (
    <TouchableOpacity
      style={[styles.button, disabled && styles.disabled, style]}
      onPress={onPress}
      disabled={disabled}
      activeOpacity={0.8}
    >
      <Text style={[styles.buttonText, textStyle]}>{title}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.brand.greens.g339988,
    padding: 15,
    borderRadius: 12,
    alignItems: "center",
  },
  buttonText: {
    color: colors.brand.greys.white,
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "HostGrotesk-Regular",
  },
  disabled: {
    backgroundColor: colors.semantic.disabled,
    opacity: 0.7,
  },
});
