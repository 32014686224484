import { Platform, StatusBar } from "react-native";
import isLargerDevice from "./isLargerDevice";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export default function useScrollTopMargin() {
  const insets = useSafeAreaInsets();
  return (
    (Platform.OS === "android" ? StatusBar.currentHeight ?? 12 : insets.top) +
    (isLargerDevice() ? 20 : 0)
  );
}
