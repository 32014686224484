import Ionicons from "@expo/vector-icons/Ionicons";
import { useEffect, useRef, useState } from "react";
import {
  Animated,
  Platform,
  Pressable,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { ButtonTypes, DefaultButton } from "../../components/DefaultButton";
import {
  InlineNotificationDirection,
  InlineNotificationType,
} from "../../components/Notifications/NotificationComponent";

import i18n from "../../../locales/i18n";
import { colors } from "../../constants/static-colors";
import { Sizes } from "../../constants/static-sizes";
import { showInlineNotification } from "../../entry/Root";
import { sendForgotPwMail } from "../../functions/user/functions";
import { validateEmail } from "../../functions/user/functions/registry";
import { useNavigation } from "@react-navigation/native";

export function ForgotPasswordScreen() {
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [cooldownActive, setCooldownActive] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(30);
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const navigation = useNavigation();

  const ref = useRef<any>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  useEffect(() => {
    if (emailSent) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    } else {
      fadeAnim.setValue(0);
    }
  }, [emailSent, fadeAnim]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (cooldownActive && cooldownTime > 0) {
      interval = setInterval(() => {
        setCooldownTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (cooldownTime === 0) {
      setCooldownActive(false);
      setCooldownTime(30);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [cooldownActive, cooldownTime]);

  const handleSendResetEmail = async () => {
    if (!validateEmail(mail)) return;

    setLoading(true);
    ref.current?.blur();
    const success = await sendForgotPwMail(mail);
    setLoading(false);

    if (success) {
      setEmailSent(true);
      setCooldownActive(true);
      showInlineNotification({
        text: i18n.t("reset_password.success"),
        type: InlineNotificationType.SUCCESS,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    } else {
      setEmailSent(false);
      showInlineNotification({
        text: i18n.t("reset_password.failure"),
        type: InlineNotificationType.ERROR,
        direction: InlineNotificationDirection.FROM_BOTTOM,
      });
    }
  };

  const handleResend = () => {
    handleSendResetEmail();
    setCooldownActive(true);
  };

  const handleNewEmail = () => {
    setEmailSent(false);
    setMail("");
    setTimeout(() => ref.current?.focus(), 100);
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 20,
        backgroundColor: colors.ui.background,
      }}
    >
      <KeyboardAwareScrollView
        style={{ width: "100%" }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ minHeight: "100%" }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            width: "100%",
            justifyContent: Platform.OS === "web" ? "center" : "flex-start",
            alignItems: "center",
            paddingHorizontal: 20,
            marginTop: 16,
          }}
        >
          {!emailSent ? (
            <>
              <View style={styles.headerContainer}>
                <Ionicons
                  name="lock-open-outline"
                  size={48}
                  color={colors.brand?.greens?.g339988 || "#339988"}
                  style={styles.headerIcon}
                />
              </View>
              <Text style={styles.greetingText}>
                Gib deine E-Mail ein, und wir senden dir einen Link zum
                Zurücksetzen deines Passworts.
              </Text>
              <View style={styles.inputContainer}>
                <Ionicons
                  name="mail-outline"
                  size={20}
                  color={colors.ui.textPlaceholder}
                  style={styles.inputIcon}
                />
                <TextInput
                  placeholder={i18n.t("reset_password.placeholder")}
                  textContentType="emailAddress"
                  ref={ref}
                  placeholderTextColor={colors.ui.textPlaceholder}
                  keyboardType="email-address"
                  style={styles.textField}
                  onChangeText={(t) => setMail(t)}
                  defaultValue={mail}
                />
              </View>

              <DefaultButton
                type={ButtonTypes.Primary}
                title={i18n.t("reset_password.reset")}
                enabled={validateEmail(mail)}
                loading={loading}
                icon={<Ionicons name="arrow-forward" size={24} />}
                action={handleSendResetEmail}
                style={styles.primaryButton}
              />
            </>
          ) : (
            <>
              <Animated.View
                style={[styles.successContainer, { opacity: fadeAnim }]}
              >
                <Ionicons
                  name="checkmark-circle"
                  size={48}
                  color={colors.brand?.greens?.g339988 || "#339988"}
                  style={styles.successIcon}
                />
                <Text style={styles.successTitle}>
                  E-Mail erfolgreich verschickt!
                </Text>
                <Text style={styles.successText}>
                  Vergiss nicht im Spam-Ordner zu schauen, falls du die E-Mail
                  nicht findest.
                </Text>
              </Animated.View>
              <View style={styles.buttonContainer}>
                <DefaultButton
                  type={ButtonTypes.Primary}
                  title={
                    cooldownActive
                      ? `Erneut senden (${cooldownTime})`
                      : "Erneut senden"
                  }
                  enabled={!cooldownActive}
                  icon={<Ionicons name="refresh" size={20} />}
                  action={handleResend}
                  style={styles.actionButton}
                />

                <DefaultButton
                  type={ButtonTypes.Secondary}
                  title="Neue E-Mail eingeben"
                  enabled={true}
                  icon={<Ionicons name="mail" size={20} />}
                  action={handleNewEmail}
                  style={styles.actionButton}
                />
                <Pressable onPress={() => navigation.goBack()}>
                  <Text
                    style={{ color: colors.ui.secondary, textAlign: "center" }}
                  >
                    Zurück zum Login
                  </Text>
                </Pressable>
              </View>
            </>
          )}
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: "center",
    marginTop: 20,
    marginBottom: 20,
  },
  headerIcon: {
    marginBottom: 16,
  },
  headerText: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.ui.textPrimary,
    fontFamily: "HostGrotesk-Regular",
  },
  greetingText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.ui.textPrimary,
    marginBottom: 32,
    maxWidth: Sizes.containerWidth,
    lineHeight: 22,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: colors.ui.inputBackground,
    borderRadius: 12,
    width: "100%",
    height: 56,
    maxWidth: Sizes.containerWidth,
    paddingHorizontal: 16,
    marginBottom: 24,
  },
  inputIcon: {
    marginRight: 12,
  },
  textField: {
    flex: 1,
    color: colors.ui.textPrimary,
    height: "100%",
    fontFamily: "HostGrotesk-Regular",
    fontSize: Sizes.boxText,
  },
  primaryButton: {
    width: "100%",
    maxWidth: Sizes.containerWidth,
  },
  successContainer: {
    alignItems: "center",
    backgroundColor: "rgba(51, 153, 136, 0.1)",
    borderRadius: 16,
    padding: 24,
    width: "100%",
    maxWidth: Sizes.containerWidth,
    marginVertical: 20,
  },
  successIcon: {
    marginBottom: 16,
  },
  successTitle: {
    fontSize: 20,
    fontWeight: "bold",
    color: colors.brand?.greens?.g339988 || "#339988",
    marginBottom: 8,
    fontFamily: "HostGrotesk-Regular",
  },
  successText: {
    fontSize: 15,
    textAlign: "center",
    color: colors.ui.textPrimary,
    marginBottom: 24,
    lineHeight: 22,
    fontFamily: "HostGrotesk-Regular",
  },
  buttonContainer: {
    width: "100%",
    gap: 16,
  },
  actionButton: {
    width: "100%",
  },
});
