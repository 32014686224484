import { useEffect, useMemo, useState } from "react";
import { TextInput, View } from "react-native";
import { getSharedStylesContent } from "../../../components/boxes/SharedStyles";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";

export default function ScoreElementInput({
  data,
  setCurrentScore,
  isEuroscore,
  setAnswers,
  answers,
}: {
  readonly data: any;
  readonly setCurrentScore: any;
  readonly isEuroscore: boolean;
  readonly setAnswers: any;
  readonly answers: any;
}) {
  // Find any existing answer for this title
  const currentAnswer = answers.find(
    (element: any) => element.title === data.title
  );

  // Keep track of the current numeric “score” & the raw text input
  const [currentValue, setCurrentValue] = useState<number | null>(
    currentAnswer?.score ?? null
  );
  const [input, setInput] = useState(currentAnswer?.value ?? "");

  // For rendering the question text in a certain width

  const [width, setWidth] = useState(0);

  /**
   * Recompute the score whenever the input changes
   */
  useEffect(() => {
    // If empty, do nothing (or treat as zero)
    if (input === "") return;

    let parsed = parseInt(input);
    if (isNaN(parsed)) {
      parsed = 0;
    }
    const prev = currentValue ?? 0;

    let newScore = 0;

    if (isEuroscore) {
      // EuroSCORE logic
      let v = parsed <= 60 ? 1 : parsed - 60;
      v = v * 0.0285181;
      newScore = Math.round(v * 100) / 100;
    } else {
      // Normal or reversed logic
      if (data.isReverse !== 1) {
        // Normal logic
        if (parsed < data.lowerBound) parsed = data.lowerBound;
        newScore = (parsed - data.lowerBound) / data.divisionNumber;
        if (parsed >= data.upperBound) newScore = 25;
      } else {
        // Reversed logic
        if (parsed > data.upperBound) parsed = data.upperBound;
        newScore = (data.upperBound - parsed) / data.divisionNumber;
        if (parsed <= data.lowerBound) newScore = 25;
      }
    }

    setCurrentValue(newScore);
    setCurrentScore((score: number) => score - prev + newScore);

    // Update global answers array
    setAnswers((answerArr: any[]) => {
      const filtered = answerArr.filter((el) => el.title !== data.title);
      return [
        ...filtered,
        { title: data.title, value: input, score: newScore, data: null },
      ];
    });
  }, [input]);

  return (
    <View
      style={{
        borderRadius: 12,
        backgroundColor: colors.ui.cardBackground,
        borderColor: colors.ui.border,
        borderWidth: 1,
        marginBottom: 20,
        padding: 12,
        flexDirection: "row",
        alignItems: "center",
        gap: 12,
      }}
    >
      {/* Top row: question text + score or icon */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        {/* The question text */}
        <View
          style={{ flex: 1 }}
          onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
        >
          {width !== 0 && <TextItem data={data} />}
        </View>
      </View>

      {/* Input row (always visible now) */}
      <View
        style={{
          flexDirection: "row",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextInput
          placeholder={data.placeholder}
          placeholderTextColor={colors.ui.textPlaceholder}
          keyboardType="numeric"
          value={input}
          onChangeText={(e) => setInput(e)}
          style={{
            paddingHorizontal: 20,
            borderRadius: 12,
            paddingVertical: 12,
            borderWidth: 1,
            flex: 1,
            color: colors.ui.textPrimary,
            fontSize: Sizes.boxText,
            borderColor: input ? colors.ui.primary : colors.ui.border,
            backgroundColor: !input
              ? colors.ui.inputBackground
              : colors.ui.highlight,
            fontFamily: "HostGrotesk-Regular",
          }}
        />
      </View>
    </View>
  );
}

const TextItem = ({ data }: { data: any }) => {
  return useMemo(() => {
    return (
      <BoxTextComponent
        style={getSharedStylesContent(data.title)}
        content={"<b>" + data.title + "</b>"}
      />
    );
  }, [data.title]);
};
