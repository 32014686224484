import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../components/CheckBoxComponent";
import { ButtonTypes, DefaultButton } from "../components/DefaultButton";
import { WeightWarningLabel } from "../components/header/DefaultHeaderComponents";
import TitleText from "../components/result/TitleText";
import staticColors from "../constants/static-colors";
import { Sizes } from "../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  setPatientData,
} from "../functions/calculator/actions";
import configureAnimations from "../functions/configure-animations";

export default function AddPatientScreen() {
  const patientData = useSelector(selectPatientData);

  const [patient, setPatient] = useState({
    name: patientData?.name ?? "",
    weight: patientData?.weight ?? "",
    size: patientData?.size ?? "",
  });

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);

  const warningLevel =
    patient?.weight >= 300 ? 2 : patient?.weight >= 200 ? 1 : 0;

  const handleNameChange = (name: string) => {
    setPatient({ ...patient, name });
  };

  const handleWeightChange = (weight: string) => {
    setPatient({ ...patient, weight });
  };

  const handleSizeChange = (size: string) => {
    setPatient({ ...patient, size });
  };

  const handleSubmit = () => {
    dispatch(clearAmount());
    dispatch(setPatientData({ ...patient, timestamp: new Date().toString() }));
    navigation.goBack();
  };

  useEffect(() => {
    configureAnimations();
  }, [warningLevel]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          flex: 1,
          width: "100%",
        }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
      >
        <TitleText
          title={
            patientData ? "Patienten bearbeiten " : "Neuen Patienten anlegen"
          }
          style={{ marginBottom: 24 }}
        />
        <Text style={styles.text}>Kennung</Text>
        <TextInput
          style={styles.input}
          placeholder="Kennung"
          defaultValue={patient.name}
          autoFocus={patient.name === ""}
          onChangeText={handleNameChange}
        />
        <Text style={styles.text}>Gewicht (in KG)</Text>
        <TextInput
          style={styles.input}
          placeholder="Gewicht [in kg]"
          defaultValue={patient.weight}
          onChangeText={handleWeightChange}
          keyboardType="numeric"
        />
        <WeightWarningLabel
          warningLevel={warningLevel}
          style={{
            marginHorizontal: 20,
            marginBottom: 12,
          }}
        />
        <Text style={styles.text}>Körpergröße [in cm] (optional)</Text>
        <TextInput
          style={styles.input}
          placeholder="Körpergröße [in cm] (optional)"
          defaultValue={patient.size}
          onChangeText={handleSizeChange}
          keyboardType="numeric"
        />
        {!patientData && (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",

              alignSelf: "center",
              marginTop: 20,
            }}
          >
            <CheckBox isSelected={checked} setSelected={setChecked} />
            <TouchableOpacity onPress={() => setChecked((s) => !s)}>
              <Text
                style={{
                  marginLeft: 8,
                  fontWeight: "500",
                  fontSize: 14,
                  color: staticColors["light"].text,
                }}
              >
                Patienten speichern
              </Text>
            </TouchableOpacity>
          </View>
        )}

        <DefaultButton
          title={patientData ? "Änderungen speichern" : "Weiter"}
          enabled={patient.name.length > 0 && patient.weight.length > 0}
          icon={
            <Feather
              name={patientData ? "save" : "arrow-up-right"}
              size={20}
              color="black"
            />
          }
          action={handleSubmit}
          type={ButtonTypes.Primary}
          style={{ marginTop: 30 }}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: Sizes.defaultContainerPadding,
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 24,
    backgroundColor: staticColors["light"].background,
  },
  text: {
    alignSelf: "flex-start",
    fontSize: Sizes.boxText,
    fontWeight: "600",
    marginTop: 12,
  },
  input: {
    backgroundColor: staticColors["light"].foreground,
    color: staticColors["light"].text,
    maxWidth: Sizes.containerWidth,
    marginBottom: 8,
    borderRadius: 12,
    marginTop: 8,
    width: "100%",
    height: 50,
    padding: 12,
    zIndex: 0,
  },
});
