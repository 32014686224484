import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Animated,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
} from "react-native";

const StoryComponent = ({ storyContent }) => {
  return <View style={styles.storyContentContainer}>{storyContent}</View>;
};

const Stories = ({ storySets }) => {
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const progressAnim = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef(null);
  const screenWidth = Dimensions.get("window").width;

  const currentStories = storySets[currentSetIndex];

  useEffect(() => {
    //animateProgress();
  }, [currentStoryIndex, currentSetIndex]);

  useEffect(() => {
    // Scroll to the correct story in the current set
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        x: currentStoryIndex * screenWidth,
        animated: true,
      });
    }
  }, [currentStoryIndex, currentSetIndex]);

  const animateProgress = () => {
    progressAnim.setValue(0);
    Animated.timing(progressAnim, {
      toValue: 1,
      duration: 5000, // Adjust duration per story
      useNativeDriver: false,
    }).start(({ finished }) => {
      if (finished) handleNextStory();
    });
  };

  const handleNextStory = () => {
    if (currentStoryIndex < currentStories.length - 1) {
      setCurrentStoryIndex(currentStoryIndex + 1);
    } else if (currentSetIndex < storySets.length - 1) {
      setCurrentSetIndex(currentSetIndex + 1);
      setCurrentStoryIndex(0);
    }
  };

  const handlePreviousStory = () => {
    progressAnim.stopAnimation((currentValue) => {
      if (currentValue > 0.05) {
        // Reset to the beginning of the current story
        progressAnim.setValue(0);
        animateProgress();
      } else {
        // Go to the previous story
        if (currentStoryIndex > 0) {
          setCurrentStoryIndex(currentStoryIndex - 1);
        } else if (currentSetIndex > 0) {
          setCurrentSetIndex(currentSetIndex - 1);
          setCurrentStoryIndex(storySets[currentSetIndex - 1].length - 1);
        }
      }
    });
  };

  const handleTap = (position) => {
    if (position === "right") {
      handleNextStory();
    } else {
      handlePreviousStory();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.progressContainer}>
        {currentStories.map((_, index) => {
          let progressWidth;
          if (index < currentStoryIndex) {
            // Fully fill progress for previous stories
            progressWidth = "100%";
          } else if (index === currentStoryIndex) {
            // Animate progress for the current story
            progressWidth = progressAnim.interpolate({
              inputRange: [0, 1],
              outputRange: ["0%", "100%"],
            });
          } else {
            // No fill for upcoming stories
            progressWidth = "0%";
          }
          return (
            <View key={index} style={styles.progressBarBackground}>
              <Animated.View
                style={[
                  styles.progressBarFill,
                  {
                    width: progressWidth,
                  },
                ]}
              />
            </View>
          );
        })}
      </View>

      <ScrollView
        horizontal
        pagingEnabled
        ref={scrollViewRef}
        scrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          width: screenWidth * currentStories.length,
          overflow: "hidden",
        }}
      >
        {currentStories.map((story, index) => (
          <StoryComponent key={index} storyContent={story} />
        ))}
      </ScrollView>

      <View style={styles.touchableContainer}>
        <TouchableOpacity
          style={styles.touchableLeft}
          onPress={() => handleTap("left")}
        />
        <TouchableOpacity
          style={styles.touchableRight}
          onPress={() => handleTap("right")}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: "#fff", overflow: "hidden" },
  progressContainer: {
    flexDirection: "row",
    height: 4,
    marginTop: 16,
    marginHorizontal: 10,
    overflow: "hidden",
  },
  progressBarBackground: {
    flex: 1,
    height: 4,
    backgroundColor: "#E6E7E8",
    marginHorizontal: 2,
  },
  progressBarFill: {
    height: 4,
    backgroundColor: "black",
  },
  storyContentContainer: {
    width: Dimensions.get("window").width,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  touchableContainer: {
    ...StyleSheet.absoluteFillObject,
    flexDirection: "row",
  },
  touchableLeft: {
    flex: 1,
    height: "100%",
  },
  touchableRight: {
    flex: 1,
    height: "100%",
  },
});

export default Stories;
