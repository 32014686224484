import { useMemo } from "react";

import { getSourceParsedText } from "../../../api/content/source-parser";
import staticColors from "../../../constants/static-colors";
import getColorScheme from "../../../hooks/useColorScheme";

import { Sizes } from "../../../constants/static-sizes";
import { getItemTitle } from "../functions";
import { BoxTextComponent } from "../../custom-box-text/CustomBoxTextComponent";

export const TextTypeComponent = ({
  item,
  shouldShowSources,
  expanded,
  depth,
}) => {
  const colorScheme = getColorScheme();

  return useMemo(
    () => (
      <BoxTextComponent
        style={{
          body: {
            fontSize: depth === 0 ? Sizes.boxText + 3 : Sizes.boxText + 2,
            fontWeight: depth === 0 ? "700" : "600",
            flex: 1,
            color: expanded
              ? staticColors["light"].text
              : staticColors[colorScheme].text,
            margin: 0,
            padding: 0,
          },
          p: {
            margin: 0,
            padding: 0,
          },
        }}
        content={
          "<p>" +
          getSourceParsedText(getItemTitle(item), shouldShowSources) +
          "</p>"
        }
      />
    ),
    [item, shouldShowSources]
  );
};
