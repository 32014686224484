import { Feather } from "@expo/vector-icons";
import chroma from "chroma-js";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Animated, Platform, TouchableOpacity, View } from "react-native";

import { Easing } from "react-native-reanimated";
import { useSelector } from "react-redux";
import { getSourceParsedText } from "../../api/content/source-parser";
import staticColors from "../../constants/static-colors";
import { pSBC } from "../../functions/color-parser";
import {
  selectCalculationBoxHeight,
  selectShowSources,
} from "../../functions/current-result/actions";
import getColorScheme from "../../hooks/useColorScheme";

import { BoxTextComponent } from "../custom-box-text/CustomBoxTextComponent";

import { Portal } from "react-native-paper";

import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import { Sizes } from "../../constants/static-sizes";
import configureAnimations, {
  configureExpansionAnimation,
} from "../../functions/configure-animations";
import AnimatedWrapper from "../AnimatedWrapper/AnimatedWrapper";
import BlurViewWrapper from "../BlurViewWrapper/BlurViewWrapper";
import BoxComponent from "../boxes/BoxSorter";
import InViewPort from "../InViewComponent";
import { InlineTextType } from "./components/InlineTextType";
import { TextTypeComponent } from "./components/TextTypeComponent";
import {
  getItemTitle,
  getMarginToDepth,
  isBaseTypeBox,
  reverseCamelCase,
} from "./functions";
import ResultPageSkeleton from "./ResultPageSkeleton";

export const SkeletonItem = ({
  item,
  depth,
  scrollY,
  index,
  remoteClose,
  targetSections,
}) => {
  const colorScheme = getColorScheme();
  const [expanded, setExpanded] = useState(false);

  const [headerInView, setHeaderInView] = useState(false);
  const [contentInView, setContentInView] = useState(false);

  const topPosition = useSelector(selectCalculationBoxHeight);

  const indicatorLineWidth = useRef(new Animated.Value(0)).current;

  const shouldShowSources = useSelector(selectShowSources);

  const generateDarkModeColor = useCallback(
    (color, amount) => {
      if (colorScheme === "light") return color;
      // Darken the color by a certain amount. Adjust the ratio as needed.
      if (!color) return null;
      const darkenedColor = chroma(color).darken(amount).hex();

      return darkenedColor;
    },
    [colorScheme]
  );

  const adjustedColor =
    generateDarkModeColor(pSBC(0.75, item.colour ?? item.color), 3) ??
    staticColors[colorScheme].background;

  const adjustedColor_2 =
    generateDarkModeColor(pSBC(0.4, item.colour ?? item.color), 3) ??
    staticColors[colorScheme].background;

  // useEffect(() => {
  //   setExpanded(false);
  // }, [remoteClose]);

  useEffect(() => {
    configureExpansionAnimation();
    if (!expanded) hideIndicatorLine();
    else {
      setTimeout(() => {
        showIndicatorLine();
      }, 150);
    }
  }, [expanded]);

  const showIndicatorLine = () => {
    Animated.timing(indicatorLineWidth, {
      toValue: Platform.OS === "web" ? 12 : 8,
      duration: 250,
      easing: Easing.in(Easing.quad),
      useNativeDriver: false,
    }).start();
  };

  const hideIndicatorLine = () => {
    Animated.timing(indicatorLineWidth, {
      toValue: 0,
      duration: 50,
      easing: Easing.in(Easing.ease),
      useNativeDriver: false,
    }).start();
  };

  const boxData = useMemo(() => {
    if (Array.isArray(item.content)) {
      return item.content.map((box) => {
        if (box.typeId === "multiContentSectionType") {
          return (
            <View
              key={box.id}
              style={{
                //marginTop: 8,
                marginBottom: 0,
                marginLeft: getMarginToDepth(depth),
              }}
            >
              <SkeletonItem
                key={box.id}
                targetSections={undefined}
                item={box}
                index={index}
                depth={depth + 1}
                scrollY={scrollY}
                remoteClose={remoteClose}
              />
            </View>
          );
        } else {
          return (
            <View
              key={box.id}
              style={{
                marginLeft: getMarginToDepth(depth),
              }}
            >
              <BoxComponent box={box} key={box.id} />
            </View>
          );
        }
      });
    } else if (isBaseTypeBox(item.typeId)) {
      return (
        <View
          style={{
            marginLeft: getMarginToDepth(depth),
          }}
        >
          <BoxComponent box={item} />
        </View>
      );
    }

    return [];
  }, [item.content]);

  useEffect(() => {
    if (!targetSections) return;
    setTimeout(() => {
      if (targetSections.find((section) => section.id === item.id)) {
        configureAnimations();
        setExpanded(true);
      }
    }, 250);
  }, [targetSections]);

  if (isBaseTypeBox(item.typeId))
    return (
      <View
        style={{
          //marginBottom: 12,
          marginLeft: getMarginToDepth(depth),
        }}
      >
        {boxData}
      </View>
    );

  console.log(
    "SkeletonItem",
    "content",
    contentInView,
    "header",
    headerInView,
    "expanded",
    expanded
  );

  return (
    <>
      {contentInView && !headerInView && expanded && (
        <Portal>
          <BlurViewWrapper
            style={{
              width: "100%",
              zIndex: 5,
              opacity: 1,
              paddingBottom: 12,

              paddingTop: 12,
              backgroundColor: adjustedColor_2 + "cc",
              paddingHorizontal: Sizes.defaultContainerPadding,
              position: "absolute",
              top: topPosition,
              left: 0,
              right: 0,
            }}
          >
            <TouchableOpacity
              onPress={() => {
                setExpanded(false);
              }}
              style={{
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <BoxTextComponent
                  style={{
                    body: {
                      color: staticColors[colorScheme].text,
                      fontWeight: "600",
                      fontSize: 15,
                      margin: 0,
                      padding: 0,
                    },
                    p: {
                      margin: 0,
                      padding: 0,
                    },
                  }}
                  content={
                    "<p>" +
                    getSourceParsedText(
                      item.title ?? item.titel ?? reverseCamelCase(item.key),
                      shouldShowSources
                    ) +
                    "</p>"
                  }
                />
              </View>
              <Feather
                name="chevron-up"
                size={24}
                color={staticColors[colorScheme].text}
              />
            </TouchableOpacity>
          </BlurViewWrapper>
        </Portal>
      )}

      <View key={item.key} style={{}}>
        <View
          style={{
            top: 24,
            bottom: 0,
            left: 0,
            right: 0,
            position: "absolute",
            zIndex: 0,
            backgroundColor: adjustedColor,
            opacity: expanded ? 1 : 0,
            marginHorizontal: -Sizes.defaultContainerPadding,
            borderBottomWidth: 4,
            borderBottomColor: adjustedColor_2,
            marginBottom: 0,
          }}
        />

        <View
          style={{
            //marginHorizontal: expanded ? -Sizes.defaultContainerPadding : 0,
            marginLeft: expanded && depth === 0 ? 7 : 0,
            borderWidth: expanded ? 0 : 2,
            borderRadius: 10,
            borderLeftWidth: !expanded ? 7 : 0,
            borderColor:
              generateDarkModeColor(item.colour ?? item.color, 1) ??
              staticColors[colorScheme].lightGray,
            marginVertical: Sizes.defaultBoxPadding,
            marginBottom: expanded ? 36 : 0,
            marginTop: Sizes.defaultBoxPadding + 12,
            flexDirection: "row",
            backgroundColor: expanded
              ? staticColors[colorScheme].transparent
              : adjustedColor,
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                //    paddingHorizontal: Sizes.defaultContainerPadding,
                padding: expanded ? 0 : Sizes.defaultBoxPadding,

                marginBottom: expanded ? 24 : 0,
                marginTop: expanded ? 12 : 0,
              }}
            >
              <AnimatedWrapper style={{ width: "100%" }}>
                <InViewPort
                  onChange={(isInView) => {
                    setHeaderInView(isInView);
                    configureAnimations();
                  }}
                  viewOffset={10}
                >
                  <TouchableOpacity
                    hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
                    style={{
                      flexDirection: "row",
                      //paddingVertical: Sizes.defaultContainerPadding,
                      zIndex: 100,
                      gap: 8,
                      alignItems: "center",
                      paddingHorizontal: Sizes.defaultContainerPadding,
                      marginHorizontal: -Sizes.defaultContainerPadding,
                      overflow: "visible",
                      paddingVertical:
                        Platform.OS === "web" ? 15 : depth === 0 ? 12 : 8,

                      marginVertical: Platform.OS === "web" ? -15 : -12,
                      backgroundColor: !expanded
                        ? staticColors[colorScheme].transparent
                        : adjustedColor_2,
                    }}
                    onPress={() => {
                      configureExpansionAnimation();
                      setExpanded((e) => !e);

                      AnalyticsHandler.getInstance().logUserScreenInteraction(
                        "section_toggle",
                        "SkeletonItem",
                        expanded
                          ? "User Collapsed Section"
                          : "User Expanded Section",
                        {
                          sectionKey: item.key,
                          sectionTitle: getItemTitle(item),
                        }
                      );
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <TextTypeComponent
                        item={item}
                        shouldShowSources={shouldShowSources}
                        expanded={expanded}
                        depth={depth}
                      />
                    </View>
                    <Feather
                      key="arrow"
                      name={expanded ? "chevron-up" : "chevron-down"}
                      size={depth === 0 ? 28 : 20}
                      color={staticColors[colorScheme].text}
                    />
                  </TouchableOpacity>
                </InViewPort>
              </AnimatedWrapper>
            </View>
            <InViewPort
              onChange={(isInView) => {
                setContentInView(isInView);
                configureAnimations();
              }}
              viewOffset={10}
            >
              <AnimatedWrapper style={{ width: "100%" }}>
                {typeof item.content === "string" && expanded && (
                  <InlineTextType item={item} />
                )}

                <View>
                  {Array.isArray(item.content) && boxData.length !== 0 && (
                    <View
                      style={{
                        overflow: expanded ? "visible" : "hidden",
                        height: expanded ? "auto" : 0,
                      }}
                    >
                      {boxData}
                    </View>
                  )}

                  {expanded && boxData.length === 0 && (
                    <View
                      style={{
                        marginTop: -8,
                      }}
                    >
                      <ResultPageSkeleton
                        data={item}
                        depth={depth + 1}
                        scrollY={scrollY}
                        remoteClose={remoteClose}
                      />
                    </View>
                  )}
                </View>
              </AnimatedWrapper>
            </InViewPort>
          </View>
        </View>

        <Animated.View
          style={{
            width: indicatorLineWidth,
            top: 24,
            bottom: 0,
            position: "absolute",
            left: -Sizes.defaultContainerPadding,
            backgroundColor: adjustedColor_2,
          }}
        />
      </View>
    </>
  );
};
