import { useState } from "react";
import { Platform, StatusBar, View } from "react-native";
import { ResultPagesWrapper } from "../components/ResultPagesWrapper";
import { colors } from "../constants/static-colors";
import { ResultPageContentWrapper } from "./ResultPageScreen/ResultPageScreen";

export default function InfoResultPageScreen({ navigation, route }) {
  const { pageContent } = route.params;

  const [warningModalVisible, setWarningModalVisible] = useState(false);

  return (
    <ResultPagesWrapper
      isModal
      navigation={navigation}
      pageContent={pageContent}
      warningModalVisible={warningModalVisible}
      setWarningModalVisible={setWarningModalVisible}
      historyUseEffectObj={{
        customFunction: null,
        logUserScreenInteraction: {
          action: "info_result_screen_opened",
          screen: "InfoResultPageScreen",
          values: "UserOpened",
          id: pageContent?._id,
        },
      }}
    >
      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          flex: 1,
          paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
          backgroundColor: colors.ui.background,
        }}
      >
        <View
          style={{
            width: "100%",
            flex: 1,
          }}
        >
          <ResultPageContentWrapper
            pageContent={pageContent}
            setWarningModalVisible={setWarningModalVisible}
          />
        </View>
      </View>
    </ResultPagesWrapper>
  );
}
