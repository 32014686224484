import { createSlice } from "@reduxjs/toolkit";

//import hints_content from '../../STATIC_CONTENT/hints_content.json'

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    currentTabSelection: "Home",
    searchBarShouldFocus: false,
    showNavbar: false,
    showTermsAndConditions: false,
    navigationIntent: "",
    isDemoMode: false,
    isScrolled: false,
  },
  reducers: {
    setShowNavbar(state, action) {
      state.showNavbar = action.payload;
    },
    setIsDemoMode(state, action) {
      state.isDemoMode = action.payload;
    },
    setCurrentTabSelection(state, action) {
      state.currentTabSelection = action.payload;
    },
    setSearchBarShouldFocus(state, action) {
      state.searchBarShouldFocus = action.payload;
    },

    setShowTermsAndConditions(state, action) {
      state.showTermsAndConditions = action.payload;
    },
    setNavigationIntent(state, action) {
      state.navigationIntent = action.payload;
    },
    setIsScrolling(state, action) {
      state.isScrolled = action.payload;
    },
  },
});

export const {
  setCurrentTabSelection,
  setSearchBarShouldFocus,
  setNavigationIntent,
  setShowNavbar,
  setShowTermsAndConditions,
  setIsDemoMode,
  setIsScrolling,
} = navigationSlice.actions;
export default navigationSlice.reducer;
export const selectTabSelection = (state: any) =>
  state.navigationReducer.currentTabSelection;
export const selectSearchBarShouldFocus = (state: any) =>
  state.navigationReducer.searchBarShouldFocus;
export const selectShowNavbar = (state: any) =>
  state.navigationReducer.showNavbar;
export const selectShowTermsAndConditions = (state: any) =>
  state.navigationReducer.showTermsAndConditions;
export const selectNavigationIntent = (state: any) =>
  state.navigationReducer.navigationIntent;
export const selectIsDemoMode = (state: any) =>
  state.navigationReducer.isDemoMode;
export const selectIsScrolling = (state: any) =>
  state.navigationReducer.isScrolled;
