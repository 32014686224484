import { useEffect } from "react";
import { View } from "react-native";

export default function DefaultScreen({ navigation }) {
  useEffect(() => {
    navigation.reset(
      {
        index: 0,
        routes: [{ name: "LaunchScreen" }],
      },
      0
    );
  }, []);

  return <View style={{ backgroundColor: "black", flex: 1 }} />;
}
