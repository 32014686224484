import { createContext, useContext, useState } from "react";
import { configureExpansionAnimation } from "../../functions/configure-animations";

const ExpansionContext = createContext<any>(null);

export const ExpansionProvider = ({ children }) => {
  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpand = (key) => {
    configureExpansionAnimation();
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const closeAll = () => {
    configureExpansionAnimation();
    setExpandedItems({});
  };

  return (
    <ExpansionContext.Provider
      value={{ expandedItems, toggleExpand, closeAll }}
    >
      {children}
    </ExpansionContext.Provider>
  );
};

export const useExpansion = () => useContext(ExpansionContext);
