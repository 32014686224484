import { Feather } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import {
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";

import { default as i18n, default as I18n } from "../../locales/i18n";
import { InlineNotificationType } from "../components/Notifications/NotificationComponent";
import { colors } from "../constants/static-colors";
import { showInlineNotification } from "../entry/Root";
import getFontStyle from "../functions/getFontSize";
import { selectCurrentUser } from "../functions/user/actions";
import { selectAdditionalFeedbackData } from "../functions/current-result/actions";

export default function FeedbackScreen({ route, navigation }) {
  const { pageContent } = route.params;
  const currentUser = useSelector(selectCurrentUser);
  const [noteText, setNoteText] = useState("");

  const [waiting, setWaiting] = useState(false);

  const additionalFeedbackData = useSelector(selectAdditionalFeedbackData);

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        "feedback_screen_opened",
        "FeedbackScreen",
        "UserOpened"
      );
    });
    return () => {
      unsubscribe();
    };
  }, [navigation]);

  const handleSendFeedback = async () => {
    // Prevent sending if there's no text.
    if (noteText.trim().length === 0) return;

    setWaiting(true);

    let feedbackText = noteText;
    if (additionalFeedbackData) {
      feedbackText = `Referenzierte Flow Page: ${additionalFeedbackData._id}: ${noteText}`;
    }

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "feedback_issued_with_data",
      "FeedbackScreen",
      "UserIssuedFeedback",
      {
        id: pageContent._id,
        feedback: feedbackText,
        user: currentUser.email,
        role: currentUser.role,
      }
    );
    navigation.goBack();
    showInlineNotification({
      type: InlineNotificationType.SUCCESS,
      text:
        i18n.locale === "de"
          ? "Feedback wurde gesendet - Vielen Dank!"
          : i18n.t("thanks_for_feedback"),
    });
    setWaiting(false);
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: "center",
        paddingTop: Platform.OS === "android" ? 20 : 0,
        justifyContent: "center",
        width: "100%",
        height: "100%",
        backgroundColor: colors.ui.background,
      }}
    >
      <Header
        additionalFeedbackData={additionalFeedbackData}
        pageContent={pageContent}
        onSend={handleSendFeedback}
        sendEnabled={noteText.trim().length > 0}
        waiting={waiting}
      />
      <ScrollView
        style={{ width: "100%" }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          flexGrow: 1,
          width: "100%",
          flex: 1,
          alignItems: "center",
        }}
      >
        <View
          style={{
            marginTop: 20,
            alignItems: "flex-start",
            width: "90%",
            flex: 1,
          }}
        >
          <View style={{ width: "100%", flex: 1, paddingBottom: 32 }}>
            <TextInput
              multiline={true}
              placeholder={I18n.t("your_feedback")}
              style={[
                styles.notes_text,
                {
                  flex: 1,
                  width: "100%",
                  backgroundColor: colors.ui.cardBackground,
                  padding: 12,
                  borderRadius: 6,
                  fontFamily: "HostGrotesk-Regular",
                  color: colors.ui.textPrimary,
                },
              ]}
              placeholderTextColor={colors.ui.textPlaceholder}
              onChangeText={(text) => {
                setNoteText(text);
              }}
              value={noteText}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

function Header({
  pageContent,
  onSend,
  sendEnabled,
  waiting,
  additionalFeedbackData,
}) {
  const navigation = useNavigation();

  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        paddingHorizontal: 20,
        paddingVertical: 12,
        height: 80,
        shadowColor: colors.ui.textPrimary,
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        backgroundColor: colors.ui.background,
        elevation: 2,
      }}
    >
      {/* Close Button on the left */}
      <TouchableOpacity
        hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
        onPress={() => {
          navigation.goBack();
        }}
        style={{ padding: 8 }}
      >
        <Feather name="x" size={24} color={colors.ui.textPrimary} />
      </TouchableOpacity>

      {/* Title and Subtitle in the center */}
      <View style={{ flex: 1, alignItems: "center" }}>
        <Text
          style={{
            ...getFontStyle(700),
            fontSize: 16,

            color: colors.ui.textPrimary,
          }}
        >
          {i18n.t("send_feedback_now")}
        </Text>
        <Text
          style={{
            fontSize: 12,
            ...getFontStyle(300),
            color: colors.ui.textPrimary,
          }}
          numberOfLines={1}
        >
          {pageContent.title ?? pageContent.fieldTitle}
        </Text>
        {additionalFeedbackData && (
          <Text
            style={{
              fontSize: 12,
              ...getFontStyle(300),
              marginTop: 4,
              color: colors.ui.textPrimary,
            }}
          >
            {additionalFeedbackData.title}
          </Text>
        )}
      </View>

      {/* Send Icon on the right */}
      <TouchableOpacity
        hitSlop={{ top: 12, left: 12, bottom: 12, right: 12 }}
        onPress={onSend}
        disabled={!sendEnabled || waiting}
        style={[
          {
            padding: 10,
            paddingHorizontal: 16,
            borderRadius: 100,
            justifyContent: "center",
            alignItems: "center",
          },
          {
            backgroundColor:
              !sendEnabled || waiting
                ? colors.brand.greys.gE5E5E5
                : colors.ui.primary,
          },
        ]}
      >
        <Feather
          name="send"
          size={20}
          color={
            !sendEnabled || waiting
              ? colors.brand.greys.gD4D4D4
              : colors.ui.textPrimary
          }
        />
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  notes_text: {
    fontSize: 15,
    color: "black",
  },
});
