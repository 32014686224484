import { Feather } from "@expo/vector-icons";
import QRCode from "react-native-qrcode-svg";

import { useEffect, useState } from "react";
import { Platform, Share, Text, TouchableOpacity, View } from "react-native";
import { AnalyticsHandler } from "../../api/analytics/AnalyticsHandler";
import configureAnimations from "../../functions/configure-animations";

import { colors } from "../../constants/static-colors";
import getFontStyle from "../../functions/getFontSize";
import { ButtonTypes, DefaultButton } from "../DefaultButton";
import CustomModal from "./CustomModal";

const OptionButton = ({ iconName, text, onPress }) => {
  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colors.ui.cardBackground,
        padding: 16,
        width: "100%",
        marginVertical: 10,
        borderRadius: 12,
      }}
      onPress={onPress}
    >
      <Feather name={iconName} size={24} color={colors.ui.textPrimary} />
      <Text
        style={{
          marginLeft: 10,
          fontSize: 16,
          ...getFontStyle(700),
          flex: 1,
          color: colors.ui.textPrimary,
        }}
      >
        {text}
      </Text>
      <Feather name="chevron-right" size={24} color={colors.ui.textPrimary} />
    </TouchableOpacity>
  );
};

export default function ShareOptionModal({ open, onClose, pageContent }) {
  const [qrCodeSelected, setQrCodeSelected] = useState(false);

  function createShareableUrl() {
    const baseUrl = `https://shared.lando.health/remote?pageId=${pageContent?._id}`;
    return baseUrl;
  }

  const shareableURL = createShareableUrl();

  function shareLink() {
    const message = `${
      pageContent?.title ?? pageContent?.fieldTitle
    } in LANDO: ${shareableURL}`;

    AnalyticsHandler.getInstance().logUserScreenInteraction(
      "share_link_selected",
      "ShareOptionModal",
      "User Selected Share Link",
      {
        pageId: pageContent?._id,
        pageTitle: pageContent?.title ?? pageContent?.fieldTitle,
      }
    );

    if (Platform.OS === "web") {
      navigator.clipboard.writeText(shareableURL);
      alert("Link in die Zwischenablage kopiert!");
      return;
    }
    Share.share({
      message: message,
    });
  }

  useEffect(() => {
    if (open) {
      setQrCodeSelected(false);
    }
  }, [open]);

  return (
    <CustomModal {...{ open, onClose }}>
      {!qrCodeSelected && (
        <View style={{ marginTop: 20 }}>
          <OptionButton iconName="link" text="Link" onPress={shareLink} />
          <OptionButton
            iconName="command"
            text="QR Code"
            onPress={() => {
              AnalyticsHandler.getInstance().logUserScreenInteraction(
                "qr_code_selected",
                "ShareOptionModal",
                "User Selected QR Code",
                {
                  pageId: pageContent?._id,
                  pageTitle: pageContent?.title ?? pageContent?.fieldTitle,
                }
              );
              configureAnimations();
              setQrCodeSelected(true);
            }}
          />

          {/* {(pageContent?.type === "scorePage" ||
            pageContent?.type.toLowerCase().includes("result")) && (
            <OptionButton
              iconName="file"
              text="Als PDF exportieren"
              onPress={() => {
                AnalyticsHandler.getInstance().logUserScreenInteraction(
                  "pdf_export_selected",
                  "ShareOptionModal",
                  "User Selected PDF Export",
                  {
                    pageId: pageContent?._id,
                    pageTitle: pageContent?.title ?? pageContent?.fieldTitle,
                  }
                );
                if (pageContent?.type === "scorePage") {
                  onClose();
                  setTimeout(() => {
                    dispatch(setRemoteOpenSaveScoreModal(true));
                  }, 500);
                } else {
                  onClose();
                  dispatch(setIsExporting(true));
                }
              }}
            />
          )}*/}
        </View>
      )}
      {qrCodeSelected && <QRCodeContent shareableUrl={shareableURL} />}

      <DefaultButton
        style={{ marginTop: 32 }}
        title="Schließen"
        type={ButtonTypes.Destruktive}
        action={onClose}
      />
    </CustomModal>
  );
}

const QRCodeContent = ({ shareableUrl }) => {
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 20,
      }}
    >
      <QRCode
        value={shareableUrl} // The link/string you want to encode
        size={200} // Size of the QR code
        color="black" // Color of the QR code
        backgroundColor="white" // Background color
      />
    </View>
  );
};
