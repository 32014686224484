import { Feather } from "@expo/vector-icons";

import { useRef } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import ActionSheet from "react-native-actionsheet";
import { useDispatch, useSelector } from "react-redux";
import { UserIcon } from "../../../components/UserIconComponent";
import Colors from "../../../constants/static-colors";
import { selectEnterprise } from "../../../functions/enterprise/actions";
import {
  deleteProfileImage,
  getImageFromLibrary,
  uploadProfileImage,
} from "../../../functions/profile/functions";
import { setProfilePicture } from "../../../functions/user/actions";
import { User } from "../../../functions/user/types";
import getColorScheme from "../../../hooks/useColorScheme";

async function selectNewProfilePicture(dispatch, currentUser) {
  const image = await getImageFromLibrary();
  if (image !== null) {
    const profilePictureLink = await uploadProfileImage(image, currentUser);
    dispatch(setProfilePicture(profilePictureLink));
  }
}

export function getDisplayName(currentUser, selectedEnterprise) {
  if (currentUser?.firstname) {
    return (
      getTitle(currentUser) +
      currentUser.firstname?.trim() +
      " " +
      currentUser.lastname?.trim()
    );
  } else {
    return selectedEnterprise?.customer_name;
  }
}

export default function UserComponent({ currentUser }) {
  const colorScheme = getColorScheme();
  const styles = createStyles(colorScheme);

  const selectedEnterprise = useSelector(selectEnterprise);

  return (
    <View style={styles.container}>
      <UserIconWithEdit currentUser={currentUser} />
      <View style={{ marginLeft: 20, flex: 1 }}>
        <Text
          style={{
            fontSize: 24,
            fontWeight: "500",
            color: Colors["light"].text,
          }}
        >
          {getDisplayName(currentUser, selectedEnterprise)}
        </Text>
      </View>
    </View>
  );
}

export const UserIconWithEdit = ({ currentUser, size = 92 }) => {
  const dispatch = useDispatch();

  const actionSheet = useRef<any>();

  const pickerOptions = ["Neu aus Galerie", "Löschen", "Abbrechen"];

  const showActionSheet = () => {
    //To show the Bottom ActionSheet
    actionSheet.current?.show();
  };

  const colorScheme = getColorScheme();

  return (
    <>
      <View>
        <TouchableOpacity
          hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
          style={{
            padding: 7,
            aspectRatio: 1,
            backgroundColor: Colors[colorScheme].background,
            borderWidth: 1,
            borderColor: "gray",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            borderRadius: 40,
            bottom: 0,
            right: 0,
            zIndex: 10,
          }}
          onPress={showActionSheet}
        >
          <Feather name="camera" size={17} color={Colors[colorScheme].text} />
        </TouchableOpacity>
        <UserIcon size={size} />
      </View>
      <ActionSheet
        ref={actionSheet}
        title={"Profilbild bearbeiten"}
        options={pickerOptions}
        cancelButtonIndex={2}
        destructiveButtonIndex={1}
        onPress={(index) => {
          if (index === 0) selectNewProfilePicture(dispatch, currentUser);
          else if (index === 1) deleteProfileImage();
        }}
      />
    </>
  );
};

export function getTitle(currentUser: User) {
  if (!currentUser?.title) return "";
  return currentUser.title + " ";
}

const createStyles = (scheme) => {
  const styles = StyleSheet.create({
    container: {
      padding: 8,
      margin: 16,
      marginVertical: 8,
      borderRadius: 8,
      overflow: "hidden",
      backgroundColor: Colors[scheme].foreground,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  });
  return styles;
};
