import { StyleSheet, Text, View } from "react-native";
import RenderHtml from "react-native-render-html";
import { BoxTextComponent } from "../../../components/custom-box-text/CustomBoxTextComponent";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";

export default function ScoreElementConditionalStaticComponent({ data }) {
  return (
    <View style={styles.rowContainer}>
      <View
        style={{
          flex: 2,
          justifyContent: "center",
          borderColor: "#ddd",
          padding: 14,
          borderBottomWidth: 1,
          backgroundColor: colors.ui.cardBackground,
        }}
      >
        <BoxTextComponent
          style={{
            body: {
              fontSize: Sizes.boxText,
              color: colors.ui.textPrimary,
            },
            p: {
              margin: 0,
              padding: 0,
            },
          }}
          content={data.conditionTitle}
        />
      </View>

      <View style={{ flex: 3 }}>
        <View style={styles.answerContainer}>
          <RenderHtml
            contentWidth={Sizes.boxText}
            source={{ html: data.positiveAnswerTitle }}
            tagsStyles={{
              strong: {
                fontWeight: "bold",
                color: colors.ui.textPrimary,
              },
              p: {
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
              },
              ul: { marginVertical: 4, paddingLeft: 20 },
              li: {
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
              },
            }}
          />
        </View>
        {data.positiveConditionScoreElement.map((item, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderColor: "#ddd",
              borderBottomWidth: 1,
              padding: 14,
              backgroundColor: colors.ui.cardBackground,
            }}
          >
            <View style={{ flex: 2 }}>
              <Text
                style={{
                  fontFamily: "HostGrotesk-Regular",
                  fontSize: Sizes.boxText,
                  color: colors.ui.textPrimary,
                }}
              >
                {item.title}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "HostGrotesk-Regular",
                  fontSize: Sizes.boxText,
                  color: colors.ui.textPrimary,
                }}
              >
                {item.value || "N/A"}
              </Text>
            </View>
          </View>
        ))}
        <View style={styles.answerContainer}>
          <RenderHtml
            contentWidth={Sizes.boxText}
            source={{ html: data.negativeAnswerTitle }}
            tagsStyles={{
              strong: {
                fontWeight: "bold",
                color: colors.ui.textPrimary,
              },
              p: {
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
              },
              ul: { marginVertical: 4, paddingLeft: 20 },
              li: {
                color: colors.ui.textPrimary,
                fontSize: Sizes.boxText,
              },
            }}
          />
        </View>
        {data.negativeConditionScoreElement.map((item, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              borderColor: "#ddd",
              borderBottomWidth: 1,
              padding: 14,
              backgroundColor: colors.ui.cardBackground,
            }}
          >
            <View style={{ flex: 2 }}>
              <Text
                style={{
                  fontFamily: "HostGrotesk-Regular",
                  fontSize: Sizes.boxText,
                  color: colors.ui.textPrimary,
                }}
              >
                {item.title}
              </Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "HostGrotesk-Regular",
                  fontSize: Sizes.boxText,
                  color: colors.ui.textPrimary,
                }}
              >
                {item.value || "N/A"}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    borderColor: "#ddd",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    overflow: "hidden",
  },
  answerContainer: {
    padding: 14,
    borderBottomWidth: 1,
    borderColor: "#ddd",
    backgroundColor: colors.ui.cardBackground,
  },
});
