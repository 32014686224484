import { useEffect, useState } from "react";
import {
  LayoutAnimation,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { batch, useDispatch, useSelector } from "react-redux";
import { AnalyticsHandler } from "../api/analytics/AnalyticsHandler";
import { Sizes } from "../constants/static-sizes";
import {
  clearAmount,
  selectPatientData,
  setResultingCalculation,
} from "../functions/calculator/actions";
import {
  setCurrentResultPage,
  setIsExporting,
  setShowTitle,
  shouldShowSources,
} from "../functions/current-result/actions";
import {
  getResultPagesCount,
  increaseResultPagesCount,
} from "../functions/current-result/helpers";
import { selectIsDemoMode } from "../functions/navigation/actions";
import { selectCurrentUser } from "../functions/user/actions";
import {
  addToUserHistory,
  showRatingIfApplicable,
} from "../functions/user/functions";
import ExpandableFABMenu from "../screens/ResultPageScreen/components/ExpandableFABMenu";
import ResultPageHeader from "./header/ResultPageHeaderComponent";
import RatingModal from "./modals/RatingModal";
import WarningModal from "./modals/WarningModal";
import WorkInProgressModal from "./modals/WorkInProgressModal";

interface HistoryUseEffectObj {
  customFunction: (() => void) | null;
  addToHistory?: boolean;
  logUserScreenInteraction: {
    screen: string;
    action: string;
    values: string;
    id: string;
  };
}

interface ResultPagesWrapperProps {
  readonly navigation: any;
  readonly children: React.ReactNode;
  readonly pageContent: any;
  readonly warningModalVisible;
  readonly setWarningModalVisible: any;
  readonly historyUseEffectObj: HistoryUseEffectObj | null;
  readonly showFloatingActionButton?: boolean;
  readonly scrollViewRef?: any;
  readonly isModal?: boolean;
}

export function ResultPagesWrapper({
  navigation,
  children,
  pageContent,
  warningModalVisible,
  setWarningModalVisible,
  historyUseEffectObj,
  showFloatingActionButton = true,
  scrollViewRef,
  isModal = false,
}: ResultPagesWrapperProps) {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const [didShowRating, setDidShowRating] = useState(false);
  const [ratingModalVisible, setRatingModalVisible] = useState(false);
  const [workInProgressModalVisible, setWorkInProgressModalVisible] =
    useState(false);
  const isDemoMode = useSelector(selectIsDemoMode);
  const patientData = useSelector(selectPatientData);

  const toggleWarningModal = () => {
    setWarningModalVisible((m) => !m);
  };

  async function checkIfRating() {
    if (didShowRating) return true;

    if (await showRatingIfApplicable()) {
      setRatingModalVisible(true);
      setDidShowRating(true);
      return false;
    }

    return true;
  }

  // Gemeinsame Methode zum Verlassen der Seite
  function storeActionsOnLeave() {
    dispatch(setShowTitle(false));
    batch(() => {
      if (!patientData) dispatch(clearAmount());
      dispatch(shouldShowSources(false));
      dispatch(setResultingCalculation(null));
      dispatch(setCurrentResultPage(null));
      dispatch(setIsExporting(false));
    });
  }

  // Animation entfernen, um wiederholte Logik zu sparen
  function removeAnimation() {
    LayoutAnimation.configureNext({
      duration: 120,
      create: {
        type: LayoutAnimation.Types.easeInEaseOut,
        property: LayoutAnimation.Properties.opacity,
      },
      update: {
        type: LayoutAnimation.Types.easeInEaseOut,
      },
    });
  }

  useEffect(() => {
    dispatch(setShowTitle(false)); // Einheitliche Logik für ShowTitle
    increaseResultPagesCount();

    const shouldAddToHistory = historyUseEffectObj?.addToHistory ?? true;
    if (shouldAddToHistory) {
      addToUserHistory(historyUseEffectObj?.logUserScreenInteraction?.id);
    }

    if (historyUseEffectObj?.customFunction)
      historyUseEffectObj?.customFunction();

    const unsubscribe = navigation.addListener("focus", () => {
      AnalyticsHandler.getInstance().logUserScreenInteraction(
        historyUseEffectObj?.logUserScreenInteraction?.action,
        historyUseEffectObj?.logUserScreenInteraction?.screen,
        historyUseEffectObj?.logUserScreenInteraction?.values,
        historyUseEffectObj?.logUserScreenInteraction?.id
      );
      dispatch(setCurrentResultPage(pageContent));
    });

    const unsubscribeBeforeRemove = navigation.addListener(
      "beforeRemove",
      async (e) => {
        e.preventDefault();
        removeAnimation();

        const openedPages = getResultPagesCount();

        if (
          currentUser.has_rated ||
          openedPages % 5 !== 0 ||
          (await checkIfRating())
        ) {
          navigation.dispatch(e.data.action);
          storeActionsOnLeaveAsync();
        }
      }
    );

    return () => {
      unsubscribe();
      unsubscribeBeforeRemove();
    };
  }, [didShowRating]);

  async function storeActionsOnLeaveAsync() {
    storeActionsOnLeave();
  }

  useEffect(() => {
    if (!pageContent) return;

    if (isDemoMode) return;
    if (pageContent.internal_rating === 0) {
      setWorkInProgressModalVisible(true);
    }
  }, [pageContent, currentUser]);

  if (pageContent === undefined) return null;

  return (
    <>
      <RatingModal
        open={ratingModalVisible}
        onClose={() => {
          setRatingModalVisible(false);
          navigation.goBack();
        }}
      />
      <WarningModal open={warningModalVisible} onClose={toggleWarningModal} />

      <WorkInProgressModal
        setClosed={setWorkInProgressModalVisible}
        open={workInProgressModalVisible}
        onClose={() => {
          navigation.goBack();
          setWorkInProgressModalVisible(false);
        }}
      />

      <ScrollView
        ref={scrollViewRef}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="handled"
        style={{
          width: "100%",
          flex: 1,
          ...Platform.select({ ios: { overflow: "visible" } }),
        }}
        contentContainerStyle={{
          width: "100%",
          flexGrow: 1,
          paddingBottom: showFloatingActionButton ? 130 : 20,
        }}
        showsVerticalScrollIndicator={false}
      >
        <ResultPageHeader isModal={isModal} />
        {children}
        {pageContent?.tags?.length > 0 && (
          <View style={styles.tagsContainer}>
            <Text
              style={{
                fontSize: Sizes.boxText,
                fontWeight: "600",
                marginBottom: 12,
              }}
            >
              Tags
            </Text>
            <View style={styles.tagsList}>
              {pageContent.tags.map((tag, index) => (
                <View key={`${tag}-${index}`} style={styles.tag}>
                  <Text style={styles.tagText}>{tag}</Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </ScrollView>
      {showFloatingActionButton && (
        <ExpandableFABMenu pageContent={pageContent} />
      )}
      {/* Tags Section */}
    </>
  );
}

const styles = StyleSheet.create({
  tagsContainer: {
    marginTop: 20,
    paddingHorizontal: 16,
    paddingBottom: 20,
  },
  tagsList: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8, // Ensure spacing between tags
  },
  tag: {
    backgroundColor: "#eaeaea", // Subtle background color for minimal look
    borderRadius: 12,
    paddingVertical: 6,
    paddingHorizontal: 8,
    marginBottom: 8,
  },
  tagText: {
    fontSize: 10,
    color: "#333", // Muted text color for minimal style
  },
});
