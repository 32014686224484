import * as FileSystem from "expo-file-system";

export const DIR = "MEDICEO_IMAGES";

async function _download(url: string, targetUrl: string) {
  try {
    if (!(await isFile(targetUrl))) {
      await checkAndCreateDirectory(FileSystem.documentDirectory + DIR);
      const resumableDownload = FileSystem.createDownloadResumable(
        url,
        targetUrl
      );
      await resumableDownload.downloadAsync();
    }
    return true;
  } catch (e) {
    return false;
  }
}

async function checkAndCreateDirectory(filePath: string) {
  const directoryInfo = await FileSystem.getInfoAsync(filePath);
  if (!directoryInfo.exists) {
    await FileSystem.makeDirectoryAsync(filePath, { intermediates: true });
  }
}

export async function downloadImage(url: string) {
  const pathComponent = getFileName(url);
  const targetUrl = FileSystem.documentDirectory + DIR + "/" + pathComponent;
  return await _download(url, targetUrl);
}

export async function isFile(filePath: string) {
  const tmp = await FileSystem.getInfoAsync(filePath);

  return tmp.exists;
}

export function getFileName(url) {
  const parts = url.split("/");
  return parts[parts.length - 2] + "_" + parts[parts.length - 1];
}
