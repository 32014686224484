import { Feather } from "@expo/vector-icons";
import SegmentedControl from "@react-native-segmented-control/segmented-control";
import { useEffect, useState } from "react";
import { Text, View } from "react-native";
import { useDispatch } from "react-redux";
import {
  ButtonTypes,
  DefaultButton,
} from "../../../../components/DefaultButton";
import { colors } from "../../../../constants/static-colors";
import getFontStyle from "../../../../functions/getFontSize";
import { addToUserCreationObject } from "../../../../functions/user/actions";
import { InputWithTitle } from "./AdditionalInformationComponent";
import { ClinicInputComponent } from "./GoogleMapsLocationAutoCompleteInputComponent";
import { DOC_SPECIALTIES } from "./specialities-doc";
import { HEALTHCARE_JOBS_FOR_STUDENTS } from "./student-jobs";

export const StudentContent = ({
  userCreationObject,
  backAction,
  continueAction,
  loading,
}) => {
  const dispatch = useDispatch();

  const isButtonEnabled = () => {
    const {
      university,
      progression_level,
      semester,
      academic_degree,
      student_type,
    } = userCreationObject;

    if (student_type === "nursing_apprentice") {
      // For nurse apprentice, check if university and progression level are valid

      const isEnabled =
        university &&
        university.trim() !== "" &&
        progression_level &&
        progression_level.trim() !== "";

      return !!isEnabled;
    }

    if (student_type === "medical_student") {
      // For medical student, check if university, semester, and academic degree are valid

      const isEnabled =
        university &&
        university.trim() !== "" &&
        semester &&
        !isNaN(semester) &&
        academic_degree &&
        academic_degree.trim() !== "";

      return !!isEnabled;
    }

    // If the job_description is neither nursing_apprentice nor medical_student, disable button

    return false;
  };
  const handleInputChange = (name, value) => {
    dispatch(
      addToUserCreationObject({
        ...userCreationObject,
        [name]: value,
      })
    );
  };

  useEffect(() => {
    if (userCreationObject.job === "Medizinstudent/in") {
      dispatch(
        addToUserCreationObject({
          ...userCreationObject,
          student_type: "medical_student",
        })
      );
    } else {
      dispatch(
        addToUserCreationObject({
          ...userCreationObject,
          student_type: "nursing_apprentice",
        })
      );
    }
  }, [userCreationObject.job]);

  return (
    <View>
      <View style={{ marginBottom: 32 }}>
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues: userCreationObject,
          }}
          presetSelection={HEALTHCARE_JOBS_FOR_STUDENTS}
          title="In welchem Studium / Ausbildung befindest du dich?"
          name="job"
        />

        {userCreationObject.student_type === "medical_student" && (
          <MedStudent {...{ userCreationObject, handleInputChange }} />
        )}
        {userCreationObject.student_type === "nursing_apprentice" && (
          <NurseApprentice {...{ userCreationObject, handleInputChange }} />
        )}
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {backAction && (
          <DefaultButton
            title={"zurück"}
            action={backAction}
            type={ButtonTypes.Outline}
            style={{ width: "30%" }}
          />
        )}
        <DefaultButton
          action={continueAction}
          title="Weiter"
          textStyle={{ alignSelf: "flex-start" }}
          enabled={isButtonEnabled()}
          style={{
            width: "50%",
            marginTop: backAction ? 0 : 20,
            marginLeft: backAction ? 20 : 0,
            paddingHorizontal: 20,
          }}
          loading={loading}
          icon={<Feather name="arrow-right" size={24} />}
          type={ButtonTypes.Primary}
        />
      </View>
    </View>
  );
};

const MedStudent = ({ userCreationObject, handleInputChange }) => {
  return (
    <View>
      <ClinicInputComponent
        inputValues={userCreationObject}
        title="Auf welcher Uni bist du?"
        placeholder="z.B. Goethe Universität Frankfurt"
        indexKey="university"
      />
      <InputWithTitle
        {...{
          handleInputChange,
          inputValues: userCreationObject,
        }}
        presetSelection={DOC_SPECIALTIES}
        title="Welchen Fachbereich möchtest du spezialisieren? (optional)"
        name="preferred_specialization"
      />
      <InputWithTitle
        {...{
          handleInputChange,
          inputValues: userCreationObject,
        }}
        presetSelection={["Bachelor", "Master", "Staatsexamen"]}
        title="Welchen Abschluss strebst du an?"
        name="academic_degree"
      />
      <InputWithTitle
        {...{
          handleInputChange,
          inputValues: userCreationObject,
        }}
        keyboardType="numeric"
        title="In welchem Semester befindest du dich?"
        name="semester"
      />
    </View>
  );
};

const NurseApprentice = ({ userCreationObject, handleInputChange }) => {
  const [isYear, setIsYear] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isYear !== undefined) {
      dispatch(
        addToUserCreationObject({
          ...userCreationObject,
          progression_unit: isYear ? "years" : "semesters",
        })
      );
    }
  }, [isYear]);

  return (
    <View>
      <ClinicInputComponent
        inputValues={userCreationObject}
        title="Wo machst du deine Ausbildung / Studium?"
        placeholder="z.B. Goethe Universität Frankfurt"
        indexKey="university"
      />
      <View
        style={{
          alignSelf: "stretch",

          gap: 12,
          marginTop: 20,
        }}
      >
        <Text
          style={{
            ...getFontStyle(500),
            color: colors.ui.textPrimary,
            fontSize: 16,
          }}
        >
          Mein Studium / Ausbildung läuft in:{" "}
        </Text>
        <SegmentedControl
          values={["Semestern", "Ausbildungsjahren"]}
          selectedIndex={isYear ? 1 : 0}
          tintColor={colors.ui.textPrimary}
          backgroundColor={colors.ui.cardBackground}
          fontStyle={{
            color: colors.ui.textPrimary,
            fontSize: 14,
            ...getFontStyle(700),
          }}
          activeFontStyle={{
            color: colors.ui.background,
            fontSize: 14,
            ...getFontStyle(700),
          }}
          onChange={(event) => {
            setIsYear(event.nativeEvent.selectedSegmentIndex === 1);
          }}
        />
      </View>
      {isYear !== undefined && (
        <InputWithTitle
          {...{
            handleInputChange,
            inputValues: userCreationObject,
          }}
          keyboardType="numeric"
          title={
            "In welchem " +
            (isYear ? "Ausbildungsjahr" : "Semester") +
            " bist du?"
          }
          name="progression_level"
        />
      )}
    </View>
  );
};
