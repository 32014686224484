import { useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch } from "react-redux";

import { useNavigation } from "@react-navigation/native";
import { default as Colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import {
  setCurrentHighlightSection,
  setResultPageCaseContent,
} from "../../../functions/current-result/actions";
import { navigateToNextCMSPage } from "../../../functions/navigation/helpers";
import getColorScheme from "../../../hooks/useColorScheme";

export const ScoreElementInlineScore = ({ data, setScore }) => {
  const [interpretationContent, setInterpretationContent] =
    useState<any>(undefined);
  const [isSelecting, setIsSelecting] = useState(false);

  const dispatch = useDispatch();
  const colorScheme = getColorScheme();
  const navigation = useNavigation();
  const [currentAnswer, setCurrentAnswer] = useState<number | null>(null);

  useEffect(() => {
    setIsSelecting(false);
    if (data?.usesReferenceId) return;
    const score = interpretationContent?.caseData?.score;
    if (interpretationContent?.caseData?.score) {
      const answerItem = data?.scoreInterpretationElementsInlineScore?.find(
        (d) => score >= d.lowerBound && score <= d.upperBound
      );

      if (answerItem) {
        setCurrentAnswer(answerItem.scoreValue);
        if (currentAnswer === null) {
          setScore((s) => {
            let _s = s;
            if (!_s) _s = 0;
            return _s + parseFloat(answerItem.scoreValue);
          });
        } else {
          setScore((s) => {
            let _s = s;
            if (!_s) _s = 0;
            return _s - currentAnswer + parseFloat(answerItem.scoreValue);
          });
        }
      }
    }
  }, [interpretationContent]);

  return (
    <TouchableOpacity
      onPress={() => {
        setIsSelecting(true);

        dispatch(setResultPageCaseContent(null));

        dispatch(setCurrentHighlightSection(null));
        navigateToNextCMSPage(data.scoreId, navigation, true, {
          setInterpretationContent: setInterpretationContent,
          additionalCaseData: interpretationContent,
        });
      }}
      style={{
        flexDirection: "row",
        borderRadius: 12,
        backgroundColor: Colors[colorScheme].foreground,
        borderColor: isSelecting
          ? Colors[colorScheme].accent
          : Colors[colorScheme].text,
        borderWidth: isSelecting ? 2 : 1,
        marginBottom: 20,
        padding: 12,
        alignItems: "center",
      }}
    >
      <Text
        style={{
          margin: 0,
          flex: 1,
          fontSize: Sizes.boxText,
          marginVertical: 12,
          color: Colors[colorScheme].text,
          opacity: 0.75,
        }}
      >
        {data.fieldTitle}
      </Text>
      {currentAnswer && (
        <View
          style={{
            borderRadius: 6,
            borderColor: Colors[colorScheme].text,
            borderWidth: 1,
          }}
        >
          <Text
            style={{
              color: Colors[colorScheme].text,
              opacity: 0.75,
              fontSize: 20,
              marginRight: 12,
              marginLeft: 12,
              fontWeight: "bold",
            }}
          >
            {Math.round(currentAnswer * 100) / 100}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};
