import { Feather } from "@expo/vector-icons";
import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Sizes } from "../../constants/static-sizes";
import { SatelliteTile } from "./PartnerDataProvider";

/**
 * SmallPartnerCard Component
 *
 * Renders a small partner card item, displaying the slogan and an arrow icon.
 * @param {SatelliteTile} item - The satellite tile item data
 */
export const SmallPartnerCard: React.FC<{ item: SatelliteTile }> = ({
  item,
}) => (
  <TouchableOpacity
    style={{
      flexBasis: "48%", // Each card takes about half of the available width
      padding: 12,
      borderRadius: 12,
      backgroundColor: "white",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      shadowColor: "#000",
      shadowOffset: {
        width: 2,
        height: 2,
      },
      shadowOpacity: 0.05,
      shadowRadius: 2,
      gap: 8,
    }}
  >
    <Text
      android_hyphenationFrequency="full"
      style={styles.cardText}
      numberOfLines={2}
      ellipsizeMode="tail"
    >
      {item?.slogan || "Unknown"}
    </Text>
    <Feather name="chevron-right" size={20} color="black" />
  </TouchableOpacity>
);

// Styles for the components
const styles = StyleSheet.create({
  cardText: {
    fontSize: Sizes.boxText,
    fontWeight: "500",
    color: "black",
    flex: 1,
  },
});
