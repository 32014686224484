import { useEffect, useMemo, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { ScoreElement } from "../../../api/content/load-all/types";
import CustomRenderHTMLComponent from "../../../components/CustomRenderHTMLComponent";
import { colors } from "../../../constants/static-colors";
import { Sizes } from "../../../constants/static-sizes";
import getFontStyle from "../../../functions/getFontSize";
import isLargerDevice from "../../../hooks/isLargerDevice";

export default function ScoreElementComponent({
  data,
  answers,
  setAnswers,
  setCurrentScore,
}: {
  readonly data: ScoreElement;
  readonly answers: any;
  readonly setAnswers: any;
  readonly setCurrentScore: any;
}) {
  const [width, setWidth] = useState(0);

  // This finds if we already have a stored answer for this question
  const currentAnswer = answers.find(
    (element: any) => element.title === data.title
  );

  // We’ll track the user’s selection state here, defaulting to “No”
  // so that the negative option is selected by default.
  const [isYesSelected, setIsYesSelected] = useState(false);

  // If an answer already exists in `answers`, we can synchronize
  // the initial state. Otherwise default to “No”.
  useEffect(() => {
    if (currentAnswer) {
      setIsYesSelected(currentAnswer.score === data.activeValue);
    } else {
      // If there's no existing answer, default to "No"
      setIsYesSelected(false);
      // Optionally record that answer in setAnswers as well:
      setAnswers((prev: any[]) => {
        const filtered = prev.filter(
          (element: any) => element.title !== data.title
        );
        return [
          ...filtered,
          { title: data.title, value: "Nein", score: data.inactiveValue },
        ];
      });
      // Also adjust the overall currentScore if needed
      setCurrentScore((prev: number) => prev + data.inactiveValue);
    }
  }, []);

  // Whenever isYesSelected changes, update the stored answer in `answers`
  // and also update the "current score" accordingly
  useEffect(() => {
    // Don’t run this if we haven’t mounted yet
    // We already handle the first assignment in the above effect.
    if (currentAnswer || isYesSelected !== false) {
      setAnswers((prev: any[]) => {
        const filtered = prev.filter(
          (element: any) => element.title !== data.title
        );
        // If user picks YES, store activeValue. Otherwise store inactiveValue.
        return [
          ...filtered,
          {
            title: data.title,
            value: isYesSelected ? "Ja" : "Nein",
            score: isYesSelected ? data.activeValue : data.inactiveValue,
          },
        ];
      });
      // We also need to update setCurrentScore. This is a bit trickier
      // because we have to remove the old score from the total and add the new one.
      // For simplicity, you might want to manage all the scoring in a single place
      // or store just the difference.
      // Here’s a naive approach: recalculate based on the new choice
      setCurrentScore((prev: number) => {
        // old score was currentAnswer?.score, new score is
        const oldScore = currentAnswer?.score ?? data.inactiveValue;
        const newScore = isYesSelected ? data.activeValue : data.inactiveValue;
        return prev - oldScore + newScore;
      });
    }
  }, [isYesSelected]);

  return (
    <View
      style={{
        borderRadius: 12,
        backgroundColor: colors.ui.cardBackground,
        borderColor: colors.ui.border,
        borderWidth: 1,
        marginBottom: 20,
        padding: 12,
        alignItems: "center",
        flexDirection: "row",
        gap: 12,
      }}
    >
      {/* Top row: question text and any indicator */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <View
          style={{ flex: 1 }}
          onLayout={(e) => setWidth(e.nativeEvent.layout.width)}
        >
          {width !== 0 && <TextItem width={width} data={data} />}
        </View>
      </View>

      {/* Always show the selection buttons (uncollapsed). */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",

          flex: 1,
        }}
      >
        {/* “No” / negative option */}
        <TouchableOpacity
          onPress={() => setIsYesSelected(false)}
          style={{
            flex: 1,
            paddingHorizontal: isLargerDevice() ? 30 : 12,
            paddingVertical: isLargerDevice() ? 12 : 8,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: !isYesSelected ? colors.ui.primary : colors.ui.border,
            backgroundColor: !isYesSelected
              ? colors.ui.highlight
              : colors.ui.cardBackground,
            marginRight: 8,
          }}
        >
          <Text
            style={{
              ...getFontStyle(500),
              textAlign: "center",
              fontSize: Sizes.boxText,
              color: colors.ui.textPrimary,
            }}
          >
            Nein
          </Text>
        </TouchableOpacity>

        {/* “Yes” / positive option */}
        <TouchableOpacity
          onPress={() => setIsYesSelected(true)}
          style={{
            flex: 1,
            paddingHorizontal: isLargerDevice() ? 30 : 12,
            paddingVertical: isLargerDevice() ? 12 : 8,
            borderRadius: 6,
            borderWidth: 1,
            borderColor: isYesSelected ? colors.ui.primary : colors.ui.border,
            backgroundColor: isYesSelected
              ? colors.ui.highlight
              : colors.ui.cardBackground,
            marginRight: 8,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: Sizes.boxText,
              ...getFontStyle(500),
              color: colors.ui.textPrimary,
            }}
          >
            Ja
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

// Simple text renderer
const TextItem = ({ width, data }: { width: number; data: ScoreElement }) => {
  return useMemo(() => {
    return (
      <CustomRenderHTMLComponent
        contentWidth={width}
        tagsStyles={{
          body: {
            alignSelf: "stretch",
            fontSize: Sizes.boxText,
            color: colors.ui.textPrimary,
          },
          p: {
            margin: 0,
          },
        }}
        source={{ html: data.title }}
      />
    );
  }, [width, data.title]);
};
